import { ListItemText } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'

export type MaterialSelectorModalHeaderProps = {
  thumbnail3d?: string
  thumbnail2d?: string
  name?: string
  materialSummary?: MaterialSummaryDto
  title: string
}

export const MaterialSelectorModalHeader = (
  props: MaterialSelectorModalHeaderProps
) => {
  const { t } = useTranslation()
  const { partyId, projectId } = useOrganizationAndProjectContext()
  return (
    <div className="material-selector-header">
      <div className="material-selector-header-models">
        {props.thumbnail3d ? (
          <img
            loading="lazy"
            src={projectImageUrl(partyId, projectId, props.thumbnail3d)}
            alt={'3d part preview'}
          />
        ) : null}
        {props.thumbnail2d ? (
          <img
            loading="lazy"
            src={projectImageUrl(partyId, projectId, props.thumbnail2d)}
            alt={'2d part preview'}
          />
        ) : null}
      </div>

      <ListItemText
        primary={props.name}
        primaryTypographyProps={{
          variant: 'h6',
        }}
        secondary={
          props.materialSummary?.id
            ? `${props.materialSummary.id}: ${props.materialSummary.description}`
            : t('project:article-not-defined', 'select an article')
        }
      />
    </div>
  )
}
