import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { CloseOutlined, LabelOutlined } from '@mui/icons-material'
import { If } from 'components/Common/If/If'
import React, { CSSProperties, useState } from 'react'

type Props = {
  children: React.ReactNode
  open?: boolean
  alwaysVisible?: boolean
  gutterTop?: boolean
  listStyle?: CSSProperties
}

const PinIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.083 1c0 1.018-1.424 1.907-3.541 2.382V11c2.926.652 4.958 2.086 4.958 3.751h-7.792V23h-1.416v-8.25H3.5c0-1.665 2.032-3.1 4.958-3.751V3.382C6.341 2.907 4.917 2.018 4.917 1h14.166z"
      />
    </svg>
  )
}

type StyleProps = {
  isOpen: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  badgeListRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  badgeList: {
    display: 'flex',
    gap: theme.spacing(1),
    transition: 'all .2s ease',
    alignItems: 'flex-end',
    visibility: props.isOpen ? 'visible' : 'hidden',
    width: props.isOpen ? '100%' : 0,
  },
}))

export const BoMItemBadgeList = (props: Props) => {
  const [pinned, setPinned] = useState(props.alwaysVisible)
  const [open, setOpened] = useState(props.open || false)

  const isOpen = open || pinned

  const { classes } = useStyles({ isOpen })

  if (
    !React.Children.toArray(props.children).filter((child) =>
      React.isValidElement(child)
    ).length
  ) {
    return null
  }

  // const handleMouseEnter = () => setOpened(true)
  const handleMouseOut = () => setTimeout(() => setOpened(false), 500)
  const handleClick = () => {
    if (!open && !pinned) {
      setOpened(true)
    } else if (pinned) {
      setOpened(false)
      setPinned(false)
    } else {
      setPinned((pinned) => !pinned)
    }
  }

  return (
    <span className={classes.badgeListRoot} onMouseLeave={handleMouseOut}>
      <span className={classes.badgeList} style={props.listStyle}>
        {props.children}
      </span>
      <If condition={!props.alwaysVisible}>
        <IconButton
          size="small"
          onClick={handleClick}
          // onMouseOver={handleMouseEnter}
        >
          {(open && !pinned && (
            <PinIcon style={{ width: '18px', height: '18px' }} />
          )) ||
            (pinned && <CloseOutlined />) || <LabelOutlined />}
        </IconButton>
      </If>
    </span>
  )
}
