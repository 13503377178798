import {
  Button,
  Card,
  Divider,
  Grow,
  ListItemText,
  Typography,
} from '@mui/material'
import { AppLogo } from 'components/Layout/AppLogo'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TelemetryService } from './services/Telemetry'

import { useAuth0 } from '@auth0/auth0-react'
import { useAppLogin } from 'features/Auth/useAppLogin'
import './AppError.scss'

const messages = {
  consent_required:
    'your email account is not confirmed yet. Please check your mailbox.',
  login_required: 'unable to perform the login. Please try again',
  noOrganizations: 'user is not part of an organization.',
  invalidUser: 'current user is not valid in the platform.',
  maintenance: 'Platform under maintenance. Please, try again in a few moments',
  incognito:
    'Your browser is blocking third- party cookies, which are required for the login process.Please enable them to continue, or disable "incognito" / "private" navigation mode and try again.',
}

const notReloadableErrors = ['incognito']
const reloadEnabled = (errorType: string) =>
  !notReloadableErrors.includes(errorType)

const AppError = () => {
  const [timeToReload, setTimeToReload] = React.useState(120)
  const location = useLocation()
  const navigate = useNavigate()
  const auth0 = useAuth0()
  const { appLogout } = useAppLogin()

  const getErrorType = useCallback(
    () =>
      (location?.state && location.state['errorType']) ||
      queryString.parse(location.search)['errorType'],
    [location.search, location.state]
  )

  const errorIs = useCallback(
    (errorType: keyof typeof messages) => getErrorType() === errorType,
    [getErrorType]
  )

  const [willReloadTheApp, setWillReloadTheApp] = useState<boolean>(true)
  const handlePageReload = useCallback(() => {
    const perfEntries = window.performance.getEntriesByType('navigation')
    if (perfEntries.findIndex((entry) => entry.type === 'reload') !== -1) {
      navigate('/app', { replace: true })
      setWillReloadTheApp(true)
    } else {
      setWillReloadTheApp(false)
    }
  }, [navigate, setWillReloadTheApp])

  useEffect(() => {
    handlePageReload()
  }, [handlePageReload])

  useEffect(() => {
    TelemetryService.getInstance().logError({
      name: 'WebApplication error',
      message: location?.state
        ? location.state['error']?.error_description || 'undefined error'
        : getErrorType() || 'undefined error',
    })

    if (errorIs('login_required')) {
      navigate('/login')
    }

    let interval = null

    if (reloadEnabled(getErrorType())) {
      interval = setInterval(() => {
        setTimeToReload((time) => {
          if (time > 0) --time
          return time
        })
      }, 1000)
    }

    return () => {
      interval && clearInterval(interval)
    }
  }, [errorIs, getErrorType, location.state, navigate])

  if (timeToReload <= 0) {
    if (auth0.isAuthenticated) {
      appLogout()
    } else {
      navigate('/', { replace: true })
    }
  }

  // if (willReloadTheApp) {
  //   return null
  // }

  const messageToShow =
    messages[getErrorType() || ''] || 'unknown error: ' + getErrorType()

  return (
    <Grow in={!willReloadTheApp}>
      <div className="app-error">
        <Card className={'app-error-card'}>
          <div className="app-error-header">
            <AppLogo className="app-logo" />
          </div>
          <Divider />
          <div className="app-error-content">
            <Typography variant="subtitle1">
              {auth0.user?.given_name &&
                `${auth0.user.given_name} ${auth0.user.family_name},`}
            </Typography>

            <Typography variant="body1" style={{ textAlign: 'center' }}>
              <strong>{messageToShow}</strong>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textAlign: 'center' }}
            >
              {auth0.user && `logged in as ${auth0.user.email}`}
            </Typography>
          </div>
          <Divider />
          {reloadEnabled(getErrorType()) && (
            <div className={'app-error-footer'}>
              <Button
                onClick={async () => {
                  // await AuthService.GetCurrent()?.Logout()
                  auth0.logout({
                    logoutParams: { returnTo: window.location.origin },
                  })
                }}
                variant="text"
                color="primary"
                size="large"
              >
                <ListItemText
                  primary={`the app will reload in ${timeToReload}s`}
                  secondary={'or click here to go to Rh24 start page'}
                />
              </Button>
            </div>
          )}
        </Card>
      </div>
    </Grow>
  )
}

export default AppError
