import { useAppSelector } from 'store/configureStore'
import { AppFeaturesDescription } from './AppFeaturesDescription'
import { FeatureKeys } from './FeatureKeys'

export const useAppFeature = (featureKey: FeatureKeys) => {
  const currentModules = useAppSelector(
    (state) => state.user.organizationContext.modules
  )

  if (!featureKey || !currentModules?.length) {
    return true
  }

  const feature = AppFeaturesDescription[featureKey]

  if (!feature) {
    return true
  }

  const moduleActive =
    feature.isActive && currentModules.some((x) => feature.modules.includes(x))

  return moduleActive
}
