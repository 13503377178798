import { BaseController } from 'controllers/BaseController'
import { SetBoMItemAsPurchasingPartRequest } from 'services/APIs/InternalAPI/internal-api.contracts'
import { GetContext } from 'store/storeUtils'
import { PurchasingPartInfoAPI } from './PurchasingPartInfoAPI'

export interface IPurchasingPartInfoAPI {
  setBomItemAsPurchasingPart: (
    bomItemId: string,
    req: SetBoMItemAsPurchasingPartRequest
  ) => Promise<void>
  unsetBomItemAsPurchasingPart: (bomItemId: string) => Promise<void>
}

export class PurchasingInfoController extends BaseController<IPurchasingPartInfoAPI> {
  constructor() {
    super((onRequestChange) => {
      const { partyId, projectId } = GetContext()
      return new PurchasingPartInfoAPI(partyId, projectId, onRequestChange)
    })
  }

  public async setBomItemAsPurchasingPart(
    bomItemId: string,
    req: SetBoMItemAsPurchasingPartRequest
  ): Promise<void> {
    try {
      await this.api.setBomItemAsPurchasingPart(bomItemId, req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async unsetAsPurchasingPart(bomItemId: string) {
    try {
      await this.api.unsetBomItemAsPurchasingPart(bomItemId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
