import { Typography } from '@mui/material'
import { LocalizedWorkingStepLabel } from 'components/Localization/LocalizedWorkingStepLabel'
import { MoneyString } from 'model/Money'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { PriceDetailsGraphData } from '../../useBomItemDetails'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

export const WorkingStepTooltip = (props: {
  data: PriceDetailsGraphData
  dataKey: string
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <LocalizedWorkingStepLabel
        workingStepType={props.data.primaryWorkingStep as WorkingStepType}
      />
      {props.data.sharedCost && (
        <Typography variant="caption">(shared cost)</Typography>
      )}
      {Object.keys(props.data)
        .filter(
          (key) => key === props.dataKey
          // key !== 'id' &&
          // key !== 'description' &&
          // key !== 'sharedCost' &&
          // key !== 'matchDescription' &&
          // key !== 'bomItemPointer' &&
          // key !== 'materialDescription'
        )
        .map((d, index) => (
          <TargetPriceTooltipListItemText
            key={`${props.data.description}-${d}-${index}`}
            primary={
              <Typography variant="body2">
                {props.data[d] && props.data[d]['value']
                  ? MoneyString(props.data[d])
                  : '-'}
              </Typography>
            }
            secondary={Boolean(d) ? t(`project:${d}`, d) : ''}
          />
        ))}
    </div>
  )
}
