import { MenuItem, ListItemAvatar, ListItemText } from '@mui/material'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { usePartTypeIdFilter } from '../BoMItemFilter/Components/PartTypeIdFilter/usePartTypeIdFilter'
import { useBomItemMenuStyles } from '../BoMItemMenu/MenuItems/BomItemMenuItem'
import { FilterAltOffOutlined, FilterAltOutlined } from '@mui/icons-material'

type Props = {
  bomItemPointer: BomItemPointer
  onClose: () => void
}

export const PartTypeFilterMenuButton = (props: Props) => {
  const { t } = useTranslation()
  const { classes } = useBomItemMenuStyles()

  const { handleChangeFilter, filterIsActive } = usePartTypeIdFilter()

  const active = filterIsActive
  return (
    <MenuItem
      className={classes.menuRoot}
      onClick={() => {
        handleChangeFilter(!active ? [props.bomItemPointer.id] : undefined)
        props.onClose && props.onClose()
      }}
    >
      <ListItemAvatar className={classes.menuItemAvatar}>
        {!active ? <FilterAltOutlined /> : <FilterAltOffOutlined />}
      </ListItemAvatar>
      <ListItemText
        primary={
          !active
            ? t('project:filter-bom-item-include', 'filter this item')
            : t('project:filter-bom-item-remove', 'remove filter')
        }
        secondary={
          !active
            ? t(
                'project:filter-bom-item-include-description',
                'show only this BoM item over the different views'
              )
            : t('project:filter-bom-item-remove-description', 'show all items')
        }
      />
    </MenuItem>
  )
}
