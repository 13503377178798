import { QuantityField } from 'components/Common/UnitEditor/QuantityField'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'

type Props = {
  bomItemPointer: BomItemPointer
  activityId: string
  onChange: (value: QuantityDto) => void
  disabled?: boolean
}
export const BomItemMaterialSurchargePercentage = (props: Props) => {
  const { t } = useTranslation()

  const bomItemSurcharge = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      props.bomItemPointer,
      props.activityId,
      'surchargeRatio'
    )
  ) as QuantityDto

  const [surchargeRatio, setSurchargeRatio] = useState({
    value: bomItemSurcharge?.value || 0,
    unit: '%',
    selectableAbbreviations: ['%'],
  })

  const handleChange = (value: QuantityDto) => {
    setSurchargeRatio({
      value: value.value,
      unit: '%',
      selectableAbbreviations: value.selectableAbbreviations,
    })

    props.onChange(value)
  }

  return (
    <QuantityField
      name="surchargeRatio"
      label={`${t('common:surcharge')}`}
      value={surchargeRatio}
      onChange={handleChange}
      disabled={props.disabled}
    />
  )
}
