import { ClearOutlined } from '@mui/icons-material'
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material'
import SearchSvg from '_icons/ui/search.svg?react'
import {
  SearchBomItemsController,
  SearchType,
} from 'controllers/Search/SearchBomItemsController'
import { useAppController } from 'customHooks/useAppController'
import _ from 'lodash'
import { BomItemSearch, BomItemSearchItem } from 'model/BomItemSearchModel'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ShowException } from 'store/Application/appActions'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { Key } from 'utils/keyCodes'
import { useOrganizationContext } from 'utils/useOrganizationContext'
import { BomItemSearchOption } from './BomItemSearchOption'
import { NoOptions } from './NoOptions'
import './SearchGlobal.scss'

const useStyles = makeStyles()((theme) => ({
  notch: {
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${
      theme.palette.mode === 'dark'
        ? `rgba(0, 0, 0, 0.6)`
        : `rgba(255, 255, 255, 0.75)`
    }, inset 0 0 0 1px currentColor`,
  },
}))

const Search = _.debounce(
  async (
    value: string,
    searchType,
    controller: SearchBomItemsController,
    setDocuments,
    setFacets,
    setSearchResult
  ) => {
    try {
      setDocuments([])
      setFacets([])
      setSearchResult([])
      const searchResult = await controller.Search(value, searchType)
      // console.log('search result', searchResult)
      setSearchResult(searchResult)
    } catch (err) {
      ShowException('search', err)
    }
  },
  420,
  { leading: false, trailing: true }
)

export const SearchGlobal = () => {
  const { classes } = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const [searchResult, setSearchResult] = useState<BomItemSearch>()
  const [documents, setDocuments] = useState<
    { document: BomItemSearchItem; highlights: Record<string, string[]> }[]
  >([])
  const [noResults, setNoResults] = useState(false)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('search')
  const [searchType, setSearchType] = useState<SearchType>('freeText')
  const [facets, setFacets] =
    useState<Array<{ value: string; count: number }>>()
  const [searchText, setSearchText] = useState<string>('')

  const { partyId: organizationId } = useOrganizationContext()
  const { profile } = useAppSelector((state: RootAppState) => state.user)
  const { controller, loading } = useAppController(
    () => new SearchBomItemsController(organizationId)
  )

  useEffect(() => {
    if (!searchText || searchText === '') {
      setNoResults(false)
      setFacets(undefined)
      setDocuments([])
    }
  }, [controller, searchText, searchType])

  useEffect(() => {
    setSearchText('')
  }, [searchType])

  useEffect(() => {
    // console.log('searchResult', searchResult)
    setDocuments(searchResult?.documents || [])
    setNoResults(searchResult?.totalCount === 0)
    setFacets(searchResult?.facets)
  }, [searchResult])

  const onChange = React.useCallback(
    (
      e,
      value:
        | string
        | { document: BomItemSearchItem; highlights: Record<string, string[]> }
    ) => {
      e.preventDefault()
      if (typeof value === 'string') {
        return
      } else {
        switch (value?.document?.type.toLowerCase()) {
          case 'project': {
            navigate(`/app/project/${value.document.projectId}`)
            break
          }
          case 'row':
          case 'part':
          case 'assembly': {
            navigate(
              `/app/project/${value.document.projectId}/bomitem/${value.document.id}`
            )
            break
          }
        }
      }
    },
    [navigate]
  )

  if (!profile || !organizationId) {
    return null
  }

  return (
    <Autocomplete
      options={documents}
      open={open}
      color="inherit"
      openOnFocus
      onOpen={() => setOpen(true)}
      onClose={(e) => {
        e.preventDefault()

        // if (reason === 'blur') {
        //   return
        // }

        setOpen(false)
        setDocuments([])
        setFacets(undefined)
      }}
      fullWidth
      classes={{
        popperDisablePortal: 'search-bom-items--options-panel',
        option: 'bom-item-option-container',
        clearIndicator: 'clear-indicator',
        inputRoot: 'MuiInputBase-root',
      }}
      // disablePortal
      // freeSolo
      ListboxProps={{
        sx: {
          '& em': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            display: 'inline-block',
            padding: (theme) => theme.spacing(0, 0.5),
            fontStyle: 'normal',
          },
        },
      }}
      isOptionEqualToValue={(option, value) => !!value}
      inputValue={searchText}
      onInputChange={(e, v) => setSearchText(v)}
      onChange={onChange}
      loading={loading['search-bom-items']}
      loadingText={t('common:loading')}
      noOptionsText={
        <NoOptions
          noResults={noResults}
          searchText={searchText}
          searchType={searchType}
          setSearchType={setSearchType}
          facets={facets}
          search={(value, searchType) => {
            setSearchText(value)
            Search(
              value,
              searchType,
              controller,
              setDocuments,
              setFacets,
              setSearchResult
            )
          }}
        />
      }
      getOptionLabel={() => ''} //option.document.name}
      filterOptions={(x) => x}
      clearOnEscape
      disableClearable={true}
      renderOption={(el, option) => (
        <BomItemSearchOption baseElProps={el} option={option} />
      )}
      renderInput={(inputProps) => (
        <>
          <TextField
            {...inputProps}
            className={'menu-search'}
            placeholder={t('common:search', 'search')}
            inputProps={{
              ...inputProps.inputProps,
              onKeyDown: (e) => {
                switch (e.key) {
                  case Key.Enter: {
                    Search(
                      e.currentTarget.value,
                      searchType,
                      controller,
                      setDocuments,
                      setFacets,
                      setSearchResult
                    )
                    break
                  }
                  case Key.Escape: {
                    setOpen(false)
                    setDocuments([])
                    setFacets(undefined)
                  }
                }
              },
            }}
            value={searchText}
            onFocus={() => setOpen(true)}
            // onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              ...inputProps.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <>
                    <SearchSvg className="MuiSvgIcon-root" />
                    {searchType === 'filter' && t('search:filter', 'filter')}
                    {searchType === 'groups' && t('search:groupby', 'group by')}
                  </>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {inputProps.InputProps.endAdornment}
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchText('')
                        setDocuments([])
                        setFacets(undefined)
                        setOpen(false)
                      }}
                      size="small"
                      color="inherit"
                    >
                      <ClearOutlined />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
              classes: {
                root: 'search-bom-items-root',
                notchedOutline: classes.notch,
              },
            }}
            variant="outlined"
          />
        </>
      )}
    />
  )
}
