import { BaseController } from 'controllers/BaseController'
import {
  DashboardType,
  PartySettingsSetDashboardRequest,
  RemoveDashboardRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { DashboardAdminAPI } from './MixPanelDashboards.api'

export interface IDashboardAdminAPI {
  setDashboard: (req: PartySettingsSetDashboardRequest) => Promise<void>
  deleteDashboard: (req: RemoveDashboardRequest) => Promise<void>
}

export class MixPanelDashboardsAdminController extends BaseController<IDashboardAdminAPI> {
  constructor(partyId: string) {
    super((onRequestChanged) => {
      return new DashboardAdminAPI(partyId, onRequestChanged)
    })
  }

  public async setDashboard(req: PartySettingsSetDashboardRequest) {
    try {
      return await this.api.setDashboard(req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async deleteDashboard(req: DashboardType) {
    try {
      return await this.api.deleteDashboard({ dashboardType: req })
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
