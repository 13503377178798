import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
import { projectGrossSalesPriceSelector } from 'features/BillOfMaterials/store/selectors/projectGrossSalesPriceSelector'
import { useTranslation } from 'react-i18next'

export function ProjectGrossSalesPrice(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()
  return (
    <ValueLabelConnected
      propertySelector={projectGrossSalesPriceSelector}
      valueLabelProps={{
        label: t('project:gross-sales-price'),
        emptyString: '-',
        classes: props.valueLabelClasses,
      }}
    />
  )
}
