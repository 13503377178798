import { DeleteOutline } from '@mui/icons-material'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { deleteBoMItem } from '../../store/asyncActions/deleteBoMItem'
import { BomItemMenuItem } from './BomItemMenuItem'

export const DeleteMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose?: () => void
}) => {
  return (
    <BomItemMenuItem
      // disableIfNotEditable
      action={deleteBoMItem}
      actionParams={{ bomItemPointer }}
      primaryTextTranslationKey="project:delete-item"
      secondaryTextTranslationKey="project:delete-item-description"
      icon={<DeleteOutline />}
      onClose={onClose}
      closeOnClick
    />
  )
}
