import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type Props = {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}

export const EstimatedProductionTimeIsZeroTodo = (props: Props) => {
  const { openDialog } = useDialogManager()
  const { getIssueDescription } = useBomItemIssueDescription()

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={() =>
        openDialog('BomItemDetailsDrawer', {
          bomItemPointer: props.bomItemPointer,
          filterZeroEstimatedProductionTime: true,
        })
      }
      color="inherit"
      startIcon={<ChecklistOutlined />}
    >
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </Button>
    // <div>
    //   <Button
    //     component="li"

    //     translationKey="project:open-row-details"
    //     variant="text"
    //     onClick={() =>
    //       openDialog('BomItemDetailsDrawer', {
    //         bomItemPointer: props.bomItemPointer,
    //         filterZeroEstimatedProductionTime: true,
    //       })
    //     }
    //   />
    // </div>
  )
}
