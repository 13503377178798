import App from 'App'
import { Auth0ProviderWithRedirectCallback } from 'AppAuth0Provider'
import { ErrorBoundary } from 'components/Common/ErrorBoundary'
import Spinning from 'components/Common/Spinner/Spinning'
import { EmbeddedRh24 } from 'features/EmbeddedRh24/EmbeddedRh24'
import { Suspense, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from 'store/configureStore'

export function AppContainer(props: { rootElement: HTMLElement }) {
  useEffect(() => {
    props.rootElement.classList.remove('app-loader')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <ErrorBoundary>
          <EmbeddedRh24 />
          <Suspense fallback={<Spinning fixed />}>
            <BrowserRouter>
              <Auth0ProviderWithRedirectCallback
                domain="auth.rhodium24.io"
                clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                authorizationParams={{
                  redirect_uri: window.location.origin + '/auth/callback',
                  audience: 'https://api.rhodium24.io',
                }}
              >
                <App />
                <div id="root-portal"></div>
              </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </DndProvider>
    // </React.StrictMode>
  )
}
