import { Paper } from '@mui/material'
import {
  MRT_SortingState,
  MRT_TableInstance,
  MRT_ToolbarAlertBanner,
} from 'material-react-table'
import TopToolbar from './TopToolbar'

import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { isEmpty } from 'lodash'
import { ChangeEvent } from 'react'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'project-table-toolbar' })((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    borderRadius: 0,
  },
  deleteButton: {
    paddingLeft: theme.spacing(1),
  },
}))

type Props = {
  table: MRT_TableInstance<ProjectSummaryDto>
  loading: Record<string, boolean>
  onlyMyProjects: boolean
  showCanceledProjects: boolean
  setShowCanceledProjects: (value: boolean) => void
  getProjectList: (filter: string) => void
  handleChangeOnlyMyProjects: (
    ev: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  handleDeleteSelectedProjects: (
    table: MRT_TableInstance<ProjectSummaryDto>
  ) => Promise<void>
  selectedProjectStates: ProjectStateAsString[]
  onProjectStateChange: (selectedProjectStates: ProjectStateAsString[]) => void
  filterValue: string
  projectStateFilters: ProjectStateAsString[]
  handleProjectStateFilter: (
    selectedProjectStates: ProjectStateAsString[]
  ) => void
  sorting: MRT_SortingState
  setSorting: (sorting: MRT_SortingState) => void
}

const _CustomToolbar = (props: Props) => {
  const { classes } = useStyles()

  const showDelete = !isEmpty(props.table.getState().rowSelection)

  return (
    <Paper className={classes.root} elevation={0}>
      <div>
        <MRT_ToolbarAlertBanner
          table={props.table}
          style={{
            maxWidth: 'none',
            width: '100%',
          }}
          sx={{
            '& .MuiAlert-message': {
              maxWidth: 'none',
              backgroundColor: (theme) => theme.palette.background.paper,
            },
          }}
        />
        {showDelete ? (
          <div className={classes.deleteButton}>
            <LocalizedButton
              translationKey="common:delete-selected-projects"
              color="error"
              variant="contained"
              size="small"
              onClick={() => props.handleDeleteSelectedProjects(props.table)}
            />
          </div>
        ) : null}
      </div>
      <div>
        {/* <MRT_GlobalFilterTextField table={table} /> */}
        <TopToolbar
          table={props.table}
          getProjectList={() => props.getProjectList(props.filterValue)}
          handleChangeOnlyMyProjects={props.handleChangeOnlyMyProjects}
          handleDeleteSelectedProjects={() =>
            props.handleDeleteSelectedProjects(props.table)
          }
          loading={props.loading}
          onlyMyProjects={props.onlyMyProjects}
          showCanceledProjects={props.showCanceledProjects}
          setShowCanceledProjects={props.setShowCanceledProjects}
          onProjectStateChange={props.handleProjectStateFilter}
          selectedProjectStates={props.projectStateFilters}
          sorting={props.sorting}
          setSorting={props.setSorting}
        />
      </div>
    </Paper>
  )
}

// if memo is used, the toolbar will not re-render when a row is selected,
// and the toolbar will not be updated

export default _CustomToolbar
