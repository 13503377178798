import { ReportProblemOutlined } from '@mui/icons-material'
import { InputAdornment, TextField, Tooltip } from '@mui/material'
import _ from 'lodash'
import { FocusEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormatProps } from 'react-number-format'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { NumberFormatCustom } from '../NumberFormatInputComponent'

type Props = {
  value: MoneyDto
  onChange: (e: { currentTarget: { value: MoneyDto } }) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  saving?: boolean
  allowNegative?: boolean
  label?: string
  variant?: 'outlined' | 'standard' | 'filled'
  helperText?: ReactElement
  endAdornment?: ReactElement
  autoFocus?: boolean
  required?: boolean
  textAlign?: 'left' | 'center' | 'right'
  error?: boolean
  warnings?: string
  defaultMoneyObject?: MoneyDto
}

function MoneyInput(props: Props) {
  const { t } = useTranslation()

  function handleChange(e) {
    let newMoney = { ...props.value }

    switch (e.currentTarget.name) {
      case 'money': {
        if (
          props.value?.value?.toFixed(2) !==
          parseFloat(e.currentTarget.value).toFixed(2)
        ) {
          newMoney = {
            symbol:
              props.value?.symbol || props.defaultMoneyObject?.symbol || '€',
            currency:
              props.value?.currency ||
              props.defaultMoneyObject?.currency ||
              'EUR',
            value: parseFloat(e.currentTarget.value) || 0,
          }
        }
        break
      }
    }

    if (!_.isEqual(newMoney, props.value)) {
      props.onChange({
        ...e,
        currentTarget: { ...e.currentTarget, value: newMoney },
      })
    }
  }

  return (
    <Tooltip title={props.warnings || ''}>
      <span>
        <TextField
          // disabled={isLoading[`${props.ps.materialId || props.ps.workingStep.primaryWorkingStep}_costprice`]}
          value={props.value?.value || 0}
          name="money"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {props.value?.symbol || props.defaultMoneyObject?.symbol || '€'}
                {props.warnings && <ReportProblemOutlined color="secondary" />}
              </InputAdornment>
            ),
            endAdornment: props.endAdornment,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              style: { textAlign: props.textAlign || 'right' },
              allowNegative: props.allowNegative || false,
              allowLeadingZeros: false,
              autoFocus: props.autoFocus,
            } as NumericFormatProps,
          }}
          onBlur={props.onBlur}
          onFocus={(e) => {
            e.target.select()
            props.onFocus && props.onFocus(e as never)
          }}
          helperText={props.helperText || (props.saving && t('common:saving'))}
          error={props.error}
          disabled={props.disabled}
          label={props.label}
          variant={props.variant}
          required={props.required}
        />
      </span>
    </Tooltip>
  )
}

export { MoneyInput }
