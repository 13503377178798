import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useAppSelector } from 'store/configureStore'
import { useAppDispatch } from 'store/configureStore'
import { RootAppState } from 'store/configureStore'

export const useResourceIdFilter = () => {
  const dispatch = useAppDispatch()

  const resourceIdFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byResourceId,
    _.isEqual
  )

  const handleChangeFilter = (resourceId: string) => {
    const newFilter = { ...resourceIdFilter.filter }

    if (newFilter.enabledFilters.includes(resourceId)) {
      newFilter.enabledFilters = newFilter.enabledFilters.filter(
        (x) => x !== resourceId
      )
    } else {
      newFilter.enabledFilters = [resourceId]
    }

    if (!resourceId || newFilter.enabledFilters.length === 0) {
      dispatch(
        newProjectActions.toggleActiveFilter({
          filterType: 'ResourceIdFilter',
          open: false,
        })
      )
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'ResourceIdFilter',
            enabledFilters: newFilter.enabledFilters,
          },
        })
      )
    }
  }

  const filterIsActive = (resourceId: string) => {
    return (
      resourceIdFilter.active &&
      resourceIdFilter.filter.enabledFilters.some((x) => x === resourceId)
    )
  }

  return {
    handleChangeFilter,
    resourceIdFilter,
    filterIsActive,
  }
}
