import { WarningOutlined } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography,
} from '@mui/material'
import { ArticleSelector } from 'components/Common/ArticleSelector/ArticleSelectorControls/ArticleSelector'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { AppFeature } from 'features/AppModules/AppFeature'
import { FeatureKeys } from 'features/AppModules/FeatureKeys'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { t } from 'i18next'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { BomItemMaterialCalculatedPrice } from './components/BomItemCalculatedPrice'
import { BomItemCustomMaterialPrice } from './components/BomItemCustomMaterialPrice'
import { BomItemInfo } from './components/BomItemInfo'
import { UseArticleCatalogPriceSwitch } from './components/UseArticleCatalogPriceSwitch'
import { usePurchasingPartDialog } from './usePurchasingPartDialog'

export type PurchasingPartDialogProps = {
  bomItemPointer: BomItemPointer
  isOpen?: boolean
  onClose?: () => void
}

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
}))

export const PurchasingPartDialog = (props: PurchasingPartDialogProps) => {
  const { classes } = useStyles()

  const {
    customPrice,
    handleChangeUseArticleDefinedPrice,
    hasChanges,
    handleClose,
    handleSave,
    isPurchasingPart,
    loading,
    // partTypeIsPurchasing,
    storeCustomPrice,
    selectedArticle,
    useArticleDefinedPrice,
    purchasingActivityId,
  } = usePurchasingPartDialog(props.bomItemPointer, props.onClose)

  const isBuyer = useAppSelector(projectSelectors.projectInBuyerViewSelector)

  if (isBuyer) {
    return null
  }

  let isValid = true

  if (useArticleDefinedPrice && !selectedArticle?.id) {
    isValid = false
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={(e, r) => r !== 'backdropClick' && props.onClose()}
      fullWidth
      maxWidth={useArticleDefinedPrice ? 'xxl' : 'md'}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            {t('project:purchasing-data', 'purchasing data')}
          </Typography>
          <UseArticleCatalogPriceSwitch
            usingArticleCatalog={useArticleDefinedPrice}
            handleChangeUseArticleCatalog={handleChangeUseArticleDefinedPrice}
            selectedArticle={selectedArticle}
          />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          height: useArticleDefinedPrice ? '70vh' : 'auto',
        }}
      >
        <form
          id="form_purchasing_part"
          className={classes.content}
          onSubmit={(e) => {
            e.preventDefault()
            handleSave()
          }}
          style={{ width: '100%', height: '100%' }}
        >
          <div
            style={{
              flex: '33% 1 0',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <BomItemInfo bomItemPointer={props.bomItemPointer} />
            {isPurchasingPart && !useArticleDefinedPrice && (
              <BomItemCustomMaterialPrice
                bomItemPointer={props.bomItemPointer}
                onPriceChange={storeCustomPrice}
                customPrice={customPrice}
                usingArticleCatalog={useArticleDefinedPrice}
                activityId={purchasingActivityId}
              />
            )}
            {useArticleDefinedPrice && (
              <div>
                {selectedArticle?.id ? (
                  <>
                    <LocalizedTypography
                      translationKey="project:selected-article"
                      variant="caption"
                      color="GrayText"
                    >
                      selected article
                    </LocalizedTypography>
                    <Typography variant="body1">
                      {selectedArticle.description}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="secondary"
                      fontWeight={'bold'}
                    >
                      {selectedArticle.id}
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      gap: '1em',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <WarningOutlined color="warning" />
                    <LocalizedTypography
                      translationKey="project:select-an-article"
                      variant="body2"
                    >
                      select an article
                    </LocalizedTypography>
                  </div>
                )}
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingRight: '1rem',
                    paddingTop: '2rem',
                  }}
                >
                  <UseArticleCatalogPriceSwitch
                    usingArticleCatalog={useArticleDefinedPrice}
                    handleChangeUseArticleCatalog={
                      handleChangeUseArticleDefinedPrice
                    }
                    selectedArticle={selectedArticle}
                  />
                </div>
              </div>
            )}

            <AppFeature featureKey={FeatureKeys.quotation}>
              <div style={{ marginTop: 'auto' }}>
                <Divider />
                <div style={{ padding: '1em' }}>
                  <LocalizedTypography
                    translationKey="project:purchasing-data-calculated-prices"
                    variant="subtitle2"
                  >
                    calculated material prices:
                  </LocalizedTypography>
                  <BomItemMaterialCalculatedPrice
                    bomItemPointer={props.bomItemPointer}
                    activityId={purchasingActivityId}
                    hidePrices={hasChanges}
                  />
                </div>
              </div>
            </AppFeature>
          </div>

          {useArticleDefinedPrice && (
            <div style={{ width: '100%', height: '100%' }}>
              <ArticleSelector
                bomItemPointers={[props.bomItemPointer]}
                workingStepType={WorkingStepType.Purchasing}
                articleSelectorMaxHeight="calc(70vh - 64px)"
                initialKeywords={
                  [
                    {
                      originalKeyword: 'part',
                      category: 'shape',
                      translationKey: 'part',
                    },
                  ]
                  // partTypeIsPurchasing
                  //   ? undefined
                  //   : [
                  //       {
                  //         originalKeyword: 'part',
                  //         category: 'shape',
                  //         translationKey: 'part',
                  //       },
                  //     ]
                }
              />
            </div>
          )}
        </form>
      </DialogContent>

      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={handleClose}
          saveButtonFormId="form_purchasing_part"
          onSaveButtonClicked={handleSave}
          saveButtonDisabled={!isValid}
          saving={loading[`${props.bomItemPointer.id}`]}
          saveButtonTranslationKey={hasChanges ? 'common:save' : 'common:close'}
          cancelButtonInvisible={!hasChanges}
        />
      </DialogActions>
    </Dialog>
  )
}
