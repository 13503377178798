import { LinkedIn, Twitter, YouTube } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const SocialMedia = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <IconButton
        component="a"
        color="inherit"
        href="https://twitter.com/QuotationFact"
        target="_blank"
        rel="noreferrer"
      >
        <Twitter fontSize="large" />
      </IconButton>
      <IconButton
        component="a"
        color="inherit"
        href="https://www.linkedin.com/company/77644228"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedIn fontSize="large" />
      </IconButton>
      <IconButton
        component="a"
        color="inherit"
        href="https://www.youtube.com/@quotationfactory5654"
        target="_blank"
        rel="noreferrer"
      >
        <YouTube fontSize="large" />
      </IconButton>
    </Box>
  )
}
