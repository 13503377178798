import { CloudDownloadOutlined } from '@mui/icons-material'
import { downloadBomItemFiles } from 'features/BillOfMaterials/store/asyncActions/downloadBomItemFiles'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const DownloadBomItemFilesMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const isBuyer = useAppSelector(projectSelectors.projectInBuyerViewSelector)

  if (isBuyer) {
    return null
  }

  return (
    <BomItemMenuItem
      action={downloadBomItemFiles}
      actionParams={{ bomItemPointer }}
      primaryTextTranslationKey="project:download-files"
      secondaryTextTranslationKey="project:download-files-description"
      icon={<CloudDownloadOutlined />}
      closeAfterFullfilled
      onClose={onClose}
    />
  )
}
