import i18next from 'i18next'

export const valueGreaterThanZero = (value: string | number) => {
  if (parseFloat(value.toString()) <= 0) {
    return {
      isValid: false,
      errorMessage: i18next.t('common:validation-greater-than-zero'),
    }
  }

  return {
    isValid: true,
  }
}
