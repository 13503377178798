import { TextField, useTheme } from '@mui/material'
import { RenderEditCellProps } from 'react-data-grid'

export function TextFieldDataGridEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  maxLength,
}: RenderEditCellProps<TRow, TSummaryRow> & { maxLength?: number }) {
  const theme = useTheme()

  return (
    <TextField
      // variant="outlined"
      fullWidth
      onFocus={(e) => e.target.select()}
      value={row[column.key] || ''}
      onChange={(event) => {
        if (maxLength && event.target.value.length <= maxLength) {
          onRowChange({
            ...row,
            [column.key]: event.target.value,
          })
        } else {
          onRowChange({
            ...row,
            [column.key]: event.target.value.slice(0, maxLength),
          })
        }
      }}
      onBlur={() => onClose(true)}
      autoFocus
      inputProps={{
        style: {
          fontSize: theme.typography.caption.fontSize,
          paddingTop: theme.spacing(1.3),
          paddingLeft: theme.spacing(1),
        },
      }}
    />
  )
}
