import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'

export const InProductionProjectStatuses = [
  ProjectStateAsString.ORDERED,
  ProjectStateAsString.PRODUCING,
  ProjectStateAsString.PRODUCED,
  ProjectStateAsString.PACKAGING,
  ProjectStateAsString.PACKAGED,
  ProjectStateAsString.DELIVERING,
  ProjectStateAsString.DELIVERED,
]
