import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Box,
  CircularProgress,
  ClickAwayListener,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { SaveButton } from 'components/Common/Button/SaveButton'
import {
  KeywordWithTranslation,
  useRALColorAndTranslation,
} from 'components/RALSelector/useRALColorData'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { isEmpty } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, {
  CSSProperties,
  FocusEventHandler,
  useCallback,
  useRef,
} from 'react'
import {
  BoMItemActivityDto,
  KeywordDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ErrorMessage } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { KeywordTag } from './KeywordTag'
import { useActivityKeywords } from './useActivityKeywords'

const MaterialKeywordsOptions = (props: {
  baseElProps: React.HTMLAttributes<HTMLLIElement>
  option: KeywordWithTranslation
  state: AutocompleteRenderOptionState
}) => {
  const color = useRALColorAndTranslation(props.option.originalKeyword)

  const keywordAndColorName = props.option.translatedString

  const matches = match(keywordAndColorName || '', props.state.inputValue, {
    insideWords: true,
  })

  let colorTextParts: {
    text: string
    highlight: boolean
  }[]

  if (color) {
    const matchesColorName =
      color &&
      match(color.translatedName, props.state.inputValue, {
        insideWords: true,
      })

    colorTextParts = parse(color?.translatedName, matchesColorName)
  }

  const parts = parse(keywordAndColorName, matches)

  return (
    <Box
      component="li"
      {...props.baseElProps}
      key={props.option.originalKeyword}
      sx={{
        backgroundColor: color?.color.hex,
        color: (theme) =>
          color && theme.palette.getContrastText(color?.color.hex),
        '&.Mui-focused': {
          outline: color && `2px solid currentColor`,
          backgroundColor: (theme) =>
            `${color?.color.hex} !important` || theme.palette.action.hover,
          color: (theme) =>
            color &&
            `${theme.palette.getContrastText(color?.color.hex)} !important`,
          '& span': {
            fontWeight: (theme) =>
              `${theme.typography.fontWeightBold} !important`,
          },
        },
      }}
    >
      <Box
        component="span"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ flex: '1 1 20%' }}>
          <Typography variant="body2">
            {parts.map((part, index) => {
              return (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              )
            })}
          </Typography>
        </div>
        {color && (
          <Typography variant="body2" style={{ width: '100%' }}>
            {colorTextParts.map((part, index) => {
              return (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text.toLowerCase()}
                </span>
              )
            })}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({ name: 'BomItemActivityKeywordsInput' })(() => ({
  input: {
    minWidth: '0 !important',
  },
}))

export const BomItemActivityKeywordsInput = (props: {
  bomItemPointer: BomItemPointer
  activityId: string
  containerStyle?: CSSProperties
  disableLabel?: boolean
  disableUnderline?: boolean
  containerClassName?: string
  disabled?: boolean
  /**
   * optional handler to save the keywords when the user clicks on apply button
   * @param tokens
   * @returns
   */
  onSaveHandler?: (tokens: BoMItemActivityDto['keywords']) => void
  /**
   * if true will call the save handler when user selects a keyword
   */
  saveOnChange?: boolean
  /**
   * if true will start the input open
   */
  startOpen?: boolean
  onBlur?: FocusEventHandler
  /**
   * if you want to control the keywords from outside
   */
  initialKeywords?: BoMItemActivityDto['keywords']
  /**
   * shows only categories present in the array (like 'quality', 'materialgroup')
   */
  categoriesToShow?: string[]
  disableClickStopPropagation?: boolean
}) => {
  const { classes } = useStyles()

  const isProjectEditable = useAppSelector(ProjectEditableStateSelector)

  // const isBuyerView = useAppSelector(
  //   projectSelectors.projectInBuyerViewSelector
  // )

  // const isPurchasingPartType = useAppSelector(
  //   bomItemIsPurchasingPartSelector(props.bomItemPointer)
  // )

  // const incompleteMaterialIssue = useAppSelector(
  //   projectSelectors.bomItemIssueByCodeSelector(
  //     props.bomItemPointer,
  //     IssueCode.MaterialNotSet
  //   ),
  //   isEqual
  // )

  const {
    t,
    translateKeyword,
    loading,
    bomItemActivity,
    handleSaveKeywords,
    options,
    open,
    saving,
    setOpen,
    theme,
    handleKeyDown,
    handleClose,
    handleChange,
    isEditing,
    handleSaveArticle,
    savingArticle,
  } = useActivityKeywords({
    bomItemPointer: props.bomItemPointer,
    activityId: props.activityId,
    disableGetNewKeywords: false,
    onSaveHandler: props.onSaveHandler,
    saveOnChange: props.saveOnChange,
    startOpen: props.startOpen,
    initialKeywords: props.initialKeywords,
    categoriesToShow: props.categoriesToShow,
  })

  const autocompleteRef = useRef<HTMLDivElement>(undefined)
  const draggableRef = useRef(undefined)

  const handleDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    async (e) => {
      let dropedActivity: BoMItemActivityDto = null

      try {
        const _dropedItem = JSON.parse(
          e.dataTransfer.getData('text/plain')
        ) as unknown

        if (_dropedItem.hasOwnProperty('clipboardActivity')) {
          dropedActivity = _dropedItem[
            'clipboardActivity'
          ] as BoMItemActivityDto
        } else if (_dropedItem.hasOwnProperty('primaryWorkingStep')) {
          dropedActivity = _dropedItem as BoMItemActivityDto
        }
      } catch (err) {
        return null
      }

      try {
        if (
          dropedActivity.primaryWorkingStep !==
          bomItemActivity.primaryWorkingStep
        ) {
          ErrorMessage(
            t('project:keywords', 'keywords'),
            t(
              'project:activity-keywords-drop-to-same-working-step',
              'You can only drop keywords to the same working step'
            )
          )

          return
        }

        e.stopPropagation()

        const thicknessKeyword = bomItemActivity.keywords['dimensions']?.find(
          (x) => x.translationKey === 'thick'
        )?.translationArgument
        const dropedThicknessKeyword = dropedActivity.keywords[
          'dimensions'
        ]?.find((x) => x.translationKey === 'thick')?.translationArgument

        if (
          dropedActivity.article?.id &&
          (!thicknessKeyword || thicknessKeyword === dropedThicknessKeyword)
        ) {
          await handleSaveArticle(
            dropedActivity.article.id,
            Object.values({
              ...bomItemActivity.keywords,
              ...dropedActivity.keywords,
            }).flat(),
            bomItemActivity.usesRawMaterial
          )
        } else {
          delete dropedActivity.keywords['dimensions']
          delete dropedActivity.keywords['shape']
          handleChange(
            null,
            Object.values({
              ...bomItemActivity.keywords,
              ...dropedActivity.keywords,
            }).flat(),
            'selectOption'
          )
          autocompleteRef.current.querySelector('input').focus()
        }
      } catch (err) {
        console.warn(err)
        return
      }
    },
    [
      bomItemActivity.keywords,
      bomItemActivity.primaryWorkingStep,
      bomItemActivity.usesRawMaterial,
      handleChange,
      handleSaveArticle,
      t,
    ]
  )

  let controlLabel = undefined
  // let isErrorLabel = false

  if (bomItemActivity.article?.id) {
    controlLabel = `${bomItemActivity.article?.id}: ${bomItemActivity.article?.description}`
    // isErrorLabel = false
  } else {
    if (
      bomItemActivity?.primaryWorkingStep == null &&
      !isEmpty(bomItemActivity?.keywords)
    ) {
      controlLabel = t(
        'errors:missingRawMaterialWorkingStep',
        'working step using raw material is required'
      )
      // isErrorLabel = true
    } else if (
      bomItemActivity?.primaryWorkingStep !== WorkingStepType.Purchasing &&
      (!bomItemActivity.id || !bomItemActivity.article?.id)
    ) {
      controlLabel = t(
        'project:incomplete-material-input',
        'select a $t(project:material-category-materialgroup), $t(project:material-category-quality)'
      )
      // isErrorLabel = true
    } else {
      controlLabel = t('common:material', 'material') + ':'
      // isErrorLabel = false
    }
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          handleSaveKeywords(bomItemActivity?.keywords)
          setOpen(false)
        }
      }}
    >
      <div
        className={props.containerClassName}
        draggable={isProjectEditable}
        ref={draggableRef}
        id={`txt_keywords_${props.activityId}`}
        style={{
          flex: '1 1 auto',
          position: 'relative',
          ...props.containerStyle,
        }}
        onDragStart={(e) => {
          setOpen(false)
          e.dataTransfer.setData('text/plain', JSON.stringify(bomItemActivity))
        }}
        onDrag={(e) => {
          if (e.target !== draggableRef.current) {
            e.preventDefault()
          }
        }}
        onDrop={handleDrop}
        onClick={(e) =>
          !props.disableClickStopPropagation && e.stopPropagation()
        }
      >
        {props.disableLabel ? undefined : (
          <Typography
            variant="caption"
            // color={isErrorLabel ? 'error' : 'textSecondary'}
            color="textSecondary"
            component="label"
            className="MuiInputLabel-shrink"
          >
            {controlLabel}
          </Typography>
        )}

        <Autocomplete<KeywordDto, true, true, false>
          classes={{
            input: classes.input,
            // listbox: classes.listbox,
          }}
          disabled={!isProjectEditable || props.disabled}
          ref={autocompleteRef}
          multiple
          disableClearable={true}
          loading={
            options === undefined ||
            loading['GetBomItemActivityMaterials'] ||
            savingArticle ||
            saving?.requestStatus === 'pending'
          }
          loadingText={`${t('common:loading')}...`}
          open={open}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={(e) =>
            !props.disableClickStopPropagation && e.stopPropagation()
          }
          onOpen={() => setOpen(true)}
          onClose={() => handleClose()}
          options={options || []}
          onBlur={(e) => {
            handleSaveKeywords(bomItemActivity?.keywords)
            props.onBlur?.(e)
          }}
          onFocus={() => {
            setTimeout(() => setOpen(true))
          }}
          value={Object.values(bomItemActivity?.keywords || {}).flat() ?? []}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : translateKeyword(option)
          }
          groupBy={(option) => option.category}
          onKeyDown={handleKeyDown}
          openOnFocus
          fullWidth
          noOptionsText={t(
            'common:material-tokens-not-found',
            'no materials keywords available'
          )}
          autoHighlight
          autoSelect
          disableCloseOnSelect
          selectOnFocus
          onChange={(e, value, reason) => handleChange(e, value, reason)}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => {
            if (!option || !value) {
              return false
            }

            return option?.originalKeyword === value?.originalKeyword
          }}
          filterOptions={(options, state) => {
            return options.filter((option) => {
              if (state.inputValue === '') {
                return true
              }

              let translatedKeyword = translateKeyword(option)

              if (translatedKeyword.toLowerCase().startsWith('ral')) {
                translatedKeyword =
                  translatedKeyword +
                  ' ' +
                  t('colors:RAL_' + option.originalKeyword.substring(3))
              }

              const matches = match(translatedKeyword, state.inputValue, {
                insideWords: true,
              })

              return matches.length > 0
            })
          }}
          renderGroup={(params) => {
            return [
              <ListSubheader
                key={params.key}
                style={{
                  zIndex: 2,
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  borderTop: `1px solid ${theme.palette.divider}`,
                  top: '-8px',
                  lineHeight: '3em',
                }}
              >
                {t(`project:material-category-${params.group}`, params.group)}
              </ListSubheader>,
              params.children,
            ]
          }}
          renderTags={(options) => {
            return options.map((token, index) => {
              return (
                <KeywordTag
                  key={token.originalKeyword + index}
                  token={token}
                  translateFn={translateKeyword}
                />
              )
            })
          }}
          renderOption={(props, option, state) => (
            <MaterialKeywordsOptions
              baseElProps={props}
              option={option}
              state={state}
              key={option.originalKeyword}
            />
          )}
          renderInput={(params) => {
            if (savingArticle) {
              return <CircularProgress style={{ marginTop: '4px' }} size={20} />
            }
            return (
              <TextField
                {...params}
                ref={autocompleteRef}
                // error={isErrorLabel}
                error={false}
                disabled={!isProjectEditable}
                onClick={(e) => e.stopPropagation()}
                InputProps={{
                  ...params.InputProps,
                  autoFocus: props.startOpen,
                  disableUnderline:
                    !isProjectEditable ||
                    props.disableUnderline ||
                    props.disabled,
                  endAdornment:
                    !props.saveOnChange &&
                    (isEditing || saving?.requestStatus === 'pending') ? (
                      <>
                        <SaveButton
                          translationKey="common:apply"
                          size="small"
                          saving={loading['SetBomItemActivityKeywords']}
                          variant="text"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSaveKeywords(bomItemActivity?.keywords)
                          }}
                          style={{
                            marginLeft: 'auto',
                          }}
                        />
                        {params.InputProps.endAdornment}
                      </>
                    ) : (
                      params.InputProps.endAdornment
                    ),
                }}
              />
            )
          }}
        />
      </div>
    </ClickAwayListener>
  )
}
