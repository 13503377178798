import { bomItemAllActivitiesSelector } from './allBomItemActivitiesSelector'
import { bomItemActivityByArticleIdSelector } from './bomItemActivityByArticleIdSelector'
import { bomItemActivityByIdSelector } from './bomItemActivityByIdSelector'
import { bomItemActivityByWorkingStepSelector } from './bomItemActivityByWorkingStepSelector'
import { bomItemArticlePerWorkingStepSelector } from './bomItemArticlesPerWorkingStepSelector'
import { bomItemKeywordsPerWorkingStepSelector } from './bomItemKeywordsPerWorkingStepSelector'
import { bomItemRawMaterialActivitySelector } from './bomItemRawMaterialActivitySelector'

export const bomItemActivitiesSelectors = {
  allActivities: bomItemAllActivitiesSelector,
  byArticleId: bomItemActivityByArticleIdSelector,
  byWorkingStep: bomItemActivityByWorkingStepSelector,
  byActivityId: bomItemActivityByIdSelector,
  articlesPerWorkingStep: bomItemArticlePerWorkingStepSelector,
  keywordsPerWorkingStep: bomItemKeywordsPerWorkingStepSelector,
  rawMaterialActivity: bomItemRawMaterialActivitySelector,
}
