import { Typography } from '@mui/material'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { bomItemFileNameSelector } from '../store/selectors/bomItemFileNameSelector'

export const PartTypeFileName = ({
  bomItemPointer,
}: {
  bomItemPointer: BomItemPointer
}) => {
  const fileName = useAppSelector(bomItemFileNameSelector(bomItemPointer))

  if (!fileName || fileName === '') {
    return null
  }

  return (
    <Typography variant="caption" color="textSecondary">
      {`${fileName}`}
    </Typography>
  )
}
