import { ClickAwayListener, useTheme } from '@mui/material'
import { isEqual, uniq } from 'lodash'
import { memo, useState } from 'react'
import {
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  PriceRowDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { MoneyAxisTick } from '../YAxisTick'
import BarComponents from './BarComponents'
import { useGetGraphData } from './GetGraphData'
import PartTypeFinancialGraphXAxisTick from './PartTypeFinancialGraphXAxisTick'
import PartTypeFinancialGraphLegend from './RenderLegend'
import PartTypeFinancialGraphTooltip from './Tooltip/PartTypeFinancialGraphTooltip'

type Props = {
  bomItemPointer: BomItemPointer
  priceDetails: PriceRowDto[]
  priceScope: PriceScope
}

const PartTypeFinancialGraph = memo((props: Props) => {
  const theme = useTheme()
  const targetPrice = useAppSelector(
    projectSelectors.bomItemTargetPriceSelector(props.bomItemPointer)
  )
  const totalProjectQuantity = useAppSelector(
    projectSelectors.bomItemFinancialSelector(
      props.bomItemPointer,
      'totalProjectQuantity'
    )
  ) as number

  const targetPriceValue =
    targetPrice?.value *
    (props.priceScope === PriceScope.Total ? totalProjectQuantity : 1)

  const { workingStepFilter } = useWorkingStepFilter()
  const graphData = useGetGraphData(
    props.bomItemPointer,
    props.priceDetails,
    workingStepFilter?.filter?.enabledFilters,
    props.priceScope
  )

  const [tooltipActive, setTooltipActive] = useState(true)
  const [tooltipFixed, setTooltipFixed] = useState(false)

  if (!graphData) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={() => setTooltipActive(false)}>
      <div
        onClick={() => setTooltipFixed((curr) => !curr)}
        onMouseEnter={() => {
          setTooltipActive(true)
        }}
        onMouseLeave={() => {
          setTooltipActive(false)
        }}
      >
        <ResponsiveContainer width="100%" height={364}>
          <BarChart
            width={300}
            data={graphData}
            margin={{
              top: 8,
              right: 16,
              left: 16,
            }}
          >
            <defs>
              <pattern
                id="pattern-stripe"
                width="4"
                height="4"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect
                  width="1"
                  height="4"
                  transform="translate(0,0)"
                  fill="white"
                ></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-stripe)"
                />
              </mask>
            </defs>
            <CartesianGrid
              strokeDasharray="3 3"
              // onMouseEnter={() => {
              //   setTooltipActive(true)
              // }}
              // onMouseLeave={() => {
              //   setTooltipActive(false)
              // }}
            />

            <XAxis
              dataKey="resourceName"
              type="category"
              dy={100}
              interval={0}
              tickMargin={20}
              height={100}
              tick={(props) => {
                return <PartTypeFinancialGraphXAxisTick {...props} />
              }}
              onMouseOver={() => setTooltipActive(false)}
            />
            <YAxis
              type="number"
              ticks={uniq(
                graphData
                  ?.map((x) => x.totals?.salesPrice?.value)
                  .concat(targetPriceValue)
                  .sort((a, b) => b - a)
              )}
              minTickGap={0}
              tickLine={true}
              interval={'preserveStart'}
              allowDataOverflow={true}
              tick={
                <MoneyAxisTick
                  defaultMoney={graphData?.[0]?.totals.salesPrice}
                />
              }
            />
            {targetPrice?.value && (
              <ReferenceLine
                y={targetPriceValue}
                stroke={theme.palette.primary.main}
                strokeWidth={2}
              />
            )}
            <Legend
              content={(lengendProps) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { key, ...rest } = lengendProps
                return (
                  <PartTypeFinancialGraphLegend
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...(rest as any)}
                    bomItemType={props.bomItemPointer.type}
                  />
                )
              }}
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{
                padding: '20px',
              }}
            />
            <Tooltip
              content={(tooltipProps) => {
                return (
                  <PartTypeFinancialGraphTooltip
                    {...tooltipProps}
                    tooltipFixed={tooltipFixed}
                    setTooltipFixed={setTooltipFixed}
                    bomItemType={props.bomItemPointer.type}
                  />
                )
              }}
              trigger={tooltipFixed === true ? 'click' : 'hover'}
              active={tooltipActive}
              position={{
                y: -150,
              }}
              offset={30}
              cursor={{
                fill: theme.palette.action.hover,
              }}
              wrapperStyle={{
                zIndex: 1000,
                maxHeight: '400px',
                overflowY: 'auto',
                pointerEvents: 'auto',
                border: `4px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
              }}
            />
            {BarComponents({
              data: graphData,
            })}
            {/* <BarComponents data={graphData} /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </ClickAwayListener>
  )
}, isEqual)

PartTypeFinancialGraph.displayName = 'PartTypeFinancialGraph'

export default PartTypeFinancialGraph
