import { CheckOutlined, CloseOutlined } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { DefinitiveQuantityField } from 'components/DefinitiveInputs/DefinitiveQuantityField'
import { DefinitiveInputValidators } from 'components/DefinitiveInputs/Validators'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { TimeString } from 'model/TimeString'
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormatProps } from 'react-number-format'
import {
  ManuallySetProductionTimeArgs,
  PriceRowDto,
  PriceScope,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { Key } from 'utils/keyCodes'
import { useFinancialDetailsInputStyles } from './FinancialDetailsInput.styles'

export const ProdTimeInput = (props: {
  bomItemPointer: BomItemPointer
  priceRow: PriceRowDto
  priceScope: PriceScope
  handleSaveManuallySetProductionTime: (
    bomItemPointer: BomItemPointer,
    args: ManuallySetProductionTimeArgs
  ) => Promise<void>
  handleResetProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  loading?: Record<string, boolean>
  variant?: 'body2' | 'caption'
}) => {
  const { classes } = useFinancialDetailsInputStyles()
  const { t } = useTranslation()

  const [editing, setEditing] = useState(false)
  // const [changed, setChanged] = useState(false)

  const changed = useRef(false)

  const [productionTime, setProductionTime] = useState<QuantityDto>()
  // props.priceRow?.productionTime

  const handleClose = useCallback(() => {
    handleSetEditing(false)
    setProductionTime(
      props.priceRow?.productionTimeManuallySet ||
        props.priceRow?.productionTime
    )
  }, [
    props.priceRow?.productionTime,
    props.priceRow?.productionTimeManuallySet,
  ])

  const handleSave: FormEventHandler = useCallback(
    async (e) => {
      try {
        e?.preventDefault()

        if (!changed.current) {
          handleClose()
          return
        }

        if (productionTime.value > 0 && Boolean(productionTime.unit)) {
          setProductionTime((curr) => {
            return {
              ...curr,
              value: productionTime.value,
            }
          })

          await props.handleSaveManuallySetProductionTime(
            props.bomItemPointer,
            {
              primaryWorkingStep: props.priceRow.workingStep.primaryWorkingStep,
              secondaryWorkingStep:
                props.priceRow.workingStep.secondaryWorkingStep,
              productionTime: productionTime,
              priceScope: props.priceScope,
            }
          )

          handleSetEditing(false)
        }
      } catch (ex) {
        ShowException('Failed to edit production time', ex)
      }
    },
    [changed, handleClose, productionTime, props]
  )

  useEffect(() => {
    const handleDocEscape = (ev: KeyboardEvent) => {
      if (ev.ctrlKey && ev.key === 's') {
        ev.preventDefault()
        setTimeout(() => {
          handleSave(null)
        })
      }

      switch (ev.key) {
        case Key.Escape:
          handleClose()
          break
        case Key.Enter:
          setTimeout(() => {
            handleSave(null)
          })
          break
      }
    }

    document.addEventListener('keydown', handleDocEscape)

    return () => {
      document.removeEventListener('keydown', handleDocEscape)
    }
  }, [handleClose, handleSave])

  useEffect(() => {
    if (!editing && !changed.current) {
      setProductionTime(
        props.priceRow?.productionTimeManuallySet ||
          props.priceRow?.productionTime
      )
    }
  }, [
    editing,
    props.priceRow?.productionTimeManuallySet,
    props.priceRow?.productionTime,
  ])

  const getValueOrDefault = (productionTimeArg) =>
    !productionTimeArg || isNaN(productionTimeArg.value)
      ? { value: 0, unit: 'sec' }
      : productionTimeArg

  const handleChange = (newQuantity: QuantityDto) => {
    changed.current = true
    setProductionTime(newQuantity)
  }

  const handleSetEditing = (isEditing: boolean) => {
    setEditing(isEditing)
    globalThis.isDirty = isEditing

    if (!isEditing) {
      changed.current = false
      // setChanged(false)
    }
  }

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  // shared costs should not be editable
  if (!editing || props.priceRow.costsAreShared) {
    const data = getValueOrDefault(productionTime)
    if (data.value === null) {
      data.value = 0
    }

    return (
      <div style={{ textAlign: 'right', position: 'relative', width: '100%' }}>
        <Tooltip
          title={
            <>
              {`${t('project:prod-time')}: ${productionTime?.value || 0} ${
                productionTime?.unit
              }`}{' '}
            </>
          }
          placement="top"
        >
          <span style={{ textAlign: 'inherit' }}>
            <ValueLabel
              onClick={
                !projectIsEditable || props.priceRow.costsAreShared
                  ? undefined
                  : () => handleSetEditing(true)
              }
              valueProps={{
                variant: props.variant || 'body2',
              }}
              // label={'prod time'}
              value={TimeString(getValueOrDefault(productionTime))}
            />
          </span>
        </Tooltip>

        {props.priceRow.productionTimeManuallySet && (
          <Tooltip
            title={
              <Typography variant="caption">
                {t('project:reset-production-time', {
                  time: TimeString(
                    getValueOrDefault(props.priceRow.productionTime)
                  ),
                })}
              </Typography>
            }
            placement="bottom"
          >
            <Button
              style={{
                position: 'absolute',
                right: 0,
                bottom: '-1.25em',
                padding: 0,
                margin: 0,
                minHeight: 0,
                minWidth: 0,
              }}
              color="inherit"
              className={classes.resetValueButton}
              onClick={() =>
                props.handleResetProductionTime(
                  props.bomItemPointer,
                  props.priceRow
                )
              }
            >
              {props.loading[
                `delete-manual-production-time-${props.priceRow.workingStep.primaryWorkingStep}-${props.priceRow.workingStep.secondaryWorkingStep}`
              ] ? (
                <CircularProgress size={16} />
              ) : (
                <Typography variant="caption" color="GrayText">
                  {TimeString(getValueOrDefault(props.priceRow.productionTime))}{' '}
                </Typography>
              )}
            </Button>
          </Tooltip>
        )}
      </div>
    )
  } else {
    return (
      <form
        onSubmit={handleSave}
        onKeyDown={(ev) => {
          if (ev.ctrlKey && ev.key === 's') {
            ev.preventDefault()
            setTimeout(() => handleSave(null))
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '.5rem',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <DefinitiveQuantityField
            onChange={(newQuantity: QuantityDto) => handleChange(newQuantity)}
            quantityDto={productionTime}
            typographyVariant={props.variant || 'body2'}
            disableWarningsIcon
            numberFormatProps={
              {
                decimalScale: 0,
                allowNegative: false,
                min: 0.01,
                thousandSeparator: true,
              } as NumericFormatProps
            }
            selectTextOnFirstRender
            isAllowed={DefinitiveInputValidators.IsMinMaxAllowed(0, 999999)}
            validation={(quantityDto) => {
              const validations = DefinitiveInputValidators.CombineValidators([
                DefinitiveInputValidators.IsRequired(quantityDto.value),
                DefinitiveInputValidators.MinValue(quantityDto.value, 0),
                DefinitiveInputValidators.MaxValue(
                  quantityDto.value,
                  1_000_000
                ),
              ])

              if (validations?.length > 0) {
                changed.current = false
              }

              return validations
            }}
            textFieldProps={{
              autoFocus: true,
            }}
          />

          {props.loading[
            `manual-estimation-${props.priceRow.workingStep.primaryWorkingStep}-${props.priceRow.workingStep.secondaryWorkingStep}`
          ] ? (
            <CircularProgress size={20} style={{ display: 'inline-flex' }} />
          ) : (
            <>
              <IconButton
                type="submit"
                color={'primary'}
                size="small"
                disabled={!changed}
              >
                <CheckOutlined />
              </IconButton>
              <IconButton type="reset" onClick={handleClose} size="small">
                <CloseOutlined />
              </IconButton>
            </>
          )}
        </div>
      </form>
    )
  }
}
