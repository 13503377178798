import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { bomItemThumbnailSelector } from './store/selectors/bomItemThumbnailSelector'

export function useBomItemThumbnailImageSrc(
  bomItemPointer: BomItemPointer,
  show2dModel?: boolean
) {
  const { partyId, projectId } = useOrganizationAndProjectContext()

  const thumbnailFilename = useAppSelector(
    (state: RootAppState) =>
      bomItemThumbnailSelector(state, bomItemPointer, show2dModel),
    isEqual
  )

  const imageSrc = thumbnailFilename
    ? projectImageUrl(partyId, projectId, thumbnailFilename)
    : ''

  return {
    thumbnailFilename,
    imageSrc,
  }
}
