/* eslint-disable tss-unused-classes/unused-classes */
import { FormControlLabel, Switch } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { MaterialSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { usePurchasingDataDialogFormControlStyles } from './usePurchasingDataDialogFormControlStyles'

type Props = {
  usingArticleCatalog: boolean
  handleChangeUseArticleCatalog: (useArticleCatalog: boolean) => void
  selectedArticle: MaterialSummaryDto
}

const useStyle = makeStyles({ name: 'usingArticleCatalog' })((theme) => ({
  articleDescription: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
  },
}))

export const UseArticleCatalogPriceSwitch = (props: Props) => {
  const { classes } = usePurchasingDataDialogFormControlStyles()
  const { classes: articleClasses } = useStyle()

  return (
    <div>
      <div className={classes.formControlRoot}>
        <FormControlLabel
          control={
            <Switch
              checked={props.usingArticleCatalog}
              onChange={(e, checked) =>
                props.handleChangeUseArticleCatalog(checked)
              }
            />
          }
          labelPlacement="start"
          label={
            <LocalizedTypography
              translationKey="project:purchasing-data-use-prices-from-article"
              variant="caption"
            >
              use prices from article catalog
            </LocalizedTypography>
          }
        />
      </div>
      {/* {props.usingArticleCatalog && (
        <div className={articleClasses.articleDescription}>
          {props.selectedArticle?.id ? (
            <>
              <Typography variant="body1">
                {props.selectedArticle.description}
              </Typography>
              <Typography variant="caption" color="GrayText">
                {props.selectedArticle.id}
              </Typography>
            </>
          ) : (
            <span style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <WarningOutlined />
              <Typography variant="body2">select an article</Typography>
            </span>
          )}
        </div>
      )} */}
    </div>
  )
}
