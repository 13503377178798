import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { createSelector } from 'reselect'
import {
  FinancialDto,
  SalesPersonDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { ProjectState } from './ProjectTypes'

function MaterialsSelector(state: RootAppState) {
  return state.materials
}

function ProjectDataSelector(state: RootAppState) {
  return state.project.activeProject
}

function GetProjectFilters(state: RootAppState) {
  return state.project.filters
}

function GetSalesRepr(state: RootAppState) {
  return state.project.activeProject?.salesPerson
}
const HasActiveFilter =
  (filtersToIgnore: Array<keyof ProjectState['filters']> = []) =>
  (state: RootAppState) => {
    return Object.entries(state.project?.filters || {})
      .filter(([key]) => {
        return !filtersToIgnore?.includes(key as keyof ProjectState['filters'])
      })
      .some(([, value]) => value.active === true)
  }

// function HasActiveFilter(state: RootAppState) {
//   return Object.values(state.project?.filters || {}).some(
//     (x) => x.active === true
//   )
// }

// function IssuesFilterSelector(state: RootAppState) {
//   return (state.newProjectReducer.filters.find(x => x.type === 'IssuesFilter') as IssuesFilter)
// }

// function WorkingStepsFilterSelector(state: RootAppState) {
//   return (state.newProjectReducer.filters.find(x => x.type === 'WorkingStepsFilter') as WorkingStepFilter)
// }

// function MaterialsFilterSelector(state: RootAppState) {
//   return (state.newProjectReducer.filters.find(x => x.type === 'MaterialKeywordsFilter') as MaterialKeywordsFilter)
// }

type projectStateSelector = {
  PasteMaterialSelectorFromClipboardIsDisabled: (state: RootAppState) => boolean
  ProjectIdSelector: (state: RootAppState) => string
  IssuesCountSelector: (state: RootAppState) => number
  IssuesFilterSelector: (
    state: RootAppState
  ) => ProjectState['filters']['byIssues']
  MaterialFilterSelector: (
    state: RootAppState
  ) => ProjectState['filters']['byMaterialKeywords']
  WorkingStepsFilter: (
    state: RootAppState
  ) => ProjectState['filters']['byWorkingStep']
  SalesRepresentant: (state: RootAppState) => SalesPersonDto
  HasActiveFilter: (
    filtersToIgnore?: Array<keyof ProjectState['filters']>
  ) => (state: RootAppState) => boolean
}

export const ProjectStateSelectors: projectStateSelector = {
  PasteMaterialSelectorFromClipboardIsDisabled: createSelector(
    MaterialsSelector,
    (materialSelector) => materialSelector.activitiesClipboard === undefined
  ),
  ProjectIdSelector: createSelector(
    ProjectDataSelector,
    (activeProject) => activeProject?.id
  ),
  IssuesFilterSelector: createSelector(
    GetProjectFilters,
    (issues) => issues.byIssues
  ),
  IssuesCountSelector: createSelector(
    GetProjectFilters,
    (issues) => issues.byIssues?.filter?.availableFilters?.length
  ),
  MaterialFilterSelector: createSelector(
    GetProjectFilters,
    (filters) => filters.byMaterialKeywords
  ),
  WorkingStepsFilter: createSelector(
    GetProjectFilters,
    (filters) => filters.byWorkingStep
  ),
  SalesRepresentant: createSelector(GetSalesRepr, (x) => x),
  HasActiveFilter: createSelector(HasActiveFilter, (x) => x),
}

export const bomItemFinancialSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): FinancialDto | null => {
    if (!state.project.activeProject?.id) {
      return null
    }

    if (!bomItemPointer) return null
    if (bomItemPointer.id === state.project?.activeProject?.id) {
      return state.project.activeProject.financial
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return null
    }

    switch (bomItem?.type) {
      case BomItemType.assemblyInstance: {
        return bomItem.financial
      }
      case BomItemType.assemblyType: {
        return bomItem.financial
      }
      case BomItemType.materialHeader: {
        return null
      }
      case BomItemType.partType:
      case BomItemType.partInstance: {
        return bomItem.financial
      }
      default: {
        return bomItem['financial']
      }
    }
  }
