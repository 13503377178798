import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import { ActionIconButton } from 'components/Layout/ActionIconButton'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { AdditionalWorkingStepsDialogPropsV2 } from 'features/WorkingSteps/AdditionalWorkingStepsV2/DialogV2/AdditionalWorkingStepsDialogPropsV2'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/configureStore'

export function AddWorkingStepButton(props: {
  bomItemPointers: BomItemPointer[]
}) {
  const { openDialog } = useDialogManager()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)

  const setWorkingStepsDialogOpen = useCallback(() => {
    if (!props.bomItemPointers.length) {
      return
    }

    if (props.bomItemPointers.length === 1) {
      dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointers?.[0]))
    }

    switch (props.bomItemPointers[0]?.type) {
      case BomItemType.partInstance: {
        return
      }
      case BomItemType.project: {
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: props.bomItemPointers,
        } as AdditionalWorkingStepsDialogPropsV2)
        return
      }
      default: {
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: props.bomItemPointers,
          activeWorkingStep: undefined,
        } as AdditionalWorkingStepsDialogPropsV2)
      }
    }
  }, [dispatch, openDialog, props.bomItemPointers])

  if (!projectIsEditable) {
    return null
  }

  return (
    <Tooltip
      title={t('project:working-steps-add-button')}
      arrow
      placement="top"
    >
      <ActionIconButton
        size="small"
        onClick={() => {
          setWorkingStepsDialogOpen()
        }}
      >
        <FontAwesomeIcon icon={faTasks} />
      </ActionIconButton>
    </Tooltip>
  )
}
