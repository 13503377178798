import { useTheme } from '@mui/material'
import { uniq } from 'lodash'
import React from 'react'
import { Bar } from 'recharts'
import { FinancialDetailsData } from './GetGraphData'

export const useBarColorMap = () => {
  const theme = useTheme()
  return {
    material: theme.palette.grey[400],
    workingStepSetup: theme.palette.info.light,
    workingSteps: theme.palette.info.main,
    workingStepUnload: theme.palette.warning.light,
    surcharge: theme.palette.success.main,
    parts: theme.palette.info.dark,
    getContrastText: theme.palette.getContrastText,
  }
}

const BarComponents = (props: { data: FinancialDetailsData[] }) => {
  const theme = useTheme()
  const colorMap = useBarColorMap()

  const includedDataKeys: Array<{
    barPosition: number
    barName: string
    barColor: string
    dataKey: string
  }> = []

  const barComponents: Array<React.ReactNode> = []

  const isAnimationActive = props.data.length < 15

  props.data.forEach((x) => {
    Object.keys(x.costFactors).forEach((key) => {
      const dataKey: string[] = []

      if (key === 'workingSteps') {
        x.costFactors.workingSteps?.forEach((y, j) => {
          dataKey.push(`costFactors.workingSteps[${j}].costPrice.value`)
        })
      } else if (key.includes('parts')) {
        x.costFactors['parts']?.forEach((y, j) => {
          dataKey.push(`costFactors.parts[${j}].costPrice.value`)
        })
      } else if (key.includes('assemblies')) {
        x.costFactors.parts?.forEach((y, j) => {
          dataKey.push(`costFactors.parts[${j}].costPrice.value`)
        })
      } else {
        dataKey.push(`costFactors.${key}.costPrice.value`)
      }

      return uniq(dataKey.flat()).map((costFactorKey, keyIndex) => {
        let barName = ''
        let barColor = ''
        let barPosition = 1

        if (key === 'workingSteps') {
          barName = `${x.costFactors.workingSteps?.[keyIndex].workingStepType}`
          barColor = colorMap.workingSteps
          barPosition = 3
        } else if (key.includes('parts')) {
          barName = `${x.costFactors.parts?.[keyIndex].description}`
          barColor = colorMap.parts
          barPosition = 0
        } else if (key.includes('assemblies')) {
          barName = `${x.costFactors.parts?.[keyIndex].description}`
          barColor = colorMap.material
          barPosition = 0
        } else {
          switch (key) {
            case 'material':
              barColor = colorMap.material
              barPosition = 1
              break
            case 'workingStepSetup':
              barColor = colorMap.workingStepSetup
              barPosition = 2
              break
            case 'workingStepUnload':
              barColor = colorMap.workingStepUnload
              barPosition = 4
              break
          }

          barName = key
        }

        if (
          includedDataKeys.findIndex((x) => x.dataKey === costFactorKey) === -1
        ) {
          includedDataKeys.push({
            barName,
            barColor,
            dataKey: costFactorKey,
            barPosition,
          })
        }
      })
    })
  })

  includedDataKeys
    .sort((a, b) => a.barPosition - b.barPosition)
    .forEach((x) => {
      barComponents.push(
        <Bar
          isAnimationActive={isAnimationActive}
          key={x.dataKey}
          dataKey={x.dataKey}
          stackId={'a'}
          fill={x.barColor}
          maxBarSize={54}
          name={x.barName}
          stroke={theme.palette.grey['500']}
          strokeWidth={1}
        />
      )
    })

  barComponents.push(
    <Bar
      isAnimationActive={isAnimationActive}
      key={'totals.surchargeValue.value'}
      dataKey="totals.surchargeValue.value"
      stackId={'a'}
      fill={theme.palette.success.main}
      maxBarSize={54}
      name="surcharge"
      stroke={theme.palette.grey['500']}
      strokeWidth={1}
    />
  )

  barComponents.push(
    <Bar
      isAnimationActive={isAnimationActive}
      key="totals.discountValue.value"
      dataKey="totals.discountValue.value"
      stackId={'a'}
      mask="url(#mask-stripe)"
      maxBarSize={54}
      name="discount"
      stroke={theme.palette.grey['500']}
      strokeWidth={1}
    />
  )

  return <>{barComponents}</>
}

export default BarComponents
