/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import chroma from 'chroma-js'
import { makeStyles } from 'tss-react/mui'

export const FINANCIAL_DETAILS_VALUE_LABEL_WIDTH = '100%'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useFinancialDetailsFormStyles = makeStyles()((theme: Theme) => ({
  root: {
    // padding: theme.spacing(2),
    // '& > li:nth-child(even)': {
    //   backgroundColor: mix(
    //     theme.palette.background.paper,
    //     theme.palette.action.selected,
    //     0.1
    //   )
    //     .alpha(0.5)
    //     .hex(),
    // },
  },
  secondaryWorkingStep: {
    paddingLeft: theme.spacing(4),
  },
  highlight: {
    backgroundColor: theme.palette.action.hover,
  },
  sharedCosts: {
    backgroundColor: chroma(theme.palette.info.light).alpha(0.1).hex(),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
  },
  headerAction: {
    marginLeft: 'auto',
  },
  row: {
    padding: theme.spacing(0.5, 0, 0.5, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    ['&:hover']: {
      backgroundColor: theme.palette.action.selected,
    },
  },
  totalRow: {
    borderTop: `2px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.action.hover,
  },
  values: {
    position: 'relative',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1.2fr 1fr 1fr 1fr 1fr 24px',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: theme.spacing(2),
  },
  resourceLabel: {
    width: '100%',
    padding: theme.spacing(0, 1),
  },
  resourceDescription: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  resetValueButton: {
    cursor: 'pointer',
    ['&:hover']: {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))
