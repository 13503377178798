import { MenuItem, TextField, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FieldProps } from 'react-jsonschema-form'
import { makeStyles } from 'tss-react/mui'
import { CorrosionMap } from './CorrosionMap'

const useStyles = makeStyles({ name: 'CorrosionClassificationField' })(
  (theme) => ({
    tooltip: {
      maxWidth: 'none',
      backgroundColor: theme.palette.background.paper,
    },
  })
)

export function CorrosionClassificationField(props: FieldProps<string>) {
  const { t } = useTranslation('project')
  const { classes } = useStyles()

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={
          <CorrosionMap
            onChange={(corrosionClassification) => {
              props.onChange(corrosionClassification)
            }}
            value={props.formData}
          />
        }
        placement="right"
      >
        <TextField
          select
          label={t(props.uiSchema['ui:label-translation-key'])}
          value={props.formData}
          onChange={(e) => props.onChange(e.target.value)}
          fullWidth
        >
          {props.schema['x-enumNames'].map((option: string) => (
            <MenuItem key={option} value={option}>
              {t('project:corrosion-' + option, option)}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
    </div>
  )
}
