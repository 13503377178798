import { TextFieldProps, useTheme } from '@mui/material'
import { saveOrderNumber } from 'features/BillOfMaterials/store/asyncActions/saveOrderNumber'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { projectOrderNumberSelector } from 'features/BillOfMaterials/store/selectors/projectOrderNumberSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'

export function ProjectOrderNumberInput(props: {
  textFieldProps?: TextFieldProps
}) {
  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)
  const isBuyerParty = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )
  const theme = useTheme()
  const { t } = useTranslation()
  const editable = useAppSelector(projectSelectors.ProjectEditableStateSelector)

  const InputProps = {
    readOnly: !projectIsEditable || isBuyerParty,
    disableUnderline: true,
  }

  return (
    <ProjectTextField
      valueSelector={projectOrderNumberSelector}
      saveAction={saveOrderNumber}
      textFieldProps={{
        title: t('project:order'),
        placeholder: t('project:order'),
        variant: 'standard',
        InputProps: InputProps,
        disabled: !editable,
        inputProps: {
          style: {
            fontSize: theme.typography.body2.fontSize,
            padding: 0,
            color: theme.palette.text.secondary,
          },
          maxLength: 50,
        },

        ...props.textFieldProps,
      }}
    />
  )
}
