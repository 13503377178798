import {
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import './LanguageButton.scss'
import { LocalizedTypography } from './LocalizedTypography'
import SupportedLanguages from './SupportedLanguages.json'

export const LanguageButton = (props: {
  className?: string
  variant?: 'button' | 'menu-item'
}) => {
  const { i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const btnRef = React.useRef(undefined)

  const languageData =
    SupportedLanguages.ui_languages[i18n.language] ||
    SupportedLanguages.ui_languages[i18n.language.split('-')[0]] ||
    SupportedLanguages.ui_languages.en

  const LanguageMenu = useCallback(() => {
    const changeLanguage = (
      e: React.MouseEvent<HTMLLIElement>,
      languageKey: keyof typeof SupportedLanguages.ui_languages
    ) => {
      setMenuOpen(false)

      e.bubbles = false
      e.preventDefault()

      setLoading(true)

      i18n.changeLanguage(
        SupportedLanguages.ui_languages[languageKey].lang_code,
        () => {
          moment.locale(
            SupportedLanguages.ui_languages[languageKey].culture_code
          )
          setLoading(false)
          setMenuOpen(false)
        }
      )

      const fix = languageKey.split('-')[0]
        ? languageKey.split('-')[0].toUpperCase()
        : 'en'

      window['Beamer']?.update({
        language: fix,
      })
    }

    return (
      <Menu
        className="language-button-menu"
        anchorEl={btnRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <div className="language-button-menu--title">
          <LocalizedTypography
            translationKey={'common:language'}
            translationDefaultValue={'language'}
            variant="h6"
          />
        </div>
        {Object.keys(SupportedLanguages.ui_languages)?.map((langKey) => {
          if (SupportedLanguages.ui_languages[langKey].hidden) return null

          return (
            <MenuItem
              key={langKey}
              id={langKey}
              onClick={(e) => {
                e.bubbles = false
                e.preventDefault()
                changeLanguage(
                  e,
                  langKey as keyof typeof SupportedLanguages.ui_languages
                )
              }}
              selected={langKey === languageData?.code}
            >
              <ListItemIcon>
                <Avatar
                  src={SupportedLanguages.ui_languages[langKey].flag}
                  alt={langKey}
                  style={{
                    height: '24px',
                    width: '24px',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={SupportedLanguages.ui_languages[langKey].description}
              />
            </MenuItem>
          )
        })}
      </Menu>
    )
  }, [i18n, languageData?.code, menuOpen])

  if (props.variant === 'menu-item') {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.bubbles = false
            e.preventDefault()
            setMenuOpen(() => true)
          }}
          ref={btnRef}
        >
          <ListItemIcon>
            {loading ? (
              <Skeleton
                variant="circular"
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
            ) : (
              <Avatar
                src={languageData?.flag}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary={languageData?.description} />
        </MenuItem>
        <LanguageMenu />
      </>
    )
  }

  return (
    <>
      <Button variant="text" onClick={() => setMenuOpen(true)} ref={btnRef}>
        {loading ? (
          <Skeleton
            variant="circular"
            style={{ width: '1em', height: '1em' }}
          />
        ) : (
          <Avatar
            src={languageData?.flag}
            style={{ width: '1em', height: '1em', marginRight: '.5em' }}
          />
        )}
        {loading ? (
          <Skeleton variant="text" />
        ) : (
          <ListItemText primary={languageData?.description} />
        )}
      </Button>
      <LanguageMenu />
    </>
  )
}
