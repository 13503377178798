import { Box, Typography } from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { t } from 'i18next'
import { QuantityString } from 'model/Quantity'
import { useRef } from 'react'
import { FeatureDetailDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { SideActionsButtons } from './components/SideActionsButtons'
import { useFeatureTreeStyles } from './useFeatureTreeStyles'
import { useModelSelectedFacesChanged } from './useModelSelectedFacesChanged'

type Props = {
  featureDetail: FeatureDetailDto
  modelController: ModelController
  onOpen: () => void
  isOpen?: boolean
  faceIdToFilter?: number
  variant?: string
  notIncludedFaceIds?: number[]
  onReload: () => void
}

export const FeatureTreeItem = (props: Props) => {
  const { classes, cx } = useFeatureTreeStyles()
  const divRef = useRef<HTMLDivElement>(null)
  const { hasSelectedFace } = useModelSelectedFacesChanged(
    props.featureDetail.faceIds,
    props.modelController,
    props.onOpen,
    divRef,
    props.isOpen,
    true
  )

  return (
    <Box
      ref={divRef}
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        padding: (theme) =>
          props.variant === 'small' ? theme.spacing(0.5) : theme.spacing(1),
        paddingLeft: (theme) => {
          return theme.spacing(1)
        },
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
      className={cx({
        [classes.activeSection]: props.variant !== 'small' && hasSelectedFace,
      })}
    >
      <Typography
        variant="caption"
        style={{ fontWeight: 'normal', flex: '1 1 auto' }}
        className={cx({
          [classes.selectFacesButton]:
            props.featureDetail.faceIds?.length > 0 &&
            props.featureDetail.faceIds?.length < 100,
        })}
        // title={'faceIds: ' + props.featureDetail.faceIds.join(',')}
        onClick={() => {
          if (!props.featureDetail.faceIds?.length) return
          props.modelController?.selectNodesById(
            props.featureDetail.faceIds.map((x) => x.toString()),
            'FACE'
          )
        }}
      >
        {props.featureDetail.label}
      </Typography>
      <SideActionsButtons
        featureDetail={props.featureDetail}
        notIncludedFaceIds={props.notIncludedFaceIds}
        modelController={props.modelController}
        onReload={props.onReload}
      />
      <Typography variant="caption">
        {props.featureDetail.quantityValue && (
          <Typography variant="caption">
            {QuantityString(props.featureDetail.quantityValue)}
          </Typography>
        )}
        {props.featureDetail.boolValue !== null && (
          <Typography variant="caption">
            {props.featureDetail.boolValue
              ? t('common:yes', 'yes').toLowerCase()
              : t('common:no', 'no').toLowerCase()}
          </Typography>
        )}
        {props.featureDetail.intValue && (
          <Typography variant="caption">
            {props.featureDetail.intValue}
          </Typography>
        )}
      </Typography>
    </Box>
  )
}
