/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useBomItemDetailsDrawerStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: `calc(${theme.breakpoints.values.sm}px + 250px)`,
    height: '100vh',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(5),
    maxWidth: `85vw`,
    overflowX: 'hidden',
    transition: 'transform 0.2s ease-in-out',
  },
  resizeBorder: {
    width: '2px',
    border: `2px solid ${theme.palette.divider}`,
    // height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
    '&:hover': {
      cursor: 'ew-resize',
    },
  },
  header: {
    padding: theme.spacing(2),
    height: theme.spacing(7),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 9,
    top: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: 'currentColor',
  },
  bomItemDescription: {
    // display: 'flex',
    position: 'sticky',
    top: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
    padding: theme.spacing(1, 1, 1, 2),
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actions: {
    padding: theme.spacing(0, 2),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    // height: theme.spacing(3),
  },
  picture: {
    padding: 0,
    flex: '0 0 84px',
  },
  description: {
    flex: '1 1 100%',
    alignItems: 'center',
    gap: '1em',
  },
  graph: {
    // height: '220px',
    // height: '100%',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  prices: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1, 2),
    width: '100%',
  },
  unitSelector: {
    display: 'flex',
    padding: theme.spacing(1, 2, 0, 2),
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  partTypeInfo: {
    padding: theme.spacing(0, 2),
  },
  materialKeywords: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'flex-end',
  },
  formDetails: {
    border: `1px solid ${theme.palette.divider}`,
    // minHeight: '250px',
    overflowY: 'auto',
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  workingStepsActions: {
    display: 'flex',
    alignItems: 'baseline',
  },
  workingSteps: {
    display: 'flex',
    gap: theme.spacing(2),
    overflowX: 'auto',
    alignItems: 'center',
  },
  bomItemIssues: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
  },
  bomItemIssuesContainer: {
    width: '100%',
  },
  loading: {
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previousItemButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'currentColor',
  },
  nextItemButton: {
    position: 'absolute',
    bottom: 0,
    right: '2em',
    color: 'currentColor',
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  workingStepsRouteIcons: {
    alignItems: 'stretch',
    display: 'flex',
    gap: theme.spacing(2),
  },
  detailsContainer: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginTop: theme.spacing(1),
    minHeight: 0,
    '& .MuiTab-root': {
      minHeight: 0,
      padding: theme.spacing(0.5),
    },
  },
  priceAndCuttingPlansContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))
