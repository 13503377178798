export enum FeatureKeys {
  kanbanBoard = 'kanbanBoard',
  quotation = 'quotation',
  projectStateManagement = 'projectStateManagement',
  salesRepresentative = 'salesRepresentative',
  bomItemDetails = 'bomItemDetails',
  routesView = 'routesView',
  materialsView = 'materialsView',
  bomView = 'bomView',
  portal = 'portal',
  mixPanelDashboard = 'mixPanelDashboard',
  dashboardView = 'dashboardView',
  integrations = 'integrations',
}
