import { BoMItemBadge } from 'features/BillOfMaterials/BoMItemBadge/BoMItemBadge'
import { BoMItemBadgeList } from 'features/BillOfMaterials/BoMItemBadge/BoMItemBadgeList'
import { bomItemExportedToErpSelector } from 'features/BillOfMaterials/store/selectors/bomItemExportedToErpSelector'
import { bomItemManufacturabilitySelector } from 'features/BillOfMaterials/store/selectors/bomItemManufacturability'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RunnableTaskStatus } from 'model/Project/BoMItemRow'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'

type Props = {
  bomItemPointer: BomItemPointer
  disableManufacturability?: boolean
  disableERP?: boolean
  listStyles?: React.CSSProperties
  showOnlyFailed?: boolean
}

export const BadgeList = (props: Props) => {
  const { t } = useTranslation()

  const manufacturability = useAppSelector(
    bomItemManufacturabilitySelector(props.bomItemPointer),
    isEqual
  )
  const exported = useAppSelector(
    bomItemExportedToErpSelector(props.bomItemPointer),
    isEqual
  )
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  if (
    exported.exportedStatus === 'NotApplicable' &&
    manufacturability.status === 'NotApplicable'
  ) {
    return null
  }

  const exportFailed =
    (exported.exportedStatus === 'Done' ||
      exported.exportedStatus === 'Failed') &&
    exported.isExported === false

  if (props.showOnlyFailed && !exportFailed) {
    return null
  }

  return (
    <BoMItemBadgeList alwaysVisible listStyle={props.listStyles}>
      {!props.disableManufacturability &&
      Boolean(manufacturability?.status) &&
      manufacturability.status !== 'NotApplicable' ? (
        <BoMItemBadge
          status={manufacturability?.status as RunnableTaskStatus}
          category={t(
            'project:badge-category-manufacturability',
            'manufacturability'
          )}
          runningLabel={t(
            `project:runnable-task-status-${manufacturability.status}`,
            manufacturability.status.toLowerCase()
          )}
          running={
            manufacturability.status.toLowerCase() === 'running' ||
            manufacturability.status.toLowerCase() === 'requested'
          }
          finishedLabel={
            manufacturability.isManufacturable
              ? t('project:manufacturable-success', 'manufacturable')
              : t('project:manufacturable-fail', 'not manufacturable')
          }
          title={manufacturability.result}
        />
      ) : null}
      {!props.disableERP &&
      !isBuyerView &&
      Boolean(exported?.exportedStatus) &&
      exported.exportedStatus !== 'NotApplicable' ? (
        <BoMItemBadge
          status={exported.exportedStatus as RunnableTaskStatus}
          category={t('project:badge-category-erp', 'ERP')}
          runningLabel={t(
            `project:runnable-task-status-${exported.exportedStatus}`,
            exported.exportedStatus.toLowerCase()
          )}
          finishedLabel={
            exported?.isExported
              ? t('project:exported-to-erp', 'exported')
              : t('project:exported-to-erp-error', 'not exported')
          }
          running={
            exported?.exportedResult?.toLowerCase() === 'running' ||
            exported?.exportedResult?.toLowerCase() === 'requested'
          }
          title={exported?.exportedResult}
        />
      ) : null}
    </BoMItemBadgeList>
  )
}
