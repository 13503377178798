import { Button, TextFieldProps, Typography, useTheme } from '@mui/material'
import { saveProjectReference } from 'features/BillOfMaterials/store/asyncActions/saveProjectReference'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { projectReferenceSelector } from 'features/BillOfMaterials/store/selectors/projectReferenceSelector'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { ProjectReferenceEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { projectOrderNumberSelector } from '../store/selectors/projectOrderNumberSelector'

export function ProjectReferenceInput(props: {
  textFieldProps?: TextFieldProps
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const editable = useAppSelector(ProjectReferenceEditableStateSelector)

  const InputProps = {
    disableUnderline: true,
  }

  return (
    <ProjectTextField
      valueSelector={projectReferenceSelector}
      saveAction={saveProjectReference}
      textFieldProps={{
        title: t('project:project-reference'),
        placeholder: t('project:project-reference'),
        required: true,
        variant: 'standard',
        disabled: !editable,
        // eslint-disable-next-line
        InputProps: InputProps as any,
        inputProps: {
          style: {
            fontSize: theme.typography.h6.fontSize,
            padding: 0,
            fontWeight: theme.typography.fontWeightBold,
          },
          maxLength: 35,
        },
        ...props.textFieldProps,
      }}
    />
  )
}

export const ProjectReferenceButton = () => {
  const projectReference = useAppSelector(projectReferenceSelector)
  const orderNumber = useAppSelector(projectOrderNumberSelector)

  const { openDialog } = useDialogManager()

  const handleOpenProjectDetails = () => {
    openDialog('ProjectDetailsDialog')
  }

  return (
    <Button
      onClick={handleOpenProjectDetails}
      variant="text"
      style={{
        textTransform: 'none',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        paddingLeft: '1em',
        paddingRight: '1em',
        height: '100%',
        justifyContent: 'space-around',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h6"
        color="textPrimary"
        noWrap
        sx={{
          fontWeight: (theme) => theme.typography.fontWeightBold,
          maxWidth: '100%',
          textOverflow: 'ellipsis',
        }}
      >
        {projectReference}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        noWrap
        sx={{
          maxWidth: '100%',
          textOverflow: 'ellipsis',
        }}
      >
        {orderNumber}
      </Typography>
    </Button>
  )
}
