import { Button, ButtonProps } from '@mui/material'
import { TOptions } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalizationKey } from './useLocalizationKey'

export type LocalizedButtonProps = Omit<ButtonProps, 'children'> & {
  translationKey: string
  defaultText?: string
  titleKey?: string
  tOptions?: TOptions
  children?: (text: string) => React.ReactElement
  icon?: React.ReactElement
}

export const LocalizedButton = (props: LocalizedButtonProps) => {
  const {
    translationKey,
    defaultText,
    tOptions,
    titleKey,
    children,
    icon,
    ...buttonProps
  } = props

  const { namespace, tKey } = useLocalizationKey(translationKey)

  const { t } = useTranslation(namespace)

  const translatedText = t(tKey, tOptions || { defaultValue: defaultText })

  return (
    <Button
      style={{ alignItems: 'flex-start' }}
      title={t(titleKey)}
      startIcon={icon}
      {...buttonProps}
    >
      {props.children ? (
        <span>{children(translatedText)}</span>
      ) : (
        <span>{translatedText}</span>
      )}
    </Button>
  )
}
