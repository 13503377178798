/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/**
 * based on https://css-tricks.com/examples/DragAndDropFileUploading/
 */

// TODO: Implement internationalization;

import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { FileWithDetails } from 'model/FileWithDetails'
import React, { useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import dragndrop from '../../../_icons/project-menu/drag-n-drop.png'
import { ErrorMessage } from '../../../store/Application/appActions'
import { ExpandedSummary } from './ExpandedSummary'
import './FileUploader.sass'

type OwnProps = {
  acceptedExtensions: Accept
  uploadProgress: number
  onFileUploaded: (files: File[] | FileWithDetails[]) => void
  setFiles: (files: File[]) => void
  files: File[]
  layout?: 'expanded' | 'small'
  cleanError?: () => void
  onlyOneFile?: boolean
}

const FileUploader = (props: OwnProps) => {
  const [isClickEnabled, setIsClickEnabled] = useState(true)

  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedExtensions,
    onDropAccepted: (accepted) => {
      if (
        props.onlyOneFile &&
        (accepted?.length > 1 || props.files?.length > 1)
      ) {
        ErrorMessage('upload', `you should upload one file.`)
      } else {
        props.setFiles([...props.files, ...accepted])
      }
    },
    onDropRejected: (rejected) => {
      ErrorMessage('upload', `rejected ${rejected.length} file(s)`)
    },
    noClick:
      !isClickEnabled ||
      (props.uploadProgress > 0 && props.uploadProgress < 100),
  })

  const onFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    props.onFileUploaded(props.files)
  }

  return (
    <div className="file-uploader--container">
      <form encType="multimidia/form-data" onSubmit={onFormSubmit}>
        <input
          id="file"
          className="box-file"
          name="files[]"
          data-multiple-caption={`${
            props.files && props.files.length
          } files selected`}
          multiple={false}
          type="file"
          {...getInputProps()}
        />
        {/* <div className="box" style={{ marginBottom: '0px' }}>
            <div className="box-input"> */}
        {(!props.files || props.files.length === 0) && (
          <div className="upload-files-empty" {...getRootProps()}>
            <img src={dragndrop} alt="drag'n'drop a file" />

            <label htmlFor="file">
              <strong>
                {t(
                  'project:upload-choose-a-file',
                  'choose a file or drag it here'
                )}
              </strong>
            </label>
          </div>
        )}
        {props.files.length > 0 && (
          <div className="small-upload" {...getRootProps()}>
            <img src={dragndrop} alt="drag'n'drop a file" />
            <LocalizedTypography
              translationKey="project:upload-choose-a-file"
              variant="body2"
              color="textSecondary"
            />
          </div>
        )}
        {!props.layout || props.layout === 'expanded' ? (
          <ExpandedSummary
            files={props.files}
            setFiles={props.setFiles}
            setIsClickEnabled={setIsClickEnabled}
            uploadProgress={props.uploadProgress}
          />
        ) : (
          <SmallSummary
            files={props.files}
            setFiles={props.setFiles}
            setIsClickEnabled={setIsClickEnabled}
            uploadProgress={props.uploadProgress}
          />
        )}
        {/* {props.uploadProgress > 0 && props.uploadProgress < 100 && (
                <progress
                  className="progress is-info"
                  value={props.uploadProgress}
                  max="100"
                >
                  {`${props.uploadProgress}%`}
                </progress>
              )} */}
        {/* </div>
          </div> */}
        {/* </div> */}
      </form>
    </div>
  )
}

export default FileUploader

export type UploadSummaryProps = {
  files: File[]
  setFiles: (files: File[]) => void
  setIsClickEnabled: React.Dispatch<React.SetStateAction<boolean>>
  uploadProgress: number
}

const SmallSummary = (props: UploadSummaryProps) => {
  if (!props.files || props.files.length === 0) return null
  return (
    <div className="small-summary">
      <span>{props.files.length} file(s)</span>
      <button
        type="button"
        title="remove all files"
        className="delete"
        onClick={(e) => {
          e.preventDefault()
          props.setFiles([])
          props.setIsClickEnabled(true)
        }}
        onMouseOver={() => props.setIsClickEnabled(false)}
        onFocus={() => props.setIsClickEnabled(false)}
        onMouseOut={() => props.setIsClickEnabled(true)}
        onBlur={() => props.setIsClickEnabled(true)}
      />
    </div>
  )
}
