import { ChecklistOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type Props = {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}
export const MaterialOrManualPriceNotSetTodo = (props: Props) => {
  const { openDialog } = useDialogManager()
  const { getIssueDescription } = useBomItemIssueDescription()

  const handleOpenPurchasingPartDialog = () => {
    openDialog('PurchasingPartDialog', {
      bomItemPointer: props.bomItemPointer,
      isOpen: true,
    } as PurchasingPartDialogProps)
  }

  return (
    <div>
      <Button
        component="li"
        onClick={handleOpenPurchasingPartDialog}
        color="inherit"
        startIcon={<ChecklistOutlined />}
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {getIssueDescription(props.issue)}
      </Button>
    </div>
  )
}
