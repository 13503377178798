import { Box, Tooltip, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { useBomItemThumbnailImageSrc } from '../useBomItemThumbnailImageSrc'
import { BomItemDialogFinancials } from './BomItemDialogFinancials'

const useBomItemDialogTitle = makeStyles()((theme) => ({
  container: {
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr',
    display: 'flex',
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
  },
  itemName: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  imgs: {
    display: 'flex',
    gap: theme.spacing(2),
    img: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
}))

export const BomItemDialogTitle = (props: {
  bomItemPointers: BomItemPointer[]
  dialogTitle: string
  showMaterialSelector?: boolean
}) => {
  const { classes } = useBomItemDialogTitle()
  const bomItemNames = useAppSelector(
    projectSelectors.bomItemsNamesSelector(props.bomItemPointers),
    isEqual
  )
  const { imageSrc: threeDModel } = useBomItemThumbnailImageSrc(
    props.bomItemPointers[0]
  )
  const { imageSrc: twoDthumbnail } = useBomItemThumbnailImageSrc(
    props.bomItemPointers[0],
    true
  )

  const bomItemName = bomItemNames.join(', ')

  return (
    <div className={classes.container}>
      <div className={classes.imgs}>
        {threeDModel && (
          <img
            loading="lazy"
            src={threeDModel}
            alt={`${bomItemNames[0]} 3D Model`}
          />
        )}

        {twoDthumbnail && twoDthumbnail !== threeDModel && (
          <img
            loading="lazy"
            src={twoDthumbnail}
            alt={`${bomItemNames[0]} 2D Thumbnail`}
          />
        )}
      </div>
      <div className={classes.itemName}>
        <Tooltip title={bomItemName} placement="top" arrow>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography
              variant="h6"
              sx={{ p: 0, maxWidth: (theme) => theme.breakpoints.values.md }}
              noWrap
            >
              {bomItemName}
            </Typography>
            {props.bomItemPointers.length > 1 && (
              <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                {props.bomItemPointers.length} items
              </Typography>
            )}
          </Box>
        </Tooltip>

        <Typography variant="caption">{props.dialogTitle}</Typography>
      </div>
      <BomItemDialogFinancials bomItemPointers={props.bomItemPointers} />
    </div>
  )
}
