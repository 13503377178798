import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { SearchType } from 'controllers/Search/SearchBomItemsController'
import React from 'react'

export const FilterExplanation = (props: {
  search(searchText: string, searchType: SearchType)
}) => {
  return (
    <span>
      <LocalizedTypography
        translationKey="search:filter-explanation-title"
        component="p"
      >
        Filter BoM Items by
      </LocalizedTypography>
      <Typography variant="caption">
        OrderNumber | SalesPrice | CostPrice | Currency | SellingPartyName |
        BuyingPartyName | ArticleNumber | ArticleDescription | Type
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <LocalizedTypography translationKey="search:filter-explanation-sample-queries">
                sample queries
              </LocalizedTypography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            hover
            onClick={() => {
              props.search("OrderNumber eq '<ordernumber>'", 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">
                {`OrderNumber eq '<order number>'`}
              </Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-order-number-desc"
              >
                Shows all items related to the specified order number
              </LocalizedTypography>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              props.search('SalesPricePerItem gt 30.00', 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">
                SalesPricePerItem gt 30.00
              </Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-sales-price-explanation"
              >
                BoM items with Sales Price greater than 30.00
              </LocalizedTypography>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              props.search('CostPricePerItem lt 40.00', 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">CostPricePerItem lt 40.00</Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-cost-price-explanation"
              >
                BoM items with Cost Price less than 40.00
              </LocalizedTypography>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              props.search('DiscountOrSurchargeRatio eq -5', 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">
                DiscountOrSurchargeRatio eq -5
              </Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-discount"
              >
                BoM items with Discount of -5%
              </LocalizedTypography>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              props.search("ArticleNumber eq '1001-24500'", 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">{`ArticleNumber eq '1001-24500'`}</Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-article-number-explanation"
              >{`BoM items with ArticleNumber equal '1001-24500'`}</LocalizedTypography>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              props.search("Currency eq 'EUR'", 'filter')
            }}
          >
            <TableCell>
              <Typography variant="body2">{`Currency eq 'EUR'`}</Typography>
            </TableCell>
            <TableCell>
              <LocalizedTypography
                variant="body2"
                translationKey="search:filter-explanation-sample-query-currency-explanation"
              >{`BoM items with 'EUR' currency`}</LocalizedTypography>
            </TableCell>
          </TableRow>
          {/* <TableRow hover onClick={() => { props.search("search.ismatch('Metal Heaven*', 'SellingPartyName')", 'filter') }}>
                        <TableCell>
                            <Typography variant="body2">{`search.ismatch('Metal Heaven*', 'SellingPartyName')`}</Typography >
                        </TableCell>
                        <TableCell>
                            <LocalizedTypography variant="body2" translationKey="search:filter-explanation-sample-query-selling-party-name-explanation">{`BoM items with SellingPartyName starting with 'Metal Heaven'`}</LocalizedTypography >
                        </TableCell>
                    </TableRow> */}
        </TableBody>
      </Table>
      <LocalizedTypography
        translationKey="search:filter-explanation-logical-operations"
        variant="caption"
        component="p"
      >
        the following logical operators are available for each filterable field:
      </LocalizedTypography>
      <Typography
        variant="caption"
        component="p"
        style={{ paddingLeft: '1em' }}
      >
        <strong>eq</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-eq"
        >
          equal
        </LocalizedTypography>{' '}
        <br />
        <strong>neq</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-neq"
        >
          not equal
        </LocalizedTypography>{' '}
        <br />
        <strong>gt</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-gt"
        >
          greater than
        </LocalizedTypography>{' '}
        <br />
        <strong>ge</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-ge"
        >
          greater than or equal to
        </LocalizedTypography>{' '}
        <br />
        <strong>lt</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-lt"
        >
          less than
        </LocalizedTypography>{' '}
        <br />
        <strong>le</strong>:{' '}
        <LocalizedTypography
          variant="inherit"
          translationKey="search:filter-explanation-le"
        >
          less than or equal to
        </LocalizedTypography>{' '}
        <br />
        {/* <strong>{`search.ismatch('<search text>', '<search field>')`}</strong>: <LocalizedTypography translationKey="search:filter-explanation-ismatch">full text search in a search field</LocalizedTypography> */}
      </Typography>

      <LocalizedTypography
        html
        variant="caption"
        translationKey="search:filter-explanation-combine"
        component="p"
      >
        {`combine expressions with <strong>and</strong> and <strong>or</strong> keywords. (eg. ArticleNumber eq '1001-24500' and DiscountOrSurcharge eq -5)`}
      </LocalizedTypography>
      <LocalizedTypography
        html
        translationKey="search:filter-explanation-type"
        variant="caption"
        component="p"
      >
        {`use the <strong>Type</strong> field to filter results by <strong>'Project', 'Assembly' or 'Row'</strong>. (eg. search.ismatch('Metal Heaven*', 'SellingPartyName') and Type eq 'Assembly'`}
      </LocalizedTypography>
    </span>
  )
}
