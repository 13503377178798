import { RequestStatus } from '../BaseAPI'
import { InternalAPI, baseAddress } from './InternalAPI'
import {
  GetSelectableCustomersResponse,
  PartyDto,
  PartySearchResult,
} from './internal-api.contracts'

const partyDetailsCache: Record<string, PartyDto> = {}

export class SearchPartyAPI extends InternalAPI {
  constructor(private partyId: string) {
    super()
    this.baseAddress += `/api/parties/${partyId}/search`
  }

  public GetPartyDetails = async (
    partyId: string,
    onRequestChange: (requestStatus: Partial<RequestStatus>) => void
  ) => {
    if (!partyDetailsCache[partyId]) {
      partyDetailsCache[partyId] = await this.GetAsync<PartyDto>({
        relativePath: `/${partyId}`,
        onRequestChange,
      })
    }

    return partyDetailsCache[partyId]
  }

  public SearchPortalParties = async (
    searchText: string,
    onRequestChange: (requestStatus: Partial<RequestStatus>) => void
  ) => {
    return await this.GetAsync<GetSelectableCustomersResponse>({
      relativePath: undefined,
      uri: `${baseAddress}/api/parties/${this.partyId}/customer-portal/party-invites/selectable-customers`,
      params: {
        s: searchText,
      },
      onRequestChange,
    })
  }

  public SearchParty = async (
    searchText: string,
    onRequestChange: (requestStatus: Partial<RequestStatus>) => void
  ) =>
    await this.GetAsync<PartySearchResult[]>({
      relativePath: '',
      onRequestChange,
      params: {
        s: searchText,
      },
    })
}
