import {
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { SearchParty } from 'components/Common/SearchParty/SearchParty'
import { QuantityField } from 'components/Common/UnitEditor/QuantityField'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectAddressForm } from 'features/ProjectDetailsInfo/components/ProjectAddressForm'
import { t } from 'i18next'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useContactPerson, useCreateProjectForm } from './useCreateProjectForm'

type Props = {
  onClose: (reason: string) => void
}

export const CreateProjectForm = (props: Props) => {
  const { contactPerson, handleChangeContactPerson } = useContactPerson()

  const {
    classes,
    cx,
    formRef,
    contactFirstNameRef,
    loading,
    postalAddress,
    setPostalAddress,
    deliveryAddress,
    setDeliveryAddress,
    setSelectedBuyer,
    buyerPartyName,
    setBuyerPartyName,
    vatRatio,
    setVatRatio,
    postalAddressAsDeliveryAddress,
    setPostalAddressAsDeliveryAddress,
    handleCreateProject,
    isBuyerView,
    sellingParties,
  } = useCreateProjectForm()

  const titleTranslationKey = isBuyerView
    ? 'project:new-quotation-request'
    : 'project:new-project'

  const isSeller = !isBuyerView

  return (
    <Dialog
      open
      onClose={(e, reason) =>
        reason === 'escapeKeyDown' && props.onClose(reason)
      }
      // onClose={props.onClose}
      fullWidth
      maxWidth="lg"
      disableRestoreFocus
    >
      <DialogTitle>
        <LocalizedTypography
          translationKey={titleTranslationKey}
          variant="h6"
        />
      </DialogTitle>

      <form
        ref={formRef}
        className={classes.rootContent}
        id="create-project-form"
        onSubmit={(e) => handleCreateProject(e, contactPerson)}
      >
        <div className={cx(classes.content, classes.projectIdent)}>
          {isSeller && (
            <TextField
              onFocus={(e) => e.target.select()}
              label={t('project:order-number')}
              name="orderNumber"
              required
              inputProps={{
                maxLength: 50,
                autoFocus: isSeller,
              }}
              style={{
                width: '40ch',
              }}
            />
          )}

          <TextField
            onFocus={(e) => e.target.select()}
            label={t('project:project-reference')}
            name="projectReference"
            inputProps={{
              autoFocus: isBuyerView,
              maxLength: 50,
            }}
            required
            fullWidth
          />
          {isSeller && (
            <QuantityField
              label={t('project:vat-ratio-no-unit')}
              name="vatRatio"
              required
              value={vatRatio}
              decimalScale={3}
              fixedDecimalScale={false}
              onChange={(value: QuantityDto) => setVatRatio(value)}
              error={isNaN(vatRatio?.value)}
              helperText={isNaN(vatRatio?.value) ? t('common:required') : ''}
            />
          )}
          {isBuyerView && (
            <TextField
              onFocus={(e) => e.target.select()}
              select
              label={t('common:supplier')}
              name="supplier"
              required
              disabled={!sellingParties || sellingParties?.length <= 1}
              value={
                sellingParties?.length === 1 ? sellingParties[0].legalName : ''
              }
              inputProps={{
                maxLength: 50,
              }}
              fullWidth
            >
              {(sellingParties || [])?.map((party) => (
                <option key={party.id} value={party.legalName}>
                  {party.legalName}
                </option>
              ))}
            </TextField>
          )}
        </div>

        {isSeller && (
          <div className={classes.content}>
            <div className={classes.buyerCompanyTitle}>
              <LocalizedTypography
                translationKey="project:buying-party"
                variant="subtitle1"
                className={classes.title}
              >
                buyer company
              </LocalizedTypography>
              {isSeller && (
                <SearchParty
                  className={classes.autoComplete}
                  TextFieldProps={{
                    InputProps: {
                      disableUnderline: true,
                    },
                    variant: 'outlined',
                    size: 'small',
                  }}
                  onPartyDetailsReceived={(buyerParty) => {
                    setSelectedBuyer(buyerParty)
                    setBuyerPartyName(buyerParty.legalName)

                    setPostalAddress(buyerParty.address)
                    setDeliveryAddress(buyerParty.address)
                    setPostalAddressAsDeliveryAddress(true)

                    setVatRatio(buyerParty.vatRatio)

                    contactFirstNameRef.current?.focus()
                  }}
                  onPartyDetailsCleaned={() => {
                    setSelectedBuyer(null)
                    setPostalAddress(null)
                    setDeliveryAddress(null)
                    setPostalAddressAsDeliveryAddress(true)
                    setBuyerPartyName('')
                  }}
                />
              )}
            </div>
            <TextField
              onFocus={(e) => e.target.select()}
              name="buyerPartyName"
              fullWidth
              label={t('project:buyer-company-name', 'company name')}
              required
              value={buyerPartyName}
              onChange={(e) => setBuyerPartyName(e.target.value)}
              inputProps={{
                maxLength: 50,
              }}
            />
          </div>
        )}

        <div className={classes.content}>
          <div className={classes.title}>
            <LocalizedTypography
              translationKey="project:contact-person"
              variant="subtitle1"
              className={classes.title}
            >
              contact person
            </LocalizedTypography>
          </div>
          <div className={classes.contactPerson}>
            <TextField
              onFocus={(e) => e.target.select()}
              name="firstName"
              onChange={(e) =>
                handleChangeContactPerson('firstName', e.currentTarget.value)
              }
              value={contactPerson?.firstName || ''}
              fullWidth
              inputRef={contactFirstNameRef}
              inputProps={{
                maxLength: 50,
              }}
              label={t('common:first-name')}
            />
            <TextField
              onFocus={(e) => e.target.select()}
              name="lastName"
              value={contactPerson?.lastName || ''}
              onChange={(e) =>
                handleChangeContactPerson('lastName', e.currentTarget.value)
              }
              fullWidth
              inputProps={{
                maxLength: 50,
              }}
              label={t('common:last-name')}
            />
            <TextField
              onFocus={(e) => e.target.select()}
              name="email"
              type="email"
              value={contactPerson?.email || ''}
              onChange={(e) =>
                handleChangeContactPerson('email', e.currentTarget.value)
              }
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
              label={t('common:email')}
            />
          </div>
        </div>
        <div className={classes.content}>
          <ProjectAddressForm
            address={postalAddress ?? {}}
            setAddress={setPostalAddress}
            titleText={t('common:postal-address', 'postal address')}
            classes={{ titleText: classes.title }}
            addressRequired
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={postalAddressAsDeliveryAddress}
                onChange={(e) =>
                  setPostalAddressAsDeliveryAddress(e.target.checked)
                }
                size="small"
              />
            }
            label={
              <LocalizedTypography
                translationKey="common:use-postal-address-as-delivery-address"
                variant="body2"
                color="primary"
              >
                use postal address as delivery address
              </LocalizedTypography>
            }
          />
        </div>
        <div className={classes.content}>
          <Collapse in={postalAddressAsDeliveryAddress === false}>
            <div className={classes.content}>
              <ProjectAddressForm
                address={deliveryAddress ?? {}}
                setAddress={setDeliveryAddress}
                titleText={t('common:delivery-address', 'delivery address')}
                classes={{ titleText: classes.title }}
              />
            </div>
          </Collapse>
        </div>
      </form>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={() => props.onClose('closeButtonClicked')}
          saveButtonFormId="create-project-form"
          saving={loading['create-project']}
          saveButtonTranslationKey="common:create"
        />
      </DialogActions>
    </Dialog>
  )
}
