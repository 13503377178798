import { Box } from '@mui/material'
import AssemblyKeywords from 'components/ProjectPage/BillOfMaterials/Rows/Assembly/AssemblyKeywords/AssemblyKeywords'
import { AddWorkingStepButton } from 'components/ProjectPage/BillOfMaterials/Rows/Components/RowWorkingSteps/WorkingSteps/AddWorkingStepButton'
import { AssemblyHeaderMenuButton } from 'features/BillOfMaterials/BoMItemMenu/AssemblyHeaderMenuButton'
import { BadgeList } from 'features/BillOfMaterials/BoMItemMenu/MenuItems/BadgeList'
import { BomItemRoutingData } from 'features/BillOfMaterials/RoutingHeader/BomItemRoutingData'
import { BomItemName } from 'features/BillOfMaterials/components/BomItemName'
import { BomItemPricePerBatch } from 'features/BillOfMaterials/components/BomItemPricePerBatch'
import { BomItemQuantity } from 'features/BillOfMaterials/components/BomItemQuantity'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import { BomItemTotalProjectQuantity } from 'features/BillOfMaterials/components/BomItemTotal'
import { BomItemTotalPrice } from 'features/BillOfMaterials/components/BomItemTotalPrice'
import { CommentForm } from 'features/BillOfMaterials/components/CommentForm/CommentForm'
import { useBomItemPricePerBatchStyles } from 'features/BillOfMaterials/components/useBomItemPricePerBatchStyles'
import { BomItemType } from 'model/Project/BoMItemRow'
import { AssemblyHeaderPointer } from 'model/Project/BomItemPointer'
import { useBomItemDetailsDrawerStyles } from '../BomItemDetailsDrawer.styles'
import { BomItemTargetPriceInput } from '../Inputs/BomItemTargetPriceInput'
import { BomItemPriceContainer } from './BomItemPriceContainer'
import { BomItemDetailsProps } from './ProjectDetails/BomItemDetailsProps'

export const AssemblyDetails = (props: BomItemDetailsProps) => {
  const { classes: sharedClasses } = useBomItemDetailsDrawerStyles()
  const { classes: priceClasses } = useBomItemPricePerBatchStyles({
    fullWidth: false,
  })

  return (
    <>
      <div className={sharedClasses.bomItemDescription}>
        <div style={{ display: 'flex' }}>
          <RowThumbnailButton
            bomItemPointer={props.bomItemPointer}
            size="large"
            pictureClassName={sharedClasses.picture}
          />
          <div className={sharedClasses.description}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: (theme) => theme.spacing(2),
              }}
            >
              <BomItemName
                bomItemPointer={props.bomItemPointer}
                asLabel
                valueLabelProps={{
                  label: '',
                  valueProps: {
                    variant: 'h6',
                  },
                }}
              />
              <AssemblyHeaderMenuButton
                bomItemPointer={props.bomItemPointer as AssemblyHeaderPointer}
              />
            </Box>
            <div className={sharedClasses.prices}>
              <BomItemTargetPriceInput
                bomItemPointer={props.bomItemPointer}
                priceScope={'perUnit'}
                textFieldProps={{
                  className: priceClasses.moneyTextField,
                  inputProps: {
                    style: {
                      textAlign: 'right',
                    },
                  },
                }}
              />
              <BomItemQuantity
                bomItemPointer={props.bomItemPointer}
                translationKey={
                  props.bomItemPointer.type === BomItemType.partType
                    ? 'common:quantity'
                    : 'project:batch-size'
                }
                className={priceClasses.batchSize}
              />
              <BomItemTotalProjectQuantity
                bomItemPointer={props.bomItemPointer}
              />
              <BomItemPricePerBatch
                bomItemPointer={props.bomItemPointer}
                valueLabelProps={{
                  isLeftAligned: true,
                }}
              />
              <BomItemTotalPrice bomItemPointer={props.bomItemPointer} />
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: (theme) => theme.spacing(1, 2, 0, 0),
          width: '100%',
        }}
      >
        <BadgeList bomItemPointer={props.bomItemPointer} />
      </Box>
      <div
        className={sharedClasses.unitSelector}
        style={{ width: '100%', display: 'block' }}
      >
        <AssemblyKeywords bomItemPointer={props.bomItemPointer} active={true} />
      </div>
      <div className={sharedClasses.unitSelector}>
        <AddWorkingStepButton bomItemPointers={[props.bomItemPointer]} />
        <BomItemRoutingData
          bomItemPointer={props.bomItemPointer}
          rootClassName={sharedClasses.workingSteps}
        />
      </div>
      <Box sx={{ padding: 2 }}>
        <CommentForm bomItemPointer={props.bomItemPointer} />
      </Box>
      <BomItemPriceContainer bomItemPointer={props.bomItemPointer} />
    </>
  )
}
