import { CheckOutlined, CloseOutlined } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { DefinitiveMoneyField } from 'components/DefinitiveInputs/DefinitiveMoneyField'
import { DefinitiveInputValidators } from 'components/DefinitiveInputs/Validators'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { noop } from 'lodash'
import { MoneyString } from 'model/Money'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MoneyDto,
  PriceRowDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { useFinancialDetailsInputStyles } from './FinancialDetailsInput.styles'

export const AdditionalProdCostsInput = (props: {
  bomItemPointer: BomItemPointer
  priceRow: PriceRowDto
  priceScope: PriceScope
  handleManualSaveAdditionalCosts: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto,
    priceScope: PriceScope
  ) => Promise<void>
  handleResetAdditionalCosts: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  loading?: Record<string, boolean>
  defaultMoneyObject: MoneyDto
  variant?: 'caption' | 'body2'
}) => {
  const [editing, setEditing] = useState(false)
  const [additionalCosts, setAdditionalCosts] = useState(
    props.priceRow?.additionalProductionCostsManuallySet ||
      props.priceRow?.additionalProductionCosts
  )

  const { t } = useTranslation()
  const { classes } = useFinancialDetailsInputStyles()

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  useEffect(() => {
    return () => {
      setAdditionalCosts(undefined)
      handleSetEditing(false)
    }
  }, [])

  const [changed, setChanged] = useState(false)
  useEffect(() => {
    if (!editing && !changed) {
      setAdditionalCosts(
        props.priceRow?.additionalProductionCostsManuallySet ||
          props.priceRow?.additionalProductionCosts
      )
    }
  }, [
    editing,
    props.priceRow?.additionalProductionCostsManuallySet,
    props.priceRow?.additionalProductionCosts,
    changed,
  ])

  const handleChange = (e: { currentTarget: { value: MoneyDto } }) => {
    setChanged(true)
    setAdditionalCosts({
      currency: e.currentTarget.value.currency,
      symbol: e.currentTarget.value.symbol,
      value: isNaN(e.currentTarget.value.value)
        ? null
        : e.currentTarget.value.value,
    })
  }

  const handleSave = useCallback(
    async (e: { preventDefault: () => void }) => {
      try {
        e.preventDefault()

        if (!changed) {
          return
        }

        if (additionalCosts.value >= 0) {
          const priceRowToSave: PriceRowDto = {
            ...props.priceRow,
            additionalProductionCostsManuallySet: additionalCosts,
          }

          setAdditionalCosts((curr) => {
            return {
              ...curr,
              value: additionalCosts.value,
            }
          })

          await props.handleManualSaveAdditionalCosts(
            props.bomItemPointer,
            priceRowToSave,
            props.priceScope
          )

          setEditing(false)
        }
      } catch (ex) {
        ShowException('Failed to edit production time', ex)
      }
    },
    [additionalCosts, changed, props]
  )

  const handleClose = useCallback(() => {
    handleSetEditing(false)
  }, [])

  const handleSetEditing = (editing: boolean) => {
    setEditing(editing)
    globalThis.isDirty = editing
    if (!editing) {
      setChanged(false)
    }
  }

  useEffect(() => {
    const handleKeydown = (ev: KeyboardEvent) => {
      if (changed && ev.ctrlKey && ev.key === 's') {
        ev.preventDefault()
        setTimeout(() => {
          handleSave(ev)
        })
        return
      }

      switch (ev.key) {
        case 'Escape':
          handleClose()
          break
        case 'Enter':
          setTimeout(() => {
            handleSave({ preventDefault: noop })
          })
          break
      }
    }

    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [changed, handleClose, handleSave])

  // shared costs should not be editable
  if (!editing || props.priceRow.costsAreShared) {
    return (
      <div style={{ textAlign: 'right', position: 'relative', width: '100%' }}>
        <Tooltip
          title={
            <>
              {`${t('project:costs-additional')}: ${MoneyString(
                additionalCosts,
                10
              )}`}
            </>
          }
          placement="top"
        >
          <span>
            <ValueLabel
              onClick={
                !projectIsEditable || props.priceRow.costsAreShared
                  ? undefined
                  : () => handleSetEditing(true)
              }
              valueProps={{
                variant: props.variant || 'body2',
              }}
              // label={'prod time'}
              value={MoneyString(additionalCosts)}
            />
          </span>
        </Tooltip>

        {props.priceRow.additionalProductionCostsManuallySet && (
          <Tooltip
            title={t(
              'project:reset-additional-costs',
              'reset to estimated additional costs',
              { costs: MoneyString(props.priceRow.additionalProductionCosts) }
            )}
            placement="bottom"
          >
            <Button
              variant="text"
              size="small"
              color="inherit"
              className={classes.resetValueButton}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                padding: 0,
                margin: 0,
                textAlign: 'right',
                bottom: '-1.25em',
                minWidth: 0,
                minHeight: 0,
              }}
              onClick={() =>
                props.handleResetAdditionalCosts(
                  props.bomItemPointer,
                  props.priceRow
                )
              }
            >
              {props.loading[
                `delete-manual-additional-costs-${props.priceRow.workingStep.primaryWorkingStep}-${props.priceRow.workingStep.secondaryWorkingStep}`
              ] ? (
                <CircularProgress size={20} />
              ) : (
                <Typography variant="caption" color="GrayText">
                  {MoneyString(props.priceRow.additionalProductionCosts)}{' '}
                </Typography>
              )}
            </Button>
          </Tooltip>
        )}
      </div>
    )
  } else {
    return (
      <form
        onSubmit={handleSave}
        onKeyDown={(e) => {
          if (e.ctrlKey && e.key === 's') {
            e.preventDefault()
            setTimeout(() => {
              handleSave(e)
            })
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '.5rem',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <DefinitiveMoneyField
            onChange={(newMoney) =>
              handleChange({ currentTarget: { value: newMoney } })
            }
            moneyDto={additionalCosts || props.defaultMoneyObject}
            typographyVariant={props.variant || 'body2'}
            numberFormatProps={{
              thousandSeparator: true,
            }}
            selectTextOnFirstRender
            isAllowed={DefinitiveInputValidators.IsMinMaxAllowed(0, 999999)}
            validation={(newMoney: MoneyDto) => {
              const validations = DefinitiveInputValidators.CombineValidators([
                DefinitiveInputValidators.IsRequired(newMoney.value),
                DefinitiveInputValidators.MinValue(newMoney.value, 0),
              ])

              if (validations?.length > 0) {
                setChanged(false)
              }

              return validations
            }}
            disableWarningsIcon
            showRawValueInTooltip={(value: MoneyDto | '') => {
              if (typeof value === 'string') {
                return null
              }

              return MoneyString(value, 20)
            }}
            textFieldProps={{
              autoFocus: true,
            }}
          />
          {props.loading[
            `manual-set-additional-costs-${props.priceRow.workingStep.primaryWorkingStep}-${props.priceRow.workingStep.secondaryWorkingStep}`
          ] ? (
            <CircularProgress size={20} style={{ display: 'inline-flex' }} />
          ) : (
            <>
              <IconButton
                type="submit"
                color={'primary'}
                size="small"
                disabled={!changed}
              >
                <CheckOutlined />
              </IconButton>
              <IconButton type="reset" onClick={handleClose} size="small">
                <CloseOutlined />
              </IconButton>
            </>
          )}
        </div>
      </form>
    )
  }
}
