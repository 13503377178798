import { TableCell, TableRow, Theme, Typography } from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { QuantityString } from 'model/Quantity'
import { useTranslation } from 'react-i18next'
import { FeatureDetailDto } from '../../../../services/APIs/InternalAPI/internal-api.contracts'

export const FeatureItem = (props: {
  featureDetail: FeatureDetailDto
  viewController: ModelController
  nested?: boolean
}) => {
  const { t } = useTranslation('project')

  const borderLeft = !props.nested
    ? (theme: Theme) => `1px solid ${theme.palette.divider}`
    : undefined

  return (
    <TableRow hover>
      <TableCell component="th" scope="row" sx={{ borderLeft: borderLeft }}>
        <Typography variant="body2">
          {t(
            `${props.featureDetail.label}`,
            props.featureDetail.label
          ).toLowerCase()}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {props.featureDetail.quantityValue && (
          <Typography variant="body2" noWrap>
            {QuantityString(props.featureDetail.quantityValue)}
          </Typography>
        )}
        {props.featureDetail.boolValue !== null && (
          <Typography variant="body2">
            {props.featureDetail.boolValue
              ? t('common:yes', 'yes').toLowerCase()
              : t('common:no', 'no').toLowerCase()}
          </Typography>
        )}
        {props.featureDetail.intValue && (
          <Typography variant="body2">
            {props.featureDetail.intValue}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  )
}
