import { Typography } from '@mui/material'
import { MoneyString } from 'model/Money'
import { useTranslation } from 'react-i18next'
import { PriceDetailsGraphData } from '../../useBomItemDetails'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

export const SurchargeAndDiscountTooltip = (props: {
  data: PriceDetailsGraphData
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5em',
        padding: '.5em',
      }}
    >
      <div>
        <TargetPriceTooltipListItemText
          primary={
            <Typography variant="body2">
              {`${MoneyString(props.data.surchargeValue)}`}
            </Typography>
          }
          secondary={t('project:surcharge')}
        />
        <TargetPriceTooltipListItemText
          primary={
            <Typography variant="body2">
              {`${MoneyString(props.data.discountValue)}`}
            </Typography>
          }
          secondary={t('project:discount')}
        />
      </div>
    </div>
  )
}
