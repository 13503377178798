import { Button, Switch, Theme, Typography } from '@mui/material'
import { useKeywordsTranslation } from 'components/Common/ArticleSelector/useKeywordsTranslation'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { KeywordTag } from 'features/BillOfMaterials/components/BomItemActivities/KeywordTag'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectStateSelectors } from 'store/Project/ProjectSelectors'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import './MaterialKeywordFilter.scss'

const useStyles = makeStyles()((theme: Theme) => ({
  activeFilter: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.getContrastText(theme.palette.info.main),
    '& :focus': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.getContrastText(theme.palette.info.light),
    },
    minHeight: '1.5rem',
    '& :hover': {
      // backgroundColor: theme.palette.info.dark,
      color: theme.palette.getContrastText(theme.palette.info.dark),
    },
  },
}))

export const MaterialKeywordFilter = () => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const { translateKeyword } = useKeywordsTranslation()

  // const [projectKeywords, setProjectKeywords] = useState(undefined)
  // const { availableFilters: availableKeywords, enabledFilters: selectedKeywords, disabledFilters: disabledKeywords } = useAppSelector((state: RootAppState) => state.newProjectReducer.filters.byMaterialKeywords.filter)
  const materialKeywordsFilter = useAppSelector(
    ProjectStateSelectors.MaterialFilterSelector,
    _.isEqual
  )

  const hasActiveFilter = useAppSelector(
    ProjectStateSelectors.HasActiveFilter()
  )

  const dispatch = useAppDispatch()

  const handleChange = (group: string, keyword: KeywordDto) => {
    const newEnabledTokens =
      { ...materialKeywordsFilter.filter.enabledFilters } || {}

    if (!_.isEmpty(newEnabledTokens[group])) {
      if (
        newEnabledTokens[group].findIndex(
          (x) => x.originalKeyword === keyword.originalKeyword
        ) > -1
      ) {
        newEnabledTokens[group] = newEnabledTokens[group].filter(
          (x) => x.originalKeyword !== keyword.originalKeyword
        )
        if (newEnabledTokens[group].length === 0) {
          delete newEnabledTokens[group]
        }
      } else {
        newEnabledTokens[group] = [...(newEnabledTokens[group] || []), keyword]
      }
    } else {
      newEnabledTokens[group] = [keyword]
    }

    if (_.isEmpty(newEnabledTokens)) {
      toggleMaterialFilter()
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            ...materialKeywordsFilter.filter,
            enabledFilters: newEnabledTokens,
          },
        })
      )
    }
  }

  const handleShowIfAll = (showIfAll: boolean) => {
    dispatch(
      newProjectActions.setFilter({
        filter: {
          ...materialKeywordsFilter.filter,
          showIfAll: showIfAll,
        },
      })
    )
  }

  const toggleMaterialFilter = () => {
    dispatch(
      newProjectActions.toggleActiveFilter({
        filterType: 'MaterialKeywordsFilter',
        open: false,
      })
    )
  }

  const removeAllFilters = () => {
    dispatch(newProjectActions.removeAllFilters())
  }

  const availableMaterialCategories = Object.keys(
    materialKeywordsFilter.filter.availableFilters || {}
  )

  if (!availableMaterialCategories?.length) {
    return (
      <LocalizedTypography
        translationKey="project:filter-no-material-keywords"
        style={{
          padding: '1rem',
          textAlign: 'center',
          textTransform: 'lowercase',
        }}
        color="GrayText"
        component="p"
        variant="caption"
      >
        the model analysis did not find or select any material keywords. please
        review the analysis results
      </LocalizedTypography>
    )
  }

  return (
    <div className="filter-container">
      <div
        style={{
          position: 'absolute',
          top: '.5em',
          right: '50%',
          transform: 'translateX(50%)',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="caption"
            color="textPrimary"
            onClick={() => handleShowIfAll(false)}
            style={{ cursor: 'pointer' }}
            title={t(
              'project:filters-filter-row-with-any-selected-keywords-title',
              'filter rows with any selected keywords'
            )}
          >
            {t(
              'project:filters-filter-row-with-any-selected-keywords-label',
              'or'
            )}
          </Typography>
          <Switch
            checked={materialKeywordsFilter.filter.showIfAll ?? false}
            onChange={(e) => handleShowIfAll(e.target.checked)}
            color="primary"
            size="small"
          />
          <Typography
            variant="caption"
            color="textPrimary"
            onClick={() => handleShowIfAll(true)}
            style={{ cursor: 'pointer' }}
            title={t(
              'project:filters-filter-row-with-all-selected-keywords-title',
              'filter rows with all selected keywords'
            )}
          >
            {t(
              'project:filters-filter-row-with-all-selected-keywords-label',
              'and'
            )}
          </Typography>
        </div>

        {/* <FormControlLabel
          control={
            <Checkbox
              checked={materialKeywordsFilter.filter.showIfAll ?? false}
              onChange={(e) => handleShowIfAll(e.target.checked)}
              color="primary"
              size="small"
              disabled={_.isEmpty(materialKeywordsFilter.filter.enabledFilters)}
            />
          }
          labelPlacement="start"
          label={
            <Typography
              variant="caption"
              color={
                _.isEmpty(materialKeywordsFilter.filter.enabledFilters)
                  ? 'GrayText'
                  : 'textPrimary'
              }
            >
              {t(
                'project:filters-filter-row-with-all-selected-keywords',
                'filter rows with all selected keywords'
              )}
            </Typography>
          }
        /> */}
      </div>
      <div className="material-keywords-filter">
        {availableMaterialCategories.map((group) => {
          return (
            <div
              className="keyword-group"
              key={group}
              hidden={group === 'others'}
            >
              <Typography
                variant="subtitle2"
                color={
                  materialKeywordsFilter.active
                    ? 'textPrimary'
                    : 'textSecondary'
                }
              >
                {t(`project:material-category-${group}`, group)}
              </Typography>
              <div className="keywords">
                {materialKeywordsFilter.filter.availableFilters[group].map(
                  (keyword) => {
                    return (
                      <KeywordTag
                        key={keyword.originalKeyword}
                        token={keyword}
                        translateFn={translateKeyword}
                        onClick={() => handleChange(group, keyword)}
                        size="small"
                        variant={
                          materialKeywordsFilter.active &&
                          materialKeywordsFilter.filter?.enabledFilters &&
                          materialKeywordsFilter.filter.enabledFilters[
                            group
                          ]?.includes(keyword)
                            ? 'filled'
                            : 'outlined'
                        }
                        color={
                          materialKeywordsFilter.active &&
                          materialKeywordsFilter.filter?.enabledFilters &&
                          materialKeywordsFilter.filter.enabledFilters[
                            group
                          ]?.includes(keyword)
                            ? 'primary'
                            : 'default'
                        }
                        className={cx('keyword', {
                          [classes.activeFilter]:
                            materialKeywordsFilter.active &&
                            materialKeywordsFilter.filter?.enabledFilters &&
                            materialKeywordsFilter.filter.enabledFilters[
                              group
                            ]?.includes(keyword),
                        })}
                      />
                    )
                  }
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="filter-actions">
        {hasActiveFilter && (
          <Button variant="outlined" color="primary" onClick={removeAllFilters}>
            {t('project:remove-all-filters', 'remove all filters')}
          </Button>
        )}
      </div>
    </div>
  )
}
