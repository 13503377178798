import { useAuth0 } from '@auth0/auth0-react'
import { Button, Container, TextField, Typography } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { AppLogo } from 'components/Layout/AppLogo'
import { useAppController } from 'customHooks/useAppController'
import { useAppLogin } from 'features/Auth/useAppLogin'
import { AcceptInviteController } from 'features/CustomerPortal/ConnectedParties/controllers/AcceptInviteController'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/configureStore'
import { useWorkspacesStyles } from './useWorkspacesStyles'

type Props = {
  getProfileData: () => void
  loading: Record<string, boolean>
  controller: UserController
}
const parseInviteLink = (inviteLink: string) => {
  const url = new URL(inviteLink)
  const urlParams = new URLSearchParams(
    url.search || url.hash.replace('#', '').split('?')[1]
  )

  const id = urlParams.get('id')
  const code = urlParams.get('code')

  const isAcceptInvite = (url.href + url.hash).includes('acceptinvite')

  if (!id || !code || !isAcceptInvite) {
    console.warn('inviteid and code not found in invite link')
    return {
      id: undefined,
      code: undefined,
    }
  }

  return { id, code }
}

export const NoAvailablePortals = (props: Props) => {
  const { classes } = useWorkspacesStyles()
  const { appLogout } = useAppLogin()
  const { user } = useAuth0()
  const { t } = useTranslation()
  const availableParties = useAppSelector((state) => {
    return state.user.organizations
  })

  const {
    controller: inviteController,
    loading,
    error,
  } = useAppController(() => new AcceptInviteController())

  const { controller: userController } = useAppController(
    () => new UserController()
  )

  const [inviteLink, setInviteLink] = useState('')
  const [validation, setValidation] = useState('')

  const handleChangeInviteLink = (inviteLink: string) => {
    setInviteLink(inviteLink)

    try {
      const url = new URL(inviteLink)
      const { id, code } = parseInviteLink(inviteLink)

      const isAcceptInvite = (url.href + url.hash).includes('acceptinvite')

      if (!id || !code || !isAcceptInvite) {
        throw new Error('invalid invite link')
      }

      error['accept-request'] = undefined
      setValidation(undefined)
    } catch (err) {
      setValidation(t('common:invite-link-invalid', 'invalid invite link'))
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (Boolean(validation)) {
      return null
    }

    try {
      const { id, code } = parseInviteLink(inviteLink)
      const resp = await inviteController.AcceptInvite(id, code)

      if (resp) {
        // validate if user has access
        const userProfile = await userController.GetProfile()
        if (
          userProfile.partiesDto.findIndex(
            (party) => party.id === resp.buyerPartyId
          ) === -1
        ) {
          setValidation('You do not have access to this workspace')
        } else {
          userController.SelectWorkspace(resp.buyerPartyId)
        }
      }
    } catch (err) {
      console.warn(err)
    }
  }

  return (
    <div className={classes.workspacesRoot}>
      <Container className={classes.noWorkspaces} maxWidth={'sm'}>
        <AppLogo />
        <div className={classes.noWorkspacesMessage}>
          <Typography style={{ textTransform: 'none' }}>
            {t('common:invite-hello-message', {
              defaultValue: 'Hello {{name}}',
              name:
                user?.given_name?.charAt(0).toUpperCase() +
                user?.given_name?.slice(1),
            })}
          </Typography>
          <Typography>
            {t('common:invite-need-workspace', {
              defaultValue:
                'you need a workspace to start using this portal. If you have an invite link, please paste it below.',
            })}
          </Typography>
          <form onSubmit={handleSubmit} className={classes.noWorkspacesForm}>
            <TextField
              multiline
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit(e)
                }
              }}
              label={t('common:invite-link', 'invite link')}
              name={'inviteLink'}
              value={inviteLink}
              onChange={(e) => handleChangeInviteLink(e.target.value)}
              required
              fullWidth
              helperText={validation || error['accept-request']?.message}
              error={!!validation || !!error['accept-request']}
            />
            <SaveButton
              translationKey={'common:join-workspace'}
              disabled={!Boolean(inviteLink) || Boolean(validation)}
              saving={loading['accept-request']}
            />
          </form>
        </div>

        <div className={classes.noWorkspacesActions}>
          <Button onClick={() => appLogout()} color="error" variant="outlined">
            {t('common:logout')}
          </Button>
          {/* <Button
            color="primary"
            onClick={() => props.getProfileData()}
            variant="outlined"
            startIcon={
              props.loading['get-profile'] ? (
                <CircularProgress size={16} />
              ) : null
            }
          >
            {t('common:workspaces-retry-get-data', 'Retry get user data')}
          </Button> */}
          {availableParties?.length > 0 && (
            <Link to="/app/workspaces">
              <Button color="primary" variant="contained">
                {t(
                  'common:workspaces-see-workspaces',
                  'available workspaces ({{count}})',
                  { count: availableParties?.length }
                )}
              </Button>
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}
