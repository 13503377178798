import { BaseController } from 'controllers/BaseController'
import { DashboardOpenedEventData } from 'features/AppEventTrack/DashboardOpenedEventData'
import { AppEventsController } from 'features/AppEventTrack/appEventsController'
import { GetPartyDashboardsResponse } from 'services/APIs/InternalAPI/internal-api.contracts'
import { GetContext } from 'store/storeUtils'
import { DashboardAPI } from './MixPanelDashboards.api'

export interface IDashboardAPI {
  getDashboards: () => Promise<GetPartyDashboardsResponse>
  trackDashboardOpenedEvent: (data: DashboardOpenedEventData) => Promise<void>
}

export class MixPanelDashboardsController extends BaseController<IDashboardAPI> {
  constructor(partyId?: string) {
    super((onRequestChanged) => {
      partyId = partyId ?? GetContext().partyId

      return new DashboardAPI(partyId, onRequestChanged)
    })
  }

  public async getDashboards() {
    try {
      return await this.api.getDashboards()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async trackDashboardOpenedEvent(dashboardType: string) {
    try {
      const appEventsController = new AppEventsController()

      return await appEventsController.PostEvent({
        eventType: 'DashboardOpened',
        eventData: {
          dashboardType: dashboardType,
        },
        success: true,
      })
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
