import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemAllActivitiesSelector } from './allBomItemActivitiesSelector'
import { bomItemRawMaterialActivitySelector } from './bomItemRawMaterialActivitySelector'

export const bomItemActivityByIdSelector =
  (bomItemPointer: BomItemPointer, activityId: string) =>
  (state: RootAppState): BoMItemActivityDto | undefined => {
    const bomItemActivities =
      bomItemAllActivitiesSelector(bomItemPointer)(state)

    let activity = bomItemActivities.find(
      (activity) => activity.id === activityId
    )

    if (!activity || activity.usesRawMaterial) {
      activity = bomItemRawMaterialActivitySelector(bomItemPointer)(state)
    }

    return activity
  }
