import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
} from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectController } from 'controllers/Project/ProjectController'
import { useAppController } from 'customHooks/useAppController'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'

export const ProjectVisibleInPortal = (
  props: Partial<FormControlLabelProps>
) => {
  const projectVisibleInPortal = useAppSelector(
    projectSelectors.projectVisibleInPortalSelector
  )
  const createdInPortal = useAppSelector(
    (state) => state.project.activeProject?.createdInPortal
  )
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const { controller, loading } = useAppController(
    () => new ProjectController()
  )

  if (createdInPortal || isBuyerView) return null

  const handleChange = async (visible: boolean) => {
    try {
      await controller.UpdateProjectVisibleInPortal(visible)
    } catch (err) {
      ShowException('project', err)
    }
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={projectVisibleInPortal}
            onChange={(e, checked) => handleChange(checked)}
            size={'small'}
            sx={{
              padding: (theme) => theme.spacing(0, 0, 0, 0.5),
            }}
          />
        }
        label={
          <LocalizedTypography
            translationKey={'project:visible-in-portal'}
            defaultValue={'visible in portal'}
            variant="caption"
          />
        }
        labelPlacement="start"
        disabled={loading['VisibleInPortal']}
        {...props}
      />
    </FormGroup>
  )
}
