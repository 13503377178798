import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'store/configureStore'
import IntercomLoadScriptFunction from './IntercomLoadScriptFunction'

const IntercomScriptTag = () => {
  const appId = import.meta.env.VITE_INTERCOM_MESSENGER_APP_ID

  window['IntercomSettings'] = {
    app_id: appId,
    alignment: 'right',
  }

  useEffect(() => {
    IntercomLoadScriptFunction(appId)
  }, [appId])

  return (
    <style>
      {`
      .intercom-lightweight-app-launcher {
        position: fixed;
        bottom: 8px !important;
        right: 8px !important;
        z-index: 10000;
      }
      .intercom-namespace {
        position: fixed !important;
        bottom: 8px !important;
        right: 8px !important;
        z-index: 10000 !important;
      }
      .intercom-app > div:nth-child(2) {
        bottom: 8px !important;
        right: 8px !important;
      }
      .intercom-app > div:nth-child(3) {
        bottom: 8px !important;
        right: 8px !important;
      }
    `}
    </style>
  )
}

export const IntercomWidget = () => {
  const { withinIframe } = useAppRunningContext()
  const { i18n } = useTranslation()
  const userProfile = useAppSelector((state) => state.user?.profile, isEqual)
  const location = useLocation()
  const partyInfo = useAppSelector(
    (state) => state.user?.organizationContext,
    isEqual
  )

  const isBuyer = useAppSelector(
    (state) => state.user?.organizationContext?.isBuyingParty
  )

  const tenantId = useAppSelector((state) => state.user?.tenantId)

  const firstRun = React.useRef(true)

  useEffect(() => {
    if (
      location.pathname.includes('getting-started') ||
      (partyInfo?.hasSupportChat && userProfile && partyInfo)
    ) {
      window['intercomSettings'] = {
        app_id: import.meta.env.VITE_INTERCOM_MESSENGER_APP_ID,
        alignment: 'right',
        user_id: userProfile?.auth0Id,
        email: userProfile?.email,
        name: `${userProfile?.firstName} ${userProfile?.lastName}`,
        user_hash: userProfile?.intercomHash,
        avatar: {
          type: 'avatar',
          image_url: userProfile?.picture,
        },
        language_override: i18n.language,
        widget: {
          activator: '#intercom-widget',
        },
        company: {
          id: isBuyer ? tenantId : partyInfo?.id,
          name: isBuyer ? undefined : partyInfo?.name,
        },
        buyerParty: !isBuyer
          ? undefined
          : {
              id: partyInfo?.id,
              name: partyInfo?.name,
            },
        sellerParty: {
          id: isBuyer ? tenantId : partyInfo?.id,
          name: isBuyer ? undefined : partyInfo?.name,
        },
      }

      if (firstRun.current) {
        window['Intercom']?.('boot', window['intercomSettings'])
        firstRun.current = false
      } else {
        window['Intercom']?.('update', window['intercomSettings'])
      }
    } else {
      if (window['Intercom']) {
        window['Intercom']?.('hide')
        window['Intercom']?.('shutdown')
      }
    }
  }, [i18n.language, isBuyer, partyInfo, tenantId, userProfile, withinIframe])

  return <IntercomScriptTag />
}
