import { Tooltip, TooltipProps } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type CustomPopperProps = {
  TooltipComponent: React.ReactElement
  AnchorComponent: React.ReactElement
  disableCloseOnBlur?: boolean
  isOpen?: boolean
  disablePortal?: boolean
  placement?: 'bottom' | 'top' | 'left' | 'right'
  overflowElementId?: string
  tooltipProps?: Partial<TooltipProps>
}

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 'max-content',
    padding: 0,
  },
}))

export const CustomPopper = (props: CustomPopperProps) => {
  const [open, setOpen] = React.useState(props.isOpen ?? false)

  const { classes } = useStyles()

  return (
    <Tooltip
      title={props.TooltipComponent}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      placement={props.placement || 'bottom'}
      enterDelay={500}
      enterNextDelay={500}
      arrow
      PopperProps={{
        disablePortal: props.disablePortal,
        style: {
          zIndex: 1000,
        },
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom'],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8,
                boundary: props.overflowElementId
                  ? document.getElementById(props.overflowElementId)
                  : null,
              },
            },
          ],
        },
      }}
      classes={{
        tooltip: classes.tooltip,
      }}
      {...props.tooltipProps}
    >
      {props.AnchorComponent}
    </Tooltip>
  )
}
