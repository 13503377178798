import { SettingsInputComponentOutlined } from '@mui/icons-material'
import { checkManufacturability } from 'features/BillOfMaterials/store/asyncActions/checkManufacturability'
import { bomItemActivitiesSelector } from 'features/BillOfMaterials/store/selectors/bomItemWorkingStepsSelector'
import { projectInBuyerViewSelector } from 'features/BillOfMaterials/store/selectors/projectInBuyerViewSelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const CheckManufacturabilityMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const bomItemWorkingSteps = useAppSelector(
    bomItemActivitiesSelector({ bomItemPointer: bomItemPointer }),
    isEqual
  )
  const isBuyerView = useAppSelector(projectInBuyerViewSelector)

  if (
    isBuyerView ||
    !Boolean(bomItemWorkingSteps) ||
    bomItemWorkingSteps.findIndex(
      (x) => x.primaryWorkingStep === WorkingStepType.SheetBending
    ) === -1
  ) {
    return null
  }

  return (
    <BomItemMenuItem
      action={checkManufacturability}
      actionParams={{ bomItemId: bomItemPointer.id }}
      closeAfterFullfilled
      onClose={onClose}
      primaryTextTranslationKey="project:context-check-manufacturability"
      secondaryTextTranslationKey="project:context-check-manufacturability-desc"
      icon={<SettingsInputComponentOutlined />}
    />
  )
}
