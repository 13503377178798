import { isEqual } from 'lodash'
import { CSSProperties, memo } from 'react'
import { FixedSizeList } from 'react-window'
import { CostFactor } from '../GetGraphData'
import { CostFactorPartItem } from './CostFactorPartsSection'
import { Legend } from './CostFactorSection'

let scrollOffset = 0
const AssemblyPartsCostFactorSection = (props: {
  parts: CostFactor[]
  legend: Legend
}) => {
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    return (
      <li style={style}>
        <CostFactorPartItem costFactor={sorted[index]} legend={props.legend} />
      </li>
    )
  }

  if (!props.parts?.length) {
    return null
  }

  const sorted = [...props.parts].sort(
    (a, b) => b.salesPrice.value - a.salesPrice.value
  )

  return (
    <FixedSizeList
      height={Math.min(65 * sorted.length, 250)}
      itemCount={sorted.length}
      itemSize={65}
      width={'100%'}
      onScroll={(e) => {
        scrollOffset = e.scrollOffset
      }}
      initialScrollOffset={scrollOffset}
    >
      {Row}
    </FixedSizeList>
  )
}

export default memo(AssemblyPartsCostFactorSection, isEqual)
