import {
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_TableInstance,
} from 'material-react-table'
import { ChangeEvent, useEffect, useState } from 'react'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectStateSelector } from './ProjectStateSelector'

import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  SortOutlined,
} from '@mui/icons-material'
import { RefreshButton } from 'components/Common/Button/RefreshWithProgress'
import { t } from 'i18next'
import React from 'react'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'ProjectsToolbar' })((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  refresh: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  sortColumnsList: {
    paddingTop: 0,
  },
  sortDirection: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 2, 0.5, 3),
  },
}))

const sortColumnns: Record<string, string> = {
  lastOperation: 'common:last-modified',
  status: 'common:status',
  orderNumber: 'common:order-number',
  projectReference: 'common:project-reference',
  numberOfRows: 'common:num-parts',
  salesPriceOfItemsAmount: 'common:sales-price-of-items',
  quotationExpireDate: 'common:expires',
  buyingPartyName: 'common:customer',
  contactPersonEmail: 'common:contact-person',
  sellingPartyName: 'common:supplier',
  // numberofitems: 'common:num-parts',
  // numberofassemblies: 'common:num-assemblies',
  salesPersonEmail: 'common:sales-representative',
  creatorEmail: 'common:created-by',
}

type Props = {
  table: MRT_TableInstance<ProjectSummaryDto>
  loading: Record<string, boolean>
  onlyMyProjects: boolean
  showCanceledProjects: boolean
  setShowCanceledProjects: (value: boolean) => void
  getProjectList: () => void
  handleChangeOnlyMyProjects: (
    ev: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  handleDeleteSelectedProjects: (
    table: MRT_TableInstance<ProjectSummaryDto>
  ) => Promise<void>
  selectedProjectStates: ProjectStateAsString[]
  onProjectStateChange: (selectedProjectStates: ProjectStateAsString[]) => void
  sorting: MRT_SortingState
  setSorting: (sorting: MRT_SortingState) => void
}

const myProjectTitle = {
  buyer: {
    translationKey: 'common:my-projects-buyer',
    defaultText: 'shows only projects create by me',
  },
  seller: {
    translationKey: 'common:my-projects-seller',
    defaultText:
      'shows only project created by me or where I am the sales representative',
  },
}

function _TopToolbar(props: Props) {
  const { classes } = useStyles()

  const isBuyer = useAppSelector(
    (state) => state.user.organizationContext.isBuyingParty
  )

  const [desc, setDesc] = useState(true)

  useEffect(() => {
    setDesc(props.sorting[0]?.desc)
  }, [props.sorting])

  const handleSortDesc = (desc: boolean) => {
    setDesc(desc)
    props.setSorting([{ id: props.sorting[0]?.id, desc: desc }])
  }

  return (
    <div className={classes.root}>
      <ProjectStateSelector
        onProjectStateChange={props.onProjectStateChange}
        selectedProjectStates={props.selectedProjectStates}
      ></ProjectStateSelector>
      <div>
        <MRT_GlobalFilterTextField table={props.table} />
        <div className={classes.details}>
          <Tooltip
            title={t(
              myProjectTitle[isBuyer ? 'buyer' : 'seller'].translationKey,
              myProjectTitle[isBuyer ? 'buyer' : 'seller'].defaultText
            )}
          >
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={props.onlyMyProjects}
                  onChange={props.handleChangeOnlyMyProjects}
                  style={{
                    padding: 0,
                    marginRight: '1em',
                  }}
                />
              }
              label={
                <Typography variant="caption">
                  {t('common:my-projects', 'my projects')}
                </Typography>
              }
            />
          </Tooltip>

          <Select
            value={props.sorting[0]?.id || 'undefined'}
            size="small"
            disableUnderline
            MenuProps={{
              classes: {
                list: classes.sortColumnsList,
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                marginTop: '0 !important',
              },
              '& .MuiInputBase-input': {
                padding: `0 .5em !important`,
              },
            }}
            onChange={(e) => {
              const id = e.target.value.toString()

              props.setSorting([{ id: id, desc: desc }])
            }}
            IconComponent={() => null}
            renderValue={(value) => {
              if (value === 'undefined') {
                return (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    title={t(
                      'common:sort-button',
                      'select a column to sort by'
                    )}
                  >
                    {/* {t('common:sort-no-sorting-column', 'choose a column to sort by')} */}
                    <SortOutlined fontSize="small" />
                  </Typography>
                )
              }
              return (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  {`${t('common:sort-by')}: ${t(sortColumnns[value])}`}
                  {desc ? (
                    <ArrowDownwardOutlined fontSize={'small'} />
                  ) : (
                    <ArrowUpwardOutlined fontSize={'small'} />
                  )}

                  {/* {`${t('common:sort-by')}: ${t(sortColumnns[value])} ${
                      desc ? (
                        <ArrowUpwardOutlined /> // `(${t('common:sort-order-desc', 'desc')})`
                      ) : (
                        <ArrowDownwardOutlined />
                      ) //'↑' //`(${t('common:sort-order-asc', 'asc')})`
                    }`} */}
                </Typography>
              )
            }}
          >
            <ListSubheader className={classes.sortDirection}>
              <Typography variant="caption">
                {t('common:sort-order')}
              </Typography>
              <ButtonGroup>
                <IconButton
                  size="small"
                  onClick={() => handleSortDesc(true)}
                  color={desc ? 'primary' : 'default'}
                  title={t('common:sort-order-desc', 'desc')}
                >
                  <ArrowDownwardOutlined />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSortDesc(false)}
                  color={!desc ? 'primary' : 'default'}
                  title={t('common:sort-order-asc', 'asc')}
                >
                  <ArrowUpwardOutlined />
                </IconButton>

                {/* <Button
                    size="small"
                    onClick={() => handleSortDesc(true)}
                    variant={desc ? 'contained' : 'outlined'}
                  >
                    {t('common:sort-order-desc', 'desc')}
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleSortDesc(false)}
                    variant={desc ? 'outlined' : 'contained'}
                  >
                    {t('common:sort-order-asc', 'asc')}
                  </Button> */}
              </ButtonGroup>
            </ListSubheader>
            {Object.keys(sortColumnns).map((key) => {
              return (
                <MenuItem value={key} key={key}>
                  {t(sortColumnns[key])}
                </MenuItem>
              )
            })}
            {/* <MenuItem value="lastOperation">
                {t('common:last-modified')}
              </MenuItem>
              <MenuItem value="status">{t('common:status')}</MenuItem>
              <MenuItem value="orderNumber">
                {t('common:order-number')}
              </MenuItem>
              <MenuItem value="projectReference">
                {t('common:project-reference')}
              </MenuItem> */}
            {/* <div>
                <div>
                  <Button>desc</Button>
                  <Button>asc</Button>
                </div>
              </div> */}
          </Select>
        </div>
      </div>

      <div>
        <div className={classes.refresh}>
          <RefreshButton
            size="small"
            onClick={props.getProjectList}
            loading={props.loading['GetProjectsListPaged']}
          />
          {/* <IconButton
          size="small"
          onClick={() => {
            props.getProjectList()
          }}
        >
          {props.loading['GetProjectsListPaged'] ? (
            <CircularProgress size={18} />
          ) : (
            <ReplayOutlined />
          )}
        </IconButton> */}
          <MRT_ShowHideColumnsButton table={props.table} size="small" />
        </div>
      </div>
    </div>
  )
}

const _TopToolbarMemoized = React.memo(_TopToolbar)

export default _TopToolbarMemoized
