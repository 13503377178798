import {
  InputAdornment,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  DefinitiveDecimalTextField,
  DefinitiveDecimalTextFieldProps,
} from './DefinitiveDecimalTextField'

export type DefinitiveMoneyFieldProps = {
  moneyDto: MoneyDto
  onChange: (value: MoneyDto) => void
  validation?: (value: MoneyDto) => string[]
  onValidation?: (errors: string[]) => void
  showRawValueInTooltip?: (value: MoneyDto | '') => string
  typographyVariant?: TypographyVariant
  disabled?: boolean
} & Omit<
  DefinitiveDecimalTextFieldProps,
  'value' | 'onChange' | 'showRawValueInTooltip'
>

export const DefinitiveMoneyField = (props: DefinitiveMoneyFieldProps) => {
  const [currentMoney, setCurrentMoney] = useState(props.moneyDto)
  const [currentValidations, setCurrentValidations] =
    useState<string[]>(undefined)

  const theme = useTheme()

  const handleValidation = useCallback(
    (moneyDto: MoneyDto) => {
      const validations = props.validation
        ? props.validation(moneyDto)
        : undefined

      if (validations?.length > 0) {
        setCurrentValidations(validations)
      } else {
        setCurrentValidations(undefined)
      }

      if (props.onValidation) {
        props.onValidation(validations)
      }

      return validations
    },
    [props]
  )

  useEffect(() => {
    setCurrentMoney(props.moneyDto)
    handleValidation(props.moneyDto)
  }, [handleValidation, props.moneyDto])

  if (!props.moneyDto || !currentMoney) {
    return null
  }

  const handleChange = (field: keyof MoneyDto, value: number | string) => {
    const newQuantity = {
      ...currentMoney,
      [field]: value,
    }

    setCurrentMoney(newQuantity)

    const validations = handleValidation(newQuantity)

    if (!Boolean(validations?.length)) {
      props.onChange(newQuantity)
    }
  }

  return (
    <DefinitiveDecimalTextField
      {...props}
      numberFormatProps={{
        ...props.numberFormatProps,
        thousandSeparator: props.numberFormatProps?.thousandSeparator ?? true,
        decimalScale: props.numberFormatProps?.decimalScale ?? 2,
        fixedDecimalScale: true,
        allowNegative: props.numberFormatProps?.allowNegative ?? false,
      }}
      textFieldProps={{
        ...props.textFieldProps,
        inputProps: {
          ...props.textFieldProps?.inputProps,
          style: {
            textAlign:
              props.textFieldProps?.inputProps?.style?.textAlign || 'right',
            fontSize:
              theme.typography[props.typographyVariant || 'caption'].fontSize,
            padding:
              props.textFieldProps?.inputProps?.style?.padding || '.1em .1em',
            ...(props.textFieldProps?.inputProps?.style || {}),
          },
        },
        InputProps: {
          ...(props.textFieldProps?.InputProps || {}),
          startAdornment: props.textFieldProps?.InputProps?.startAdornment || (
            <InputAdornment position="start">
              <Typography variant={props.typographyVariant || 'inherit'}>
                {props.moneyDto.symbol}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: props.textFieldProps?.InputProps?.endAdornment,
        },
        disabled: props.disabled,
      }}
      maxValue={props.maxValue}
      minValue={props.minValue}
      value={currentMoney?.value}
      onChange={(value) => handleChange('value', value)}
      warnings={currentValidations}
      showRawValueInTooltip={
        props.showRawValueInTooltip &&
        ((value) =>
          props.showRawValueInTooltip({
            ...props.moneyDto,
            value: value?.toString() === '' ? undefined : 0,
          }))
      }
      isAllowed={props.isAllowed}
    />
  )
}
