import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { useCancelledStateItem } from './useCancelledStateItem'
import { useProjectStateItem } from './useProjectStateItem'

const projectState = ProjectStatus.CANCELLED

export const CancelledState = () => {
  const {
    getStateTranslationKey,
    stateItemIsCurrent,
    classes,
    changeStatus,
    SavingComponent,
    LightTooltip,
  } = useProjectStateItem(projectState)

  const { canChangeToCancelledState, Errors, errorStyle } =
    useCancelledStateItem()

  const handleClick = () => {
    if (canChangeToCancelledState) {
      changeStatus(ProjectStateAsString.DEFINING)
    }
  }

  return (
    <LightTooltip title={canChangeToCancelledState ? '' : <Errors />}>
      <li
        style={errorStyle}
        className={
          stateItemIsCurrent() ? classes.errorButton : classes.disabled
        }
        onClick={handleClick}
      >
        <LocalizedTypography
          translationKey={getStateTranslationKey()}
          variant="body2"
        />

        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
