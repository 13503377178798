import { BaseController } from 'controllers/BaseController'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  GetProjectProvideRawMaterialSettingsResponse,
  UpdateProjectProvideRawMaterialSettingsRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { GetContext } from 'store/storeUtils'

interface IProjectProvideRawMaterialSettingsAPI {
  getProjectProvideRawMaterialSettings: () => Promise<GetProjectProvideRawMaterialSettingsResponse>
  setProjectProvideRawMaterialSettings: (
    req: UpdateProjectProvideRawMaterialSettingsRequest
  ) => Promise<void>
}

class ProjectProvideRawMaterialSettingAPI
  extends InternalAPI
  implements IProjectProvideRawMaterialSettingsAPI
{
  constructor(
    partyId: string,
    projectId: string,
    onRequestChanged: OnRequestChangeCallback
  ) {
    super(
      `/api/parties/${partyId}/projects/${projectId}/provide-raw-material-setting`,
      onRequestChanged
    )
  }

  async setProjectProvideRawMaterialSettings(
    req: UpdateProjectProvideRawMaterialSettingsRequest
  ) {
    return this.PutAsync<void>({
      id: 'UpdateProjectProvideRawMaterialSettings',
      relativePath: '',
      data: req,
    })
  }

  async getProjectProvideRawMaterialSettings() {
    return this.GetAsync<GetProjectProvideRawMaterialSettingsResponse>({
      id: 'GetProjectProvideRawMaterialSettings',
      relativePath: '',
    })
  }
}

export class ProjectProvideRawMaterialSettingController extends BaseController<IProjectProvideRawMaterialSettingsAPI> {
  constructor() {
    super((onRequestChanged) => {
      const { partyId, projectId } = GetContext()
      return new ProjectProvideRawMaterialSettingAPI(
        partyId,
        projectId,
        onRequestChanged
      )
    })
  }

  async getProjectProvideRawMaterialSettings() {
    try {
      return await this.api.getProjectProvideRawMaterialSettings()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  async setProjectProvideRawMaterialSettings(
    req: UpdateProjectProvideRawMaterialSettingsRequest
  ) {
    try {
      await this.api.setProjectProvideRawMaterialSettings(req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
