import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FeatureSectionDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeSection } from './FeatureTreeSection'

type Props = {
  featureSections: FeatureSectionDto[]
  modelController: ModelController
  onOpen: () => void
  isOpen?: boolean
  faceIdToFilter?: number
  variant: 'small'
  onReload: () => void
  shallFilterBasedOnModelSelection: boolean
}

export const FeatureTreeSections = (props: Props) => {
  const { featureSections, modelController, onOpen } = props

  const [filteredSections, setFilteredSections] = useState(featureSections)

  const onSelectionCallback = useCallback(
    (selectedElements: Array<{ elementId: string | number }>) => {
      if (!selectedElements.length) {
        setFilteredSections(props.featureSections)
        return
      }

      const filteredSections = props.featureSections.filter((x) => {
        return [...x.faceIds, ...x.details.flatMap((d) => d.faceIds)].some(
          (faceId) => {
            return selectedElements.some(
              (element) => element.elementId.toString() === faceId.toString()
            )
          }
        )
      })

      setFilteredSections(filteredSections)
    },
    [props.featureSections]
  )

  useEffect(() => {
    let unregister = null

    if (props.shallFilterBasedOnModelSelection) {
      unregister = modelController.onSelectionChanged(onSelectionCallback)
    } else {
      setFilteredSections(props.featureSections)
    }

    return () => {
      if (unregister) {
        unregister()
      }
    }
  }, [
    modelController,
    onSelectionCallback,
    props.featureSections,
    props.shallFilterBasedOnModelSelection,
  ])

  useEffect(() => {
    if (props.shallFilterBasedOnModelSelection) {
      onSelectionCallback(modelController.selectedItems)
    }
  }, [
    modelController.selectedItems,
    onSelectionCallback,
    props.shallFilterBasedOnModelSelection,
  ])

  return (
    <div>
      {filteredSections.map((featureSection, index) => {
        return (
          <Fragment key={index}>
            <FeatureTreeSection
              key={`${featureSection.label}-${index}`}
              featureSection={featureSection}
              modelController={modelController}
              onOpen={onOpen}
              isOpen={props.isOpen}
              faceIdToFilter={props.faceIdToFilter}
              variant={props.variant}
              onReload={props.onReload}
            />
          </Fragment>
        )
      })}
    </div>
  )
}
