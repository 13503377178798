import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { BomItemSearchItem } from 'model/BomItemSearchModel'
import { formatCurrency } from 'model/Money'
import moment from 'moment'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import { makeStyles } from 'tss-react/mui'

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

type BomItemOption = {
  document: BomItemSearchItem
  highlights: Record<keyof BomItemSearchItem, string[]>
}

const SearchResultProjectName = (props: { option: BomItemOption }) => {
  return (
    <span>
      {props.option.document.orderNumber &&
      props.option.highlights?.orderNumber ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: props.option.highlights.orderNumber[0],
            }}
          />
          <span>{' | '}</span>
        </>
      ) : (
        `${props.option.document.orderNumber} | `
      )}
      {props.option.highlights?.projectReference ? (
        <span
          dangerouslySetInnerHTML={{
            __html: props.option.highlights.projectReference[0],
          }}
        />
      ) : (
        props.option.document.projectReference
      )}
    </span>
  )
}

const useStyles = makeStyles()((theme: Theme) => ({
  searchResultRoot: {
    '& em': {
      backgroundColor: `${theme.palette.warning.light}`,
      color: `${theme.palette.warning.contrastText}`,
      padding: theme.spacing(0, 0.5),
    },
  },
  bomItemOption: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      pointer: 'cursor',
    },
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.selected,
    // },
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer'
  },
}))

function SearchResultWithHightlight(props: {
  option: BomItemOption
  field: keyof BomItemSearchItem
}) {
  if (!props.option.document) return null

  if (props.option.highlights && props.option.highlights[props.field]) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: props.option.highlights[props.field][0],
        }}
      />
    )
  } else {
    return <span>{props.option.document[props.field].toString()}</span>
  }
}

export const BomItemSearchOption = (props: {
  option: BomItemOption
  baseElProps: HTMLAttributes<HTMLLIElement>
}) => {
  const { i18n } = useTranslation()
  const { classes, cx } = useStyles()

  if (!props.option.document) return null

  const salesPricePerItem = formatCurrency(
    i18n.language,
    props.option.document.currency,
    props.option.document.salesPricePerItem
  )
  // const costPricePerItem = formatCurrency(i18n.language, props.option.currency, props.option.costPricePerItem);

  const salesPriceOfItems = formatCurrency(
    i18n.language,
    props.option.document.currency,
    props.option.document.salesPriceOfItems
  )
  // const costPriceOfItems = formatCurrency(i18n.language, props.option.document.currency, props.option.document.costPriceOfItems);
  // const discountValueOfItems = formatCurrency(i18n.language, props.option.document.currency, props.option.document.discountOrSurchargeValueOfItems)

  switch (props.option.document.type.toLowerCase()) {
    case 'row':
    case 'part':
    case 'assembly':
      const document = props.option.document
      return (
        <li
          {...props.baseElProps}
          className={cx([
            'bom-item-search-option',
            classes.bomItemOption,
            classes.searchResultRoot,
          ])}
        >
          <div className="bomitem-name">
            <ListItemAvatar>
              <>
                {document.thumbnail3d && (
                  <img
                    alt={document.name}
                    loading="lazy"
                    style={{ marginRight: '.5em' }}
                    src={projectImageUrl(
                      document.buyingPartyId,
                      document.projectId,
                      document.thumbnail3d
                    )}
                    width={48}
                  />
                )}
                {document.thumbnail2d && (
                  <img
                    alt={document.name}
                    loading="lazy"
                    style={{ marginRight: '.5em' }}
                    src={projectImageUrl(
                      document.buyingPartyId,
                      document.projectId,
                      document.thumbnail2d
                    )}
                    width={48}
                  />
                )}
                {!document.thumbnail2d && !document.thumbnail3d && (
                  <Avatar>{document.type[0]}</Avatar>
                )}
              </>
            </ListItemAvatar>
            <ListItemText
              primary={
                <SearchResultWithHightlight
                  option={props.option}
                  field="name"
                />
              }
              secondary={
                <SearchResultWithHightlight
                  option={props.option}
                  field="type"
                />
              }
            />
          </div>
          <div style={{ flex: '0 1 50%' }}>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: 700 }}
            >
              <SearchResultProjectName option={props.option} />
              {/* {`${props.option.document.orderNumber && props.option.document.orderNumber + ' | ' || ''} ${props.option.document.projectReference}`} */}
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: 700 }}
            >
              <SearchResultWithHightlight
                option={props.option}
                field="status"
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <SearchResultWithHightlight
                option={props.option}
                field="buyingPartyName"
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <SearchResultWithHightlight
                option={props.option}
                field="sellingPartyName"
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.salesPricePerItem > 0 ? (
                <>
                  {salesPricePerItem} x {props.option.document.quantity} ={' '}
                  <strong>{salesPriceOfItems}</strong>
                </>
              ) : (
                <LocalizedTypography translationKey="search:price-not-defined">
                  not quoted
                </LocalizedTypography>
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.articleNumber ? (
                <>
                  <SearchResultWithHightlight
                    option={props.option}
                    field="articleNumber"
                  />
                  {': '}
                  <SearchResultWithHightlight
                    option={props.option}
                    field="articleDescription"
                  />
                </>
              ) : (
                <LocalizedTypography translationKey="search:article-not-defined">
                  article not defined
                </LocalizedTypography>
              )}
            </Typography>
            <ul className="working-items-list">
              {props.option.document.workingSteps?.map((x) => {
                return (
                  <li key={`${props.option.document.id}_ws_${x}`}>
                    <WorkingStepIconSvg
                      workingStepType={WorkingStepType[x]}
                      attributes={{ width: '32px' }}
                    />
                  </li>
                )
              })}
            </ul>
            <Typography variant="body2" color="textSecondary">
              last updated:{' '}
              {moment(props.option.document.lastUpdated).format('L')}
            </Typography>
          </div>
        </li>
      )
    case 'project':
      return (
        <li
          {...props.baseElProps}
          // className="bom-item-search-option project-option"
          className={cx([
            'bom-item-search-option',
            classes.bomItemOption,
            classes.searchResultRoot,
          ])}
        >
          <div className="bomitem-name">
            <ListItemText
              primary={<SearchResultProjectName option={props.option} />}
              secondary={
                <>
                  <SearchResultWithHightlight
                    option={props.option}
                    field="type"
                  />
                  <span>
                    {' '}
                    (
                    <SearchResultWithHightlight
                      option={props.option}
                      field="status"
                    />
                    )
                  </span>
                </>
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
            />
          </div>
          <div style={{ flex: '0 1 50%' }}>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.buyingPartyName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.sellingPartyName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.salesPriceOfItems > 0 ? (
                `${salesPriceOfItems}`
              ) : (
                <LocalizedTypography translationKey="search:price-not-defined">
                  not quoted
                </LocalizedTypography>
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.option.document.orderNumber}
            </Typography>
            <ul className="working-items-list">
              {props.option.document.workingSteps?.map((x) => {
                return (
                  <li key={`${props.option.document.id}_ws_${x}`}>
                    <WorkingStepIconSvg
                      workingStepType={
                        WorkingStepType[
                          camelize(x)
                        ] as unknown as WorkingStepType
                      }
                      attributes={{ width: '32px' }}
                    />
                  </li>
                )
              })}
            </ul>
            <Typography variant="body2" color="textSecondary">
              last updated:{' '}
              {moment(props.option.document.lastUpdated).format('L')}
            </Typography>
          </div>
        </li>
      )
  }

  return <>{props.option.document.type}</>
}
