import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _, { isEqual } from 'lodash'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'

export const useArticleIdFilter = () => {
  const dispatch = useAppDispatch()

  const articleIdFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byArticleId,
    _.isEqual
  )

  const handleChangeFilter = (articleIds: string[]) => {
    const newFilter = { ...articleIdFilter.filter }

    newFilter.enabledFilters = articleIds

    if (!articleIds.length || isEqual(newFilter, articleIdFilter.filter)) {
      dispatch(
        newProjectActions.toggleActiveFilter({
          filterType: 'ArticleIdFilter',
          open: false,
        })
      )
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'ArticleIdFilter',
            enabledFilters: newFilter.enabledFilters,
          },
        })
      )
    }
  }

  const filterIsActive = (articleId: string) => {
    return (
      articleIdFilter.active &&
      articleIdFilter.filter.enabledFilters.some((x) => x === articleId)
    )
  }

  return {
    handleChangeFilter,
    articleIdFilter,
    filterIsActive,
  }
}
