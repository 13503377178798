import { Typography } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { RootAppState } from 'store/configureStore'

type Props = {
  issuesFilterEnabled?: boolean
  materialFilterEnabled?: boolean
  workingStepFilterEnabled?: boolean
}

export const BoMItemFilterCount = (props: Props) => {
  const filterCount = useAppSelector(
    (state: RootAppState) =>
      state.project.assemblyHeadersIds.reduce(
        (acc, headerId) => {
          const assemblyHeader = state.project.assemblyHeaders[headerId]

          if (assemblyHeader.filteredPartTypePointers?.length > 0) {
            // visible assembly, count it and it's parts
            acc.assemblyCount = acc.assemblyCount + 1
            acc.partCount =
              acc.partCount + assemblyHeader.filteredPartTypePointers.length
          }

          acc.totalPartCount =
            acc.totalPartCount + assemblyHeader.partTypePointers?.length

          return acc
        },
        { assemblyCount: 0, partCount: 0, totalPartCount: 0 }
      ),
    _.isEqual
  )

  const { t } = useTranslation()

  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        component="p"
      >
        {t('project:filters-filter-result', {
          partsCount: filterCount.partCount,
          assembliesCount: filterCount.assemblyCount,
        })}
      </Typography>

      <Typography
        variant="caption"
        align="center"
        component="p"
        color="textSecondary"
      >
        {[
          props.workingStepFilterEnabled
            ? t(`common:working-steps`, 'working steps')
            : '',
          props.materialFilterEnabled
            ? t('common:material-keywords', 'material keywords')
            : '',
          props.issuesFilterEnabled ? t('common:issues') : '',
        ]
          .filter((x) => x !== '')
          .join(' | ') ||
          t('project:filters-no-filters-applied', 'no filters applied')}
      </Typography>
    </>
  )
}
