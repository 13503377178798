import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import { FeaturesDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'

export const useBomItemFeatures = (
  bomItemPointer: BomItemPointer
): [FeaturesDto, boolean, () => void] => {
  const { controller } = useAppController(() => new BomItemController())

  const [features, setFeatures] = useState<FeaturesDto>(undefined)

  const typeId = useAppSelector(
    projectSelectors.partInstancePartTypeSelector(bomItemPointer),
    isEqual
  )

  const getFeatures = useCallback(() => {
    controller
      .GetItemFeaturesDetails(typeId)
      .then((features) => {
        if (features?.features?.length === 0) {
          return null
        }
        setFeatures(features)
      })
      .catch((err) => ShowException('Item features', err))
  }, [controller, typeId])

  useEffect(() => {
    getFeatures()
  }, [getFeatures])

  const handleReload = () => {
    getFeatures()
  }

  return [features, false, handleReload]
}
