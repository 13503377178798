import { DashboardOpenedEventData } from 'features/AppEventTrack/DashboardOpenedEventData'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  GetPartyDashboardsResponse,
  PartySettingsSetDashboardRequest,
  RemoveDashboardRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { IDashboardAPI } from './MixPanelDashboards.controller'
import { IDashboardAdminAPI } from './MixPanelDashboardsAdminController'

export class DashboardAPI extends InternalAPI implements IDashboardAPI {
  constructor(
    partyId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/dashboards`)
  }
  trackDashboardOpenedEvent: (data: DashboardOpenedEventData) => Promise<void>

  public async getDashboards() {
    return this.GetAsync<GetPartyDashboardsResponse>({
      id: 'getDashboards',
      relativePath: `/`,
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class DashboardAdminAPI
  extends InternalAPI
  implements IDashboardAdminAPI
{
  constructor(
    partyId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/settings/dashboard`)
  }

  public async setDashboard(req: PartySettingsSetDashboardRequest) {
    return this.PutAsync<void>({
      id: 'setDashboard',
      relativePath: `/`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async deleteDashboard(req: RemoveDashboardRequest) {
    return this.DeleteAsync({
      id: 'deleteDashboard',
      relativePath: `/`,
      data: req,
      onRequestChange: this.onRequestChanged,
    })
  }
}
