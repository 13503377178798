import { Box, Tab, Tabs } from '@mui/material'
import { AddWorkingStepButton } from 'components/ProjectPage/BillOfMaterials/Rows/Components/RowWorkingSteps/WorkingSteps/AddWorkingStepButton'
import { BadgeList } from 'features/BillOfMaterials/BoMItemMenu/MenuItems/BadgeList'
import { ProjectBatchSizeInput } from 'features/BillOfMaterials/ProjectHeader/ProjectBatchSizeInput'
import {
  ProjectDiscountRatio,
  ProjectSurchargeRatio,
} from 'features/BillOfMaterials/ProjectHeader/ProjectDiscountOrSurcharge'
import { ProjectGrossSalesPrice } from 'features/BillOfMaterials/ProjectHeader/ProjectGrossSalesPrice'
import { ProjectNetSalesPrice } from 'features/BillOfMaterials/ProjectHeader/ProjectNetSalesPrice'
import { ProjectOrderNumberInput } from 'features/BillOfMaterials/ProjectHeader/ProjectOrderNumberInput'
import { ProjectPricePerBatch } from 'features/BillOfMaterials/ProjectHeader/ProjectPricePerBatch'
import { ProjectReferenceInput } from 'features/BillOfMaterials/ProjectHeader/ProjectReferenceInput'
import { BomItemRoutingData } from 'features/BillOfMaterials/RoutingHeader/BomItemRoutingData'
import { CommentForm } from 'features/BillOfMaterials/components/CommentForm/CommentForm'
import { ProjectStatusSelector } from 'features/BillOfMaterials/components/ProjectStatus/ProjectStatus'
import { useBomItemPricePerBatchStyles } from 'features/BillOfMaterials/components/useBomItemPricePerBatchStyles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBomItemDetailsDrawerStyles } from '../../BomItemDetailsDrawer.styles'
import { BomItemTargetPriceInput } from '../../Inputs/BomItemTargetPriceInput'
import { BomItemPriceContainer } from '../BomItemPriceContainer'
import { BomItemDetailsProps } from './BomItemDetailsProps'
import { ProjectFinancialDetails } from './ProjectFinancialDetails'
import { ProjectGraphsContainer } from './ProjectGraphsContainer'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </div>
  )
}

export const ProjectDetails = (props: BomItemDetailsProps) => {
  const { classes: sharedClasses } = useBomItemDetailsDrawerStyles()
  const { classes: priceClasses } = useBomItemPricePerBatchStyles({
    fullWidth: false,
  })
  const { t } = useTranslation()

  // const [view, setView] = useState<PriceSummaryDetailsViews>('priceSummary')
  const [viewIndex, setViewIndex] = useState<number>(props.detailTabIndex ?? 0)

  // const handleChangeView = (view: PriceSummaryDetailsViews) => {
  //   setView(view)
  // }

  return (
    <>
      <div className={sharedClasses.bomItemDescription}>
        <div
          className={sharedClasses.description}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <ProjectStatusSelector bomItemPointer={props.bomItemPointer} />
          <Box sx={{ width: '100%', padding: (theme) => theme.spacing(0, 2) }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ProjectReferenceInput
                textFieldProps={{
                  fullWidth: true,
                }}
              />
              <BadgeList bomItemPointer={props.bomItemPointer} />
            </Box>
            <ProjectOrderNumberInput textFieldProps={{ fullWidth: true }} />
          </Box>
          <div className={sharedClasses.prices}>
            <BomItemTargetPriceInput
              bomItemPointer={props.bomItemPointer}
              priceScope={'perUnit'}
              textFieldProps={{
                className: priceClasses.moneyTextField,
                inputProps: {
                  style: {
                    textAlign: 'right',
                    width: '90px',
                  },
                },
              }}
            />
            <ProjectBatchSizeInput />
            <ProjectPricePerBatch />
            <ProjectGrossSalesPrice />
            <ProjectSurchargeRatio />
            <ProjectDiscountRatio />
            <ProjectNetSalesPrice />
          </div>
        </div>
      </div>
      <div className={sharedClasses.unitSelector}>
        <AddWorkingStepButton bomItemPointers={[props.bomItemPointer]} />
        <BomItemRoutingData
          bomItemPointer={props.bomItemPointer}
          rootClassName={sharedClasses.workingSteps}
        />
      </div>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <CommentForm bomItemPointer={props.bomItemPointer} />
      </Box>
      <Tabs
        value={viewIndex}
        onChange={(_, value) => {
          setViewIndex(value)
        }}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        className={sharedClasses.tabs}
      >
        <Tab label={t('project:project-totals', 'project totals')} />
        <Tab
          label={t('project:project-working-steps', 'project working steps')}
        />
        <Tab label={t('project:vertical-menu-margin-label')} />
      </Tabs>
      <div className={sharedClasses.detailsContainer}>
        <TabPanel value={viewIndex} index={0}>
          <ProjectFinancialDetails />
        </TabPanel>
        <TabPanel value={viewIndex} index={1}>
          <BomItemPriceContainer bomItemPointer={props.bomItemPointer} />
        </TabPanel>
        <TabPanel value={viewIndex} index={2}>
          <ProjectGraphsContainer />
        </TabPanel>
      </div>
    </>
  )
}
