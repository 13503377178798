import { AccountTreeOutlined } from '@mui/icons-material'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { AssemblyHeaderPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const AssemblySimplificationMenuItem = ({
  assemblyHeaderPointer: bomItemPointer,
  onClose,
}: {
  assemblyHeaderPointer: AssemblyHeaderPointer
  onClose: () => void
}) => {
  const { openDialog } = useDialogManager()
  const assemblyIsIndividualPart = useAppSelector(
    (state) =>
      state.project.assemblyHeaders?.[bomItemPointer.id]
        ?.representsIndividualParts
  )

  if (assemblyIsIndividualPart) {
    return null
  }

  const handleOpen = () => {
    openDialog('AssemblySimplification', {
      bomItemPointer,
      open: true,
    })
  }

  return (
    <BomItemMenuItem
      onClick={handleOpen}
      closeOnClick
      onClose={onClose}
      primaryTextTranslationKey="project:vertical-menu-assembly-simplification"
      secondaryTextTranslationKey="project:vertical-menu-assembly-simplification-subtitle"
      icon={<AccountTreeOutlined style={{ transform: 'scale(-1, 1)' }} />}
      hiddenForBuyers
      disableIfNotEditable
    />
  )
}
