import { Typography } from '@mui/material'
import { MoneyString } from 'model/Money'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export const PriceLabel = (props: {
  title: string
  moneyDto: MoneyDto
  moneyStyle?: React.CSSProperties
}) => {
  if (!props.moneyDto) {
    return null
  }
  return (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Typography
        variant="body2"
        style={{ marginBottom: 0, padding: 0, ...(props.moneyStyle || {}) }}
      >
        {MoneyString(props.moneyDto)}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          padding: 0,
          lineHeight: '.5em',
          whiteSpace: 'nowrap',
        }}
      >
        {props.title}
      </Typography>
    </div>
  )
}
