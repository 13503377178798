import {
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { alphabetcalSort } from 'utils/customSort'
import { WorkingStepWithData } from '../store/AdditionalWorkingStepsReducer'

export function translateWorkingSteps(
  workingSteps: WorkingStepDto[],
  getLocalizedWorkingStep: (workingStep: WorkingStepType) => string
): WorkingStepWithData[] {
  return workingSteps
    .map((item) =>
      Object.assign(item, {
        label: getLocalizedWorkingStep(item.primaryWorkingStep),
      })
    )
    .sort(alphabetcalSort('label'))
}
