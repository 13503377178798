import React from 'react'

type TabPanelProps = {
  children?: React.ReactNode
  index: number | string
  value: number | string
  unmount?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  if (props.unmount && value !== index) {
    return null
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
