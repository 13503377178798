import { TextFieldWithLoading } from 'components/TextFieldWithLoading'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { bomItemActivityByIdSelector } from '../store/selectors/activities/bomItemActivityByIdSelector'

type Props = {
  bomItemPointer: BomItemPointer
  bomItemActivityId: string
}

export const BomItemActivityComment = (props: Props) => {
  const { controller, loading } = useAppController(
    () => new BomItemController()
  )
  const { t } = useTranslation()

  const activityComment = useAppSelector((state) => {
    const activity = bomItemActivityByIdSelector(
      props.bomItemPointer,
      props.bomItemActivityId
    )(state)

    return activity?.comment
  })

  const [value, setValue] = useState(activityComment)

  useEffect(() => {
    setValue(activityComment)
  }, [activityComment])

  const autoSaveTimeout = useRef<NodeJS.Timeout>()

  const [validation, setValidation] = useState<string | undefined>(undefined)
  const validate = useCallback(
    (comment: string) => {
      const remainingChars = 140 - comment.length
      if (remainingChars <= 40) {
        setValidation(t('common:chars-left', { count: remainingChars }))
      } else {
        setValidation(undefined)
      }
    },
    [setValidation, t]
  )
  const handleSaveComment = useCallback(
    async (comment: string) => {
      if (autoSaveTimeout.current) {
        clearTimeout(autoSaveTimeout.current)
      }

      if (comment === activityComment) {
        return
      }

      try {
        await controller.updateActivityComment(props.bomItemPointer, {
          boMItemActivityId: props.bomItemActivityId,
          comment: comment,
        })
      } catch (err) {
        ShowException('comment', err)
      }
    },
    [activityComment, controller, props.bomItemActivityId, props.bomItemPointer]
  )

  const handleChange = useCallback(
    (e: { target: { value: string } }) => {
      if (autoSaveTimeout.current) {
        clearTimeout(autoSaveTimeout.current)
      }

      setValue(e.target.value)
      validate(e.target.value)

      autoSaveTimeout.current = setTimeout(async () => {
        await handleSaveComment(e.target.value)
      }, 1500)
    },
    [handleSaveComment, validate]
  )

  return (
    <TextFieldWithLoading
      loading={loading[`update-activity-comment-${props.bomItemActivityId}`]}
      saveOnEnterFunction={handleSaveComment}
      name="comment"
      placeholder={t('common:comment')}
      variant="standard"
      onDoubleClick={(e) => e.stopPropagation()}
      multiline
      minRows={1}
      maxRows={4}
      fullWidth
      disabled={props.bomItemPointer.type === BomItemType.partInstance}
      value={value}
      onChange={handleChange}
      onBlur={(e) => handleSaveComment(e.currentTarget.value)}
      inputProps={{
        style: {
          fontFamily: 'monospace',
          display: 'flex',
          alignItems: 'center',
        },
        maxLength: 140,
      }}
      helperText={validation}
    />
  )
}
