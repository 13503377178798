import { useTheme } from '@mui/material'
import { TargetPriceTooltip } from 'features/BomItemDetailsDrawer/Graph/Tooltips/TargetPriceTooltip'
import { isEqual } from 'lodash'
import { useCallback, useRef } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  PriceScope,
  PriceSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { MoneyAxisTick } from '../../../Graph/YAxisTick'
import { GraphData } from '../ProjectPriceSummaries/ProjectPriceSummaryGraph'

export const TotalsBarChart = (props: {
  priceSummaryKeys: Array<string>
  isWorkingStepVisible: boolean
  isMaterialVisible: boolean
  scrollAndFilter: (priceSummary: PriceSummaryDto) => void
  containerHeight?: number
}) => {
  const theme = useTheme()

  const priceSummariesSum: Array<GraphData> = useAppSelector(
    (state: RootAppState) => {
      const initialState: Array<GraphData> = [
        props?.isWorkingStepVisible
          ? {
              description: 'working steps',
              category: 'working steps',
              totalSalesPriceWorkingSteps: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              totalSalesPriceMaterials: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              workingStepPrices: [],
              materialPrices: [],
            }
          : null,
        props?.isMaterialVisible
          ? {
              description: 'materials',
              category: 'materials',
              totalSalesPriceWorkingSteps: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              totalSalesPriceMaterials: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              workingStepPrices: [],
              materialPrices: [],
            }
          : null,
        props?.isWorkingStepVisible && props?.isMaterialVisible
          ? {
              description: 'totals',
              category: 'totals',
              totalSalesPriceWorkingSteps: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              totalSalesPriceMaterials: {
                ...state.project.activeProject?.financial?.costPricePerItem,
                value: 0,
              },
              workingStepPrices: [],
              materialPrices: [],
            }
          : null,
      ]

      const graphData = props.priceSummaryKeys.reduce((acc, key) => {
        const priceSummary = state.project.priceSummaries[key]
        if (props?.isWorkingStepVisible && priceSummary?.isWorkingStep) {
          acc[0].totalSalesPriceWorkingSteps.value +=
            priceSummary.totalSalesPrice.value
          acc[0].workingStepPrices.push({
            ...priceSummary,
            bomItemPointer: undefined,
            description: priceSummary.description,
            resource: priceSummary.workingStep?.resource?.name,
            primaryWorkingStep: priceSummary.workingStep.primaryWorkingStep,
            secondaryWorkingStep: priceSummary.workingStep.secondaryWorkingStep,
          })

          if (acc[2]) {
            acc[2].totalSalesPriceWorkingSteps.value +=
              priceSummary.totalSalesPrice.value
            acc[2].workingStepPrices.push({
              ...priceSummary,
              bomItemPointer: undefined,
              description: priceSummary.description,
              primaryWorkingStep: priceSummary.workingStep.primaryWorkingStep,
              secondaryWorkingStep:
                priceSummary.workingStep.secondaryWorkingStep,
              resource: priceSummary.workingStep?.resource?.name,
            })
          }
        } else if (
          props?.isMaterialVisible &&
          (priceSummary?.isMaterial ||
            priceSummary?.isPurchasePartWithoutArticle)
        ) {
          acc[1].totalSalesPriceMaterials.value +=
            priceSummary.totalSalesPrice.value
          acc[1].materialPrices.push({
            ...priceSummary,
            bomItemPointer: undefined,
            description: priceSummary.description,
            materialId: priceSummary.materialId,
            materialDescription: priceSummary.description,
            materialSalesPrice: priceSummary.totalSalesPrice,
          })
          if (acc[2]) {
            acc[2].totalSalesPriceMaterials.value +=
              priceSummary.totalSalesPrice.value
            acc[2].materialPrices.push({
              ...priceSummary,
              bomItemPointer: undefined,
              description: priceSummary.description,
            })
          }
        }

        return acc
      }, initialState)

      graphData[0] &&
        graphData[0].workingStepPrices.sort(
          (a, b) => b.totalSalesPrice.value - a.totalSalesPrice.value
        )
      graphData[1] &&
        graphData[1].materialPrices.sort(
          (a, b) => b.totalSalesPrice.value - a.totalSalesPrice.value
        )
      graphData[2] &&
        graphData[2].workingStepPrices.sort(
          (a, b) => b.totalSalesPrice.value - a.totalSalesPrice.value
        )
      graphData[2] &&
        graphData[2].materialPrices.sort(
          (a, b) => b.totalSalesPrice.value - a.totalSalesPrice.value
        )

      return graphData
    },
    isEqual
  )

  const priceSummaries = useAppSelector((state: RootAppState) => {
    return props.priceSummaryKeys
      .map((x) => {
        // const p = { ...state.project.priceSummaries[x] }
        // p.discountValue = {
        //   ...p.discountValue,
        //   value: p.discountValue.value * -1,
        // }
        return {
          ...state.project.priceSummaries[x],
          category: state.project.priceSummaries[x]?.isMaterial
            ? 'material'
            : 'workingSteps',
        }
      })
      .sort((a, b) => b.totalSalesPrice?.value - a.totalSalesPrice?.value)
  }, isEqual)

  const activeDataKey = useRef<string | null>(null)
  const handleChangeDataKey = useCallback((dataKey: keyof GraphData) => {
    activeDataKey.current = dataKey
  }, [])

  return (
    <ResponsiveContainer width={'100%'} height={props.containerHeight || 204}>
      <BarChart data={priceSummariesSum}>
        {/* <Tooltip /> */}
        <CartesianGrid strokeDasharray={'3 3'} />
        <Tooltip
          cursor={false}
          content={(tooltipProps) => {
            return (
              <TargetPriceTooltip
                {...tooltipProps}
                activeDataKey={activeDataKey.current}
                priceScope={PriceScope.Total}
              />
            )
          }}
          wrapperStyle={{
            zIndex: 1000,
          }}
          offset={40}
          isAnimationActive={false}
        />
        <XAxis
          dataKey="category"
          type="category"
          // interval={0}
          tickMargin={12}
          height={42}
        />
        <YAxis
          type="number"
          // ticks={priceSummaries
          //   .map((x) => x.totalSalesPrice.value)
          //   .sort()
          //   .concat(projectNetSalesPrice.value)}
          tick={
            <MoneyAxisTick defaultMoney={priceSummaries[0].totalSalesPrice} />
          }
          minTickGap={10}
          interval={'preserveStartEnd'}
          allowDataOverflow={false}
          domain={[0, 'dataMax']}
        />
        {props?.isWorkingStepVisible &&
          priceSummariesSum[0].workingStepPrices.map((price, i) => {
            return (
              <Bar
                dataKey={`workingStepPrices.${i}.totalSalesPrice.value`}
                key={`${price.description}-${i}`}
                stackId="a"
                fill={theme.palette.info.light}
                maxBarSize={56}
                stroke={theme.palette.common.black}
                strokeWidth={1}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => {
                  handleChangeDataKey(
                    `workingStepPrices.${i}` as keyof GraphData
                  )
                }}
                onMouseLeave={() => {
                  handleChangeDataKey(undefined)
                }}
                onClick={() => {
                  props.scrollAndFilter(price)
                  // handlePartTypeClicked(x.childrens[key].id)
                }}
              />
            )
          })}
        {props?.isMaterialVisible &&
          priceSummariesSum[1].materialPrices.map((price, i) => {
            return (
              <Bar
                dataKey={`materialPrices.${i}.totalSalesPrice.value`}
                key={`${priceSummariesSum[1].description}-${i}`}
                stackId="a"
                style={{ cursor: 'pointer' }}
                fill={theme.palette.info.dark}
                maxBarSize={56}
                stroke={theme.palette.common.black}
                strokeWidth={1}
                onMouseEnter={() =>
                  handleChangeDataKey(`materialPrices.${i}` as keyof GraphData)
                }
                onMouseLeave={() => handleChangeDataKey(undefined)}
                onClick={() => props.scrollAndFilter(price)}
              ></Bar>
            )
          })}
      </BarChart>
    </ResponsiveContainer>
  )
}
