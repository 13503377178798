import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useAppController } from 'customHooks/useAppController'
import { useCallback, useEffect, useState } from 'react'
import {
  ProjectProvideRawMaterialSettingDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useShowException } from 'utils/useShowException'
import { ProjectProvideRawMaterialSettingController } from './ProjectProvideRawMaterialSetting.controller'

import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'ProjectMaterialEstimationSettings' })(
  (theme) => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    listItem: {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)

export type Props = {
  onClose: () => void
}

export type ProjectProvideRawMaterialSettingsDialogProps = {
  open?: boolean
  onClose: () => void
}

export const ProjectProvideRawMaterialSettingsDialog = (
  props: ProjectProvideRawMaterialSettingsDialogProps
) => {
  const { classes } = useStyles()
  const ShowException = useShowException('project material estimation settings')

  const { controller, loading } = useAppController(
    () => new ProjectProvideRawMaterialSettingController()
  )

  const localizedWorkingStep = useLocalizedWorkingStepLabel()

  const [providedMaterialWorkingStep, setProvidedMaterialWorkingSteps] =
    useState<ProjectProvideRawMaterialSettingDto[]>([])

  const getData = useCallback(() => {
    controller
      .getProjectProvideRawMaterialSettings()
      .then((resp) => {
        setProvidedMaterialWorkingSteps(resp?.selected)
      })
      .catch(ShowException)
  }, [ShowException, controller])

  useEffect(() => {
    getData()
  }, [getData])

  const handleChange = (workingStepType: WorkingStepType, checked: boolean) => {
    setProvidedMaterialWorkingSteps((prev) => {
      const newState = prev.map((x) => {
        if (x.workingStepType === workingStepType) {
          x.customerProvidesMaterial = checked
        }
        return x
      })

      return newState
    })
  }

  const handleSave = async () => {
    try {
      await controller.setProjectProvideRawMaterialSettings({
        workingSteps: providedMaterialWorkingStep,
      })

      props.onClose()
    } catch (err) {
      ShowException(err)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <LocalizedTypography translationKey="project:provided-material-dialog-title">
          Project Material Estimation Settings
        </LocalizedTypography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <LocalizedTypography translationKey="project:provided-material-desc1">
          select the working steps for which the raw material is provided by the
          buying party
        </LocalizedTypography>
        <ul>
          {providedMaterialWorkingStep?.map((providedSetting) => {
            const isProvided = providedSetting.customerProvidesMaterial

            return (
              <li
                key={providedSetting.workingStepType}
                className={classes.listItem}
                onClick={() => {
                  handleChange(providedSetting.workingStepType, !isProvided)
                }}
              >
                <FormControlLabel
                  onClick={(e) => e.stopPropagation()}
                  control={
                    <Checkbox
                      checked={isProvided}
                      indeterminate={isProvided === undefined}
                      onChange={(e, checked) => {
                        e.stopPropagation()
                        handleChange(providedSetting.workingStepType, checked)
                      }}
                    />
                  }
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5em',
                      }}
                    >
                      <WorkingStepIconSvg
                        disableTitle
                        workingStepType={providedSetting.workingStepType}
                        attributes={{ width: '32px' }}
                      />
                      <Typography
                        style={{
                          width: '100%',
                        }}
                      >
                        {localizedWorkingStep(providedSetting.workingStepType)}
                      </Typography>
                    </div>
                  }
                />
              </li>
            )
          })}
        </ul>
        <LocalizedTypography
          translationKey="project:provided-material-desc2"
          variant="caption"
        >
          * the system will not calculate the raw material cost for the selected
          working steps
        </LocalizedTypography>
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={props.onClose}
          onSaveButtonClicked={handleSave}
          saving={loading['UpdateProjectProvideRawMaterialSettings']}
        />
      </DialogActions>
    </Dialog>
  )
}
