import { BomItemActivityKeywordsInput } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivityKeywordsInput'
import React from 'react'
import { RenderEditCellProps } from 'react-data-grid'
import { Key } from 'utils/keyCodes'
import { useDataGridEditorNavigation } from './useDataGridEditorNavigation'

const minimalKeywords = {
  materialgroup: [
    {
      category: 'meterialgroup',
      originalKeyword: 'sheet',
      translationKey: 'sheet',
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MaterialKeywordDataGridEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  rootElementClassName,
  showGroups: categoriesToShow,
  disableLabel,
}: RenderEditCellProps<TRow, TSummaryRow> & {
  rootElementClassName?: string
  showGroups?: string[]
  disableLabel?: boolean
}) {
  const { handleNavigationKeys } = useDataGridEditorNavigation(() => {
    onClose(true, true)
  }, true)
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Key.Enter) {
      // needed to not close the editor on enter (ie user selected a keyword with Enter key)
      event.preventDefault()
      event.stopPropagation()
    } else {
      handleNavigationKeys(event)
    }
  }

  return (
    <div
      onKeyDown={handleKeyDown}
      style={{
        width: '100%',
        height: '100%',
        padding: '8px 16px',
      }}
    >
      <BomItemActivityKeywordsInput
        bomItemPointer={row?.['bomItemPointer']}
        activityId={row?.['activityId']}
        disableLabel={disableLabel}
        disableUnderline
        saveOnChange
        initialKeywords={row?.['keywords'] ?? minimalKeywords}
        onSaveHandler={(tokens) => {
          onRowChange({
            ...row,
            [column.key]: tokens,
          })
        }}
        startOpen
        onBlur={() => onClose(true)}
        categoriesToShow={categoriesToShow}
      />
      {/* <MaterialKeywordsInput
        rootMaterialKeywordsInputClassName={rootElementClassName}
        onChangeTokens={(tokens) => {
          onRowChange({
            ...row,
            [column.key]: tokens,
          })
        }}
        enableSaveOnChange
        selectedTokens={
          !row['thumbnail3d'] && _.isEmpty(row[column.key])
            ? {
                materialgroup: [
                  {
                    category: 'meterialgroup',
                    originalKeyword: 'sheet',
                    translationKey: 'sheet',
                  },
                ],
              }
            : row[column.key]
        }
        onBlur={() => onClose(true)}
        autoFocus
        startOpen
        disableUnderline
        disableLabel={disableLabel}
        showGroups={showGroups}
      /> */}
    </div>
  )
}
