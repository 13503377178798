import { Box, TextField, Typography } from '@mui/material'
import Form from '@rjsf/mui'
import { customizeValidator } from '@rjsf/validator-ajv8'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { useAppController } from 'customHooks/useAppController'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { CorrosionClassificationField } from 'features/Corrosion/CorrosionClassificationField'
import { isEmpty, isEqual, merge } from 'lodash'
import { AdditionalWorkingStepFormConfiguration } from 'model/AdditionalWorkingStep/AdditionalWorkingStepForm'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  forwardRef,
  ForwardRefRenderFunction,
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { WorkingStepWithData } from '../store/AdditionalWorkingStepsReducer'
import { ArrayFieldTemplate } from './Templates/ArrayFieldTemplate'
import { FieldTemplate } from './Templates/FieldTemplate'
import { ObjectFieldTemplate } from './Templates/ObjectFieldTemplate'
import { RJSFQuantityField } from './Templates/RJSFQuantityField'
import { RJSFBaseInputTemplate } from './Templates/RJSFTextField'
import { RalSelectorField } from './Templates/RalSelectorField'
import { SelectWidget } from './Widgets/SelectWidget'
import { ZincCodeWidget } from './Widgets/ZincCodeWidget'
import { localizedErrors } from './localizedErrors'

type Props = {
  bomItemPointer: BomItemPointer
  workingStepWithData: WorkingStepWithData
  onWorkingStepChanged: (workingStep: WorkingStepWithData) => void
  onValidationError: (
    workingStep: WorkingStepWithData,
    errorSchema: Record<string, { __errors: string[] }>
  ) => void
  removeValidations: (workingStepType: WorkingStepType, itemId: string) => void
}

export type WorkingStepFormRef = {
  reload: () => void
}

const useStyles = makeStyles({ name: 'WorkingStepForm' })(() => ({
  form: {
    // '& .MuiBox-root > div': {
    //   border: '1px solid red',
    //   gridColumn: '1 / -1',
    // },
  },
}))

const _WorkingStepForm: ForwardRefRenderFunction<WorkingStepFormRef, Props> = (
  props,
  ref
) => {
  const { t } = useTranslation()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { controller, loading } = useAppController(
    () => new WorkingStepsController()
  )
  const projectId = useAppSelector((state) => state.project.activeProject?.id)
  const { classes } = useStyles()

  const bomItemActivity = useAppSelector(
    bomItemActivitiesSelectors.byWorkingStep(
      props.bomItemPointer,
      props.workingStepWithData.primaryWorkingStep
    ),
    isEqual
  )

  useEffect(() => {
    if (
      !props.workingStepWithData.comment &&
      bomItemActivity?.comment &&
      bomItemActivity?.comment !== props.workingStepWithData.comment
    ) {
      props.onWorkingStepChanged({
        ...props.workingStepWithData,
        comment: bomItemActivity?.comment,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bomItemActivity?.comment])

  const [workingStepForm, setWorkingStepForm] =
    useState<AdditionalWorkingStepFormConfiguration>({
      jsonSchema: null,
      formData: null,
      uiSchema: null,
      workingStepType: props.workingStepWithData?.primaryWorkingStep,
    })

  const formRef = useRef(null)

  useEffect(() => {
    setWorkingStepForm((state) => ({
      ...state,
      formData: props.workingStepWithData?.formData,
      workingStepType: props.workingStepWithData?.primaryWorkingStep,
    }))
  }, [
    props.workingStepWithData?.formData,
    props.workingStepWithData?.primaryWorkingStep,
  ])

  const fetchWorkingStepForm = useCallback(
    (isReload?: boolean) => {
      controller
        .GetWorkingStepForm(
          projectId,
          props.bomItemPointer,
          props.workingStepWithData.primaryWorkingStep,
          isReload
        )
        .then((res) => {
          if (res) {
            if (isReload) {
              if (workingStepForm?.workingStepType === res.workingStepType) {
                setWorkingStepForm((state) => ({
                  ...state,
                  formData: res.formData,
                  workingStepType: props.workingStepWithData.primaryWorkingStep,
                }))
              } else {
                console.warn(
                  'working steps are not equal, working step form reload will not be applied',
                  workingStepForm?.workingStepType,
                  res.workingStepType
                )
              }
            } else {
              const _formData = merge(
                {},
                res.formData,
                props.workingStepWithData?.formData
              )

              setWorkingStepForm({
                ...res,
                formData: _formData,
                workingStepType: res.workingStepType,
              })
            }
          }
        })
        .catch((reason) => {
          setWorkingStepForm(undefined)
          ShowException(t('project:additional-working-steps--title'), reason)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      controller,
      projectId,
      props.bomItemPointer,
      props.workingStepWithData?.formData,
      props.workingStepWithData.primaryWorkingStep,
      t,
    ]
  )

  useEffect(() => {
    fetchWorkingStepForm()
    return () => {
      controller.CancelRequests()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workingStepWithData?.primaryWorkingStep])

  useImperativeHandle(ref, () => ({
    reload: () => fetchWorkingStepForm(true),
  }))

  const { t: tErrors } = useTranslation('errors')
  const validator = customizeValidator({}, (errors) =>
    localizedErrors(errors, tErrors)
  )

  return (
    <Suspense>
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: (theme) => theme.spacing(2),
            padding: (theme) => theme.spacing(0, 2),
          }}
        >
          <WorkingStepIconSvg
            workingStepType={props.workingStepWithData.primaryWorkingStep}
            attributes={{ width: '56px' }}
          />
          {getLocalizedWorkingStep(
            props.workingStepWithData.primaryWorkingStep
          )}
        </Typography>
        <Box sx={{ p: 2 }}>
          <TextField
            placeholder={t('common:comment', 'comment')}
            inputProps={{
              style: {
                fontFamily: 'monospace',
              },
              maxLength: 140,
            }}
            multiline
            onFocus={(e) => e.target.select()}
            value={props.workingStepWithData.comment || ''}
            onChange={(e) => {
              props.onWorkingStepChanged({
                ...props.workingStepWithData,
                comment: e.target.value,
              })
            }}
            fullWidth
            helperText={
              140 - props.workingStepWithData.comment?.length <= 40
                ? t('common:chars-left', {
                    count: 140 - props.workingStepWithData.comment.length,
                  })
                : null
            }
          />
        </Box>
        <LoadingContainer
          loading={
            loading[WorkingStepsController.WorkingStepsOperations.GET_FORM]
          }
          noMessage
        >
          {isEmpty(workingStepForm?.jsonSchema) ? null : workingStepForm
              .jsonSchema.title === 'EmptyDto' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                p: 2,
              }}
            >
              <Typography variant="h6">
                {t(
                  'project:additional-working-steps--no-form',
                  'additional details not needed'
                )}
              </Typography>
            </Box>
          ) : (
            <div>
              <Form
                schema={workingStepForm.jsonSchema}
                uiSchema={workingStepForm.uiSchema}
                formData={workingStepForm.formData}
                id="working-step-form"
                ref={formRef}
                className={classes.form}
                templates={{
                  FieldTemplate: FieldTemplate,
                  ArrayFieldTemplate: ArrayFieldTemplate,
                  ObjectFieldTemplate: ObjectFieldTemplate,
                  ButtonTemplates: {
                    SubmitButton: () => null,
                  },
                  FieldErrorTemplate: () => null,
                  BaseInputTemplate: RJSFBaseInputTemplate,
                }}
                fields={{
                  quantityField: RJSFQuantityField as never,
                  ralSelectorField: RalSelectorField as never,
                  corrosionClassificationField:
                    CorrosionClassificationField as never,
                  zincCode: ZincCodeWidget as never,
                }}
                widgets={{
                  SelectWidget: SelectWidget,
                }}
                onChange={(data) => {
                  setWorkingStepForm((state) => ({
                    ...state,
                    formData: data.formData,
                  }))
                  props.onWorkingStepChanged({
                    ...props.workingStepWithData,
                    formData: data.formData,
                  })
                }}
                onSubmit={(data) => {
                  props.onWorkingStepChanged({
                    ...props.workingStepWithData,
                    formData: data.formData,
                  })
                }}
                validator={validator}
                liveValidate
                showErrorList={false}
                formContext={{
                  onValidationError: (
                    errors: Record<string, { __errors: string[] }>
                  ) => {
                    setTimeout(() => {
                      if (errors) {
                        props.onValidationError(
                          props.workingStepWithData,
                          errors
                        )
                      }
                    })
                  },
                  onItemRemoved: (itemId: string) => {
                    props.removeValidations(
                      props.workingStepWithData.primaryWorkingStep,
                      itemId
                    )
                  },
                }}
              />
            </div>
          )}
          {/* <JsonViewer data={workingStepForm.jsonSchema} />
          <JsonViewer data={workingStepForm.uiSchema} />
          <JsonViewer data={workingStepForm.formData} /> */}
        </LoadingContainer>
      </div>
    </Suspense>
  )
}

const WorkingStepForm = forwardRef(_WorkingStepForm)

export default WorkingStepForm
