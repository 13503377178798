import { FileCopyOutlined } from '@mui/icons-material'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const CopyActivityKeywordsMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const bomItemActivities: BoMItemActivityDto[] = useAppSelector(
    bomItemActivitiesSelectors.allActivities(bomItemPointer),
    isEqual
  )

  return (
    <BomItemMenuItem
      primaryTextTranslationKey="project:copy-material"
      icon={<FileCopyOutlined />}
      disableIfNotEditable
      action={MaterialActions.copyMaterial}
      actionParams={{
        baseBomItemPointer: bomItemPointer,
        activities: bomItemActivities,
      }}
      closeOnClick
      onClose={onClose}
    />
  )
}
