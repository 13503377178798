import { BaseController } from 'controllers/BaseController'
import { BomItemSearch } from 'model/BomItemSearchModel'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'

interface ISearchAPI {
  Search: (q: string, searchType: SearchType) => Promise<BomItemSearch>
}

export type SearchType = 'freeText' | 'filter' | 'groups'

class SearchAPI extends InternalAPI implements ISearchAPI {
  constructor(
    partyId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super()
    this.relativePath = `/api/parties/${partyId}/bomItems`
  }

  public async Search(q: string, searchType: SearchType) {
    let params = {}

    switch (searchType) {
      case 'freeText':
        params = { q: q }
        break
      case 'filter':
        params = { filter: q }
        break
      case 'groups':
        params = { facet: q }
        break
    }

    return await this.GetAsync<BomItemSearch>({
      id: 'search-bom-items',
      params: params,
      relativePath: '',
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class SearchBomItemsController extends BaseController<ISearchAPI> {
  constructor(partyId: string) {
    super((onRequestChanged) => new SearchAPI(partyId, onRequestChanged))
  }

  public async Search(q: string, searchType: SearchType) {
    try {
      return await this.api.Search(q, searchType)
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }
}
