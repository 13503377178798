import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import LocizeTranslationBackend from 'i18next-locize-backend'
import { initReactI18next, useTranslation } from 'react-i18next'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AppCore from 'AppCore'
import SupportedLanguages from 'components/Localization/SupportedLanguages.json'
import Layout from './components/Layout'
import { SegmentService, TelemetryService } from './services/Telemetry'

import moment from 'moment'

import 'moment/dist/locale/de'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/pt-br'

TelemetryService.getInstance().initialize()
SegmentService.getInstance()

const translationDevMode =
  process.env.NODE_ENV === 'development' &&
  Boolean(import.meta.env.VITE_LOCIZE_PROJECT_ID)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(LocizeTranslationBackend)
  .init({
    debug: translationDevMode,
    saveMissing: translationDevMode,
    updateMissing: false, //NEVER SET TO TRUE (it will update the translations in locize and can cause issues with the material keywords)
    preload: ['en'],
    fallbackLng: 'en',
    ns: ['common', 'project', 'errors', 'getting-started'],
    defaultNS: 'common',
    fallbackNS: 'common',
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format, lng) => {
        switch (format) {
          case 'intlDate': {
            return new Date(value).toLocaleDateString(lng)
          }
          case 'intlNumber': {
            return parseFloat(value).toLocaleString(lng)
          }
          default:
            return value
        }
      },
    },
    nonExplicitSupportedLngs: true,
    supportedLngs: SupportedLanguages.supported_languages,
    backend: {
      projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
      apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
      referenceLng: 'en',
      allowedAddOrUpdateHosts: ['local.rhodium24.io'],
    },
  })
  .then(() => {
    moment.locale(i18n.language)

    // moment.updateLocale(i18n.language, {
    //   week: {
    //     dow: 0,
    //     doy: 6,
    //   },
    // })

    if (i18n.options.updateMissing) {
      throw new Error("Don't set updateMissing to true in the i18n options!")
    }
  })

i18n.on('languageChanged', (lng) => {
  moment.locale(lng.toLowerCase())
})

const App = () => {
  const { i18n: _i18n } = useTranslation()
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={_i18n.language}
      >
        <Layout>
          <AppCore />
        </Layout>
      </LocalizationProvider>
    </>
  )
}

export default App
