import { Button, ButtonGroup, Drawer, Typography } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import OperationsLogTimeline from './GeometriesLogTimeline'
import { useProjectOperationsStyles } from './ProjectOperationsLogsDrawer.styles'
import { useOperationsLogs } from './useOperationsLogs'

export type ProjectOperationsProps = {
  open?: boolean
  onClose?: () => void
}

export const ProjectOperationsDialog = (props: ProjectOperationsProps) => {
  const { classes } = useProjectOperationsStyles()

  const { handleDeleteAllLogs, filteredOperationsLog } = useOperationsLogs(200)

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <LocalizedTypography
            translationKey="project:project-operations-log-title"
            variant="h5"
          >
            project operations logs
          </LocalizedTypography>
        </div>
        <ButtonGroup fullWidth>
          <Button onClick={props.onClose}>Close</Button>
          {/* <Button onClick={debouncedGetOperations}>Refresh</Button> */}
          <Button onClick={() => handleDeleteAllLogs(props)} color="secondary">
            Delete All
          </Button>
        </ButtonGroup>

        <LoadingContainer
          loading={!filteredOperationsLog}
          noMessage
          empty={
            filteredOperationsLog?.length === 0 && (
              <Typography>no operations found</Typography>
            )
          }
        >
          <OperationsLogTimeline geometryLogs={filteredOperationsLog} />
          {/* <pre>{JSON.stringify(operationsLog, null, 2)}</pre> */}
        </LoadingContainer>
      </div>
    </Drawer>
  )
}
