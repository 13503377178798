/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { FileWithDetails } from 'model/FileWithDetails'
import { Attachment } from 'model/Project/Attachment'
import { BoMItemRow } from 'model/Project/BoMItemRow'
import { batch } from 'react-redux'
import {
  AssemblyHeaderDto,
  MaterialSummaryDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { UnloadProjectList } from 'store/ProjectList/asyncActions/unloadProjectList'
import { action } from 'typesafe-actions'
import { Debugger } from 'utils/debugger'
import { AppState } from '..'
import {
  MoneyDto,
  PriceSummaryDto,
  ProjectDto,
  QuotationDetailsDto,
  RowDto,
  WorkingStepDto,
  WorkingStepType,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import { RecursivePartial } from '../../utils/RecursivePartial'
import {
  APICall,
  ErrorMessage,
  ShowException,
  ToggleNewItemsModal,
} from '../Application/appActions'
import { APICallArgs, ApplicationActionTypes } from '../Application/appState'
import { getContext } from '../getContext'
import { projectConstants } from './ProjectConstants'
import { BoMItemsFilter } from './ProjectTypes'

// const PROJECT_SAVED_MESSAGE = 'project saved'

/**
 * deprecated
 * you should use the newProjectActions instead
 */
const oldProjectActions = {
  GetCostPriceExplanation:
    (_projectId: string, rowId: string, setData: (str: string) => void) =>
    (dispatch, getState: () => AppState) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'GetCostPriceExplanation',
            configuration: {
              method: 'GET',
              url: `api/parties/${organizationId}/projects/${projectId}/items/${rowId}/materials/costPriceExplanation`,
              onSuccess: (explanation: string) => {
                setData(explanation)
                dispatch({
                  type: projectConstants.SET_CURRENT_PRICE_EXPLANATION,
                  payload: explanation,
                })
              },
              onError: (message) => {
                ShowException('project', message)
              },
            },
          })
        )
      )
    },
  CleanCurrentCostPriceExplanation: () => ({
    type: projectConstants.SET_CURRENT_PRICE_EXPLANATION,
    payload: undefined,
  }),
  SetQuotationDetails:
    (projectId: string, quotationDetails: QuotationDetailsDto) =>
    (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'SaveQuotationDetails',
            configuration: {
              method: 'POST',
              url: `api/parties/${organizationId}/projects/${projectId}/quotation`,
              data: quotationDetails,
              onSuccess: (quotationDetails: QuotationDetailsDto) => {
                dispatch({
                  type: projectConstants.QUOTATION_DETAILS_SAVED,
                  quotationDetails,
                })

                // SuccessMessage('Save', 'Quotation saved', {
                //   showCloseButton: false,
                // })
              },
            },
          })
        )
      )
    },
  // LoadPartType:
  //   (payload: { rowId: string }) => (dispatch, getState: () => AppState) => {
  //     const { organizationId, projectId } = getContext(getState)

  //     return action(
  //       ApplicationActionTypes.API,
  //       new APICallArgs({
  //         label: 'GetPartType',
  //         configuration: {
  //           method: 'GET',
  //           url: `api/parties/${organizationId}/projects/${projectId}/items/${payload.rowId}`,
  //           onSuccess: (row: RowDto) => {
  //             dispatch(oldProjectActions.ReplaceRow(row))
  //           },
  //           onError: (message) => {
  //             ErrorMessage('project', message)
  //           },
  //         },
  //       })
  //     )
  //   },

  ProjectInfoReceived:
    (project: ProjectDto) => (dispatch, getState: () => AppState) => {
      if (
        getState().project.activeProject &&
        getState().project.activeProject?.id !== project.id
      ) {
        return
      }

      dispatch({
        type: projectConstants.PROJECT_INFO_RECEIVED,
        project,
      })
    },

  LoadProjectInfo:
    (
      payload: {
        projectId: string
        shouldSubscribleToUpdates: boolean
      },
      selectedBomItemId?: string
    ) =>
    (dispatch, getState: () => AppState) => {
      const { partyId: organizationId } = getContext(getState)

      dispatch(UnloadProjectList())

      const startTime = performance.now()

      const logTime = (message) => {
        Debugger.Log(
          'info',
          message,
          ((performance.now() - startTime) / 1000).toFixed(2)
        )
      }

      logTime('loadProjectInfo Started')
      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'LoadProjectInfo',
            configuration: {
              method: 'GET',
              url: `api/parties/${organizationId}/projects/${payload.projectId}`,
              onSuccess: (data: ProjectDto) => {
                if (
                  getState().project.activeProject &&
                  getState().project.activeProject?.id !== data.id
                ) {
                  return
                }
                batch(() => {
                  logTime('start processing api response')
                  dispatch({
                    type: projectConstants.PROJECT_INFO_RECEIVED,
                    project: data,
                    selectedBomItemId,
                  })
                  logTime('end processing api response')

                  dispatch({
                    type: projectConstants.UPDATE_HEADER_FILTERED_PARTS,
                  })

                  // if (payload.shouldSubscribleToUpdates) {
                  //   setTimeout(() => {
                  //     SubscribeToProjectUpdates(
                  //       dispatch,
                  //       organizationId,
                  //       payload.projectId,
                  //       !data.isBuyingPartyView
                  //     )
                  //   })
                  // }
                  logTime('state loaded and processed')
                })
              },
              onError: (err) => {
                ShowException('project', err)
                if (!getState().project.activeProject) {
                  // appHistory.push('/app')
                }
                // ErrorMessage(
                //   'project',
                //   message ??
                //   'unexpected error. try to reload your project from the project list'
                // )
                // appHistory.push('/app/projects')
              },
            },
          })
        )
      )
    },
  ReloadProjectInfo: () => (dispatch, getState: () => AppState) => {
    dispatch(
      oldProjectActions.LoadProjectInfo({
        projectId: getState().project.activeProject?.id,
        shouldSubscribleToUpdates: true,
      })
    )
  },

  SaveName:
    (rowOrAssemblyId: string, partName: string, onRequestEnded: () => void) =>
    (dispatch, getState: () => AppState) => {
      const { projectId, partyId: organizationId } = getContext(getState)

      dispatch(
        APICall(
          new APICallArgs({
            label: 'save-partName',
            configuration: {
              url: `api/parties/${organizationId}/projects/${projectId}/rows/partName`,
              method: 'PUT',
              data: { rowOrAssemblyId: rowOrAssemblyId, partName: partName },
              onSuccess: () => {
                // SuccessMessage('project', PROJECT_SAVED_MESSAGE)
                onRequestEnded()
              },
              onError: (message) => {
                ShowException('project', message)
                onRequestEnded()
                dispatch(
                  oldProjectActions.LoadProjectInfo({
                    projectId,
                    shouldSubscribleToUpdates: false,
                  })
                )
              },
            },
          })
        )
      )
    },
  SaveTargetPrice:
    (bomItemId: string, newTargetPrice: MoneyDto, onFinish?: () => void) =>
    (dispatch, getState: () => AppState) => {
      const { projectId, partyId: organizationId } = getContext(getState)

      // save
      dispatch(
        APICall({
          label: 'save-target-price',
          configuration: {
            url: `api/parties/${organizationId}/projects/${projectId}/TargetSalesPricePerItem`,
            method: 'PUT',
            data: {
              id: bomItemId,
              targetSalesPricePerItem: newTargetPrice,
            },
            onSuccess: () => {
              onFinish && onFinish()
            },
            onError: (message) => {
              ShowException('target price', message)
              onFinish && onFinish()
              dispatch(oldProjectActions.ReloadProjectInfo())
            },
          },
        })
      )
    },

  SetTargetPrice:
    (
      bomItemId: string,
      newTargetPrice: MoneyDto,
      isAssemblyHeader?: boolean,
      isProject?: boolean
    ) =>
    (dispatch) => {
      // update it locally
      if (isProject) {
        dispatch(
          oldProjectActions.SetActiveProjectProperties({
            financial: {
              targetSalesPricePerItem: newTargetPrice,
            },
          })
        )
      } else if (isAssemblyHeader) {
        dispatch(
          oldProjectActions.SetAssemblyProperties(bomItemId, {
            assembly: {
              financial: {
                targetSalesPricePerItem: newTargetPrice,
              },
            },
          })
        )
      } else {
        dispatch(
          oldProjectActions.SetRowProperties(bomItemId, {
            financial: {
              targetSalesPricePerItem: newTargetPrice,
            },
          })
        )
      }
    },

  SaveQuantity:
    (
      rowId: string,
      quantity: number,
      isProject = false,
      onRequestEnded?: () => void
    ) =>
    (dispatch, getState: () => AppState) => {
      const { projectId, partyId: organizationId } = getContext(getState)

      // dispatch(projectActions.RemoveAllCalculatedFields())

      // if (getState().project.flatView[rowId].type === BoMItemType.assemblyHeader) {
      //   dispatch(
      //     projectActions.SetRowProperties(rowId, {
      //       assembly: { financial: { quantity: quantity } },
      //     })
      //   )
      // } else {
      //   dispatch(
      //     projectActions.SetRowProperties(rowId, {
      //       financial: { quantity: quantity },
      //     })
      //   )
      // }

      let url = `api/parties/${organizationId}/projects/${projectId}`

      if (isProject) url += '/quantities'
      else url += `/items/${rowId}/quantities`

      dispatch(
        APICall(
          new APICallArgs({
            label: 'save-quantity',
            configuration: {
              url: url,
              method: 'PUT',
              data: { quantity: quantity },
              onSuccess: () => {
                // SuccessMessage('project', PROJECT_SAVED_MESSAGE)
                onRequestEnded && onRequestEnded()
              },
              onError: (message) => {
                ErrorMessage('project', message)
                onRequestEnded && onRequestEnded()
                dispatch(oldProjectActions.LoadProjectInfo(projectId))
              },
            },
          })
        )
      )
    },
  SaveProjectReference:
    (projectReference: string, onSuccess?: () => void) =>
    (dispatch, getState: () => AppState) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      dispatch(
        oldProjectActions.SetActiveProjectProperties({
          projectReference: projectReference,
        })
      )

      dispatch(
        APICall({
          label: 'SaveProjectReference',
          configuration: {
            url: `api/parties/${organizationId}/projects/${projectId}/reference`,
            method: 'PUT',
            data: { projectReference: projectReference },
            onSuccess: () => {
              onSuccess && onSuccess()
              // SuccessMessage('project', PROJECT_SAVED_MESSAGE)
            },
            onError: (message) => {
              ErrorMessage('project', message)
              oldProjectActions.LoadProjectInfo(projectId)
            },
          },
        })
      )
    },
  SaveProjectQuantity:
    (quantity: number) => (dispatch, getState: () => AppState) => {
      const { projectId } = getContext(getState)

      batch(() => {
        dispatch(oldProjectActions.SaveQuantity(projectId, quantity, true))
        dispatch(oldProjectActions.RemoveAllCalculatedFields())
      })
    },

  DiscardChanges: () => ({
    type: projectConstants.DISCARD_CHANGES,
  }),

  DownloadAssemblyFiles:
    (assemblyId: string) => (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id
      const projectId = getState().project.activeProject?.id

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'project/downloadRowFiles',
            configuration: {
              method: 'GET',
              url: `api/parties/${organizationId}/projects/${projectId}/assemblies/${assemblyId}/files`,
              onProgress: (progress) =>
                console.info('downloadRowFiles', progress),
              isFileDownload: true,
              onError: (err) => {
                ShowException('download files', err)
              },
            },
          })
        )
      )

      // SuccessMessage(
      //   'preparing files to download',
      //   'this operation may take a few minutes'
      // )
    },

  DownloadRowFiles:
    (rowId: string) =>
    (
      dispatch: (arg0: {
        type: ApplicationActionTypes.API
        payload: APICallArgs
      }) => void,
      getState: () => AppState
    ) => {
      const organizationId = getState().user.organizationContext.id
      const projectId = getState().project.activeProject?.id

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'project/downloadRowFiles',
            configuration: {
              method: 'GET',
              url: `api/parties/${organizationId}/projects/${projectId}/items/${rowId}/files`,
              onProgress: (progress) =>
                console.info('downloadRowFiles', progress),
              isFileDownload: true,
              onError: (err) => {
                ShowException('download files', err)
              },
            },
          })
        )
      )

      // SuccessMessage(
      //   'preparing files to download',
      //   'this operation may take a few minutes'
      // )
    },

  DownloadProjectFiles:
    (projectId: string) => (dispatch, getState: () => AppState) => {
      const organizationId = getState().user.organizationContext.id

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'project/downloadProjectFiles',
            configuration: {
              method: 'GET',
              url: `api/parties/${organizationId}/projects/${projectId}/files`,
              onProgress: (progress) => {
                console.info('download progress', progress)
              },
              isFileDownload: true,
              onError: (err) => {
                ShowException('download files', err)
              },
            },
          })
        )
      )
    },
  UploadFiles:
    (files: FileWithDetails[]) => (dispatch, getState: () => AppState) => {
      const { partyId: organizationId, projectId } = getContext(getState)

      const fileDataWithFixedTokens = files.map((file) => {
        return {
          ...file,
          tokens: Object.values(file.tokens || {})
            .flat()
            .map((x) => x.originalKeyword.trim()),
        }
      })

      dispatch(
        action(
          ApplicationActionTypes.API,
          new APICallArgs({
            label: 'UploadFiles',
            configuration: {
              method: 'POST',
              url: `api/parties/${organizationId}/projects/${projectId}/uploadGeometryWithDetails`,
              data: fileDataWithFixedTokens,
              isFileUpload: true,
              uploadParameterName: 'geometryFiles',
              onSuccess: () => {
                dispatch(ToggleNewItemsModal(false))
              },
              onError: (err: string) => {
                ErrorMessage('upload', err)
              },
              onProgress: (progress) => {
                dispatch(
                  newProjectActions.uploadProgress({
                    operation: 'upload-files-to-project',
                    progress: (progress.loaded / progress.total) * 100,
                  })
                )
              },
            },
          })
        )
      )
    },

  SetFilter:
    (filter: BoMItemsFilter, updateDisabledFilters?: boolean) => (dispatch) => {
      dispatch({
        type: projectConstants.SET_FILTER_PROPERTY,
        filter,
        updateDisabledFilters,
      })

      dispatch({
        type: projectConstants.UPDATE_HEADER_FILTERED_PARTS,
      })
    },

  AddFilterProperty: (filter: BoMItemsFilter) => ({
    type: projectConstants.ADD_FILTER_PROPERTY,
    filter,
  }),

  ToggleFilter:
    (
      filterToToggle:
        | 'WorkingStepFilter'
        | 'MaterialKeywordsFilter'
        | 'IssuesFilter'
    ) =>
    (dispatch) => {
      dispatch({
        type: projectConstants.TOGGLE_FILTER_ACTIVE,
        filterToToggle,
      })

      dispatch({
        type: projectConstants.UPDATE_HEADER_FILTERED_PARTS,
      })
    },

  ChangeLayout: (layout: 'list' | 'grid') => ({
    type: projectConstants.SET_LAYOUT_TYPE,
    layout,
  }),

  SetAssemblyProperties:
    (assemblyId: string, properties: RecursivePartial<AssemblyHeaderDto>) =>
    (dispatch) => {
      dispatch({
        type: projectConstants.SET_ASSEMBLY_PROPERTIES,
        assemblyId,
        properties,
      })
    },

  SetRowProperties: (
    rowId: string,
    newProps: RecursivePartial<BoMItemRow>,
    arrayMergeStrategy?: any //(target: any[], source: any[]) => any[]
  ) => ({
    type: projectConstants.SET_ROW_PROPERTIES,
    rowId,
    newProps,
    arrayMergeStrategy,
  }),

  AddRowToMaterialHeader: (
    rowId: string,
    materialSummaryDto: MaterialSummaryDto
  ) => ({
    type: projectConstants.AddRowToMaterialHeader,
    rowId,
    materialSummaryDto,
  }),

  ReplaceRow: (newRow: RowDto) => {
    return {
      type: projectConstants.REPLACE_ROW,
      newRow,
    }
  },
  SetActiveProjectProperties:
    (newProps: RecursivePartial<ProjectDto>) => (dispatch) => {
      dispatch({
        type: projectConstants.SET_PROJECT_PROPERTIES,
        properties: newProps,
      })
    },

  SetAdditionalWorkingSteps:
    (
      rowIds: string[],
      workingStepsToAdd: WorkingStepDto[],
      workingStepsToRemove: WorkingStepDto[]
    ) =>
    (dispatch) => {
      batch(() => {
        rowIds.forEach((rowId) => {
          dispatch({
            type: projectConstants.SET_ADDITIONAL_WORKING_STEPS,
            rowId: rowId,
            workingStepsToAdd,
            workingStepsToRemove,
          })
        })
      })
    },

  SetValidationHighlight: (aggregateId: string, isRow?: boolean) => ({
    type: projectConstants.SET_VALIDATION_HIGHLIGHT,
    aggregateId: aggregateId,
    isRow: isRow ?? true,
  }),

  RemoveAllValidationHightlight: () => ({
    type: projectConstants.REMOVE_ALL_VALIDATION_HIGHTLIGHT,
  }),

  ReplacePriceSummary: (newPriceSummary: PriceSummaryDto) => (dispatch) => {
    batch(() => {
      dispatch(oldProjectActions.RemoveAllCalculatedFields())
      dispatch({
        type: projectConstants.REPLACE_PRICE_SUMMARY,
        newPriceSummary: newPriceSummary,
      })
    })
  },

  RemoveAllCalculatedFields: () => ({
    type: projectConstants.REMOVE_ALL_CALCULATED_FIELDS,
  }),

  RemoveBomItemCalculatedFields: (bomItemId: string) => ({
    type: projectConstants.REMOVE_BOM_ITEM_CALCULATED_FIELDS,
    bomItemId,
  }),

  SetBomItemResource: (
    bomItemId: string,
    workingStepType: WorkingStepType,
    resourceId: string,
    resourceName: string
  ) => ({
    type: projectConstants.SET_BOM_ITEM_RESOURCE,
    bomItemId,
    workingStepType,
    resourceId,
    resourceName,
  }),

  IncludeBomItemAttachment: (bomItemId: string, attachments: Attachment[]) => ({
    type: projectConstants.SET_BOM_ITEM_ATTACHMENT,
    bomItemId,
    attachments,
  }),

  RemoveBomItemAttachment: (bomItemId: string, fileNames: string[]) => ({
    type: projectConstants.REMOVE_BOM_ITEM_ATTACHMENT,
    bomItemId,
    fileNames,
  }),

  SetProjectOperationPending: (pending: boolean) => ({
    type: projectConstants.SET_PROJECT_OPERATION_PENDING,
    pending,
  }),
}

export { oldProjectActions }

// async function SubscribeToProjectUpdates(
//   dispatch: any,
//   organizationId: string,
//   projectId: string,
//   isSellerRole: boolean
// ) {
//   // const hub = await SignalRService.GetHub()

//   // hub.unregister('onProjectProgress')

//   // hub.registerHandler('onProjectProgress', (notification: Notification) => {
//   //   dispatch(
//   //     NotificationActions.AddNotification(
//   //       notification.aggregateId,
//   //       notification
//   //     )
//   //   )
//   //   if (notification.shallUpdateUI) {
//   //     if (!notificationQueue) {
//   //       notificationQueue = new ProjectUpdateQueue(
//   //         organizationId,
//   //         projectId,
//   //         dispatch
//   //       )
//   //     }
//   //     notificationQueue.QueueNotification(notification)
//   //   }
//   // })

//   // const updateGroupName = `${projectId}_${isSellerRole ? 'sellers' : 'buyers'}`

//   // setTimeout(
//   //   () =>
//   //     hub.JoinGroup(
//   //       updateGroupName,
//   //       () => console.info('connected to project update group'),
//   //       true
//   //     ),
//   //   250
//   // )
// }
