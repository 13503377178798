/**
 * this component resamble the MUI TextField when disabled, but using just Typography will use less width
 @param
  props.label - the label of the textfield
  props.value - the value of the textfield
  props.labelProps - the props of the label
  props.valueProps - the props of the value
 */

import { Typography, TypographyProps } from '@mui/material'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useValueLabelStyles } from './ValueLabel.styles'

export type ValueLabelClasses = {
  root?: string
}

export type ValueLabelProps = {
  value: string | number
  label?: (string | number) | React.ReactNode
  labelProps?: TypographyProps
  valueProps?: TypographyProps
  isLeftAligned?: boolean
  fullWidth?: boolean
  disableMinWidth?: boolean
  onClick?: () => void
  valueLabelRootStyles?: CSSProperties
  labelTranslationKey?: string
  emptyString?: string
  classes?: ValueLabelClasses
  valueVariant?: TypographyProps['variant']
}

const ValueLabel = (props: ValueLabelProps) => {
  const { classes, cx } = useValueLabelStyles(
    props.isLeftAligned ? 'left' : 'right',
    props.fullWidth,
    props.disableMinWidth,
    Boolean(props.onClick)
  )

  const { t } = useTranslation()

  return (
    // <Tooltip
    //   title={props.value?.toString().length > 15 ? props.value : ''}
    //   arrow
    // >
    <div
      className={cx('valueLabel', classes.valueLabelRoot, props.classes?.root)}
      style={props.valueLabelRootStyles}
      onClick={props.onClick}
    >
      {props.label ? (
        <Typography
          variant="caption"
          color="textSecondary"
          {...props.labelProps}
          className={classNames(props.labelProps?.className, classes.label)}
        >
          {props.labelTranslationKey
            ? t(props.labelTranslationKey, props.label.toString())
            : props.label}
        </Typography>
      ) : null}
      <Typography
        variant={props.valueVariant || 'body1'}
        color="textPrimary"
        title={
          props.value?.toString().length > 15 ? props.value.toString() : ''
        }
        {...props.valueProps}
        className={classNames(classes.value, props.valueProps?.className)}
      >
        {props.value?.toString()?.length > 0 ? props.value : props.emptyString}
      </Typography>
    </div>
    // </Tooltip>
  )
}

// export default React.memo(ValueLabel, isEqual)
export default ValueLabel
