import { BaseController } from 'controllers/BaseController'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  GetSystemPartiesResponse,
  ManagementPartyDto,
  UpdatePartyRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  HttpGetMetaData,
  InternalAPI,
} from 'services/APIs/InternalAPI/InternalAPI'

export const PartyManagementOperations = {
  GetAll: 'PartyManagementOperations-GetAll',
  Add: 'PartyManagementOperations-Add',
  Update: 'PartyManagementOperations-Update',
  Delete: 'PartyManagementOperations-Delete',
}

export interface IPartyManagementAPI {
  GetAll(): Promise<ManagementPartyDto[]>
  GetAllPaged(
    meta?: HttpGetMetaData<ManagementPartyDto>
  ): Promise<GetSystemPartiesResponse>
  Add(partyDto: ManagementPartyDto): Promise<ManagementPartyDto>
  Update(partyDto: ManagementPartyDto): Promise<ManagementPartyDto>
  Delete(partyId: string): Promise<void>
}

export class PartyManagementController extends BaseController<IPartyManagementAPI> {
  constructor() {
    super((onRequestChanged) => new PartyManagementAPI(onRequestChanged))
  }

  public async GetAll() {
    try {
      return await this.api.GetAll()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetAllPaged(meta?: HttpGetMetaData<ManagementPartyDto>) {
    try {
      return await this.api.GetAllPaged(meta)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async Add(partyDto: ManagementPartyDto) {
    try {
      return await this.api.Add(partyDto)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async Update(partyDto: ManagementPartyDto) {
    try {
      return await this.api.Update(partyDto)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async Delete(partyId: string) {
    try {
      return await this.api.Delete(partyId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}

class PartyManagementAPI extends InternalAPI implements IPartyManagementAPI {
  constructor(private onRequestChange?: OnRequestChangeCallback) {
    super('/api/system/partymanagement', onRequestChange)
  }

  public GetAll() {
    return this.GetAsync<ManagementPartyDto[]>({
      id: PartyManagementOperations.GetAll,
      relativePath: '/GetParties',
      onRequestChange: this.onRequestChange,
    })
  }

  public GetAllPaged(meta?: HttpGetMetaData<ManagementPartyDto>) {
    return this.GetPagedAsync<GetSystemPartiesResponse>({
      id: PartyManagementOperations.GetAll,
      relativePath: '/GetParties',
      _meta: meta,
    })
  }

  public Add(partyDto: ManagementPartyDto) {
    return this.PostAsync<ManagementPartyDto>({
      id: PartyManagementOperations.Add,
      relativePath: '',
      data: partyDto,
      onRequestChange: this.onRequestChange,
    })
  }

  public Update(partyDto: ManagementPartyDto) {
    const data: UpdatePartyRequest = {
      partyId: partyDto.id,
      ...partyDto,
    }

    return this.PutAsync<ManagementPartyDto>({
      id: PartyManagementOperations.Update,
      relativePath: '',
      data,
      onRequestChange: this.onRequestChange,
    })
  }

  public Delete(partyId: string) {
    return this.DeleteAsync({
      id: PartyManagementOperations.Delete,
      relativePath: '',
      data: { partyId },
      onRequestChange: this.onRequestChange,
    })
  }
}
