// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepCompareIgnoringKeys<T extends Record<string, any>>(
  obj1: T,
  obj2: T,
  ignoreKeys: (keyof T)[] = []
): boolean {
  // If the objects are the same instance, they are equal
  if (obj1 === obj2) {
    return true
  }

  // If either object is null or undefined, they are not equal
  if (obj1 == null || obj2 == null) {
    return false
  }

  // If the objects have different constructors, they are not equal
  if (obj1.constructor !== obj2.constructor) {
    return false
  }

  // If the objects are primitive types, compare them directly
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  // Get the keys of the objects and filter out the ignored keys
  const keys1 = Object.keys(obj1).filter(
    (key) => !ignoreKeys.includes(key as keyof T)
  )
  const keys2 = Object.keys(obj2).filter(
    (key) => !ignoreKeys.includes(key as keyof T)
  )

  // If the objects have a different number of keys, they are not equal
  if (keys1.length !== keys2.length) {
    return false
  }

  // Recursively compare the values of each key
  for (const key of keys1) {
    if (!deepCompareIgnoringKeys(obj1[key] as T, obj2[key] as T, ignoreKeys)) {
      return false
    }
  }

  // If all keys and values are equal, the objects are equal
  return true
}
