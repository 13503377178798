import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { valueGreaterThanZero } from '../Validations/valueGreaterThanZero'
import { saveBomItemQuantity } from '../store/asyncActions/saveBomItemQuantity'
import { ProjectTextField } from '../store/components/ProjectTextField'
import { ValueLabelConnected } from '../store/components/ValueLabelConnected'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemQuantityIsEditableSelector } from '../store/selectors/bomItemQuantityIsEditableSelector'
import { bomItemQuantitySelector } from '../store/selectors/bomItemQuantitySelector'

type Props = {
  bomItemPointer: BomItemPointer
  className?: string
  translationKey?: string
  disabled?: boolean
  rightAligned?: boolean
}

const useStyles = makeStyles({ name: 'PurchasingData-BomItemQuantity' })(
  () => ({
    valueLabel: {
      height: 'auto',
    },
  })
)

function _BomItemQuantity(props: Props) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const quantityIsEditable = useAppSelector(
    bomItemQuantityIsEditableSelector(props.bomItemPointer)
  )
  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)

  if (!quantityIsEditable || !projectIsEditable || props.disabled) {
    return (
      <ValueLabelConnected
        propertySelector={bomItemQuantitySelector(props.bomItemPointer)}
        valueLabelProps={{
          label: t(props.translationKey || 'project:batch-size'),
          valueProps: {
            className: props.className,
          },
          isLeftAligned: !props.rightAligned || true,
          classes: {
            root: classes.valueLabel,
          },
        }}
      />
    )
  }

  return (
    <ProjectTextField
      valueSelector={bomItemQuantitySelector(props.bomItemPointer)}
      actionTypePrefix={saveBomItemQuantity.typePrefix.concat(
        '/',
        props.bomItemPointer.id
      )}
      saveAction={(value) =>
        saveBomItemQuantity({
          bomItemPointer: props.bomItemPointer,
          quantity: value,
        })
      }
      textFieldProps={{
        label: t(props.translationKey || 'project:batch-size'),
        required: true,
        type: 'number',
        className: props.className,
        sx: {
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: (theme) => theme.spacing(-2.5),
            width: '100%',
          },
        },
      }}
      validation={valueGreaterThanZero}
    />
  )
}

export const BomItemQuantity = React.memo(_BomItemQuantity, isEqual)
