import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'store/configureStore'
import { useRequestedProjectState } from './useRequestedProjectState'

const errors = []

export const useQuoteProjectState = () => {
  const {
    canChangeToRequestState,
    Errors: RequestedErrors,
    errorCount: requestedErrorsCount,
  } = useRequestedProjectState()
  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const [errorCount, setErrorCount] = useState(requestedErrorsCount)

  const canChangeToQuoteState =
    canChangeToRequestState &&
    currentProjectStatus !== ProjectStateAsString.QUOTED

  /**
   * if the current state is quoted, a salesperson can change to negotiating
   */
  const canChangeToNegotiatingState = useMemo(() => {
    if (currentProjectStatus === ProjectStateAsString.QUOTED && !isBuyerView) {
      return true
    }

    return false
  }, [currentProjectStatus, isBuyerView])

  useEffect(() => {
    setErrorCount(requestedErrorsCount)
  }, [canChangeToQuoteState, requestedErrorsCount])

  const Errors = useCallback(() => {
    return (
      <>
        <RequestedErrors />
        {!!errors.length && <ul>{errors}</ul>}
      </>
    )
  }, [RequestedErrors])

  const errorStyle: CSSProperties | undefined =
    !canChangeToQuoteState && !canChangeToNegotiatingState
      ? {
          cursor: 'not-allowed',
        }
      : undefined

  return {
    canChangeToNegotiatingState,
    canChangeToQuoteState,
    Errors,
    errorStyle,
    errorCount,
    currentProjectStatus,
  }
}
