import { ArrowRightAltOutlined } from '@mui/icons-material'
import { ListItemAvatar, ListItemText, MenuItem } from '@mui/material'
import { useBomItemDetails } from 'features/BillOfMaterials/components/useBomItemDetails'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { useBomItemMenuStyles } from './BomItemMenuItem'

export const OpenDetailsSidePanelButton = (props: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const { handleOpenBomItemDetails } = useBomItemDetails()
  const { t } = useTranslation()
  const { classes } = useBomItemMenuStyles()
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  if (isBuyerView) {
    return null
  }

  return (
    <span>
      <MenuItem
        onClick={() => {
          handleOpenBomItemDetails(props.bomItemPointer, true)
          props.onClose()
        }}
        className={classes.menuRoot}
      >
        <ListItemAvatar className={classes.menuItemAvatar}>
          <ArrowRightAltOutlined />
        </ListItemAvatar>
        <ListItemText
          primary={t('project:open-bom-item-details-title', 'details')}
          secondary={t(
            'project:open-bom-item-details',
            'open in details panel'
          )}
        />
      </MenuItem>
    </span>
  )
}
