import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'

export const useErpErrorFilter = () => {
  const dispatch = useAppDispatch()

  const erpErrorFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byErpError,
    _.isEqual
  )

  const handleChangeFilter = useCallback(() => {
    dispatch(
      newProjectActions.setFilter({
        filter: {
          type: 'ErpErrorFilter',
        },
      })
    )
  }, [dispatch])

  const handleDisableFilter = useCallback(() => {
    dispatch(
      newProjectActions.toggleActiveFilter({
        filterType: 'ErpErrorFilter',
      })
    )
  }, [dispatch])

  return {
    handleChangeFilter,
    partTypeIdFilter: erpErrorFilter,
    filterIsActive: erpErrorFilter.active,
    handleDisableFilter,
  }
}
