import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { projectStatusSelector } from 'features/BillOfMaterials/store/selectors/projectStatusSelector'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'store/configureStore'

const errors = []

export const useDefiningStateItem = () => {
  const projectCurrentState = useAppSelector(projectStatusSelector)

  const canChangeToDefiningState =
    projectCurrentState !== ProjectStateAsString.ORDERED
  const [errorCount, setErrorCount] = useState(0)

  useEffect(() => {
    errors.length = 0

    if (projectCurrentState === ProjectStateAsString.ORDERED) {
      errors.push(
        <li key="project-ordered">
          <LocalizedTypography
            translationKey="project:project-ordered-error-title"
            variant="body1"
            color="primary"
            component="p"
          >
            project ordered
          </LocalizedTypography>
          <LocalizedTypography
            translationKey="project:project-ordered-error-subtitle"
            variant="caption"
          >
            project was ordered
          </LocalizedTypography>
        </li>
      )
    }

    setErrorCount(errors.length)
  }, [projectCurrentState])

  const Errors = useCallback(() => {
    return (
      !!errors.length && (
        <>
          <ul>{errors}</ul>
        </>
      )
    )
  }, [])

  const errorStyle: CSSProperties | undefined = !canChangeToDefiningState
    ? {
        cursor: 'not-allowed',
      }
    : undefined

  return {
    canChangeToDefiningState,
    Errors,
    errorStyle,
    errorCount,
  }
}
