import { OnRequestChangeCallback } from '../../../services/APIs/BaseAPI'
import { PartyProfileDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from '../../../services/APIs/InternalAPI/InternalAPI'
import { IOrganizationProfileAPI } from './IOrganizationProfileAPI'
import { OrganizationProfileOperations } from './OrganizationProfileOperations'

export class OrganizationProfileAPI
  extends InternalAPI
  implements IOrganizationProfileAPI
{
  constructor(private onRequestChange: OnRequestChangeCallback) {
    super()
  }

  public async getPartyProfile(partyId: string) {
    return await this.GetAsync<PartyProfileDto>({
      id: OrganizationProfileOperations.GetDetails,
      relativePath: `/api/parties/${partyId}/settings/party-profile`,
      onRequestChange: this.onRequestChange,
    })
  }

  public async getLogo(organizationId: string) {
    return await this.GetAsync<string>({
      id: OrganizationProfileOperations.GetLogo,
      relativePath: `/api/parties/${organizationId}/logo`,
      onRequestChange: this.onRequestChange,
    })
  }
  public async postLogo(partyId: string, logo: File) {
    return await this.UploadAsync<void>({
      id: OrganizationProfileOperations.SetLogo,
      relativePath: `/api/parties/${partyId}/settings/party-profile/logo`,
      data: [logo],
      onRequestChange: this.onRequestChange,
    })
  }

  public async updatePartyProfile(
    partyId: string,
    partyProfile: PartyProfileDto
  ) {
    return await this.PutAsync<PartyProfileDto>({
      id: OrganizationProfileOperations.UpdateProfile,
      relativePath: `/api/parties/${partyId}/settings/party-profile`,
      data: partyProfile,
      onRequestChange: this.onRequestChange,
    })
  }

  public async saveDateFormat(dateFormat: string) {
    return await this.PutAsync<void>({
      id: OrganizationProfileOperations.SaveDateFormat,
      relativePath: `/api/parties/settings/party-profile/date-format`,
      data: {
        dateFormat,
      },
      onRequestChange: this.onRequestChange,
    })
  }
}
