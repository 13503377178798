import { MoreVertOutlined } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { AppFeature } from 'features/AppModules/AppFeature'
import { FeatureKeys } from 'features/AppModules/FeatureKeys'
import { AssemblyHeaderPointer } from 'model/Project/BomItemPointer'
import { useRef, useState } from 'react'
import { AggregateLevel } from 'services/APIs/InternalAPI/internal-api.contracts'
import { AssemblyFlattenMenuItem } from './MenuItems/AssemblyFlattenMenuItem'
import { AssemblySimplificationMenuItem } from './MenuItems/AssemblySimplificationMenuItem'
import { DeleteMenuItem } from './MenuItems/DeleteMenuItem'
import { DownloadBomItemFilesMenuItem } from './MenuItems/DownloadBomItemFilesMenuItem'
import { EventLogMenuItem } from './MenuItems/EventLogMenuItem'
import { OpenDetailsSidePanelButton } from './MenuItems/OpenDetailsSidePanelButton'
import { OpenOnErpMenuItem } from './MenuItems/OpenOnErpMenuItem'
import { RegressionTestMenuItem } from './MenuItems/RegressionTestMenuItem'
import { ReportRecognitionIssuesMenuItem } from './MenuItems/ReportRecognitionIssuesMenuItem'

export const AssemblyHeaderMenuButton = ({
  bomItemPointer,
}: {
  bomItemPointer: AssemblyHeaderPointer
}) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(undefined)

  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        ref={buttonRef}
        size="small"
        color="inherit"
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <span onDoubleClick={(e) => e.stopPropagation()}>
          <AppFeature featureKey={FeatureKeys.bomItemDetails}>
            <OpenDetailsSidePanelButton
              bomItemPointer={bomItemPointer}
              onClose={handleClose}
            />
          </AppFeature>
          <AssemblyFlattenMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <AssemblySimplificationMenuItem
            assemblyHeaderPointer={bomItemPointer}
            onClose={handleClose}
          />
          <DownloadBomItemFilesMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <OpenOnErpMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <DeleteMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <ReportRecognitionIssuesMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
            aggregateLevel={AggregateLevel.Assembly}
          />
          <RegressionTestMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <EventLogMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
        </span>
      </Menu>
    </>
  )
}
