import { Badge, Theme } from '@mui/material'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { CuttingPlanDetails } from '../MaterialHeader/CuttingPlanDetails'

type Props = {
  bomItemPointer: BomItemPointer
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  cuttingPlan: {
    width: '100%',
    contentVisibility: 'auto',
    containIntrinsicSize: '0 124px',
    display: 'block',
  },
}))

export const BomItemCuttingPlans = (props: Props) => {
  const cuttingPlans = useAppSelector(
    projectSelectors.bomItemCuttingPlansSelector(props.bomItemPointer),
    isEqual
  )
  const { classes } = useStyles()

  const { partyId, projectId } = useOrganizationAndProjectContext()

  if (!cuttingPlans) {
    return null
  }

  return (
    <ul className={classes.root}>
      {cuttingPlans.map(
        (plan) =>
          plan && (
            <li key={plan.id} style={{ width: '100%' }}>
              <Badge
                badgeContent={plan.quantity}
                color="primary"
                style={{ width: '100%' }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <img
                  className={classes.cuttingPlan}
                  src={projectImageUrl(
                    partyId,
                    projectId,
                    plan.fileNameOfNestingImage
                  )}
                  loading="lazy"
                  alt={`cutting plan ${plan.id}`}
                  style={{
                    aspectRatio: `${plan.details.sheetLength} / ${plan.details.sheetWidth}`,
                  }}
                />
              </Badge>
              <CuttingPlanDetails details={plan.details} size="small" />
            </li>
          )
      )}
    </ul>
  )
}
