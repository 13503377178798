import { MoreHorizOutlined } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import classNames from 'classnames'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEmpty, isEqual } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IssueCode,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { BomItemPointer } from '../../../model/Project/BomItemPointer'
import { useWorkingStepFilter } from '../BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { WorkingStepStats } from '../store/selectors/assemblyWorkingStepsSummaryWithCounterSelector'

type WorkingStepIconSize = 'small' | 'medium' | 'large'

type StyleProps = {
  size?: WorkingStepIconSize
}

type OwnProps = {
  // partTypeWorkingStepDtos: WorkingStepDto[]
  bomItemPointer: BomItemPointer
  includeAssemblyWorkingSteps?: boolean
  onClick?: (workingStepStats: WorkingStepStats) => void
  getButtonVariant?: (workingStep: WorkingStepType) => 'filled' | 'outlined'
  includeCountOfWorkingSteps?: boolean
  shouldFilter?: boolean
  ulClassName?: string
  onlyAssemblyWorkingSteps?: boolean
  maxItemsInARow?: number
}

type Props = StyleProps & OwnProps

const getSize = (size?: WorkingStepIconSize) => {
  switch (size) {
    case 'small':
      return '24px'
    case 'medium':
      return '32px'
    case 'large':
      return '42px'
    default:
      return '24px'
  }
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  workingStepSummaryList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    alignItems: 'flex-end',
    overflowX: 'auto',
  },
  workingStepSummaryIcon: {
    '& svg': {
      width: getSize(props.size),
    },
    padding: '4px',
  },
  buttonFilled: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonNotAllowed: {
    color: theme.palette.error.contrastText,
    // backgroundColor: theme.palette.error.main,
    // border: `1px solid ${theme.palette.error.main}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      right: 0,
      height: '2px',
      backgroundColor: theme.palette.error.main,
    },
    '&:hover': {
      outline: `1px solid ${theme.palette.error.dark}`,
    },
  },
  buttonDisabled: {
    pointerEvent: 'none',
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
  tooltipUl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '8px',
  },
}))

export const WorkingStepButton = (
  props: { ws: WorkingStepStats; isNotAllowed: boolean } & Omit<
    Props,
    'bomItemPointer'
  >
) => {
  const { classes } = useStyles(props)
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { handleChangeFilter, filterIsActive } = useWorkingStepFilter()
  const { t } = useTranslation('errors')

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (props.onClick) props.onClick(props.ws)
    if (props.shouldFilter) handleChangeFilter(props.ws.primaryWorkingStep)
  }

  return (
    <Tooltip
      title={
        <>
          <Typography variant="caption">
            {props.isNotAllowed
              ? `${getLocalizedWorkingStep(props.ws.primaryWorkingStep)}: ${t(
                  `errors:${IssueCode.WorkingStepNotAvailable}`,
                  'working step not available in self-service portal'
                )}`
              : getLocalizedWorkingStep(props.ws.primaryWorkingStep)}
          </Typography>
        </>
      }
      placement="top"
      arrow
    >
      <div style={{ textAlign: 'center' }}>
        {props.includeCountOfWorkingSteps && props.ws ? (
          <Typography variant="caption" color="textSecondary" component="p">
            {props.ws.count}
          </Typography>
        ) : null}
        <IconButton
          className={classNames(classes.workingStepSummaryIcon, {
            [classes.buttonFilled]:
              (props.shouldFilter &&
                filterIsActive(props.ws.primaryWorkingStep)) ||
              (props.getButtonVariant &&
                props.getButtonVariant(props.ws.primaryWorkingStep) ===
                  'filled'),
            [classes.buttonNotAllowed]: props.isNotAllowed,
            [classes.buttonDisabled]: !props.onClick && !props.shouldFilter,
          })}
          size="small"
          onClick={handleClick}
        >
          <WorkingStepIconSvg
            disableTitle
            workingStepType={props.ws.primaryWorkingStep}
          />
        </IconButton>
      </div>
    </Tooltip>
  )
}

export const WorkingStepsSummary = (
  props: {
    workingStepsStats: Partial<Record<WorkingStepType, WorkingStepStats>>
    notAllowedWorkingSteps?: WorkingStepType[]
  } & Omit<Props, 'bomItemPointer'>
) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ ...props })

  const workingStepStatsArray = Object.values(props.workingStepsStats)

  const [showAll, setShowAll] = useState(false)

  const { filterIsActive } = useWorkingStepFilter()

  const workingStepsMainRow =
    !showAll &&
    props.maxItemsInARow > 0 &&
    props.maxItemsInARow < workingStepStatsArray.length
      ? workingStepStatsArray
          .sort((a, b) => {
            if (filterIsActive(a.primaryWorkingStep)) return -1
            if (filterIsActive(b.primaryWorkingStep)) return 1
            return 0
          })
          .slice(0, props.maxItemsInARow)
      : workingStepStatsArray

  return (
    <ul className={props.ulClassName || classes.workingStepSummaryList}>
      {workingStepsMainRow?.map((ws) => {
        const notAllowed = props.notAllowedWorkingSteps?.includes(
          ws.primaryWorkingStep
        )

        return (
          <li key={ws.primaryWorkingStep}>
            <WorkingStepButton
              {...props}
              size={props.size ? props.size : 'small'}
              ws={ws}
              isNotAllowed={notAllowed}
            />
          </li>
        )
      })}
      {props.maxItemsInARow > 0 &&
      props.maxItemsInARow < workingStepStatsArray.length ? (
        <Tooltip
          title={
            !showAll &&
            props.maxItemsInARow > 0 &&
            props.maxItemsInARow < workingStepStatsArray.length ? (
              <WorkingStepsSummary
                {...props}
                maxItemsInARow={0}
                size="medium"
                ulClassName={classes.tooltipUl}
              />
            ) : (
              ''
            )
          }
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
        >
          <li style={{ marginLeft: 'auto' }}>
            <IconButton
              size="small"
              onClick={() => setShowAll((current) => !current)}
              title={
                showAll
                  ? t(
                      'project:working-steps-show-less',
                      'show less working steps'
                    )
                  : t(
                      'project:working-steps-show-all',
                      'show all working steps'
                    )
              }
            >
              <MoreHorizOutlined />
            </IconButton>
          </li>
        </Tooltip>
      ) : null}
    </ul>
  )
}

export const ProjectPriceSummariesWorkingStepSummary = (props: Props) => {
  const workingStepsStats = useAppSelector(
    projectSelectors.projectPriceSummariesWorkingStepSummary,
    isEqual
  )

  if (isEmpty(workingStepsStats)) return null

  return (
    <WorkingStepsSummary {...props} workingStepsStats={workingStepsStats} />
  )
}

export const BomItemWorkingStepSummary = (props: Props) => {
  const workingStepsStats = useAppSelector(
    projectSelectors.assemblyWorkingStepsSummaryWithCounterSelector(
      props.bomItemPointer,
      props.onlyAssemblyWorkingSteps
    ),
    isEqual
  )

  const notAllowedWorkingSteps = useAppSelector(
    projectSelectors.allNotAllowedWorkingStepsSelector
  )

  if (!workingStepsStats) {
    return null
  }

  return (
    <WorkingStepsSummary
      {...props}
      workingStepsStats={workingStepsStats}
      notAllowedWorkingSteps={notAllowedWorkingSteps}
    />
  )
}
