import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { ShowNewProjectForm } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'

const errors = []

export const useCancelledStateItem = () => {
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )
  const dispatch = useAppDispatch()

  const [errorCount, setErrorCount] = useState(0)

  const openNewProjectDialog = useCallback(() => {
    dispatch(ShowNewProjectForm(true, !isBuyerView))
  }, [dispatch, isBuyerView])

  const canChangeToCancelledState = !isBuyerView

  useEffect(() => {
    errors.length = 0

    if (isBuyerView) {
      errors.push(
        <li
          key={'invalid-project-state'}
          style={{ cursor: 'pointer' }}
          onClick={openNewProjectDialog}
        >
          <LocalizedTypography
            translationKey={'project:cancelled-error'}
            variant={'body1'}
            color={'error'}
            component={'p'}
          >
            cancelled project
          </LocalizedTypography>
          <LocalizedTypography
            translationKey={'project:cancelled-error-subtitle'}
            variant={'caption'}
          >
            start a new request for quotation
          </LocalizedTypography>
        </li>
      )
    }

    setErrorCount(errors.length)
  }, [isBuyerView, openNewProjectDialog])

  const Errors = useCallback(() => {
    return (
      <>
        {!!errors.length && (
          <>
            <ul>{errors}</ul>
          </>
        )}
      </>
    )
  }, [])

  const errorStyle: CSSProperties | undefined = !canChangeToCancelledState
    ? {
        cursor: 'not-allowed',
      }
    : undefined

  return {
    canChangeToCancelledState,
    Errors,
    errorStyle,
    errorCount,
  }
}
