import { Typography } from '@mui/material'
import { useAppSelector } from 'store/configureStore'
import { bomItemRowNumberSelector } from '../store/selectors/bomItemNumberSelector'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  circularProgressFix: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}))

export const PartTypeRowNumber = ({
  bomItemPointer,
}: {
  bomItemPointer: BomItemPointer
}) => {
  const { classes } = useStyles()

  const bomItemRowNumber = useAppSelector(
    bomItemRowNumberSelector(bomItemPointer)
  )

  return (
    <Typography
      variant="caption"
      color="textSecondary"
      component="div"
      className={classes.circularProgressFix}
    >
      {bomItemRowNumber}
    </Typography>
  )
}
