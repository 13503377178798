import React from 'react'

/**
 * Container with support to scroll horizontally by holding the middle mouse button
 * @param children element that will be rendered inside the horizontal scroll container
 * @returns Horizontal scroll container component
 */
export const HorizontalScrollContainer = ({
  children,
  ...rest
}: {
  children: React.ReactChild
} & React.HTMLAttributes<HTMLDivElement>) => {
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const scrolling = React.useRef(false)

  const handleMouseDownScroll = (e) => {
    if (e.buttons === 4) {
      // middle mouse button
      scrollRef.current.style.cursor = 'grabbing'
      scrolling.current = true
    }
  }

  const handleMouseUpScroll = () => {
    if (scrolling.current) {
      scrollRef.current.style.cursor = 'auto'
      scrolling.current = false
    }
  }

  const handleMouseMoveScroll = (e: { movementX: number }) => {
    if (scrolling.current) {
      scrollRef.current.scrollLeft += -e.movementX
    }
  }

  return (
    <div
      {...rest}
      ref={scrollRef}
      onMouseDown={handleMouseDownScroll}
      onMouseUp={handleMouseUpScroll}
      onMouseMove={handleMouseMoveScroll}
    >
      {children}
    </div>
  )
}
