import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import {
  GettingStartedRouteKeys,
  gettingStartedRoutes,
} from './GettingStartedRoutes'
import { gettingStartedSelectors } from './store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'GettingStartedMenu' })((theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  title: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
  disabled: {
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}))

function MenuTimelineItem(props: {
  stepKey: GettingStartedRouteKeys
  // step: GettingStartedRouteDef
  // stepIsDone: (step: GettingStartedRouteDef) => boolean
  // stepIsDone: boolean
  // stepIsAvailable?: boolean
  disableConnector?: boolean
}) {
  const location = useLocation()
  const { t } = useTranslation('getting-started')
  const { classes, cx } = useStyles()

  const stepDone = useAppSelector(
    gettingStartedSelectors.stepIsDoneSelector(props.stepKey)
  )
  const stepIsAvailable = useAppSelector(
    gettingStartedSelectors.stepIsAvailableSelector(props.stepKey)
  )

  const stepDef = gettingStartedRoutes[props.stepKey]
  const currentStep = location.pathname.includes(stepDef.path)

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          variant={stepDone || currentStep ? 'filled' : 'outlined'}
          color={stepDone ? 'success' : 'info'}
        />
        {!props.disableConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Link
          to={stepDef.path}
          state={{
            from: stepDone ? 'previous' : 'next',
          }}
          className={cx(classes.link, {
            [classes.activeLink]: currentStep,
            [classes.disabled]: !stepIsAvailable,
          })}
        >
          {t(stepDef.menuTranslationKey)}
        </Link>
      </TimelineContent>
    </TimelineItem>
  )
}

export const GettingStartedMenu = () => {
  const { classes } = useStyles()

  // const context = useContext(GettingStartedPageContext)

  // const location = useLocation()

  const availableWorkspaces = useAppSelector(
    gettingStartedSelectors.getAvailableWorkspaces,
    isEqual
  )
  const joinWorkspaceFlow = availableWorkspaces?.length > 0

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <LocalizedTypography
          translationKey="getting-started:getting-started-menu-title"
          variant="h6"
          style={{ textAlign: 'center' }}
        >
          Getting Started
        </LocalizedTypography>
      </div>

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <MenuTimelineItem stepKey="welcome" />

        <MenuTimelineItem stepKey="validateEmail" />
        {joinWorkspaceFlow ? (
          <MenuTimelineItem stepKey="joinWorkspace" />
        ) : (
          <>
            <MenuTimelineItem stepKey="factorySetup" />
            <MenuTimelineItem stepKey="workingSteps" />
            <MenuTimelineItem stepKey="confirm" />
          </>
        )}
        <MenuTimelineItem stepKey="nextStep" disableConnector />
      </Timeline>
    </div>
  )
}
