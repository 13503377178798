import { ErrorObject } from 'ajv'
import { TFunction } from 'i18next'

export function localizedErrors(
  errors: null | ErrorObject[] = [],
  t: TFunction
) {
  if (!(errors && errors.length)) {
    return
  }

  errors.forEach((error) => {
    let outMessage = ''
    if (error.keyword === 'required') {
      outMessage = t('required')
    } else if (error.keyword === 'type') {
      outMessage = t('invalid-field-type', {
        defaultValue: `expected type: $t(errors:field-type-${error.params.type})`,
      })
    }

    if (outMessage) {
      error.message = outMessage
    }
  })
}
