import { CheckOutlined, CloseOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, Theme } from '@mui/material'
import { DefinitiveMoneyField } from 'components/DefinitiveInputs/DefinitiveMoneyField'
import { DefinitiveQuantityField } from 'components/DefinitiveInputs/DefinitiveQuantityField'
import { CostDriversActions } from 'features/BillOfMaterials/store/asyncActions/CostDriversActions'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { HomePriceButtonOutlined } from 'features/BomItemDetailsDrawer/BomItemDetails/ProjectDetails/ProjectPriceSummaries/HomePriceButtonOutlined'
import { useTranslation } from 'react-i18next'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { Key } from 'utils/keyCodes'
import { useDiscountAndSurchargeEditor } from './useDiscountAndSurchargeEditor'

const useStyles = makeStyles({ name: 'SurchargeAndDiscountSmallTable' })(
  (theme: Theme) => ({
    discountTable: {
      borderCollapse: 'separate',
      borderSpacing: `${theme.spacing(2)} 0`,
      textAlign: 'right',
      width: '100%',
      '& thead td': {
        fontSize: theme.typography.caption.fontSize,
        textAlign: 'left',
        color: theme.palette.text.disabled,
      },
      '& td': {
        width: theme.spacing(12),
      },
    },
    discountForm: {
      position: 'relative',
      textAlign: 'right',
      // padding: theme.spacing(2),
      justifyContent: 'flex-start',
      display: 'block',
      margin: '0 auto',

      '& .MuiInputAdornment-root': {
        marginLeft: '2px',
      },
      '& .MuiInput-root': {
        minWidth: '140px',
      },
    },
    discountFormButtons: {
      // position: 'absolute',
      right: theme.spacing(-2),
      bottom: 0,
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  })
)

export const SurchargeAndDiscountSmallTable = (props: {
  priceSummary: PriceSummaryDto
  noHeaders?: boolean
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const {
    handleSubmit,
    changedFields,
    handleChangeFields,
    handleReset,
    changed,
  } = useDiscountAndSurchargeEditor(props.priceSummary)

  const handleResetAction = (costFactor: 'Surcharge' | 'Discount') => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let action: (arg0: { priceSummary: PriceSummaryDto }) => any

    switch (costFactor) {
      case 'Surcharge': {
        action = CostDriversActions.ResetCostDriverSurchargeToDefault
        break
      }
      case 'Discount': {
        action = CostDriversActions.ResetCostDriverDiscountToDefault
        break
      }
    }

    dispatch(
      action({
        priceSummary: props.priceSummary,
      })
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      className={cx(classes.discountForm)}
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.key === Key.Escape && handleReset()}
    >
      <table className={classes.discountTable}>
        {props.noHeaders ? null : (
          <thead>
            <tr>
              <td>
                <div className={classes.label}>{t('common:surcharge')}</div>
              </td>
              <td>
                <div className={classes.label}>{t('common:discount')}</div>
              </td>
            </tr>
          </thead>
        )}

        <tbody>
          <tr>
            <td>
              <DefinitiveMoneyField
                moneyDto={
                  changedFields?.surchargeValue ||
                  props.priceSummary?.surchargeValue
                }
                numberFormatProps={{
                  thousandSeparator: true,
                }}
                textFieldProps={{
                  inputProps: {
                    onFocus: (e) => e.target.select(),
                    style: {
                      paddingRight:
                        // props.noHeaders &&
                        props.priceSummary.isSurchargeManuallySet &&
                        projectIsEditable
                          ? '0px'
                          : '34px',
                    },
                  },
                  InputProps: {
                    endAdornment:
                      // props.noHeaders &&
                      props.priceSummary.isSurchargeManuallySet ? (
                        <InputAdornment position="end">
                          <HomePriceButtonOutlined
                            handleResetToDefaults={() =>
                              handleResetAction('Surcharge')
                            }
                            isManuallySet={
                              props.priceSummary.isSurchargeManuallySet
                            }
                            isLoading={false}
                            hidden={!projectIsEditable}
                          />
                        </InputAdornment>
                      ) : null,
                  },
                }}
                onChange={(value) => {
                  handleChangeFields('surchargeValue', value)
                }}
                disabled={changed['surchargeRatio'] || !projectIsEditable}
                typographyVariant="caption"
                disableSelectOnFocus
              />
            </td>
            <td>
              <DefinitiveMoneyField
                moneyDto={
                  changedFields?.discountValue ||
                  props.priceSummary?.discountValue
                }
                onChange={(value) => {
                  handleChangeFields('discountValue', value)
                }}
                disabled={changed['discountRatio'] || !projectIsEditable}
                typographyVariant="caption"
                disableSelectOnFocus
                textFieldProps={{
                  InputProps: {
                    endAdornment:
                      // props.noHeaders &&
                      props.priceSummary.isDiscountManuallySet ? (
                        <InputAdornment position="end">
                          <HomePriceButtonOutlined
                            handleResetToDefaults={() =>
                              handleResetAction('Discount')
                            }
                            isManuallySet={
                              props.priceSummary.isDiscountManuallySet
                            }
                            isLoading={false}
                            hidden={!projectIsEditable}
                          />
                        </InputAdornment>
                      ) : null,
                  },
                  inputProps: {
                    onFocus: (e) => e.target.select(),
                    style: {
                      paddingRight:
                        props.priceSummary.isDiscountManuallySet &&
                        projectIsEditable
                          ? '0px'
                          : '34px',
                    },
                  },
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <DefinitiveQuantityField
                quantityDto={
                  changedFields?.surchargeRatio ||
                  props.priceSummary?.surchargeRatio
                }
                onChange={(value) => {
                  handleChangeFields('surchargeRatio', value)
                }}
                disabledValue={changed['surchargeValue'] || !projectIsEditable}
                typographyVariant="caption"
                isAllowed={(value) => {
                  return !value.floatValue || value.floatValue >= 0
                }}
                disableSelectOnFocus
                textFieldProps={{
                  inputProps: {
                    style: {
                      paddingRight: props.priceSummary.isSurchargeManuallySet
                        ? '24px'
                        : '21px',
                    },
                    onFocus: (e) => e.target.select(),
                  },
                }}
              />
            </td>
            <td>
              <DefinitiveQuantityField
                disableSelectOnFocus
                quantityDto={
                  changedFields?.discountRatio ||
                  props.priceSummary?.discountRatio
                }
                onChange={(value) => {
                  handleChangeFields('discountRatio', value)
                }}
                disabledValue={changed['discountValue'] || !projectIsEditable}
                typographyVariant="caption"
                isAllowed={(value) => {
                  return (
                    !value.floatValue ||
                    (value.floatValue >= 0 && value.floatValue <= 100)
                  )
                }}
                textFieldProps={{
                  inputProps: {
                    onFocus: (e) => e.target.select(),

                    style: {
                      paddingRight: props.priceSummary.isDiscountManuallySet
                        ? '26px'
                        : '22px',
                    },
                  },
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {Object.values(changed).find((x) => Boolean(x)) && (
        <div className={classes.discountFormButtons}>
          <IconButton type="reset" onClick={handleReset} size="small">
            <CloseOutlined />
          </IconButton>
          <IconButton
            type="submit"
            color={'primary'}
            size="small"
            disabled={!changed}
          >
            <CheckOutlined />
          </IconButton>
        </div>
      )}
    </form>
  )
}
