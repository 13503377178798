import { Theme } from '@mui/material'
import appLogo from '_icons/logo/QF_logoHrz_tag.png'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: '526px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  img: {
    width: '100%',
    height: '100%',
  },
}))

const ProductName = () => {
  const { classes } = useStyles()

  const { withinIframe } = useAppRunningContext()

  if (withinIframe) {
    return null
  }

  return (
    <div className={classes.root}>
      <img src={appLogo} alt="Quotation Factory" className={classes.img} />

      {/* <Typography variant="h3" className={classes.strong}>
        Rhodium<sup>24</sup>
      </Typography>
      <Divider orientation="horizontal" />
      <LocalizedTypography
        className={classes.subtitle}
        variant="subtitle1"
        translationKey={'common:product-subtitle'}
      /> */}
    </div>
  )
}

export { ProductName }
