/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useProjectOperationsStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    textAlign: 'center',
  },
  timelineDot: {
    padding: 0,
    width: '24px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
    border: 'none',
  },
  childOperationListItem: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  operationBoxContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    maxWidth: theme.spacing(25),
  },
}))
