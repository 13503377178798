import { BaseController } from 'controllers/BaseController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import {
  MoneyDto,
  MoneyPerQuantityDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { ProjectArticleAPI } from './ProjectArticleAPI'

export interface IProjectArticleAPI {
  SetArticleSurchargeRatio: (
    articleId: string,
    surchargeRatio: QuantityDto
  ) => Promise<void>

  SetArticleSurchargeValue: (
    articleId: string,
    surchargeValue: MoneyDto
  ) => Promise<void>

  SetArticleDiscountRatio: (
    articleId: string,
    discountRatio: QuantityDto
  ) => Promise<void>

  SetArticleDiscountValue: (
    articleId: string,
    discountValue: MoneyDto
  ) => Promise<void>

  DeleteArticleDiscount: (articleId: string) => Promise<void>

  DeleteArticleSurcharge: (articleId: string) => Promise<void>

  SetArticleCostPrice: (
    articleId: string,
    costPrice: MoneyPerQuantityDto
  ) => Promise<void>
  DeleteArticleCostPrice: (articleId: string) => Promise<void>
}

export class ProjectArticleController extends BaseController<IProjectArticleAPI> {
  private _projectId: string
  constructor() {
    const { partyId, projectId } = GetContext()
    super((onRequestChanged) => {
      return new ProjectArticleAPI(partyId, projectId, onRequestChanged)
    })

    this._projectId = projectId
  }

  public SaveArticleIdSurchargeRatio = async (
    articleId: string,
    surchargeRatio: QuantityDto
  ) => {
    try {
      await this.api.SetArticleSurchargeRatio(articleId, surchargeRatio)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleIdSurchargeValue = async (
    articleId: string,
    surchargeValue: MoneyDto
  ) => {
    try {
      await this.api.SetArticleSurchargeValue(articleId, surchargeValue)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleIdDiscountRatio = async (
    articleId: string,
    discountRatio: QuantityDto
  ) => {
    try {
      await this.api.SetArticleDiscountRatio(articleId, discountRatio)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleIdDiscountValue = async (
    articleId: string,
    discountValue: MoneyDto
  ) => {
    try {
      await this.api.SetArticleDiscountValue(articleId, discountValue)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteArticleDiscount = async (articleId: string) => {
    try {
      await this.api.DeleteArticleDiscount(articleId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteArticleSurcharge = async (articleId: string) => {
    try {
      await this.api.DeleteArticleSurcharge(articleId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public DeleteArticleCostPrice = async (articleId: string) => {
    try {
      await this.api.DeleteArticleCostPrice(articleId)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public SaveArticleCostPrice = async (
    priceSummaryId: string,
    articleId: string,
    costPrice: MoneyPerQuantityDto
  ) => {
    try {
      store.dispatch(
        newProjectActions.setProjectStateProperties({
          priceSummaries: {
            [priceSummaryId]: {
              rate: { ...costPrice },
              totalSalesPrice: { value: null },
            },
          },
        })
      )
      await this.api.SetArticleCostPrice(articleId, costPrice)
    } catch (err) {
      store.dispatch(fetchProject({ projectId: this._projectId }))
      throw this.HandleError(err)
    }
  }
}
