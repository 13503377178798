/* eslint-disable tss-unused-classes/unused-classes */
import { useSellingParties } from 'components/ProjectPage/ProjectSupplier/useSuppliers'
import { ProjectsController } from 'controllers/Projects/ProjectsController'
import { OrganizationProfileController } from 'controllers/Settings/OrganizationProfile/OrganizationProfileController'
import { useAppController } from 'customHooks/useAppController'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { isEqual, noop } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SearchPartyAPI } from 'services/APIs/InternalAPI/SearchPartyAPI'
import {
  AddressDto,
  ContactPersonDto,
  CreateProjectRequest,
  PartyDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException, ShowNewProjectForm } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const DEFAULT_VAT_VALUE = 21

const useStyles = makeStyles({ name: 'CreateProjectForm' })((theme) => ({
  rootContent: {
    padding: theme.spacing(0, 3),
    flexDirection: 'column',
    gap: theme.spacing(2),
    display: 'flex',
    textTransform: 'lowercase',
  },
  projectIdent: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'bold',
    // marginBottom: theme.spacing(2),
  },
  buyerCompanyTitle: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  autoComplete: {
    flexBasis: '33%',
    minWidth: `${theme.breakpoints.values.sm / 2}px`,
    ['& input']: {
      color: theme.palette.text.secondary,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  contactPerson: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

export const useContactPerson = () => {
  const [contactPerson, setContactPerson] =
    React.useState<ContactPersonDto>(null)

  const isBuyerView = useAppSelector(
    (state) => state.user?.organizationContext?.isBuyingParty
  )

  const currentUser = useAppSelector((state) => state.user.profile)

  useEffect(() => {
    if (isBuyerView) {
      setContactPerson({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
      })
    }
  }, [
    currentUser.email,
    currentUser.firstName,
    currentUser.lastName,
    isBuyerView,
  ])

  const handleChangeContactPerson = (
    key: keyof ContactPersonDto,
    value: string
  ) => {
    setContactPerson((prev) => ({ ...prev, [key]: value }))
  }

  return {
    contactPerson,
    handleChangeContactPerson,
  }
}

export const useCreateProjectForm = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('project')
  const formRef = useRef<HTMLFormElement>(null)
  const contactFirstNameRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isBuyerView = useAppSelector(
    (state) => state.user.organizationContext?.isBuyingParty,
    isEqual
  )
  const { sellingParties } = useSellingParties(isBuyerView)

  const { controller, loading } = useAppController(
    () => new ProjectsController()
  )

  const [postalAddress, setPostalAddress] = React.useState<AddressDto>(null)
  const [deliveryAddress, setDeliveryAddress] = React.useState<AddressDto>(null)
  const [selectedBuyer, setSelectedBuyer] = React.useState<PartyDto | null>(
    null
  )
  const [buyerPartyName, setBuyerPartyName] = React.useState<string>('')

  const currentParty = useAppSelector(
    (state) => state.user.organizationContext,
    isEqual
  )

  const searchAPI = useMemo(
    () => new SearchPartyAPI(currentParty.id),
    [currentParty.id]
  )

  const { controller: partyProfileController } = useAppController(
    () => new OrganizationProfileController()
  )

  const [vatRatio, setVatRatio] = React.useState<QuantityDto>({
    value: DEFAULT_VAT_VALUE,
    unit: '%',
    selectableAbbreviations: ['%'],
  })

  useEffect(() => {
    if (!isBuyerView) {
      partyProfileController.getVatPercentage().then(setVatRatio).catch(noop)
    }
  }, [isBuyerView, partyProfileController])

  const [postalAddressAsDeliveryAddress, setPostalAddressAsDeliveryAddress] =
    React.useState(true)

  useEffect(() => {
    if (isBuyerView) {
      searchAPI.GetPartyDetails(currentParty.id, noop).then((party) => {
        setSelectedBuyer(party)
        setBuyerPartyName(party.legalName)
        setPostalAddress(party.address)
        setDeliveryAddress(party.deliveryAddress)
      })
    }
  }, [currentParty, isBuyerView, searchAPI])

  const handleCopyAddress = (options: {
    from: 'deliveryAddress' | 'postalAddress'
    to: 'deliveryAddress' | 'postalAddress'
  }) => {
    const { from, to } = options

    const baseAddress =
      from === 'deliveryAddress' ? deliveryAddress : postalAddress

    if (!baseAddress) return

    if (to === 'deliveryAddress') {
      setDeliveryAddress(baseAddress)
    } else {
      setPostalAddress(baseAddress)
    }
  }

  const handleCreateProject = async (
    e: React.FormEvent<HTMLFormElement>,
    contactPerson?: ContactPersonDto
  ) => {
    e.preventDefault()

    try {
      const formData = new FormData(e.currentTarget)
      const createProjectArgs: CreateProjectRequest = {
        relationId: selectedBuyer?.relationId,
        orderNumber: (formData.get('orderNumber') as string) ?? '',
        projectReference: formData.get('projectReference') as string,
        vatRatio: isBuyerView ? undefined : vatRatio,
        buyingCompanyName: buyerPartyName,
        address: postalAddress,
        deliveryAddress: postalAddressAsDeliveryAddress
          ? postalAddress
          : deliveryAddress,
        contactPerson: contactPerson,
      }

      dispatch(fullUnloadActiveProject())

      await controller.CreateNewProject(createProjectArgs, navigate)

      dispatch(ShowNewProjectForm(false))
    } catch (err) {
      ShowException(t('common:create-project'), err)
    }
  }

  return {
    classes,
    cx,
    formRef,
    contactFirstNameRef,
    navigate,
    dispatch,
    controller,
    loading,
    postalAddress,
    setPostalAddress,
    deliveryAddress,
    setDeliveryAddress,
    selectedBuyer,
    setSelectedBuyer,
    buyerPartyName,
    setBuyerPartyName,
    vatRatio,
    setVatRatio,
    postalAddressAsDeliveryAddress,
    setPostalAddressAsDeliveryAddress,
    handleCopyAddress,
    handleCreateProject,
    isBuyerView,
    sellingParties,
  }
}
