import { BomItemType } from 'model/Project/BoMItemRow'
import { AssemblyDetails } from './AssemblyDetails'
import { PartTypeDetails } from './PartTypeDetails'
import { ProjectDetails } from './ProjectDetails/ProjectDetails'
import { BomItemDetailsProps } from './ProjectDetails/BomItemDetailsProps'
import { useAppDispatch } from 'store/configureStore'
import { useEffect } from 'react'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'

export const BomItemDetail = (props: BomItemDetailsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    requestIdleCallback(
      () => {
        dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))
      },
      { timeout: 1000 }
    )
  }, [dispatch, props.bomItemPointer])

  switch (props.bomItemPointer.type) {
    case BomItemType.project:
      return <ProjectDetails {...props} />
    case BomItemType.assemblyInstance:
    case BomItemType.assemblyType:
      return <AssemblyDetails {...props} />
    case BomItemType.partType:
    case BomItemType.partInstance:
      return <PartTypeDetails {...props} />
    default:
      return null
  }
}
