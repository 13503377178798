import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export type ConfirmationDialogProps = {
  title: string
  onConfirm: () => void
  message?: React.ReactNode
  onCancel?: () => void
  confirmText?: string
  cancelText?: string
  open?: boolean
  onClose?: () => void
  disableAutoCloseOnConfirm?: boolean
  disableOkButton?: boolean
  children?: React.ReactNode
}

export const ConfirmationDialog = ({
  title: title,
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  open,
  onClose,
  disableAutoCloseOnConfirm,
  ...props
}: ConfirmationDialogProps) => {
  const { t } = useTranslation()

  confirmText = confirmText || t('common:ok')
  cancelText = cancelText || t('common:cancel')
  message =
    message ||
    t('common:irreversible-action', 'This action is not reversible. Continue?')

  const { closeDialog } = useDialogManager()

  const handleConfirm = useCallback(() => {
    if (!disableAutoCloseOnConfirm) {
      onClose()
      closeDialog(true)
    }

    if (onConfirm) {
      onConfirm()
    }
  }, [closeDialog, disableAutoCloseOnConfirm, onClose, onConfirm])

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    onClose()
  }, [onCancel, onClose])

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (!open) {
        return
      }

      switch (e.key) {
        case 'Enter':
          handleConfirm()
          break
        case 'Escape':
          handleCancel()
          break
      }
    }

    if (open) {
      window.addEventListener('keydown', keydown)
    }

    return () => {
      window.removeEventListener('keydown', keydown)
    }
  }, [handleCancel, handleConfirm, open])

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="sm"
      fullWidth
      {...props}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="confirmation-dialog-description"
          style={{ whiteSpace: 'pre-line' }}
        >
          {message}
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          {cancelText}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={props.disableOkButton}
          color="primary"
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
