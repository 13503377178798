import { MoneyPerQuantityDto } from '../services/APIs/InternalAPI/internal-api.contracts'
import { MoneyString } from './Money'
import { QuantityString } from './Quantity'

export const MoneyPerQuantityString = (
  moneyPerQuantity: MoneyPerQuantityDto
) => {
  if (!moneyPerQuantity?.money || !moneyPerQuantity?.quantity) {
    return null
  }

  return `${MoneyString(moneyPerQuantity?.money)} / ${QuantityString(
    moneyPerQuantity?.quantity,
    {
      showValue1: false,
    }
  )}`
}
