import { Box } from '@mui/material'
import { AppLogo } from 'components/Layout/AppLogo'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import React from 'react'
import { Link } from 'react-router-dom'
import './Logout.scss'
import { useAppLogin } from './useAppLogin'

export const Logout = () => {
  const { appLogout, isAuthenticated } = useAppLogin()

  React.useEffect(() => {
    if (isAuthenticated) {
      window['Intercom']?.('shutdown')
      ;(async () => await appLogout())()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (isAuthenticated) {
    return (
      <div className="logout-page">
        <LocalizedTypography translationKey="common:loggingOut">
          Logging out...
        </LocalizedTypography>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
        textAlign="center"
        maxWidth={(theme) => theme.breakpoints.values.sm}
        gap={2}
      >
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <AppLogo style={{ maxWidth: '50%', display: 'inline-block' }} />
        </div>
        <LocalizedTypography
          translationKey="common:logged-out-text"
          variant="body2"
        >
          You have been logged out
        </LocalizedTypography>
        <Link to={'/login'}>
          <LocalizedTypography
            translationKey="common:go-to-login-page"
            variant="body2"
          >
            Go to login page
          </LocalizedTypography>
        </Link>
        {/* <Link to={'/getting-started'}>
          <LocalizedTypography
            translationKey="common:go-to-getting-started-page"
            variant="body2"
          >
            Getting started with Quotation Factory
          </LocalizedTypography>
        </Link> */}
      </Box>
    </div>
  )
}
