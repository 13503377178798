import { AttachMoneyOutlined, WarningOutlined } from '@mui/icons-material'
import { Slider, SliderThumb, Theme, Tooltip, Typography } from '@mui/material'
import TargetIcon from '_icons/target-icon.svg?react'
import { MoneyString } from 'model/Money'
import React, { SVGProps, createContext, forwardRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles, withStyles } from 'tss-react/mui'

const StyledTargetIcon = (props: SVGProps<SVGSVGElement>) => (
  <TargetIcon
    {...props}
    fill="currentColor"
    style={{ width: '42px', height: '42px' }}
    stroke="currentColor"
    strokeWidth={'56px'}
  />
)

type Props = {
  targetPrice: MoneyDto
  salesPrice: MoneyDto
  openTargetPricePopOver?: () => void
  rootClassName?: string
  value?: number
  index?: number
  open?: boolean
  children?: React.ReactElement
}

function ValueLabelComponent(props: Props) {
  const isSalesPrice = props.value === props.salesPrice.value
  const { t } = useTranslation()

  return (
    <Tooltip
      open={props.open}
      placement={props['index'] === 0 ? 'left' : 'right'}
      enterTouchDelay={0}
      PopperProps={{
        disablePortal: true,
      }}
      arrow
      title={
        <Typography
          variant="caption"
          color="inherit"
          style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
        >
          {isSalesPrice
            ? `${t('project:price-per-batch')}\n${MoneyString(
                props.salesPrice
              )}`
            : `${t('common:target-price', 'target price')}\n${MoneyString(
                props.targetPrice
              )}`}
        </Typography>
      }
    >
      {props.children}
    </Tooltip>
  )
}

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {
  'data-index': number
}

const ThumbRender = (props: ThumbComponentProps, ref) => {
  let IconComponent = <StyledTargetIcon />
  const context = useContext(TargetPriceSliderContext)

  if (context) {
    if (context.targetPrice.value < context.salesPrice.value) {
      if (props['data-index'] === 0) {
        // target
        IconComponent = <StyledTargetIcon />
      } else {
        IconComponent =
          context.salesPrice.value === 0 ? (
            <WarningOutlined />
          ) : (
            <AttachMoneyOutlined />
          )
      }
    } else {
      if (props['data-index'] === 1) {
        // target
        IconComponent = <StyledTargetIcon />
      } else {
        IconComponent =
          context.salesPrice.value === 0 ? (
            <WarningOutlined />
          ) : (
            <AttachMoneyOutlined />
          )
      }
    }
  }

  const { children, ...otherRootProps } = props

  return (
    <SliderThumb {...otherRootProps} ref={ref}>
      {children}
      {IconComponent}
    </SliderThumb>
  )
}

const ThumbComponent = forwardRef(ThumbRender)

const useStyles = makeStyles()((theme: Theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}))

const StyledSlider = withStyles(Slider, (theme: Theme) => ({
  thumb: {
    // height: 27,
    width: 20,
    backgroundColor: theme.palette.background.paper,
    '& .bar': {
      height: 9,
      width: 2,
      backgroundColor: 'currentColor',
    },
  },
  active: {},
  track: {
    height: 2,
  },
  rail: {
    color: theme.palette.divider,
    opacity: 1,
    height: 3,
  },
}))

const TargetPriceSliderContext = createContext<Props>(undefined)

export const TargetPriceSlider = (props: Props) => {
  const { classes, cx } = useStyles()
  // const { t } = useTranslation()

  if (!props.salesPrice || props.salesPrice.value === 0 || !props.targetPrice)
    return null

  const ratio = 1 - props.targetPrice.value / props.salesPrice.value

  const className =
    props.salesPrice.value === 0
      ? classes.warning
      : ratio > 0
      ? classes.error
      : ratio > -0.3
      ? classes.success
      : classes.info

  return (
    <TargetPriceSliderContext.Provider value={props}>
      <StyledSlider
        size="medium"
        value={[props.salesPrice.value, props.targetPrice.value]}
        className={cx(className, props.rootClassName)}
        min={
          Math.min(props.salesPrice.value, props.targetPrice.value) -
          0.1 * Math.min(props.salesPrice.value, props.targetPrice.value)
        }
        max={
          Math.max(props.salesPrice.value, props.targetPrice.value) +
          0.1 * Math.max(props.salesPrice.value, props.targetPrice.value)
        }
        track={'normal'}
        valueLabelDisplay="auto"
        // components={{
        //   Thumb: ThumbComponent,
        //   ValueLabel: (p) => <ValueLabelComponent {...p} {...props} />,
        // }}
        slots={{
          thumb: ThumbComponent,
          valueLabel: (p) => <ValueLabelComponent {...p} {...props} />,
        }}
        onClick={() =>
          props.openTargetPricePopOver && props.openTargetPricePopOver()
        }
      />
    </TargetPriceSliderContext.Provider>
  )
}
