import { useTranslation } from 'react-i18next'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RALColorInfo } from './RALColorInfo'
import RALData from './RALData.json'

export type RALColorInfoRecord = Record<string, RALColorInfo>

export type KeywordWithTranslation = KeywordDto & { translatedString?: string }

export function useRALColorAndTranslation(
  keyword: string
): RALColorInfo | undefined {
  const { t } = useTranslation('colors')

  if (keyword?.toUpperCase().startsWith('RAL')) {
    const ralCode = keyword.substring(3).trim()
    const ralData: RALColorInfo = RALData[ralCode]

    if (ralData) {
      ralData.translatedName = t(`RAL_${ralCode}`, ralData.names?.en)
    }

    return ralData
  }

  return undefined
}

export function useRALColorWithoutTranslation(
  keyword: string
): RALColorInfo | undefined {
  if (keyword.toUpperCase().startsWith('RAL')) {
    const ralCode = keyword.substring(3).trim()
    const ralData: RALColorInfo = RALData[ralCode]

    return ralData
  }

  return undefined
}
