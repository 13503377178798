import { isEqual } from 'lodash'
import {
  BomItemPointer,
  MaterialHeaderPointer,
} from 'model/Project/BomItemPointer'
import { useEffect } from 'react'
import {
  KeywordDto,
  MaterialSummaryDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { MaterialActions } from '../../../store/MaterialSelector/MaterialStore'

export const useArticleSelector = (
  bomItemPointers: BomItemPointer[],
  workingStepType: WorkingStepType,
  materialHeaderPointer?: MaterialHeaderPointer,
  initialKeywords?: KeywordDto[]
) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      MaterialActions.initMaterialSelector(
        bomItemPointers,
        workingStepType,
        materialHeaderPointer,
        initialKeywords
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const materialSelectorUI = useAppSelector(
    (state) => state.materials.articleSelectorUI,
    isEqual
  )

  const onTokensChanges = (tokens: KeywordDto[]) => {
    dispatch(MaterialActions.changeTokens(tokens, workingStepType))
  }

  const onMaterialSelected = (selectedMaterial: MaterialSummaryDto) => {
    dispatch(MaterialActions.selectMaterial(selectedMaterial))
  }

  const onClose = () => {
    dispatch(MaterialActions.closeModal())
  }

  return {
    materialSelectorUI,
    onTokensChanges,
    onMaterialSelected,
    onClose,
  }
}
