import { useState } from 'react'

export const useHandleDragFiles = (
  onDragFiles?: (isDragging: boolean) => void
) => {
  const [isDragging, setIsDragging] = useState(false)

  const handleDragFiles = (ev: React.DragEvent<HTMLDivElement>) => {
    if (ev.dataTransfer.types) {
      for (let i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types[i] === 'Files') {
          onDragFiles && onDragFiles(true)
          setIsDragging(true)
        }
      }
    }
  }

  return {
    handleDragFiles,
    isDragging,
  }
}
