import {
  BoMItemActivityDto,
  KeywordDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'

export function getBomItemActivityKeywords(
  bomItemActivity: BoMItemActivityDto
): Record<string, KeywordDto[]> {
  return bomItemActivity.keywords
}
