import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useState } from 'react'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'

export const usePartGeometryDetails = (bomItemPointer: BomItemPointer) => {
  const { controller, loading } = useAppController(
    () => new BomItemController()
  )

  const loadPartGeometryDetails = useCallback(async () => {
    try {
      return await controller.GetItemFeaturesDetails(bomItemPointer)
    } catch (err) {
      console.error(err)
      return undefined
    }
  }, [bomItemPointer, controller])

  const [formDetails, setGeometryDetails] = useState<FeatureDto[]>(undefined)

  const handleLoadGeometryDetails = useCallback(async () => {
    try {
      const details = await loadPartGeometryDetails()
      setGeometryDetails(details?.features)
    } catch (err) {
      ShowException('Failed to load geometry details', err)
    }
  }, [loadPartGeometryDetails])

  useEffect(() => {
    handleLoadGeometryDetails()

    return () => {
      controller.CancelRequests()
      setGeometryDetails(undefined)
    }
  }, [controller, handleLoadGeometryDetails])

  return { formDetails, loading, handleLoadGeometryDetails }
}
