const getPropDeep = (obj, propPath: string) =>
  propPath.split('.').reduce((obj, prop) => (obj ? obj[prop] : undefined), obj)

export const alphabetcalSort =
  (propertyPath: string) =>
  <T>(a: T, b: T) => {
    const propA = getPropDeep(a, propertyPath)
    const propB = getPropDeep(b, propertyPath)

    if (propA && !propB) return -1
    if (!propA && propB) return 1

    if (propA?.toLowerCase() < propB?.toLowerCase()) return -1
    if (propA?.toLowerCase() > propB?.toLowerCase()) return 1

    return 0
  }
