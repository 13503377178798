import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { SetBoMItemAsPurchasingPartRequest } from 'services/APIs/InternalAPI/internal-api.contracts'
import { IPurchasingPartInfoAPI } from './PurchasingInfoController'

export class PurchasingPartInfoAPI
  extends InternalAPI
  implements IPurchasingPartInfoAPI
{
  constructor(
    organizationId: string,
    projectId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(`/api/parties/${organizationId}/projects/${projectId}`)
  }

  public async setBomItemAsPurchasingPart(
    bomItemId: string,
    req: SetBoMItemAsPurchasingPartRequest
  ): Promise<void> {
    await this.PostAsync<void>({
      id: `${bomItemId}`,
      relativePath: `/items/${bomItemId}/purchasing/setPurchasingInfo`,
      data: req,
      onRequestChange: this.onRequestChange,
    })
  }

  public async unsetBomItemAsPurchasingPart(bomItemId: string) {
    await this.DeleteAsync({
      id: `${bomItemId}`,
      relativePath: `/items/${bomItemId}/purchasing`,
      onRequestChange: this.onRequestChange,
    })
  }
}
