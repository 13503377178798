import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useAppController } from 'customHooks/useAppController'
import { BomItemActivityController } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivityController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { ArticleSelectorUI } from '../../../model/ArticleSelectorUI'
import {
  KeywordDto,
  MaterialSummaryDto,
  WorkingStepType,
} from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { MaterialActions } from '../../../store/MaterialSelector/MaterialStore'
import { SettingsActions } from '../../../store/Settings/SettingsStore'
import { DialogCloseSaveButtons } from '../DialogActionButtons/DialogCloseSaveButtons'
import { LoadingContainer } from '../LoadingContainer'
import './ArticleSelectorDialog.scss'
import { AvailableArticles } from './AvailableArticles'
import { MaterialSelectorModalHeader } from './MaterialSelectorModalHeader'
import { TokenSelector } from './TokenSelector'

type OwnProps = {
  showSaveButton: boolean
  modalId: string
  isPurchasingPrice: boolean
  open: boolean
}

type StateProps = {
  articleSelectorUI: ArticleSelectorUI
  activeRows: PartTypeRow[]
  isLoading: boolean
  projectId?: string
  organizationId?: string
  openedMaterialSelectorPointer: BomItemPointer
  workingStepType?: WorkingStepType
}

type DispatchProps = {
  onClose: () => void
  onChangeTokens: (
    selectedTokens: KeywordDto[],
    workingStepType: WorkingStepType
  ) => void
  onMaterialSelected: (selectedMaterial: MaterialSummaryDto) => void
  saveRows: () => void
  flip3dModel: (rowId: string, reset: boolean) => void
  rotate3dModel: (rowId: string) => void
  addPurchasingData: (material) => void
}

export type ArticleSelectorDialogProps = StateProps & DispatchProps & OwnProps

const mapStateToProps = (state: RootAppState): StateProps => ({
  articleSelectorUI: state.materials.articleSelectorUI,
  activeRows: state.materials.activeRows,
  isLoading: state.materials.isLoading,
  projectId: state.project?.activeProject?.id,
  openedMaterialSelectorPointer: state.materials.materialHeaderPointer,
  organizationId: state.user.organizationContext?.id,
  workingStepType: state.materials.workingStepType,
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onClose: () => {
    dispatch(MaterialActions.closeModal())
    dispatch(UIActions.CloseModal())
  },
  onChangeTokens: (
    selectedTokens: KeywordDto[],
    workingStepType: WorkingStepType
  ) => dispatch(MaterialActions.changeTokens(selectedTokens, workingStepType)),
  onMaterialSelected: (material: MaterialSummaryDto) =>
    dispatch(MaterialActions.selectMaterial(material)),
  saveRows: () => {
    {
      dispatch(MaterialActions.saveSelectedTokensAndMaterials())
    }
  },
  flip3dModel: (rowId: string, reset: boolean) =>
    dispatch(MaterialActions.flipModel(rowId, reset)),
  rotate3dModel: (rowId: string) =>
    dispatch(MaterialActions.rotateModel(rowId)),
  addPurchasingData: (material) =>
    dispatch(SettingsActions.addPurchasingData(material)),
})

const ArticleSelectorDialog = (props: ArticleSelectorDialogProps) => {
  const { t } = useTranslation('project')
  const projectId = useAppSelector(projectSelectors.projectIdSelector)

  const handleMaterialSelected = (
    material: MaterialSummaryDto,
    shouldSave = false
  ) => {
    props.onMaterialSelected(material)

    if (props.isPurchasingPrice) {
      props.addPurchasingData(material)
    }

    if (shouldSave && props.saveRows) {
      props.saveRows()
    }
  }

  const { controller, loading } = useAppController(
    () => new BomItemActivityController()
  )

  const dispatch = useAppDispatch()

  const handleSaveSelectedArticle = async () => {
    try {
      const isRawMaterial = props.activeRows[0]?.activities.find(
        (x) => x.primaryWorkingStep === props.workingStepType
      )?.usesRawMaterial

      await controller.SetBomItemActivityArticle(
        props.activeRows.map((row) => {
          return {
            bomItemPointer: {
              id: row?.id,
              type: row.type,
            },
            activityId: row.activities.find(
              (x) => x.primaryWorkingStep === props.workingStepType
            )?.id,
          }
        }),
        props.articleSelectorUI.selectedMaterial?.id,
        props.articleSelectorUI.selectedTokens,
        isRawMaterial
      )

      props.onClose()

      dispatch(
        newProjectActions.setBomItemInSearch({
          id: props.activeRows[0]?.id,
          type: BomItemType.partType,
        })
      )
    } catch (err) {
      ShowException('Article Selector', err)
    } finally {
      dispatch(fetchProject({ projectId: projectId }))
    }
  }

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      disableRestoreFocus
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        <MaterialSelectorModalHeader
          {...props.activeRows[0]}
          title={t('article-selector--title', 'article selector')}
        />
      </DialogTitle>
      <DialogContent className="material-selector--content">
        <LoadingContainer
          loading={
            props.isLoading ||
            props.openedMaterialSelectorPointer?.id !== props.modalId
          }
          variant="spinner"
          noMessage
        >
          <Grid container spacing={1} style={{ height: '100%' }}>
            <Grid item xs={4}>
              <TokenSelector
                {...props.articleSelectorUI}
                width={'100%'}
                onTokensChanges={(tokens) => {
                  props.onChangeTokens(tokens, props.workingStepType)
                }}
              />
            </Grid>
            <Grid item xs>
              <AvailableArticles
                {...props.articleSelectorUI}
                width={'100%'}
                onMaterialSelected={handleMaterialSelected}
              />
            </Grid>
          </Grid>
        </LoadingContainer>
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={props.onClose}
          onSaveButtonClicked={() => {
            // setSaving(true)
            handleSaveSelectedArticle()
          }}
          saveButtonDisabled={!props.showSaveButton}
          saving={loading['bomitem-keywords']}
        />
      </DialogActions>
    </Dialog>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleSelectorDialog)
