import { Paper, Theme } from '@mui/material'
import _ from 'lodash'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { PriceScope } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { PriceDetailsGraphData } from '../../useBomItemDetails'
import { MaterialTooltip } from './MaterialTooltip'
import { PartOrAssemblyTooltip } from './PartOrAssemblyTooltip'
import { ProjectLevelMaterialTooltip } from './ProjectLevelMaterialTooltip'
import { ProjectLevelWorkingStepTooltip } from './ProjectLevelWorkingStepTooltip'
import { SurchargeAndDiscountTooltip } from './SurchargeAndDiscountTooltip'
import { WorkingStepTooltip } from './WorkingStepTooltip'

export type TooltipTypes =
  | 'WorkingStep'
  | 'WorkingStepSummary'
  | 'Part'
  | 'ChildrenSummary'
  | 'Material'
  | 'SurchargeAndDiscount'
  | 'ProjectLevelMaterial'
  | 'ProjectLevelWorkingStep'
  | 'Total'

export type TargetPriceTooltipPayload = {
  data: PriceDetailsGraphData
  tooltipDataType: TooltipTypes
  priceScope: PriceScope
}

export type TargetPriceTooltipProps = {
  activeDataKey: string
  priceScope: PriceScope
} & TooltipProps<ValueType, NameType>

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1, 2),
    zIndex: 1000,
    maxWidth: '320px',
    maxHeight: '1000px',
    //   padding: theme.spacing(2),
  },
}))

export const TargetPriceTooltip = (props: TargetPriceTooltipProps) => {
  const { classes } = useStyles()

  if (props.active && props.payload && props.payload.length) {
    let currentItem

    if (
      props.activeDataKey?.startsWith('childrenBomItems') ||
      props.activeDataKey?.startsWith('materialPrices') ||
      props.activeDataKey?.startsWith('workingStepPrices')
    ) {
      currentItem = _.get(props.payload[0].payload, props.activeDataKey)
    } else {
      currentItem = props.payload.find(
        (x) => x.dataKey.toString().split('.value')[0] === props.activeDataKey
      )
    }

    if (!currentItem) {
      return null
    }

    const GetTooltipComponent = () => {
      if (props.activeDataKey?.startsWith('childrenBomItems')) {
        return (
          <PartOrAssemblyTooltip
            bomItemPointer={currentItem.bomItemPointer}
            priceScope={props.priceScope}
          />
        )
      }

      if (props.activeDataKey?.startsWith('materialPrices')) {
        return <ProjectLevelMaterialTooltip data={currentItem} />
      }

      if (props.activeDataKey?.startsWith('workingStepPrices')) {
        return <ProjectLevelWorkingStepTooltip data={currentItem} />
      }

      switch (
        currentItem.dataKey
          .toString()
          .split('.value')[0] as keyof PriceDetailsGraphData
      ) {
        case 'materialSalesPrice':
          return <MaterialTooltip data={currentItem.payload} />
        case 'loading':
        case 'setup':
        case 'mainActivityCostPrice':
        case 'unloading':
        case 'totalCostPrice':
          return (
            <WorkingStepTooltip
              data={currentItem.payload}
              dataKey={currentItem.dataKey.toString().split('.value')[0]}
            />
          )
        case 'surchargeValue':
        case 'discountValue':
          return <SurchargeAndDiscountTooltip data={currentItem.payload} />
        default:
          return null
      }
    }

    return (
      <Paper elevation={3} className={classes.root}>
        {GetTooltipComponent()}
      </Paper>
    )
  }

  return null
}

// const stylePosition: CSSProperties = {
//   position: 'fixed',
//   top: props.position.y || -20000 + 5,
//   left: props.position.x || -20000 + 5,
//   zIndex: 10,
//   maxWidth: '220px',
// }

// const divRef = useRef<HTMLDivElement>(undefined)

// useLayoutEffect(() => {
//   // detect if the tooltip is outside the window and adjust the position
//   if (divRef.current) {
//     let leftPosition = 0
//     let topPosition = 0

//     if (
//       window.innerWidth - 12 - props.position.x >
//       divRef.current.clientWidth
//     ) {
//       // shows on the right
//       leftPosition = props.position.x + 12
//     } else {
//       // shows on the left
//       leftPosition = props.position.x - divRef.current.clientWidth - 12
//     }

//     if (
//       window.innerHeight - 12 - props.position.y >
//       divRef.current.clientHeight
//     ) {
//       // shows on the bottom
//       topPosition = props.position.y
//     } else {
//       // shows on the top
//       topPosition = props.position.y - divRef.current.clientHeight / 2 - 12
//     }

//     divRef.current.style.top = `${topPosition}px`
//     divRef.current.style.left = `${leftPosition}px`
//   }
// }, [props.position.x, props.position.y])

// if (!props.payload?.data || !props.payload?.tooltipDataType) {
//   return <div ref={divRef} style={stylePosition} />
// }

// const Tooltips: Record<TooltipTypes, ReactElement> = {
//   Part: (
//     <PartOrAssemblyTooltip
//       bomItemPointer={props.payload.data.bomItemPointer}
//       priceScope={props.payload.priceScope}
//     />
//   ),
//   WorkingStep: <WorkingStepTooltip data={props.payload.data} />,
//   WorkingStepSummary: <WorkingStepSummaryTooltip data={props.payload.data} />,
//   ChildrenSummary: <ChildrenSummaryTooltip data={props.payload.data} />,
//   Material: <MaterialTooltip data={props.payload.data} />,
//   SurchargeAndDiscount: (
//     <SurchargeAndDiscountTooltip data={props.payload.data} />
//   ),
//   ProjectLevelMaterial: (
//     <ProjectLevelMaterialTooltip data={props.payload.data} />
//   ),
//   ProjectLevelWorkingStep: (
//     <ProjectLevelWorkingStepTooltip data={props.payload.data} />
//   ),

//   Total: <TotalTooltip data={props.payload.data} />,
// }

// const TooltipComponent = Tooltips[props.payload?.tooltipDataType]

// return (
//   <div style={stylePosition} ref={divRef}>
//     {/* <ClickAwayListener onClickAway={props.onClose}> */}
//     <Paper className={classes.root}>{TooltipComponent}</Paper>
//     {/* </ClickAwayListener> */}
//   </div>
// )
// }
