import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  GetGeometryOperationsResponse,
  GetOperationSummaryResponse,
  ProjectOperationDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { IProjectOperationsAPI } from './GeometryLogs.types'

export class ProjectOperationsAPI
  extends InternalAPI
  implements IProjectOperationsAPI
{
  constructor(
    organizationId: string,
    projectId: string,
    private onRequestChanged?: OnRequestChangeCallback
  ) {
    super()
    this.baseAddress += `/api/parties/${organizationId}/projects/${projectId}`
  }

  public async GetProjectOperations() {
    return await this.GetAsync<ProjectOperationDto[]>({
      id: 'get-project-operations',
      relativePath: `/operations`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetGeometriesOperations(page, pageSize) {
    return await this.GetAsync<GetGeometryOperationsResponse>({
      id: 'get-geometries-operations',
      relativePath: `/operations/geometries`,
      params: {
        page,
        pageSize,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public async RemoveAllGeometryLogs() {
    return await this.DeleteAsync({
      relativePath: `/operations/geometries`,
      onRequestChange: this.onRequestChanged,
    })
  }

  public async GetOperationsSummary() {
    return await this.GetAsync<GetOperationSummaryResponse>({
      id: 'get-operations-summary',
      relativePath: `/operations/summary`,
      onRequestChange: this.onRequestChanged,
    })
  }
}
