import { ListItemText, ListItemTextProps } from '@mui/material'

export const TargetPriceTooltipListItemText = (props: ListItemTextProps) => {
  return (
    <ListItemText
      {...props}
      primaryTypographyProps={{
        component: 'span',
        sx: {
          fontSize: (theme) => theme.typography.caption.fontSize,
        },
      }}
      secondaryTypographyProps={{
        component: 'span',
        sx: {
          fontSize: (theme) => theme.typography.body2.fontSize,
          color: (theme) => theme.typography.body2.color,
        },
      }}
    />
  )
}
