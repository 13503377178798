import { Box, Typography } from '@mui/material'
import { MoneyPerQuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  DefinitiveMoneyField,
  DefinitiveMoneyFieldProps,
} from './DefinitiveMoneyField'
import {
  DefinitiveQuantityField,
  DefinitiveQuantityFieldProps,
} from './DefinitiveQuantityField'

type Props = Omit<DefinitiveMoneyFieldProps, 'moneyDto'> &
  Omit<DefinitiveQuantityFieldProps, 'quantityDto'> & {
    value: MoneyPerQuantityDto
    onChange: (value: MoneyPerQuantityDto) => void
    label?: string
  }

export const DefinitiveMoneyPerQuantityField = (props: Props) => {
  return (
    <Box
      className={props.rootElementClassName}
      sx={
        props.rootElementClassName
          ? null
          : {
              display: 'flex',
              alignItems: 'flex-end',
              gap: '.5em',
              '& label': {
                textOverflow: 'unset',
                overflow: 'visible',
              },
            }
      }
    >
      <DefinitiveMoneyField
        {...props}
        textFieldProps={{
          ...(props.textFieldProps || {}),
          label: props.label,
          name: 'money',
        }}
        moneyDto={props.value.money}
        onChange={(value) => {
          props.onChange({
            ...props.value,
            money: value,
          })
        }}
      />
      <Typography color="GrayText">/</Typography>
      <DefinitiveQuantityField
        {...props}
        textFieldProps={{
          label: ' ',
          name: 'quantity',
        }}
        numberFormatProps={{
          decimalScale: 0,
          allowNegative: false,
        }}
        quantityDto={props.value.quantity}
        onChange={(value) => {
          props.onChange({
            ...props.value,
            quantity: value,
          })
        }}
      />
    </Box>
  )
}
