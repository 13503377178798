import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { dashify } from 'utils/dashify'
import { FeatureTreeItem } from './FeatureTreeItem'
import { FeatureTreeSections } from './FeatureTreeSections'

type Props = {
  feature: FeatureDto
  index: number
  modelController: ModelController
  faceIdToFilter?: number
  variant?: 'small'
  filterByModelSelectedFaces?: boolean
  featureLabelDefaultExpanded?: string
  onReload: () => void
}

export const FeatureTreeNode = (props: Props) => {
  const [expanded, setExpanded] = React.useState(
    props.faceIdToFilter
      ? true
      : props.featureLabelDefaultExpanded === props.feature.label
      ? true
      : false
  )

  const { t } = useTranslation()

  const [hasSelectedFace, setHasSelectedFace] = useState(true)

  const filterCallback = useCallback(
    (args: { elementId: string | number; elementType: string }[]) => {
      if (!args?.length || !props.filterByModelSelectedFaces) {
        setHasSelectedFace(true)
        setExpanded(false)
        return
      }

      for (let i = 0; i < args.length; i++) {
        const faceIds = [
          ...props.feature.details?.flatMap((details) => details.faceIds),
          ...props.feature.sections?.flatMap((section) => section.faceIds),
          ...props.feature.sections?.flatMap((section) =>
            section.details?.flatMap((details) => details.faceIds)
          ),
        ].flat()

        const containsSelectedElement = faceIds.includes(
          Number(args[i].elementId)
        )

        if (
          args[i]?.elementType &&
          args[i].elementType.toUpperCase() === 'FACE' &&
          !containsSelectedElement
        ) {
          setHasSelectedFace(false)
          setExpanded(false)
        } else {
          setHasSelectedFace(true)
          setExpanded(true)
        }
      }
    },
    [
      props.feature.details,
      props.feature.sections,
      props.filterByModelSelectedFaces,
    ]
  )

  useEffect(() => {
    if (!props.filterByModelSelectedFaces) {
      setHasSelectedFace(true)
    } else {
      filterCallback(props.modelController.selectedItems)
    }
  }, [
    filterCallback,
    props.filterByModelSelectedFaces,
    props.modelController.selectedItems,
  ])

  useEffect(() => {
    const unregister = props.modelController.onSelectionChanged(filterCallback)

    return () => {
      unregister()
    }
  }, [filterCallback, props.modelController])

  return (
    <Accordion
      style={{ display: hasSelectedFace ? 'block' : 'none' }}
      key={props.index}
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      variant="outlined"
    >
      {!props.faceIdToFilter && (
        <AccordionSummary>
          {t(`project:${dashify(props.feature.label)}`, props.feature.label)}
        </AccordionSummary>
      )}
      <AccordionDetails>
        {props.feature.details?.map((detail, index) => {
          if (
            props.faceIdToFilter &&
            !detail.faceIds.includes(props.faceIdToFilter)
          ) {
            return null
          }

          return (
            <Fragment key={index}>
              <FeatureTreeItem
                key={`${detail.label}-${index}`}
                featureDetail={detail}
                modelController={props.modelController}
                onOpen={() => setExpanded(true)}
                isOpen={expanded}
                variant={props.variant}
                onReload={props.onReload}
              />
            </Fragment>
          )
        })}
        {props.feature.sections && (
          <>
            <FeatureTreeSections
              featureSections={props.feature.sections}
              modelController={props.modelController}
              onOpen={() => {
                setExpanded(true)
              }}
              isOpen={expanded}
              faceIdToFilter={props.faceIdToFilter}
              variant={props.variant}
              onReload={props.onReload}
              shallFilterBasedOnModelSelection={
                props.filterByModelSelectedFaces
              }
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
