import { ChevronRightOutlined } from '@mui/icons-material'
import { Collapse, IconButton, Tooltip, Typography } from '@mui/material'
import FilterAltOffOutlined from '_icons/filterAltOffOutlined.svg?react'
import FilterAltOutlined from '_icons/filterAltOutlined.svg?react'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { useResourceIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useResourceIdFilter'
import ProjectResourceDiscountOrSurchageEditor from 'features/BillOfMaterials/ProjectViews/DashboardView/ProjectOverview/components/ProjectResourceDiscountOrSurchargeEditor'
import { t } from 'i18next'
import { MoneyString, SumMoney } from 'model/Money'
import { useEffect, useState } from 'react'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { getPriceSummaryKey } from 'store/Project/ProjectStateUtils'
import { makeStyles } from 'tss-react/mui'
import { ProjectWorkingStepPriceSummary } from '../ProjectPriceSummaries/WorkingStepPriceSummary'

type Props = {
  resources: Record<string, PriceSummaryDto[]>
  forceOpen: boolean
}

const useStyles = makeStyles({ name: 'ProjectTotalsResourcesRow' })((theme) => {
  return {
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    header: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
    filterButton: {
      marginLeft: 'auto',
      paddingRight: theme.spacing(1),
    },
    resourceSurchargeAndDiscountContainer: {
      display: 'flex',
      gap: theme.spacing(5),
      padding: theme.spacing(1, 2),
      justifyContent: 'center',
      textAlign: 'center',
    },
    resourceSurchargeAndDiscount: {
      textAlign: 'left',
      width: '120px',
    },
  }
})

const ResourceRow = (props: {
  priceSummaries: PriceSummaryDto[]
  forceOpen: boolean
}) => {
  const { classes } = useStyles()

  const [open, setOpen] = useState(props.forceOpen)

  useEffect(() => {
    setOpen(props.forceOpen)
  }, [props.forceOpen])

  const { handleChangeFilter, filterIsActive, resourceIdFilter } =
    useResourceIdFilter()

  const { articleIdFilter } = useArticleIdFilter()

  const resource = props.priceSummaries?.[0]?.workingStep?.resource

  if (
    resourceIdFilter.active &&
    !resourceIdFilter.filter.enabledFilters.includes(resource.id)
  ) {
    return null
  }

  if (props.priceSummaries.length === 0) {
    return null
  }

  if (articleIdFilter.active) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={() => setOpen((x) => !x)}>
        <IconButton>
          <ChevronRightOutlined
            style={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </IconButton>
        <Typography variant="body1">{resource?.name}</Typography>
        <div className={classes.filterButton}>
          <Tooltip
            title={t('project:filter-by-resource-id', 'filter by resource')}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleChangeFilter(resource.id)
                setOpen(true)
              }}
              style={{ alignSelf: 'center' }}
              sx={{
                '& svg': {
                  fill: (theme) => theme.palette.text.primary,
                  width: '1em',
                },
              }}
              size="small"
              color="primary"
            >
              {filterIsActive(resource.id) ? (
                <FilterAltOffOutlined />
              ) : (
                <FilterAltOutlined />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Collapse in={open} unmountOnExit>
        <div className={classes.resourceSurchargeAndDiscountContainer}>
          <div
            style={{
              // width: '100%',
              display: 'flex',
              gap: '2rem',
              flex: '1 1 100%',
              paddingLeft: '33%',
            }}
          >
            <div className={classes.resourceSurchargeAndDiscount}>
              <LocalizedTypography
                translationKey="project:surcharge"
                variant="caption"
                color="textSecondary"
              >
                surcharge
              </LocalizedTypography>
              <ProjectResourceDiscountOrSurchageEditor
                priceSummaries={props.priceSummaries}
                priceSummaryKey={getPriceSummaryKey(props.priceSummaries[0])}
                resourceId={props.priceSummaries[0].workingStep?.resource?.id}
                surchargeOrDiscount="surcharge"
              />
            </div>

            <div className={classes.resourceSurchargeAndDiscount}>
              <LocalizedTypography
                translationKey="project:discount"
                variant="caption"
                color="textSecondary"
              >
                discount
              </LocalizedTypography>
              <ProjectResourceDiscountOrSurchageEditor
                priceSummaries={props.priceSummaries}
                priceSummaryKey={getPriceSummaryKey(props.priceSummaries[0])}
                resourceId={props.priceSummaries[0].workingStep?.resource?.id}
                surchargeOrDiscount="discount"
              />
            </div>
          </div>

          <div
            style={{
              marginLeft: 'auto',
              flex: '1 1 100px',
              textAlign: 'right',
              minWidth: '100px',
            }}
          >
            <LocalizedTypography
              translationKey="project:sales-price"
              variant="caption"
              color="textSecondary"
            >
              sales price
            </LocalizedTypography>
            <Typography style={{ textAlign: 'right' }}>
              {MoneyString(
                SumMoney(props.priceSummaries.map((x) => x.totalSalesPrice))
              )}
            </Typography>
          </div>
        </div>
        {props.priceSummaries.map((priceSummary) => (
          <div key={priceSummary.workingStep.key}>
            <ProjectWorkingStepPriceSummary priceSummary={priceSummary} />
          </div>
        ))}
      </Collapse>
    </div>
  )
}

export const ProjectTotalsResourcesRows = (props: Props) => {
  return (
    <div>
      {Object.entries(props.resources).map(([resourceId, priceSummaries]) => (
        <div key={resourceId}>
          <ResourceRow
            priceSummaries={priceSummaries}
            forceOpen={props.forceOpen}
          />
        </div>
      ))}
    </div>
  )
}
