import { useCallback } from 'react'
import { Key } from 'utils/keyCodes'

export function useDataGridEditorNavigation(
  onClose: (key: string) => void,
  ignoreArrowUpAndDown = false
) {
  const handleNavigationKeys = useCallback(
    (event: React.KeyboardEvent<unknown>) => {
      // like Excel, arrow keys move focus to the next cell
      if (
        (!ignoreArrowUpAndDown && event.key === Key.ArrowUp) ||
        (!ignoreArrowUpAndDown && event.key === Key.ArrowDown) ||
        event.key === Key.ArrowLeft ||
        event.key === Key.ArrowRight
      ) {
        event.preventDefault()
        event.stopPropagation()
        onClose(event.key)
      }
    },
    [ignoreArrowUpAndDown, onClose]
  )

  return { handleNavigationKeys }
}
