import { PriceRowDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export const priceRowType:
  | undefined
  | 'material'
  | 'workingStep'
  | 'total'
  | 'machine' = undefined

export const priceRowIdentifier = (
  priceRow: PriceRowDto
): typeof priceRowType => {
  if (priceRow.isWorkingStep) {
    return 'workingStep'
  }

  if (priceRow.materialId) {
    return 'material'
  }

  if (priceRow.isTotalRow) {
    return 'total'
  }

  if (
    !priceRow.isTotalRow &&
    !priceRow.isWorkingStep &&
    priceRow.children?.length
  ) {
    return 'machine'
  }

  return undefined
}
