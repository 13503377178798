import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  AcceptPartyInviteRequest,
  AcceptPartyInviteResponse,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { IAcceptInviteAPI } from './IAcceptInviteAPI'

export class AcceptInviteAPI extends InternalAPI implements IAcceptInviteAPI {
  constructor(private onRequestChange: OnRequestChangeCallback) {
    super('/api/customer-portal/accept-invite')
  }
  public async AcceptInvite(req: AcceptPartyInviteRequest) {
    return await this.PostAsync<AcceptPartyInviteResponse>({
      relativePath: '/',
      data: req,
      id: 'accept-request',
      onRequestChange: this.onRequestChange,
    })
  }
}
