import i18n from 'i18next'

export function NumberParts(
  language: string,
  type: Intl.NumberFormatPartTypes
) {
  return Intl.NumberFormat(language)
    .formatToParts(1000.1)
    .find((x) => x.type === type)?.value
}

export function GetCurrentLanguageNumberPart(
  numberFormatPart: Intl.NumberFormatPartTypes
) {
  return NumberParts(i18n.language, numberFormatPart)
}
