import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import FilterIconSvg from '_icons/project-menu/filter.svg?react'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import BomItemBatchUpdateGrid from 'features/BillOfMaterials/BatchEditRowsDialog/BomItemBatchUpdateGrid'
import { isEqual } from 'lodash'
import {
  AssemblyHeaderRow,
  BomItemType,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { useEffect, useState } from 'react'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { BoMItemSatisfyActiveFilters } from 'utils/BoMItemSatisfyActiveFilters'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import FiltersContainer from '../BoMItemFilter/Components/FiltersContainer'
import { batchUpdateBomItems } from '../store/asyncActions/batchUpdateBomItems'
import { newProjectActions } from '../store/projectReducer'
import { bomItemSelector } from '../store/selectors/bomItemSelector'
import { projectOperationSelector } from '../store/selectors/projectOperationPendingSelector'
import { selectedBomItemsSelector } from '../store/selectors/selectedBomItemsSelector'
import { BomItemUpdateGridModel } from './BomItemUpdateGridModel'

export type BatchEditBomItemsDialogProps = {
  open: boolean
  onClose: () => void
}

const assembliesSelector = (state: RootAppState): AssemblyHeaderRow[] => {
  return state.project.assemblyHeadersIds.reduce((acc, curr) => {
    const assemblyHeader = bomItemSelector(state, {
      id: curr,
      type: BomItemType.assemblyType,
    }) as AssemblyHeaderRow

    if (
      assemblyHeader?.filteredPartTypePointers?.length > 0 &&
      assemblyHeader?.partTypeIds?.length > 0 &&
      BoMItemSatisfyActiveFilters(state.project, assemblyHeader)
    ) {
      acc.push(assemblyHeader)
    }

    return acc
  }, [])
}

export const BatchEditRows = ({
  open,
  onClose,
}: BatchEditBomItemsDialogProps) => {
  const [rows, setRows] = useState<BomItemUpdateGridModel[]>([])
  const { partyId, projectId } = useOrganizationAndProjectContext()
  const dispatch = useAppDispatch()

  const selectedBomItems = useAppSelector(
    selectedBomItemsSelector,
    isEqual
  ) as PartTypeRow[]

  const assemblies = useAppSelector(assembliesSelector, isEqual)

  const operationStatus = useAppSelector(
    projectOperationSelector(batchUpdateBomItems.typePrefix),
    isEqual
  )

  useEffect(() => {
    if (operationStatus?.requestStatus === 'fulfilled') {
      onClose()
    }
  }, [onClose, operationStatus?.requestStatus])

  useEffect(() => {
    setRows(
      selectedBomItems.map((x) => ({
        bomItemPointer: { id: x.id, type: x.type },
        rowNumber: x.rowNumber,
        thumbnail3d: projectImageUrl(partyId, projectId, x.thumbnail3d),
        name: x.name,
        quantity: x.financial?.quantity,
        // keywords: x.materialSummary.tokens,
        comment: x.comment,
        enableChangeQuantity: x.canChangeQuantity,
        originalFileName: x.originalFileName,
        activityId: x.activities?.find((x) => x.usesRawMaterial)?.id,
        drawingNumber: x.drawingNumber,
        revision: x.revision,
      }))
    )
  }, [partyId, projectId, selectedBomItems])

  const handleSave = () => {
    dispatch(
      batchUpdateBomItems(
        rows.map((x) => ({
          name: x.name,
          bomItemPointer: x.bomItemPointer,
          quantity: x.quantity,
          keywords: x.keywords,
          comment: x.comment,
        }))
      )
    )
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        Assemblies
        <Autocomplete
          onChange={(event, value) => {
            if (typeof value === 'string') {
              return
            }
            const selectedRows = value
              .map((x) => {
                if (typeof x !== 'string') {
                  return x.filteredPartTypePointers
                }
                return null
              })
              .flat()

            if (selectedRows) {
              dispatch(
                newProjectActions.setSelectedBomItems(
                  selectedRows.filter((x) => x !== null)
                )
              )
            }
          }}
          multiple
          options={assemblies}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option.name}
          renderOption={(el, option) => (
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              {option.thumbnail3d ? (
                <img
                  loading="lazy"
                  src={projectImageUrl(partyId, projectId, option.thumbnail3d)}
                  style={{
                    width: '3rem',
                  }}
                />
              ) : (
                <div style={{ width: '3rem' }} />
              )}
              <Typography variant="body1">{option.name}</Typography>
            </div>
          )}
        />
        <Accordion>
          <AccordionSummary>
            <FilterIconSvg style={{ width: '3em' }} />
          </AccordionSummary>
          <AccordionDetails>
            <FiltersContainer />
          </AccordionDetails>
        </Accordion>
      </DialogTitle>
      <DialogContent>
        <BomItemBatchUpdateGrid
          bomItemUpdateModels={rows}
          setBomItemUpdateModel={setRows}
        />
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={onClose}
          onSaveButtonClicked={handleSave}
          saving={operationStatus?.requestStatus === 'pending'}
        />
      </DialogActions>
    </Dialog>
  )
}
