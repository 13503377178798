import { ProjectArticleController } from 'controllers/Project/ProjectArticleController'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { useAppController } from 'customHooks/useAppController'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  MoneyDto,
  PriceSummaryDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'

export const useDiscountAndSurchargeEditor = (
  priceSummary: PriceSummaryDto
) => {
  const { controller } = useAppController(() => new WorkingStepsController())

  const { controller: articleController } = useAppController(
    () => new ProjectArticleController()
  )

  const [changedFields, setChangedFields] = useState<{
    surchargeRatio: QuantityDto
    surchargeValue: MoneyDto
    discountRatio: QuantityDto
    discountValue: MoneyDto
  }>({
    surchargeRatio: priceSummary?.surchargeRatio,
    surchargeValue: priceSummary?.surchargeValue,
    discountRatio: priceSummary?.discountRatio,
    discountValue: priceSummary?.discountValue,
  })

  useEffect(() => {
    setChangedFields({
      surchargeRatio: priceSummary?.surchargeRatio,
      surchargeValue: priceSummary?.surchargeValue,
      discountRatio: priceSummary?.discountRatio,
      discountValue: priceSummary?.discountValue,
    })
  }, [priceSummary])

  const [changed, setChanged] = useState<Record<string, boolean>>({
    surchargeRatio: false,
    surchargeValue: false,
    discountRatio: false,
    discountValue: false,
  })

  const handleChangeFields = (
    field: keyof typeof changedFields,
    value: QuantityDto | MoneyDto
  ) => {
    if (!isEqual(priceSummary[field], value)) {
      setChangedFields((curr) => ({ ...curr, [field]: value }))
      setChanged((prev) => ({ ...prev, [field]: true }))
    }
  }

  const saveHandlers = useMemo(() => {
    if (priceSummary?.isWorkingStep) {
      return {
        saveDiscountRatio: async () => {
          await controller.SetWorkingStepManualDiscount(
            priceSummary?.workingStep.key,
            priceSummary?.workingStep.primaryWorkingStep,
            {
              discountRatio: changedFields?.discountRatio,
            }
          )
        },
        saveDiscountValue: async () => {
          await controller.SetWorkingStepManualDiscount(
            priceSummary?.workingStep.key,
            priceSummary?.workingStep.primaryWorkingStep,
            {
              discountValue: changedFields?.discountValue,
            }
          )
        },
        saveSurchargeRatio: async () => {
          await controller.SetWorkingStepManualSurcharge(
            priceSummary?.workingStep.key,
            priceSummary?.workingStep.primaryWorkingStep,
            {
              surchargeRatio: changedFields?.surchargeRatio,
            }
          )
        },
        saveSurchargeValue: async () => {
          await controller.SetWorkingStepManualSurcharge(
            priceSummary?.workingStep.key,
            priceSummary?.workingStep.primaryWorkingStep,
            {
              surchargeValue: changedFields?.surchargeValue,
            }
          )
        },
      }
    } else {
      return {
        saveDiscountRatio: async () => {
          await articleController.SaveArticleIdDiscountRatio(
            priceSummary?.materialId,
            changedFields?.discountRatio
          )
        },
        saveDiscountValue: async () => {
          await articleController.SaveArticleIdDiscountValue(
            priceSummary?.materialId,
            changedFields?.discountValue
          )
        },
        saveSurchargeRatio: async () => {
          await articleController.SaveArticleIdSurchargeRatio(
            priceSummary?.materialId,
            changedFields?.surchargeRatio
          )
        },
        saveSurchargeValue: async () => {
          await articleController.SaveArticleIdSurchargeValue(
            priceSummary?.materialId,
            changedFields?.surchargeValue
          )
        },
      }
    }
  }, [
    articleController,
    changedFields?.discountRatio,
    changedFields?.discountValue,
    changedFields?.surchargeRatio,
    changedFields?.surchargeValue,
    controller,
    priceSummary?.isWorkingStep,
    priceSummary?.materialId,
    priceSummary?.workingStep?.key,
    priceSummary?.workingStep?.primaryWorkingStep,
  ])

  const handleSaveWorkingStepDiscount = async () => {
    try {
      if (changed['discountRatio']) {
        await saveHandlers.saveDiscountRatio()
        setChanged((prev) => ({ ...prev, discountRatio: false }))
      }

      if (changed['discountValue']) {
        await saveHandlers.saveDiscountValue()
        setChanged((prev) => ({ ...prev, discountValue: false }))
      }
    } catch (err) {
      ShowException('Failed to set discount', err)
    }
  }

  const handleSaveWorkingStepSurcharge = async () => {
    try {
      if (changed['surchargeRatio']) {
        await saveHandlers.saveSurchargeRatio()
        setChanged((prev) => ({ ...prev, surchargeRatio: false }))
      }
      if (changed['surchargeValue']) {
        await saveHandlers.saveSurchargeValue()
        setChanged((prev) => ({ ...prev, surchargeValue: false }))
      }
    } catch (err) {
      ShowException('Failed to set surcharge', err)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await handleSaveWorkingStepSurcharge()
    await handleSaveWorkingStepDiscount()
  }

  const handleReset = () => {
    setChangedFields({
      surchargeRatio: priceSummary?.surchargeRatio,
      surchargeValue: priceSummary?.surchargeValue,
      discountRatio: priceSummary?.discountRatio,
      discountValue: priceSummary?.discountValue,
    })
    setChanged({
      surchargeRatio: false,
      surchargeValue: false,
      discountRatio: false,
      discountValue: false,
    })
  }

  return {
    changed,
    changedFields,
    handleChangeFields,
    handleSubmit,
    handleReset,
  }
}
