import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CostFactor } from '../GetGraphData'
import { PriceLabel } from './PriceLabel'

export type Legend = {
  color: string
  description?: string
  descriptionColor?: string
}

export const CostFactorSection = (props: {
  title?: string
  costFactor: CostFactor
  boldSalesPrice?: boolean
  legend?: Legend
  onClick?: () => void
  onDoubleClick?: () => void
  isActive?: boolean
}) => {
  const { t } = useTranslation()

  if (!props.costFactor) {
    return null
  }

  return (
    <Box
      sx={{
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      {props.title && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 .5em',
            color: (theme) =>
              theme.palette.getContrastText(props.legend?.color),
            backgroundColor: props.legend?.color,
          }}
        >
          <Typography
            variant="caption"
            color="textGray"
            fontWeight={'bold'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            maxWidth={'80%'}
          >
            {props.title}:
          </Typography>
          <Typography variant="caption">{props.legend?.description}</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          padding: '.5em',
          paddingTop: '0.25em',
          paddingLeft: 0,
          paddingBottom: 0,
          gap: '1rem',
          backgroundColor: (theme) =>
            props.isActive ? theme.palette.action.selected : 'inherit',
        }}
      >
        <PriceLabel
          title={t('project:cost-price')}
          moneyDto={props.costFactor.costPrice}
        />
        <PriceLabel
          title={t('project:surcharge')}
          moneyDto={props.costFactor.surchargeValue}
        />
        <PriceLabel
          title={t('project:discount')}
          moneyDto={props.costFactor.discountValue}
        />
        <PriceLabel
          title={t('project:sales-price')}
          moneyDto={props.costFactor.salesPrice}
          moneyStyle={props.boldSalesPrice ? { fontWeight: 'bold' } : {}}
        />
      </Box>
    </Box>
  )
}
