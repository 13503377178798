import useLocalizedMaterialReactTable from 'components/ProjectsPage/useLocalizedMaterialReactTable'
import { BomItemDimensionSummary } from 'features/BillOfMaterials/PartType/PartTypeDimensionSummary'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import { isEmpty } from 'lodash'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_Updater,
} from 'material-react-table'
import { BomItemType } from 'model/Project/BoMItemRow'
import React, { useMemo, useState } from 'react'
import { RowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { HashGroup } from './AssemblySimplificationDialog'

export const HashGroupTable = (props: {
  rowDtos: RowDto[]
  selectedAssemblyId: string
  hashGroupKey: string
  setSelectedRows: React.Dispatch<React.SetStateAction<HashGroup>>
  selectedRows: HashGroup
  isClosing: boolean
}) => {
  const [rowDtosWithTableData, setRowDtosWithTableData] = useState<
    // Array<RowDto & { tableData: { checked: boolean } }>
    Array<RowDto>
  >([])

  const [selection, setSelection] = useState<MRT_RowSelectionState>({})

  React.useEffect(() => {
    if (!props.selectedAssemblyId) {
      setRowDtosWithTableData([])
    } else {
      setRowDtosWithTableData(props.rowDtos)
    }
  }, [props.rowDtos, props.selectedAssemblyId])

  React.useEffect(() => {
    setSelection((curr) => {
      if (isEmpty(props.selectedRows)) {
        return curr
      }

      if (!props.selectedRows[props.selectedAssemblyId]) {
        return curr
      }

      const newSelection = { ...curr }

      for (const row of props.rowDtos) {
        newSelection[row.id] = props.selectedRows[props.selectedAssemblyId][
          props.hashGroupKey
        ]?.partTypeIds?.includes(row.id)
      }

      return newSelection
    })
  }, [
    props.hashGroupKey,
    props.rowDtos,
    props.selectedAssemblyId,
    props.selectedRows,
  ])

  const handleRowSelectionChange = (
    rowSelectionState: MRT_Updater<MRT_RowSelectionState>
  ) => {
    const selectionState =
      rowSelectionState instanceof Function
        ? rowSelectionState(selection)
        : rowSelectionState

    props.setSelectedRows((curr) => {
      const _newSelectedRows = { ...curr }

      _newSelectedRows[props.selectedAssemblyId][
        props.hashGroupKey
      ].partTypeIds = Object.keys(selectionState).filter(
        (x) => selectionState[x] === true
      )

      return _newSelectedRows
    })
  }

  const columns: MRT_ColumnDef<RowDto>[] = useMemo(() => {
    return [
      {
        header: 'row number',
        accessorFn: (row) => row.rowNumber,
        minSize: 1,
        size: 10,
      },
      {
        header: 'thumbnail',
        accessorFn: (row) => row.thumbnail3d,
        Cell: (cellProps) => {
          return (
            <RowThumbnailButton
              bomItemPointer={{
                id: cellProps.row.original.id,
                type: BomItemType.partType,
              }}
              disableClick
              showTooltip={true}
              pictureClassName="assembly-simplification-thumbnail"
            />
          )
        },
        size: '20',
        minSize: 1,
        enableGlobalFilter: false,
        enableColumnFilter: false,
      },
      {
        header: 'name',
        accessorFn: (row) => row.name,
        size: '80',
        minSize: 1,
      },
      {
        header: 'dimensions',
        minSize: 1,
        size: '50',
        accessorFn: (row) => row.dimensionsSummary,
        Cell: (cellProps) => {
          return (
            <BomItemDimensionSummary
              bomItemPointer={{
                id: cellProps.row.original.partTypeId,
                type: BomItemType.partType,
              }}
              rootStyle={{ justifyContent: 'flex-end' }}
            />
          )
        },
      },
    ] as MRT_ColumnDef<RowDto>[]
  }, [])

  const table = useLocalizedMaterialReactTable({
    columns: columns,
    data: rowDtosWithTableData,
    layoutMode: 'grid',
    state: {
      rowSelection: selection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: handleRowSelectionChange,
    initialState: {
      density: 'compact',
    },
    muiTableBodyCellProps: {
      style: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    enableRowSelection: true,
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableTableFooter: false,
    enablePagination: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
  })

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  )
}
