import React from 'react'
import { CancelButton } from '../Button/CancelButton'
import { SaveButton } from '../Button/SaveButton'

type Props = {
  onCloseButtonClicked: () => void
  onSaveButtonClicked?: () => void
  saveButtonFormId?: string
  saveButtonTranslationKey?: string
  buttonsDisabled?: boolean
  saveButtonDisabled?: boolean
  cancelButtonDisabled?: boolean
  cancelButtonInvisible?: boolean
  cancelButtonTranslationKey?: string
  progress?: number
  saving?: boolean
  saveButtonIcon?: React.ReactElement
  hideSaveButton?: boolean
  children?: React.ReactNode
  saveButtonType?: 'button' | 'submit'

}

export const DialogCloseSaveButtons = (props: Props) => {
  return (
    <>
      {props.children}
      {!props.cancelButtonInvisible && (
        <CancelButton
          onClick={props.onCloseButtonClicked}
          labelTranslationKey={props.cancelButtonTranslationKey}
          disabled={
            props.buttonsDisabled ||
            props.cancelButtonDisabled ||
            (props.progress && props.progress > 0) ||
            props.saving
          }
        />
      )}
      {!props.hideSaveButton && (
        <SaveButton
          onClick={props.onSaveButtonClicked}
          formId={props.saveButtonFormId}
          translationKey={props.saveButtonTranslationKey}
          progress={props.progress}
          saving={props.saving}
          disabled={
            props.buttonsDisabled ||
            props.saveButtonDisabled ||
            (props.progress && props.progress > 0) ||
            props.saving
          }
          startIcon={props.saveButtonIcon}
          type={props.saveButtonType ?? 'submit'}
        />
      )}
    </>
  )
}
