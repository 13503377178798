/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui'

export const useFeatureTreeStyles = makeStyles()((theme) => ({
  selectFacesButton: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  activeSection: {
    outline: `2px solid ${theme.palette.info.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  sections: {
    paddingLeft: theme.spacing(1),
  },
  sectionNoPadding: {
    padding: 0,
  },
}))
