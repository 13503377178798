import { useEffect, useRef, useState } from 'react'

/**
 * Hook that returns true if the component is rendered for the first time
 * this will use useRef, meaning the components that uses this hook will not re-render
 * @returns true if the component is rendered for the first time
 */
export const useIsFirstRenderRef = () => {
  const isFirstRender = useRef(true)
  useEffect(() => {
    isFirstRender.current = false
  }, [])
  return isFirstRender.current
}

/**
 * Hook that returns true if the component is rendered for the first time
 * this will use useState, meaning the components that uses this hook will re-render
 * @returns true if the component is rendered for the first time
 */
export const useIsFirstRenderState = () => {
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    setIsFirstRender(false)
  }, [])
  return isFirstRender
}
