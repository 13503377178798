import {
  CloseOutlined,
  DataUsageOutlined,
  PersonOutlined,
} from '@mui/icons-material'
import { Button, Drawer, IconButton } from '@mui/material'
import DashboardSvg from '_icons/ui/dashboard.svg?react'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppController } from 'customHooks/useAppController'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DashBoardDto,
  DashboardType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { MixPanelDashboardsController } from './MixPanelDashboards.controller'

const useStyles = makeStyles()((theme) => ({
  dashboardList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: theme.breakpoints.values.sm,
    '& li': {
      flex: '1 1 50%',
    },
  },
  dashboardButton: {
    display: 'block',
  },
  dashboardIcon: {
    width: theme.spacing(3),
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(2),
  },
  drawer: {
    width: theme.spacing(50),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.contrastText,
  },
  content: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

const DashboardButton = ({
  dashboardDto,
  trackEvent,
}: {
  dashboardDto: DashBoardDto
  trackEvent: (dashboardType) => void
}) => {
  const { t } = useTranslation('common')

  const translated = t(
    `common:${dashboardDto.dashboardType}`,
    dashboardDto.dashboardType
  )

  let startIcon = null

  switch (dashboardDto.dashboardType) {
    case DashboardType.Usage.toLowerCase():
      startIcon = (
        <DataUsageOutlined
          sx={{
            width: (theme) => theme.spacing(4),
            height: (theme) => theme.spacing(4),
          }}
        />
      )
      break
    case DashboardType.CustomerBehaviour.toLowerCase():
      startIcon = (
        <PersonOutlined
          sx={{
            width: (theme) => theme.spacing(4),
            height: (theme) => theme.spacing(4),
          }}
        />
      )
      break
    default:
      break
  }

  return (
    <Button
      component="a"
      href={dashboardDto.dashboardUrl}
      target="_blank"
      rel="noreferrer"
      variant="text"
      startIcon={startIcon}
      fullWidth
      onClick={() => {
        trackEvent(dashboardDto.dashboardType)
      }}
    >
      {translated}
    </Button>
  )
}

export const MixPanelDashboardDrawer = () => {
  const { controller, loading } = useAppController(
    () => new MixPanelDashboardsController()
  )

  const { classes } = useStyles()

  const [dashboards, setDashboards] = useState<DashBoardDto[] | undefined>(
    undefined
  )

  const [open, setOpen] = useState(false)

  useEffect(() => {
    controller.getDashboards().then((res) => {
      setDashboards(res.dashboards)
    })
  }, [controller])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const trackEvent = async (dashboardType: DashboardType) => {
    try {
      await controller.trackDashboardOpenedEvent(dashboardType)
    } catch (err) {
      // just ignore tracking errors
    }
  }

  return (
    <>
      <Button
        className={classes.dashboardButton}
        onClick={() => setOpen((curr) => !curr)}
      >
        <DashboardSvg className={classes.dashboardIcon} />
        <LocalizedTypography
          translationKey={'common:dashboards'}
          variant="caption"
          color="textSecondary"
          component="p"
        />
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        variant="persistent"
        classes={{
          paper: classes.drawer,
        }}
      >
        <IconButton
          onClick={() => setOpen(false)}
          className={classes.closeButton}
          size="small"
        >
          <CloseOutlined />
        </IconButton>
        <LocalizedTypography
          translationKey={'common:dashboards'}
          className={classes.title}
          component="h6"
        />
        <div className={classes.content}>
          <LocalizedTypography
            translationKey="common:dashboardsDescription"
            defaultValue={
              'Access our dashboards for valuable insights into usage patterns. Click on a dashboard to open it in a new tab.'
            }
            variant="body2"
          ></LocalizedTypography>
          <LoadingContainer loading={loading['getDashboards']} noMessage>
            {dashboards?.length ? (
              <ul className={classes.dashboardList}>
                {dashboards.map((dashboard) => (
                  <li key={dashboard.dashboardUrl}>
                    <DashboardButton
                      dashboardDto={dashboard}
                      trackEvent={trackEvent}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <LocalizedTypography
                translationKey="common:noDashboards"
                translationDefaultValue="No dashboards available"
                variant="body2"
                component="p"
                style={{
                  textAlign: 'center',
                }}
              />
            )}
          </LoadingContainer>
        </div>
      </Drawer>
    </>
  )
}
