import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { NumberParts } from './Input/NumberParts'
import React from 'react'

interface NumberFormatCustomProps {
  // inputRef: (instance: typeof NumericFormat<number | string> | null) => void
  onChange: (e) => void
  name: string
  decimalScale?: number
  fixedDecimalScale?: boolean
}

function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
  const { onChange, ...other } = props

  const { i18n } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = (other as any)?.value?.value
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (other as any)?.value?.value
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (other as any)?.value

  return (
    <NumericFormat
      {...other}
      value={value}
      // defaultValue={props.value}
      getInputRef={ref}
      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.source === 'event') {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
            currentTarget: {
              name: props.name,
              value: values.floatValue,
            },
          })
        }
      }}
      thousandSeparator={NumberParts(i18n.language, 'group')}
      decimalSeparator={NumberParts(i18n.language, 'decimal')}
      allowLeadingZeros={false}
      decimalScale={props.decimalScale ?? 2}
      fixedDecimalScale={props.fixedDecimalScale ?? true}
      valueIsNumericString
    />
  )
}

const NumberInputWithRef = React.forwardRef(NumberFormatCustom)

export { NumberInputWithRef as NumberFormatCustom }
