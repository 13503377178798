import { Button, IconButton, Tooltip } from '@mui/material'
import FilterAltOffOutlined from '_icons/filterAltOffOutlined.svg?react'
import { useTranslation } from 'react-i18next'
import { ProjectStateSelectors } from 'store/Project/ProjectSelectors'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { newProjectActions } from '../store/projectReducer'

export const RemoveFiltersIcon = ({
  variant = 'Icon',
  buttonStyle,
  onFiltersRemoved,
  iconClassName,
}: {
  variant?: 'Icon' | 'Button'
  onFiltersRemoved?: () => void
  buttonStyle?: React.CSSProperties
  iconClassName?: string
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const hasActiveFilters = useAppSelector(
    ProjectStateSelectors.HasActiveFilter()
  )

  if (!hasActiveFilters) {
    return null
  }

  function removeAllFilters() {
    dispatch(newProjectActions.removeAllFilters())
    onFiltersRemoved && onFiltersRemoved()
  }

  if (variant === 'Button') {
    return (
      <Button variant="outlined" onClick={removeAllFilters}>
        {t('project:remove-filters')}
      </Button>
    )
  } else {
    return (
      <Tooltip title={t('project:remove-filters')}>
        <IconButton
          size="small"
          onClick={removeAllFilters}
          color="secondary"
          style={{
            // padding: 0,
            minWidth: 0,
            width: '32px',
            height: '32px',
            ...buttonStyle,
          }}
        >
          <FilterAltOffOutlined className={iconClassName} />
        </IconButton>
      </Tooltip>
    )
  }
}
