import { Tab, Tabs, Theme } from '@mui/material'
import CostIcon from '_icons/costs.svg?react'
import TimeIcon from '_icons/time.svg?react'
import WeightIcon from '_icons/weight.svg?react'
import { TabPanel } from 'components/Layout/TabPanelProps'
import { CostPriceGraph } from 'components/ProjectPage/Graphs/ProjectCostPriceGraph'
import { TimeGraph } from 'components/ProjectPage/Graphs/TimeGraph'
import { WeightGraph } from 'components/ProjectPage/Graphs/WeightGraph'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  tabs: {
    padding: theme.spacing(0),
    '& button': {
      padding: 0,
      minHeight: 0,
    },
    '& svg': {
      width: theme.spacing(4),
    },
    marginBottom: theme.spacing(2),
    // display: 'flex',
    // '& svg': {
    //   width: theme.spacing(4),
    // },
    // '& li': {
    //   flex: '1 1 auto',
    // },
    // '& button': {
    //   padding: 0,
    // }
  },
  graphContainer: {
    width: '100%',
    color: theme.palette.text.primary,
    '& text': {
      fill: theme.palette.text.primary,
    },
    '& .is-active': {
      backgroundColor: `${theme.palette.info.light} !important`,
    },
    '& .custom-tooltip': {
      backgroundColor: theme.palette.grey['200'],
      color: theme.palette.getContrastText(theme.palette.grey['200']),
    },
    '& .barInfo': {
      fill: theme.palette.info.main,
    },
    '& .barSuccess': {
      fill: theme.palette.success.main,
    },
    '& .barError': {
      fill: theme.palette.error.main,
    },
  },
}))
export const ProjectGraphsContainer = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const priceSummaries = useAppSelector(
    (state: RootAppState) => state.project.priceSummaries
  )

  const projectCostPrice = useAppSelector(
    projectSelectors.projectCostPriceSelector
  )

  const [viewIndex, setViewIndex] = useState(0)

  if (
    !Object.keys(priceSummaries || {}).length ||
    !Boolean(projectCostPrice?.value)
  ) {
    return (
      <div className="no-items">
        {t(
          'project:financial-data-not-available',
          'financial data not available'
        )}
      </div>
    )
  }

  return (
    <div className={`${classes.graphContainer}`}>
      <Tabs
        value={viewIndex}
        onChange={(_, value) => {
          setViewIndex(value)
        }}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab label={<CostIcon />} />
        <Tab label={<TimeIcon />} />
        <Tab label={<WeightIcon />} />
      </Tabs>
      <TabPanel value={viewIndex} index={0}>
        <CostPriceGraph />
      </TabPanel>
      <TabPanel value={viewIndex} index={1}>
        <TimeGraph />
      </TabPanel>
      <TabPanel value={viewIndex} index={2}>
        <WeightGraph />
      </TabPanel>
    </div>
  )
}
