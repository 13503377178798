import WorkingStepResourceTooltip from 'features/BillOfMaterials/WorkingSteps/WorkingStepResourceTooltip'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  BoMItemActivityDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

type Props = {
  bomItemPointer: BomItemPointer
  activity: BoMItemActivityDto
}

const useStyles = makeStyles({ name: 'AcitivityWithArticle' })(() => ({
  root: {
    width: '100%',
  },
}))

export function ActivityWithoutArticle(props: Props) {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const selectWorkingStep = () => {
    dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))
  }

  if (
    !props.activity?.primaryWorkingStep ||
    props.activity.primaryWorkingStep === WorkingStepType.NotInitialized
  ) {
    return null
  }

  return (
    <>
      <div className={classes.root} onClick={selectWorkingStep}>
        <WorkingStepResourceTooltip
          bomItemPointer={props.bomItemPointer}
          bomItemActivity={props.activity}
        ></WorkingStepResourceTooltip>
      </div>
    </>
  )
}
