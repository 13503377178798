import { IconButton } from '@mui/material'
import { withStyles } from 'tss-react/mui';

export const ActionIconButton = withStyles(IconButton, () => ({
  root: {
    ['& span']: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));
