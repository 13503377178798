import {
  Button,
  ButtonProps,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MouseEventHandler, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { saveBomItemIsPurchasingPart } from '../store/asyncActions/saveBomItemIsPurchasingPart'
import { newProjectActions } from '../store/projectReducer'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemIsPurchasingPartSelector } from '../store/selectors/bomItemIsPurchasingPartSelector'
import { projectInBuyerViewSelector } from '../store/selectors/projectInBuyerViewSelector'
import { projectOperationSelector } from '../store/selectors/projectOperationPendingSelector'

const useStyles = makeStyles({ name: 'purhcasing-item-button' })((theme) => ({
  tooltip: {
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
}))

export const PurchasingItemSwitcher = ({
  bomItemPointer,
  dialog,
}: {
  bomItemPointer: BomItemPointer
  useIconAtStart?: boolean
  buttonProps?: ButtonProps
  dialog?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { classes } = useStyles()

  const bomItemIsPurchasingPart = useAppSelector(
    bomItemIsPurchasingPartSelector(bomItemPointer)
  )

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)
  const isBuyerView = useAppSelector(projectInBuyerViewSelector)

  const { openDialog } = useDialogManager()

  const operationStatus = useAppSelector(
    projectOperationSelector(
      saveBomItemIsPurchasingPart.typePrefix.concat('/', bomItemPointer.id)
    ),
    isEqual
  )

  const clickTimeout = useRef<NodeJS.Timeout>(undefined)
  const clickCounter = useRef<number>(0)

  if (isBuyerView) {
    return null
  }

  const handleOpenDialog = () => {
    if (projectIsEditable && dialog) {
      openDialog('PurchasingPartDialog', {
        bomItemPointer: bomItemPointer,
        isOpen: true,
      } as PurchasingPartDialogProps)
      dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))
    }
  }

  const handleClick: MouseEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(newProjectActions.setBomItemInSearch(undefined))

    clickCounter.current++

    if (clickCounter.current === 1) {
      clickTimeout.current = setTimeout(() => {
        if (projectIsEditable) {
          dispatch(saveBomItemIsPurchasingPart({ bomItemPointer }))
        }
        clickCounter.current = 0
      }, 250)
    } else {
      clearTimeout(clickTimeout.current)
      clickCounter.current = 0
    }
  }

  const handleDoubleClick = (e) => {
    e.stopPropagation()
    handleOpenDialog()
  }

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      title={
        dialog && projectIsEditable ? (
          <Button
            onClick={handleOpenDialog}
            size="small"
            color="info"
            variant="contained"
          >
            {t('project:purchasing-part-open-details', 'details')}
          </Button>
        ) : (
          ''
        )
      }
    >
      <FormControlLabel
        disabled={
          !projectIsEditable || operationStatus?.requestStatus === 'pending'
        }
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        control={
          <Checkbox
            checked={bomItemIsPurchasingPart}
            size="small"
            color="primary"
          />
        }
        label={
          <Typography variant="caption" color="GrayText">
            {t('project:purchasing-part', 'purchasing')}
          </Typography>
        }
      />
    </Tooltip>

    // <Tooltip
    //   classes={{
    //     tooltip: classes.tooltip,
    //   }}
    //   title={
    //     bomItemIsPurchasingPart ? (
    //       <Paper>
    //         <Button
    //           onClick={() =>
    //             dispatch(saveBomItemIsPurchasingPart({ bomItemPointer }))
    //           }
    //           size="small"
    //           variant="contained"
    //           color="info"
    //         >
    //           {t('project:purchasing-part-unset', 'unset as purchasing part')}
    //         </Button>
    //       </Paper>
    //     ) : (
    //       ''
    //     )
    //   }
    //   arrow
    // >
    //   <span>
    //     <Button
    //       {...buttonProps}
    //       size={buttonProps?.size || 'small'}
    //       variant="text"
    //       disabled={!projectIsEditable}
    //       color={bomItemIsPurchasingPart ? 'primary' : 'inherit'}
    //       onClick={handleClick}
    //       endIcon={!useIconAtStart && <ButtonIcon />}
    //       startIcon={useIconAtStart && <ButtonIcon />}
    //       style={{
    //         minWidth: 0,
    //       }}
    //     >
    //       <>
    //         {bomItemIsPurchasingPart ? (
    //           <LocalizedTypography
    //             color={bomItemIsPurchasingPart ? 'primary' : 'textSecondary'}
    //             variant="caption"
    //             translationKey="common:purchasing-part"
    //           >
    //             purchasing
    //           </LocalizedTypography>
    //         ) : null}
    //       </>
    //     </Button>
    //   </span>
    // </Tooltip>
  )
}
