import { useTheme } from '@mui/material'
import React from 'react'
import { Text } from 'recharts'
import { getPriceSummaryKey } from 'store/Project/ProjectStateUtils'
import { PriceSummaryDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { WorkingStepIconSvg } from '../../Common/WorkingSteps/WorkingStepIcon'

export const CostFactorLabel = (props: {
  x: number
  y: number
  payload
  priceSummaries: PriceSummaryDto[]
  selectedData: PriceSummaryDto
}) => {
  const theme = useTheme()

  if (!props.priceSummaries) return null

  const costFactor = Object.values(props.priceSummaries).find(
    (x) => x.description === props.payload.value
  )

  const isSelected =
    props.selectedData &&
    costFactor &&
    getPriceSummaryKey(costFactor) === getPriceSummaryKey(props.selectedData)

  if (costFactor?.isWorkingStep) {
    return (
      <>
        {/* <g style={{ transform: 'translate(-28%, -24px)', cursor: 'pointer' }}> */}
        <g>
          <Text
            {...props}
            fill={
              isSelected
                ? theme.palette.action.active
                : theme.palette.text.primary
            }
            fontSize={theme.typography.body2.fontSize}
            width={170}
            lineHeight="1.25em"
            style={{
              transform: 'translateX(-8px)',
              cursor: 'pointer',
              marginRight: '42px',
            }}
          >
            {props.payload.value}
          </Text>
        </g>
        <g style={{ transform: 'translateY(-16px)', cursor: 'pointer' }}>
          <WorkingStepIconSvg
            workingStepType={costFactor.workingStep.primaryWorkingStep}
            attributes={{
              x: props.x,
              y: props.y,
              width: 32,
              height: 32,
            }}
          />
        </g>
      </>
    )
  } else {
    return (
      <g>
        <Text
          {...props}
          fill={
            isSelected
              ? theme.palette.action.active
              : theme.palette.text.primary
          }
          fontSize={theme.typography.body2.fontSize}
          width={200}
          lineHeight="1.25em"
          style={{ transform: 'translateX(-8px)', cursor: 'pointer' }}
        >
          {props.payload.value}
        </Text>
      </g>
    )
  }
}
