import { ElementType, PropsWithChildren } from 'react'
import {
  AppModule,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureKeys } from './FeatureKeys'
import { useAppFeature } from './useAppFeature'

type AppFeature = {
  description: string
  isActive: boolean
  modules: AppModule[]
}

export type AppModuleFeatures = Record<FeatureKeys, AppFeature>
export type AppModuleWorkingSteps = Record<
  AppModule,
  Record<WorkingStepType, boolean>
>

export const AppFeature = (
  props: PropsWithChildren<{
    featureKey: FeatureKeys
    fallbackComponent?: ElementType
  }>
) => {
  const featureEnabled = useAppFeature(props.featureKey)

  if (featureEnabled) {
    return props.children
  }

  if (props.fallbackComponent) {
    return <props.fallbackComponent />
  }

  return null
}
