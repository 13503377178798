import { SxProps } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { FeaturesDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTree } from './FeatureTree'

export const BomItemFeaturesDetailsContainer = (props: {
  bomItemPointer: BomItemPointer
  orientation: 'horizontal' | 'vertical'
  features: FeaturesDto
  rootSxProps?: SxProps
  onNoFeatures?: () => void
  modelController?: ModelController
  loading: boolean
  onReload: () => void
  featureLabelDefaultExpandeded?: string
}) => {
  if (!props.features?.features?.length) {
    return null
  }

  return (
    <LoadingContainer loading={props.loading} noMessage>
      <FeatureTree
        features={props.features?.features}
        modelController={props.modelController}
        onReload={props.onReload}
        featureLabelDefaultExpanded={props.featureLabelDefaultExpandeded}
      />
    </LoadingContainer>
  )
}
