import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'

export const usePartTypeIdFilter = () => {
  const dispatch = useAppDispatch()

  const partTypeIdFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byPartTypeId,
    _.isEqual
  )

  const handleChangeFilter = useCallback(
    (partTypeIds: string[]) => {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'PartTypeIdFilter',
            enabledFilters: partTypeIds,
          },
        })
      )
    },
    [dispatch]
  )

  const handleDisableFilter = useCallback(() => {
    dispatch(
      newProjectActions.toggleActiveFilter({
        filterType: 'PartTypeIdFilter',
      })
    )
  }, [dispatch])

  const filterIsActive = useMemo(() => {
    return (
      partTypeIdFilter.active &&
      partTypeIdFilter.filter.enabledFilters?.length > 0
    )
  }, [partTypeIdFilter.active, partTypeIdFilter.filter.enabledFilters?.length])

  return {
    handleChangeFilter,
    partTypeIdFilter,
    filterIsActive,
    handleDisableFilter,
  }
}
