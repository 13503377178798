import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { BomItemDetailsV2DialogProps } from 'features/BillOfMaterials/BomItemDetailsV2/BomItemDetailsV2_Dialog'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch } from 'store/configureStore'
import { UIActions } from 'store/UI/UIActions'

export const UpperSideSelectionTodo = (props: {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}) => {
  const { getIssueDescription } = useBomItemIssueDescription()

  const dispatch = useAppDispatch()
  const setDetailsOpen = (e) => {
    e.stopPropagation()

    dispatch(
      UIActions.OpenModal('BomItemDetailsV2', {
        bomItemPointer: props.bomItemPointer,
        open: true,
        featureLabelDefaultExpanded: 'sides & walls',
      } as BomItemDetailsV2DialogProps)
    )
  }

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={setDetailsOpen}
      color="inherit"
      startIcon={<ChecklistOutlined />}
    >
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </Button>
  )

  // return (
  //   <LocalizedButton
  //     defaultText="select upper side using the 3d viewer"
  //     translationKey="project:select-upper-side"
  //     variant="text"
  //     onClick={setDetailsOpen}
  //   />
  // )
}
