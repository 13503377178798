import { Button, ButtonGroup, Typography } from '@mui/material'
import { SearchType } from 'controllers/Search/SearchBomItemsController'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FacetExplanation } from './Explanations/FacetExplanation'
import { FilterExplanation } from './Explanations/FilterExplanation'
import { FreeTextExplanation } from './Explanations/FreeTextExplanation'

export const NoOptions = (props: {
  searchType: SearchType
  setSearchType: (searchType: SearchType) => void
  facets: Array<{ value: string; count: number }>
  search: (value: string, searchType: SearchType) => void
  searchText: string
  noResults: boolean
}) => {
  const { t } = useTranslation('search')

  // eslint-disable-next-line react/display-name
  const Explanation = React.memo(() => {
    switch (props.searchType) {
      case 'freeText':
        return <FreeTextExplanation />
      case 'filter':
        return <FilterExplanation search={props.search} />
      case 'groups':
        return <FacetExplanation {...props} />
    }
  })

  return (
    <span className="search-bom-items--no-option">
      {props.noResults && (
        <Typography color="secondary" className="no-search-result">
          {t('search:no-record-found-for-query', {
            defaultValue:
              'no records found for query {{searchType}}: {{searchText}}',
            replace: {
              searchType: props.searchType,
              searchText: props.searchText,
            },
          })}
        </Typography>
      )}
      <ButtonGroup variant="text" className="search-types">
        <Button
          color={props.searchType === 'freeText' ? 'primary' : 'inherit'}
          variant={props.searchType === 'freeText' ? 'outlined' : 'text'}
          onClick={() => props.setSearchType('freeText')}
        >
          {t('search:free-text-search', 'free text search')}
        </Button>
        <Button
          color={props.searchType === 'filter' ? 'primary' : 'inherit'}
          variant={props.searchType === 'filter' ? 'outlined' : 'text'}
          onClick={() => props.setSearchType('filter')}
        >
          {t('search:filter', 'filter')}
        </Button>
        <Button
          color={props.searchType === 'groups' ? 'primary' : 'inherit'}
          variant={props.searchType === 'groups' ? 'outlined' : 'text'}
          onClick={() => props.setSearchType('groups')}
        >
          {t('search:group-by', 'group by')}
        </Button>
      </ButtonGroup>
      <Explanation />
    </span>
  )
}
