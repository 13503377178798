import { useTheme } from '@mui/material'
import appLogo from '_icons/logo/QF_logoHrz_tag.png'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import _ from 'lodash'
import { ImgHTMLAttributes } from 'react'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  imgLogo: {
    display: 'block',
  },
}))

export const AppLogo = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()

  const logoSrc = useAppSelector((state: RootAppState) =>
    _.isEmpty(state.app.theme?.logoSrc) ? appLogo : state.app.theme?.logoSrc
  )

  const { withinIframe } = useAppRunningContext()

  if (withinIframe && !theme?.logoSrc) {
    return null
  }

  return (
    <>
      <img
        src={logoSrc}
        alt="logo"
        {...props}
        className={cx(classes.imgLogo, props.className)}
      />
    </>
  )
}
