import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material'
import i18next from 'i18next'
import React, { PropsWithChildren } from 'react'
import store from 'store/configureStore'
import { createAppTheme } from 'utils/appTheme'
import { TelemetryService } from '../../services/Telemetry'
import './ErrorBoundary.scss'

type State = {
  error: Error | null
  errorInfo: React.ErrorInfo | null
  hasError: boolean
  expanded: boolean
}

export class ErrorBoundary extends React.Component<
  PropsWithChildren<unknown>,
  State
> {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
      expanded: true,
    }
  }

  static getDerivedStateFromError = (error: Error) => {
    return { hasError: true, error: error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo, expanded: true })

    TelemetryService.getInstance().logError(error)
  }

  theme: Theme
  componentDidMount() {
    this.theme = store.getState().app.theme
  }

  setExpanded = (expanded: boolean) => {
    this.setState(() => ({ expanded }))
  }

  render() {
    if (this.state.error) {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createAppTheme(this.theme, i18next.language)}>
            <div
              className="error-boundary"
              style={{
                backgroundColor: this.theme?.palette.background.default,
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1em',
                }}
              >
                <div>
                  <Typography
                    variant="h5"
                    color="error"
                    className="error-header"
                  >
                    Something went wrong
                  </Typography>
                  {window.parent === window.self ? (
                    <Typography
                      color="primary"
                      gutterBottom
                      component="a"
                      style={{ display: 'block' }}
                      href={`mailto:support@quotationfactory.com?subject=[webapp crash]: ${encodeURIComponent(
                        this.state.error?.toString()
                      )}&body=Error found in the WebApp at \n\n${encodeURIComponent(
                        new Date().toUTCString()
                      )}. \n\n${encodeURIComponent(
                        this.state.error?.toString()
                      )}\n\n${encodeURIComponent(
                        this.state.errorInfo?.componentStack || ''
                      )}`}
                    >
                      Please send an e-mail to our support team if you need
                      further assistance.
                    </Typography>
                  ) : null}
                </div>

                <div>
                  <Button
                    onClick={() =>
                      window.location.replace(window.location.toString())
                    }
                    variant={'contained'}
                    color="primary"
                    type="submit"
                  >
                    reload page
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.replace('/app')
                    }}
                    variant={'contained'}
                    color="primary"
                  >
                    initial page
                  </Button>
                </div>
                <Accordion
                  expanded={this.state.expanded}
                  onChange={(e, expanded) => this.setExpanded(expanded)}
                  className="error-panel"
                >
                  <AccordionSummary>
                    <div>
                      <Typography variant="body2" component="p">
                        details: {this.state.error?.toString()}
                      </Typography>
                      <Typography variant="caption">
                        {`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="p" className="error-details-title">
                      {this.state.error?.toString()}
                    </Typography>
                    <Typography variant="body2" className="error-details-stack">
                      {this.state.errorInfo?.componentStack}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Container>
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      )
    }

    return this.props.children
  }
}
