import React from 'react'
import './FiltersContainer.scss'
import { IssuesFilterContainer } from './IssuesFilter/IssuesFilterContainer'
import { MaterialKeywordFilter } from './MaterialKeywordsFilter/MaterialKeywordFilter'

export const FiltersContainer = (props: {
  openTab?: number
  hideMaterialAndWorkingSteps?: boolean
  hideIssues?: boolean
}) => {
  return (
    <div className="filters-container">
      {/* <BoMItemFilterCount
        workingStepFilterEnabled={workingStepsFilterEnabled}
        materialFilterEnabled={materialFilterEnabled}
        issuesFilterEnabled={issueFilterEnabled}
      /> */}
      {props.hideIssues ? <MaterialKeywordFilter /> : <IssuesFilterContainer />}
    </div>
  )
}

export default React.memo(FiltersContainer)
