import { Typography } from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { Fragment, useRef } from 'react'
import { FeatureSectionDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeItem } from './FeatureTreeItem'
import { useFeatureTreeStyles } from './useFeatureTreeStyles'
import { useModelSelectedFacesChanged } from './useModelSelectedFacesChanged'

export const FeatureTreeSection = (props: {
  featureSection: FeatureSectionDto
  modelController: ModelController
  onOpen: () => void
  isOpen?: boolean
  faceIdToFilter?: number
  variant?: 'small'
  onReload: () => void
}) => {
  const { classes, cx } = useFeatureTreeStyles()
  const divRef = useRef<HTMLDivElement>(null)

  const { hasSelectedFace } = useModelSelectedFacesChanged(
    props.featureSection.faceIds,
    props.modelController,
    props.onOpen,
    divRef,
    props.isOpen,
    true
  )

  return (
    <div
      ref={divRef}
      className={cx(classes.sections, {
        [classes.sectionNoPadding]: props.variant === 'small',
        [classes.activeSection]: hasSelectedFace,
      })}
    >
      <Typography
        component={props.featureSection.faceIds?.length > 0 ? 'button' : 'span'}
        className={cx({
          [classes.selectFacesButton]: props.featureSection.faceIds?.length > 0,
        })}
        onClick={() => {
          if (!props.featureSection.faceIds?.length) return
          props.modelController?.selectNodesById(
            props.featureSection.faceIds.map((x) => x.toString()),
            'FACE'
          )
        }}
        variant="caption"
        style={{ fontWeight: 'bold' }}
      >
        {props.featureSection.label}
      </Typography>
      <span>
        {props.featureSection.details.map((featureDetail, index) => {
          const notIncludedFaceIds = props.featureSection.details
            .flatMap((x) => x.faceIds)
            .filter((x) => !featureDetail.faceIds.includes(x))

          return (
            <Fragment key={index}>
              <FeatureTreeItem
                key={`${featureDetail.label}-${index}`}
                featureDetail={featureDetail}
                modelController={props.modelController}
                onOpen={props.onOpen}
                faceIdToFilter={props.faceIdToFilter}
                variant={props.variant}
                notIncludedFaceIds={notIncludedFaceIds}
                onReload={props.onReload}
              />
            </Fragment>
          )
        })}
      </span>
    </div>
  )
}
