import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
import { projectNetSalesPriceSelector } from 'features/BillOfMaterials/store/selectors/projectNetSalesPriceSelector'
import { useTranslation } from 'react-i18next'

export function ProjectNetSalesPrice(props: {
  showEmptyValue?: boolean
  valueLabelProps?: Partial<ValueLabelProps>
}) {
  const { t } = useTranslation()

  return (
    <ValueLabelConnected
      propertySelector={projectNetSalesPriceSelector}
      valueLabelProps={{
        label: t('project:net-sales-price'),
        ...(props.valueLabelProps || {}),
      }}
      showEmptyValue={props.showEmptyValue}
    />
  )
}
