import { Theme } from '@mui/material'
import { LocalizedWorkingStepLabel } from 'components/Localization/LocalizedWorkingStepLabel'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { WorkingStepIconSvg } from './WorkingStepIcon'

const useStyles = makeStyles()((theme: Theme) => ({
  workingStepWithDescription: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    alignItems: 'center',
    width: theme.spacing(15),
    '& svg': {
      width: theme.spacing(4),
    },
  },
}))

export const WorkingStepIconWithDescription = (props: {
  workingStepType: WorkingStepType
  className?: string
}) => {
  const { classes } = useStyles()

  return (
    <div className={props.className || classes.workingStepWithDescription}>
      <WorkingStepIconSvg
        workingStepType={props.workingStepType}
        disableTitle
      />
      <LocalizedWorkingStepLabel
        workingStepType={props.workingStepType}
        variant="body2"
        color="textSecondary"
      />
    </div>
  )
}
