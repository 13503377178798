import { useAppController } from 'customHooks/useAppController'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { throttle } from 'lodash'
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApiMetadata,
  ProjectOperationDto,
  ProjectOperationStatus,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { ModalTypeProps } from 'store/UI/UITypes'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { ProjectOperationsController } from './GeometryLogs.controller'

export type GeometryOperationsStats = Record<
  ProjectOperationStatus,
  { count: number }
> & {
  _meta?: ApiMetadata
}

// let messageCount = 0

const asyncGetGeometryOperationsLogs = async (
  controller: ProjectOperationsController,
  page?: number,
  pageSize?: number
) => {
  const result = await controller.GetGeometryOperations(page || 0, pageSize)

  return result
}

const getOperationsAndSetStats = async (
  controller: ProjectOperationsController,
  setOperationsLog: React.Dispatch<SetStateAction<ProjectOperationDto[]>>,
  setStats: React.Dispatch<SetStateAction<GeometryOperationsStats>>,
  setFilteredOperationsLogs: React.Dispatch<
    SetStateAction<ProjectOperationDto[]>
  >,
  operationFilter?: (operation: ProjectOperationDto) => boolean,
  page?: number,
  pageSize?: number
) => {
  try {
    const operationsLog = await asyncGetGeometryOperationsLogs(
      controller,
      page,
      pageSize
    )

    setTimeout(() => {
      if (!operationsLog) {
        return
      }

      setOperationsLog(operationsLog?.records)
      setStats({
        [ProjectOperationStatus.Queued]: { count: operationsLog?.totalQueued },
        [ProjectOperationStatus.Started]: {
          count: operationsLog?.totalStarted,
        },
        [ProjectOperationStatus.Finished]: {
          count: operationsLog?.totalFinished,
        },
        [ProjectOperationStatus.Failed]: { count: operationsLog?.totalFailed },
        _meta: operationsLog?._meta,
      })

      const filtered = operationFilter
        ? operationsLog?.records?.filter(operationFilter)
        : operationsLog?.records

      setFilteredOperationsLogs(filtered)
    })
  } catch (err) {
    setOperationsLog([])
    if (err && err['message'] !== 'user cancelled') {
      ShowException('project operations', err)
    }
  }
}

const throtledGetOperationsAndSetStats = throttle(
  getOperationsAndSetStats,
  2000,
  { leading: true, trailing: true }
)

export function useOperationsLogs(pageSize: number) {
  const currentPageSize = useRef(pageSize)

  const [operationsLog, setOperationsLog] =
    useState<ProjectOperationDto[]>(undefined)

  const [filteredOperationsLogs, setFilteredOperationsLogs] =
    useState<ProjectOperationDto[]>(undefined)

  const operationFilter = useRef(undefined)
  const operationPropertyFilter = useRef([])

  const [operationsStats, setOperationStats] =
    useState<GeometryOperationsStats>(undefined)

  const { controller } = useAppController(
    () => new ProjectOperationsController()
  )

  const shallUpdateLogs = useAppSelector(
    projectSelectors.shallUpdateLogsSelector('geometry')
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (shallUpdateLogs) {
      dispatch(
        newProjectActions.updateOperationsFlags({
          shallUpdateOperationsLogs: false,
        })
      )

      throtledGetOperationsAndSetStats(
        controller,
        setOperationsLog,
        setOperationStats,
        setFilteredOperationsLogs,
        operationFilter.current,
        controller.currentPage,
        currentPageSize.current
      )

      // return getOperationsAndSetStates(
      //   controller,
      //   setOperationsLog,
      //   setOperationStats,
      //   setFilteredOperationsLogs,
      //   operationFilter.current
      // )
      // updateQueue.addTask(() => {
      //   // console.log('------- [OPERATIONS]: update started ------')

      //   // dispatch(
      //   //   newProjectActions.updateOperationsFlags({
      //   //     shallUpdateOperationsLogs: false,
      //   //   })
      //   // )
      //   return getOperationsAndSetStats(
      //     controller,
      //     setOperationsLog,
      //     setOperationStats,
      //     setFilteredOperationsLogs,
      //     operationFilter.current,
      //     controller.currentPage,
      //     currentPageSize.current
      //   )
      // })
    }

    return () => {
      throtledGetOperationsAndSetStats.cancel()
      controller.CancelRequests()
    }
  }, [
    controller,
    dispatch,
    operationsStats?._meta?.page,
    pageSize,
    shallUpdateLogs,
  ])

  useEffect(() => {
    return () => {
      dispatch(
        newProjectActions.updateOperationsFlags({
          shallUpdateOperationsLogs: true,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  const { openDialog } = useDialogManager()

  const handleDeleteAllLogs = async (props?: ModalTypeProps) => {
    openDialog('ConfirmationDialog', {
      title: t('project:delete-all-operation-logs'),
      onConfirm: async () => {
        try {
          await controller.DeleteAllProjectOperationLogs()
          throtledGetOperationsAndSetStats(
            controller,
            setOperationsLog,
            setOperationStats,
            setFilteredOperationsLogs,
            operationFilter.current
          )
        } catch (err) {
          ShowException('delete all logs', err)
        }
      },
      onCancel: () => {
        openDialog('ProjectOperations', { ...props })
      },
    })
  }

  const handleChangeFilter = useCallback(
    (
      property: keyof ProjectOperationDto,
      removeValues: ProjectOperationDto[typeof property]
    ) => {
      if (operationPropertyFilter.current.includes(property)) {
        operationPropertyFilter.current =
          operationPropertyFilter.current.filter((x) => x !== property)
        operationFilter.current = undefined
        setFilteredOperationsLogs(operationsLog)
        return
      }

      operationPropertyFilter.current = [
        ...operationPropertyFilter.current,
        property,
      ]

      const filterFn = (operation: ProjectOperationDto) =>
        operation[property] === removeValues

      operationFilter.current = filterFn
      setFilteredOperationsLogs(operationsLog.filter(filterFn))
    },
    [operationsLog]
  )

  const getPage = useCallback(
    async (pageNumber: number) => {
      controller.setLoading('geometryPageChange', true)

      const resp = await getOperationsAndSetStats(
        controller,
        setOperationsLog,
        setOperationStats,
        setFilteredOperationsLogs,
        operationFilter.current,
        pageNumber,
        currentPageSize.current
      )

      controller.setLoading('geometryPageChange', false)

      return resp
    },
    [controller]
  )

  const getOperations = useCallback(() => {
    return getOperationsAndSetStats(
      controller,
      setOperationsLog,
      setOperationStats,
      setFilteredOperationsLogs,
      operationFilter.current,
      0,
      currentPageSize.current
    )
  }, [controller])

  const handlePageChanged = useCallback(
    (page: number) => {
      getPage(page)
    },
    [getPage]
  )

  return {
    operationsLog,
    filteredOperationsLog: filteredOperationsLogs,
    getOperations,
    handleDeleteAllLogs,
    operationsStats,
    handleChangeFilter,
    hasMoreItems: controller.hasMoreItems,
    getPage,
    currentPage: controller.currentPage,
    pageSize: currentPageSize.current,
    handleChangePageSize: (pageSize: number) => {
      currentPageSize.current = pageSize
      getOperationsAndSetStats(
        controller,
        setOperationsLog,
        setOperationStats,
        setFilteredOperationsLogs,
        operationFilter.current,
        0,
        currentPageSize.current
      )
    },
    handlePageChanged,
    loading: controller?.loading,
  }
}
