import { MoneyString } from 'model/Money'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { ValueLabelConnected } from '../store/components/ValueLabelConnected'
import { bomItemFinancialSelector } from '../store/selectors/bomItemFinancialSelector'
import { projectSelectors } from '../store/selectors/projectSelectors'

export const BomItemTotalPrice = ({
  bomItemPointer,
}: {
  bomItemPointer: BomItemPointer
}) => {
  const { t } = useTranslation()
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  return (
    <ValueLabelConnected
      propertySelector={(state) =>
        MoneyString(
          bomItemFinancialSelector(
            bomItemPointer,
            'salesPriceOfItems'
          )(state) as MoneyDto
        ) || '-'
      }
      showEmptyValue={isBuyerView ? false : true}
      valueLabelProps={{
        label: t('project:total-price'),
      }}
      hiddenFallbackComponent={'div'}
    />
  )
}
