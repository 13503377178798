import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { markText } from 'utils/textFilterUtils'
import { PersonInfo, formatName } from '../../../utils/nameFormatter'

type PersonInfoOptions = {
  disableEmail?: boolean
  disableTitle?: boolean
  nameTypographyProps?: TypographyProps
  emailTypographyProps?: TypographyProps
}

type Props = {
  personInfo: PersonInfo
  TitleComponent?: React.ReactElement
  TooltipProps?: Partial<TooltipProps>
  onClick?: () => void
  searchTerm?: string
} & PersonInfoOptions

export function ContactInfoLabel(props: Props) {
  if (!props.personInfo) {
    return ''
  }

  // const Component = props.onClick ? Button : 'div'

  return (
    <Tooltip
      {...(props.TooltipProps || {})}
      title={
        props.disableTitle
          ? ''
          : props.TitleComponent ?? (
              <Typography variant="caption">
                {`${formatName(props.personInfo)} - ${props.personInfo.email}`}
              </Typography>
            )
      }
    >
      <div
        onClick={props.onClick}
        style={{
          display: 'block',
          textAlign: 'left',
          color: 'inherit',
        }}
      >
        <Typography
          variant="body2"
          component="span"
          {...(props.nameTypographyProps || {})}
        >
          {markText(formatName(props.personInfo), props.searchTerm)}
        </Typography>
        {props.personInfo.email && !props.disableEmail && (
          <Typography
            variant="caption"
            color="textSecondary"
            component="span"
            {...(props.emailTypographyProps || {})}
          >
            {markText(props.personInfo.email, props.searchTerm)}
          </Typography>
        )}
      </div>
    </Tooltip>
  )
}
