import _ from 'lodash'
import { useAppSelector } from 'store/configureStore'
import { RootAppState } from 'store/configureStore'
import { ProjectStateSelectors } from 'store/Project/ProjectSelectors'
import { issueFilterActive } from '../Filter/IssuesFilter/issueFilterActive'

export function useEnabledBoMFilters() {
  const workingStepsFilterEnabled = useAppSelector(
    ProjectStateSelectors.WorkingStepsFilter,
    _.isEqual
  ).active
  const materialFilterEnabled = useAppSelector(
    ProjectStateSelectors.MaterialFilterSelector,
    _.isEqual
  ).active

  const issueFilterEnabled = useAppSelector(
    (state: RootAppState) =>
      issueFilterActive(ProjectStateSelectors.IssuesFilterSelector(state)),
    _.isEqual
  )

  const issuesFilterCount = useAppSelector(
    (state: RootAppState) =>
      state.project.filters.byIssues?.filter.availableFilters?.length || 0
  )

  return {
    workingStepsFilterEnabled,
    materialFilterEnabled,
    issueFilterEnabled,
    issuesFilterCount,
  }
}
