import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bomItemExternalUrlSelector } from 'features/BillOfMaterials/store/selectors/bomItemExternalUrlSelector'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const OpenOnErpMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const externalUrl = useAppSelector(bomItemExternalUrlSelector(bomItemPointer))

  // if (!externalUrl) {
  //   return null
  // }

  return (
    <span>
      <BomItemMenuItem
        forceDisabled={!externalUrl}
        onClick={() => window.open(externalUrl)}
        primaryTextTranslationKey="project:open-on-erp"
        secondary={<>{externalUrl}</>}
        icon={
          <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} size="lg" />
        }
        closeOnClick
        onClose={onClose}
        hiddenForBuyers
      />
    </span>
  )
}
