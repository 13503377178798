import * as React from "react";
const SvgWeight = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 100 100", style: {
  enableBackground: "new 0 0 100 100"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "transparent"
}, d: "M69.7,38.8l12,39.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0l-31.6,0l-31.6,0c0,0,0,0-0.1,0 c0-0.1,0-0.2,0-0.3l11.9-39.2L69.7,38.8z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M43.4,60.7l5.4-5.6c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-3c-0.1,0-0.2,0-0.3,0.1 c-0.8,0.9-1.7,2-2.7,3l-0.7,0.8c-1,1.1-1.8,2-2.3,2.6v-6.1c0-0.2-0.2-0.4-0.4-0.4h-2.5c-0.2,0-0.4,0.2-0.4,0.4v14.2 c0,0.2,0.2,0.4,0.4,0.4h2.5c0.2,0,0.4-0.2,0.4-0.4v-4.8l1.5-1.4l4.4,6.4c0.1,0.1,0.2,0.2,0.3,0.2h3.1c0.1,0,0.3-0.1,0.3-0.2 c0.1-0.1,0.1-0.3,0-0.4L43.4,60.7z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M63.3,61h-5.8c-0.2,0-0.4,0.2-0.4,0.4v2c0,0.2,0.2,0.4,0.4,0.4h2.9v0.1c0,1.2-0.4,3.2-3.2,3.2 c-2.5,0-3.9-1.8-3.9-5c0-2.3,0.7-5.1,3.9-5.1c1.6,0,2.7,0.7,3.1,1.9c0.1,0.2,0.2,0.3,0.4,0.3h2.6c0.1,0,0.2-0.1,0.3-0.1 c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-2-2.4-4.4-6.4-4.4c-4.5,0-7.4,3.1-7.4,7.9c0,4.6,2.7,7.6,6.8,7.6c2,0,3.4-0.5,4.3-1.6 c0,0.4,0.1,0.7,0.2,1.1c0,0.2,0.2,0.3,0.4,0.3h1.8c0.2,0,0.4-0.2,0.4-0.4v-7.7C63.7,61.2,63.5,61,63.3,61z" })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M83.2,77.8L71.3,38.5c-0.2-0.7-0.8-1.2-1.6-1.2h-12l0.8-1.2c1.2-1.7,1.8-3.7,1.8-5.8 C60.3,24.6,55.7,20,50,20s-10.3,4.6-10.3,10.3c0,2.1,0.6,4.1,1.8,5.8l0.8,1.2h-12c-0.7,0-1.4,0.5-1.6,1.2L16.8,77.8 c0,0.1-0.2,1.1,0.1,1.6c0.4,0.5,1.4,0.6,1.4,0.6l31.6,0l31.6,0c0,0,1,0,1.4-0.6C83.4,78.8,83.2,77.9,83.2,77.8z M50,23.3 c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S46.1,23.3,50,23.3z M50,76.6H20.7l10.9-36H50h18.4l10.9,36H50z" })));
export default SvgWeight;
