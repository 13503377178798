import { Button } from '@mui/material'
import { isEqual } from 'lodash'
import { AssemblyHeaderRow, BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useErpErrorFilter } from '../BoMItemFilter/Components/PartTypeIdFilter/useErpErrorFilter'
import { applyErpErrorFilter } from '../BoMItemFilter/Filter/IssuesFilter/applyErpErrorFilter'
import { bomItemSelector } from '../store/selectors/bomItemSelector'
import { projectSelectors } from '../store/selectors/projectSelectors'

type Props = {
  bomItemPointer: BomItemPointer
}

const rowsWithErpExportErrorsSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): BomItemPointer[] => {
    const bomItem = bomItemSelector(state, bomItemPointer)
    const rowsWithErrors: BomItemPointer[] = []

    if (!bomItem) {
      return []
    }

    if (bomItem.type === BomItemType.project) {
      state.project.filteredAssemblyHeaderIds.forEach((assemblyHeaderId) => {
        const assembly = bomItemSelector(state, {
          id: assemblyHeaderId,
          type: BomItemType.assemblyType,
        }) as AssemblyHeaderRow

        if (applyErpErrorFilter(state.project, assembly)) {
          rowsWithErrors.push({
            id: assemblyHeaderId,
            type: BomItemType.assemblyType,
          })
        }

        assembly.filteredPartTypePointers.forEach((partTypePointer) => {
          if (applyErpErrorFilter(state.project, partTypePointer)) {
            rowsWithErrors.push(partTypePointer)
          }
        })
      })
    } else if (bomItem.type === BomItemType.assemblyType) {
      if (applyErpErrorFilter(state.project, bomItem)) {
        rowsWithErrors.push(bomItem)
      }

      bomItem.filteredPartTypePointers.forEach((partTypePointer) => {
        if (applyErpErrorFilter(state.project, partTypePointer)) {
          rowsWithErrors.push(partTypePointer)
        }
      })
    }

    return rowsWithErrors
  }

export const BomItemErpExportErrorFilter = (props: Props) => {
  const { t } = useTranslation('project')

  const rowsWithErrors = useAppSelector(
    rowsWithErpExportErrorsSelector(props.bomItemPointer),
    isEqual
  )

  const isBuyerParty = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const { handleChangeFilter, filterIsActive, handleDisableFilter } =
    useErpErrorFilter()

  if (isBuyerParty || rowsWithErrors.length === 0) {
    return null
  }

  const onClick = () => {
    if (filterIsActive) {
      handleDisableFilter()
    } else {
      handleChangeFilter()
    }
  }

  return (
    <Button
      size="small"
      variant={filterIsActive ? 'contained' : 'text'}
      color="error"
      onClick={onClick}
      onDoubleClick={(e) => e.stopPropagation()}
      title={t('erp-errors-title', 'show only rows with ERP export issues')}
    >
      {t('erp-errors-count', {
        defaultValue: '{{count}} rows with ERP export issues',
        count: rowsWithErrors.length,
      })}
    </Button>
  )
}
