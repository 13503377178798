/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Theme, Typography, useTheme } from '@mui/material'
import { useRALColorWithoutTranslation } from 'components/RALSelector/useRALColorData'
import { KeywordTag } from 'features/BillOfMaterials/components/BomItemActivities/KeywordTag'
import _ from 'lodash'
import { KeywordTranslated } from 'model/materials/MaterialSelectorDtoWithTranslation'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select, {
  ActionMeta,
  GroupHeadingProps,
  MultiValue,
  MultiValueProps,
  OptionProps,
  createFilter,
} from 'react-select'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useKeywordsTranslation } from './useKeywordsTranslation'

export type TokenSelectorProps = {
  selectedTokens: Array<KeywordTranslated>
  selectableTokens: Array<KeywordTranslated>
  onTokensChanges: (tokens: KeywordDto[]) => void
  width: string
  listMaxHeight?: string
}

const GroupHeading = (
  props: GroupHeadingProps<
    KeywordTranslated,
    true,
    {
      category: string
      options: Array<KeywordTranslated>
    }
  >
) => {
  // console.log('groupHeading', props)
  const { t } = useTranslation()

  return (
    <div>
      <Typography variant="body2" color="textSecondary">
        {t(
          `project:material-category-${props['data'].category}`,
          props['data'].category
        )}
      </Typography>
    </div>
  )
}

const MultiValueComponent = (
  props: MultiValueProps<
    KeywordTranslated,
    true,
    {
      category: string
      options: KeywordTranslated[]
    }
  >
) => {
  const { translateKeyword } = useKeywordsTranslation()
  return (
    <div {...props.innerProps}>
      <KeywordTag
        key={props.data.originalKeyword}
        token={props.data}
        translateFn={translateKeyword}
        style={{ margin: '4px 8px 4px 0' }}
        onDelete={(e) => {
          props.removeProps.onClick && props.removeProps.onClick(e)
        }}
      />
    </div>
  )
}

const OptionComponent = (
  props: OptionProps<
    KeywordTranslated,
    true,
    {
      category: string
      options: KeywordTranslated[]
    }
  >
) => {
  const colorInfo = useRALColorWithoutTranslation(props.data.originalKeyword)

  return (
    <div {...props.innerProps}>
      <MenuItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: colorInfo?.color.hex,
          color: (theme) =>
            colorInfo && theme.palette.getContrastText(colorInfo?.color.hex),
          '&:hover': {
            border: colorInfo && `2px solid currentColor`,
            backgroundColor: colorInfo?.color.hex,
            fontWeight: (theme) => theme.typography.fontWeightBold,
          },
        }}
      >
        {colorInfo && (
          <Typography
            variant="body2"
            style={{ flex: '0 0 20%', fontWeight: 'inherit' }}
          >
            {props.data.originalKeyword}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{ flex: '1 1 100%', fontWeight: 'inherit' }}
        >
          {props.data.translatedString}
        </Typography>
      </MenuItem>
    </div>
  )
}

const getSelectStyles = (theme: Theme, listMaxHeight?: string) => ({
  control: (base: any) => ({
    ...base,
    minWidth: '100%',
    maxWidth: '100%',
    marginTop: '5px',
    marginBottom: '2px',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.paper,
  }),
  menu: () => ({ boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }),
  menuList: (base: any) => ({
    ...base,
    height: '100%',
    overflowY: 'auto',
    maxHeight: listMaxHeight || 'calc(60vh - 58px)',
  }),
  option: (base: any, { isSelected, isFocused }) => ({
    ...base,
    paddingLeft: '25px',
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundColor:
      isFocused || isSelected ? theme.palette.action.selected : '',
    '&:active': {
      backgroundColor: theme.palette.action.selected,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (base: any) => ({ ...base, display: 'none' }),
  input: (base) => ({
    ...base,
    color: theme.palette.text.primary,
  }),
})

export const TokenSelector = (props: TokenSelectorProps) => {
  const theme = useTheme()
  const selectStyles = useMemo(
    () => getSelectStyles(theme, props.listMaxHeight),
    [theme, props.listMaxHeight]
  )
  const { i18n } = useTranslation()
  const { translateKeyword } = useKeywordsTranslation()

  const onTokensSelected = (
    value: MultiValue<KeywordDto>,
    action: ActionMeta<KeywordDto>
  ) => {
    if (action.removedValue) {
      props.onTokensChanges(
        props.selectedTokens.filter(
          (token) =>
            token.originalKeyword !== action.removedValue?.originalKeyword
        )
      )

      return
    }

    props.onTokensChanges((value || []).map((x) => x))
  }

  const isLoadingMaterials = useAppSelector(
    (state: RootAppState) =>
      state.app.currentNetworkAction === 'loading-materials' &&
      state.app.isLoading === true
  )

  return (
    <div style={{ width: props.width }}>
      <Select
        autoFocus={true}
        openMenuOnFocus={true}
        menuIsOpen={true}
        defaultMenuIsOpen={true}
        isMulti={true}
        isLoading={isLoadingMaterials}
        tabSelectsValue={false}
        closeMenuOnSelect={false}
        onChange={onTokensSelected}
        filterOption={createFilter({
          stringify(option) {
            return option.data.originalKeyword + option.data.translatedString
            // if (option.data.originalKeyword.startsWith('RAL')) {
            //   return (
            //     translateKeyword(option.data) +
            //     tColor('RAL_' + option.data.originalKeyword.substring(3))
            //   )
            // } else {
            //   return translateKeyword(option.data)
            // }
          },
        })}
        options={
          (isLoadingMaterials && []) ||
          Object.keys(
            _.groupBy(props.selectableTokens, (x) => x?.category)
          ).map((key) => {
            return {
              category: key,
              options: props.selectableTokens
                .filter((x) => x && x.category === key)
                .sort((a, b) =>
                  (a.originalKeyword || '').localeCompare(
                    b.originalKeyword || '',
                    i18n.language,
                    { numeric: true }
                  )
                ),
            }
          })
        }
        value={props.selectedTokens}
        styles={selectStyles}
        getOptionLabel={(option) => translateKeyword(option)}
        getOptionValue={(option) => option.originalKeyword ?? ''}
        components={{
          GroupHeading: GroupHeading,
          MultiValue: MultiValueComponent,
          Option: OptionComponent,
        }}
        placeholder="Select tokens"
        classNamePrefix="materialSelector"
        menuShouldScrollIntoView={true}
        hideSelectedOptions={true}
      />
    </div>
  )
}
