import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { useTranslation } from 'react-i18next'
import { FeatureSectionDto } from '../../../../services/APIs/InternalAPI/internal-api.contracts'
import { FeatureFaceIds } from './FeatureFaceIds'
import { FeatureItem } from './FeatureItem'

export const FeatureSection = (props: {
  featureSection: FeatureSectionDto[]
  viewController?: ModelController
}) => {
  const { t } = useTranslation('project')

  return (
    <TableRow
      sx={{ borderLeft: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <TableCell colSpan={10} sx={{ padding: 0 }}>
        <Table sx={{ width: '100%' }}>
          <TableBody>
            {props.featureSection.map((featureSection, index) => {
              return (
                <>
                  <TableRow key={`${featureSection.label}-${index}`}>
                    <TableCell
                      style={{
                        width: '100%',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {featureSection.label}
                      </Typography>
                      <Table size="small" sx={{ width: '100%' }}>
                        <TableBody>
                          {featureSection.details
                            .sort((a, b) =>
                              t(a.label).localeCompare(t(b.label))
                            )
                            .map((featureDetail, index) => {
                              return (
                                <FeatureItem
                                  key={`${featureDetail.label}-${index}`}
                                  featureDetail={featureDetail}
                                  viewController={props.viewController}
                                  nested
                                />
                              )
                            })}
                        </TableBody>
                      </Table>
                      <FeatureFaceIds
                        faceIds={featureSection.faceIds}
                        viewController={props.viewController}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  )
}
