/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const usePortalHomeStyles = makeStyles()((theme) => ({
  dashboardContainer: {
    display: 'grid',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      paddingTop: theme.spacing(4),
    },
    gap: theme.spacing(4),
    alignContent: 'center',
    width: '100%',
    height: '100%',

    // display: 'flex',
    // gap: theme.spacing(4),
    // flexWrap: 'wrap',
    // paddingTop: theme.spacing(4),
    // justifyContent: 'space-around',
    // '& .dashboard-widget': {
    //   // width: '100%',
    //   flex: '1 1 300px',
    // },
    // height: 'calc(100vh - 152px)',
    // overflowY: 'auto',
  },
  filter: {
    // width: '100% ',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  kanbanContainer: {
    width: '100vw',
    height: 'calc(100vh - 152px)',
  },
  createProjectContainer: {
    maxHeight: 'calc(100vh - 152px)',
    overflowY: 'auto',
  },
}))
