import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectsSummariesController } from 'controllers/Projects/ProjectsSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { debounce } from 'lodash'
import {
  MRT_BottomToolbar,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableContainer,
  MRT_TableInstance,
  MRT_VisibilityState,
} from 'material-react-table'
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { deleteProjects } from 'store/ProjectList/asyncActions/deleteProjects'
import { useShowException } from 'utils/useShowException'
import useLocalizedMaterialReactTable from '../useLocalizedMaterialReactTable'
import { useProjectListColumns } from './_projectListColumns'
import CustomToolbar from './components/CustomToolbar'
import { RowActionsComponent } from './components/RowActions'

export const NewProjectList = () => {
  const { controller, loading } = useAppController(
    () => new ProjectsSummariesController()
  )
  const partyId = useAppSelector((state) => state.user?.organizationContext?.id)

  const ShowException = useShowException('projects')
  const [onlyMyProjects, setOnlyMyProjects] = useClientStorage<boolean>(
    'filterMyProjects',
    false
  )
  const [showCanceledProjects, setShowCanceledProjects] =
    useClientStorage<boolean>('showCanceledProjects', false)

  const _columns = useProjectListColumns()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [projectList, setProjectList] = useState<ProjectSummaryDto[]>(undefined)

  const [currentQuery, setCurrentQuery] = useClientStorage('currentQuery', '')

  const [pagination, setPagination] = useClientStorage<MRT_PaginationState>(
    'project-list-pagination',
    {
      pageIndex: 0,
      pageSize: 20,
    },
    'localStorage',
    (oldValue) => {
      if (oldValue?.pageSize > 20) {
        return {
          ...oldValue,
          pageIndex: 0,
          pageSize: 20,
        }
      } else {
        return oldValue
      }
    }
  )

  const [sorting, setSorting] = useClientStorage<MRT_SortingState>(
    'projectListSorting',
    [
      {
        desc: true,
        id: 'lastOperation',
      },
    ]
  )

  const [columnVisibility, setColumnVisibility] =
    useClientStorage<MRT_VisibilityState>('columnsVisibility', {})

  const [rowCount, setRowCount] = useState(0)

  const [projectStateFilters, setProjectStateFilters] = useClientStorage<
    ProjectStateAsString[]
  >('project-state-filters', [])

  const [filterValue, setFilterValue] = useState<string>(currentQuery)

  const getProjectList = useCallback(
    async (filter: string) => {
      try {
        try {
          controller.CancelRequests()
        } catch (err) {
          console.info(
            'canceled getProjects request - another request was made'
          )
        }

        const resp = await controller.GetProjectsListPaged(
          onlyMyProjects,
          showCanceledProjects ||
            projectStateFilters.includes(ProjectStateAsString.CANCELLED),
          {
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            search: filter,
            orderBy: sorting?.[0]?.id as keyof ProjectSummaryDto,
            orderDirection: sorting?.[0]?.desc ? 'desc' : 'asc',
          },
          projectStateFilters
        )

        if (resp) {
          setCurrentQuery(filter)
          setProjectList((curr) => resp?.details || curr)
          setRowCount((curr) => resp?.totalCount || curr)
        }
      } catch (err) {
        setProjectList((curr) => {
          if (curr === undefined) {
            return []
          } else {
            return curr
          }
        })
        ShowException(err)
      }
    },
    [
      ShowException,
      controller,
      onlyMyProjects,
      pagination.pageIndex,
      pagination.pageSize,
      projectStateFilters,
      setCurrentQuery,
      showCanceledProjects,
      sorting,
    ]
  )

  const debouncedGetProjectList = useMemo(() => {
    return debounce(getProjectList, 5_000, {
      leading: false,
      trailing: true,
      maxWait: 5_000,
    })
  }, [getProjectList])

  const handleDeleteProject = useCallback(
    async (projectId: string, projectStatus: ProjectStateAsString) => {
      try {
        dispatch(
          deleteProjects({
            requests: [{ projectId: projectId, projectStatus: projectStatus }],
            successCallback: () => {
              setProjectList(projectList.filter((x) => x.id !== projectId))
            },
          })
        )
      } catch (err) {
        ShowException(err)
      }
    },
    [ShowException, dispatch, projectList]
  )

  const handleDeleteSelectedProjects = useCallback(
    async (table: MRT_TableInstance<ProjectSummaryDto>) => {
      const selectedProjectsIds = table.getState().rowSelection
      const deleteRequests = Object.keys(selectedProjectsIds).map((x) => {
        return {
          projectId: x,
          projectStatus: projectList
            .find((p) => p.id === x)
            ?.status.toUpperCase() as ProjectStateAsString,
        }
      })

      dispatch(
        deleteProjects({
          requests: deleteRequests,
          successCallback: (projectIds) => {
            setProjectList(
              projectList.filter((x) => !projectIds.includes(x.id))
            )
            table.setState((state) => ({ ...state, rowSelection: {} }))
          },
        })
      )
    },
    [dispatch, projectList]
  )

  const handleChangeOnlyMyProjects = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setOnlyMyProjects(checked)
    },
    [setOnlyMyProjects]
  )

  const handleProjectStateFilter = useCallback(
    (selectedProjectStates: ProjectStateAsString[]) => {
      setProjectStateFilters(selectedProjectStates)
    },
    [setProjectStateFilters]
  )

  useEffect(() => {
    getProjectList(currentQuery)

    return () => {
      debouncedGetProjectList.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedGetProjectList])

  useEffect(() => {
    SignalRService.GetHub().then((hub) => {
      hub.registerHandler('onProjectsListUpdates', () => {
        const filter = document.getElementById(
          'search-projects'
        ) as HTMLInputElement

        debouncedGetProjectList(filter?.value)
      })

      hub.JoinGroup(`${partyId}_project_list`, () => {
        console.info('connected')
      })
    })

    return () => {
      SignalRService.GetHub().then((hub) => {
        hub.LeaveGroup(`${partyId}_project_list`, () => {
          console.info('disconnected from project list')
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId])

  const handleFilterChange = async (filterValue: string) => {
    setFilterValue(filterValue)
    await getProjectList(filterValue)
  }

  const tableOptions = useLocalizedMaterialReactTable({
    columns: _columns,
    data: projectList || [],
    // data: [],
    getRowId: (row) => row.id,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
    },
    state: {
      isLoading:
        filterValue !== currentQuery ||
        (loading['GetProjectsListPaged'] && projectList === undefined) ||
        projectList === undefined,
      globalFilter: filterValue,
      columnVisibility: columnVisibility,
      pagination: pagination,
      sorting: sorting,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 50],
    },
    manualSorting: true,
    onSortingChange: (updateOrValue) => {
      setSorting(updateOrValue)
    },
    rowCount: rowCount,
    enableFilterMatchHighlighting: false,
    enableSortingRemoval: false,
    enableMultiSort: false,
    enableClickToCopy: true,
    enableStickyHeader: true,
    enableGrouping: true,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableRowActions: true,
    positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    // layoutMode: 'grid',
    layoutMode: 'semantic',

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onColumnVisibilityChange: (updater: any) => {
      const value = updater()

      if (Object.keys(value)[0] === 'projectReference') {
        return
      }

      setColumnVisibility(updater as SetStateAction<MRT_VisibilityState>)
    },
    manualPagination: true,
    onPaginationChange: (pagination) => {
      setPagination(pagination)
    },
    manualFiltering: true,
    onGlobalFilterChange: (filterValue) => {
      handleFilterChange(filterValue)
    },
    renderRowActions: (props) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <RowActionsComponent
            {...props}
            handleDeleteProject={handleDeleteProject}
          />
        </div>
      )
    },
    enableGlobalFilter: false,

    muiTableBodyRowProps: (rowProps) => {
      return {
        onClick: (e) => {
          dispatch(fullUnloadActiveProject())

          if (e.ctrlKey || e.metaKey) {
            window.open(
              `${window.location.origin}/app/project/${rowProps.row.original.id}`,
              '_blank',
              'rel=noopener noreferrer'
            )
          } else {
            navigate(`/app/project/${rowProps.row.original.id}`)
          }
        },
        sx: {
          cursor: 'pointer',
        },
      }
    },
    muiTableContainerProps: () => {
      return {
        style: {
          height: '65vh', //height,
        },
      }
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      InputProps: {
        sx: {
          '& input': {
            width: {
              xs: '25vw',
              sm: '25vw',
              md: '25vw',
              lg: '20vw',
              xl: '20vw',
            },
          },
        },
      },
      inputProps: {
        id: 'search-projects',
      },
    },
    enableColumnDragAndDrop: false,

    muiColumnActionsButtonProps: {
      sx: {
        height: '1.5rem',
        width: '1.5rem',
      },
    },
    muiTableHeadCellProps: (column) => {
      return {
        sx: {
          paddingTop: '.5rem',
          '& > div': {
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          },
          '& .Mui-TableHeadCell-Content-Wrapper': {
            textTransform: 'lowercase',
          },
          '& [draggable=true]': {
            display: 'none',
          },
          '& .Mui-TableHeadCell-Content': {
            gap: '1rem',
            '& .MuiBadge-root': {
              display:
                sorting[0]?.id.toLowerCase() === column.column.id.toLowerCase()
                  ? 'inline-flex'
                  : 'none',
            },
          },
        },
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        visibleInShowHideMenu: false,
        Header: '',
      },

      'mrt-row-select': {
        visibleInShowHideMenu: false,
      },
      // 'mrt-row-spacer': {
      //   visibleInShowHideMenu: false,
      // },
    },
    renderEmptyRowsFallback: () => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '25vh',
            width: '95vw',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <LocalizedTypography
              translationKey="common:no-projects-found"
              color="textSecondary"
              component="p"
            >
              no projects found.
            </LocalizedTypography>
          </div>
        </div>
      )
    },
  })

  return (
    <div>
      <CustomToolbar
        table={tableOptions}
        loading={loading}
        onlyMyProjects={onlyMyProjects}
        showCanceledProjects={showCanceledProjects}
        setShowCanceledProjects={setShowCanceledProjects}
        getProjectList={getProjectList}
        handleChangeOnlyMyProjects={handleChangeOnlyMyProjects}
        handleDeleteSelectedProjects={handleDeleteSelectedProjects}
        selectedProjectStates={projectStateFilters}
        onProjectStateChange={handleProjectStateFilter}
        filterValue={filterValue}
        projectStateFilters={projectStateFilters}
        handleProjectStateFilter={handleProjectStateFilter}
        sorting={sorting}
        setSorting={setSorting}
      />
      <MRT_TableContainer table={tableOptions} />
      <MRT_BottomToolbar table={tableOptions} />
    </div>
  )
}
