/* eslint-disable tss-unused-classes/unused-classes */
import { useAuth0 } from '@auth0/auth0-react'
import Axios from 'axios'
import { useAppController } from 'customHooks/useAppController'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { isEqual } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService } from 'services/auth'
import { ShowException } from 'store/Application/appActions'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useWorkspacesStyles } from './useWorkspacesStyles'

const Selectors = {
  organizations: (state: RootAppState) => state.user.organizations,
  availablePortals: (state: RootAppState) => state.user.availablePortals,
}

export const useWorkspaces = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { classes } = useWorkspacesStyles()
  const WorkspacePageException = (error) => ShowException('Workspaces', error)

  const { controller, loading } = useAppController(() => new UserController())

  const organizations = useAppSelector(Selectors.organizations, isEqual)

  const availablePortals = useAppSelector(Selectors.availablePortals, isEqual)
  const { withinIframe } = useAppRunningContext()
  const currentPartyId = useAppSelector(
    (state) => state.user?.organizationContext?.id
  )
  const currentPartyName = useAppSelector(
    (state) => state.user?.organizationContext?.name
  )

  const auth0 = useAuth0()

  const getProfileData = useCallback(async () => {
    try {
      if (withinIframe && !Axios.defaults.headers['MH-Tenant']) {
        console.error(
          'getProfileData: no partyId provided, tenant header not set'
        )
        return
      }

      console.info('workspace: will get profile data')

      const user = await controller.GetProfile({
        onNavigate: (returnUrl, currentPartyId) => {
          console.info(
            'routes: useWorspaces: profile data returned and will redirect to',
            { returnUrl, currentPartyId }
          )
          navigate(returnUrl, { replace: true })
        },
        returnUrl: location.state?.returnUrl,
      })

      console.info('workspace: profile data returned', user)
    } catch (err) {
      console.error('workspace: getting profile data faled', err)
    }
  }, [controller, location.state?.returnUrl, navigate, withinIframe])

  const selectWorkspace = useCallback(
    (workspaceId: string) => {
      controller
        .SelectWorkspace(workspaceId)
        .then(() => {
          if (location.state?.projectNotAllowedId) {
            navigate(`/app/project/${location.state?.projectNotAllowedId}`, {
              replace: true,
            })
            return null
          }

          navigate(
            (location.state?.returnUrl &&
              location.state?.returnUrl !== '/app/workspaces' &&
              location.state?.returnUrl) ||
              '/app',
            { replace: true }
          )
        })
        .catch(WorkspacePageException)
    },

    [
      controller,
      location.state?.projectNotAllowedId,
      location.state?.returnUrl,
      navigate,
    ]
  )

  useEffect(() => {
    const userAuthenticated = !auth0?.isLoading && auth0?.isAuthenticated

    if (userAuthenticated && !organizations) {
      AuthService.Build(auth0)
      getProfileData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth0.isAuthenticated,
    auth0.isLoading,
    getProfileData,
    organizations,
    selectWorkspace,
  ])

  return {
    auth0,
    location,
    organizations,
    availablePortals,
    loading,
    selectWorkspace,
    currentPartyId,
    currentPartyName,
    withinIframe,
    classes,
    t,
    getProfileData,
    controller,
  }
}
