import { useAuth0 } from '@auth0/auth0-react'
import { Navigate, Outlet } from 'react-router-dom'

export const OnlyNOTAuthenticated = () => {
  const auth0 = useAuth0()

  if (auth0.isAuthenticated) {
    return <Navigate to="/app" />
  }

  return <Outlet />
}
