import {
  ChecklistOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@mui/icons-material'
import { Theme, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  IssueCode,
  IssueDto,
  IssueSeverity,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { EstimatedProductionTimeIsZeroTodo } from '../BomItemTodos/Todos/EstimatedProductionTimeIsZeroTodo'
import { MaterialCostPriceIsZeroTodo } from '../BomItemTodos/Todos/MaterialCostPriceIsZeroTodo'
import { MaterialNotSetTodo } from '../BomItemTodos/Todos/MaterialNotSetTodo'
import { MaterialOrManualPriceNotSetTodo } from '../BomItemTodos/Todos/MaterialOrManualPriceNotSetTodo'
import { UpperSideNotNeededTodo } from '../BomItemTodos/Todos/UpperSideNotNeededTodo'
import { UpperSideSelectionTodo } from '../BomItemTodos/Todos/UpperSideSelectionTodo'
import { todosIssueCodes } from '../BomItemTodos/TodosIssueCodes'
import { bomItemIssuesSelector } from '../store/selectors/bomItemIssuesSelector'
import { useBomItemIssueDescription } from './useBomItemIssueDescription'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    warningColor: {
      color: theme.palette.warning.light,
    },
    issuesContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }
})

export const IssueSeverityIcon = (props: { severity: IssueSeverity }) => {
  const { classes } = useStyles()

  return props.severity === IssueSeverity.Error ? (
    <CloseOutlined color="error" />
  ) : (
    <WarningOutlined className={classes.warningColor} />
  )
}

const TodoComponents = (bomItemPointer: BomItemPointer, issue: IssueDto) => {
  return {
    [IssueCode.UpperSideNotSelected]: () => {
      return (
        <UpperSideSelectionTodo bomItemPointer={bomItemPointer} issue={issue} />
      )
    },
    [IssueCode.UpperSideSelectedNotNeeded]: () => {
      return (
        <UpperSideNotNeededTodo bomItemPointer={bomItemPointer} issue={issue} />
      )
    },
    [IssueCode.MaterialCostPriceNotSet]: () => {
      return (
        <MaterialCostPriceIsZeroTodo
          bomItemPointer={bomItemPointer}
          issue={issue}
        />
      )
    },
    [IssueCode.MaterialAndManualCostPriceNotSet]: () => {
      return (
        <MaterialOrManualPriceNotSetTodo
          bomItemPointer={bomItemPointer}
          issue={issue}
        />
      )
    },
    [IssueCode.ProductionTimeNotSet]: () => {
      return (
        <EstimatedProductionTimeIsZeroTodo
          bomItemPointer={bomItemPointer}
          issue={issue}
        />
      )
    },
    [IssueCode.MaterialNotSet]: () => {
      return (
        <MaterialNotSetTodo bomItemPointer={bomItemPointer} issue={issue} />
      )
    },
  }
}

const BomItemIssue = (props: {
  issue: IssueDto
  bomItemPointer: BomItemPointer
}) => {
  const { classes } = useStyles()
  const { getIssueDescription } = useBomItemIssueDescription()

  const issueActions = TodoComponents(props.bomItemPointer, props.issue)[
    props.issue.issueCode
  ]

  if (issueActions) {
    return issueActions(props.issue)
  }

  return (
    <li
      className={classes.issuesContainer}
      onClick={() => {
        issueActions?.()
      }}
      style={{
        cursor: issueActions ? 'pointer' : 'default',
        width: '100%',
        paddingLeft: '8px',
      }}
    >
      {todosIssueCodes.has(props.issue.issueCode) ? (
        <ChecklistOutlined />
      ) : (
        <span style={{ marginRight: '8px' }}>
          <IssueSeverityIcon severity={props.issue.severity} />
        </span>
      )}
      <Typography variant="body2" style={{ width: '100%', display: 'block' }}>
        {getIssueDescription(props.issue)}
      </Typography>
    </li>
  )
}

export const BomItemIssues = (props: {
  bomItemPointer: BomItemPointer
  ulClassName?: string
  liClassName?: string
  showAll?: boolean
  hideIssues?: boolean
  hideTodos?: boolean
}) => {
  let issues = useAppSelector(
    bomItemIssuesSelector(props.bomItemPointer),
    isEqual
  )

  if (!issues?.length) {
    return null
  }

  if (props.hideTodos) {
    issues = issues.filter((x) => !todosIssueCodes.has(x.issueCode))
  }

  if (props.hideIssues) {
    issues = issues.filter((x) => todosIssueCodes.has(x.issueCode))
  }

  return (
    <ul className={props.ulClassName} style={{ width: '100%' }}>
      {issues.map((x) => (
        <BomItemIssue
          bomItemPointer={props.bomItemPointer}
          key={x.id}
          issue={x}
        />
      ))}
    </ul>
  )
}
