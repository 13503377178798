/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui'
import { StyleProps } from './BomItemPricePerBatch'

export const useBomItemPricePerBatchStyles = makeStyles<StyleProps>()(
  (theme, props) => ({
    moneyTextField: {
      maxWidth: props.fullWidth ? '100%' : theme.spacing(15),
      // '& label': {
      //   transform: 'translate(0px, 1.5px) scale(0.75)',
      //   transformOrigin: 'top right',
      //   left: 'unset',
      //   right: 0,
      //   whiteSpace: 'nowrap',
      // },
    },
    targetPrice: {
      display: 'absolute',
    },
    batchSize: {
      maxWidth: theme.spacing(10),
      '& input': {
        textAlign: 'right',
      },
    },
  })
)
