import { useAppController } from 'customHooks/useAppController'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import {
  KeywordDto,
  MaterialSummaryDto,
  MoneyDto,
  QuantityDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { PurchasingInfoController } from './PurchasingInfoController'

export type CustomPrice = {
  CostPrice: MoneyDto
  SurchargeRatio: QuantityDto
  DiscountRatio: QuantityDto
}

export const usePurchasingPartDialog = (
  bomItemPointer: BomItemPointer,
  onClose: () => void
) => {
  const dispatch = useAppDispatch()

  const purchasingActivity = useAppSelector(
    bomItemActivitiesSelectors.byWorkingStep(
      bomItemPointer,
      WorkingStepType.Purchasing
    ),
    isEqual
  )

  const currentDefinedCostPrice = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      bomItemPointer,
      purchasingActivity?.id,
      'costPricePerItem'
    )
  ) as MoneyDto

  const currentDefinedSurcharge = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      bomItemPointer,
      purchasingActivity?.id,
      'surchargeRatio'
    )
  ) as MoneyDto

  const currentDefinedDiscount = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      bomItemPointer,
      purchasingActivity?.id,
      'discountRatio'
    )
  ) as MoneyDto

  const currentMaterialSummary = useAppSelector(
    projectSelectors.bomItemMaterialSummarySelector(
      bomItemPointer,
      purchasingActivity?.id
    )
  )

  const partTypeIsPurchasing = useAppSelector(
    projectSelectors.bomItemIsPurchasingPartSelector(bomItemPointer)
  )

  const [hasChanges, setHasChanges] = useState<boolean>(() => {
    if (!partTypeIsPurchasing) {
      return true
    } else {
      return false
    }
  })

  const { controller, loading } = useAppController(
    () => new PurchasingInfoController()
  )

  const selectedArticle: MaterialSummaryDto = useAppSelector(
    (state) =>
      state.materials.articleSelectorUI?.selectedMaterial ||
      purchasingActivity?.article,
    isEqual
  )

  useEffect(() => {
    if (selectedArticle?.id !== currentMaterialSummary?.id) {
      setHasChanges(true)
    }
  }, [selectedArticle?.id, currentMaterialSummary?.id])

  const [useArticleDefinedPrice, setUseArticleDefinedPrice] = useState(() => {
    const keywordsToSend = Object.values(
      currentMaterialSummary?.tokens || {}
    ).flatMap((x: Array<KeywordDto> | KeywordDto) => {
      if (Array.isArray(x)) {
        return x.map((x) => x.originalKeyword)
      } else {
        return x.originalKeyword
      }
    })

    return keywordsToSend[0] === 'part' && Boolean(currentMaterialSummary?.id)
  })

  const currentlyIsCustomPrice = useAppSelector(
    projectSelectors.bomItemMaterialHasCustomPrice(
      bomItemPointer,
      purchasingActivity?.id
    )
  )

  const [customPrice, setCustomPrice] = useState<CustomPrice | undefined>({
    CostPrice: currentDefinedCostPrice,
    SurchargeRatio: currentDefinedSurcharge,
    DiscountRatio: currentDefinedDiscount,
  })

  const [isPurchasingPart, setIsPurchasingPart] = useState(true)

  const storeCustomPrice = (
    key: keyof CustomPrice,
    value: MoneyDto | QuantityDto
  ) => {
    setCustomPrice((old) => {
      const newCustomPrice = {
        ...(old || {
          CostPrice: undefined,
          DiscountRatio: undefined,
          SurchargeRatio: undefined,
        }),
        [key]: value,
      }

      return newCustomPrice
    })
    setHasChanges(true)
  }

  const handleClose = () => {
    dispatch(MaterialActions.closeModal())
    onClose()
  }

  const handleSave = async () => {
    try {
      if (!hasChanges) {
        handleClose()
        return
      }

      if (isPurchasingPart) {
        let keywordsToSend = Object.values(
          selectedArticle?.tokens || {}
        ).flatMap((x: Array<KeywordDto> | KeywordDto) => {
          if (Array.isArray(x)) {
            return x.map((x) => x.originalKeyword)
          } else {
            return x.originalKeyword
          }
        })

        if (keywordsToSend[0] !== 'part') {
          keywordsToSend = ['part']
        }

        await controller.setBomItemAsPurchasingPart(bomItemPointer.id, {
          articleId: useArticleDefinedPrice ? selectedArticle?.id : undefined,
          keywords: useArticleDefinedPrice ? keywordsToSend : ['part'],
          costPricePerItem: !useArticleDefinedPrice
            ? customPrice?.CostPrice
            : undefined,
          surchargePercentage: !useArticleDefinedPrice
            ? customPrice?.SurchargeRatio?.value || 0
            : undefined,
          discountPercentage: !useArticleDefinedPrice
            ? customPrice?.DiscountRatio?.value || 0
            : undefined,
        })

        if (useArticleDefinedPrice) {
          handleClose()
        }
      } else {
        await controller.unsetAsPurchasingPart(bomItemPointer.id)
        handleClose()
      }

      setHasChanges(false)
    } catch (err) {
      ShowException('purchasing data', err)
    }
  }

  const handleChangeIsPurchasingPart = (checked: boolean) => {
    setIsPurchasingPart(checked)
    if (checked === false) {
      setUseArticleDefinedPrice(false)
    }
    setHasChanges(true)
  }

  const handleChangeUseArticleDefinedPrice = (checked: boolean) => {
    setUseArticleDefinedPrice(checked)
    if (checked === true) {
      setIsPurchasingPart(true)
    }
    setHasChanges(true)
  }

  return {
    partTypeIsPurchasing,
    selectedArticle,
    useArticleDefinedPrice,
    handleChangeUseArticleDefinedPrice,
    customPrice,
    isPurchasingPart,
    handleChangeIsPurchasingPart,
    handleClose,
    handleSave,
    loading,
    currentlyIsCustomPrice,
    storeCustomPrice,
    hasChanges,
    purchasingActivityId: purchasingActivity?.id,
  }
}
