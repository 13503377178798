import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { ValueLabelConnected } from '../store/components/ValueLabelConnected'
import { bomItemFinancialSelector } from '../store/selectors/bomItemFinancialSelector'

export const BomItemTotalProjectQuantity = ({
  bomItemPointer: bomItemId,
}: {
  bomItemPointer: BomItemPointer
}) => {
  const { t } = useTranslation()
  return (
    <ValueLabelConnected
      propertySelector={(state) =>
        bomItemFinancialSelector(
          bomItemId,
          'totalProjectQuantity'
        )(state)?.toString()
      }
      valueLabelProps={{
        label: t('project:total-project-quantity'),
        emptyString: '-',
      }}
    />
  )
}
