import { Button, lighten, Theme, Tooltip, Typography } from '@mui/material'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { CSSProperties } from 'react'
import { IssueCode } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { BomItemDetailsV2DialogProps } from '../BomItemDetailsV2/BomItemDetailsV2_Dialog'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { useBomItemThumbnailImageSrc } from '../useBomItemThumbnailImageSrc'
import { useBomItemIssueDescription } from './useBomItemIssueDescription'

type RowThumbnailButtonSizes = 'small' | 'medium' | 'large'

type BomItemThumbnailButtonProps = {
  bomItemPointer: BomItemPointer
  threeJSContainerStyle?: CSSProperties
  show2dModel?: boolean
  withCameraControls?: boolean
  showToolbox?: boolean
  size?: RowThumbnailButtonSizes
  showTooltip?: boolean
  pictureClassName?: string
  disableClick?: boolean
  operationPending?: boolean
  dontRenderEmpty?: boolean
}

const useStyles = (
  size?: RowThumbnailButtonSizes,
  disableClick?: boolean,
  operationPending?: boolean
) =>
  makeStyles()((theme: Theme) => {
    let width = theme.spacing(6)

    if (size === 'small') width = theme.spacing(4)
    if (size === 'large') width = theme.spacing(10)

    return {
      imgSize: {
        width: width,
        height: width,
        flexShrink: 0,
        flexGrow: 0,
      },
      thumbnailSize: {
        width: theme.spacing(30),
      },
      imgButton: {
        padding: 0,
        zIndex: 0,
        backgroundColor: operationPending
          ? lighten(theme.palette.action.disabled, 0.825)
          : 'inherit',
        mixBlendMode: operationPending
          ? 'multiply'
          : theme?.BoMThumbnail?.mixBlendMode,
        minWidth: width,
      },
      thumbnail: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
      },
    }
  })

const RowThumbnailButton = (props: BomItemThumbnailButtonProps) => {
  const { classes } = useStyles(
    props.size,
    props.disableClick,
    props.operationPending
  )()

  const { openDialog, closeDialog } = useDialogManager()

  const upperSideTodo = useAppSelector(
    projectSelectors.bomItemIssueByCodeSelector(
      props.bomItemPointer,
      IssueCode.UpperSideNotSelected
    )
  )

  const { getIssueDescription } = useBomItemIssueDescription()

  const setDetailsOpen = (e) => {
    e.stopPropagation()

    openDialog('BomItemDetailsV2', {
      bomItemPointer: props.bomItemPointer,
      open: true,
      onClose: () => closeDialog(),
    } as BomItemDetailsV2DialogProps)
  }

  const { imageSrc, thumbnailFilename } = useBomItemThumbnailImageSrc(
    props.bomItemPointer,
    props.show2dModel
  )

  if (!thumbnailFilename) {
    if (props.dontRenderEmpty) return null
    return <div className={classes.imgSize} />
  }

  if (props.showTooltip) {
    return (
      <Tooltip
        placement={'top'}
        arrow
        enterDelay={500}
        enterNextDelay={500}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              loading="lazy"
              decoding="async"
              src={imageSrc}
              alt={thumbnailFilename}
              className={classes.thumbnailSize}
              style={{ display: 'block' }}
            />
            {upperSideTodo && (
              <Typography variant="caption" color="textPrimary">
                {getIssueDescription(upperSideTodo)}
              </Typography>
            )}
          </div>
        }
        classes={{
          tooltip: classes.thumbnail,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom'],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  padding: 8,
                  boundary: document.getElementById(props.bomItemPointer.id),
                },
              },
            ],
          },
        }}
      >
        <Button
          onClick={(e) => setDetailsOpen(e)}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          disableElevation
          className={`${props.pictureClassName} ${classes.imgButton}`}
        >
          <img
            src={imageSrc}
            loading="lazy"
            decoding="async"
            className={classes.imgSize}
            alt={thumbnailFilename}
          />
        </Button>
      </Tooltip>
    )
  }

  return (
    <Button
      onClick={(e) => setDetailsOpen(e)}
      disableFocusRipple
      disableTouchRipple
      disableRipple
      disableElevation
      disabled={props.disableClick}
      className={props.pictureClassName}
      variant={upperSideTodo ? 'outlined' : 'text'}
    >
      <img
        loading="lazy"
        decoding="async"
        src={imageSrc}
        className={classes.imgSize}
        alt={thumbnailFilename}
      />
    </Button>
  )
}

export { RowThumbnailButton }
