import { CircularProgress, Divider, Theme } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React from 'react'
import {
  KeywordDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'
import { AvailableArticles } from '../AvailableArticles'
import { TokenSelector } from '../TokenSelector'
import { useArticleSelector } from '../useArticleSelector'

type Props = {
  bomItemPointers: BomItemPointer[]
  workingStepType: WorkingStepType
  articleSelectorMaxHeight?: string
  /**
   * If you want to preselect some keywords, you can pass them here
   * the article selector will automatically select them after first render
   * */
  initialKeywords?: KeywordDto[]
}

const useStyles = makeStyles({ name: 'ArticleSelector' })((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}))

const _ArticleSelector = (props: Props) => {
  const { classes } = useStyles()
  const { materialSelectorUI, onTokensChanges, onMaterialSelected } =
    useArticleSelector(
      props.bomItemPointers,
      props.workingStepType,
      null,
      props.initialKeywords
    )

  if (!materialSelectorUI) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <TokenSelector
        selectedTokens={materialSelectorUI.selectedTokens}
        selectableTokens={materialSelectorUI.selectableTokens}
        onTokensChanges={onTokensChanges}
        width="100%"
        listMaxHeight={props.articleSelectorMaxHeight}
      />
      <Divider orientation="vertical" flexItem />
      <AvailableArticles
        selectedMaterial={materialSelectorUI.selectedMaterial}
        materials={materialSelectorUI.materials}
        onMaterialSelected={onMaterialSelected}
        width="100%"
        maxHeight={props.articleSelectorMaxHeight}
      />
    </div>
  )
}

const ArticleSelectorMemo = React.memo(_ArticleSelector, isEqual)

export { ArticleSelectorMemo as ArticleSelector }
