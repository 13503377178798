import { Box } from '@mui/material'
import { FieldProps, UiSchema } from '@rjsf/utils'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { dashify } from 'utils/dashify'
import { NewQuantity } from '../../old-implementation/AdditionalWorkingSteps/Form/LatestCustomInput/NewQuantity'

export type QuantityFieldUISchema =
  | UiSchema
  | {
      'ui-label'?: string
      'selectable-units': string[]
      'min-value'?: number
      'max-value'?: number
      required?: boolean
      onValidationError: (property, error) => void
    }

export type RJSFQuantityFieldProps = FieldProps<QuantityDto> & {
  uiSchema?: QuantityFieldUISchema
}

export const RJSFQuantityField = (props: RJSFQuantityFieldProps) => {
  const { t } = useTranslation()
  const hasChanges = useRef(false)

  const quantityDto =
    props.formData.value > 0
      ? props.formData
      : {
          selectableAbbreviations:
            (props.uiSchema && props.uiSchema['selectable-units']) || [],
          value: 0,
          unit:
            props.uiSchema && props.uiSchema['selectable-units']
              ? props.uiSchema['selectable-units'][0]
              : '',
        }

  const handleChange = (newQuantity: QuantityDto) => {
    hasChanges.current = true
    props.onChange(newQuantity)
  }

  const label = t(`ws-form-${dashify(props.uiSchema['ui:label'].toString())}`, {
    defaultValue: props.uiSchema['ui:label'].toString(),
    ns: 'project',
  })

  if (props.rawErrors?.length) {
    setTimeout(() => {
      hasChanges.current &&
        props.formContext.onValidationError({
          [props.schema.$id]: {
            __errors: Boolean(props.rawErrors)
              ? [`${label}: ${props.rawErrors.join(' | ')}`]
              : undefined,
          },
        })
    })
  } else if (props.schema.$id) {
    props.formContext.onValidationError({
      [props.schema.$id]: {
        __errors: undefined,
      },
    })
  }

  return (
    <Box
      sx={{ marginBottom: 1, ['& .MuiFormControl-root']: { width: '100%' } }}
    >
      <NewQuantity
        quantity={quantityDto}
        onValidation={(validationError) => {
          if (props.formContext.onValidationError) {
            setTimeout(() => {
              props.formContext.onValidationError({
                [props.idSchema.$id]: {
                  __errors: Boolean(validationError?.value)
                    ? [`${label}: ${validationError?.value}`]
                    : undefined,
                },
              })
            })
          }
        }}
        selectableAbbreviations={quantityDto.selectableAbbreviations}
        onChangeQuantity={handleChange}
        label={label}
        minValue={props.uiSchema['min-value']}
        maxValue={props.uiSchema['max-value']}
        required={props.uiSchema.required}
        disableUnit={!quantityDto.selectableAbbreviations?.length}
        fullWidth
      />
    </Box>
  )
}
