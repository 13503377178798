import { styled } from "@mui/material"

export const ViewContainer = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
}))

export const ModelsContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 80px)',
  flexDirection: 'row',
  position: 'relative',
}))

export const HeaderContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
}))
