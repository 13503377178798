import { WarningOutlined } from '@mui/icons-material'
import { Divider, Theme, Typography } from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { LocalizedWorkingStepLabel } from 'components/Localization/LocalizedWorkingStepLabel'
import { MoneyString } from 'model/Money'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { withStyles } from 'tss-react/mui'
import { PriceDetailsGraphData } from '../../useBomItemDetails'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

const WarningIcon = withStyles(WarningOutlined, (theme: Theme) => ({
  root: {
    color: theme.palette.warning.main,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}))

export const ProjectLevelWorkingStepTooltip = (props: {
  data: PriceDetailsGraphData
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <WorkingStepIconSvg
        workingStepType={props.data.primaryWorkingStep}
        attributes={{ width: '3em' }}
      />
      <Typography>{props.data.resource}</Typography>
      <LocalizedWorkingStepLabel
        workingStepType={props.data.primaryWorkingStep}
        variant="caption"
        component="p"
      />
      {props.data.primaryWorkingStep !== props.data.secondaryWorkingStep && (
        <LocalizedWorkingStepLabel
          workingStepType={props.data.secondaryWorkingStep}
          variant="caption"
          component="p"
          style={{ fontWeight: 'bold' }}
        />
      )}
      {props.data.sharedCost && (
        <Typography variant="caption" color="textSecondary">
          {`(${t('project:shared-cost', 'shared cost')})`}
        </Typography>
      )}
      <div style={{ textAlign: 'right' }}>
        <TargetPriceTooltipListItemText
          secondary={TimeString(props.data.productionTime)}
          primary={t('project:production-time', 'production time')}
        />

        {props.data.materialSalesPrice && (
          <TargetPriceTooltipListItemText
            secondary={MoneyString(props.data.materialSalesPrice)}
            primary={
              <LocalizedTypography
                translationKey="project:material"
                variant="caption"
              />
            }
          />
        )}

        {props.data.loading && (
          <TargetPriceTooltipListItemText
            secondary={MoneyString(props.data.loading)}
            primary={
              <LocalizedWorkingStepLabel
                workingStepType={WorkingStepType.Loading}
                variant="caption"
              />
            }
          />
        )}

        {props.data.setup && (
          <TargetPriceTooltipListItemText
            secondary={MoneyString(props.data.setup)}
            primary={
              <LocalizedWorkingStepLabel
                workingStepType={WorkingStepType.Setup}
                variant="caption"
              />
            }
          />
        )}
        <TargetPriceTooltipListItemText
          secondary={MoneyString(props.data.totalCostPrice) || <WarningIcon />}
          primary={t('project:cost-price')}
        />
        {props.data.unloading && (
          <TargetPriceTooltipListItemText
            secondary={MoneyString(props.data.unloading)}
            primary={
              <LocalizedWorkingStepLabel
                workingStepType={WorkingStepType.Unloading}
                variant="caption"
              />
            }
          />
        )}
        <TargetPriceTooltipListItemText
          secondary={`${MoneyString(props.data.surchargeValue)}`}
          primary={t('common:surcharge')}
        />
        <TargetPriceTooltipListItemText
          secondary={`${MoneyString(props.data.discountValue)}`}
          primary={t('common:discount')}
          color="secondary"
        />
        <Divider style={{ margin: '0 -1rem' }} />
        <TargetPriceTooltipListItemText
          secondary={MoneyString(props.data.totalSalesPrice)}
          primary={t('project:sales-price')}
        />
      </div>
    </div>
  )
}
