import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material'
import { NumberFormatCustom } from 'components/Common/NumberFormatInputComponent'
import { projectOperationSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormatProps } from 'react-number-format'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { Key } from 'utils/keyCodes'

type Props = {
  /**
   * selector to get the value ((state: RootAppState) => (string | number) | Money | Quantity)
   */
  valueSelector?: (state: RootAppState) => (string | number) | MoneyDto
  /**
   * a function created with createAsyncFunc
   */
  saveAction
  /**
   * if a value is provided, it will be used as the default value (will not go to redux store)
   */
  value?: (string | number) | MoneyDto
  textFieldProps?: TextFieldProps
  actionTypePrefix?: string
  validation?: (value: string | number) => {
    isValid: boolean
    errorMessage?: string
  }
  disableShowHelpText?: boolean
  money?: boolean
  textAlign?: 'left' | 'center' | 'right'
  allowNegative?: boolean
  helperTextFunc?: (value: string) => string
  disableShowTooltipForLongText?: boolean
}

/**
 * given a redux selector or a value (but not both) and an async save action
 * this component should be able to be used as a TextField
 * supporting money and quantity
 * @param props
 * @returns
 */
export const ProjectTextField = (props: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const valueFromStore =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    props.value || useAppSelector(props.valueSelector, isEqual)
  const operationStatus = useAppSelector(
    projectOperationSelector(
      props.saveAction['typePrefix'] || props.actionTypePrefix
    )
  )

  const isEditing = useRef(false)
  const [value, setValue] = useState(undefined)
  const [validation, setValidation] = useState<{
    isValid: boolean
    errorMessage?: string
  }>(undefined)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  useEffect(() => {
    if (!validation?.isValid === false) {
      setTooltipOpen(true)
    }
  }, [validation])

  const _setValue = (value: null | string | number | MoneyDto) => {
    if (
      Boolean(value) &&
      typeof value === 'object' &&
      Object.keys(value || {}).includes('value')
    ) {
      setValue(value.value)
    } else {
      setValue(value)
    }
  }

  useEffect(() => {
    if (!isEditing.current) {
      _setValue(valueFromStore)
    }

    return () => {
      isEditing.current = false
    }
  }, [valueFromStore])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isEditing.current = true
    setValue(event.target.value)
    if (props.validation) {
      setValidation(props.validation(event.target.value))
    }
  }

  const fieldIsRequiredAndHasValue = () => {
    if (props.textFieldProps?.required) {
      return !!value
    }

    return true
  }

  const handleSave = () => {
    let valueIsEqual = false

    if (
      Boolean(valueFromStore) &&
      typeof valueFromStore === 'object' &&
      'value' in valueFromStore
    ) {
      // Quantity or Money
      valueIsEqual =
        parseFloat(valueFromStore.value?.toString()) ===
        parseFloat(value?.toString())
    } else {
      valueIsEqual = isEqual(valueFromStore, value)
    }

    if (
      fieldIsRequiredAndHasValue() &&
      (validation === undefined || validation.isValid === true) &&
      !valueIsEqual
    ) {
      if (valueFromStore !== null && props?.money) {
        dispatch(
          props.saveAction({
            ...(valueFromStore as MoneyDto),
            value: parseFloat(value || 0),
          } as MoneyDto)
        )
      } else {
        dispatch(props.saveAction(value))
      }
      isEditing.current = false
    }
  }

  const saveOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (!props.textFieldProps?.multiline && e.key === Key.Enter) ||
      (props.textFieldProps?.multiline &&
        e.key === Key.Enter &&
        (e.ctrlKey || e.metaKey))
    ) {
      handleSave()
    }
    if (e.key === Key.Escape) {
      _setValue(valueFromStore)
      setValidation(undefined)
      isEditing.current = false
    }
  }

  const lastStatusShown = useRef(undefined)
  useEffect(() => {
    if (
      lastStatusShown.current !== operationStatus?.requestId &&
      operationStatus?.requestStatus === 'rejected'
    ) {
      ShowException('project', operationStatus.error)
      lastStatusShown.current = operationStatus.requestId
    }
  }, [operationStatus])

  const moneyInputProps = props?.money && {
    inputComponent: NumberFormatCustom as never,
    inputProps: {
      style: { textAlign: props.textAlign || 'right' },
      allowNegative: props.allowNegative || false,
      allowLeadingZeros: false,
    } as NumericFormatProps,
  }

  const EndAdornment = useCallback(() => {
    if (
      operationStatus?.requestStatus === 'pending' &&
      valueFromStore &&
      !(valueFromStore['unit'] || valueFromStore['symbol'])
    ) {
      return (
        <InputAdornment position="end">
          <CircularProgress
            size={12}
            style={{ position: 'absolute', right: '.75em' }}
          />
        </InputAdornment>
      )
    }
    if (valueFromStore && valueFromStore['unit']) {
      return (
        <InputAdornment position="end">{valueFromStore['unit']}</InputAdornment>
      )
    }
    if (props.textFieldProps?.InputProps?.endAdornment) {
      return <>{props.textFieldProps.InputProps.endAdornment}</>
    }

    return null
  }, [
    operationStatus?.requestStatus,
    props.textFieldProps?.InputProps?.endAdornment,
    valueFromStore,
  ])

  const StartAdornment = useCallback(() => {
    if (
      operationStatus?.requestStatus === 'pending' &&
      valueFromStore &&
      (valueFromStore['unit'] || valueFromStore['symbol'])
    ) {
      return (
        <InputAdornment position="start">
          <CircularProgress
            size={12}
            // style={{ position: 'absolute', right: '.75em' }}
          />
        </InputAdornment>
      )
    }
    if (valueFromStore && valueFromStore['symbol']) {
      return (
        <InputAdornment position="start">
          {valueFromStore['symbol']}
        </InputAdornment>
      )
    }

    return null
  }, [operationStatus?.requestStatus, valueFromStore])

  const helperText =
    (props.textFieldProps?.required && !value && t('common:required')) ||
    validation?.errorMessage // ||

  const inputRef = React.useRef<HTMLInputElement>(null)
  useEffect(() => {
    const wheelHandler = (e: WheelEvent) => {
      e.preventDefault()
    }

    if (props.textFieldProps?.type === 'number') {
      const input = inputRef?.current
      if (input) {
        input.addEventListener('wheel', wheelHandler)
      }

      return () => {
        input?.removeEventListener('wheel', wheelHandler)
      }
    }

    return () => null
  }, [props.textFieldProps.type])

  return (
    <Tooltip
      title={
        Boolean(helperText)
          ? helperText
          : value?.toString().length > 25 &&
            !props.disableShowTooltipForLongText
          ? value
          : ''
      }
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      // onClose={() => setTooltipOpen(false)}
      arrow
    >
      <TextField
        {...(props.textFieldProps || {})}
        autoComplete="new-password"
        title={undefined}
        ref={inputRef}
        value={value ?? ''}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onBlur={handleSave}
        onFocus={(e) => e.target.select()}
        onChange={handleChange}
        onKeyDown={saveOnEnter}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => {
          if (!validation || validation.isValid) {
            setTooltipOpen(false)
          }
        }}
        // helperText={validation?.errorMessage} // || props.disableShowHelpText ? null : helperText}
        helperText={props.helperTextFunc?.(value)}
        error={
          (props.textFieldProps?.required && !value) ||
          validation?.isValid === false
        }
        InputProps={{
          ...(props.textFieldProps?.InputProps || {}),
          endAdornment: <EndAdornment />,
          startAdornment: <StartAdornment />,
          ...moneyInputProps,
        }}
        // inputProps={{
        //   ref: inputRef,
        // }}
      />
    </Tooltip>
  )
}
