import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemDetailsDrawerProps } from 'features/BomItemDetailsDrawer/BomItemDetailsDrawer'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import { ToggleNewItemsModal } from 'store/Application/appActions'
import { ModalTypes } from 'store/UI/UITypes'
import {
  AppDispatch,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { BomItemPointer } from '../../../model/Project/BomItemPointer'
import { newProjectActions } from '../store/projectReducer'

function openBomItemDetails(
  isBuyerView: boolean,
  dispatch: AppDispatch,
  openDialog: (
    arg0: ModalTypes,
    arg1: BomItemDetailsDrawerProps,
    sameParametersClosesTheModal: boolean
  ) => void,
  bomItemPointer: BomItemPointer,
  includeBomItemInSearch: boolean,
  detailTabIndex: number
) {
  if (isBuyerView) {
    return null
  }

  dispatch(ToggleNewItemsModal(false))
  openDialog(
    'BomItemDetailsDrawer',
    {
      bomItemPointer: bomItemPointer,
      detailTabIndex: detailTabIndex,
    } as BomItemDetailsDrawerProps,
    true
  )

  if (includeBomItemInSearch) {
    dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))
    dispatch(
      newProjectActions.setBoMItemOpen({
        bomItemPointer,
        isOpen: true,
      })
    )
  }
}

const openBomItemDetailsDebounced = debounce(openBomItemDetails, 200, {
  leading: true,
  trailing: false,
})

export const useBomItemDetails = () => {
  const { openDialog } = useDialogManager()
  const dispatch = useAppDispatch()

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const handleOpenBomItemDetails = useCallback(
    (
      bomItemPointer: BomItemPointer,
      includeBomItemInSearch: boolean,
      detailTabIndex?: number
    ) =>
      openBomItemDetailsDebounced(
        isBuyerView,
        dispatch,
        openDialog,
        bomItemPointer,
        includeBomItemInSearch,
        detailTabIndex
      ),
    [dispatch, isBuyerView, openDialog]
  )

  return {
    handleOpenBomItemDetails,
    isBuyerView,
  }
}
