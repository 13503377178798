export type PersonInfo = {
  firstName?: string
  lastName?: string
  email?: string
}

export function formatName(personInfo: PersonInfo) {
  if (!personInfo) {
    return ''
  }

  return `${personInfo?.firstName || ''} ${personInfo?.lastName || ''}`.trim()
}
