import { saveProjectBatchSize } from 'features/BillOfMaterials/store/asyncActions/saveProjectBatchSize'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { projectQuantitySelector } from 'features/BillOfMaterials/store/selectors/projectQuantitySelector'
import { valueGreaterThanZero } from 'features/BillOfMaterials/Validations/valueGreaterThanZero'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'

export function ProjectBatchSizeInput(props: { textFieldClassName?: string }) {
  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)
  const { t } = useTranslation()

  return (
    <ProjectTextField
      valueSelector={projectQuantitySelector}
      saveAction={saveProjectBatchSize}
      disableShowHelpText
      textFieldProps={{
        label: t('project:batch-size'),
        type: 'number',
        required: true,
        className: props.textFieldClassName,
        InputProps: {
          readOnly: !projectIsEditable,
          disableUnderline: !projectIsEditable,
        },
      }}
      validation={valueGreaterThanZero}
    />
  )
}
