import { Typography, TypographyProps } from '@mui/material'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useLocalizedWorkingStepLabel } from './useLocalizedWorkingStepLabel'

type Props = TypographyProps<'p' | 'span'> & {
  workingStepType: WorkingStepType
  component?: string
}

export const LocalizedWorkingStepLabel = (props: Props) => {
  const { workingStepType, ...typographyProps } = props

  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  return (
    <Typography {...(typographyProps || {})}>
      {getLocalizedWorkingStep(workingStepType)}
    </Typography>
  )
}
