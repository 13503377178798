import { FormControlLabel, Switch, Typography } from '@mui/material'

type Props = {
  show2dModel: boolean
  setShow2dModel: (show: boolean) => void
  show3dModel: boolean
  setShow3dModel: (show: boolean) => void
}

export const ModelVisibilitySwitches = (props: Props) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={props.show3dModel}
            onChange={(e) => {
              if (!e.target.checked && !props.show2dModel) {
                props.setShow2dModel(true)
              }

              props.setShow3dModel(e.target.checked)
            }}
            size="small"
          />
        }
        label={
          <Typography variant="caption" color="GrayText">
            3D
          </Typography>
        }
        labelPlacement="top"
      />
      <FormControlLabel
        control={
          <Switch
            checked={props.show2dModel}
            onChange={(e) => {
              if (!e.target.checked && !props.show3dModel) {
                props.setShow3dModel(true)
              }

              props.setShow2dModel(e.target.checked)
            }}
            size="small"
          />
        }
        label={
          <Typography variant="caption" color="GrayText">
            2D
          </Typography>
        }
        labelPlacement="top"
      />
    </div>
  )
}
