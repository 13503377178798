import { useAuth0 } from '@auth0/auth0-react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClearOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { WorkingStepIconWithDescription } from 'components/Common/WorkingSteps/WorkingStepIconWithDescription'
import { LocalizedWorkingStepLabel } from 'components/Localization/LocalizedWorkingStepLabel'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { isEqual } from 'lodash'
import { matchSorter } from 'match-sorter'
import { Issue } from 'model/IssueContext'
import { WorkingStepTypeLabel } from 'model/WorkingStepType'
import { TextField } from 'mui-rff'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { IssuesAPI } from 'services/APIs/ExternalAPI/IssuesAPI'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  CloseUserFeedbackForm,
  ShowException,
  SuccessMessage,
} from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import './UserFeedback.scss'

const availableWorkingSteps = Array.from(WorkingStepTypeLabel.keys()).filter(
  (x) =>
    ![
      WorkingStepType.NotInitialized,
      WorkingStepType.Setup,
      WorkingStepType.Unloading,
      WorkingStepType.Loading,
    ].includes(x)
)

export const UserFeedbackForm = () => {
  const dispatch = useAppDispatch()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { user } = useAuth0()

  const isOpen = useAppSelector((state) => state.app.userFeedback?.show)
  const issue = useAppSelector(
    (state) => state.app.userFeedback?.issue,
    isEqual
  )

  const userPartyId = useAppSelector(
    (state: RootAppState) => state.user?.organizationContext?.id
  )

  const sellerPartyId = useAppSelector(
    (state: RootAppState) => state.project.activeProject?.sellingParty?.partyId
  )
  const buyerPartyId = useAppSelector(
    (state: RootAppState) => state.project.activeProject?.buyingParty?.partyId
  )

  const [isSaving, setIsSaving] = useState(false)

  if (!isOpen) return null

  const handleClose = () => {
    dispatch(CloseUserFeedbackForm())
  }

  const handleSend = async (issueToSend: Issue) => {
    const _issueToSend = {
      ...issueToSend,
      context: {
        ...issueToSend.context,
        auth0id: user.sub,
        rowId: issue.context?.bomItemPointer.id,
        sellerPartyId: sellerPartyId,
        buyerPartyId: buyerPartyId,
        organizationId: userPartyId,
      },
    }

    const issuesAPI = new IssuesAPI(userPartyId)
    try {
      const resp = await issuesAPI.CreateIssue(_issueToSend, (req) =>
        setIsSaving(req.isLoading)
      )

      console.info(`recognition issue created: ${resp.workitemid}`)

      SuccessMessage(
        'feedback',
        `thanks for your feedback. The issue #${resp.workitemid} will be reviewed by development team`
      )

      handleClose()
    } catch (err) {
      ShowException('user feedback', err)
    } finally {
      setIsSaving(false)
    }
  }

  const validateIssue = (data: Issue) => {
    const errors = {}

    if (!data.tags?.length) {
      errors['tags'] = 'required'
    }

    return errors
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        report a recognition issue
      </DialogTitle>
      <DialogContent className="issueform--body">
        <Form
          initialValues={{ ...issue }}
          onSubmit={handleSend}
          validate={validateIssue}
          render={(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit} id="issue-form">
                <Field name="tags">
                  {(fieldProps) => (
                    <Autocomplete
                      multiple
                      options={availableWorkingSteps}
                      disableCloseOnSelect
                      autoHighlight
                      onChange={(ev, value) => {
                        if (typeof value === 'string') {
                          return fieldProps.input.value
                        }
                        fieldProps.input.onChange(value)
                        return value
                      }}
                      renderOption={(el, option) => {
                        return (
                          <ListItem component="span" {...el}>
                            <ListItemAvatar>
                              <WorkingStepIconSvg
                                workingStepType={option}
                                attributes={{ width: '2em' }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <LocalizedWorkingStepLabel
                                  workingStepType={option}
                                />
                              }
                            />
                          </ListItem>
                        )
                      }}
                      filterSelectedOptions
                      filterOptions={(options, state) => {
                        const withLocalization = options.map((x) => ({
                          workingStep: x,
                          localizedLabel: getLocalizedWorkingStep(x),
                        }))

                        const sorted = matchSorter(
                          withLocalization,
                          state.inputValue,
                          { keys: ['localizedLabel'] }
                        )

                        return sorted.map((x) => x.workingStep)
                      }}
                      renderTags={(values, tagProps) => {
                        return (
                          <>
                            {values.map((workingStepType, index) => {
                              const { onDelete, ...rest } = tagProps({
                                index,
                              })

                              return (
                                <div
                                  key={index}
                                  {...rest}
                                  style={{
                                    marginTop: '12px',
                                    position: 'relative',
                                    marginRight: '1em',
                                  }}
                                >
                                  <WorkingStepIconWithDescription
                                    key={workingStepType}
                                    workingStepType={workingStepType}
                                  />
                                  <IconButton
                                    onClick={onDelete}
                                    style={{
                                      position: 'absolute',
                                      top: '-.5em',
                                      right: '.5em',
                                      // width: '4px',
                                    }}
                                    size="small"
                                  >
                                    <ClearOutlined fontSize="small" />
                                  </IconButton>
                                </div>
                              )
                            })}
                          </>
                        )
                      }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          name="tags"
                          label="expected working steps"
                        />
                      )}
                    />
                  )}
                </Field>
                <TextField
                  name="message"
                  variant="filled"
                  multiline
                  rows={5}
                  disabled={isSaving}
                  placeholder="in a few words, what was the issue and what is the expected output?"
                  margin="normal"
                />
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <FontAwesomeIcon
                        icon={faChevronDown as IconProp}
                        size={'xs'}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="is-marginless"
                  >
                    <ListItem>
                      <ListItemText
                        className="data-summary is-paddingless"
                        primary="data"
                        secondary="data that will be shared with development team"
                      />
                    </ListItem>
                  </AccordionSummary>
                  <AccordionDetails className="is-marginless is-paddingless">
                    <pre>{JSON.stringify(formProps.values, null, 2)}</pre>
                  </AccordionDetails>
                </Accordion>
              </form>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          saveButtonFormId="issue-form"
          saving={isSaving}
          onCloseButtonClicked={handleClose}
        />
      </DialogActions>
    </Dialog>
  )
}
