import { PushPin, PushPinOutlined } from '@mui/icons-material'
import { Box, Card, IconButton, Typography, useTheme } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { useBarColorMap } from '../BarComponents'
import { FinancialDetailsData } from '../GetGraphData'
import AssemblyPartsCostFactorSection from './AssemblyPartsCostFactor'
import BomItemCostFactorSection from './BomItemCostFactorSection'
import { CostFactorSection } from './CostFactorSection'

type Props = {
  tooltipFixed: boolean
  setTooltipFixed: (value: boolean) => void
  bomItemType: BomItemType
} & TooltipProps<ValueType, NameType>

const PartTypeFinancialGraphTooltip = (props: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const data: FinancialDetailsData[] = props.payload.flatMap(
    (entry) => entry.payload
  )

  const barColorMap = useBarColorMap()

  useEffect(() => {
    // doing this to not close on escape key (bomItemDetailsDrawer check this flat to not close on escape key pressed)
    globalThis.isDirty = props.active

    return () => {
      globalThis.isDirty = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      sx={{
        pt: 1,
        pb: 1,
        // zIndex: 100,
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onMouseDown={() => {
        props.setTooltipFixed(true)
      }}
      onDoubleClick={() => {
        props.setTooltipFixed(false)
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '4px',
          right: '8px',
        }}
        id="financial-graph-tooltip"
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            props.setTooltipFixed(!props.tooltipFixed)
          }}
          title={
            "Fix tooltip\nWhen fixed, the tooltip won't disappear when you move the mouse away. Click again to unfix."
          }
          style={{
            transform: props.tooltipFixed
              ? 'none'
              : 'scale(.75) rotate(45deg) translate(0px, -12px)',
          }}
          color={props.tooltipFixed ? 'primary' : 'default'}
        >
          {props.tooltipFixed ? (
            <PushPin color={'primary'} />
          ) : (
            <PushPinOutlined />
          )}
        </IconButton>
      </div>

      {data[0] && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Box
            sx={{
              padding: '0 3em 0 1em',
            }}
          >
            <Typography variant="subtitle1" fontWeight={'bold'}>
              {props.label}
            </Typography>
            {props.label === 'total' ? null : (
              <>
                <Typography
                  variant="caption"
                  color="textGray"
                  whiteSpace={'pre-line'}
                  component="p"
                >
                  {data[0].costFactors.material?.description}
                </Typography>
                <Typography variant="caption" color="textGray">
                  {data[0].costFactors.material?.materialId}
                </Typography>
              </>
            )}
          </Box>

          <div>
            <CostFactorSection
              // title="totals"
              boldSalesPrice
              costFactor={{
                description: 'totals',
                costPrice: data[0].totals?.costPrice,
                surchargeValue: data[0].totals?.surchargeValue,
                discountValue: data[0].totals?.discountValue,
                salesPrice: data[0].totals?.salesPrice,
              }}
            />
            {/* divider */}
            <Box
              sx={{
                width: '100%',
                height: '4px',
                border: (theme) => `2px solid ${theme.palette.divider}`,
                margin: '.5em 0',
                borderLeft: 'none',
                borderRight: 'none',
              }}
            />
            {/* divider */}
            <AssemblyPartsCostFactorSection
              parts={data[0].costFactors.parts}
              legend={{
                color: barColorMap.parts,
                description:
                  props.bomItemType === BomItemType.project
                    ? t('project:assemblies', 'assemblies')
                    : t('project:parts', 'parts'),
                descriptionColor: theme.palette.getContrastText(
                  barColorMap.parts
                ),
              }}
            />
            <BomItemCostFactorSection costFactors={data[0].costFactors} />
          </div>
        </div>
      )}
    </Card>
  )
}

export default memo(PartTypeFinancialGraphTooltip, isEqual)
