import { isEqual } from 'lodash'
import { useCallback, useRef } from 'react'
import { UIActions } from 'store/UI/UIActions'
import { ModalTypeProps, ModalTypes } from 'store/UI/UITypes'
import { useAppDispatch } from 'store/configureStore'
import { openedModalsStack } from './openedModalsStack'

export const useDialogManager = () => {
  const dispatch = useAppDispatch()

  const currentProps = useRef<unknown | undefined>()

  const openDialog = useCallback(
    (
      modalType: ModalTypes,
      modalProps?: ModalTypeProps,
      sameParametersClosesTheModal?: boolean
    ) => {
      if (
        sameParametersClosesTheModal &&
        modalProps &&
        isEqual(currentProps.current, modalProps)
      ) {
        dispatch(UIActions.CloseModal())
        currentProps.current = undefined
      } else {
        dispatch(UIActions.OpenModal(modalType, modalProps))
        currentProps.current = modalProps
      }
    },
    [dispatch]
  )

  const closeDialog = useCallback(
    (clearStack?: boolean) => {
      dispatch(UIActions.CloseModal())

      if (clearStack) {
        openedModalsStack.length = 0
      }
    },
    [dispatch]
  )

  return {
    openDialog,
    closeDialog,
  }
}
