import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import { CancelButton } from 'components/Common/Button/CancelButton'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import _ from 'lodash'
import { MoneyPerQuantityString } from 'model/MoneyPerQuantity'
import { MaterialHeaderRow } from 'model/Project/BoMItemRow'
import { QuantityString } from 'model/Quantity'
import { useState } from 'react'
import projectImageUrl from 'services/APIs/InternalAPI/ProjectImages'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'

export type CostPriceDetailsProps = {
  open?: boolean
  onClose?: () => void
  selectedPriceSummaryKey?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(5),
    },
  },
  detailsTable: {
    '& th, td': {
      textAlign: 'center',
    },
  },
  pricesAndDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cuttingPlans: {
    overflowY: 'auto',
    maxWidth: theme.spacing(80),
    maxHeight: '250px',
    padding: '1em 2em',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
  },
}))

/**
 * Cost Price Dialog -> Should show details about the cost price of a cost factor
 * should also show the staggered price applied to the cost factor
 */
export const CostPriceDetailsDialog = (props: CostPriceDetailsProps) => {
  const { classes } = useStyles()

  const [selectedPriceSummaryKey] = useState(props.selectedPriceSummaryKey)

  // const allPriceSummariesKeys = useAppSelector((state: RootAppState) => {
  //   return Object.keys(state.project?.priceSummaries || {}).filter(
  //     (k) => state.project?.priceSummaries[k]?.isMaterial
  //   )
  // })

  const selectedPriceSummary = useAppSelector(
    (state: RootAppState) =>
      state.project?.priceSummaries &&
      state.project?.priceSummaries[selectedPriceSummaryKey],
    _.isEqual
  )

  const { partyId, projectId } = useOrganizationAndProjectContext()

  // const selectNextCostPriceSummary = () => {
  //   const nextIndex = allPriceSummariesKeys.indexOf(selectedPriceSummaryKey) + 1
  //   const nextKey = allPriceSummariesKeys[nextIndex]
  //   setSelectedPriceSummaryKey(nextKey ?? allPriceSummariesKeys[0])
  // }

  // const selectPrevCostPriceSummary = () => {
  //   const nextIndex = allPriceSummariesKeys.indexOf(selectedPriceSummaryKey) - 1
  //   const nextKey =
  //     allPriceSummariesKeys[
  //       nextIndex < 0 ? allPriceSummariesKeys.length - 1 : nextIndex
  //     ]
  //   setSelectedPriceSummaryKey(nextKey)
  // }

  // const affectedBomItems = useAppSelector((state: RootAppState) => {
  //   if (selectedPriceSummary?.isWorkingStep) {
  //     return Object.values(state.newProjectReducer.flatView).filter(
  //       (x) =>
  //         (x.type === BoMItemType.assemblyHeader &&
  //           x.assembly.assemblyWorkingStepDtos.some(
  //             (y) =>
  //               y.resource?.id ===
  //               selectedPriceSummary.workingStep?.resource?.id
  //           )) ||
  //         (x.type === BoMItemType.partType &&
  //           x.workingSteps.some(
  //             (y) =>
  //               y.resource?.id ===
  //               selectedPriceSummary.workingStep?.resource?.id
  //           ))
  //     )
  //   } else if (selectedPriceSummary?.isMaterial) {
  //     return Object.values(state.newProjectReducer.flatView).filter((x) => {
  //       if (x.type === BoMItemType.partType) {
  //         return x.materialSummary.id === selectedPriceSummary.materialId
  //       } else return null
  //     })
  //   } else {
  //     return null
  //   }
  // })

  const cuttingPlan: MaterialHeaderRow = useAppSelector(
    (state: RootAppState) => {
      if (selectedPriceSummary?.isMaterial) {
        return state.project.materialHeaders[selectedPriceSummary.materialId]
      }
      return null
    }
  )

  if (!selectedPriceSummary) {
    return null
  }

  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => reason !== 'backdropClick' && props.onClose()}
      aria-labelledby="cost-price-details-dialog-title"
      maxWidth="lg"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="cost-price-details-dialog-title">
        Cost Price Details
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedPriceSummary.description}
        </DialogContentText>
        <div className={classes.root}>
          <div className={classes.pricesAndDescription}>
            <div>
              <Table className={classes.detailsTable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>rate</TableCell>
                    <TableCell>weight</TableCell>
                    <TableCell>purchasing unit</TableCell>
                    <TableCell>raw material purchasing</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {MoneyPerQuantityString(
                        selectedPriceSummary.staggeredCostPrices?.find(
                          (x) => x.selected
                        )?.price
                      )}
                    </TableCell>
                    <TableCell>
                      {QuantityString(selectedPriceSummary.weight) || '?'}
                    </TableCell>
                    <TableCell>
                      {QuantityString(selectedPriceSummary.staggeredQuantity) ||
                        '?'}
                    </TableCell>
                    <TableCell>
                      {QuantityString(
                        selectedPriceSummary.staggeredTotalQuantity
                      ) || ''}
                      {cuttingPlan?.cuttingPlans ? (
                        <Typography variant="caption" component="p">
                          {` (${cuttingPlan?.cuttingPlans?.reduce(
                            (acc, c) => acc + c.quantity,
                            0
                          )} items)`}
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {cuttingPlan?.cuttingPlans && (
              <div className={classes.cuttingPlans}>
                {cuttingPlan.cuttingPlans?.map((cuttingPlan) => (
                  <Badge
                    key={cuttingPlan.id}
                    badgeContent={cuttingPlan.quantity}
                    color="primary"
                    style={{
                      maxWidth: '100%',
                      display: 'inline-block',
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    max={cuttingPlan.quantity}
                  >
                    <img
                      // key={cuttingPlan.id}
                      // src={`data:image/jpeg;base64, ${cuttingPlan.thumbnail}`}
                      src={projectImageUrl(
                        partyId,
                        projectId,
                        cuttingPlan.fileNameOfNestingImage
                      )}
                      alt={`cutting plan ${cuttingPlan.id}`}
                      loading="lazy"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '136px',
                      }}
                    />
                  </Badge>
                ))}
              </div>
            )}
            {selectedPriceSummary?.isWorkingStep && (
              <div style={{ textAlign: 'center' }}>
                <WorkingStepIconSvg
                  workingStepType={
                    selectedPriceSummary.workingStep.primaryWorkingStep
                  }
                  attributes={{
                    width: '100px',
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  {selectedPriceSummary.workingStep.resource?.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  ({selectedPriceSummary.workingStep.secondaryWorkingStep})
                </Typography>
              </div>
            )}
          </div>
          <div>
            {selectedPriceSummary.staggeredCostPrices && (
              <>
                {/* <ScaledPricesViewer
                  usedQuantity={selectedPriceSummary.staggeredTotalQuantity}
                  scaledPrices={selectedPriceSummary.staggeredCostPrices}
                />

                <ScaledCostPriceTable
                  scaledPrices={selectedPriceSummary.staggeredCostPrices}
                /> */}
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={() => props.onClose()}
          labelTranslationKey="common:close"
        />
      </DialogActions>
    </Dialog>
  )
}
