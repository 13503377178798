import React from 'react'
import { NumericFormat } from 'react-number-format'

interface NumberFormatCustomProps {
  // inputRef: (instance: NumberFormat<string | number> | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      // getInputRef={inputRef}
      getInputRef={ref}
      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.source === 'event') {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
}

const NumberInput = React.forwardRef(NumberFormatCustom)

export { NumberInput as NumberFormatCustom }
