import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
// import { projectDiscountOrSurchargeSelector } from 'features/BillOfMaterials/store/selectors/projectDiscountOrSurchargeSelector'
import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useTranslation } from 'react-i18next'

// export function ProjectDiscountOrSurcharge() {
//   const { t } = useTranslation()
//   return (
//     <ValueLabelConnected
//       propertySelector={projectDiscountOrSurchargeSelector}
//       valueLabelProps={{
//         label: t('project:discount-surcharge'),
//       }}
//     />
//   )
// }

export function ProjectSurchargeRatio(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()

  return (
    <ValueLabelConnected
      propertySelector={projectSelectors.projectSurchargeRatioSelector}
      valueLabelProps={{
        label: t('common:surcharge', 'surcharge'),
        emptyString: '-',
        classes: props.valueLabelClasses,
      }}
    />
  )
}

export function ProjectDiscountRatio(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()

  return (
    <ValueLabelConnected
      propertySelector={projectSelectors.projectDiscountSelector}
      valueLabelProps={{
        label: t('common:discount', 'discount'),
        emptyString: '-',
        classes: props.valueLabelClasses,
      }}
    />
  )
}
