import { Typography } from '@mui/material'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import { PriceRowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
  useFinancialDetailsFormStyles,
} from './FinancialDetailsForm.styles'

type Props = {
  totalRow: PriceRowDto
  setActiveCostPriceExplanation: (priceRow: PriceRowDto) => void
}
export function PriceDetailsTotalRow(props: Props) {
  const { classes } = useFinancialDetailsFormStyles()
  return (
    <li className={classes.totalRow} >
      <div className={classes.values} style={{ alignItems: 'stretch' }}>
        <ValueLabel
          label={'prod time'}
          labelTranslationKey={'project:prod-time'}
          value={TimeString(props.totalRow?.productionTime, true)}
          valueLabelRootStyles={{
            // whiteSpace: 'pre-line',
            width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
          }}
        />
        <ValueLabel
          label={'costs [prod time]'}
          labelTranslationKey={'project:costs-prod'}
          value={MoneyString(props.totalRow?.productionTimeCosts)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
          }}
        />
        <ValueLabel
          label={'costs [additional]'}
          labelTranslationKey={'project:costs-additional'}
          value={MoneyString(props.totalRow?.additionalProductionCosts)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
          }}
        />
        <ValueLabel
          label={'costs [total]'}
          labelTranslationKey={'project:costs-total'}
          value={MoneyString(props.totalRow?.costPrice)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
          }}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ValueLabel
            fullWidth
            label={'surcharge'}
            labelTranslationKey={'common:surcharge'}
            value={MoneyString(props.totalRow?.surchargeValue)}
            valueLabelRootStyles={{
              whiteSpace: 'pre-line',
              width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
            }}
          />
          <Typography
            color="textSecondary"
            variant="caption"
            style={{ position: 'absolute', bottom: '-1.25em', right: 0 }}
          >
            {QuantityString(props.totalRow?.surchargeRatio)}
          </Typography>
        </div>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ValueLabel
            fullWidth
            label={'discount'}
            labelTranslationKey={'common:discount'}
            value={MoneyString(props.totalRow?.discountValue)}
            valueLabelRootStyles={{
              whiteSpace: 'pre-line',
              width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
            }}
          />
          <Typography
            color="textSecondary"
            variant="caption"
            style={{ position: 'absolute', bottom: '-1.25em', right: 0 }}
          >
            {QuantityString(props.totalRow?.discountRatio)}
          </Typography>
        </div>

        <ValueLabel
          label={'sales price'}
          labelTranslationKey={'project:sales-price'}
          value={MoneyString(props.totalRow?.salesPrice)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
          }}
        />
      </div>
    </li>
  )
}
