import { Timeline } from '@mui/lab'
import { isEqual } from 'lodash'
import React from 'react'
import { useAppSelector } from 'store/configureStore'
import { ProjectOperationDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { AppState } from 'store'
import GeometryLogItem from './GeometryLogItem'

type Props = {
  geometryLogs: ProjectOperationDto[]
}

const GeometriesLogTimeline = (props: Props) => {
  const assemblyIds = useAppSelector((state: AppState) => {
    return state.project.assemblyHeadersIds
  }, isEqual)

  if (!props.geometryLogs) {
    return null
  }

  // const getItemSize = (index: number) =>
  //   Math.floor(props.geometryLogs[index]?.children.length ?? 0 / 10) * 2 + 72

  return (
    <Timeline
      position="left"
      style={{ width: '100%', minHeight: 'calc(100vh - 294px)' }}
    >
      {props.geometryLogs.map((geometryLog) => (
        <GeometryLogItem
          key={geometryLog.id}
          geometryLog={geometryLog}
          assemblyIds={assemblyIds}
        />
      ))}
    </Timeline>
  )
}

export default React.memo(GeometriesLogTimeline, isEqual)
