import { ButtonGroup } from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { SearchType } from 'controllers/Search/SearchBomItemsController'
import React from 'react'
import { FacetList } from './FacetList'

export const FacetExplanation = (props: {
  facets: { value: string; count: number }[]
  searchText: string
  searchType: SearchType
  setSearchType: (searchType: SearchType) => void
  search: (searchText: string, searchType: SearchType) => void
}) => {
  return props.facets ? (
    <FacetList
      facets={props.facets}
      searchText={props.searchText}
      onFilter={(filterValue) => {
        props.setSearchType('filter')

        let filter = ''

        if (props.searchText.startsWith('LastUpdated')) {
          filter = `LastUpdated ge ${filterValue} and search.ismatch('Project', 'Type')`
        } else {
          filter = `${props.searchText} eq '${filterValue}'`
          if (props.searchText.startsWith('Article')) {
            filter += ` and Type eq 'Part'`
          } else {
            filter += ` and Type eq 'Project'`
          }
        }
        setTimeout(() => {
          // props.setSearchText(filter);
          props.search(filter, 'filter')
        })
      }}
    />
  ) : (
    <>
      <LocalizedTypography translationKey="search:group-by" variant="caption">
        group by:
      </LocalizedTypography>
      <ButtonGroup variant="text" style={{ justifyContent: 'flex-start' }}>
        <LocalizedButton
          translationKey="search:group-by-month"
          onClick={() => {
            props.search('LastUpdated,interval:month', props.searchType)
          }}
        />
        <LocalizedButton
          translationKey="search:group-by-article-number"
          onClick={() => {
            props.search('ArticleNumber', props.searchType)
          }}
        />
        <LocalizedButton
          translationKey="search:group-by-selling-party"
          onClick={() => {
            props.search('SellingPartyName', props.searchType)
          }}
        />
        <LocalizedButton
          translationKey="search:group-by-buying-party"
          onClick={() => {
            props.search('BuyingPartyName', props.searchType)
          }}
        />
      </ButtonGroup>
    </>
  )
}
