import { ArticleSelectorDialogProps } from 'components/Common/ArticleSelector/ArticleSelectorDialog'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { MouseEventHandler, useCallback } from 'react'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'

export const useActivityArticleSelectorDialog = (
  bomItemPointer: BomItemPointer,
  workingStepType: WorkingStepType
) => {
  const { openDialog } = useDialogManager()
  const dispatch = useAppDispatch()

  const isPurchasingPart = useAppSelector(
    projectSelectors.bomItemIsPurchasingPartSelector(bomItemPointer)
  )

  const setArticleDialogOpen: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()

      dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))

      if (isPurchasingPart) {
        openDialog('PurchasingPartDialog', {
          bomItemPointer: bomItemPointer,
          isOpen: true,
        } as PurchasingPartDialogProps)
        return
      } else {
        dispatch(
          MaterialActions.initAndOpenArticleSelector(
            [bomItemPointer],
            workingStepType
          )
        )

        openDialog('ArticleSelector', {
          showSaveButton: true,
          isPurchasingPrice: false,
        } as ArticleSelectorDialogProps)
      }
    },
    [bomItemPointer, dispatch, isPurchasingPart, openDialog, workingStepType]
  )

  return {
    setArticleDialogOpen,
  }
}
