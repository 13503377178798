import { Box } from '@mui/material'
import { ObjectFieldTemplateProps } from '@rjsf/utils'

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
        gap: '2rem',
        padding: '0.5rem 0rem',
      }}
    >
      {props.description}
      {props.properties.map((element, index) => {
        return (
          <Box
            key={index}
            sx={{
              gridColumn:
                (element.content.props.schema.type === 'object' ||
                  element.content.props.uiSchema?.['ui:field'] ===
                    'zincCode') &&
                !element.content.props.schema.properties
                  ?.selectableAbbreviations
                  ? '1 / -1'
                  : undefined,
              width: '100%',
            }}
          >
            {element.content}
            {/* <JsonViewer data={element.content.props.uiSchema} /> */}
          </Box>
        )
      })}
      {/* </Box> */}
    </div>
  )
}
