import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useBomItemDetails } from 'features/BillOfMaterials/components/useBomItemDetails'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect } from 'react'
import { useAppSelector } from 'store/configureStore'
import { Key } from 'utils/keyCodes'
import { useBomItemDetailsDrawerStyles } from './BomItemDetailsDrawer.styles'

/**
 * this component manages the previous and next bom items arrows
 * for now is buggy and not used in the UI due development time spend on a feature not
 * used that much
 */

export const BomItemNextAndPreviousArrows = ({
  bomItemPointer,
}: {
  bomItemPointer: BomItemPointer
}) => {
  const { classes } = useBomItemDetailsDrawerStyles()

  const { previousBomItemPointer, nextBomItemPointer } = useAppSelector(
    projectSelectors.previousAndNextBomItemIdsSelector(bomItemPointer),
    isEqual
  )

  const { handleOpenBomItemDetails } = useBomItemDetails()

  const openNextBomItem = useCallback(() => {
    if (!nextBomItemPointer) {
      return
    }
    handleOpenBomItemDetails(nextBomItemPointer, true)
  }, [handleOpenBomItemDetails, nextBomItemPointer])

  const openPreviousBomItem = useCallback(() => {
    if (!previousBomItemPointer) {
      return
    }
    handleOpenBomItemDetails(previousBomItemPointer, true)
  }, [handleOpenBomItemDetails, previousBomItemPointer])

  const handleArrows = useCallback(
    (e) => {
      if (e.key === Key.ArrowDown) {
        e.preventDefault()
        e.stopPropagation()
        openNextBomItem()
      }

      if (e.key === Key.ArrowUp) {
        e.preventDefault()
        e.stopPropagation()
        openPreviousBomItem()
      }
    },
    [openNextBomItem, openPreviousBomItem]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleArrows)
    return () => {
      document.removeEventListener('keydown', handleArrows)
    }
  }, [handleArrows])

  return (
    <>
      <IconButton
        className={classes.previousItemButton}
        onClick={openPreviousBomItem}
        size="small"
        disabled={!previousBomItemPointer?.id}
        title={previousBomItemPointer?.id}
      >
        <ArrowUpwardOutlined />
      </IconButton>
      <IconButton
        className={classes.nextItemButton}
        onClick={openNextBomItem}
        size="small"
        disabled={!nextBomItemPointer?.id}
        title={nextBomItemPointer?.id}
      >
        <ArrowDownwardOutlined />
      </IconButton>
    </>
  )
}
