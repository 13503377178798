import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  ManuallySetProductionTimeArgs,
  PriceRowDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { PriceDetailsMachineRow } from './PriceDetailsMachineRow'
import { PriceDetailsMaterialrow } from './PriceDetailsMaterialRow'
import { PriceDetailsTotalRow } from './PriceDetailsTotalRow'
import { PriceDetailsWorkingStepRow } from './PriceDetailsWorkingStepRow'
import { priceRowIdentifier } from './priceRowIdentifier'

type Props = {
  priceRows: PriceRowDto[]
} & Omit<PriceRowProps, 'priceRow'>

export type PriceRowProps = {
  bomItemPointer: BomItemPointer
  priceRow: PriceRowDto
  setActiveCostPriceExplanation: (priceRow: PriceRowDto) => void
  priceScope: PriceScope
  handleResetAdditionalCosts: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleResetProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleSaveProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: ManuallySetProductionTimeArgs
  ) => Promise<void>
  handleSaveAdditionalCostPrice: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto,
    priceScope: PriceScope
  ) => Promise<void>
  loading: Record<string, boolean>
}

const PriceDetailsRow = (
  props: PriceRowProps & {
    expanded?: boolean
  }
) => {
  const priceRowType = priceRowIdentifier(props.priceRow)

  switch (priceRowType) {
    case 'machine': {
      return <PriceDetailsMachineRow {...props} expanded={props.expanded} />
    }

    case 'workingStep': {
      return (
        <PriceDetailsWorkingStepRow
          {...props}
          workingStepPricingRow={props.priceRow}
        />
      )
    }
    case 'material': {
      return <PriceDetailsMaterialrow {...props} materialRow={props.priceRow} />
    }
    case 'total': {
      return <PriceDetailsTotalRow {...props} totalRow={props.priceRow} />
    }
  }
}

export const PriceRowsRender = (props: Props & { expanded?: boolean }) => {
  return (
    <>
      {props.priceRows.map((priceRow, i) => {
        return <PriceDetailsRow key={i} {...props} priceRow={priceRow} />
      })}
    </>
  )
}
