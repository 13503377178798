import { Button, ButtonProps } from '@mui/material'
import ShowToRoleAndStates from 'components/Common/Portal/ShowToRole'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ShowNewProjectForm } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationContext } from 'utils/useOrganizationContext'
import { useNavbarButtonClasses } from './useNavbarButtonClasses'

export const NewProjectButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch()
  const { classes } = useNavbarButtonClasses()

  const { isBuyingParty } = useOrganizationContext()
  const { partyId } = useOrganizationContext()

  const hasActiveProject = useAppSelector(
    (state) => state.project.activeProject?.id !== undefined
  )

  const handleOpenNewProject = (isSellerRole: boolean) => {
    dispatch(ShowNewProjectForm(true, isSellerRole))
  }

  if (!partyId) {
    return null
  }

  return (
    <>
      <ShowToRoleAndStates seller isBuyerRole={isBuyingParty}>
        <Button
          onClick={() => handleOpenNewProject(true)}
          name="new-quotation-button"
          className={classes.buttonCallToAction}
          variant={hasActiveProject ? 'text' : 'contained'}
          color="success"
          {...props}
        >
          <LocalizedTypography
            translationKey={'project:new-project'}
            translationDefaultValue="new project"
          />
        </Button>
      </ShowToRoleAndStates>
      <ShowToRoleAndStates buyer isBuyerRole={isBuyingParty}>
        <Button
          {...props}
          onClick={() => handleOpenNewProject(false)}
          color="success"
          variant={hasActiveProject ? 'text' : 'contained'}
          name="new-quotation-request-button"
          className={classes.buttonCallToAction}
        >
          <LocalizedTypography
            translationKey={'project:new-quotation-request'}
            translationDefaultValue="new quotation request"
          />
        </Button>
      </ShowToRoleAndStates>
    </>
  )
}
