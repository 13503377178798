import { baseAddress } from './InternalAPI'

function projectImageUrl(
  partyId: string,
  projectId: string,
  filename: string
): string {
  return `${baseAddress}/api/parties/${partyId}/projects/${projectId}/images/${filename}`
}

export default projectImageUrl
