import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  KeywordDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemAllActivitiesSelector } from './allBomItemActivitiesSelector'

export const bomItemKeywordsPerWorkingStepSelector =
  (bomItemPointer: BomItemPointer, workingStepType: WorkingStepType) =>
  (state: RootAppState): Record<string, Array<KeywordDto>> | undefined => {
    const bomItemActivities =
      bomItemAllActivitiesSelector(bomItemPointer)(state)

    return bomItemActivities.find(
      (activity) => activity.primaryWorkingStep === workingStepType
    )?.keywords
  }
