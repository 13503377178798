import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { getDateFormatPattern } from 'utils/getDateFormatString'

export const usePartyDateFormat = () => {
  const { i18n } = useTranslation()

  const partyFormat = useAppSelector(
    (state) => state.user?.organizationContext?.dateFormat
  )

  if (!partyFormat) {
    const pattern = getDateFormatPattern(i18n.language)

    return pattern
  }

  return partyFormat
}
