import React from 'react'
import byteSize from 'byte-size'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { UploadSummaryProps } from './FileUploader'
import { DeleteOutlined } from '@mui/icons-material'

export const ExpandedSummary = (props: UploadSummaryProps) => {
  const { t } = useTranslation()

  if (!props.files || props.files.length === 0) {
    return null
  }

  return (
    <TableContainer style={{ maxHeight: '524px' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col">
              {t('common:file-name', 'file name')}
            </TableCell>
            <TableCell
              component="th"
              scope="col"
              style={{ textAlign: 'right' }}
            >
              {t('common:size')}
            </TableCell>
            <TableCell scope="col" align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.files.map((f, i) => (
            <TableRow key={i}>
              <TableCell>{f.name}</TableCell>
              <TableCell
                style={{
                  textAlign: 'right',
                }}
              >
                {byteSize(f.size).toString()}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'right',
                }}
              >
                <IconButton
                  color="secondary"
                  onClick={() => {
                    const newFiles = [...props.files]
                    newFiles.splice(i, 1)
                    props.setFiles(newFiles)
                    props.setIsClickEnabled(true)
                  }}
                  onMouseOver={() => props.setIsClickEnabled(false)}
                  onFocus={() => props.setIsClickEnabled(false)}
                  onMouseOut={() => props.setIsClickEnabled(true)}
                  onBlur={() => props.setIsClickEnabled(true)}
                  disabled={props.uploadProgress > 0}
                  size="small"
                >
                  <DeleteOutlined />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    // <table
    //   className="table table-sm table-striped"
    //   style={{
    //     textAlign: 'left',
    //     width: '100%',
    //     // maxHeight: '220px'
    //   }}
    // >
    //   <thead>
    //     <tr>
    //       <th scope="col"></th>
    //       <th
    //         scope="col"
    //         style={{
    //           textAlign: 'right',
    //         }}
    //       >
    //       </th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //   </tbody>
    // </table>
  )
}
