import { useMediaQuery, useTheme } from '@mui/material'

export const Hidden = (props: {
  children: React.ReactNode
  smDown?: boolean
  smUp?: boolean
  mdDown?: boolean
  mdUp?: boolean
  lgDown?: boolean
  lgUp?: boolean
}) => {
  const theme = useTheme()

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'))

  if (props.smDown && isSmDown) {
    return null
  }

  if (props.smUp && isSmUp) {
    return null
  }

  if (props.mdDown && isMdDown) {
    return null
  }

  if (props.mdUp && isMdUp) {
    return null
  }

  if (props.lgDown && isLgDown) {
    return null
  }

  if (props.lgUp && isLgUp) {
    return null
  }

  return <>{props.children}</>
}
