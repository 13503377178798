import { CheckOutlined, CloseOutlined } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { MouseEventHandler } from 'react'

export const SaveAndResetButtons = (props: {
  handleReset: MouseEventHandler<HTMLButtonElement>
  disabled: boolean
  saving: boolean
}) => {
  return (
    <>
      <IconButton type="reset" onClick={props.handleReset} size="small">
        <CloseOutlined />
      </IconButton>
      <IconButton
        type="submit"
        color={'primary'}
        size="small"
        disabled={props.disabled}
      >
        {props.saving ? <CircularProgress size={18} /> : <CheckOutlined />}
      </IconButton>
    </>
  )
}
