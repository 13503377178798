import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from '@mui/material'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { useEffect, useLayoutEffect, useRef } from 'react'
import ReduxToastr from 'react-redux-toastr'
import { SetDefaultTheme } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { BuiltInThemes, createAppTheme } from 'utils/appTheme'

import { useClientStorage } from 'customHooks/useClientStorage'
import { IntercomWidget } from 'features/SupportChat/Intercom/IntercomWidget'
import { useTranslation } from 'react-i18next'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { Key } from 'utils/keyCodes'

const ToastrStyles = () => {
  const theme = useTheme()

  return (
    <style>
      {`
        .qf-toastr {
          background-color: ${theme.palette.background.paper} !important;
          box-shadow: ${theme.shadows[1]};
          opacity: 1 !important;
        }
        .qf-toastr .rrt-holder {
          margin-top: unset !important;
          transform: translateY(-50%);
          height: unset !important;
          line-height: unset !important;
        }
        .qf-toastr .rrt-middle-container {
          font-family: ${theme.typography.fontFamily} !important;
        }
        .qf-toastr-error {
          background-color: ${theme.palette.error.main} !important;
          color: ${theme.palette.error.contrastText} !important;
        }
        .qf-tastr-error .rr-progress-container {
          background-color: ${theme.palette.error.dark}
        }
        .qf-toastr-success {
          background-color: ${theme.palette.success.main} !important;
          color: ${theme.palette.success.contrastText} !important;
        }
        .qf-tastr-success .rr-progress-container {
          background-color: ${theme.palette.success.dark}
        }
        .qf-toastr-info {
          background-color: ${theme.palette.info.main} !important;
          color: ${theme.palette.info.contrastText} !important;
        }
        .qf-tastr-info .rr-progress-container {
          background-color: ${theme.palette.info.dark}
        }
        .qf-toastr-warning {
          background-color: ${theme.palette.warning.main} !important;
          color: ${theme.palette.warning.contrastText} !important;
        }
        .qf-tastr-warning .rr-progress-container {
          background-color: ${theme.palette.warning.dark}
        }
      `}
    </style>
  )
}

const FontImporter = () => {
  const theme = useTheme()
  const shouldRun = useRef(true)

  useEffect(() => {
    if (shouldRun.current) {
      shouldRun.current = false
    }
  }, [])

  if (!shouldRun) {
    return null
  }

  return (
    <>
      {theme.googleFonts?.split(',').map((fontFamily) => (
        <style key={fontFamily}>
          {`@import url("https://fonts.googleapis.com/css2?family=${fontFamily.trim()}&display=swap")`}
        </style>
      ))}
    </>
  )
}

const IconsCSSColorsVariables = () => {
  const theme = useTheme()

  return (
    <style>
      {`:root {
          --icon-outline: ${theme.iconColors.outline};
          --icon-white: ${theme.iconColors.white};
          --icon-highlight: ${theme.iconColors.highlight};
        }`}
    </style>
  )
}

const CSSColorScheme = () => {
  const theme = useTheme()

  // background-color: ${theme.palette.warning.main}
  return (
    <style>
      {`body {
          color-scheme: ${theme.palette.mode};
          background-color: ${theme.palette.background.default};
        }

        mark {
          background-color: ${theme.palette.warning.main};
          color: ${theme.palette.warning.contrastText};
          padding: 0 .25ch 0 .25ch;
          display: inline-block;
          border-radius: 4px;
        }
      `}
    </style>
  )
}

const TextBoxNumberPreventWheelAndUpAndDown = () => {
  useEffect(() => {
    const ignoreWheel = (e: WheelEvent) => {
      if (e.target?.['type'] === 'number') {
        e.preventDefault()
      }
    }

    const ignoreArrowUpAndDown = (e: KeyboardEvent) => {
      if (e.target?.['type'] === 'number') {
        if (e.key === Key.ArrowDown || e.key === Key.ArrowUp) {
          e.preventDefault()
        }
      }
    }

    document.addEventListener('wheel', ignoreWheel, { passive: false })
    document.addEventListener('keydown', ignoreArrowUpAndDown)

    return () => {
      document.removeEventListener('wheel', ignoreWheel)
      document.removeEventListener('keydown', ignoreArrowUpAndDown)
    }
  }, [])
  return null
}

/**
 * when the theme changes, save the basic theme to the local storage, so we
 * can use it when reloading the app (to not have a flash of the default theme when user
 * reloads the app)
 * @returns null
 */
const ThemeStorage = () => {
  const theme = useTheme()

  const [, setBasicTheme] = useClientStorage(
    'rh24_basic_theme',
    null,
    'localStorage'
  )

  useEffect(() => {
    if (theme) {
      const basicTheme = {
        primaryColor: theme.palette.info.main,
        secondaryColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.default,
      }

      setBasicTheme(basicTheme)
    }
  }, [setBasicTheme, theme])

  return null
}

const Layout = ({ children }: { children: React.ReactNode }) => {
  const themeOptions = useAppSelector((state) => state.app.theme)
  const dispatch = useAppDispatch()
  const { withinIframe } = useAppRunningContext()
  const { i18n } = useTranslation()

  useLayoutEffect(() => {
    try {
      if (!themeOptions && !withinIframe) {
        dispatch(
          SetDefaultTheme(
            (localStorage.getItem('rh24BuiltInTheme') as BuiltInThemes) ||
              'light'
          )
        )
      }
    } catch (err) {
      console.error(err)
    }
  }, [dispatch, themeOptions, withinIframe])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createAppTheme(themeOptions, i18n.language)}>
        <CssBaseline enableColorScheme />
        <FontImporter />
        <IconsCSSColorsVariables />
        <CSSColorScheme />
        <ToastrStyles />
        <TextBoxNumberPreventWheelAndUpAndDown />
        <ThemeStorage />
        {children}
        <div tabIndex={-1} style={{ backgroundColor: '#e5e5e5' }}>
          <ReduxToastr
            preventDuplicates={true}
            position="top-left"
            className="qf-toastr"
          />
        </div>
        <IntercomWidget />
        {/* <CobrowseWidget /> */}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Layout
