export function showFieldErrors(meta: {
  submitError?
  dirtySinceLastSubmit?
  error?
  touched?
  modified?
}) {
  return !!(
    meta &&
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    (meta.touched || meta.modified)
  )
}
