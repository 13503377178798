import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import {
  IssueDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'

type Props = {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}

export const MaterialCostPriceIsZeroTodo = (props: Props) => {
  const { openDialog } = useDialogManager()
  const { getIssueDescription } = useBomItemIssueDescription()

  const projectId = useAppSelector(projectSelectors.projectIdSelector)

  const { handleChangeFilter } = useArticleIdFilter()

  const allActivities = useAppSelector(
    bomItemActivitiesSelectors.allActivities(props.bomItemPointer),
    isEqual
  )

  const activitiesWithArticleWithoutCostPrice = allActivities.filter(
    (activity) => {
      return (
        activity.supportsArticle &&
        !activity.article?.financial?.costPriceOfItems?.value
      )
    }
  )

  const isPurchasingPart = activitiesWithArticleWithoutCostPrice.some(
    (activity) => activity.primaryWorkingStep === WorkingStepType.Purchasing
  )

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={() => {
        if (isPurchasingPart) {
          openDialog('PurchasingPartDialog', {
            bomItemPointer: props.bomItemPointer,
          } as PurchasingPartDialogProps)
        } else {
          handleChangeFilter(
            activitiesWithArticleWithoutCostPrice.map((x) => x.article?.id)
          )
          openDialog('BomItemDetailsDrawer', {
            bomItemPointer: {
              id: projectId,
              type: BomItemType.project,
            },
          })
        }
      }}
      color="inherit"
      startIcon={<ChecklistOutlined />}
    >
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </Button>
  )
}
