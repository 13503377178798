import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
} from '@mui/material'
import { isEqual } from 'lodash'
import { MaterialHeaderRow } from 'model/Project/BoMItemRow'
import { MaterialHeaderPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { ImgLoader } from '../../../components/Common/ImgLoader/ImgLoader'
import { CuttingPlanDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { useOrganizationAndProjectContext } from '../../../utils/useOrganizationContext'
import { bomItemSelectorFunc } from '../store/selectors/bomItemSelector'
import './ActiveCuttingPlanDialog.scss'
import { CuttingPlanDetails } from './CuttingPlanDetails'

export type CuttingPlanDialogProps = {
  cuttingPlan: CuttingPlanDto
  materialHeaderPointer: MaterialHeaderPointer
  open?: boolean
  onClose?: () => void
}

export const CuttingPlanDialog = (props: CuttingPlanDialogProps) => {
  const { partyId: organizationId, projectId } =
    useOrganizationAndProjectContext()

  const materialHeader: MaterialHeaderRow = useAppSelector(
    bomItemSelectorFunc(props.materialHeaderPointer),
    isEqual
  ) as MaterialHeaderRow

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>cutting plans</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ListItemText
            primary={materialHeader.materialDescription}
            primaryTypographyProps={{
              variant: 'subtitle1',
              color: 'primary',
            }}
            secondary={`quantity: ${props.cuttingPlan.quantity}`}
          />
        </DialogContentText>
        <div className="cutting-plan-image">
          <ImgLoader
            src={`api/parties/${organizationId}/projects/${projectId}/nesting/2d/images/${props.cuttingPlan.fileNameOfNestingImage}`}
            alt="cutting plan"
          />
        </div>
      </DialogContent>
      {props.cuttingPlan.details ? (
        <CuttingPlanDetails details={props.cuttingPlan.details} />
      ) : null}

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => props.onClose()}
          color="secondary"
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
