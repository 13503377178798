import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useAppSelector } from 'store/configureStore'
import { useAppDispatch } from 'store/configureStore'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectStateSelectors } from 'store/Project/ProjectSelectors'

export const useWorkingStepFilter = () => {
  const dispatch = useAppDispatch()

  const workingStepFilter = useAppSelector(
    ProjectStateSelectors.WorkingStepsFilter,
    _.isEqual
  )

  const handleChangeFilter = (
    workingStepType?: WorkingStepType,
    override?: boolean
  ) => {
    let workingStepsToFilter = []

    if (override) {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'WorkingStepsFilter',
            enabledFilters: [workingStepType],
          },
        })
      )

      return
    }

    if (workingStepType === undefined) {
      workingStepsToFilter = Array.from(
        workingStepFilter.filter.availableFilters
      )
    } else if (workingStepType !== WorkingStepType.NotInitialized) {
      workingStepsToFilter = workingStepFilter?.filter.enabledFilters?.includes(
        workingStepType
      )
        ? workingStepFilter?.filter.enabledFilters?.filter(
            (x) => x !== workingStepType
          )
        : [
            ...(workingStepFilter?.filter?.enabledFilters || []),
            workingStepType,
          ]
    }

    if (workingStepsToFilter.length === 0) {
      dispatch(
        newProjectActions.toggleActiveFilter({
          filterType: 'WorkingStepFilter',
        })
      )
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'WorkingStepsFilter',
            enabledFilters: workingStepsToFilter,
          },
        })
      )
    }
  }

  const filterIsActive = (workingStepType: WorkingStepType) => {
    return (
      workingStepFilter.active &&
      workingStepFilter.filter.enabledFilters.some((x) => x === workingStepType)
    )
  }

  return {
    handleChangeFilter,
    workingStepFilter,
    filterIsActive,
  }
}
