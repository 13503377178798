/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { RemoveFiltersIcon } from 'features/BillOfMaterials/ProjectHeader/RemoveFiltersIcon'
import { TimeString } from 'model/TimeString'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { CostFactorLabel } from './CostFactorLabel'
import { useGraphsTooltipStyles } from './MarginGraphTooltip'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TimeGraphProps = {
  data: PriceSummaryDto[]
}

const CustomTooltip = (props: {
  active?: boolean
  payload?: any[]
  label?: string | number
}) => {
  const { classes } = useGraphsTooltipStyles()

  if (!props.payload || !props.payload.length) return null

  if (props.active) {
    return (
      <div className={classes.customTooltip}>
        <Typography variant="subtitle1">{props.label}</Typography>
        <div className={classes.usage}>
          <strong>{TimeString(props.payload[0].payload.productionTime)}</strong>
        </div>
      </div>
    )
  }
  return null
}

const TimeGraph = () => {
  const data = useAppSelector((state: RootAppState) =>
    Object.values(state.project.priceSummaries)
      .filter((x) => x?.isWorkingStep)
      .sort((a, b) => b.productionTime.value - a.productionTime.value)
  )

  const { handleChangeFilter } = useWorkingStepFilter()

  return (
    <div
      className="graph-container"
      style={{
        height: `${Object.values(data).length * 70}px`,
        minHeight: '220px',
        width: '100%',
        paddingRight: '32px',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: 0, left: '1em' }}>
        <RemoveFiltersIcon />
      </div>
      <ResponsiveContainer height={`90%`}>
        <BarChart
          data={data}
          margin={{ top: 16 }}
          layout="vertical"
          barCategoryGap={16}
          barGap={16}
          barSize={32}
          onClick={(next) =>
            next &&
            handleChangeFilter(
              data[next?.activeTooltipIndex]?.workingStep.primaryWorkingStep,
              true
            )
          }
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={(props) => <CustomTooltip {...props} />} />
          <Bar
            dataKey="productionTime.value"
            name="seconds"
            fill="#8884d8"
            height={20}
          />
          <YAxis
            dataKey="description"
            type="category"
            axisLine={false}
            width={240}
            tickMargin={32}
            tick={(props) => (
              <CostFactorLabel
                {...props}
                priceSummaries={Object.values(data)}
              />
            )}
            stroke={'2px solid black'}
            minTickGap={0}
          />
          <XAxis type="number" orientation="top" />
          <Legend
            verticalAlign="top"
            layout="vertical"
            align="center"
            height={36}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export { TimeGraph }
