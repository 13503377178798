import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react'
import { EmbeddedRh24MessageTypes } from 'features/EmbeddedRh24/EmbeddedRh24'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'

import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

export const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate()
  const { withinIframe } = useAppRunningContext()

  const onRedirectCallback = (appState?: AppState) => {
    console.info('onRedirectCallback', appState)

    if (withinIframe && appState?.tenantConfiguration) {
      // in some cases, the tenant configuration is not available.
      // the tenant configuration was sent as appState to auth0 and should be sent
      // to reload the configuration in embedded mode.
      // the timeout is to give some time to process the message

      window.postMessage({
        type: EmbeddedRh24MessageTypes.RH24_EMBEDDED_SETUP,
        payload: appState.tenantConfiguration,
      })
    }

    

    setTimeout(() => {
      navigate(
        appState?.returnUrl ||
          window.location.pathname + window.location.search,
        {
          state: { ...appState },
        }
      )
    }, 400)
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      {...props}
      cacheLocation={withinIframe ? 'localstorage' : 'memory'}
    >
      {children}
    </Auth0Provider>
  )
}
