/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, MenuItem, TextField, Tooltip } from '@mui/material'
import { WidgetProps } from '@rjsf/utils'
import { useTranslation } from 'react-i18next'
import { dashify } from 'utils/dashify'

export const SelectWidget = (props: WidgetProps) => {
  const { t } = useTranslation()
  const {
    rawErrors,
    uiSchema,
    readonly,
    autofocus,
    formContext,
    hideLabel,
    hideError,
    ...others
  } = props

  const label = uiSchema
    ? t(
        `ws-step-form-${dashify(
          uiSchema['ui:label-translation-key'] ||
            uiSchema['ui:label'] ||
            uiSchema['ui:title']
        )}`,
        {
          defaultValue:
            uiSchema['ui:label'] ||
            uiSchema['ui:title'] ||
            uiSchema['ui:label-translation-key'],
          ns: 'project',
        }
      )
    : null

  if (props.rawErrors?.length) {
    setTimeout(() => {
      props.formContext.onValidationError({
        [props.id]: {
          __errors: Boolean(props.rawErrors)
            ? [`${label}: ${props.rawErrors.join(' | ')}`]
            : undefined,
        },
      })
    })
  } else {
    props.formContext.onValidationError({
      [props.id]: {
        __errors: undefined,
      },
    })
  }

  const notInitialzedOrEmpty = !props.value || props.value === 'NotInitialized'
  let errors = rawErrors?.join(' ') ?? ''

  if (props.required && notInitialzedOrEmpty) {
    setTimeout(() => {
      props.formContext.onValidationError({
        [props.id]: {
          __errors: [
            `${label}: ${t('validation-required-field', { ns: 'common' })}`,
          ],
        },
      })
    })

    if (!errors) {
      errors = t('validation-required-field', { ns: 'common' })
    }
  }

  return (
    <Box className={'select-widget'}>
      <Tooltip
        title={notInitialzedOrEmpty ? '' : t(props.value, { ns: 'project' })}
        placement="top"
      >
        <TextField
          {...others}
          value={notInitialzedOrEmpty ? '' : props.value}
          InputProps={{
            readOnly: readonly,
          }}
          select
          label={label}
          variant="standard"
          onChange={(e) => {
            props.onChange(e.target.value)
          }}
          onBlur={(e) => props.onBlur(props.id, e.target.value)}
          onFocus={(e) => props.onFocus(props.id, e.target.value)}
          color="primary"
          error={
            Boolean(props.rawErrors?.length) ||
            (notInitialzedOrEmpty && props.required)
          }
          helperText={errors}
          required={props.required}
          fullWidth
        >
          {props.schema['x-enumNames']?.map((name: string | undefined) => {
            if (!name || name === 'NotInitialized') return null
            return (
              <MenuItem key={name} value={name}>
                {t(name, { defaultValue: name, ns: 'project' })}
              </MenuItem>
            )
          })}
        </TextField>
      </Tooltip>
      {/* <JsonViewer data={props} /> */}
    </Box>
  )
}
