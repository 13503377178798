import { MoreVertOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import {
  RemoveAllWorkingStepsNotAvailableInPortal,
  RemoveRowsByIssueCode,
  SolveAllIssuesByIssueCodeAsync,
} from 'features/BillOfMaterials/store/asyncActions/SolveIssueAsync'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import _, { isEqual } from 'lodash'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IssueCode,
  IssueDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { BoMItemFilterCount } from '../BoMItemFilterCounter/BoMItemFilterCounter'
import { useEnabledBoMFilters } from '../useEnabledBoMFilters'
import './IssuesFilterContainer.scss'

const groupIssues = (issues: IssueDto[], groupBy: keyof IssueDto) =>
  _.groupBy(issues, (x) => x[groupBy])

const selectIssueFilter = (state: RootAppState) => ({
  active: state.project.filters.byIssues.active,
  enabledFilters: state.project.filters.byIssues.filter.enabledFilters,
  availableFilters: state.project.filters.byIssues.filter.availableFilters,
})

const useStyles = makeStyles()((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    textTransform: 'lowercase',
    justifyContent: 'space-between',
  },
  title: {
    padding: theme.spacing(0, 0, 1, 2),
    width: '100%',
    display: 'block',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}))

const IssueItemMenu = (props: { issueCode: IssueCode; isBuyer: boolean }) => {
  let menuItems = []
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const menuActionHandler = (e, eventKey: string) => {
    e.preventDefault()
    e.stopPropagation()

    switch (eventKey) {
      case 'allow-all-working-steps': {
        dispatch(SolveAllIssuesByIssueCodeAsync({ issueCode: props.issueCode }))
        break
      }
      case 'remove-all-not-allowed-working-steps': {
        dispatch(RemoveAllWorkingStepsNotAvailableInPortal())
        break
      }
      case 'remove-all-parts-not-available': {
        dispatch(RemoveRowsByIssueCode({ issueCode: props.issueCode }))
        break
      }
    }
  }

  switch (props.issueCode) {
    case IssueCode.WorkingStepNotAvailable: {
      menuItems = [
        !props.isBuyer ? (
          <MenuItem
            key="allow-all-working-steps"
            onClick={(e) => menuActionHandler(e, 'allow-all-working-steps')}
          >
            {t(
              'project:allow-all-working-steps-from-portal',
              'allow all working steps from portal'
            )}
          </MenuItem>
        ) : null,
        <MenuItem
          key="remove-all-not-allowed-working-steps"
          onClick={(e) =>
            menuActionHandler(e, 'remove-all-not-allowed-working-steps')
          }
        >
          {t(
            'project:remove-all-not-available-working-steps',
            'remove all not available working steps'
          )}
        </MenuItem>,
        !props.isBuyer ? (
          <MenuItem
            key="remove-all-parts-not-available"
            onClick={(e) =>
              menuActionHandler(e, 'remove-all-parts-not-available')
            }
          >
            {t(
              'project:remove-all-not-available-parts',
              'remove all parts with not available working steps'
            )}
          </MenuItem>
        ) : null,
      ]
    }
  }

  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  if (!menuItems?.length) {
    return null
  }

  return (
    <>
      <IconButton
        size="small"
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {menuItems}
      </Menu>
    </>
  )
}

const IssueItem = (props: {
  onClick: (e) => void
  checked: boolean
  issueCount: number
  issue: IssueDto
  className: string
}) => {
  const { t } = useTranslation()
  const { getIssueDescription } = useBomItemIssueDescription()

  const isBuyer = useAppSelector(projectSelectors.projectInBuyerViewSelector)

  return (
    <>
      <ListItem className={props.className}>
        <FormControlLabel
          style={{ flex: '1 1 100%' }}
          onClick={props.onClick}
          control={<Checkbox checked={props.checked} color="primary" />}
          label={
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    style={{ whiteSpace: 'break-spaces' }}
                    variant="body2"
                  >
                    {getIssueDescription(props.issue)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="span"
                  >
                    {` (${props.issueCount})`}
                  </Typography>
                </>
              }
              secondary={t(`common:${props.issue.severity}`)}
            />
          }
        />
        <IssueItemMenu issueCode={props.issue.issueCode} isBuyer={isBuyer} />
      </ListItem>
    </>
  )
}

export const IssuesFilterContainer = (props: {
  showTitle?: boolean
  hideNoneAndAllButtons?: boolean
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const dispatch = useAppDispatch()
  const { availableFilters, enabledFilters } = useAppSelector(
    selectIssueFilter,
    isEqual
  )

  const {
    workingStepsFilterEnabled,
    materialFilterEnabled,
    issueFilterEnabled,
  } = useEnabledBoMFilters()

  const issueCodes = groupIssues(availableFilters as IssueDto[], 'issueCode')

  const handleChange = (e, issueCode: IssueCode, checked: boolean) => {
    e.preventDefault()
    e.bubble = false

    const newEnabledFilters = checked
      ? [...(enabledFilters || []), issueCode]
      : enabledFilters.filter((x) => x !== issueCode)

    if (newEnabledFilters.length === 0) {
      toggleIssuesFilterOff()
    } else {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'IssuesFilter',
            enabledFilters: newEnabledFilters,
          },
        })
      )
    }
  }

  if (_.isEmpty(issueCodes)) {
    return (
      <div className="filter-empty">
        <LocalizedTypography
          translationKey="common:empty"
          color="textSecondary"
        >
          empty
        </LocalizedTypography>
      </div>
    )
  }

  const toggleIssuesFilterOff = () => {
    dispatch(
      newProjectActions.toggleActiveFilter({
        filterType: 'IssuesFilter',
        open: false,
      })
    )
    // dispatch(newProjectActions.removeAllFilters())
  }

  return (
    <div className="filters-container">
      <div className="issues-filter-container">
        {props.showTitle && (
          <>
            <BoMItemFilterCount
              workingStepFilterEnabled={workingStepsFilterEnabled}
              materialFilterEnabled={materialFilterEnabled}
              issuesFilterEnabled={issueFilterEnabled}
            />
            <LocalizedTypography
              translationKey={'common:issues'}
              variant="body2"
              color="primary"
              className={classes.title}
            >
              issues
            </LocalizedTypography>
          </>
        )}
        <ul className="issues-filter-code-list">
          {Object.keys(issueCodes).map((issueCode) => {
            return (
              <IssueItem
                key={issueCode}
                onClick={(e) =>
                  handleChange(
                    e,
                    issueCode as never,
                    !(enabledFilters?.findIndex((x) => x === issueCode) > -1)
                  )
                }
                checked={enabledFilters?.findIndex((x) => x === issueCode) > -1}
                issueCount={issueCodes[issueCode].length}
                issue={issueCodes[issueCode][0]}
                className={classes.listItem}
              />
            )
          })}
        </ul>
      </div>
      {!props.hideNoneAndAllButtons && enabledFilters?.length > 0 && (
        <div className="filter-actions">
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleIssuesFilterOff}
          >
            {t('project:remove-all-filters', 'remove all filters')}
          </Button>
        </div>
      )}
    </div>
  )
}
