/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash'
import { memo } from 'react'
import { Text } from 'recharts'

type Props = {
  className: string
  fill: string
  height: number
  index: number
  orientation: string
  payload: {
    coordinate: number
    index: number
    offset: number
    value: string
  }
  stroke: string
  textAnchor: string
  tickFormatter: (value: any) => any
  verticalAnchor: string
  visibleTicksCount: number
  width: number
  x: number
  y: number
  dy?: number | string
  dx?: number | string
}

const PartTypeFinancialGraphXAxisTick = (props: Props) => {
  return (
    <Text
      height="140"
      orientation={''}
      stroke="none"
      x={props.x}
      y={props.y}
      dy={20}
      dx={10}
      width={100}
      // className="recharts-text recharts-cartesian-axis-tick-value"
      // textLength={120}
      textAnchor="end"
      angle={-45}
    >
      {props.payload.value}
      {/* <tspan>
        {splitedTexts.map((text, index) => {
          return (
            <tspan x={props.x} dy={'1rem'} key={index}>
              {text}
            </tspan>
          )
        })}
      </tspan> */}
    </Text>
  )
}

export default memo(PartTypeFinancialGraphXAxisTick, isEqual)
