import _ from 'lodash'
import { QuantityDto } from '../services/APIs/InternalAPI/internal-api.contracts'

export const SumQuantity = ([...args]: QuantityDto[]) => {
  const sum = _.sumBy(args, (x) => x?.value ?? 0)

  return {
    ...args[0],
    value: sum,
  }
}
