import { MenuItem, MenuList } from '@mui/material'
import moment from 'moment'
import React from 'react'

export const FacetList = (props: {
  facets: Array<{ value: string; count: number }>
  searchText: string
  onFilter: (filterValue: string) => void
}) => {
  const getDescription = (value: string) => {
    let description = value
    const searchInterval = props.searchText.split(':')[1]

    if (searchInterval || moment(value).isValid()) {
      const utcTime = moment(value).utc()
      switch (searchInterval?.toLowerCase() || '') {
        case 'year':
          description = utcTime.get('year').toString()
          break
        case 'quarter':
          description = `${utcTime.get('year')} - ${utcTime.get('quarter')}`
          break
        case 'month':
          description = `${utcTime.format('MMM')} - ${utcTime.get('year')}`
          break
        default:
          description = utcTime.format('LL')
      }
    }

    return description
  }

  return (
    <MenuList>
      {props.facets.map(
        (x) =>
          x.value && (
            <MenuItem key={x.value} onClick={() => props.onFilter(x.value)}>
              {`${getDescription(x.value)} (${x.count})`}
            </MenuItem>
          )
      )}
    </MenuList>
  )
}
