import { Paper, Tooltip } from '@mui/material'
import { ReportRecognitionIssuesMenuItem } from 'features/BillOfMaterials/BoMItemMenu/MenuItems/ReportRecognitionIssuesMenuItem'
import { PurchasingItemSwitcher } from 'features/BillOfMaterials/PartType/PurchasingItemSwitcher'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { WorkingStepStats } from 'features/BillOfMaterials/store/selectors/assemblyWorkingStepsSummaryWithCounterSelector'
import { bomItemNameSelector } from 'features/BillOfMaterials/store/selectors/bomItemNameSelector'
import { bomItemRowNumberSelector } from 'features/BillOfMaterials/store/selectors/bomItemNumberSelector'
import { bomItemIsSearchedSelector } from 'features/BillOfMaterials/store/selectors/bomItemSearched'
import { bomItemActivitiesSelector } from 'features/BillOfMaterials/store/selectors/bomItemWorkingStepsSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useBomItemThumbnailImageSrc } from 'features/BillOfMaterials/useBomItemThumbnailImageSrc'
import { BomItemDetailsDrawerProps } from 'features/BomItemDetailsDrawer/BomItemDetailsDrawer'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { startTransition, useMemo, useRef, useState } from 'react'
import {
  ProjectOperationDto,
  ProjectOperationStatus,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { OperationPartTypeTooltip } from './OperationPartTypeTooltip'
import { useProjectOperationsStyles } from './ProjectOperationsLogsDrawer.styles'

type Props = {
  operationGroup: ProjectOperationDto
  primaryWorkingStepsFilter?: WorkingStepStats[]
  // root assembly should be the first operation log item, since the api doesn't return the bomitemtype for each item
  isRootAssembly?: boolean
  rootAssemblyId?: string
}

type StyleProps = {
  operationStatus: ProjectOperationStatus
  rowNumber?: number
}

const useOperationBoxStyles = makeStyles<StyleProps>()((theme, props) => {
  let backgroundColor: string

  switch (props.operationStatus) {
    case ProjectOperationStatus.Failed:
      backgroundColor = theme.palette.error.main
      break
    case ProjectOperationStatus.Started:
      backgroundColor = theme.palette.primary.main
      break
    case ProjectOperationStatus.Finished:
      backgroundColor =
        props.rowNumber > 0
          ? theme.palette.success.main
          : theme.palette.success.dark
      break
    case ProjectOperationStatus.Queued:
      backgroundColor = theme.palette.grey[200]
      break
    default:
      backgroundColor = theme.palette.grey[200]
      break
  }

  return {
    root: {
      display: 'block',
      width: theme.spacing(2),
      height: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: backgroundColor,
    },
    tooltip: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      alignItems: 'center',
    },
    tooltipMenu: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.divider}`,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
    customMaxWidth: {
      maxWidth: '400px',
    },
    lastItem: {
      marginLeft: 'auto',
    },
    highlight: {
      outline: `3px solid ${theme.palette.info.dark}`,
    },
  }
})

let lastHandledRootAssemblyId: string = undefined

export const OperationBox = (props: Props) => {
  const dispatch = useAppDispatch()

  const rowNumber = useAppSelector(
    bomItemRowNumberSelector({
      id: props.operationGroup.boMItemId,
      type: BomItemType.partType,
    })
  )

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const bomItemPointer = {
    id: props.operationGroup.boMItemId,
    type: props.isRootAssembly
      ? BomItemType.assemblyType
      : rowNumber > 0
      ? BomItemType.partType
      : BomItemType.assemblyInstance,
  }

  const { imageSrc } = useBomItemThumbnailImageSrc(bomItemPointer)

  const bomItemName = useAppSelector(bomItemNameSelector(bomItemPointer))

  const bomItemIsSearched = useAppSelector(
    bomItemIsSearchedSelector(bomItemPointer.id)
  )

  const { classes, cx } = useOperationBoxStyles({
    operationStatus: props.operationGroup.status,
    rowNumber: rowNumber,
  })

  const bomItemWorkingSteps = useAppSelector(
    bomItemActivitiesSelector({
      bomItemPointer: bomItemPointer,
      includeOwnWorkingSteps: true,
    }),
    isEqual
  )

  const boxRef = useRef(undefined)

  const [menuOpen, setMenuOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { openDialog } = useDialogManager()

  // if (props.primaryWorkingStepsFilter.length === 0) {
  //   return null
  // }

  if (
    props.primaryWorkingStepsFilter?.length > 0 &&
    bomItemPointer.type === BomItemType.partType &&
    bomItemWorkingSteps?.length > 0 &&
    bomItemWorkingSteps?.findIndex((x) =>
      props.primaryWorkingStepsFilter
        .map((x) => x.primaryWorkingStep)
        .includes(x.primaryWorkingStep)
    ) === -1
  ) {
    return null
  }

  const handleClick = () => {
    startTransition(() => {
      if (
        props.rootAssemblyId &&
        bomItemPointer.id !== props.rootAssemblyId &&
        lastHandledRootAssemblyId !== props.rootAssemblyId
      ) {
        // open the root assembly then select the bom item
        lastHandledRootAssemblyId = props.rootAssemblyId

        dispatch(
          newProjectActions.setBoMItemOpen({
            bomItemPointer: {
              id: props.rootAssemblyId,
              type: BomItemType.assemblyType,
            },
            isOpen: true,
          })
        )
        dispatch(
          newProjectActions.setBomItemInSearch({
            id: props.rootAssemblyId,
            type: BomItemType.assemblyType,
          })
        )

        dispatch(
          newProjectActions.setBoMItemOpen({
            bomItemPointer: bomItemPointer,
            isOpen: true,
            onlyOpenParents: true,
          })
        )

        setTimeout(() => {
          dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))
        }, 0)
      } else {
        dispatch(
          newProjectActions.setBoMItemOpen({
            bomItemPointer: bomItemPointer,
            isOpen: true,
            onlyOpenParents:
              bomItemPointer.type === BomItemType.assemblyType ? false : true,
          })
        )

        dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))
      }

      setMenuOpen((c) => !c)
    })
  }

  const handleDblClick = () => {
    if (!isBuyerView) {
      dispatch(
        newProjectActions.setBoMItemOpen({
          bomItemPointer,
          isOpen: true,
        })
      )
      dispatch(newProjectActions.setBomItemInSearch(bomItemPointer))

      openDialog('BomItemDetailsDrawer', {
        bomItemPointer: bomItemPointer,
      } as BomItemDetailsDrawerProps)
    }
  }

  return (
    <>
      <Tooltip
        classes={{
          tooltip: classes.customMaxWidth,
        }}
        arrow
        open={tooltipOpen}
        onClose={() => {
          setTooltipOpen(false)
          setMenuOpen(false)
        }}
        title={
          <Paper elevation={0}>
            <div className={classes.tooltipMenu}>
              <PurchasingItemSwitcher bomItemPointer={bomItemPointer} dialog />
              <ReportRecognitionIssuesMenuItem
                className={classes.lastItem}
                variant="IconButton"
                initialMessage={props.operationGroup.errorMessage}
                bomItemPointer={bomItemPointer}
                onClose={() => setMenuOpen(false)}
                onClick={() => {
                  setTooltipOpen(false)
                  setMenuOpen(false)
                }}
              />
            </div>
            <OperationPartTypeTooltip
              imageSrc={imageSrc}
              bomItemName={bomItemName}
              rowNumber={rowNumber}
              tooltip={classes.tooltip}
              operationGroup={props.operationGroup}
            />
          </Paper>
        }
      >
        <span>
          <div
            ref={boxRef}
            className={cx(classes.root, {
              [classes.highlight]: bomItemIsSearched,
            })}
            onClick={handleClick}
            onDoubleClick={handleDblClick}
            // style={{ cursor: `${rowNumber ? 'pointer' : 'default'}` }}
            style={{ cursor: `pointer` }}
            onMouseEnter={() => {
              setTooltipOpen(true)
            }}
            onMouseLeave={() => {
              if (!menuOpen) {
                setTooltipOpen(false)
              }
            }}
            onContextMenu={(e) => {
              e.preventDefault()
              handleClick()
            }}
          />
        </span>
      </Tooltip>
    </>
  )
}

export const OperationGroupChildrenBoxes = (props: Props) => {
  const { classes } = useProjectOperationsStyles()

  const GetOperationsComponents = useMemo(() => {
    let rootAssemblyId: string | undefined

    if (props.operationGroup.children.length > 1) {
      rootAssemblyId = props.operationGroup.children[0].boMItemId
    }

    return props.operationGroup.children.map((operation, i) => {
      const isRootAssembly = i === 0 && props.operationGroup.children.length > 1

      return (
        <OperationBox
          key={operation.id}
          isRootAssembly={isRootAssembly}
          rootAssemblyId={rootAssemblyId}
          operationGroup={operation}
          primaryWorkingStepsFilter={props.primaryWorkingStepsFilter}
        />
      )
    })
  }, [props.operationGroup.children, props.primaryWorkingStepsFilter])

  if (!props.operationGroup?.children?.length) {
    return null
  }

  return (
    <ul className={classes.operationBoxContainer}>{GetOperationsComponents}</ul>
  )
}
