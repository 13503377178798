import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { WorkingStepIconWithResourceSelector } from './WorkingStepIconWithResourceSelctor'

export const BomItemRoutingData = (props: {
  bomItemPointer: BomItemPointer
  rootClassName?: string
  listItemClassName?: string
}) => {
  const bomItemActivities = useAppSelector(
    projectSelectors.bomItemWorkingStepsSelector({
      bomItemPointer: props.bomItemPointer,
    }),
    isEqual
  )

  if (!bomItemActivities) {
    return null
  }

  return (
    <ul className={props.rootClassName}>
      {bomItemActivities.map((activity) => {
        return (
          <li key={activity.id} className={props.listItemClassName}>
            <WorkingStepIconWithResourceSelector
              bomItemPointers={[props.bomItemPointer]}
              workingStep={activity}
            />
          </li>
        )
      })}
    </ul>
  )
}
