import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import React, { useEffect, useState } from 'react'

export const useModelSelectedFacesChanged = (
  faceIds: (string | number)[],
  modelController: ModelController,
  onOpen: () => void,
  divRef: React.RefObject<HTMLDivElement>,
  isOpen?: boolean,
  disableScroll?: boolean
) => {
  const [hasSelectedFace, setHasSelectedFace] = useState(false)

  useEffect(() => {
    const callback = (args: { elementId: string; elementType: string }[]) => {
      if (!args?.length) {
        setHasSelectedFace(false)
        return
      }

      for (let i = 0; i < args.length; i++) {
        if (
          args[i]?.elementType &&
          args[i].elementType.toUpperCase() === 'FACE' &&
          faceIds?.includes(Number(args[i].elementId))
        ) {
          setHasSelectedFace(true)
          onOpen()
          if (!disableScroll) {
            const scrollTo = () =>
              divRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })

            if (!isOpen) {
              //give time to open the accordion
              setTimeout(scrollTo, 1000)
            } else {
              scrollTo()
            }
          }

          return
        } else {
          setHasSelectedFace(false)
        }
      }
    }

    const unregister = modelController.onSelectionChanged(callback)

    return () => {
      unregister()
    }
  }, [disableScroll, divRef, faceIds, isOpen, modelController, onOpen])

  return {
    hasSelectedFace,
  }
}
