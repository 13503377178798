import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { memo } from 'react'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useBarColorMap } from '../BarComponents'
import { CostFactorTypes } from '../GetGraphData'
import { CostFactorSection } from './CostFactorSection'

const BomItemCostFactorSection = (props: { costFactors: CostFactorTypes }) => {
  const barColorMap = useBarColorMap()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { handleChangeFilter, workingStepFilter } = useWorkingStepFilter()

  return (
    <div
      style={{
        maxHeight: '240px',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <CostFactorSection
        title={t('common:material', 'material')}
        legend={{
          color: barColorMap.material,
        }}
        costFactor={props.costFactors.material}
      />

      <CostFactorSection
        title={`${getLocalizedWorkingStep(
          WorkingStepType.Setup
        )} / ${getLocalizedWorkingStep(WorkingStepType.Loading)}`}
        legend={{
          color: barColorMap.workingStepSetup,
        }}
        costFactor={props.costFactors.workingStepSetup}
      />

      {props.costFactors.workingSteps?.map((key) => {
        return (
          <CostFactorSection
            key={key.workingStepType}
            title={getLocalizedWorkingStep(key.workingStepType)}
            legend={{
              color: barColorMap.workingSteps,
              description: t('project:main-activity', 'main activity'),
              descriptionColor: barColorMap.getContrastText(
                barColorMap.workingSteps
              ),
            }}
            costFactor={key}
            isActive={
              workingStepFilter?.filter?.enabledFilters?.includes(
                key.workingStepType
              ) || false
            }
            onDoubleClick={() => handleChangeFilter(key.workingStepType)}
          />
        )
      })}

      <CostFactorSection
        title={getLocalizedWorkingStep(WorkingStepType.Unloading)}
        costFactor={props.costFactors.workingStepUnload}
        legend={{
          color: barColorMap.workingStepUnload,
        }}
      />
    </div>
  )
}

export default memo(BomItemCostFactorSection, isEqual)
