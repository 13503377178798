import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material'

import { TOptions } from 'i18next'
import React from 'react'
import { Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { expect } from 'utils/expect'
import FileUploader from '.'
import { DialogCloseSaveButtons } from '../DialogActionButtons/DialogCloseSaveButtons'
import { useFileUploader } from './useFileUploader'

type Props = {
  isActive: boolean
  allowedExtensions: Accept
  allowedExtensionsLabel?: string[]
  setIsActive: (active: boolean) => void
  /**
   * use this if you want to control how the files are uploaded
   * @param files files to be uploaded
   * @returns
   */
  /**
   * required if props.postFiles() is not provided
   */
  filePostUrl?: string
  networkLabel: string

  /**
   * called when the file upload is successful (and no props.postFiles() is provided)
   * @param data
   * @returns
   */
  onFileUploadSucceed?: (data?: unknown) => void

  /**
   * @param message message to be displayed if upload fails (and no props.postFiles() is provided)
   * @returns
   */
  onFileUploadFail?: (message) => void

  titleTOptions?: { translationKey: string; options?: TOptions }
  successMessageTitleTKey?: string
  successMessageTKey?: string
  explanationText?: string | (() => string)
  onlyOneFile?: boolean
  postFiles?: (files: File[]) => void
  errorComponent?: React.ReactElement
  onFilesChanged?: () => void
  slotProps?: DialogProps['slotProps']
  saving?: boolean
}

export const FileUploadDialog = (props: Props) => {
  const { t } = useTranslation()

  const { files, setFiles, progress, postFiles, cleanFiles } = useFileUploader(
    props.filePostUrl,
    props.networkLabel,
    props.onFileUploadSucceed,
    props.onFileUploadFail,
    props.successMessageTitleTKey,
    props.successMessageTKey
  )

  const handleSave = () => {
    if (props.postFiles) {
      props.postFiles(files)
      return
    }

    expect(
      props.filePostUrl,
      'filePostUrl is required if props.postFiles is not provided'
    )

    postFiles()
  }

  return (
    <Dialog
      open={props.isActive}
      onClose={() => props.setIsActive(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
      slotProps={props.slotProps}
    >
      <DialogTitle id="form-dialog-title">
        {t(
          props.titleTOptions?.translationKey ?? 'common:import-project',
          props.titleTOptions?.options ?? { defaultValue: 'import project' }
        )}
      </DialogTitle>
      <DialogContent
        style={{
          height: '50vh',
        }}
      >
        <DialogContentText component="span">
          {props.explanationText && (
            <Typography color="textPrimary" variant="subtitle1">
              {typeof props.explanationText === 'function'
                ? props.explanationText()
                : props.explanationText}
            </Typography>
          )}
          {t('common:allowed-extensions', {
            defaultValue: 'allowed extensions: {{extensions}}',
            replace: {
              extensions: props.allowedExtensionsLabel
                ? props.allowedExtensionsLabel.join(', ')
                : Object.values(props.allowedExtensions).join(', '),
            },
          })}
        </DialogContentText>
        <FileUploader
          acceptedExtensions={props.allowedExtensions}
          onFileUploaded={postFiles}
          uploadProgress={progress}
          setFiles={(files) => {
            setFiles(files)
            props.onFilesChanged?.()
          }}
          files={files}
          onlyOneFile={props.onlyOneFile}
        />
        {props.errorComponent}
      </DialogContent>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={() => {
            cleanFiles()
            props.setIsActive(false)
            props.onFilesChanged?.()
          }}
          onSaveButtonClicked={() => {
            handleSave()
          }}
          saving={props.saving}
          progress={progress}
          saveButtonDisabled={files?.length === 0}
          saveButtonTranslationKey="common:import-files"
        />
      </DialogActions>
    </Dialog>
  )
}
