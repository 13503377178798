import { BomItemType } from 'model/Project/BoMItemRow'
import { useMemo } from 'react'
import { useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'

export const useBomItemPointer = (
  bomItemId: string,
  bomItemType: BomItemType
) => {
  const bomItemExists = useAppSelector(
    projectSelectors.bomItemExists({
      id: bomItemId,
      type: bomItemType,
    })
  )

  return useMemo(() => {
    if (!bomItemExists) {
      return null
    }

    return {
      id: bomItemId,
      type: bomItemType,
    }
  }, [bomItemExists, bomItemId, bomItemType])
}
