import { Button, Grow, Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

type Props = {
  projectId: string
  currentPartyName: string
}

const useStyles = makeStyles({ name: 'ProjectNotAllowedInWorkspace' })(
  (theme) => ({
    root: {
      textAlign: 'center',
      borderRadius: theme.shape.borderRadius,
      maxWidth: theme.breakpoints.values.sm,
      margin: '0 250px',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
    },
    button: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export const ProjectNotAllowedInWorkspace = (props: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRemoveData = () => {
    navigate('/app/workspaces', {
      replace: true,
    })
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => setOpen(true), 60)
  }, [])

  return (
    <Grow in={open}>
      <div className={classes.root}>
        <Button
          onClick={handleRemoveData}
          className={classes.button}
          variant="outlined"
          color="warning"
        >
          <Typography>
            {t(
              'common:project-not-allowed-in-workspace',
              'Project {{projectId}} was not found in workspace',
              {
                projectId: props.projectId,
              }
            )}
          </Typography>
          <Typography fontWeight={'bold'} component={'p'} marginLeft=".5ch">
            {props.currentPartyName}
          </Typography>
          <LocalizedTypography
            translationKey="common:project-not-allowed-in-workspace-select-another"
            variant="caption"
            color="grayText"
            component="span"
          >
            select another workspace or click here to go to project list
          </LocalizedTypography>
        </Button>
      </div>
    </Grow>
  )
}
