import { Theme } from '@mui/material'
import { BomItemName } from 'features/BillOfMaterials/components/BomItemName'
import { BomItemQuantity } from 'features/BillOfMaterials/components/BomItemQuantity'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { makeStyles } from 'tss-react/mui'

type Props = {
  bomItemPointer: BomItemPointer
}

const useStyles = makeStyles({ name: 'BomItemInfo' })((theme: Theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  valueLabel: {
    height: 'auto',
    whiteSpace: 'break-spaces',
  },
}))

export const BomItemInfo = (props: Props) => {
  const { classes } = useStyles()
  return (
    <div className={classes.content}>
      <RowThumbnailButton
        bomItemPointer={props.bomItemPointer}
        disableClick
        showTooltip={false}
        size="large"
      />
      <BomItemName
        bomItemPointer={props.bomItemPointer}
        asLabel
        valueLabelProps={{
          classes: {
            root: classes.valueLabel,
          },
        }}
      />
      <BomItemQuantity
        bomItemPointer={props.bomItemPointer}
        disabled
        translationKey="project:quantity"
      />
    </div>
  )
}
