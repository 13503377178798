/* eslint-disable tss-unused-classes/unused-classes */
import { MoneyInput } from 'components/Common/Input/MoneyInput'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { BomItemMaterialDiscountPercentage } from 'features/BillOfMaterials/components/BomItemMaterialDiscountPercentage'
import { BomItemMaterialSurchargePercentage } from 'features/BillOfMaterials/components/BomItemMaterialSurchargePercentage'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import {
  MoneyDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { CustomPrice } from '../usePurchasingPartDialog'

type Props = {
  bomItemPointer: BomItemPointer
  activityId: string
  onPriceChange: (key: keyof CustomPrice, value: MoneyDto | QuantityDto) => void
  customPrice: CustomPrice
  usingArticleCatalog: boolean
}

const useStyle = makeStyles({ name: 'BomItemCustomPrice' })((theme) => ({
  customPrices: {
    display: 'flex',
    gap: theme.spacing(3),
    padding: theme.spacing(0, 1, 0, 2),
    justifyContent: 'space-between',
  },
  root: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const BomItemCustomMaterialPrice = (props: Props) => {
  const { classes: pricesClasses } = useStyle()
  const { t } = useTranslation()

  const bomItemCostPrice = useAppSelector(
    projectSelectors.bomItemMaterialFinancialSelector(
      props.bomItemPointer,
      props.activityId,
      'costPricePerItem'
    ),
    isEqual
  ) as MoneyDto

  return (
    <div className={pricesClasses.root}>
      <LocalizedTypography
        translationKey="project:purchasing-data-custom-material-price-title"
        variant="body1"
      >
        custom material price
      </LocalizedTypography>
      <div className={pricesClasses.customPrices}>
        <MoneyInput
          label={t('project:cost-price-per-item', 'cost price/item')}
          value={props.customPrice?.CostPrice ?? bomItemCostPrice}
          onChange={(e) => {
            props.onPriceChange('CostPrice', e.currentTarget.value)
          }}
          autoFocus
          textAlign="right"
        />

        <BomItemMaterialSurchargePercentage
          bomItemPointer={props.bomItemPointer}
          activityId={props.activityId}
          onChange={(value) => props.onPriceChange('SurchargeRatio', value)}
        />

        <BomItemMaterialDiscountPercentage
          bomItemPointer={props.bomItemPointer}
          activityId={props.activityId}
          onChange={(value) => {
            props.onPriceChange('DiscountRatio', value)
          }}
        />
      </div>
    </div>
  )
}
