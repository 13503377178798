import { TextFieldProps } from '@mui/material'
import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { saveBomItemName } from '../store/asyncActions/saveBomItemName'
import { ProjectTextField } from '../store/components/ProjectTextField'
import { ValueLabelConnected } from '../store/components/ValueLabelConnected'
import { ProjectEditableStateSelector } from '../store/selectors/ProjectEditableStateSelector'
import { bomItemNameSelector } from '../store/selectors/bomItemNameSelector'

export function BomItemName(props: {
  bomItemPointer: BomItemPointer
  textFieldProps?: TextFieldProps
  asLabel?: boolean
  valueLabelProps?: Partial<ValueLabelProps>
}) {
  const { t } = useTranslation()

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)

  if (!projectIsEditable || props.asLabel) {
    return (
      <ValueLabelConnected
        propertySelector={bomItemNameSelector(props.bomItemPointer)}
        valueLabelProps={{
          label: t('common:name'),
          valueProps: {
            className: props.textFieldProps?.className,
          },
          isLeftAligned: true,
          fullWidth: true,
          ...props.valueLabelProps,
        }}
      />
    )
  }

  return (
    <ProjectTextField
      valueSelector={bomItemNameSelector(props.bomItemPointer)}
      actionTypePrefix={saveBomItemName.typePrefix.concat(
        '/',
        props.bomItemPointer.id
      )}
      saveAction={(value) =>
        saveBomItemName({
          bomItemPointer: props.bomItemPointer,
          name: value,
        })
      }
      textFieldProps={{
        label: t('common:name'),
        required: true,
        type: 'text',
        ...props.textFieldProps,
      }}
    />
  )
}
