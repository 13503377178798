import { ContentPasteOutlined, InputOutlined } from '@mui/icons-material'
import { useKeywordsTranslation } from 'components/Common/ArticleSelector/useKeywordsTranslation'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useAppController } from 'customHooks/useAppController'
import { BomItemActivityController } from 'features/BillOfMaterials/components/BomItemActivities/BomItemActivityController'
import { KeywordTag } from 'features/BillOfMaterials/components/BomItemActivities/KeywordTag'
import { useActivityKeywords } from 'features/BillOfMaterials/components/BomItemActivities/useActivityKeywords'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { t } from 'i18next'
import { cloneDeep, isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  KeywordDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { activityClipboardSelector } from 'store/MaterialSelector/MaterialSelectors'
import store, { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

type PasteActivityProps = {
  bomItemPointer: BomItemPointer
  clipboardActivity: BoMItemActivityDto
  onClose: () => void
}

type PasteAllActivitiesProps = {
  bomItemPointer: BomItemPointer
  clipboardActivities: BoMItemActivityDto[]
  onClose: () => void
}

const PasteAllActivitiesMenuItem = (props: PasteAllActivitiesProps) => {
  const { controller, loading } = useAppController(
    () => new BomItemActivityController()
  )

  const handleSaveKeywords = async () => {
    try {
      props.clipboardActivities?.forEach(async (clipboardActivity) => {
        const targetActivity = bomItemActivitiesSelectors.byWorkingStep(
          props.bomItemPointer,
          clipboardActivity.primaryWorkingStep
        )(store.getState())

        if (!targetActivity) {
          return
        }

        const allTokens = cloneDeep(clipboardActivity.keywords)

        allTokens.shape = targetActivity?.keywords['shape'] || []
        allTokens.dimensions = targetActivity?.keywords['dimensions'] || []

        await controller.SetBomItemActivityKeywords(
          [
            {
              bomItemPointer: props.bomItemPointer,
              activityId: targetActivity.id,
            },
          ],
          allTokens,
          targetActivity.usesRawMaterial
        )
      })
    } catch (err) {
      ShowException('paste keywords', err)
    }
  }

  return (
    <BomItemMenuItem
      disableIfNotEditable
      onClick={handleSaveKeywords}
      loading={loading['setActivityKeywords']}
      icon={<ContentPasteOutlined />}
      primaryText={t('project:paste-all-activities', 'paste all materials')}
      primaryTextTranslationKey="project:paste-material"
      closeAfterFullfilled
      onClose={props.onClose}
    />
  )
}

const PasteActivityMenuItem = (props: PasteActivityProps) => {
  const { t } = useTranslation()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { translateKeyword } = useKeywordsTranslation()

  const workingStepLabel = getLocalizedWorkingStep(
    props.clipboardActivity.primaryWorkingStep
  )

  const targetActivity = useAppSelector(
    bomItemActivitiesSelectors.byWorkingStep(
      props.bomItemPointer,
      props.clipboardActivity.primaryWorkingStep
    ),
    isEqual
  )

  const { handleChange, handleSaveKeywords, loading, bomItemActivity } =
    useActivityKeywords({
      bomItemPointer: props.bomItemPointer,
      activityId: targetActivity?.id,
      disableGetNewKeywords: true,
    })

  useEffect(() => {
    const allTokens: Array<KeywordDto[]> = []

    allTokens.push(
      Object.values(props.clipboardActivity.keywords).flatMap((x) => x)
    )
    allTokens.push(
      Object.values(targetActivity?.keywords['shape'] || {}).flatMap((x) => x)
    )
    allTokens.push(
      Object.values(targetActivity?.keywords['dimensions'] || {}).flatMap(
        (x) => x
      )
    )

    handleChange(
      null,
      allTokens.flatMap((x) => x),
      'selectOption',
      true
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!targetActivity) {
    return null
  }

  return (
    <BomItemMenuItem
      disableIfNotEditable
      onClick={() => handleSaveKeywords(bomItemActivity.keywords)}
      loading={loading['setActivityKeywords']}
      icon={
        <WorkingStepIconSvg
          workingStepType={targetActivity.primaryWorkingStep}
        />
      }
      primaryText={t(
        'project:paste-activity-material',
        'paste {{workingStep}} material',
        {
          workingStep: workingStepLabel,
        }
      )}
      primaryTextTranslationKey="project:paste-material"
      secondary={
        <ul>
          {Object.values(props.clipboardActivity?.keywords || {})
            .flat()
            .flatMap((x) => {
              return (
                <KeywordTag
                  key={x.originalKeyword}
                  token={x}
                  translateFn={translateKeyword}
                />
              )
            })}
        </ul>
        // <MaterialTags selectedTokens={props.clipboardActivity.keywords} />
      }
      closeAfterFullfilled
      onClose={props.onClose}
    />
  )
}

export const PasteActivityKeywordsMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const activitiesClipboard = useAppSelector(activityClipboardSelector, isEqual)

  const bomItemWorkingSteps = useAppSelector(
    projectSelectors.bomItemWorkingStepsSelector({
      bomItemPointer: bomItemPointer,
      includeOwnWorkingSteps: true,
    }),
    isEqual
  )

  const pasteMaterialItems = useMemo(() => {
    const pasteItems = activitiesClipboard?.activities.map((activity) => {
      return (
        <PasteActivityMenuItem
          key={activity.id}
          bomItemPointer={bomItemPointer}
          clipboardActivity={activity}
          onClose={onClose}
        />
      )
    })

    pasteItems?.push(
      <PasteAllActivitiesMenuItem
        key="all"
        clipboardActivities={activitiesClipboard.activities}
        bomItemPointer={bomItemPointer}
        onClose={onClose}
      />
    )

    return pasteItems
  }, [activitiesClipboard, bomItemPointer, onClose])

  const clipboardWorkingSteps = activitiesClipboard?.activities.map(
    (x) => x.primaryWorkingStep
  )

  const hasWorkingStep = bomItemWorkingSteps?.find((x) =>
    clipboardWorkingSteps?.includes(x.primaryWorkingStep)
  )

  if (!hasWorkingStep) {
    return null
  }

  return (
    <BomItemMenuItem
      disableIfNotEditable
      icon={<InputOutlined />}
      primaryText={t('project:paste-material', 'paste material')}
      primaryTextTranslationKey="project:paste-material"
      closeAfterFullfilled
      onClose={onClose}
      nestedItems={pasteMaterialItems}
    />
  )
}
