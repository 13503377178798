import Axios from 'axios'
import _ from 'lodash'
import { NavigateFunction } from 'react-router'
import { TelemetryService } from 'services/Telemetry'

const APP_VERSION = import.meta.env.VITE_BUILD_NUMBER

async function getCDNCurrentVersion(): Promise<string | undefined> {
  const url = `version.json?c=${Date.now()}`
  const headers = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '-1',
  }

  try {
    const response = await Axios.get<{ appBuildNumber: string }>(url, {
      headers,
    })

    return response.data.appBuildNumber
  } catch (err) {
    console.error(JSON.stringify(err))

    if (err['code'] === 'ECONNABORTED') {
      return undefined
    }

    console.error('error loading CDN Version Number', err)
    return undefined
  }
}

const checkAppVersionSkipRoutes = ['logout', 'accept', 'auth']

/**
 * returns true if the app is up to date
 * @param navigate
 * @returns
 */
export const checkAppVersion = async (
  navigate: NavigateFunction
): Promise<{
  fileBuildNumber: string
  cdnBuildNumber: string
  isLatest: boolean
}> => {
  try {
    const pathname = window.location.pathname.toLowerCase()
    console.info('checking app version', pathname)

    if (
      checkAppVersionSkipRoutes.some((route) =>
        pathname.toLowerCase().includes(route)
      )
    ) {
      return {
        fileBuildNumber: APP_VERSION,
        cdnBuildNumber: `<skiped for route ${pathname}>`,
        isLatest: true,
      }
    }
    const cdnCurrentVersion = await getCDNCurrentVersion()

    if (!cdnCurrentVersion) {
      //cdn current version not available, let just load the app normally (but maybe with Browser caching problems)
      return {
        fileBuildNumber: APP_VERSION,
        cdnBuildNumber: '<unable to get cdn version number>',
        isLatest: true,
      }
    }

    if (cdnCurrentVersion === APP_VERSION) {
      console.info('rh24 app is up to date', {
        appVersion: APP_VERSION,
        cdnCurrentVersion: cdnCurrentVersion,
      })

      return {
        fileBuildNumber: APP_VERSION,
        cdnBuildNumber: cdnCurrentVersion,
        isLatest: true,
      }
    }

    console.warn(
      `[Quotation Factory]: Needs to be updated, reloading the window (currentVersion: ${APP_VERSION} | vnext: ${cdnCurrentVersion})`,
      cdnCurrentVersion
    )

    clearTheCache()

    const newUrl = new URL(window.location.href)
    newUrl.searchParams.set('z', Date.now().toString())

    console.warn('will use window.location.replace', newUrl)

    await fetch(newUrl.href, {
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Expires: '-1',
      },
    })

    window.location.href = newUrl.href
    window.location.reload()

    return {
      fileBuildNumber: APP_VERSION,
      cdnBuildNumber: cdnCurrentVersion,
      isLatest: false,
    }
  } catch (err) {
    TelemetryService.getInstance().logError(err as Error)
    console.error(err)
    if (err instanceof Error) {
      if (err.message.includes("Failed to read the 'sessionStorage'")) {
        navigate('/app/error', {
          state: {
            errorType: 'incognito',
          },
        })
      }
    }
    return {
      fileBuildNumber: APP_VERSION,
      cdnBuildNumber: 'error: ' + err.toString(),
      isLatest: true,
    }
  }
}

const clearTheCache = async () => {
  try {
    if ('caches' in window) {
      // Service worker cache should be cleared with caches.delete()
      const cacheKeys = await window.caches.keys()
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key)
        })
      )
    }
  } catch (err) {
    console.error('rh24 error when cleaning browser cache', err)
  }
}

export const checkAppVersionThrottled = _.throttle(
  (navigate: NavigateFunction) => checkAppVersion(navigate),
  60_000,
  {
    leading: true,
    trailing: false,
  }
)
