import { Button, Typography } from '@mui/material'

import { SaveButton } from 'components/Common/Button/SaveButton'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { DefinitiveMoneyPerQuantityField } from 'components/DefinitiveInputs/DefinitiveMoneyPerQuantityField'
import { DefinitiveInputValidators } from 'components/DefinitiveInputs/Validators'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { useProjectPriceSummaryDetailsStyles } from '../ProjectFinancialDetails.styles'
import { HomePriceButtonOutlined } from './HomePriceButtonOutlined'
import { useProjectPriceSummaryDetails } from './useProjectPriceSummaryDetails'

const getLoadingKey = (operation: string, priceSummary: PriceSummaryDto) => {
  return `${operation}-${priceSummary.workingStep.resource.id}-${priceSummary.workingStep.secondaryWorkingStep}`
}

const ReadOnlyFields = ({
  changedPriceSummary,
}: {
  changedPriceSummary: PriceSummaryDto
}) => {
  const { t } = useTranslation()

  if (!changedPriceSummary) {
    return null
  }

  return (
    <>
      <ValueLabel
        valueVariant="body2"
        value={QuantityString(changedPriceSummary.surchargeRatio)}
        label={t('common:surcharge')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'surchp',
        }}
      />
      <ValueLabel
        valueVariant="body2"
        value={MoneyString(changedPriceSummary.surchargeValue)}
        label={t('common:surcharge')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'surchv',
        }}
      />

      <ValueLabel
        valueVariant="body2"
        value={QuantityString(changedPriceSummary.discountRatio)}
        label={t('common:discount')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'discp',
        }}
      />
      <ValueLabel
        valueVariant="body2"
        value={MoneyString(changedPriceSummary.discountValue)}
        label={t('common:discount')}
        valueLabelRootStyles={{
          whiteSpace: 'pre-line',
          gridArea: 'discv',
        }}
      />
    </>
  )
}

export const ProjectWorkingStepPriceSummary = (props: {
  priceSummary: PriceSummaryDto
}) => {
  const { classes } = useProjectPriceSummaryDetailsStyles()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { t } = useTranslation()

  const isEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const {
    changedPriceSummary,
    handleChange,
    handleSubmit,
    changed,
    handleReset,
    saving,
    handleResetToDefaults,
    validationErrors,
    loading,
  } = useProjectPriceSummaryDetails(props.priceSummary)

  const { articleIdFilter } = useArticleIdFilter()

  if (!props.priceSummary?.isWorkingStep) {
    return null
  }

  if (articleIdFilter.active) {
    return null
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div
        id={
          props.priceSummary.materialId ||
          props.priceSummary.workingStep?.resource?.id
        }
        className={classes.priceSummaryGrid}
      >
        <div className={classes.priceSummaryDescription}>
          <div className={classes.name}>
            <div>
              <Typography variant="body1" component="div">
                <Typography className={classes.secondaryWorkingStepName}>
                  {`${getLocalizedWorkingStep(
                    props.priceSummary.workingStep.secondaryWorkingStep
                  )}`}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.usage}>
          <Typography variant="caption">
            {TimeString(changedPriceSummary.productionTime)}
          </Typography>
        </div>
        <div className={classes.rate}>
          <DefinitiveMoneyPerQuantityField
            label={t('project:rate-per-time-unit', 'rate per time unit')}
            value={changedPriceSummary.rate}
            onChange={(moneyPerQuantity) => {
              handleChange('rate', moneyPerQuantity)
            }}
            disabled={!isEditable}
            disabledUnit={!isEditable}
            disabledValue={!isEditable}
            validation={(money) => {
              return DefinitiveInputValidators.CombineValidators([
                DefinitiveInputValidators.MinValue(money?.value, 0.01),
              ])
            }}
          />
          <HomePriceButtonOutlined
            handleResetToDefaults={() => handleResetToDefaults('costPrice')}
            isLoading={
              loading[getLoadingKey('delete-cost-price', changedPriceSummary)]
            }
            isManuallySet={changedPriceSummary.isCostPriceManuallySet}
            hidden={!isEditable}
          />
        </div>

        <ValueLabel
          valueVariant="body2"
          value={MoneyString(changedPriceSummary.productionTimeCosts)}
          label={'costs [prod time]'}
          labelTranslationKey={'project:costs-prod'}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'costProd',
          }}
        />
        <ValueLabel
          valueVariant="body2"
          label={'costs [additional]'}
          labelTranslationKey={'project:costs-additional'}
          value={MoneyString(changedPriceSummary.additionalCosts)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'costAdd',
          }}
        />
        <ValueLabel
          valueVariant="body2"
          label={'costs [total]'}
          labelTranslationKey={'project:costs-total'}
          value={MoneyString(changedPriceSummary.totalCostPrice)}
          valueLabelRootStyles={{
            whiteSpace: 'pre-line',
            gridArea: 'cost',
          }}
        />
        <ReadOnlyFields changedPriceSummary={changedPriceSummary} />
        <ValueLabel
          valueVariant="body2"
          label={'sales price'}
          labelTranslationKey={'project:sales-price'}
          value={MoneyString(changedPriceSummary.totalSalesPrice)}
          valueLabelRootStyles={{
            gridArea: 'salesPrice',
          }}
        />
      </div>
      {changed && (
        <div className={classes.actions}>
          <ul>
            {Object.keys(validationErrors).map((errKey) => (
              <li key={errKey}>
                <Typography color="secondary" variant="caption">
                  {validationErrors[errKey]}
                </Typography>
              </li>
            ))}
          </ul>

          <Button
            size="small"
            type="reset"
            variant="outlined"
            color="secondary"
          >
            cancel
          </Button>
          <SaveButton
            disabled={Object.keys(validationErrors).length > 0}
            size="small"
            saving={saving}
          />
        </div>
      )}
    </form>
  )
}
