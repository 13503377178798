import {
  CheckCircleOutlined,
  ErrorOutlined,
  QuestionAnswerOutlined,
  TimerOutlined,
} from '@mui/icons-material'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { CircularProgress, Typography, useTheme } from '@mui/material'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { BomItemWorkingStepSummary } from 'features/BillOfMaterials/components/BomItemWorkingStepSummary'
import { WorkingStepStats } from 'features/BillOfMaterials/store/selectors/assemblyWorkingStepsSummaryWithCounterSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'

import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProjectOperationDto,
  ProjectOperationStatus,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { OperationGroupChildrenBoxes } from './GeometryLogChildrenBoxes'
import { useProjectOperationsStyles } from './ProjectOperationsLogsDrawer.styles'

const OperationIcon = (props: {
  operationGroup: ProjectOperationDto
  checkChildrens?: boolean
}) => {
  const theme = useTheme()

  switch (props.operationGroup.status) {
    case ProjectOperationStatus.Failed: {
      return <ErrorOutlined color="secondary" />
    }
    case ProjectOperationStatus.Started: {
      return <CircularProgress size="24px" disableShrink />
    }
    case ProjectOperationStatus.Queued: {
      return <TimerOutlined color="action" />
    }
    case ProjectOperationStatus.Finished: {
      return (
        <CheckCircleOutlined style={{ color: theme.palette.success.main }} />
      )
    }
    default:
      return <QuestionAnswerOutlined />
  }
}

const GeometryLogItem = ({
  geometryLog: operationGroup,
  assemblyIds,
}: {
  geometryLog: ProjectOperationDto
  assemblyIds: string[]
  style?: CSSProperties
}) => {
  const { classes } = useProjectOperationsStyles()
  const { t } = useTranslation()

  const bomItemIdForWorkingStepSummary =
    operationGroup.children.length === 1
      ? operationGroup.children[0].boMItemId
      : operationGroup.children?.find((x) => assemblyIds?.includes(x.boMItemId))
          ?.boMItemId

  const bomItemType =
    operationGroup.totalChildrenCount === 1
      ? BomItemType.partType
      : BomItemType.assemblyType

  const workingStepsStats = useAppSelector(
    projectSelectors.assemblyWorkingStepsSummaryWithCounterSelector({
      id: bomItemIdForWorkingStepSummary,
      type: bomItemType,
    }),
    isEqual
  )

  const { workingStepFilter } = useWorkingStepFilter()

  const filteredStats: WorkingStepStats[] = Object.values(
    Object.entries(workingStepsStats || {}).reduce((acc, [key, value]) => {
      if (!workingStepFilter.active) {
        acc[key] = value
      } else {
        if (
          workingStepFilter.filter.enabledFilters.includes(
            value.primaryWorkingStep
          )
        ) {
          acc[key] = value
        }
      }

      return acc
    }, {})
  )

  if (!operationGroup) return null

  return (
    <TimelineItem key={operationGroup.id}>
      <TimelineOppositeContent>
        {operationGroup?.children ? (
          <BomItemWorkingStepSummary
            bomItemPointer={{
              id: bomItemIdForWorkingStepSummary,
              type: bomItemType,
            }}
            includeCountOfWorkingSteps
            includeAssemblyWorkingSteps
            shouldFilter
          />
        ) : null}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          // color="primary"
          className={classes.timelineDot}
        >
          <OperationIcon checkChildrens operationGroup={operationGroup} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          variant="body2"
          style={{ width: '250px' }}
          noWrap
          title={
            operationGroup.operationTypeTranslationArguments as unknown as string
          }
        >
          {operationGroup.operationTypeTranslationArguments}
        </Typography>
        {operationGroup.totalChildrenCount > 0 ? (
          <Typography
            variant="caption"
            component="p"
            color="textSecondary"
            title={t(
              'project:total-operation-count-title',
              'finished: {{finished}} / total: {{total}}',
              {
                finished: operationGroup.totalChildrenFinishedCount,
                total: operationGroup.totalChildrenCount,
              }
            )}
          >
            {operationGroup.totalChildrenFinishedCount} /{' '}
            {operationGroup.totalChildrenCount}
          </Typography>
        ) : null}

        {operationGroup.errorMessage ? (
          <Typography variant="caption" component="p" color="secondary">
            {operationGroup.errorMessage}
          </Typography>
        ) : null}

        <OperationGroupChildrenBoxes
          operationGroup={operationGroup}
          primaryWorkingStepsFilter={filteredStats}
        />
      </TimelineContent>
    </TimelineItem>
  )
}

export default React.memo(GeometryLogItem, isEqual)
