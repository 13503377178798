import { Chip, Tooltip, Typography } from '@mui/material'
import { RunnableTaskStatus } from 'model/Project/BoMItemRow'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  runningLabel: string
  category: string
  status: RunnableTaskStatus
  onClick?: () => void
  title?: React.ReactNode
  finishedLabel: React.ReactNode
  running: boolean
}

const useStyles = makeStyles()(() => ({
  bomItemBadge: {
    padding: 0,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
}))

const RunnableTaskStatusToColor = (taskStatus: RunnableTaskStatus) => {
  switch (taskStatus) {
    case RunnableTaskStatus.NotApplicable:
      return 'transparent'
    case RunnableTaskStatus.Requested:
      return 'info'
    case RunnableTaskStatus.Running:
      return 'info'
    case RunnableTaskStatus.Done:
      return 'success'
    case RunnableTaskStatus.Failed:
      return 'error'
    default:
      return 'info'
  }
}

type StyleProps = {
  color: ReturnType<typeof RunnableTaskStatusToColor>
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useChipLabelClasses = makeStyles<StyleProps>()((theme, props) => ({
  rootChipLabel: {
    display: 'flex',
    padding: 0,
    margin: 0,
    alignItems: 'center',
  },
  chipTitle: {
    // backgroundColor: theme.palette.action.disabled,
    padding: theme.spacing(0.25, 1, 0),
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    verticalAlign: 'center',
    borderRight: `2px solid ${theme.palette.divider}`,
  },
  chipLabel: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: theme.typography.fontWeightBold as any,
    padding: theme.spacing(1),
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    backgroundColor: (theme.palette[props.color] || theme.palette.info).main,
    color: (theme.palette[props.color] || theme.palette.info).contrastText,
  },
}))

const ChipLabel = (props: {
  category: string
  label: React.ReactNode
  color: ReturnType<typeof RunnableTaskStatusToColor>
}) => {
  const { classes } = useChipLabelClasses({ color: props.color })

  return (
    <span className={classes.rootChipLabel}>
      <Typography variant="caption" className={classes.chipTitle}>
        {props.category}
      </Typography>
      <Typography
        variant="caption"
        className={classes.chipLabel}
        component="span"
      >
        {props.label}
      </Typography>
    </span>
  )
}

export const BoMItemBadge = (props: Props) => {
  const color = RunnableTaskStatusToColor(props.status)

  const { classes } = useStyles()

  return (
    <Tooltip title={props.title} arrow>
      <Chip
        className={classes.bomItemBadge}
        onClick={props.onClick}
        component={'span'}
        label={
          <ChipLabel
            {...props}
            label={props.running ? props.runningLabel : props.finishedLabel}
            color={color}
          />
        }
        size="small"
        variant="outlined"
      />
    </Tooltip>
  )
}
