import { LinearProgress, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'QuotationExpirationDateProgress' })(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      alignSelf: 'flex-start',
      height: '100%',
      minWidth: '100px',
      marginTop: '-3px',
    },
  })
)

export function ExpirationDateProgressVariant(props: {
  expirationDate: Date
  disableLabel?: boolean
}) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  // to prevent problems with timezone, make it expire at endOf('day')
  const _moment = moment(props.expirationDate, 'YYYY-MM-DD').endOf('day')

  const expirationDate = new Date(props.expirationDate)

  const diffInMs = (expirationDate as never) - (new Date() as never)

  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))

  const progress = diffInDays < 0 ? 1 : (1 - diffInDays / 30) * 0.95

  let color: 'error' | 'warning' | 'info' = 'info'

  if (progress > 0.7) {
    color = 'error'
  } else if (progress > 0.4) {
    color = 'warning'
  } else {
    color = 'info'
  }

  return (
    <Tooltip
      title={t(
        'common:quotation-valid-util-tooltip',
        'Quotation valid until {{formatedDate}}. After that date, the project will be automatically canceled',
        { formatedDate: _moment.format('L') }
      )}
      placement="top"
    >
      <div className={classes.root}>
        {props.disableLabel ? null : (
          <Typography variant="caption" color="textSecondary">
            {t('common:expires')}
          </Typography>
        )}
        <span style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <LinearProgress
            variant="determinate"
            value={progress * 100}
            color={color}
          />
          <Typography variant="body2" fontWeight={'bold'} color={color}>
            {_moment.fromNow()}
          </Typography>
        </span>
      </div>
    </Tooltip>
  )
}
