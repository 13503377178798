import { Divider, Typography, TypographyProps } from '@mui/material'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { QuantityString } from 'model/Quantity'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { bomItemDimensionsSelector } from '../store/selectors/bomItemDimensionsSelector'

type DimensionsProps = {
  title: string
  dimensions: { length: QuantityDto; width: QuantityDto; height: QuantityDto }
  typographyProps?: TypographyProps
}

export const DimensionSummaryString = (dimensions: {
  length: QuantityDto
  width: QuantityDto
  height?: QuantityDto
}) => {
  const orderedDimensions = [
    dimensions.length,
    dimensions.width,
    dimensions.height,
  ]
    .filter((x) => !!x)
    .sort((a, b) => b.value - a.value)

  const dimensionsString = orderedDimensions
    .map((dimension, index) => {
      if (index === orderedDimensions.length - 1) {
        return QuantityString(dimension)
      }

      return QuantityString(dimension, {
        showUnit: false,
      })
    })
    .filter((x) => !!x)
    .join(' x ')

  return dimensionsString
}

const DimensionString = (props: DimensionsProps) => {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      {...(props.typographyProps || {})}
    >{`${props.title ? `${props.title}:` : ''} ${DimensionSummaryString(
      props.dimensions
    )}`}</Typography>
  )
}

export const BomItemDimensionSummary = ({
  bomItemPointer,
  typographyProps,
  rootStyle,
}: {
  bomItemPointer: BomItemPointer
  typographyProps?: TypographyProps
  rootStyle?: React.CSSProperties
}) => {
  const dimensions = useAppSelector(
    bomItemDimensionsSelector(bomItemPointer),
    isEqual
  )

  if (!dimensions?.weight && !dimensions?.length3D && !dimensions?.length2D) {
    return null
  }

  return (
    <span
      style={{ display: 'flex', gap: '8px', marginRight: '8px', ...rootStyle }}
    >
      {dimensions.weight?.value && (
        <>
          <Typography
            variant="caption"
            color="textSecondary"
            {...typographyProps}
          >
            {QuantityString(dimensions.weight)}
          </Typography>
          <Divider orientation="vertical" flexItem />
        </>
      )}
      {dimensions.length3D && (
        <>
          <DimensionString
            title="3D"
            dimensions={{
              length: dimensions.length3D,
              width: dimensions.width3D,
              height: dimensions.height3D,
            }}
            typographyProps={typographyProps}
          />
          <Divider orientation="vertical" flexItem />
        </>
      )}
      {dimensions.length2D && (
        <>
          <DimensionString
            title="2D"
            dimensions={{
              length: dimensions.length2D,
              width: dimensions.width2D,
              height: undefined,
            }}
            typographyProps={typographyProps}
          />
          {(bomItemPointer.type === BomItemType.assemblyType ||
            bomItemPointer.type === BomItemType.assemblyInstance) && (
            <Divider orientation="vertical" flexItem />
          )}
        </>
      )}
    </span>
  )
}
