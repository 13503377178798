import { groupBy } from 'lodash'
import { PriceSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { AppState } from 'store'

type GroupedMaterialSelectors = {
  materials: {
    priceSummaries: PriceSummaryDto[]
  }
  resources: Record<string, PriceSummaryDto[]>
}

export const priceSummariesGroupedSelector = (state: AppState) => {
  const priceSummaries = Object.values(state.project.priceSummaries)

  const groupedPriceSummaries: GroupedMaterialSelectors = {
    materials: {
      priceSummaries: priceSummaries.filter((summary) => summary.isMaterial),
    },
    resources: groupBy(
      priceSummaries.filter((summary) => summary.isWorkingStep),
      (item) => item.workingStep?.resource?.id
    ),
  }

  return groupedPriceSummaries
}
