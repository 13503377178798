import { Dialog, DialogActions, Typography } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { BomItemName } from 'features/BillOfMaterials/components/BomItemName'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { oldProjectActions } from 'store/Project/ProjectStore'
import { RootAppState } from 'store/configureStore'
import { WorkingStepType } from '../../../../services/APIs/InternalAPI/internal-api.contracts'
import './CostPriceExplanationModal.sass'

type CostPriceExplanationModalProps = {
  projectId: string
  bomItemPointer: BomItemPointer
  workingStepType: WorkingStepType
  isActive: boolean
  onClose: () => void
  getCostPriceExplanation: (
    projectId: string,
    bomItemPointer: BomItemPointer,
    setData: (str: string) => void
  ) => void
  isLoading: boolean
  costPriceExplanation: string
  cleanCurrentCostPriceExplanation: () => void
}

const mapStateToProps = (state: RootAppState) => ({
  projectId: state.project.activeProject?.id,
  isLoading:
    state.app.currentNetworkAction === 'GetCostPriceExplanation' &&
    state.app.isLoading,
  costPriceExplanation: state.project.activeProject.costPriceExplanation,
})

const mapDispatchToProps = (dispatch) => ({
  getCostPriceExplanation: (
    projectId: string,
    bomItemPointer: BomItemPointer,
    setData: (str: string) => void
  ) =>
    dispatch(
      oldProjectActions.GetCostPriceExplanation(
        projectId,
        bomItemPointer.id,
        setData
      )
    ),
  cleanCurrentCostPriceExplanation: () =>
    dispatch(oldProjectActions.CleanCurrentCostPriceExplanation()),
})

const CostPriceExplanationModal = (props: CostPriceExplanationModalProps) => {
  const { t } = useTranslation()
  const emptyExplanation = t('common:no-available-data', 'no available data')
  const [costPriceExplanation, setCostPriceExplanation] = useState(null)
  const {
    getCostPriceExplanation,
    cleanCurrentCostPriceExplanation,
    projectId,
    bomItemPointer,
    workingStepType,
  } = props

  useEffect(() => {
    getCostPriceExplanation(projectId, bomItemPointer, setCostPriceExplanation)

    return () => cleanCurrentCostPriceExplanation()
  }, [
    cleanCurrentCostPriceExplanation,
    getCostPriceExplanation,
    projectId,
    bomItemPointer,
    workingStepType,
  ])

  return (
    <Dialog
      open={props.isActive}
      title="cost price"
      onClose={() => {
        props.onClose()
        props.cleanCurrentCostPriceExplanation()
      }}
      maxWidth={'md'}
      fullWidth
      id="costPriceExplanation-modal"
    >
      <div className="cost-price-explanation--title">
        <RowThumbnailButton
          bomItemPointer={props.bomItemPointer}
          size="medium"
          disableClick
        />
        <div>
          <Typography variant="h6">material cost price explanation</Typography>
          <BomItemName
            bomItemPointer={props.bomItemPointer}
            asLabel
            valueLabelProps={{
              label: '',
              valueProps: {
                variant: 'caption',
              },
            }}
          />
        </div>
      </div>
      <div className="cost-price-explanation--body">
        <LoadingContainer loading={props.isLoading} noMessage variant="spinner">
          <div className="cost-price-explanation--formula">
            <ReactMarkdown>
              {costPriceExplanation || emptyExplanation}
            </ReactMarkdown>
          </div>
        </LoadingContainer>
      </div>
      <DialogActions>
        <LocalizedButton
          translationKey="common:close"
          color="secondary"
          onClick={() => props.onClose()}
        />
      </DialogActions>
    </Dialog>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostPriceExplanationModal)
