import { Box } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import corrosionMapPng from './images/corrosion-map.png?url'
import CorrosionMapSvg from './images/corrosion-map.svg?react'

const useStyles = makeStyles({ name: 'CorrosionMap' })((theme) => ({
  root: {
    backgroundImage: `url(${corrosionMapPng})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    aspectRatio: '548 / 842',
    height: '70vh',
    '& .selected': {
      stroke: theme.palette.primary.main,
      strokeWidth: '1px',
      mask: `url(#mask-stripe)`,
      fill: 'black',
    },
    
  },
}))

export const CorrosionMap = (props: {
  value: string
  onChange: (corrosionClassification: string) => void
}) => {
  const svgRef = React.useRef<HTMLDivElement>(null)
  const { classes } = useStyles()

  React.useEffect(() => {
    if (svgRef.current && props.value) {
      svgRef.current.querySelectorAll('path').forEach((path) => {
        path?.classList.remove('selected')
      })

      svgRef.current
        .querySelector(`#${props.value.toLowerCase()}`)
        ?.classList.add('selected')
    }
  }, [props.value])

  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      props.onChange(e.currentTarget['id'].toUpperCase())
    }

    const allPaths = svgRef.current?.querySelectorAll('path')

    if (allPaths) {
      allPaths.forEach((path) => {
        path.addEventListener('click', handleClick)
        path.style.cursor = 'pointer'
      })
    }

    return () => {
      if (allPaths) {
        allPaths.forEach((path) => {
          path.removeEventListener('click', handleClick)
        })
      }
    }
  }, [props])

  return (
    <Box ref={svgRef} className={classes.root}>
      <CorrosionMapSvg
        style={{
          width: '100%',
          height: '100%',
          cursor: 'click',
        }}
      />
    </Box>
  )
}
