import { groupBy } from 'lodash'

function extractIndex(key: string): number {
  const index = key.match(/_(\d+)/)?.[1]
  return parseInt(index)
}

/**
 * Fix the key of the object to be in the correct order and with indexes starting from 0
 * ie: {
 *  "root_propertyName_1_fieldNameA": string[]
 *  "root_propertyName_1_fieldNameB": string[]
 *  "root_propertyName_2_fieldNameA": string[]
 * } => {
 *  "root_propertyName_0_fieldNameA": string[]
 *  "root_propertyName_0_fieldNameB": string[]
 *  "root_propertyName_1_fieldNameB": string[]
 * }
 * @param {object} obj - The object to fix the keys
 * @returns {object} - The object with the fixed keys
 */
export const fixValidationObjectKeys = <T>(obj: T, removedKey: string): T => {
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  // Get the keys of the object
  const currentValidationKeys = Object.keys(obj)

  // Check if the object has keys with indexes
  const hasIndexes = currentValidationKeys.some((key) => key.match(/_\d+/))

  // If the object has no keys with indexes, return the object as is
  if (!hasIndexes) {
    return obj
  }

  // Create a new object to store the fixed keys
  const newObj: T = {} as T

  currentValidationKeys.sort((a, b) => {
    const indexA = extractIndex(a)
    const indexB = extractIndex(b)
    return indexA - indexB
  })

  const currentKeysGroupedByIndexes = groupBy(currentValidationKeys, (key) => {
    const index = extractIndex(key)
    return index
  })

  const removedIndex = extractIndex(removedKey)
  const firstIndex = extractIndex(currentValidationKeys[0])

  let adjustedIndex = extractIndex(removedKey)

  if (currentKeysGroupedByIndexes[removedIndex]) {
    if (Object.keys(currentKeysGroupedByIndexes).length === 1) {
      adjustedIndex = 0
    } else if (firstIndex > removedIndex) {
      adjustedIndex = removedIndex
    } else {
      adjustedIndex = firstIndex
    }
  } else {
    if (removedIndex < firstIndex) {
      adjustedIndex = firstIndex - 1
    } else {
      adjustedIndex = removedIndex
    }
  }

  if (isNaN(adjustedIndex)) {
    console.info('correctIndex is NaN', removedKey)
    return obj
  }

  for (const index in currentKeysGroupedByIndexes) {
    const keys = currentKeysGroupedByIndexes[index]

    let adjusted = false

    for (const key of keys) {
      if (extractIndex(key) < removedIndex) {
        newObj[key] = obj[key]
        continue
      } else {
        const newKey = key.replace(`_${index}`, `_${adjustedIndex}`)
        newObj[newKey] = obj[key]
        adjusted = true
      }
    }

    if (adjusted) {
      adjustedIndex++
      adjusted = false
    }
  }

  return newObj
}
