import {
  AutocompleteProps,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import AppFilterIcon from '_icons/filterAltOutlined.svg?react'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onProjectStateChange: (projectState: ProjectStateAsString[]) => void
  selectedProjectStates: ProjectStateAsString[]
  children?: React.ReactElement
  autoCompleteProps?: Partial<
    AutocompleteProps<ProjectStateAsString, true, false, false>
  >
}

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({ name: 'project-state-selector' })((theme) => ({
  menuItems: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  menuActions: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const options = Object.values(ProjectStateAsString) as ProjectStateAsString[]

export function ProjectStateSelector(props: Props) {
  const { t } = useTranslation()

  const { classes } = useStyles()
  const [selectedItems, setSelectedItems] = React.useState<
    ProjectStateAsString[]
  >(props.selectedProjectStates)

  useEffect(() => {
    setSelectedItems(props.selectedProjectStates)
  }, [props.selectedProjectStates])

  const timeout = useRef<NodeJS.Timeout>(undefined)

  const handleChange = (_, newValue: ProjectStateAsString[]) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    setSelectedItems(newValue)

    timeout.current = setTimeout(() => {
      props.onProjectStateChange(newValue)
    }, 2000)
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <div>
      <Tooltip
        title={t('common:project-state-filter', 'filter by project state')}
      >
        <Badge badgeContent={selectedItems?.length || 0} color="primary">
          <IconButton
            size="small"
            ref={buttonRef}
            onClick={() => setMenuOpen(true)}
          >
            <AppFilterIcon style={{ width: '24px' }} />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={buttonRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <div className={classes.menuActions}>
          <Button
            variant="text"
            onClick={() => {
              handleChange(null, [])
            }}
          >
            {t('common:clear-all')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              handleChange(null, options)
            }}
          >
            {t('common:select-all')}
          </Button>
        </div>
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              if (selectedItems?.includes(option)) {
                handleChange(
                  null,
                  selectedItems.filter((item) => item !== option)
                )
              } else {
                handleChange(null, [...selectedItems, option])
              }
            }}
            className={classes.menuItems}
            selected={selectedItems?.includes(option)}
          >
            <Switch size="small" checked={selectedItems?.includes(option)} />
            <Typography>
              {option === ProjectStateAsString.CANCELLED
                ? t(`common:state-cancelled-expired`, `cancelled/expired`)
                : t(`project:state-${option.toLowerCase()}`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      {/* <Autocomplete
        {...(props.autoCompleteProps || {})}
        multiple
        id="project-state-selector"
        options={options}
        getOptionLabel={(option) => option as string}
        value={selectedItems}
        fullWidth
        onChange={handleChange}
        disableCloseOnSelect
        renderOption={(renderProps, option, state) => {
          const { key, ...optionProps } = renderProps
          return (
            <li
              key={key}
              {...optionProps}
              style={{ display: 'flex', gap: '1em' }}
            >
              <Switch size="small" checked={state.selected} />
              {t(`project:state-${option.toLowerCase()}`)}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={
              !selectedItems?.length
                ? t('common:project-state-filter', 'filter project states')
                : null
            }
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <Chip
                key={key}
                size="small"
                label={t(`project:state-${option.toLowerCase()}`)}
                variant="outlined"
                style={{ width: '100px', justifyContent: 'space-between' }}
                {...tagProps}
              />
            )
          })
        }}
      />
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {props.children}
        {selectedItems?.length < options.length && (
          <LocalizedButton
            translationKey="common:select-all-project-states"
            size="small"
            onClick={() => {
              props.onProjectStateChange(options as ProjectStateAsString[])
            }}
          />
        )}
      </div> */}
    </div>
  )
}
