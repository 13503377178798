import {
  adaptV4Theme,
  DeprecatedThemeOptions,
  ThemeOptions,
} from '@mui/material'
import Axios from 'axios'
import { isEmpty } from 'lodash'
import React from 'react'
import { TelemetryService } from 'services/Telemetry'
import { SetAppTheme } from 'store/Application/appActions'
import { AppDispatch, useAppDispatch } from 'store/configureStore'
import { tenantActions } from 'store/Tenant/TenantConfigurationReducer'
import { newUserActions } from 'store/User/newUserReducer'

export enum EmbeddedRh24MessageTypes {
  RH24_EMBEDDED_SETUP = 'RH24_EMBEDDED_SETUP',
}

interface IEmbeddedRh24Message {
  type: EmbeddedRh24MessageTypes
  payload: {
    theme?: DeprecatedThemeOptions
    themeV5?: ThemeOptions
    partyId: string
    parentTitle?: string
    parentHref?: string
  }
}

const messageHandler =
  (dispatch: AppDispatch) => (ev: MessageEvent<IEmbeddedRh24Message>) => {
    if (ev.data?.type === EmbeddedRh24MessageTypes.RH24_EMBEDDED_SETUP) {
      console.info('starting self-service portal', ev.data)

      // backwards compatibility
      const payload =
        ev.data.payload ??
        (ev.data as unknown as IEmbeddedRh24Message['payload'])

      const adaptedTheme = isEmpty(payload.themeV5)
        ? adaptV4Theme(payload.theme)
        : payload.themeV5

      dispatch(SetAppTheme(adaptedTheme))

      if (payload.partyId) {
        Axios.defaults.headers['MH-Tenant'] = payload.partyId

        TelemetryService.getInstance().TrackEvent('SelfServicePortalStarted', {
          tenantId: payload.partyId,
        })

        dispatch(newUserActions.setTenantId(payload.partyId))
        dispatch(
          newUserActions.setParentPageInfo({
            parentHref: payload.parentHref,
            parentTitle: payload.parentTitle,
          })
        )

        dispatch(tenantActions.setTenantConfiguration(payload))
      } else {
        console.error('no partyId provided, tenant header not set')
        TelemetryService.getInstance().logError(
          new Error('no partyId provided, tenant header not set')
        )
      }
    }
  }

export const EmbeddedRh24 = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (window.self !== window.top) {
      window.addEventListener('message', messageHandler(dispatch))
      return () =>
        window.removeEventListener('message', messageHandler(dispatch))
    }

    return undefined
  }, [dispatch])

  return null
}
