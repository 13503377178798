import _ from 'lodash'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  MoneyDto,
  MoneyPerQuantityDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { IProjectArticleAPI } from './ProjectArticleController'

export class ProjectArticleAPI
  extends InternalAPI
  implements IProjectArticleAPI
{
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects/${projectId}/articles`)
  }

  public SetArticleSurchargeRatio = async (
    articleId: string,
    surchargeRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: 'SetArticleSurchargeRatio',
      relativePath: `/surcharge`,
      data: {
        surchargeRatio,
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetArticleSurchargeValue = async (
    articleId: string,
    surchargeValue: MoneyDto
  ) => {
    return await this.PutAsync<void>({
      id: 'surchargeValue',
      relativePath: `/surcharge`,
      data: {
        surchargeValue,
        articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetArticleDiscountRatio = async (
    articleId: string,
    discountRatio: QuantityDto
  ) => {
    return await this.PutAsync<void>({
      id: 'SetArticleDiscountRatio',
      relativePath: `/discount`,
      data: {
        discountRatio,
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetArticleDiscountValue = async (
    articleId: string,
    discountValue: MoneyDto
  ) => {
    return await this.PutAsync<void>({
      id: 'discountValue',
      relativePath: `/discount`,
      data: {
        discountValue,
        articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteArticleDiscount = async (articleId: string) => {
    return await this.DeleteAsync({
      id: `delete-discount-${articleId}`,
      relativePath: `/discount`,
      params: {
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteArticleSurcharge = async (articleId: string) => {
    return await this.DeleteAsync({
      id: `delete-surcharge-${articleId}`,
      relativePath: `/surcharge`,
      params: {
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public SetArticleCostPrice = async (
    articleId: string,
    costPrice: MoneyPerQuantityDto
  ) => {
    //removing properties that crash with server side serialization
    const costPriceWithoutSelectableAbbreviations = _.cloneDeep(costPrice)
    delete costPriceWithoutSelectableAbbreviations.quantity
      ?.selectableAbbreviations

    return await this.PutAsync<void>({
      id: `${articleId}_costprice`,
      relativePath: `/costPrice`,
      data: {
        costPrice: costPriceWithoutSelectableAbbreviations,
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }

  public DeleteArticleCostPrice = async (articleId: string) => {
    return await this.DeleteAsync({
      id: `delete-cost-price-${articleId}`,
      relativePath: `/costPrice`,
      params: {
        articleId: articleId,
      },
      onRequestChange: this.onRequestChanged,
    })
  }
}
