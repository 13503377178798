import { LinearProgress } from '@mui/material'
import { isEqual } from 'lodash'
import { useAppSelector } from 'store/configureStore'

export function NetworkWatcher() {
  const reqState = useAppSelector(
    (state) => state.connection.lastRequest,
    isEqual
  )

  if (!reqState?.isLoading) {
    return null
  }

  return (
    <div
      style={{
        position: 'fixed',
        height: '2px',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        backgroundColor: 'transparent',
        zIndex: 9999,
      }}
    >
      <LinearProgress
        color="info"
        style={{
          height: '2px',
          borderRadius: '2px',
        }}
      />
    </div>
  )
}
