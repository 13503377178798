import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Chip,
  InputAdornment,
  ListSubheader,
  TextField,
  useTheme,
} from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  KeywordWithTranslation,
  useKeywordsTranslation,
} from 'components/Common/ArticleSelector/useKeywordsTranslation'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { MaterialsController } from 'controllers/MaterialsController'
import { useAppController } from 'customHooks/useAppController'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { headerFilteredRowsSelector } from 'features/BillOfMaterials/store/selectors/headerFilteredRowsSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
// import './AssemblyKeywords.scss'

type Props = {
  bomItemPointer: BomItemPointer
  disabled?: boolean
  onClose?: () => void
  hideLabel?: boolean
  disableUnderline?: boolean
  // filteredPartTypeIds: string[]
}

export const MaterialKeywordsOptions = (props: {
  baseElProps: React.HTMLAttributes<HTMLLIElement> & { key: string }
  option: KeywordWithTranslation
  state: AutocompleteRenderOptionState
}) => {
  const matches = match(props.option.translatedString, props.state.inputValue)
  const parts = parse(props.option.translatedString, matches)
  const { key, ...rest } = props.baseElProps

  return (
    <li key={key} {...rest}>
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  )
}

export const AssemblyKeywords = (props: Props & { active: boolean }) => {
  const canBeEdited = useAppSelector(
    projectSelectors.canEditRowSelector(props.bomItemPointer)
  )
  const theme = useTheme()
  const { asArrayOfTranslatedKeywords } = useKeywordsTranslation()

  const filteredPartTypePointers = useAppSelector(
    headerFilteredRowsSelector(props.bomItemPointer),
    isEqual
  )

  const { controller, loading } = useAppController(
    () => new MaterialsController()
  )
  const [selectableKeywords, setSelectableKeywords] =
    useState<KeywordWithTranslation[]>(undefined)

  const [selectedKeywords, setSelectedKeywords] = useState<
    KeywordWithTranslation[]
  >([])
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const getSelectableKeywords = React.useCallback(
    async (selectedKeywords: KeywordDto[]) => {
      try {
        const keywords = await controller.GetSelectableKeywordsForAssembly(
          props.bomItemPointer.id,
          selectedKeywords.map((k) => k.originalKeyword)
        )

        setSelectableKeywords(asArrayOfTranslatedKeywords(keywords))
      } catch (err) {
        console.error(err)
        // ShowException('project', err)
      }
    },
    [asArrayOfTranslatedKeywords, controller, props.bomItemPointer.id]
  )

  // React.useEffect(() => {
  //   // if (props.active) {
  //   //     getAvailableKeywords([])
  //   // }

  //   return () => {
  //     setSelectedKeywords([])
  //     setSelectableKeywords([])
  //   }
  // }, [getSelectableKeywords, props.active])

  const handleRemoveKeywords = (token: KeywordWithTranslation) => {
    const newSelectedKeywords = selectedKeywords.filter(
      (x) => x.originalKeyword !== token.originalKeyword
    )

    setSelectedKeywords(newSelectedKeywords)
    getSelectableKeywords(newSelectedKeywords)
  }

  const handleSaveKeywords = async () => {
    try {
      setOpen(false)
      await controller.AddKeywordsToRows({
        keywords: selectedKeywords,
        bomItemPointers: filteredPartTypePointers,
      })
      setSelectedKeywords([])
      setSelectableKeywords(undefined)
      props.onClose && props.onClose()
    } catch (err) {
      ShowException('project', err)
    }
  }

  const projectIsEditable = useAppSelector(ProjectEditableStateSelector)

  if (!projectIsEditable)
    return (
      <div className={'assembly-keywords open assembly-keywords-selector'} />
    )

  return (
    <div className={`assembly-keywords ${props.active ? 'open' : 'closed'}`}>
      <div className="assembly-keywords-selector">
        <Autocomplete
          disableCloseOnSelect
          filterSelectedOptions
          multiple
          open={open}
          onDoubleClick={(e) => e.stopPropagation()}
          autoHighlight
          autoComplete
          handleHomeEndKeys
          disableClearable
          onOpen={() => {
            setOpen(true)
            getSelectableKeywords(selectedKeywords)
          }}
          onClose={() => {
            setOpen(false)
          }}
          options={[
            ...(selectedKeywords || []),
            ...(selectableKeywords || []),
          ].sort((a, b) => a.category.localeCompare(b.category))}
          loading={loading['GetSelectableKeywordsForAssembly']}
          loadingText={`${t('common:loading')}...`}
          renderOption={(props, option, state) => (
            <MaterialKeywordsOptions
              baseElProps={props}
              option={option}
              state={state}
              key={option.originalKeyword}
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option.originalKeyword === value.originalKeyword
          }}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.translatedString
          }
          value={selectedKeywords || []}
          disabled={
            loading['AddKeywordsToRows'] ||
            props.disabled ||
            !filteredPartTypePointers?.length
          }
          // filterOptions={(options, { inputValue }) => {
          //   const selectable = options.filter((o) => o.category)

          //   if (!inputValue) return selectable

          //   return matchSorter(selectable, inputValue, {
          //     keys: ['translatedString'],
          //   })
          // }}
          onChange={(e, value) => {
            if (typeof value === 'string') {
              return
            }
            // setSelectedKeywords((value as { group: string; keyword: string; }[]).map(x => ({ group: '', keyword: x.keyword })))
            setSelectedKeywords(value as KeywordWithTranslation[])

            setSelectableKeywords([])
            getSelectableKeywords(value as KeywordWithTranslation[])
          }}
          groupBy={(option) => option.category}
          renderTags={(options, getTagProps) =>
            options.map((token, index) => {
              const { key, ...rest } = getTagProps({ index })
              return (
                <Chip
                  key={key}
                  size="small"
                  variant="outlined"
                  label={token.translatedString}
                  {...rest}
                  onDelete={() => handleRemoveKeywords(token)}
                />
              )
            })
          }
          renderGroup={(params) => {
            return [
              <ListSubheader
                key={params.key}
                style={{
                  zIndex: 2,
                  backgroundColor: theme.palette.background.paper,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
              >
                {t(`project:material-category-${params.group}`, params.group)}
              </ListSubheader>,
              params.children,
            ]
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={!canBeEdited}
              label={
                !props.hideLabel &&
                t('common:material-keywords', 'material keywords')
              }
              placeholder={
                selectedKeywords.length
                  ? ''
                  : t(
                      'project:keywords-apply-to-parts',
                      'apply material keywords to parts'
                    )
              }
              InputProps={{
                ...params.InputProps,
                disableUnderline: props.disableUnderline,
                endAdornment:
                  selectedKeywords.length > 0 ? (
                    <InputAdornment position="end">
                      <SaveButton
                        translationKey="common:apply"
                        size="small"
                        saving={loading['AddKeywordsToRows']}
                        variant="text"
                        onClick={() => handleSaveKeywords()}
                        style={{ marginLeft: 'auto' }}
                        disabled={
                          loading['AddKeywordsToRows'] ||
                          props.disabled ||
                          !filteredPartTypePointers?.length
                        }
                      />
                      {params.InputProps.endAdornment}
                    </InputAdornment>
                  ) : (
                    params.InputProps.endAdornment
                  ),
              }}
              // helperText={`${loading['GetSelectableKeywordsForAssembly'] ? t('common:loading') : ''}`}
            />
          )}
        />
      </div>
    </div>
  )
}

export default React.memo(AssemblyKeywords, isEqual)
