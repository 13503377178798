import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemTotalProjectQuantity } from './BomItemTotal'
import { BomItemTotalPrice } from './BomItemTotalPrice'
import { BomItemPricePerBatch } from './BomItemPricePerBatch'
import { BomItemQuantity } from './BomItemQuantity'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: theme.spacing(2),
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(0, 2),
  },
}))

export const BomItemDialogFinancials = (props: {
  bomItemPointers: BomItemPointer[]
}) => {
  const { classes } = useStyles()

  if (props.bomItemPointers.length > 1) {
    return null
  }

  return (
    <div className={classes.root}>
      <BomItemQuantity bomItemPointer={props.bomItemPointers[0]} />
      <BomItemTotalProjectQuantity bomItemPointer={props.bomItemPointers[0]} />
      <BomItemPricePerBatch bomItemPointer={props.bomItemPointers[0]} />
      <BomItemTotalPrice bomItemPointer={props.bomItemPointers[0]} />
    </div>
  )
}
