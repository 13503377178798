import { Breakpoint, Theme } from '@mui/material'
import { HTMLProps, PropsWithChildren } from 'react'
import { makeStyles } from 'tss-react/mui'

type StyleProps = {
  maxWidth?: Breakpoint
}

const useStyle = makeStyles<StyleProps>()((theme: Theme, props) => ({
  container: {
    maxWidth: `${theme.breakpoints.values[props.maxWidth || 'lg']}px`,
    margin: '0 auto',
    padding: theme.spacing(0, 5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}))

export const Container = (
  props: { maxWidth?: Breakpoint } & PropsWithChildren<
    HTMLProps<HTMLDivElement>
  >
) => {
  const { classes } = useStyle(props)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, children, maxWidth, ...rest } = props

  return (
    <div className={`${classes.container} ${className}`} {...rest}>
      {children}
    </div>
  )
}
