import {
  CheckOutlined,
  CloseOutlined,
  HomeWorkOutlined,
} from '@mui/icons-material'
import { CircularProgress, IconButton, InputAdornment } from '@mui/material'
import { DefinitiveMoneyField } from 'components/DefinitiveInputs/DefinitiveMoneyField'
import { DefinitiveQuantityField } from 'components/DefinitiveInputs/DefinitiveQuantityField'
import { DefinitiveInputValidators } from 'components/DefinitiveInputs/Validators'
import { ProjectResourceController } from 'controllers/Project/ProjectResourceController'
import { useAppController } from 'customHooks/useAppController'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { SumMoney } from 'model/Money'
import { memo, useCallback, useEffect, useState } from 'react'
import {
  MoneyDto,
  PriceSummaryDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { Key } from 'utils/keyCodes'

type Props = {
  resourceId: string
  priceSummaries: PriceSummaryDto[]
  priceSummaryKey: string
  surchargeOrDiscount: 'discount' | 'surcharge'
}

const _ResourceDiscountOrSurchageEditor = (props: Props) => {
  const { controller, loading } = useAppController(() => {
    return new ProjectResourceController(
      props.resourceId,
      props.priceSummaryKey
    )
  })

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const [isValid, setIsValid] = useState(true)

  const [resourceTotals, setResourceTotals] = useState<{
    surchargeValue?: MoneyDto
    surchargeRatio?: QuantityDto
    discountValue?: MoneyDto
    discountRatio?: QuantityDto
  }>({})

  const setInitialValues = useCallback(() => {
    setResourceTotals(
      props.priceSummaries.reduce((acc, curr) => {
        return {
          surchargeValue: SumMoney([curr.surchargeValue, acc.surchargeValue]),
          surchargeRatio: curr.surchargeRatio,
          discountValue: SumMoney([curr.discountValue, acc.discountValue]),
          discountRatio: curr.discountRatio,
        }
      })
    )
    setChanged(undefined)
  }, [props.priceSummaries])

  useEffect(() => {
    setInitialValues()
  }, [setInitialValues])

  const [changed, setChanged] = useState<'value' | 'ratio' | undefined>(
    undefined
  )

  const handleSave = async () => {
    try {
      if (!isValid) {
        return
      }

      switch (props.surchargeOrDiscount) {
        case 'surcharge': {
          await controller.SetResourceSurcharge({
            surchargeRatio:
              changed === 'ratio' ? resourceTotals.surchargeRatio : undefined,
            surchargeValue:
              changed === 'value' ? resourceTotals.surchargeValue : undefined,
          })

          break
        }
        case 'discount': {
          await controller.SetResourceDiscount({
            discountRatio:
              changed === 'ratio' ? resourceTotals.discountRatio : undefined,
            discountValue:
              changed === 'value' ? resourceTotals.discountValue : undefined,
          })

          break
        }
      }
      setChanged(undefined)
    } catch (err) {
      ShowException('save resource surcharge or discount', err)
    }
  }

  const handleChangeValue = async (money: MoneyDto) => {
    try {
      setResourceTotals((prev) => {
        if (props.surchargeOrDiscount === 'surcharge') {
          return {
            ...prev,
            surchargeValue: money,
          }
        }
        if (props.surchargeOrDiscount === 'discount') {
          return {
            ...prev,
            discountValue: money,
          }
        }

        return prev
      })

      setChanged('value')
    } catch (err) {
      ShowException('unable to set resource discount or surcharge', err)
    }
  }

  const handleChangeRatio = async (quantity: QuantityDto) => {
    try {
      // await controller.SetResourceSurcharge({ surchargeRatio: quantity })
      setResourceTotals((prev) => {
        if (props.surchargeOrDiscount === 'surcharge') {
          return {
            ...prev,
            surchargeRatio: quantity,
          }
        }

        if (props.surchargeOrDiscount === 'discount') {
          return {
            ...prev,
            discountRatio: quantity,
          }
        }

        return prev
      })

      setChanged('ratio')
    } catch (err) {
      ShowException('unable to set resource discount or surcharge', err)
    }
  }

  const handleResetToDefault = async () => {
    try {
      if (props.surchargeOrDiscount === 'surcharge') {
        await controller.DeleteResourceSurcharge()
      }
      if (props.surchargeOrDiscount === 'discount') {
        await controller.DeleteResourceDiscount()
      }
      setChanged(undefined)
    } catch (err) {
      ShowException('unable to reset resource discount or surcharge', err)
    }
  }

  const handleResetToInitial = async () => {
    setInitialValues()
  }

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === Key.Escape) {
          handleResetToInitial()
        }
        if (e.key === Key.Enter) {
          handleSave()
        }
      }}
    >
      <span
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <DefinitiveMoneyField
          moneyDto={
            props.surchargeOrDiscount === 'surcharge'
              ? resourceTotals.surchargeValue
              : resourceTotals.discountValue
          }
          onChange={(money) => {
            handleChangeValue(money)
          }}
          disabled={changed === 'ratio' || !projectIsEditable}
          textFieldProps={{
            style: {
              minWidth: '120px',
            },
            InputProps: {
              endAdornment:
                ((props.surchargeOrDiscount === 'surcharge' &&
                  props.priceSummaries[0].isSurchargeManuallySet) ||
                  (props.surchargeOrDiscount === 'discount' &&
                    props.priceSummaries[0].isDiscountManuallySet)) &&
                projectIsEditable ? (
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '0px',
                    }}
                  >
                    <IconButton
                      onClick={() => handleResetToDefault()}
                      size="small"
                      disabled={!projectIsEditable}
                    >
                      <HomeWorkOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <div style={{ width: '28px' }} />
                ),
            },
          }}
        />
      </span>
      <DefinitiveQuantityField
        quantityDto={
          props.surchargeOrDiscount === 'surcharge'
            ? resourceTotals.surchargeRatio
            : resourceTotals.discountRatio
        }
        onChange={(quantity) => {
          handleChangeRatio(quantity)
        }}
        disabledValue={changed === 'value' || !projectIsEditable}
        disableWarningsIcon
        validation={(discountRatio) => {
          if (props.surchargeOrDiscount === 'discount') {
            return DefinitiveInputValidators.CombineValidators([
              DefinitiveInputValidators.MaxValue(discountRatio?.value, 100),
            ])
          }

          return []
        }}
        onValidation={(validations) => {
          setIsValid(validations.length === 0)
        }}
        textFieldProps={{
          style: {
            minWidth: '120px',
          },
          inputProps: {
            style: {
              paddingRight: '4px',
            },
          },
          sx: {
            '& .MuiInputAdornment-root': {
              paddingRight: '4px',
            },
          },
        }}
      />
      {changed && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              handleResetToInitial()
            }}
            size="small"
          >
            <CloseOutlined />
          </IconButton>
          <IconButton
            onClick={() => {
              handleSave()
            }}
            size="small"
            disabled={loading['setPriceSummary'] || !isValid}
          >
            {loading['setPriceSummary'] ? (
              <CircularProgress size={18} />
            ) : (
              <CheckOutlined color="success" />
            )}
          </IconButton>
        </div>
      )}
    </div>
  )
}

const ProjectResourceDiscountOrSurchageEditor = memo(
  _ResourceDiscountOrSurchageEditor,
  isEqual
)

export default ProjectResourceDiscountOrSurchageEditor
