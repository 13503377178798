import * as React from "react";
const SvgCosts = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 100 100", style: {
  enableBackground: "new 0 0 100 100"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "transparent"
}, points: "81.5,38.8 81.5,34.3 13.1,34.3 13.1,73.7 17.6,73.7 17.6,78.2 86,78.2 86,38.8  " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M87.8,40.5v37.6c0,1-0.8,1.8-1.8,1.8H19.4c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8h64.8V40.5 c0-1,0.8-1.8,1.8-1.8C86.9,38.8,87.8,39.6,87.8,40.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M80.6,33.4H14c-1,0-1.8,0.8-1.8,1.8v37.6c0,1,0.8,1.8,1.8,1.8h66.6c1,0,1.8-0.8,1.8-1.8V35.2 C82.4,34.2,81.6,33.4,80.6,33.4z M78.8,71h-63V37h63V71z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M44.4,60.7l-1.5-1.5c-0.2-0.2-0.4-0.2-0.6,0c-1.4,1.4-3.2,2-5.2,2c-1.6,0-3-0.4-4.2-1.1 c-1.2-0.8-2.1-1.8-2.7-3.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1H38c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4h-8.3 c-0.1,0-0.2-0.1-0.2-0.2c0-0.4-0.1-0.8-0.1-1s0-0.6,0.1-1c0-0.1,0.1-0.2,0.2-0.2H38c0.2,0,0.4-0.2,0.4-0.4v-0.8 c0-0.2-0.2-0.4-0.4-0.4h-7.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.1,0-0.2c0.6-1.3,1.5-2.4,2.7-3.1c1.2-0.8,2.6-1.1,4.2-1.1 c2,0,3.7,0.7,5.2,2c0.2,0.2,0.5,0.2,0.6,0l1.5-1.5c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.8-0.9-1.9-1.5-3-2 c-1.4-0.6-2.9-0.9-4.5-0.9c-1.7,0-3.2,0.3-4.7,0.9c-1.4,0.6-2.6,1.5-3.6,2.6c-1,1.1-1.7,2.4-2.1,3.9c0,0.1-0.1,0.1-0.2,0.1h-2.7 c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h2.3c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0.2c0,0.2,0,0.5,0,1s0,0.8,0,1 c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1h-2.3c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h2.7c0.1,0,0.2,0.1,0.2,0.1 c0.4,1.5,1.1,2.8,2.1,3.9c1,1.1,2.2,2,3.6,2.6c1.4,0.6,3,0.9,4.7,0.9c1.6,0,3.2-0.3,4.5-0.9c1.2-0.5,2.2-1.2,3-2 c0.1-0.1,0.1-0.2,0.1-0.3C44.5,60.9,44.4,60.7,44.4,60.7z" }))));
export default SvgCosts;
