import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { MultiplyMoney, SumMoney } from 'model/Money'
import {
  AssemblyHeaderRow,
  BomItemType,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import {
  MoneyDto,
  PriceRowDto,
  PriceScope,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'

export type CostFactor = {
  description: string
  partTypePointer?: BomItemPointer
  materialId?: string
  workingStepType?: WorkingStepType
  costPrice: MoneyDto
  surchargeValue: MoneyDto
  discountValue: MoneyDto
  salesPrice: MoneyDto
}

export type CostFactorTypes = {
  parts?: CostFactor[]
  material?: CostFactor
  workingStepSetup?: CostFactor
  workingSteps?: CostFactor[]
  workingStepUnload?: CostFactor
}

export type FinancialDetailsData = {
  resourceName: string // will be the key for the graph
  costFactors?: CostFactorTypes
  totals?: {
    // used to fill label data
    surchargeValue?: MoneyDto
    discountValue?: MoneyDto
    salesPrice?: MoneyDto
    costPrice?: MoneyDto
  }
}

export function useGetGraphData(
  bomItemPointer: BomItemPointer,
  priceDetails: PriceRowDto[],
  workingStepsFilter: WorkingStepType[] | undefined,
  priceScope: PriceScope
): FinancialDetailsData[] {
  const { t } = useTranslation()

  if (!priceDetails?.[0]?.children?.[0]?.costPrice) {
    return null
  }

  const totalRow: FinancialDetailsData = {
    resourceName: 'total',
    costFactors: {},
    totals: {
      costPrice: MultiplyMoney(priceDetails?.[0]?.costPrice, 0),
    },
  }

  const includeInTotalRow = (key: string, x: CostFactor) => {
    const costFactor: CostFactor = {
      description: x.description,
      costPrice: SumMoney([
        totalRow.costFactors[key]?.costPrice || MultiplyMoney(x.costPrice, 0),
        x.costPrice,
      ]),
      surchargeValue: SumMoney([
        totalRow.costFactors[key]?.surchargeValue ||
          MultiplyMoney(x.costPrice, 0),
        x.surchargeValue,
      ]),
      discountValue: SumMoney([
        totalRow.costFactors[key]?.discountValue ||
          MultiplyMoney(x.costPrice, 0),
        MultiplyMoney(x.discountValue, -1),
      ]),
      salesPrice: SumMoney([
        totalRow.costFactors[key]?.salesPrice || MultiplyMoney(x.costPrice, 0),
        x.salesPrice,
      ]),
      workingStepType: x.workingStepType,
    }

    if (key.includes('parts.') || key.includes('assemblies.')) {
      if (!totalRow.costFactors.parts) {
        totalRow.costFactors.parts = [
          {
            description: key.split('.')[0],
            costPrice: MultiplyMoney(x.costPrice, 0),
            surchargeValue: MultiplyMoney(x.surchargeValue, 0),
            discountValue: MultiplyMoney(x.discountValue, 0),
            salesPrice: MultiplyMoney(x.salesPrice, 0),
            partTypePointer: {
              id: undefined,
              type: bomItemPointer.type,
            },
          },
        ]
      }

      totalRow.costFactors.parts[0].costPrice = SumMoney([
        totalRow.costFactors.parts[0].costPrice,
        x.costPrice,
      ])

      totalRow.costFactors.parts[0].surchargeValue = SumMoney([
        totalRow.costFactors.parts[0].surchargeValue,
        x.surchargeValue,
      ])

      totalRow.costFactors.parts[0].discountValue = SumMoney([
        totalRow.costFactors.parts[0].discountValue,
        MultiplyMoney(x.discountValue, -1),
      ])

      totalRow.costFactors.parts[0].salesPrice = SumMoney([
        totalRow.costFactors.parts[0].salesPrice,
        x.salesPrice,
      ])
    }

    if (key.includes('workingSteps.')) {
      const workingStepToInclude = key.split('.')[1]

      if (!totalRow.costFactors.workingSteps) {
        totalRow.costFactors.workingSteps = []
      }

      const workingStepIndex = totalRow.costFactors.workingSteps?.findIndex(
        (x) => x.workingStepType === workingStepToInclude
      )

      if (workingStepIndex === -1) {
        totalRow.costFactors.workingSteps.push(costFactor)
      } else {
        const workingStepToChange =
          totalRow.costFactors.workingSteps[workingStepIndex]

        workingStepToChange.costPrice = SumMoney([
          workingStepToChange.costPrice,
          x.costPrice,
        ])

        workingStepToChange.surchargeValue = SumMoney([
          workingStepToChange.surchargeValue,
          x.surchargeValue,
        ])

        workingStepToChange.discountValue = SumMoney([
          workingStepToChange.discountValue,
          MultiplyMoney(x.discountValue, -1),
        ])

        workingStepToChange.salesPrice = SumMoney([
          workingStepToChange.salesPrice,
          x.salesPrice,
        ])
      }
    } else {
      totalRow.costFactors[key.toString()] = costFactor
    }
  }

  if (workingStepsFilter?.length > 0) {
    priceDetails = priceDetails.filter((x) => {
      if (x.isTotalRow) {
        return false
      }

      return x.children.some((x) => {
        return workingStepsFilter.includes(x.workingStep?.secondaryWorkingStep)
      })
    })
  }

  const partsCostFactors = useHandlePartsData(
    bomItemPointer,
    includeInTotalRow,
    priceScope
  )
  const financialDetails: FinancialDetailsData[] = []

  if (partsCostFactors) {
    financialDetails.push({
      resourceName:
        bomItemPointer.type === BomItemType.project
          ? t('project:assemblies')
          : t('project:parts', 'parts'),
      costFactors: {
        parts: partsCostFactors,
      },
      totals: {
        costPrice: SumMoney(partsCostFactors.map((x) => x.costPrice)),
        discountValue: SumMoney(partsCostFactors.map((x) => x.discountValue)),
        surchargeValue: SumMoney(partsCostFactors.map((x) => x.surchargeValue)),
        salesPrice: SumMoney(partsCostFactors.map((x) => x.salesPrice)),
      },
    })
  }

  financialDetails.push(
    ...priceDetails.flatMap((x) => {
      if (x.isTotalRow) {
        return {
          resourceName: 'total',
          costFactors: totalRow.costFactors,
          totals: {
            discountValue: MultiplyMoney(x.discountValue, -1),
            surchargeValue: x.surchargeValue,
            salesPrice: x.salesPrice,
            costPrice: x.costPrice,
          },
        } as FinancialDetailsData
      } else {
        const resourceCosts: FinancialDetailsData = initFinancialDetails(x)

        handleMaterialRow(x, resourceCosts, includeInTotalRow)
        handleWorkingStepSetupRow(x, resourceCosts, includeInTotalRow)
        handleWorkingStepsRows(x, resourceCosts, includeInTotalRow)
        handleWorkingStepUnloadRow(x, resourceCosts, includeInTotalRow)

        return resourceCosts
      }
    })
  )

  return financialDetails
}

function useHandlePartsData(
  bomItemPointer: BomItemPointer,
  includeInTotalRow: (key: string, costFactor: CostFactor) => void,
  priceScope: PriceScope
): CostFactor[] {
  switch (bomItemPointer.type) {
    case BomItemType.assemblyInstance:
    case BomItemType.assemblyType:
      const state = store.getState()

      const assembly = projectSelectors.bomItemSelector(
        state,
        bomItemPointer
      ) as AssemblyHeaderRow

      const partIds =
        assembly?.filteredPartTypePointers ||
        assembly?.filteredPartInstancePointers

      if (!partIds?.length) {
        return null
      }

      const partsCostFactors: CostFactor[] = partIds
        .map((partId) => {
          const partType = projectSelectors.bomItemSelector(
            state,
            partId
          ) as PartTypeRow

          const costFactor: CostFactor = {
            description: partType.name,
            partTypePointer: {
              id: partType.id,
              type: partType.type,
            },
            costPrice:
              priceScope === PriceScope.Total
                ? partType.financial.costPriceOfItems
                : MultiplyMoney(
                    partType.financial.costPricePerItem,
                    partType.financial.quantity
                  ),
            surchargeValue:
              priceScope === PriceScope.Total
                ? partType.financial.surchargeValueOfItems
                : MultiplyMoney(
                    partType.financial.surchargeValuePerItem,
                    partType.financial.quantity
                  ),
            discountValue:
              priceScope === PriceScope.Total
                ? MultiplyMoney(partType.financial.discountValueOfItems, -1)
                : MultiplyMoney(
                    MultiplyMoney(
                      partType.financial.discountValuePerItem,
                      partType.financial.quantity
                    ),
                    -1
                  ),
            salesPrice:
              priceScope === PriceScope.Total
                ? partType.financial.salesPriceOfItems
                : MultiplyMoney(
                    partType.financial.salesPricePerItem,
                    partType.financial.quantity
                  ),
          }

          return costFactor
        })
        .sort((a, b) => b.salesPrice.value - a.salesPrice.value)

      partsCostFactors.forEach((costFactor) => {
        includeInTotalRow(`parts.${costFactor.partTypePointer.id}`, costFactor)
      })

      return partsCostFactors
    case BomItemType.project: {
      const project = store.getState().project

      const assembliesCostFactor: CostFactor[] = project.assemblyHeadersIds
        .map((assemblyId) => {
          const assembly = project.assemblyHeaders[assemblyId]

          const costFactor: CostFactor = {
            description: assembly.name,
            partTypePointer: {
              id: assembly.id,
              type: BomItemType.assemblyType,
            },
            costPrice:
              priceScope === PriceScope.Total
                ? assembly.financial.costPriceOfItems
                : MultiplyMoney(
                    assembly.financial.costPricePerItem,
                    assembly.financial.quantity
                  ),
            surchargeValue:
              priceScope === PriceScope.Total
                ? assembly.financial.surchargeValueOfItems
                : MultiplyMoney(
                    assembly.financial.surchargeValuePerItem,
                    assembly.financial.quantity
                  ),
            discountValue:
              priceScope === PriceScope.Total
                ? MultiplyMoney(assembly.financial.discountValueOfItems, -1)
                : MultiplyMoney(
                    MultiplyMoney(
                      assembly.financial.discountValuePerItem,
                      assembly.financial.quantity
                    ),
                    -1
                  ),
            salesPrice:
              priceScope === PriceScope.Total
                ? assembly.financial.salesPriceOfItems
                : MultiplyMoney(
                    assembly.financial.salesPricePerItem,
                    assembly.financial.quantity
                  ),
          }

          return costFactor
        })
        .sort((a, b) => b.salesPrice.value - a.salesPrice.value)

      assembliesCostFactor.forEach((costFactor) => {
        includeInTotalRow(
          `assemblies.${costFactor.partTypePointer.id}`,
          costFactor
        )
      })

      return assembliesCostFactor
    }

    default:
      return null
  }
}

function handleWorkingStepUnloadRow(
  priceRow: PriceRowDto,
  resourceCosts: FinancialDetailsData,
  includeInTotalRow: (key: string, costFactor: CostFactor) => void
) {
  const workingStepUnloadRow = priceRow.children.find(
    (x) => x.workingStep?.secondaryWorkingStep === WorkingStepType.Unloading
  )

  if (workingStepUnloadRow) {
    resourceCosts.costFactors.workingStepUnload = {
      description: workingStepUnloadRow.description,
      workingStepType: workingStepUnloadRow.workingStep?.secondaryWorkingStep,
      costPrice: workingStepUnloadRow.costPrice,
      surchargeValue: workingStepUnloadRow.surchargeValue,
      discountValue: MultiplyMoney(workingStepUnloadRow.discountValue, -1),
      salesPrice: workingStepUnloadRow.salesPrice,
    }

    includeInTotalRow(
      'workingStepUnload',
      resourceCosts.costFactors.workingStepUnload
    )

    resourceCosts.totals.discountValue = SumMoney([
      resourceCosts.totals.discountValue,
      MultiplyMoney(workingStepUnloadRow.discountValue, -1),
    ])
    resourceCosts.totals.surchargeValue = SumMoney([
      resourceCosts.totals.surchargeValue,
      workingStepUnloadRow.surchargeValue,
    ])
    resourceCosts.totals.salesPrice = SumMoney([
      resourceCosts.totals.salesPrice,
      workingStepUnloadRow.salesPrice,
    ])
  }
}

function handleWorkingStepsRows(
  priceRow: PriceRowDto,
  resourceCosts: FinancialDetailsData,
  includeInTotalRow: (key: string, costFactor: CostFactor) => void
) {
  const workingSteps = priceRow.children.filter(
    (x) =>
      x.isWorkingStep &&
      x.workingStep?.secondaryWorkingStep !== WorkingStepType.Setup &&
      x.workingStep?.secondaryWorkingStep !== WorkingStepType.Loading &&
      x.workingStep?.secondaryWorkingStep !== WorkingStepType.Unloading
  )

  resourceCosts.costFactors.workingSteps = workingSteps.map((x) => {
    resourceCosts.totals.discountValue = SumMoney([
      resourceCosts.totals.discountValue,
      MultiplyMoney(x.discountValue, -1),
    ])
    resourceCosts.totals.surchargeValue = SumMoney([
      resourceCosts.totals.surchargeValue,
      x.surchargeValue,
    ])
    resourceCosts.totals.salesPrice = SumMoney([
      resourceCosts.totals.salesPrice,
      x.salesPrice,
    ])
    resourceCosts.totals.costPrice = SumMoney([
      resourceCosts.totals.costPrice,
      x.costPrice,
    ])

    const costFactor = {
      description: x.description,
      workingStepType: x.workingStep?.secondaryWorkingStep,
      costPrice: x.costPrice,
      surchargeValue: x.surchargeValue,
      discountValue: MultiplyMoney(x.discountValue, -1),
      salesPrice: x.salesPrice,
    }

    includeInTotalRow(
      `workingSteps.${x.workingStep?.secondaryWorkingStep}`,
      costFactor
    )

    return costFactor
  })
}

function handleWorkingStepSetupRow(
  priceRow: PriceRowDto,
  resourceCosts: FinancialDetailsData,
  includeInTotalRow: (key: string, costFactor: CostFactor) => void
) {
  const workingStepSetupRow = priceRow.children.find(
    (x) =>
      x.workingStep?.secondaryWorkingStep === WorkingStepType.Setup ||
      x.workingStep?.secondaryWorkingStep === WorkingStepType.Loading
  )

  if (workingStepSetupRow) {
    resourceCosts.costFactors.workingStepSetup = {
      description: workingStepSetupRow.description,
      workingStepType: workingStepSetupRow.workingStep?.secondaryWorkingStep,
      costPrice: workingStepSetupRow.costPrice,
      surchargeValue: workingStepSetupRow.surchargeValue,
      discountValue: MultiplyMoney(workingStepSetupRow.discountValue, -1),
      salesPrice: workingStepSetupRow.salesPrice,
    }

    includeInTotalRow(
      'workingStepSetup',
      resourceCosts.costFactors.workingStepSetup
    )

    resourceCosts.totals.discountValue = SumMoney([
      resourceCosts.totals.discountValue,
      MultiplyMoney(workingStepSetupRow.discountValue, -1),
    ])
    resourceCosts.totals.surchargeValue = SumMoney([
      resourceCosts.totals.surchargeValue,
      workingStepSetupRow.surchargeValue,
    ])
    resourceCosts.totals.salesPrice = SumMoney([
      resourceCosts.totals.salesPrice,
      workingStepSetupRow.salesPrice,
    ])
    resourceCosts.totals.costPrice = SumMoney([
      resourceCosts.totals.costPrice,
      workingStepSetupRow.costPrice,
    ])
  }
}

function handleMaterialRow(
  priceRowDto: PriceRowDto,
  resourceCosts: FinancialDetailsData,
  includeInTotalRow: (key: string, costFactor: CostFactor) => void
) {
  const materialRow = priceRowDto.children.find((x) => x.materialId)

  if (materialRow) {
    resourceCosts.costFactors.material = {
      description: materialRow.description,
      workingStepType: materialRow.workingStep?.secondaryWorkingStep,
      costPrice: materialRow.costPrice,
      surchargeValue: materialRow.surchargeValue,
      discountValue: MultiplyMoney(materialRow.discountValue, -1),
      salesPrice: materialRow.salesPrice,
      materialId: materialRow.materialId,
    }

    includeInTotalRow('material', resourceCosts.costFactors.material)

    resourceCosts.totals.discountValue = SumMoney([
      resourceCosts.totals.discountValue,
      MultiplyMoney(materialRow.discountValue, -1),
    ])
    resourceCosts.totals.surchargeValue = SumMoney([
      resourceCosts.totals.surchargeValue,
      materialRow.surchargeValue,
    ])
    resourceCosts.totals.salesPrice = SumMoney([
      resourceCosts.totals.salesPrice,
      materialRow.salesPrice,
    ])
    resourceCosts.totals.costPrice = SumMoney([
      resourceCosts.totals.costPrice,
      materialRow.costPrice,
    ])
  }
}

function initFinancialDetails(priceRow: PriceRowDto): FinancialDetailsData {
  const defaultMoney = MultiplyMoney(priceRow.children?.[0].costPrice, 0)

  return {
    resourceName: priceRow.description,
    costFactors: {},
    totals: {
      discountValue: MultiplyMoney(defaultMoney, 0),
      surchargeValue: MultiplyMoney(defaultMoney, 0),
      salesPrice: MultiplyMoney(defaultMoney, 0),
      costPrice: MultiplyMoney(defaultMoney, 0),
    },
  } as FinancialDetailsData
}
