import { Typography } from '@mui/material'
import { MoneyString } from 'model/Money'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { PriceDetailsGraphData } from '../../useBomItemDetails'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

const useStyles = makeStyles()((theme) => ({
  header: { padding: theme.spacing(1), textAlign: 'left' },
  // fields: {
  //   textAlign: 'right',
  // },
}))

export const MaterialTooltip = (props: { data: PriceDetailsGraphData }) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.header}>
      <Typography variant="body1">{t('project:materials')}</Typography>
      <TargetPriceTooltipListItemText
        primary={
          <Typography variant="body1">
            {`${MoneyString(
              props.data.materialSalesPrice || props.data.total
            )}`}
          </Typography>
        }
        secondary={
          <>
            <Typography
              variant="body2"
              color="GrayText"
              component="span"
              style={{ display: 'block' }}
            >
              {props.data.materialDescription ||
                props.data.description ||
                t('project:materials', 'materials')}
            </Typography>
            <Typography variant="caption" color="GrayText">
              {props.data.materialId}
            </Typography>
          </>
        }
        color="secondary"
      />
    </div>
  )
}
