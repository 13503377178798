/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const usePurchasingDataDialogFormControlStyles = makeStyles({
  name: 'PurchasingDataFields',
})((theme: Theme) => ({
  formControlRoot: {
    '& > label:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}))
