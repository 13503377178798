import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  BoMItemActivityArticleDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemAllActivitiesSelector } from './allBomItemActivitiesSelector'

/**
 * Select the article of a BoM item for a specific working step
 * If the working step does not support articles, the raw material article is returned
 * @param bomItemPointer bomItemPointer
 * @param workingStepType workingStepType
 * @returns article
 */
export const bomItemArticlePerWorkingStepSelector =
  (bomItemPointer: BomItemPointer, workingStepType: WorkingStepType) =>
  (state: RootAppState): BoMItemActivityArticleDto | undefined => {
    const bomItemActivities =
      bomItemAllActivitiesSelector(bomItemPointer)(state)

    const activityWithWorkingStep = bomItemActivities.find(
      (activity) => activity.primaryWorkingStep === workingStepType
    )

    if (!activityWithWorkingStep) {
      return null
    }

    if (activityWithWorkingStep.supportsArticle) {
      return activityWithWorkingStep.article
    } else {
      return bomItemActivities.find((x) => x.usesRawMaterial)?.article
    }
  }
