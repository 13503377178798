import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { ProjectState } from 'store/Project/ProjectTypes'

export const useManufacturableFilter = () => {
  const dispatch = useAppDispatch()

  const manufacturabilityCheckFilter = useAppSelector(
    (state: RootAppState) => state.project.filters?.byManufacturability,
    _.isEqual
  )

  const handleChangeFilter = useCallback(
    (
      manufacturabilityStatus: ProjectState['filters']['byManufacturability']['filter']['manufacturabilityStatus']
    ) => {
      dispatch(
        newProjectActions.setFilter({
          filter: {
            type: 'ManufacturabilityCheckFilter',
            manufacturabilityStatus: manufacturabilityStatus,
          },
        })
      )
    },
    [dispatch]
  )

  const filterIsActive = () => {
    return (
      manufacturabilityCheckFilter.active &&
      manufacturabilityCheckFilter.filter.manufacturabilityStatus?.length &&
      manufacturabilityCheckFilter.filter.manufacturabilityStatus !== 'All'
    )
  }

  return {
    handleChangeFilter,
    manufacturabilityCheckFilter,
    filterIsActive,
  }
}
