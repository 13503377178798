import { ChevronRightOutlined } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useFinancialDetailsFormStyles } from './FinancialDetailsForm.styles'
import { PriceRowProps, PriceRowsRender } from './PriceRowRender'

export function PriceDetailsMachineRow(
  props: PriceRowProps & {
    expanded?: boolean
  }
) {
  const { classes, cx } = useFinancialDetailsFormStyles()

  const [open, setOpen] = useState(props.expanded ?? true)

  useEffect(() => {
    setOpen(props.expanded)
  }, [props.expanded])

  return (
    <>
      <li
        style={{
          padding: '1em',
          display: 'flex',
          cursor: 'pointer',
        }}
        className={cx(classes.highlight, {
          [classes.sharedCosts]: props.priceRow.costsAreShared,
        })}
        onClick={() => setOpen((open) => !open)}
      >
        <IconButton size="small">
          <ChevronRightOutlined
            style={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </IconButton>
        <div className={cx(classes.header)}>
          <div className={classes.resourceLabel}>
            <Typography variant="body1" component="p">
              {props.priceRow.description}
            </Typography>
          </div>
        </div>
      </li>
      {open && (
        <ul>
          <PriceRowsRender {...props} priceRows={props.priceRow.children} />
        </ul>
      )}
    </>
  )
}
