import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, { useCallback, useEffect } from 'react'
import { Key } from 'utils/keyCodes'
import { useBomItemDetailsDrawerStyles } from './BomItemDetailsDrawer.styles'
import { BomItemNextAndPreviousArrows } from './BomItemNextAndPreviousArrows'

const titleTranslationKey = {
  [BomItemType.project]: 'project:project',
  [BomItemType.partType]: 'project:part-type',
  [BomItemType.assemblyInstance]: 'project:assembly',
  [BomItemType.assemblyType]: 'project:assembly',
  [BomItemType.materialHeader]: 'project:material',
}

const BomItemDetailsDrawerHeaderComponent = (props: {
  bomItemPointer: BomItemPointer
  handleClose: () => void
}) => {
  const { classes } = useBomItemDetailsDrawerStyles()

  const { handleClose } = props
  const closeOnEscape = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.key === Key.Escape) {
        handleClose()
        return
      }
    },
    [handleClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscape)
    return () => {
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [closeOnEscape])

  return (
    <>
      <LocalizedTypography
        translationKey={
          titleTranslationKey[props.bomItemPointer.type] ??
          titleTranslationKey[BomItemType.project]
        }
        variant="h6"
      />
      <IconButton
        className={classes.closeButton}
        onClick={props.handleClose}
        size="small"
      >
        <CloseOutlined />
      </IconButton>
      <BomItemNextAndPreviousArrows bomItemPointer={props.bomItemPointer} />
    </>
  )
}

export const BomItemDetailsDrawerHeader = React.memo(
  BomItemDetailsDrawerHeaderComponent
)
