import { FeedbackOutlined } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { bomItemNameSelector } from 'features/BillOfMaterials/store/selectors/bomItemNameSelector'
import { bomItemRowNumberSelector } from 'features/BillOfMaterials/store/selectors/bomItemNumberSelector'
import { bomItemUrlSelector } from 'features/BillOfMaterials/store/selectors/bomItemUrlSelector'
import { Issue } from 'model/IssueContext'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { AggregateLevel } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowUserFeedbackForm } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { BomItemMenuItem } from './BomItemMenuItem'

export const ReportRecognitionIssuesMenuItem = ({
  bomItemPointer,
  onClose,
  aggregateLevel,
  onClick,
  initialMessage = '',
  variant = 'menuItem',
  className,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
  aggregateLevel?: AggregateLevel
  onClick?: () => void
  initialMessage?: string
  variant?: 'menuItem' | 'IconButton'
  className?: string
}) => {
  const currentUserEmail = useAppSelector(
    (state: RootAppState) => state.user?.profile?.email
  )

  const { partyId, projectId } = useOrganizationAndProjectContext()
  const partName = useAppSelector(bomItemNameSelector(bomItemPointer))
  const partUrl = useAppSelector(bomItemUrlSelector(bomItemPointer))
  const partRowNumber = useAppSelector(bomItemRowNumberSelector(bomItemPointer))
  const { t } = useTranslation()

  const sellerPartyId = useAppSelector(
    (state) => state.project.activeProject?.sellingParty?.partyId
  )
  const buyerPartyId = useAppSelector(
    (state) => state.project.activeProject?.buyingParty?.partyId
  )

  const dispatch = useAppDispatch()

  const issue: Issue = {
    message: initialMessage,
    tags: [],
    context: {
      organizationId: partyId,
      bomItemPointer: bomItemPointer,
      rowId: bomItemPointer.id,
      projectId: projectId,
      aggregateType: aggregateLevel || AggregateLevel.PartType,
      aggregateName: partName,
      rowNumber: partRowNumber,
      email: currentUserEmail,
      url: partUrl,
      buyerPartyId: buyerPartyId,
      sellerPartyId: sellerPartyId,
    },
  }

  if (variant === 'IconButton') {
    return (
      <Tooltip title={t('project:report-recognition-issues')}>
        <Button
          className={className}
          onClick={() =>
            dispatch(
              ShowUserFeedbackForm({
                show: true,
                issue,
              })
            )
          }
          endIcon={<FeedbackOutlined />}
        ></Button>
      </Tooltip>
    )
  }

  return (
    <BomItemMenuItem
      action={ShowUserFeedbackForm}
      actionParams={{
        show: true,
        issue,
      }}
      primaryTextTranslationKey="project:report-recognition-issues"
      secondaryTextTranslationKey="project:report-recognition-issues-description"
      icon={<FeedbackOutlined />}
      onClose={onClose}
      closeOnClick
      onClick={onClick}
    />
  )
}
