import { Typography, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  errorBadge: {
    position: 'absolute',
    left: theme.spacing(3),
    minWidth: '20px',
    height: '20px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    display: 'flex',
    justifySelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))

export const ErrorCountBadge = (props: { errorCount: number }) => {
  const { classes } = useStyles()
  return (
    !!props.errorCount && (
      <Typography variant="caption" className={classes.errorBadge}>
        {props.errorCount}
      </Typography>
    )
  )
}
