import { UnfoldLessOutlined, UnfoldMoreOutlined } from '@mui/icons-material'
import { Divider, IconButton } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import CostPriceExplanationModal from 'components/ProjectPage/BillOfMaterials/RowDetails/CostPriceExplanationModal'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useState } from 'react'
import {
  ManuallySetProductionTimeArgs,
  PriceRowDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
  useFinancialDetailsFormStyles,
} from './FinancialDetailsForm.styles'
import { PriceRowsRender } from './PriceRowRender'

type Props = {
  bomItemPointer: BomItemPointer
  priceDetails: PriceRowDto[]
  priceScope: PriceScope
  handleResetProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleSaveProductionTime: (
    bomItemPointer: BomItemPointer,
    args: ManuallySetProductionTimeArgs
  ) => Promise<void>
  handleResetAdditionalCosts: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleSaveAdditionalCostPrice: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto,
    priceScope: PriceScope
  ) => Promise<void>
  loading?: Record<string, boolean>
}

export const PriceDetailsForm = (props: Props) => {
  const { classes } = useFinancialDetailsFormStyles()
  const [activeCostPriceExplanation, setActiveCostPriceExplanation] =
    useState(null)

  const { workingStepFilter } = useWorkingStepFilter()

  const [expanded, setExpanded] = useState(false)

  if (!props.priceDetails) {
    return null
  }

  const filteredPriceDetails = props.priceDetails.filter((priceDetail) => {
    if (!workingStepFilter.active) {
      return true
    }

    const workingStepsToInclude = [...workingStepFilter.filter.enabledFilters]

    if (
      workingStepsToInclude.some((f) => {
        return (
          priceDetail.children.findIndex(
            (x) => x.workingStep.secondaryWorkingStep === f
          ) !== -1
        )
      })
    ) {
      return true
    }

    return false
  })

  // const totalRow = props.priceDetails.find((x) => x.isTotalRow)

  return (
    <>
      {activeCostPriceExplanation != null && (
        <CostPriceExplanationModal
          isActive={true}
          bomItemPointer={props.bomItemPointer}
          onClose={() => setActiveCostPriceExplanation(null)}
          workingStepType={activeCostPriceExplanation}
        />
      )}
      <ul className={classes.root}>
        {filteredPriceDetails.length ? (
          <li
            className={classes.values}
            style={{
              textAlign: 'right',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '1em',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <IconButton size="small" onClick={() => setExpanded(true)}>
                <UnfoldLessOutlined />
              </IconButton>
              <IconButton size="small" onClick={() => setExpanded(false)}>
                <UnfoldMoreOutlined />
              </IconButton>
            </div>
            <LocalizedTypography
              translationKey="project:prod-time"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              prod time
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="project:costs-prod"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              costs [prod time]
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="project:costs-additional"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              costs [additional]
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="project:costs-total"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              costs [total]
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="common:surcharge"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              surcharge
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="project:discount"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              discount
            </LocalizedTypography>
            <LocalizedTypography
              translationKey="project:sales-price"
              variant="caption"
              style={{ width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH }}
            >
              sales price
            </LocalizedTypography>
          </li>
        ) : null}

        <PriceRowsRender
          {...props}
          handleSaveProductionTime={(bomItemPointer, priceRow) => {
            return props.handleSaveProductionTime(bomItemPointer, {
              productionTime: priceRow.productionTime,
              priceScope: props.priceScope,
              primaryWorkingStep: priceRow.primaryWorkingStep,
              secondaryWorkingStep: priceRow.secondaryWorkingStep,
            })
          }}
          priceRows={filteredPriceDetails}
          setActiveCostPriceExplanation={setActiveCostPriceExplanation}
          loading={props.loading}
          expanded={!expanded}
        />
        <Divider />
      </ul>
    </>
  )
}
