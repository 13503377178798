import { Theme } from '@mui/material'
import { HTMLProps, PropsWithChildren } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  section: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(6, 3)}}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(6, 6)}}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(18, 9)}`,
    },
  },
}))

export const Section = (
  props: PropsWithChildren<HTMLProps<HTMLDivElement>>
) => {
  const { classes } = useStyles()
  const { children, className, ...rest } = props
  return (
    <div className={`${classes.section} ${className}`} {...rest}>
      {children}
    </div>
  )
}
