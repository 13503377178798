import { bomItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { MoneyString } from 'model/Money'
import {
  AssemblyHeaderRow,
  AssemblyInstanceRow,
  BomItemType,
  PartInstanceRow,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import {
  FinancialDto,
  PriceScope,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { RowThumbnailButton } from '../../../BillOfMaterials/components/BomItemThumbnailButton'
import { TargetPriceTooltipListItemText } from './TargetPriceTooltipListItemText'

export const PartOrAssemblyTooltip = (props: {
  bomItemPointer: BomItemPointer
  priceScope: PriceScope
}) => {
  const { t } = useTranslation()

  const row = useAppSelector((state: RootAppState) =>
    bomItemSelector(state, props.bomItemPointer)
  ) as AssemblyHeaderRow | PartTypeRow | AssemblyInstanceRow | PartInstanceRow

  let thumbnailPath = ''
  let financial: FinancialDto = undefined
  let name = ''

  switch (row?.type) {
    case BomItemType.assemblyType:
    case BomItemType.assemblyInstance: {
      thumbnailPath = !row.representsIndividualParts && row.thumbnail3d
      financial = row.financial
      name = row.name
      break
    }
    case BomItemType.partType:
    case BomItemType.partInstance: {
      thumbnailPath = row.thumbnail3d
      financial = row.financial
      name = row.name
      break
    }
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', gap: '1em' }}
    >
      {thumbnailPath && (
        <RowThumbnailButton
          bomItemPointer={props.bomItemPointer}
          showTooltip
          size="small"
          disableClick
        />
      )}
      <TargetPriceTooltipListItemText
        primary={name}
        secondary={`${t('project:sales-price')}: ${MoneyString(
          props.priceScope === PriceScope.Unit
            ? financial?.salesPricePerItem
            : financial?.salesPriceOfItems
        )}`}
      />
    </div>
  )
}
