import { Issue } from '../../../model/IssueContext'
import { RequestStatus } from '../BaseAPI'
import { ExternalAPI } from './ExternalAPI'

export type CreateIssueResponse = {
  workitemid: string
}

export class IssuesAPI extends ExternalAPI {
  constructor(partyId: string) {
    super()
    this.baseAddress += `/issues/v1/parties/${partyId}`
  }

  public CreateIssue = async (
    issue: Issue,
    onRequestChange: (req: Partial<RequestStatus>) => void
  ) => {
    const resp = await this.PostAsync<CreateIssueResponse>({
      id: 'create-issue',
      relativePath: '',
      data: {
        message: issue.message,
        projectId: issue.context.projectId,
        rowId: issue.context.bomItemPointer.id,
        organizationId: issue.context.organizationId,
        rowNumber: issue.context.rowNumber,
        tags: issue.tags.join(','),
        email: issue.context.email,
        auth0id: issue.context.auth0id,
        url: issue.context.url,
        sellerPartyId: issue.context.sellerPartyId,
        buyerPartyId: issue.context.buyerPartyId,
      },
      onRequestChange,
    })

    return resp
  }
}
