import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AuthService } from '../../../services/auth'
import { ErrorMessage } from '../../../store/Application/appActions'
import RestUtils from '../../../utils/restUtils'

type ImgLoaderProps = {
  src: string
  alt: string
}

const useStyles = makeStyles()(() => ({
  maxWidthImg: {
    maxWidth: '100%',
  },
}))

export const ImgLoader = (props: ImgLoaderProps) => {
  const { classes } = useStyles()

  const [img, setImg] = useState(undefined)
  const [error, setError] = useState(false)

  useEffect(() => {
    const getImage = async () => {
      const accessToken = await AuthService.GetCurrent().GetAccessToken()

      try {
        const res = await RestUtils.request(
          'GET',
          props.src,
          null,
          false,
          false,
          null,
          null,
          accessToken
        )
        if (res.content) {
          setImg(res.content)
        }
      } catch (err) {
        ErrorMessage('image viewer', err['message'])
        setError(true)
        setImg(undefined)
      }
    }

    getImage()
  }, [props.src])

  return (
    <div className="imgloader">
      {error && <div className="subtitle">Error loading the image</div>}
      {!error ? (
        !img ? (
          <CircularProgress />
        ) : (
          <img
            loading="lazy"
            src={`data:image/png;base64, ${img}`}
            alt={props.alt}
            className={classes.maxWidthImg}
          />
        )
      ) : null}
    </div>
  )
}
