/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useFinancialDetailsInputStyles = makeStyles()((theme: Theme) => ({
  resetValueButton: {
    cursor: 'pointer',
    ['&:hover']: {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))
