import { Typography, useTheme } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { CSSProperties, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'

export const PartTypeFinancialGraphLegend = (
  props: LegendProps & {
    bomItemType: BomItemType
  }
) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  return (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        marginTop: '1rem',
        flexWrap: 'wrap',
      }}
    >
      {props.payload.map((entry, index) => {
        const style: CSSProperties = {
          display: 'inner-block',
          width: props.iconSize,
          height: props.iconSize,
          backgroundColor: entry.color,
          marginRight: '0.5rem',
        }

        if (
          entry.payload['dataKey'] === 'costFactors.material.costPrice.value'
        ) {
          entry.value = t('common:material')
        } else if (
          entry.payload['dataKey'] ===
          'costFactors.workingStepSetup.costPrice.value'
        ) {
          entry.value = `${getLocalizedWorkingStep(
            WorkingStepType.Setup
          )} / ${getLocalizedWorkingStep(WorkingStepType.Loading)}`
        } else if (
          entry.payload['dataKey'] ===
          'costFactors.workingStepUnload.costPrice.value'
        ) {
          entry.value = `${getLocalizedWorkingStep(WorkingStepType.Unloading)}`
        } else if (entry.payload['dataKey'].includes('workingSteps')) {
          // only shows 1 legend for working steps (main activity)
          if (entry.payload['dataKey'].includes('[0]')) {
            style.backgroundColor = theme.palette.info.dark
            return (
              <li
                key={`item-${index}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: entry.color,
                }}
              >
                <div style={style} />
                <LocalizedTypography
                  translationKey="project:main-activity"
                  variant="body2"
                >
                  main activity
                </LocalizedTypography>
              </li>
            )
          } else {
            return null
          }
        } else if (entry.payload['dataKey'].includes('parts')) {
          if (entry.payload['dataKey'].includes('[0]')) {
            entry.value =
              props.bomItemType === BomItemType.project
                ? t('project:assemblies')
                : t('project:parts')
          } else {
            return null
          }
        } else if (entry.payload['dataKey'] === 'totals.discountValue.value') {
          style.backgroundColor = 'none'
          style.border = `1px solid ${theme.palette.error.main}`
          style.background = `repeating-linear-gradient(
            -45deg,
            ${'transparent'},
            ${'transparent'} 15%,
            ${theme.palette.error.main} 20%)
            `

          entry.value = t('common:discount')
        } else if (entry.payload['dataKey'] === 'totals.surchargeValue.value') {
          entry.value = t('common:surcharge')
        }

        return (
          <li
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: entry.color,
            }}
          >
            <div style={style} />
            <Typography variant="body2">{entry.value}</Typography>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(PartTypeFinancialGraphLegend, isEqual)
