import { ShowNewProjectForm } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { CreateProjectForm } from './CreateProjectForm'

export const CreateProjectDialog = () => {
  const dispatch = useAppDispatch()

  const open = useAppSelector((state) => state?.app?.newProjectForm?.show)

  if (!open) return null

  const handleClose = () => {
    dispatch(ShowNewProjectForm(false))
  }

  return <CreateProjectForm onClose={handleClose} />
}
