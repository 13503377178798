/* eslint-disable tss-unused-classes/unused-classes */
import { CircularProgress, Theme, Tooltip } from '@mui/material'
import {
  ProjectStateAsString,
  ProjectStatus,
  ProjectStatusLabelAfterCurrentState,
  ProjectStatusLabelBeforeCurrentState,
  ProjectStatusLabelDuringCurrentState,
} from 'controllers/Project/ProjectStateMachine'
import { saveProjectStatus } from 'features/BillOfMaterials/store/asyncActions/saveProjectStatus'
import { projectOperationSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles, withStyles } from 'tss-react/mui'

const LightTooltip = withStyles(Tooltip, (theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    minWidth: '200px',
    padding: 0,
    color: theme.palette.text.primary,
    '& li': {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}))

const useStyles = makeStyles()((theme: Theme) => ({
  active: {
    position: 'relative',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.info.main}`,
    },
  },
  disabled: {
    position: 'relative',
    backgroundColor: theme.palette.action.disabledBackground,
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.action.disabledBackground}`,
    },
  },
  errors: {
    padding: theme.spacing(2),
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    cursor: 'pointer',
  },
  produced: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.success.main}`,
    },
  },
}))

export const useProjectStateItem = (stateItem?: ProjectStatus) => {
  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )

  stateItem = stateItem ?? ProjectStatus[currentProjectStatus]

  const req = useAppSelector(
    projectOperationSelector(saveProjectStatus.typePrefix)
  )

  const { classes } = useStyles()

  const stateItemIsInPast = () => {
    return stateItem < ProjectStatus[currentProjectStatus]
  }

  const stateItemIsInFuture = () =>
    stateItem > ProjectStatus[currentProjectStatus]
  const stateItemIsCurrent = () =>
    stateItem === ProjectStatus[currentProjectStatus]

  const stateItemIsCurrentOrPast = () =>
    stateItemIsCurrent() || stateItemIsInPast()

  const getStateTranslationKey = () => {
    const prefix = 'project:state-'
    let key = ''

    if (stateItemIsInPast()) {
      key =
        ProjectStatusLabelAfterCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    } else if (stateItemIsInFuture()) {
      key =
        ProjectStatusLabelBeforeCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    } else {
      key =
        ProjectStatusLabelDuringCurrentState[
          ProjectStateAsString[ProjectStatus[stateItem]]
        ]
    }

    return prefix + key
  }

  const dispatch = useAppDispatch()

  const changeStatus = (to: ProjectStateAsString) => {
    dispatch(
      saveProjectStatus({
        from: currentProjectStatus,
        to: to,
      })
    )
  }

  const saving =
    req?.requestStatus === 'pending' &&
    req?.arg['to'] === ProjectStateAsString[ProjectStatus[stateItem]]

  const SavingComponent = useCallback(() => {
    if (!saving) {
      return null
    }

    return (
      <CircularProgress
        size={12}
        style={{
          position: 'absolute',
          right: '2em',
          top: '3px',
        }}
        color="inherit"
      />
    )
  }, [saving])

  return {
    currentProjectStatus,
    getStateTranslationKey,
    stateItemIsCurrent,
    stateItemIsInPast,
    classes,
    changeStatus,
    stateItemIsCurrentOrPast,
    stateItemIsInFuture,
    SavingComponent,
    LightTooltip,
  }
}
