/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import ArticleSelectorDialog from 'components/Common/ArticleSelector/ArticleSelectorDialog'
import { AssemblySimplificationDialog } from 'components/Common/AssemblySimplification/AssemblySimplificationDialog'
import { BatchEditRows } from 'features/BillOfMaterials/BatchEditRowsDialog/BatchEditRowsDialog'
import BomItemDetailsV2_Dialog from 'features/BillOfMaterials/BomItemDetailsV2/BomItemDetailsV2_Dialog'
import { CuttingPlanDialog } from 'features/BillOfMaterials/MaterialHeader/ActiveCuttingPlanDialog'
import { BomItemDetailsDrawer } from 'features/BomItemDetailsDrawer/BomItemDetailsDrawer'
import { ConfirmationDialog } from 'features/ConfirmationDialog/ConfirmationDialog'
import { CopyPasteMaterialDrawer } from 'features/CopyAndPasteMaterials/CopyPasteMaterialDrawer'
import { CostPriceDetailsDialog } from 'features/CostPriceDetails/CostPriceDetailsDialog'
import { EventLogsDialog } from 'features/EventLogs/components/EventLogsDialog'
import { ProjectOperationsDialog } from 'features/GeometryLogs/ProjectOperationsLogsDrawer'
import { ProjectProvideRawMaterialSettingsDialog } from 'features/MaterialProvidedSettings/Project/ProjectProvideRawMaterialSettingsDialog'
import { ProjectDetailsDialog } from 'features/ProjectDetailsInfo/components/ProjectDetailsInfoDialog'
import { PurchasingPartDialog } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { SalesRepresentativeDialog } from 'features/SalesRepresentative/components/SalesRepresentativeDialog'
import { SupportContactDialog } from 'features/SupportContactDialog/SupportContactDialog'
import { AdditionalWorkingStepsDialog } from 'features/WorkingSteps/AdditionalWorkingSteps/Dialog/AdditionalWorkingStepsDialog'
import React, { useRef } from 'react'
import { ModalTypes } from 'store/UI/UITypes'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { openedModalsStack } from './openedModalsStack'
import { useDialogManager } from './useDialogManager'

const getDialogComponent = (args: {
  modalType: ModalTypes
  modalProps: any
  onClose: () => void
}) => {
  const modals: Record<ModalTypes, () => React.ReactElement> = {
    ArticleSelector: () => (
      <ArticleSelectorDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    AssemblySimplification: () => (
      <AssemblySimplificationDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    BatchEditBomItems: () => (
      <BatchEditRows {...args.modalProps} open onClose={args.onClose} />
    ),
    CuttingPlanDetails: () => (
      <CuttingPlanDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    BomItemDetailsDrawer: () => (
      <BomItemDetailsDrawer {...args.modalProps} open onClose={args.onClose} />
    ),
    ConfirmationDialog: () => (
      <ConfirmationDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    CostPriceDetails: () => (
      <CostPriceDetailsDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    EventLogs: () => (
      <EventLogsDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    ProjectOperations: () => (
      <ProjectOperationsDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    SalesRepresentative: () => (
      <SalesRepresentativeDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    SupportContact: () => (
      <SupportContactDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    AdditionalWorkingSteps: () => (
      <AdditionalWorkingStepsDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    BomItemDetailsV2: () => (
      <BomItemDetailsV2_Dialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    PurchasingPartDialog: () => (
      <PurchasingPartDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    ProjectDetailsDialog: () => (
      <ProjectDetailsDialog {...args.modalProps} open onClose={args.onClose} />
    ),
    CopyPasteDrawer: () => (
      <CopyPasteMaterialDrawer
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
    ProjectMaterialEstimationSettingsDialog: () => (
      <ProjectProvideRawMaterialSettingsDialog
        {...args.modalProps}
        open
        onClose={args.onClose}
      />
    ),
  }

  return modals[args.modalType]
}

export const DialogManager = () => {
  const activeModal = useAppSelector((state: RootAppState) => state.ui.Modals)

  const { closeDialog, openDialog } = useDialogManager()

  const currentOpenedModalType = useRef(undefined)

  const dialogFactory = getDialogComponent({
    modalType: activeModal?.CurrentModal,
    modalProps: activeModal?.ModalProps,
    onClose: closeDialog,
  })

  if (dialogFactory) {
    // push to opened modals stack
    openedModalsStack.push({
      modalType: activeModal.CurrentModal,
      modalProps: activeModal.ModalProps,
    })

    currentOpenedModalType.current = activeModal.CurrentModal

    return dialogFactory()
  } else {
    // pop from opened modals stack (this will be the currently visible modal)
    let lastOpenedModal = openedModalsStack.pop()

    while (
      lastOpenedModal &&
      lastOpenedModal?.modalType === currentOpenedModalType.current
    ) {
      lastOpenedModal = openedModalsStack.pop()
    }

    if (lastOpenedModal) {
      openDialog(lastOpenedModal.modalType, lastOpenedModal.modalProps, true)
      return null
    } else {
      currentOpenedModalType.current = undefined
      return null
    }
  }
}
