/* eslint-disable react/display-name */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { RowThumbnailButton } from 'features/BillOfMaterials/components/BomItemThumbnailButton'
import {
  bomItemSelector,
  bomItemSelectorFunc,
} from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import _, { isEqual } from 'lodash'
import { AssemblySimplifyModel } from 'model/AssemblySimplifyModel'
import { AssemblyHeaderRow, BomItemType } from 'model/Project/BoMItemRow'
import { AssemblyHeaderPointer } from 'model/Project/BomItemPointer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'
import { DialogCloseSaveButtons } from '../DialogActionButtons/DialogCloseSaveButtons'
import { LoadingContainer } from '../LoadingContainer'
import './AssemblySimplificationDialog.scss'
import { HashGroupTable } from './HashGroupTable'

export type AssemblySimplificationDialogProps = {
  bomItemPointer: AssemblyHeaderPointer
  open: boolean
  onClose?: () => void
}

export type HashGroup = {
  [assemblyId: string]: {
    [hash: string]: {
      partTypeIds: string[]
    }
  }
}

export const AssemblySimplificationDialog = (
  props: AssemblySimplificationDialogProps
) => {
  const { partyId: organizationId, projectId } =
    useOrganizationAndProjectContext()

  const { controller, loading } = useAppController(
    () => new BomItemController(organizationId, projectId)
  )

  const { openDialog } = useDialogManager()

  const assembly = useAppSelector(
    bomItemSelectorFunc(props.bomItemPointer),
    isEqual
  ) as AssemblyHeaderRow

  const assemblyPartTypes = useAppSelector((state: RootAppState) => {
    if (assembly) {
      return assembly.partTypePointers.map((pointer) =>
        bomItemSelector(state, pointer)
      )
    }
    return []
  }, _.isEqual)
  const dispatch = useAppDispatch()

  const [data, setData] = useState<AssemblySimplifyModel[] | undefined>(
    undefined
  )
  const [selectedAssembly, setSelectedAssembly] = useState<
    AssemblyHeaderRow | undefined
  >(undefined)

  const [selectedRows, setSelectedRows] = useState<HashGroup>({})

  React.useEffect(() => {
    ;(async () => {
      try {
        const data = await controller.GetPartTypeGroups()
        if (data?.length) {
          setData(data)
          setSelectedAssembly(assembly)
        }
      } catch (err) {
        ShowException(t('project:assembly-simplification-title'), err)
      }
    })()

    return () => {
      setData(undefined)
      setSelectedAssembly(undefined)
      setSelectedRows({})
      setIsClosing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])

  React.useEffect(() => {
    if (selectedAssembly && !selectedRows[selectedAssembly.id]) {
      setSelectedRows((curr) => {
        const newSelectedRows = { ...curr }
        const assemblyHashGroups = data?.find(
          (x) => x.assemblyId === selectedAssembly.id
        )?.hashGroups

        assemblyHashGroups &&
          Object.keys(assemblyHashGroups).forEach((hashGroup) => {
            Object.assign(newSelectedRows, {
              ...newSelectedRows,
              [selectedAssembly.id]: {
                ...newSelectedRows[selectedAssembly.id],
                [hashGroup]: {
                  partTypeIds: assemblyHashGroups[hashGroup].partTypeIds,
                },
              },
            })
          })

        return newSelectedRows
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedAssembly])

  const [isClosing, setIsClosing] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    props.onClose()
  }

  const handleSave = async () => {
    try {
      openDialog('ConfirmationDialog', {
        title: t('project:assembly-simplification-title'),
        onConfirm: async () => {
          try {
            const promises = Object.keys(selectedRows).map(
              async (assemblyId) => {
                try {
                  await controller.SetPartTypeGroups(
                    assemblyId,
                    _.flatten(
                      Object.values(selectedRows[assemblyId]).map(
                        (x) => x.partTypeIds
                      )
                    ),
                    dispatch
                  )
                } catch (err) {
                  ShowException('assembly simplification', err)
                  throw err
                }
              }
            )

            await Promise.all(promises)
            handleClose()
          } catch (err) {
            openDialog('AssemblySimplification', props)
          }
        },
        onCancel: () => {
          openDialog('AssemblySimplification', props)
        },
      } as ConfirmationDialogProps)
    } catch (err) {
      ShowException('simplification', err)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="lg"
      fullWidth
      className="assembly-simplification"
    >
      <DialogTitle>
        <LocalizedTypography
          translationKey={'project:assembly-simplification-title'}
        >
          assembly simplification
        </LocalizedTypography>
      </DialogTitle>

      <DialogContent className="assembly-simplification-content">
        <DialogContentText>
          {selectedAssembly && (
            <div className="assembly-intro">
              {selectedAssembly?.thumbnail3d ? (
                <RowThumbnailButton
                  bomItemPointer={props.bomItemPointer}
                  disableClick
                  showTooltip
                  size="large"
                />
              ) : null}
              <Typography variant="h6">{selectedAssembly?.name}</Typography>
            </div>
          )}
        </DialogContentText>
        <LoadingContainer
          noMessage
          loading={data === undefined && loading['get-part-type-groups']}
          empty={
            (data === undefined ||
              data.filter((x) => x.assemblyId === props.bomItemPointer.id)
                .length === 0) && (
              <Typography>no duplicated parts found</Typography>
            )
          }
        >
          {data?.length && data?.length > 0 && (
            <>
              <div className="assembly-list"></div>
              {selectedAssembly && (
                <div className="assembly-group">
                  {data
                    ?.filter((x) => x.assemblyId === selectedAssembly.id)
                    ?.map((assemblyData) => {
                      return Object.keys(assemblyData.hashGroups)?.map(
                        (hashGroupKey) => {
                          return (
                            <HashGroupTable
                              key={hashGroupKey}
                              rowDtos={
                                Object.values(assemblyPartTypes).filter(
                                  (x) =>
                                    x?.type === BomItemType.partType &&
                                    assemblyData.hashGroups[
                                      hashGroupKey
                                    ].partTypeIds.includes(x.id)
                                ) as RowDto[]
                              }
                              hashGroupKey={hashGroupKey}
                              selectedAssemblyId={selectedAssembly.id}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              isClosing={isClosing}
                            />
                          )
                        }
                      )
                    })}
                </div>
              )}
            </>
          )}
        </LoadingContainer>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            marginRight: 'auto',
            paddingLeft: '1em',
          }}
        >
          <LocalizedButton
            translationKey="common:select-all"
            onClick={() => {
              const newSelectedRows: HashGroup = {}

              for (const hashgroup of data) {
                Object.keys(hashgroup.hashGroups).forEach((hash) => {
                  newSelectedRows[selectedAssembly.id] = {
                    ...newSelectedRows[selectedAssembly.id],
                    [hash]: {
                      partTypeIds: hashgroup.hashGroups[hash].partTypeIds,
                    },
                  }
                })
              }

              setSelectedRows(newSelectedRows)
            }}
          />
          <LocalizedButton
            translationKey="common:unselect-all"
            onClick={() => {
              const newSelectedRows: HashGroup = {}

              for (const hashgroup of data) {
                Object.keys(hashgroup.hashGroups).forEach((hash) => {
                  newSelectedRows[selectedAssembly.id] = {
                    ...newSelectedRows[selectedAssembly.id],
                    [hash]: {
                      partTypeIds: [],
                    },
                  }
                })
              }

              setSelectedRows(newSelectedRows)
            }}
          />
        </div>

        <DialogCloseSaveButtons
          onCloseButtonClicked={handleClose}
          onSaveButtonClicked={handleSave}
          saveButtonDisabled={
            !Object.keys(selectedRows).find((assemblyId) => {
              const hashgroups = selectedRows[assemblyId]

              return Object.keys(hashgroups).find((hashgroup) => {
                return hashgroups[hashgroup].partTypeIds?.length > 1
              })
            })
          }
        />
      </DialogActions>
    </Dialog>
  )
}
