import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ManagementPartyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { DashboardManagement } from './DashboardManagement'
import { NewFormParties } from './NewFormParties'

type Props = {
  open: boolean
  data: ManagementPartyDto
  onConfirm: (party: ManagementPartyDto) => Promise<ManagementPartyDto>
  isNew: boolean
  onClose: () => void
  onCancel?: () => void
  errors?: {
    validation?: {
      id?: string
      name?: string
      coCKey?: string
      currencyCode?: string
      modules?: string
    }
  }
}

export const SystemPartySettingsDialog = (props: Props) => {
  const { t } = useTranslation()

  const [changedParty, setChangedParty] = useState<ManagementPartyDto>(
    props.data
  )

  const handleCancel = () => {
    props.onCancel?.()
    props.onClose()
  }

  const handleChange = <K extends keyof ManagementPartyDto>(
    key: K,
    value: ManagementPartyDto[K]
  ) => {
    setChangedParty({ ...changedParty, [key]: value })
  }

  const handleSave = async () => {
    try {
      await props.onConfirm(changedParty)
      props.onClose()
    } catch (err) {
      ShowException('party management', err)
    }
  }

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="lg"
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          handleCancel()
        }
      }}
    >
      <DialogTitle>
        {props.isNew
          ? t('system:new-party', 'New Party')
          : t('system:edit-party', 'Edit Party')}
      </DialogTitle>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSave()
        }}
      >
        <DialogContent>
          <NewFormParties
            data={changedParty}
            onChange={handleChange}
            isNew={props.isNew}
            errors={props.errors}
          />
          <Box
            sx={{
              pt: 2,
            }}
          >
            <DashboardManagement partyId={props.data.id} />
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogCloseSaveButtons onCloseButtonClicked={handleCancel} />
        </DialogActions>
      </form>
    </Dialog>
  )
}
