import { Box } from '@mui/material'
import { FieldTemplateProps } from '@rjsf/utils'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { dashify } from 'utils/dashify'

export const FieldTemplate = (props: FieldTemplateProps) => {
  if (props.schema.type === 'object') {
    return (
      <div
        style={{
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          padding: '0 1rem',
        }}
        className="object-field-template"
      >
        {props.uiSchema['ui:title'] ? (
          <LocalizedTypography
            translationKey={`project:ws-${
              props.uiSchema['ui:label-translation-key'] ||
              dashify(props.uiSchema['ui:title'])
            }`}
            variant="h6"
            color="textPrimary"
            // style={{ paddingLeft: '1em' }}
          >
            {props.uiSchema['ui:title']}
          </LocalizedTypography>
        ) : null}
        {/* {props.schema.definitions ? (
          <Typography
            variant="h6"
            color="textPrimary"
            style={{ paddingLeft: '2em' }}
          >
            {props.uiSchema}
          </Typography>
        ) : null} */}
        {/* {props.description} */}
        {props.children}
        {props.errors}
        {props.help}
      </div>
    )
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          gridTemplateColumns: 'repeat(4, 1fr)',
          // border: '1px solid red',
          '& > div': {
            width: '100%',
          },
        }}
        className="here"
      >
        {props.children}
        {/* <Typography variant="body2" color="gray">
            {props.description}
          </Typography> */}
        {/* <div>
          <label htmlFor={props.id}>
            {props.label}
            {props.required ? '*' : null}
          </label>
          {props.children}
          {props.description}
          {props.errors}
          {props.help}
        </div> */}
      </Box>
    )
  }
}
