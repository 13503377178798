import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useShowException } from 'utils/useShowException'

export const UpperSideNotNeededTodo = (props: {
  bomItemPointer: BomItemPointer
  issue: IssueDto
}) => {
  const { getIssueDescription } = useBomItemIssueDescription()
  const ShowException = useShowException('project')

  const { controller } = useAppController(() => new BomItemController())

  const onClick = () => {
    controller
      .SetBomItemUpperSide(props.bomItemPointer.id, {
        sideId: 0,
      })
      .catch(ShowException)
  }

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      color="inherit"
      onClick={onClick}
      startIcon={<ChecklistOutlined />}
    >
      <Typography variant="body2">
        {getIssueDescription(props.issue)}
      </Typography>
    </Button>
  )
}
