/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useKanbanCardStyles = makeStyles()((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.action.hover,
    marginTop: theme.spacing(1),
    width: '250px',
    // height: theme.spacing(35),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    boxShadow: theme.shadows[1],
    ['& .MuiTypography-body1']: {
      fontSize: '12px',
    },
    ['& .MuiTypography-body2']: {
      fontSize: '10px',
    },
    ['& .MuiTypography-caption']: {
      fontSize: '9px',
    },
    '& .kanban-card-progress': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  ['priority-0']: {
    // backgroundColor: 'inherit',
    color: theme.palette.text.primary,
    '& .kanban-grayText': {
      color: theme.palette.text.secondary,
    },
  },
  ['priority-1']: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '& .kanban-grayText': {
      color: theme.palette.error.contrastText,
    },
    '& .kanban-card-progress': {
      display: 'flex',
      justifyContent: 'space-between',
      color: `${theme.palette.error.contrastText} !important`,
      // backgroundColor: theme.palette.warning.dark,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.error.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.error.contrastText,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  ['priority-2']: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '& .kanban-grayText': {
      color: theme.palette.warning.contrastText,
    },
    '& .kanban-card-progress': {
      color: `${theme.palette.warning.contrastText} !important`,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.error.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.warning.contrastText,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  ['priority-3']: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '& .kanban-grayText': {
      color: theme.palette.success.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
    '& .kanban-card-progress': {
      color: `${theme.palette.success.contrastText} !important`,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.success.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.success.contrastText,
      },
    },
  },
  ['priority-4']: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '& .kanban-grayText': {
      color: theme.palette.info.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
    '& .kanban-card-progress': {
      color: `${theme.palette.info.contrastText} !important`,
      '& .MuiLinearProgress-bar': {
        backgroundColor: `${theme.palette.info.contrastText} !important`,
      },
      '& .MuiTypography-root': {
        color: theme.palette.info.contrastText,
      },
    },
  },
  valueLabelRoot: {
    display: 'flex',
    alignItems: 'space-around',
    gap: theme.spacing(2),
  },
  label: {
    // display: 'inline-block',
    flex: '0 0 40%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  value: {
    // display: 'inline-block',
    flex: '0 0 60%',
    paddingRight: theme.spacing(2),
  },
  header: {
    cursor: 'grab',
    backgroundColor: theme.palette.action.hover,
    // boxShadow: theme.shadows[1],
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerMenu: {
    marginTop: theme.spacing(0.5),
    color: 'inherit',
  },
  contentPadding: {
    padding: theme.spacing(1, 2),
  },
  rightAligned: {
    textAlign: 'right',
  },
  grayText: {
    color: theme.palette.text.secondary,
  },
}))
