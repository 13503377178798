import {
  Box,
  Button,
  SxProps,
  Tab,
  Table,
  TableBody,
  Tabs,
} from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FeatureDetailDto,
  FeatureDto,
  FeatureSectionDto,
} from '../../../../services/APIs/InternalAPI/internal-api.contracts'
import { FeatureItem } from './FeatureItem'
import { FeatureSection } from './FeatureSection'

interface Props {
  features: FeatureDto[]
  orientation: 'vertical' | 'horizontal'
  rootBoxSx?: SxProps
  viewController?: ModelController
}

const FeaturesDetails = (props: Props) => {
  const { t } = useTranslation('project')
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [activeTab, setActiveTab] = React.useState<string>(undefined)
  const [activeDetails, setActiveDetails] =
    React.useState<FeatureDetailDto[]>(undefined)
  const [sections, setSections] = React.useState<FeatureSectionDto[]>(undefined)

  React.useEffect(() => {
    if (props.features) {
      const category = props.features.find((x) => x.label === activeTab)
      setActiveDetails(category?.details)
      setSections(category?.sections)
    }

    if (!activeTab && props.features) {
      setActiveDetails(props.features)
      setActiveTab(props.features[0]?.label)
    }
  }, [activeTab, props.features])

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        // height: (theme) => theme.spacing(50),
        // paddingBottom: 2,
        flexDirection: props.orientation === 'vertical' ? 'row' : 'column',
        ...props.rootBoxSx,
      }}
    >
      {props.orientation === 'horizontal' ? (
        <Box
          sx={{
            display: 'grid',
            marginBottom: 2,
            gridTemplateColumns: '1fr 1fr 1fr',
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          {props.features.map((feature, index) => {
            return (
              <Button
                key={feature.label}
                variant={index === activeIndex ? 'contained' : 'text'}
                disableElevation
                disableFocusRipple
                disableRipple
                sx={{ width: '150px' }}
                className={index === activeIndex ? 'active' : ''}
                color={index === activeIndex ? 'primary' : 'inherit'}
                onClick={() => {
                  if (activeTab === feature.label) return
                  setActiveIndex(index)
                  setActiveDetails(undefined)
                  setActiveTab(feature.label)
                }}
              >
                {t(`${feature.label}`, feature.label).toLowerCase()}
              </Button>
            )
          })}
        </Box>
      ) : (
        <Tabs
          orientation={props.orientation}
          variant="scrollable"
          value={activeIndex}
          onChange={(event, newActiveIndex) => {
            setActiveIndex(newActiveIndex)
            setActiveDetails(props.features[newActiveIndex].details)
            setSections(props.features[newActiveIndex].sections)
          }}
          aria-label="part features"
          sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider' }}
        >
          {props.features.map((feature) => {
            return (
              <Tab
                key={feature.label}
                label={t(`${feature.label}`, feature.label).toLowerCase()}
              />
            )
          })}
        </Tabs>
      )}
      {activeDetails ? (
        <Box
          sx={{
            flexGrow: 1,
            // borderBottom: 1,
            // borderColor: 'divider',
          }}
        >
          <Table
            size="small"
            sx={{
              width: '100%',
            }}
          >
            <TableBody>
              {activeDetails
                .sort((a, b) => t(a.label).localeCompare(t(b.label)))
                .map((featureDetails, index) => {
                  return (
                    <FeatureItem
                      key={`${featureDetails.label}-${index}`}
                      featureDetail={featureDetails}
                      viewController={props.viewController}
                    />
                  )
                })}
              {sections && (
                <FeatureSection
                  featureSection={sections}
                  viewController={props.viewController}
                />
              )}
            </TableBody>
          </Table>
        </Box>
      ) : null}
    </Box>
  )
}

export { FeaturesDetails }
