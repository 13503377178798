/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useValueLabelStyles = (
  textAlign?: 'left' | 'right',
  fullWidth?: boolean,
  disableMinWidth?: boolean,
  onClick?: boolean
) =>
  makeStyles({ name: 'ValueLabel' })((theme: Theme) => ({
    // this will reproduce MUI TextField's styles
    valueLabelRoot: {
      // width: '5rem',
      width: fullWidth ? 'fit-content' : 'initial',
      border: '0',
      margin: '0',
      display: 'inline-flex',
      padding: '0',
      paddingBottom: '4px',
      paddingTop: '4px',
      position: 'relative',
      minWidth: disableMinWidth ? 0 : theme.spacing(7),
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '100%',
      alignItems: textAlign === 'left' ? 'flex-start' : 'flex-end',
    },
    label: {
      textAlign: textAlign || 'right',
      transformOrigin: 'top right',
      transform: 'translate(0, -6px)',
      lineHeight: '1.4375em',
    },
    value: {
      textAlign: textAlign || 'right',
      // padding: '0px 0 8px',
      // marginBottom: '5px',
      cursor: onClick ? 'pointer' : 'default',
      textDecorationLine: onClick ? 'underline' : 'none',
      textUnderlineOffset: '4px',
    },
  }))()
