import { useAppLogin } from 'features/Auth/useAppLogin'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Debugger } from 'utils/debugger'
import Spinning from './Common/Spinner/Spinning'

export const OnlyAuthenticated = () => {
  const location = useLocation()

  const { isLoading, isAuthenticated, clearLoginTries } = useAppLogin()

  if (isLoading) {
    return <Spinning fixed />
  }

  const returnUrl = location.pathname + location.search + location.hash
  if (!isAuthenticated) {
    Debugger.Info(
      'routes: not authenticated, will redirect to login',
      returnUrl
    )

    return (
      <Navigate
        to={`/login?returnTo=${returnUrl}`}
        state={{
          returnUrl: returnUrl,
        }}
      />
    )
  } else {
    clearLoginTries()
  }

  Debugger.Info('routes: authenticated', returnUrl)

  return (
    <>
      <Outlet />
    </>
  )
}
