import { Typography } from '@mui/material'
import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

type Props = {
  bomItemPointer: BomItemPointer
  activityId: string
  hidePrices?: boolean
}

const useStyles = makeStyles({ name: 'BomItemCalculatedPrice' })((theme) => ({
  table: {
    width: '100%',
    '& td': {
      textAlign: 'right',
    },
    '& th': {
      textAlign: 'right',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
    },
    rowGap: theme.spacing(1),
  },
}))

export const BomItemMaterialCalculatedPrice = (props: Props) => {
  const { classes } = useStyles()

  const bomItemQuantity = useAppSelector(
    projectSelectors.bomItemQuantitySelector(props.bomItemPointer)
  )

  return (
    <div style={{ padding: '1em' }}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th></th>
            <th>cost price</th>
            <th>surcharge</th>
            <th>discount</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Typography variant="caption">per part:</Typography>
            </td>
            <td>
              <ValueLabelConnected
                propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                  props.bomItemPointer,
                  props.activityId,
                  'costPricePerItem'
                )}
                hideValue={props.hidePrices}
              />
            </td>
            <td>
              <ValueLabelConnected
                propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                  props.bomItemPointer,
                  props.activityId,
                  'surchargeRatio'
                )}
                hideValue={props.hidePrices}
              />
            </td>
            <td>
              <ValueLabelConnected
                propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                  props.bomItemPointer,
                  props.activityId,
                  'discountRatio'
                )}
                hideValue={props.hidePrices}
              />
            </td>
            <td>
              <ValueLabelConnected
                propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                  props.bomItemPointer,
                  props.activityId,
                  'salesPricePerItem'
                )}
                hideValue={props.hidePrices}
              />
            </td>
          </tr>
          {bomItemQuantity > 1 && (
            <tr>
              <td>
                <Typography variant="caption">
                  total ({bomItemQuantity}):
                </Typography>
              </td>
              <td>
                <ValueLabelConnected
                  propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                    props.bomItemPointer,
                    props.activityId,
                    'costPriceOfItems'
                  )}
                  hideValue={props.hidePrices}
                />
              </td>
              <td>
                <ValueLabelConnected
                  propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                    props.bomItemPointer,
                    props.activityId,
                    'surchargeRatio'
                  )}
                  hideValue={props.hidePrices}
                />
              </td>
              <td>
                <ValueLabelConnected
                  propertySelector={projectSelectors.bomItemMaterialFinancialSelector(
                    props.bomItemPointer,
                    props.activityId,
                    'discountRatio'
                  )}
                  hideValue={props.hidePrices}
                />
              </td>
              <td>
                <ValueLabelConnected
                  propertySelector={projectSelectors.bomItemFinancialSelector(
                    props.bomItemPointer,
                    'salesPriceOfItems'
                  )}
                  hideValue={props.hidePrices}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
