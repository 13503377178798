import { CheckOutlined } from '@mui/icons-material'
import { CircularProgress, TextField, TextFieldProps } from '@mui/material'
import { Key } from 'utils/keyCodes'

type Props = {
  loading: boolean
  saveOnEnterFunction?: (value: string) => void
} & TextFieldProps

export const TextFieldWithLoading = (props: Props) => {
  const { loading, saveOnEnterFunction, ...textFieldProps } = props

  const handleSaveOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!saveOnEnterFunction) {
      return true
    }

    if (
      (!props?.multiline && e.key === Key.Enter) ||
      (props?.multiline && e.key === Key.Enter && (e.ctrlKey || e.metaKey))
    ) {
      props.saveOnEnterFunction(e.currentTarget.value)
    }

    return true
  }

  let LoadingOrSaveIcon = null

  if (loading === false) {
    LoadingOrSaveIcon = (
      <CheckOutlined color="success" style={{ width: '12px' }} />
    )
  } else if (loading === true) {
    LoadingOrSaveIcon = (
      <CircularProgress
        size={'12px'}
        style={{
          marginTop: '4px',
        }}
      />
    )
  } else {
    LoadingOrSaveIcon = null
  }

  return (
    <TextField
      {...textFieldProps}
      onKeyDown={handleSaveOnEnter}
      helperText={
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ flex: '1 1 100%' }}>{textFieldProps.helperText}</span>
          {LoadingOrSaveIcon}
        </span>
      }
      onFocus={(e) => e.currentTarget.select()}
      inputProps={{
        ...textFieldProps.inputProps,
        style: {
          ...(textFieldProps.inputProps?.style || {}),
          paddingRight: '3em',
        },
      }}
    />
  )
}
