import { SaveButton } from 'components/Common/Button/SaveButton'
import ShowToRole from 'components/Common/Portal/ShowToRole'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  IssueCode,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { SolveIssueAsync } from '../store/asyncActions/SolveIssueAsync'
import { projectOperationSelector } from '../store/selectors/projectOperationPendingSelector'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { useNotAllowedWorkingStep } from './useNotAllowedWorkingStep'

type Props = {
  bomItemPointer: BomItemPointer
  workingStepType?: WorkingStepType
}

export const SolveWorkingStepNotAvailableButton = ({
  bomItemPointer,
  workingStepType,
}: Props) => {
  const dispatch = useAppDispatch()
  const operation = useAppSelector(
    projectOperationSelector(
      SolveIssueAsync.typePrefix.concat('/', bomItemPointer.id)
    )
  )

  const { hasNotAllowedWorkingStep: anyNotAllowedWorkingStep } =
    useNotAllowedWorkingStep(bomItemPointer, workingStepType)

  const projectInBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  if (projectInBuyerView || !anyNotAllowedWorkingStep) {
    return null
  }

  const handleSolveIssue = () => {
    dispatch(
      SolveIssueAsync({
        bomItemPointer,
        issueCode: IssueCode.WorkingStepNotAvailable,
      })
    )
  }

  return (
    <ShowToRole seller>
      <SaveButton
        variant="outlined"
        color="primary"
        onClick={handleSolveIssue}
        saving={operation?.requestStatus === 'pending'}
        translationKey="project:solve-not-available-working-step-for-this-item"
      />
    </ShowToRole>
  )
}
