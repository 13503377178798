import { Box, Button, Typography } from '@mui/material'
import { ArrayFieldTemplateProps } from '@rjsf/utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { dashify } from 'utils/dashify'

const useStyles = makeStyles({ name: 'ArrayFieldTemplate' })((theme) => ({
  root: {
    '& ul': {
      marginBottom: theme.spacing(2),
    },
    '& > ul > li': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
      textAlign: 'right',
    },
    '& .items': {
      // padding: theme.spacing(2),
    },
    // '& .select-widget': {
    //   margin: theme.spacing(1, 0),
    // },
    '& > ul > li:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  let description = _.get(props.uiSchema, 'ui:options.description')
  description = Boolean(description)
    ? t(`${dashify(description)}`, {
        defaultValue: description,
        ns: 'project',
      })
    : undefined

  return (
    <div className={classes.root}>
      {description && (
        <Box sx={{ pl: 2 }}>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
      )}
      <ul>
        {props.items.map((element, index) => (
          <li key={index}>
            <div className="items">
              {element.children}
              <Button
                color="secondary"
                variant="outlined"
                onClick={(e) => {
                  element.onDropIndexClick(index)(e)
                  props.formContext.onItemRemoved &&
                    props.formContext.onItemRemoved(
                      _.get(element, 'children.props.idSchema.$id')
                    )
                  const errors = Object.keys(
                    element.children.props.idSchema || {}
                  ).reduce((acc, key) => {
                    if (key === '$id') {
                      return acc
                    }
                    acc[element.children.props.idSchema[key].$id] = {
                      __errors: undefined,
                    }

                    return acc
                  }, {})

                  props.formContext.onValidationError(errors)
                }}
                size="small"
              >
                {t(
                  'project:additional-working-steps-remove-item',
                  'remove item'
                )}
              </Button>
            </div>
          </li>
        ))}
      </ul>
      {props.canAdd && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={props.onAddClick}
          >
            {t('project:additional-working-steps-add-item', 'add new item')}
          </Button>
        </div>
      )}
    </div>
  )
}
