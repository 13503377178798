/* eslint-disable tss-unused-classes/unused-classes */
import { Checkbox, FormControlLabel } from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useClientStorage } from 'customHooks/useClientStorage'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { useEffect, useState } from 'react'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeNode } from './FeatureTreeNode'

type Props = {
  features: FeatureDto[]
  modelController: ModelController
  faceIdToFilter?: number
  variant?: 'small'
  onReload: () => void
  featureLabelDefaultExpanded?: string
}

export const FeatureTree = (props: Props) => {
  const [filterByModelSelectedFaces, setFilterByModelSelectedFace] =
    useClientStorage('filterByModelSelectedFaces', true)

  const [hasSelectedItems, setHasSelectedItems] = useState(false)

  const showSyncWithModelSelection = props.features.find(
    (x) =>
      x.details?.find((x) => x.faceIds.length > 0) ||
      x.sections?.find((x) => x.faceIds.length > 0) ||
      x.sections?.find((x) => x.details?.find((x) => x.faceIds.length > 0))
  )

  useEffect(() => {
    if (props.modelController.selectedItems.length > 0) {
      setHasSelectedItems(true)
    }
  }, [props.modelController.selectedItems])

  useEffect(() => {
    const callback = () => {
      if (props.modelController.selectedItems.length > 0) {
        setHasSelectedItems(true)
      } else {
        setHasSelectedItems(false)
      }
    }

    const unregister = props.modelController.onSelectionChanged(callback)

    return () => {
      unregister()
    }
  })

  const handleDeselectAllFaces = () => {
    props.modelController.deselectAll()
  }

  return (
    <>
      <div>
        {showSyncWithModelSelection && (
          <div
            style={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterByModelSelectedFaces}
                  onChange={() =>
                    setFilterByModelSelectedFace(!filterByModelSelectedFaces)
                  }
                  size="small"
                />
              }
              label={
                <LocalizedTypography
                  translationKey="project:sync-with-model-selection"
                  variant="caption"
                >
                  sync with model selection
                </LocalizedTypography>
              }
            />
            {hasSelectedItems && filterByModelSelectedFaces && (
              <LocalizedButton
                translationKey="project:deselectAllFaces"
                defaultValue="deselect all faces"
                variant="text"
                size="small"
                onClick={() => handleDeselectAllFaces()}
              />
            )}
          </div>
        )}
      </div>
      {props.features.map((feature, index) => (
        <>
          <FeatureTreeNode
            key={index}
            feature={feature}
            index={index}
            modelController={props.modelController}
            faceIdToFilter={props.faceIdToFilter}
            variant={props.variant}
            filterByModelSelectedFaces={filterByModelSelectedFaces}
            onReload={props.onReload}
            featureLabelDefaultExpanded={props.featureLabelDefaultExpanded}
          />
        </>
      ))}
    </>
  )
}
