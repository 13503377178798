import { BaseController } from 'controllers/BaseController'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  ProjectDetailsDto,
  UpdateProjectDetailsRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { GetContext } from 'store/storeUtils'

interface IProjectDetailsAPI {
  getProjectDetails: () => Promise<ProjectDetailsDto>
  updateProjectDetails: (req: UpdateProjectDetailsRequest) => Promise<void>
}

export class ProjectDetailsAPI
  extends InternalAPI
  implements IProjectDetailsAPI
{
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChanged: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects/${projectId}/details`)
  }
  public async getProjectDetails() {
    return await this.GetAsync<ProjectDetailsDto>({
      relativePath: '/',
      id: 'get-project-details',
      onRequestChange: this.onRequestChanged,
    })
  }

  public async updateProjectDetails(req: UpdateProjectDetailsRequest) {
    await this.PostAsync({
      relativePath: '/',
      data: req,
      id: 'update-project-details',
      onRequestChange: this.onRequestChanged,
    })
  }
}

export class ProjectDetailsController extends BaseController<IProjectDetailsAPI> {
  constructor() {
    super((onRequestChanged) => {
      const { partyId, projectId } = GetContext()
      return new ProjectDetailsAPI(partyId, projectId, onRequestChanged)
    })
  }

  public async getProjectDetails() {
    try {
      return this.api.getProjectDetails()
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async updateProjectDetails(req: UpdateProjectDetailsRequest) {
    try {
      await this.api.updateProjectDetails(req)
    } catch (error) {
      throw this.HandleError(error)
    }
  }
}
