// https://github.com/rcdexta/react-trello/issues/340#issuecomment-704796291

import React, { PropsWithChildren, useRef, useState } from 'react'
import { LaneScrollContext } from './LaneScrollContext'

const CARD_HEIGHT = 186

export const ScrollableLane = (
  props: PropsWithChildren<Record<string, never>>
) => {
  const [scrollIndex, setScrollIndex] = useState({
    min: 0,
    max: Math.ceil(window.innerHeight / CARD_HEIGHT) + 2,
  })

  const scrollerRef = useRef<HTMLDivElement>(null)

  const cardCount = props.children[0].props.children.length

  return (
    <LaneScrollContext.Provider value={scrollIndex}>
      <div
        style={{ overflow: 'auto', height: '100%' }}
        onScroll={(e) => {
          setScrollIndex((prev) => {
            // we're using the async version of hook setting here
            // because react synthetic event objects are reused e.target may be null here
            if (!e.target) {
              return prev
            }

            const scrollableLaneHeightInCards = Math.ceil(
              (scrollerRef.current?.getBoundingClientRect?.()?.height ??
                window.innerHeight) / 205
            )
            const nextBaseIndex = Math.floor(
              e.target['scrollTop'] / CARD_HEIGHT
            )
            const nextMinIndex = Math.min(
              Math.max(cardCount - scrollableLaneHeightInCards - 2, 0),
              Math.max(nextBaseIndex - 2, 0)
            )
            const nextMaxIndex = nextBaseIndex + scrollableLaneHeightInCards + 2

            if (nextMinIndex === prev.min && nextMaxIndex === prev.max) {
              return prev
            }

            return {
              min: nextMinIndex,
              max: nextMaxIndex,
            }
          })
        }}
        ref={scrollerRef}
      >
        {React.Children.map(props.children, (child: React.ReactNode) => {
          if (child) {
            const marginTop = scrollIndex.min * CARD_HEIGHT
            return React.cloneElement(child as React.ReactElement, {
              // because we are only rendering a subset of our tasks we need to force the scrolling div
              // to have the correct height and use a suitable marginTop so that any tasks from  0 to scrollIndex.min
              // appear to still be there by pushing the rendered cards down to the correct location... hacky :(
              style: {
                minHeight: `${
                  child['props'].children.length * CARD_HEIGHT - marginTop
                }px`,
                marginTop: `${marginTop}px`,
              },
            })
          }
          return null
        })}
      </div>
    </LaneScrollContext.Provider>
  )
}
