import { BaseController } from 'controllers/BaseController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { EventLogDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { GetCurrentProjectId, GetPartyId } from 'store/storeUtils'

interface IEventLogsApi {
  ClearAllLogs: () => Promise<void>
  GetManufacturabilityLogs: (bomItemId: string) => Promise<Array<EventLogDto>>
  GetProjectExportToErpLogs: () => Promise<Array<EventLogDto>>
  GetAssemblyExportToErpLogs: (
    assemblyId: string
  ) => Promise<Array<EventLogDto>>
  GetPartTypeExportToErpLogs: (rowId: string) => Promise<Array<EventLogDto>>
}

export class EventLogsApi extends InternalAPI implements IEventLogsApi {
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(`/api/parties/${partyId}/projects/${projectId}/eventlog`)
  }

  public async ClearAllLogs() {
    return this.DeleteAsync({
      id: 'clear-all-logs',
      relativePath: '/clearAllLogs',
      onRequestChange: this.onRequestChange,
    })
  }
  public async GetManufacturabilityLogs(bomItemId: string) {
    return this.GetAsync<Array<EventLogDto>>({
      id: `get-event-logs`,
      relativePath: `/getManufacturabilityLogs/${bomItemId}`,
      onRequestChange: this.onRequestChange,
    })
  }
  public async GetProjectExportToErpLogs() {
    return this.GetAsync<Array<EventLogDto>>({
      id: `get-event-logs`,
      relativePath: `/GetProjectExportToErpLogs`,
      onRequestChange: this.onRequestChange,
    })
  }
  public async GetAssemblyExportToErpLogs(assemblyId: string) {
    return this.GetAsync<Array<EventLogDto>>({
      id: `get-event-logs`,
      relativePath: `/GetAssemblyExportToErpLogs/${assemblyId}`,
      onRequestChange: this.onRequestChange,
    })
  }
  public async GetPartTypeExportToErpLogs(rowId: string) {
    return this.GetAsync<Array<EventLogDto>>({
      id: `get-event-logs`,
      relativePath: `/GetPartTypeExportToErpLogs/${rowId}`,
      onRequestChange: this.onRequestChange,
    })
  }
}

export class EventLogsController extends BaseController<IEventLogsApi> {
  constructor() {
    const partyId = GetPartyId()
    const projectid = GetCurrentProjectId()
    super((r) => new EventLogsApi(partyId, projectid, r))
  }

  public async ClearAllLogs() {
    try {
      return await this.api.ClearAllLogs()
    } catch (err) {
      this.HandleError(err)
    }
  }

  public async GetEventLogs(
    type: 'manufacturability' | 'erp',
    bomItemPointer: BomItemPointer
  ): Promise<EventLogDto[] | null> {
    try {
      switch (type) {
        case 'manufacturability': {
          return await this.api.GetManufacturabilityLogs(bomItemPointer.id)
        }
        case 'erp': {
          switch (bomItemPointer.type) {
            case BomItemType.project:
              return await this.api.GetProjectExportToErpLogs()
            case BomItemType.assemblyInstance:
            case BomItemType.assemblyType:
              return await this.api.GetAssemblyExportToErpLogs(
                bomItemPointer.id
              )
            case BomItemType.partType:
            case BomItemType.partInstance:
              return await this.api.GetPartTypeExportToErpLogs(
                bomItemPointer.id
              )
            default:
              return null
          }
        }
      }
    } catch (err) {
      return this.HandleError(err)
    }
  }
}
