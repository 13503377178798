import LogRocket from 'logrocket'
import { createRoot } from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'

import { AppContainer } from 'AppContainer'
import 'polyfills'
import './style/index.sass'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

if (
  import.meta.env.VITE_ENVIRONMENT === 'prd' ||
  import.meta.env.VITE_ENVIRONMENT === 'uat'
) {
  LogRocket.init('8jypme/rhodium24')
}

console.info('App version', import.meta.env.VITE_BUILD_NUMBER)

root.render(<AppContainer rootElement={rootElement} />)

// try {
//   const po = new PerformanceObserver((list) => {
//     for (const entry of list.getEntries()) {
//       // Log the entry and all associated details.
//       console.log('long task logger', entry)
//     }
//   })

//   po.observe({ type: 'longtask', buffered: true })
// } catch (e) {
//   // Do nothing if the browser doesn't support this API.
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorker.register({
//   // https://github.com/facebook/create-react-app/issues/5316
//   onUpdate: registration => {
//     alert('New version available! Update it now!')
//     window.location.reload()
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//     }
//   },
//   onSuccess: registration => {
//     while (true) {
//       setTimeout(() => {
//         console.log('calling registration.update')
//         registration.update()
//       }, 2000)
//     }
//   }
// })

// serviceWorker.unregister()
