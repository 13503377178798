import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/configureStore'
import {
  APICall,
  ShowException,
  SuccessMessage,
} from '../../../store/Application/appActions'

export const useFileUploader = (
  url: string,
  networkLabel,
  onSuccess?: (data?: unknown) => void,
  onError?: (message) => void,
  successMessageTitleTKey?: string,
  successMessageTKey?: string
) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<File[]>([])
  const [progress, setProgress] = useState<number>(undefined)

  const dispatch = useAppDispatch()

  const cleanFiles = () => setFiles([])

  const postFiles = () => {
    dispatch(
      APICall({
        label: networkLabel,
        configuration: {
          method: 'POST',
          url: url,
          isFileUpload: true,
          data: files,
          onProgress: (progress) =>
            setProgress(
              parseFloat((progress.loaded / progress.total).toFixed(2))
            ),
          onSuccess: (data) => {
            setFiles([])
            setProgress(undefined)

            if (!onSuccess) {
              SuccessMessage(
                t(
                  successMessageTitleTKey || 'common:import-project',
                  'import project'
                ),
                t(
                  successMessageTKey || 'common-import-project--success',
                  'project imported'
                )
              )
            } else {
              onSuccess(data)
            }
          },
          onError: (message) => {
            ShowException(t('common:import-project'), message)
            setFiles([])
            setProgress(undefined)
            onError && onError(message)
          },
        },
      })
    )
  }

  return {
    files,
    setFiles,
    progress,
    setProgress,
    postFiles,
    cleanFiles,
  }
}
