import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  BoMItemActivityDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemAllActivitiesSelector } from './allBomItemActivitiesSelector'

export const bomItemActivityByWorkingStepSelector =
  (bomItemPointer: BomItemPointer, workingStepType: WorkingStepType) =>
  (state: RootAppState): BoMItemActivityDto | undefined => {
    if (!bomItemPointer || !workingStepType) {
      return undefined
    }

    const bomItemActivities =
      bomItemAllActivitiesSelector(bomItemPointer)(state)

    const activity = bomItemActivities?.find(
      (activity) => activity.primaryWorkingStep === workingStepType
    )

    return activity
  }
