/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Typography } from '@mui/material'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React from 'react'
import { KeywordDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { useKeywordsTranslation } from './useKeywordsTranslation'

type MaterialTagsProps = {
  selectedTokens: Record<string, KeywordDto[]>
  size?: 'small' | 'medium'
  variant?: 'default' | 'text-caption'
  showCategories?: KeywordDto['category'][]
  fallbackString?: string
}

export const BomItemMaterialTags = (
  props: {
    bomItemPointer: BomItemPointer
    activityId: string
  } & Omit<MaterialTagsProps, 'selectedTokens'>
) => {
  const { bomItemPointer, ...rest } = props

  const materialSummary = useAppSelector(
    projectSelectors.bomItemMaterialSummarySelector(
      bomItemPointer,
      props.activityId
    ),
    isEqual
  )

  if (!materialSummary) return null

  return <_MaterialTags {...rest} selectedTokens={materialSummary?.tokens} />
}

const _MaterialTags = (props: MaterialTagsProps) => {
  const { asArrayOfTranslatedKeywords } = useKeywordsTranslation()

  let keywordsWithTranslation = asArrayOfTranslatedKeywords(
    props.selectedTokens,
    { disableSorting: true }
  )

  if (props.showCategories && props.showCategories.length) {
    keywordsWithTranslation = keywordsWithTranslation.filter((x) =>
      props.showCategories?.includes(x.category)
    )
  }

  if (keywordsWithTranslation.length === 0) {
    if (props.fallbackString) {
      return (
        <Typography variant="caption" component="p">
          {props.fallbackString}
        </Typography>
      )
    } else {
      return null
    }
  }

  if (props.variant === 'text-caption') {
    return (
      <div
        style={{
          display: 'flex',
          textAlign: 'left',
          gap: 4,
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant={'caption'}
          style={{ cursor: 'inherit' }}
          color="textSecondary"
          // noWrap
        >
          {keywordsWithTranslation.map((x, i) => {
            return (
              <span
                key={x.originalKeyword}
                style={{ cursor: 'inherit' }}
                color="textSecondary"
              >
                {x.translatedString +
                  (i < keywordsWithTranslation.length - 1 ? ' | ' : '')}
              </span>
            )
          })}
        </Typography>
      </div>
    ) as any
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '4px',
        rowGap: '8px',
        textAlign: 'left',
      }}
    >
      {keywordsWithTranslation.map((x) => (
        <Chip
          key={x.originalKeyword}
          label={x.translatedString}
          size={props.size || 'small'}
          style={{ cursor: 'inherit' }}
          variant="outlined"
        />
      ))}
    </div>
  ) as any
}

const MaterialTags = React.memo(_MaterialTags, isEqual)

export default MaterialTags
