import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { useManufacturableFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ManufacturableFilter/useManufacturableFilter'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useQuoteProjectState } from './useQuoteProjectState'

const notAllowedStatus = [
  ProjectStateAsString.DEFINING,
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
]
const errors = []

export const useOrderProjectState = () => {
  const { Errors: QuotedErrors, errorCount: quotedErrorsCount } =
    useQuoteProjectState()
  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )
  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const manufacturabilityErrors = useAppSelector((state: RootAppState) => {
    return state.project.partTypeIds.find((x) => {
      const partType = state.project.partTypes[x]

      if (
        partType &&
        partType.manufacturableStatus !== 'NotApplicable' &&
        partType.isManufacturable === false
      ) {
        return true
      }

      return false
    })
  })

  /**
   * you can change to order state if
   * - you can change to quote state
   * - the project is not in defining, requested or quoting state
   * - there are no manufacturability errors
   * - you are a salesperson
   */
  const canChangeToOrderState = useMemo(() => {
    if (!isBuyerView) {
      return true
    }

    if (notAllowedStatus.includes(currentProjectStatus)) {
      return false
    }

    if (manufacturabilityErrors) {
      return false
    }

    return true
  }, [
    // canChangeToQuoteState,
    currentProjectStatus,
    isBuyerView,
    manufacturabilityErrors,
  ])

  // const canChangeToOrderState =
  //   canChangeToQuoteState &&
  //   !notAllowedStatus.includes(currentProjectStatus) &&
  //   !manufacturabilityErrors

  const { handleChangeFilter } = useManufacturableFilter()

  const filterRowsWithManufacturabilityIssues = useCallback(() => {
    handleChangeFilter('NotManufacturable')
  }, [handleChangeFilter])

  const [errorCount, setErrorCount] = useState(0)

  useEffect(() => {
    errors.length = 0

    if (manufacturabilityErrors) {
      errors.push(
        <li
          key={'manufacturability-issues'}
          style={{ cursor: 'pointer' }}
          onClick={filterRowsWithManufacturabilityIssues}
        >
          <LocalizedTypography
            translationKey={'project:manufacturability-issues'}
            variant={'body1'}
            color={'error'}
            component={'p'}
          >
            manufacturability issues
          </LocalizedTypography>
          <LocalizedTypography
            translationKey={'project:manufacturability-issues-subtitle'}
            variant={'caption'}
          >
            check the rows with manufacturability issues
          </LocalizedTypography>
        </li>
      )
    }

    if (notAllowedStatus.includes(currentProjectStatus)) {
      errors.push(
        <li key={'invalid-project-state'}>
          <LocalizedTypography
            translationKey={'project:finish-quote-error'}
            variant={'body1'}
            color={'error'}
            component={'p'}
          >
            quote project before order
          </LocalizedTypography>
          <LocalizedTypography
            translationKey={'project:finish-quote-error-subtitle'}
            variant={'caption'}
          >
            finish the quotation before order
          </LocalizedTypography>
        </li>
      )
    }

    setErrorCount(errors.length + quotedErrorsCount)
  }, [
    currentProjectStatus,
    filterRowsWithManufacturabilityIssues,
    manufacturabilityErrors,
    quotedErrorsCount,
  ])

  const Errors = useCallback(() => {
    return (
      <>
        <QuotedErrors />
        <ul>{errors}</ul>
      </>
    )
  }, [QuotedErrors])

  const errorStyle: CSSProperties | undefined = !canChangeToOrderState
    ? {
        cursor: 'not-allowed',
      }
    : undefined

  return {
    canChangeToOrderState,
    Errors,
    errorStyle,
    errorCount,
  }
}
