/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useProjectPriceSummaryDetailsStyles = makeStyles({
  name: 'SummaryDetails',
})((theme: Theme) => ({
  form: {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  priceSummaryGrid: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'grid',
    alignItems: 'end',
    gridRowGap: theme.spacing(0),
    gridColumnGap: theme.spacing(1),
    gridTemplate: `"desc  desc  desc  usage    usage   rate    rate    rate"
                   "costProd  costAdd   cost  surchp  surchv  discp   discv   salesPrice"`,
    gridTemplateColumns: 'repeat(8, 1fr)',
    columnGap: theme.spacing(2),
    '& .reset-button': {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    '& .MuiInputBase-input': {
      paddingBottom: '1px !important',
      textOverflow: 'initial',
      fontSize: theme.typography.body2.fontSize,
    },
  },

  priceSummaryDescription: {
    gridArea: 'desc',
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
  },
  usage: {
    gridArea: 'usage',
    alignSelf: 'center',
    justifySelf: 'flex-start',
  },
  spacer: {
    width: theme.spacing(5),
    display: 'block',
  },
  rate: {
    gridArea: 'rate',
    alignSelf: 'start',
    justifySelf: 'end',
    gridRow: '1',
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.text.secondary,
    '& svg': {
      width: theme.spacing(2),
    },
  },

  filterButton: {
    gap: theme.spacing(1),
    gridArea: 'filter',
    justifySelf: 'start',
    alignSelf: 'center',
    '& svg': {
      width: theme.spacing(2),
    },
  },
  scaledValueTable: {
    gridArea: 'costProd',
    alignSelf: 'start',
    justifySelf: 'left',
    fontSize: theme.typography.caption.fontSize,
    '& .MuiTableCell-root': {
      fontSize: 'inherit',
    },
    '& td': {
      padding: theme.spacing(0.5, 1),
      whiteSpace: 'nowrap',
    },
  },
  priceSummaryValues: {
    display: 'flex',
    gap: theme.spacing(2),
    gridArea: 'value',
    justifySelf: 'end',
    alignSelf: 'end',
    alignItems: 'end',
  },

  active: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  description: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      width: theme.spacing(4),
    },
  },
  estimation: {
    flex: '0 0 10%',
    justifyContent: 'flex-end',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold as never,
    '& svg': {
      width: theme.spacing(4),
    },
  },
  secondaryWorkingStepName: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold as never,
  },
  values: {
    paddingTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '16px repeat(8, minMax(80px, 1fr))',
    gap: theme.spacing(2),
    alignItems: 'flex-end',
    '& svg': {
      color: theme.palette.text.secondary,
      width: theme.spacing(2),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1),
  },
}))
