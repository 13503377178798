import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { projectSelectors } from '../store/selectors/projectSelectors'

const notAllowedWorkingStepsSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    if (bomItemPointer?.type === BomItemType.partType) {
      return projectSelectors.notAllowedWorkingStepsSelector(bomItemPointer)(
        state
      )
    } else {
      return projectSelectors.allNotAllowedWorkingStepsSelector(state)
    }
  }

export const useNotAllowedWorkingStep = (
  bomItemPointer: BomItemPointer,
  workingStepType: WorkingStepType
) => {
  const notAllowedWorkingSteps = useAppSelector(
    notAllowedWorkingStepsSelector(bomItemPointer),
    isEqual
  )

  const workingStepNotAllowed =
    notAllowedWorkingSteps?.includes(workingStepType)

  const allNotAllowedWorkingSteps = useAppSelector(
    projectSelectors.allNotAllowedWorkingStepsSelector,
    isEqual
  )

  const hasOneOfNotAllowedWorkingSteps =
    allNotAllowedWorkingSteps?.includes(workingStepType)

  return {
    workingStepNotAllowed,
    hasNotAllowedWorkingStep:
      notAllowedWorkingSteps && notAllowedWorkingSteps.length > 0,
    hasOneOfNotAllowedWorkingSteps,
  }
}
