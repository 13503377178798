import moment from 'moment'
import { QuantityDto } from '../services/APIs/InternalAPI/internal-api.contracts'
import { formatFn } from './Quantity'

export function TimeString(quantity: QuantityDto, returnDefault = false) {
  if (quantity?.value == null) {
    return returnDefault ? '00h 00m 00s' : ''
  }

  let durationUnit: 'h' | 'm' | 's' | 'ms'

  switch (quantity.unit) {
    case 'hrs': {
      durationUnit = 'h'
      break
    }
    case 'min': {
      durationUnit = 'm'
      break
    }
    case 'sec': {
      durationUnit = 's'
      break
    }
  }

  const duration = moment.duration(quantity.value || 0, durationUnit)

  const m = moment.utc(duration.asMilliseconds())

  return `${formatFn(Math.floor(duration.asHours()))}h ${formatFn(
    m.minute()
  )}m ${formatFn(Math.round(m.second()))}s`
}
