import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
import { projectPricePerBatchSelector } from 'features/BillOfMaterials/store/selectors/projectPricePerBatchSelector'
import { useTranslation } from 'react-i18next'

export function ProjectPricePerBatch(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()
  return (
    <ValueLabelConnected
      propertySelector={projectPricePerBatchSelector}
      valueLabelProps={{
        label: t('project:price-per-batch'),
        classes: props.valueLabelClasses,
        emptyString: '-',
      }}
    />
  )
}
