export enum projectConstants {
  PROJECT_INFO_RECEIVED = 'project/project_info_received',
  SET_POSSIBLE_TOKENS_AND_MATERIALS = 'project/set_possible_tokens_and_materials',
  SET_ROW_MATERIAL = 'project/set_row_material',
  THUMBNAIL_RECEIVED = 'project/thumbnail_received',
  COPY_MATERIAL_TO_CLIPBOARD = 'project/copy_material_to_clipboard',
  UNLOAD_ACTIVE_PROJECT = 'project/unload_active_project',
  DISCARD_CHANGES = 'project/discart_changes',
  SAVE_PROJECT = 'project/SAVE_PROJECT',
  SET_FILTER_PROPERTY = 'project/SET_FILTER_PROPERTY',
  ADD_FILTER_PROPERTY = 'project/ADD_FILTER_PROPERTY',
  TOGGLE_FILTER_ACTIVE = 'project/TOGGLE_FILTER_ACTIVE',
  SET_LAYOUT_TYPE = 'project/SET_LAYOUT_TYPE',
  PROJECT_LIST_RECEIVED = 'projects/project_list_received',
  UNLOAD_PROJECT_LIST = 'projects/UNLOAD_PROJECT_LIST',
  SET_ADDITIONAL_WORKING_STEPS = 'project/SET_ADDITIONAL_WORKING_STEPS',
  SET_ASSEMBLY_QUANTITY = 'project/SET_ASSEMBLY_QUANTITY',
  SET_ASSEMBLY_PROPERTIES = 'project/SET_ASSEMBLY_FINANCIALS',
  SET_ROW_PROPERTIES = 'project/SET_ROW_PROPERTIES',
  SET_PROJECT_PROPERTIES = 'project/SET_PROJECT_PROPERTIES',
  SET_CURRENT_TAB_INDEX = 'project/navigation/SET_CURRENT_TAB_INDEX',
  CLEAN_SELECTED_MATERIAL = 'project/CLEAN_SELECTED_MATERIAL',
  SET_TOKENS = 'project/SET_TOKENS',
  CLOSE_MATERIAL_SELECTOR_MODAL = 'project/CLOSE_MATERIAL_SELECTOR_MODAL',
  OPEN_MATERIAL_SELECTOR_MODAL = 'project/OPEN_MATERIAL_SELECTOR_MODAL',
  REPLACE_ROW = 'project/REPLACE_ROW',
  QUOTATION_DETAILS_SAVED = 'project/QuotationDetailsSaved',
  SET_CURRENT_PRICE_EXPLANATION = 'row/SET_CURRENT_PRICE_EXPLANATION',
  COLLAPSE_HEADERS = 'project/COLLAPSE_HEADERS',
  SET_ASSEMBLY_IS_CLOSED = 'project/SET_ASSEMBLY_IS_CLOSED',
  SET_MATERIAL_IS_CLOSED = 'project/SET_MATERIAL_IS_CLOSED',
  SET_ROW_DETAILS_OPEN = 'project/SET_ROW_DETAILS_OPEN',
  UPDATE_FINANCIALS = 'project/UPDATE_FINANCIALS',
  SET_MARGIN_DATA = 'project/SET_MARGIN_DATA',
  SET_VALIDATION_HIGHLIGHT = 'project/SET_VALIDATION_HIGHTLIGHT',
  REMOVE_ALL_VALIDATION_HIGHTLIGHT = 'REMOVE_ALL_VALIDATION_HIGHTLIGHT',
  REPLACE_PRICE_SUMMARY = 'project/replace_price_summary',
  DELETE_ROW_OR_ASSEMBLY = 'project/DELETE_ROW_OR_ASSEMBLY',
  AddRowToMaterialHeader = 'project/AddRowToMaterialHeader',
  SET_BOM_ITEM_OFFSET = 'project/SET_BOM_ITEM_OFFSET',
  REMOVE_BOM_ITEM = 'project/REMOVE_BOM_ITEM',
  REMOVE_ALL_CALCULATED_FIELDS = 'project/REMOVE_ALL_CALCULATED_FIELDS',
  REMOVE_BOM_ITEM_CALCULATED_FIELDS = 'project/REMOVE_BOM_ITEM_CALCULATED_FIELDS',
  SET_BOM_ITEM_RESOURCE = 'project/SET_BOM_ITEM_RESOURCE',
  SET_BOM_ITEM_ATTACHMENT = 'SET_BOM_ITEM_ATTACHMENT',
  REMOVE_BOM_ITEM_ATTACHMENT = 'REMOVE_BOM_ITEM_ATTACHMENT',
  REFRESH_WORKING_STEP_FILTER = 'project/REFRESH_WORKING_STEP_FILTER',
  REFRESH_MATERIAL_KEYWORD_FILTER = 'REFRESH_MATERIAL_KEYWORD_FILTER',
  UPDATE_HEADER_FILTERED_PARTS = 'UPDATE_HEADER_FILTERED_PARTS',
  SET_PROJECT_OPERATION_PENDING = 'SET_PROJECT_OPERATION_PENDING',
  REFRESH_ISSUES_FILTER = 'REFRESH_ISSUES_FILTER',
  OPEN_OR_CLOSE_HEADERS = 'OPEN_OR_CLOSE_HEADERS',
  SET_BOM_ITEM_FOCUS = 'SET_BOM_ITEM_FOCUS',
}
