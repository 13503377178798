import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import {
  forgetAsPurchasingPart,
  rememberAsPurchasingPart,
} from 'features/BillOfMaterials/store/asyncActions/rememberAsPurchasingPart'
import { bomItemActivityByWorkingStepSelector } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivityByWorkingStepSelector'
import { bomItemIsPurchasingPartSelector } from 'features/BillOfMaterials/store/selectors/bomItemIsPurchasingPartSelector'
import { bomItemIsRememberedPurchasingBoMItemSelector } from 'features/BillOfMaterials/store/selectors/bomItemIsRememberedPurchasingBoMItemSelector'
import { bomItemActivityArticleSelector } from 'features/BillOfMaterials/store/selectors/bomItemMaterialSummarySelector'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'
import { BomItemMenuItem } from './BomItemMenuItem'

export const RememberOrForgetGeometryAsPurchasingMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const isPurchasing = useAppSelector(
    bomItemIsPurchasingPartSelector(bomItemPointer)
  )

  const purchasingActivity = useAppSelector(
    bomItemActivityByWorkingStepSelector(
      bomItemPointer,
      WorkingStepType.Purchasing
    )
  )

  const isRememberedPurchasingBoMItem = useAppSelector(
    bomItemIsRememberedPurchasingBoMItemSelector(bomItemPointer)
  )

  const activityArticle = useAppSelector(
    bomItemActivityArticleSelector(bomItemPointer, purchasingActivity?.id),
    isEqual
  )

  if (!isPurchasing) {
    return null
  }

  if (isRememberedPurchasingBoMItem) {
    return (
      <BomItemMenuItem
        action={forgetAsPurchasingPart}
        actionParams={{
          bomItemPointer,
        }}
        primaryTextTranslationKey="project:vertical-menu-forget-geometry-purchasing-item"
        secondaryTextTranslationKey="project:vertical-menu-forget-geometry-purchasing-item-desc"
        onClose={onClose}
        closeAfterFullfilled
        icon={
          <WorkingStepIconSvg
            workingStepType={WorkingStepType.Purchasing}
            attributes={{
              width: '2rem',
            }}
          />
        }
      />
    )
  }

  if (!isRememberedPurchasingBoMItem && activityArticle?.id) {
    // shows only when material is selected
    return (
      <BomItemMenuItem
        hiddenForBuyers
        action={rememberAsPurchasingPart}
        actionParams={{
          bomItemPointer,
          articleId: activityArticle.id,
        }}
        primaryTextTranslationKey="project:vertical-menu-remember-geometry-purchasing-item"
        secondaryTextTranslationKey="project:vertical-menu-remember-geometry-purchasing-item-desc"
        onClose={onClose}
        closeAfterFullfilled
        icon={
          <WorkingStepIconSvg
            workingStepType={WorkingStepType.Purchasing}
            attributes={{
              width: '2rem',
            }}
          />
        }
      />
    )
  }

  return null
}
