import { Typography, useTheme } from '@mui/material'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { RemoveFiltersIcon } from 'features/BillOfMaterials/ProjectHeader/RemoveFiltersIcon'
import { QuantityString } from 'model/Quantity'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { useGraphsTooltipStyles } from './MarginGraphTooltip'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WeightGraphProps = {
  data: { description: string; weight: QuantityDto }[]
}

const CustomTooltip = (props: {
  active?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any[]
  label?: string | number
}) => {
  const { classes } = useGraphsTooltipStyles()

  if (!props.payload || !props.payload.length) return null

  if (props.active) {
    return (
      <div className={classes.customTooltip}>
        <Typography variant="subtitle1">{props.label}</Typography>
        <div className={classes.usage}>
          <strong>{QuantityString(props.payload[0].payload.weight)}</strong>
        </div>
      </div>
    )
  }
  return null
}

const MaterialLabel = (props: { x; y; payload }) => {
  const theme = useTheme()

  return (
    <g>
      <Text
        {...props}
        fill="black"
        fontSize={theme.typography.body2.fontSize}
        width={200}
        lineHeight="1.25em"
        style={{ cursor: 'pointer' }}
      >
        {props.payload.value}
      </Text>
    </g>
  )
}

const WeightGraph = () => {
  const data = useAppSelector((state: RootAppState) => {
    return Object.values(state.project.priceSummaries)
      .filter((x) => x?.isMaterial)
      .map((x) => ({
        description: x.description,
        weight: x.weight,
        value: x.weight.value,
        materialId: x.materialId,
      }))
      .sort((a, b) => b.value - a.value)
  })

  const { handleChangeFilter } = useArticleIdFilter()

  return (
    <>
      <div
        style={{
          height: `${Object.values(data).length * 90}px`,
          minHeight: '220px',
          paddingRight: '32px',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: 0, left: '1em' }}>
          <RemoveFiltersIcon />
        </div>
        <ResponsiveContainer height={'100%'}>
          <BarChart
            data={data}
            margin={{ top: 16 }}
            layout="vertical"
            barCategoryGap={16}
            barGap={16}
            barSize={32}
            onClick={(next) =>
              next &&
              handleChangeFilter([data[next?.activeTooltipIndex]?.materialId])
            }
            style={{ cursor: 'pointer' }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={(props) => <CustomTooltip {...props} />} />
            <Bar dataKey="value" name="kilogram" fill="#8884d8" height={20} />
            <YAxis
              dataKey="description"
              type="category"
              axisLine={false}
              width={240}
              stroke={'2px solid black'}
              tick={(props) => <MaterialLabel {...props} />}
              tickMargin={8}
              minTickGap={0}
            />
            <XAxis
              label={{
                value: 'kg',
                position: 'top',
              }}
              orientation="top"
              type="number"
            />
            <Legend
              verticalAlign="top"
              layout="vertical"
              align="center"
              height={36}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export { WeightGraph }
