import { SearchOutlined } from '@mui/icons-material'
import {
  Autocomplete,
  ListItemText,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { matchSorter } from 'match-sorter'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import {
  fuzzySearchMultipleWords,
  markTextSplitBySpaces,
} from 'utils/textFilterUtils'
import { MaterialSummaryDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'
import './AvailableArticles.sass'

export type AvailableArticlesProps = {
  materials: MaterialSummaryDto[]
  selectedMaterial?: MaterialSummaryDto
  onMaterialSelected: (
    material: MaterialSummaryDto,
    shouldSave?: boolean
  ) => void
  width: string
  maxHeight?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  optionRoot: {
    '& mark': {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
      // padding: theme.spacing(0, 0.5),
    },
  },
}))

const filterOptions = (
  options: Array<MaterialSummaryDto>,
  currentFilter: string
) =>
  fuzzySearchMultipleWords(
    options,
    [
      { key: 'id', minRanking: matchSorter.rankings.CONTAINS },
      { key: 'description', threshold: matchSorter.rankings.CONTAINS },
    ],
    currentFilter
  )

// const debouncedSearch = _.debounce(filterOptions, 500)

export const AvailableArticles = (props: AvailableArticlesProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation()
  const [showMark, setShowMark] = React.useState(false)

  const [currentFilter, setCurrentFilter] = React.useState('')

  return (
    <div className="available-materials" style={{ width: props.width }}>
      {props.materials?.length > 0 ? (
        <Autocomplete
          options={props.materials}
          noOptionsText={t('project:article-selector--no-available-articles')}
          clearOnEscape
          disablePortal
          freeSolo
          open={true}
          ListboxProps={{
            style: {
              maxHeight: props.maxHeight || '50vh',
              // position: 'relative',
            },
          }}
          autoSelect
          handleHomeEndKeys
          PaperComponent={(props) => <Paper {...props} elevation={0} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={() => currentFilter} // `${option.id} - ${option.description}`
          value={props.selectedMaterial || null}
          onChange={(event, newValue) => {
            if (!newValue) {
              setShowMark(false)
              return
            }

            if (typeof newValue === 'string') {
              setShowMark(true)
              setCurrentFilter(newValue)
            } else {
              props.onMaterialSelected(newValue as MaterialSummaryDto)
              setShowMark(false)
              setCurrentFilter('')
            }
          }}
          filterOptions={(options) => {
            return filterOptions(options, currentFilter)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchOutlined />,
              }}
              value={currentFilter}
              placeholder={t(
                'project:available-articles',
                'available articles'
              ).toString()}
              onChange={(event) => {
                setShowMark(true)
                setCurrentFilter(event.target.value)
              }}
            />
          )}
          renderOption={(el, option: MaterialSummaryDto, state) => (
            <li {...el} key={option.id}>
              <ListItemText
                className={classes.optionRoot}
                primary={
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: showMark
                        ? markTextSplitBySpaces(
                            option.description,
                            state.inputValue
                          )
                        : option.description
                        ? option.description
                        : '',
                    }}
                  />
                }
                secondary={
                  <Typography
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html: showMark
                        ? markTextSplitBySpaces(option.id, state.inputValue)
                        : option.id
                        ? option.id
                        : '',
                    }}
                    variant="body2"
                    color="textSecondary"
                  />
                }
              />
            </li>
          )}
        />
      ) : (
        <NoAvailableMaterials />
      )}
    </div>
  )
}

const NoAvailableMaterials = () => {
  return (
    <div className="no-available-materials-container">
      <LocalizedTypography
        translationKey={'project:article-selector--no-available-articles'}
      >
        no articles found with the given keywords
      </LocalizedTypography>
    </div>
  )
}
