import { Typography } from '@mui/material'
import { Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldProps } from 'react-jsonschema-form'

const RALSelector = lazy(
  () => import('components/RALSelector/RALColorSelector')
)

export const RalSelectorField = (props: FieldProps<string>) => {
  const { t } = useTranslation()
  return (
    <Suspense
      fallback={
        <Typography
          variant="caption"
          style={{ alignSelf: 'center', marginTop: '1em' }}
        >{`${t('common:loading')}...`}</Typography>
      }
    >
      <RALSelector
        selectedRALId={props.formData}
        onRALIdSelected={(ralId) => {
          props.onChange(ralId)
        }}
        label={t(`${props.uiSchema['ui:label-translation-key']}`, {
          ns: 'project',
        })}
      />
    </Suspense>
  )
}
