/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useAppDataGridStyles = makeStyles()((theme: Theme) => ({
  root: {
    '--rdg-color': `${theme.palette.text.primary} !important`,
    '--rdg-border-color': `${theme.palette.divider} !important`,
    '--rdg-summary-border-color': `${theme.palette.divider} !important`,
    '--rdg-background-color': `${theme.palette.background.paper} !important`,
    '--rdg-header-background-color': `${theme.palette.background.paper} !important`,
    '--rdg-row-hover-background-color': `${theme.palette.action.hover} !important`,
    '--rdg-row-selected-background-color': `${theme.palette.action.selected} !important`,
    '--rdg-row-selected-hover-background-color': `${theme.palette.action.selected} !important`,
    '--rdg-checkbox-color': `${theme.palette.primary.light} !important`,
    '--rdg-checkbox-disabled-border-color': `${theme.palette.action.disabled} !important`,
    '--rdg-checkbox-disabled-background-color': `${theme.palette.action.disabledBackground} !important`,
    '--rdg-checkbox-focus-color': `${theme.palette.action.focus} !important`,
    '--rdg-color-scheme': `${theme.palette.mode} !important`,
    '--rdg-row-height': `${theme.spacing(20)} !important`,
    '--rdg-selected-color': `${theme.palette.info.light} !important`,
    fontSize: `${theme.typography.caption.fontSize} !important`,
    overflow: 'auto',
    '& .rdg-cell-drag-handle': {
      backgroundColor: `${theme.palette.primary.light} !important`,
      '&:hover': {
        border: `none !important`,
        height: '12px',
        width: '12px',
        cursor: 'pointer',
      },
    },
    '& .rdg-cell-dragged-over': {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
  },
}))
