import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import useLocalizedMaterialReactTable from 'components/ProjectsPage/useLocalizedMaterialReactTable'
import { useAppController } from 'customHooks/useAppController'
import { MixPanelDashboardsController } from 'features/MixPanelDashboards/MixPanelDashboards.controller'
import { MixPanelDashboardsAdminController } from 'features/MixPanelDashboards/MixPanelDashboardsAdminController'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import {
  DashBoardDto,
  DashboardType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useShowException } from 'utils/useShowException'

type Props = {
  partyId: string
}
export const DashboardManagement = (props: Props) => {
  const { controller: getDashboardsController, loading: getDashboardsLoading } =
    useAppController(() => new MixPanelDashboardsController(props.partyId))
  const { controller: adminController } = useAppController(
    () => new MixPanelDashboardsAdminController(props.partyId)
  )

  const ShowException = useShowException('dashboard management')

  const [dashboards, setDashboards] = useState<DashBoardDto[] | undefined>(
    undefined
  )

  useEffect(() => {
    getDashboardsController
      .getDashboards()
      .then((res) => {
        res.dashboards = res.dashboards.map((d, i) => {
          return {
            ...d,
            id: `${d.dashboardType}-${i}`,
          }
        })
        setDashboards(res.dashboards)
      })
      .catch((err) => {
        console.info('no dashboard found', err)
      })
  }, [getDashboardsController])

  const _columns = useMemo<MRT_ColumnDef<DashBoardDto>[]>(() => {
    return [
      {
        header: 'Dashboard Type',
        accessorFn: (row) => {
          return row.dashboardType
        },
        id: 'dashboardType',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          { label: 'usage', value: DashboardType.Usage.toLowerCase() },
          {
            label: 'customerBehavior',
            value: DashboardType.CustomerBehaviour.toLowerCase(),
          },
        ],
      },
      {
        header: 'Dashboard Url',
        accessorFn: (row) => row.dashboardUrl,
        id: 'dashboardUrl',
      },
    ]
  }, [])

  const table = useLocalizedMaterialReactTable({
    columns: _columns,
    data: dashboards || [],
    enableEditing: true,
    editDisplayMode: 'row',
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 20,
        minSize: 20,
      },
    },
    state: {
      isLoading: getDashboardsLoading['getDashboards'],
    },
    renderRowActions: (renderProps) => {
      return (
        <>
          <IconButton
            onClick={() => {
              renderProps.table.setEditingRow(renderProps.row)
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={async () => {
              try {
                await adminController.deleteDashboard(
                  renderProps.row.original.dashboardType as DashboardType
                )
              } catch (err) {
                ShowException(err)
              } finally {
                setDashboards(
                  (await getDashboardsController.getDashboards()).dashboards
                )
              }
            }}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      )
    },
    createDisplayMode: 'row',
    renderTopToolbarCustomActions: (renderProps) => {
      return (
        <Button onClick={() => renderProps.table.setCreatingRow(true)}>
          Add Dashboard
        </Button>
      )
    },
    onCreatingRowSave: async (createProps) => {
      try {
        const dashboardType =
          createProps.values.dashboardType.charAt(0).toUpperCase() +
          createProps.values.dashboardType.slice(1)

        await adminController.setDashboard({
          dashboardType: dashboardType,
          dashboardUrl: createProps.values.dashboardUrl,
        })
        setDashboards(
          (await getDashboardsController.getDashboards()).dashboards
        )
        createProps.exitCreatingMode()
      } catch (err) {
        ShowException(err)
      }
    },
    onEditingRowSave: async (editProps) => {
      try {
        const dashboardType =
          editProps.values.dashboardType.charAt(0).toUpperCase() +
          editProps.values.dashboardType.slice(1)

        await adminController.setDashboard({
          dashboardType: dashboardType,
          dashboardUrl: editProps.values.dashboardUrl,
        })
        setDashboards(
          (await getDashboardsController.getDashboards()).dashboards
        )
        editProps.exitEditingMode()
      } catch (err) {
        ShowException(err)
      }
    },
  })

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  )
}
