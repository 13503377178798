import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { Region } from 'country-region-data'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import React, { useEffect } from 'react'

type Props = {
  countryCode: string
  selectedRegionName: string
  onRegionChange: (region: Region) => Promise<void> | void
  TextFieldProps?: TextFieldProps
  onFocus?
  onBlur?
  disabled?: boolean
}

export const RegionSelector = (props: Props) => {
  const [regions, setRegions] = React.useState<Region[]>([])
  const [selectedRegion, setSelectedRegion] = React.useState<Region | null>(
    null
  )

  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (props.countryCode) {
      import('country-region-data/data.json')
        .then((countries) =>
          setRegions(
            countries.default.find(
              (x) => x.countryShortCode === props.countryCode
            )?.regions as Region[]
          )
        )
        .finally(() => setLoading(false))
    }
  }, [props.countryCode])

  React.useEffect(() => {
    if (regions?.length && props.selectedRegionName) {
      setSelectedRegion(
        () =>
          regions.find((x) =>
            [x.shortCode.toLowerCase(), x.name.toLowerCase()].includes(
              props.selectedRegionName.toLowerCase()
            )
          ) ?? null
      )
    } else {
      setSelectedRegion(null)
    }
  }, [regions, props.selectedRegionName])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Autocomplete
      options={regions}
      loading={loading}
      disableClearable
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      autoHighlight
      autoSelect
      freeSolo
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'string' && typeof value === 'string')
          return option === value

        if (typeof option === 'object' && typeof value === 'object') {
          return option?.shortCode === ''
            ? true
            : option?.shortCode === value?.shortCode
        }

        if (!value || _.isEmpty(option)) return false
        if (typeof option === 'object' && typeof value === 'object') {
          return option?.shortCode === value?.shortCode
        }

        return option === value
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option

        return `${option.name} (${option.shortCode})`
      }}
      onChange={(e, value) => {
        if (!value || typeof value === 'string') return

        props.onRegionChange(value)
      }}
      filterOptions={(options, filter) => {
        return matchSorter(options, filter.inputValue, {
          keys: ['name', 'shortCode'],
        })
      }}
      disabled={loading || props.disabled}
      value={selectedRegion || ''}
      renderOption={(e, option) => {
        if (typeof option === 'string') {
          return <li {...e}>{option}</li>
        }

        return (
          <Typography {...e} component="li" variant="body1">
            {option.name}
            <Box component="span" marginLeft={1}>
              <Typography
                component="small"
                variant="caption"
                color="textSecondary"
              >
                {`(${option.shortCode})`}
              </Typography>
            </Box>
          </Typography>
        )
      }}
      renderInput={(params) => (
        <TextField
          label="region"
          variant="outlined"
          {...props.TextFieldProps}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...(props.TextFieldProps?.inputProps || {}),
          }}
        />
      )}
    />
  )
}
