import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { DisplayModeTool } from 'features/ModelViewer/cadexchanger/tools/DisplayMode'
import { MouseEvent } from 'react'

type Props = {
  viewController: ModelController
  faceIds: number[]
}

export const FeatureFaceIds = (props: Props) => {
  const handleSelectFace = async (faceId: number) => {
    if (props.viewController) {
      await props.viewController.selectNodesById([faceId.toString()], 'FACE')
    }
  }

  const handleSelectAll = async (e: MouseEvent) => {
    e.stopPropagation()
    if (props.viewController) {
      await props.viewController.selectNodesById(
        props.faceIds.map((x) => x.toString()),
        'FACE'
      )
    }
  }

  if (!props.viewController || !props.faceIds || !props.faceIds.length) {
    return null
  }

  return (
    <Accordion sx={{ p: 0 }}>
      <AccordionSummary>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body2">Face Ids</Typography>
          <Button onClick={handleSelectAll}>select all</Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ul
          style={{
            display: 'flex',
          }}
        >
          {props.faceIds.map((faceId) => (
            <li key={faceId}>
              <Button size="small" onClick={() => handleSelectFace(faceId)}>
                {faceId}
              </Button>
            </li>
          ))}
        </ul>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            width: '100%',
          }}
        >
          <Typography variant="caption">Display mode</Typography>
          <DisplayModeTool viewManager={props.viewController} />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
