/* eslint-disable tss-unused-classes/unused-classes */
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useProjectMaterialsCardStyles = makeStyles({
  name: 'materialItemPriceHeader',
})((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  priceHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    // padding: theme.spacing(1, 2),
  },
  columnUsage: {
    width: '100%',
  },
  usage: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  columnValue: {
    flex: '0 0 80px',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  materialName: {
    fontWeight: 'normal',
    marginBottom: '.5em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceColumnsHeader: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& span': {
      width: '100%',
      textAlign: 'right',
    },
  },
  content: {
    maxHeight: theme.spacing(49),
    overflow: 'auto',
    '& li:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  columnDiscount: {
    flex: '0 0 50%',
    display: 'inherit',
    // justifyContent: 'center',
    gap: theme.spacing(1),
    '& form': {
      width: '100%',
    },
  },
}))
