import { VerticalAlignBottom } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { useState } from 'react'
import { FeatureDetailDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'

type Props = {
  modelController: ModelController
  featureDetail: FeatureDetailDto
  notIncludedFaceIds: number[]
  onReload: () => void
}

export const SideActionsButtons = (props: Props) => {
  const { controller } = useAppController(() => new BomItemController())

  const [saving, setSaving] = useState(false)

  if (
    !props.modelController.canSelectUpperSide ||
    !props.featureDetail.label.startsWith('side') ||
    props.featureDetail.label.toLowerCase().includes('upper') ||
    props.featureDetail.label.toLowerCase().includes('(3d)') ||
    props.featureDetail.label.toLowerCase().includes('details')
  ) {
    return null
  }

  const handleSetUpperSide = async () => {
    try {
      const sideId = parseInt(props.featureDetail.label.replace('side ', ''))
      setSaving(true)

      if (isNaN(sideId)) {
        return
      }

      await props.modelController.setFacesColor(
        props.featureDetail.faceIds.map((x) => x.toString()),
        props.modelController.upperSideColor
      )

      if (props.notIncludedFaceIds?.length > 0) {
        await props.modelController.setFacesColor(
          props.notIncludedFaceIds.map((x) => x.toString()),
          undefined
        )
      }

      await props.modelController.updateScene()

      await controller.SetBomItemUpperSide(
        props.modelController.BomItemPointer.id,
        {
          sideId,
        }
      )

      props.onReload()
    } catch (err) {
      ShowException('Failed to set upper side', err)
    } finally {
      setSaving(false)
    }
  }

  return (
    <div style={{ width: '50%' }}>
      <SaveButton
        saving={saving}
        size={'small'}
        onClick={handleSetUpperSide}
        variant="outlined"
        startIcon={<VerticalAlignBottom />}
      >
        <Typography variant="caption">Set as upper side</Typography>
      </SaveButton>
    </div>
  )
}
