import { useAuth0 } from '@auth0/auth0-react'
import { AppBar, Toolbar } from '@mui/material'
import { AppLogo } from 'components/Layout/AppLogo'
import { LanguageButton } from 'components/Localization/LanguageButton'
import { SocialMedia } from 'components/SocialMedia'
import { ThemeChangeButton } from 'components/ThemeChangeButton'
import { isEqual } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthService } from 'services/auth'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { GettingStartedMenu } from './GettingStartedMenu'
import { GettingStartedRoutes } from './GettingStartedRoutes'
import {
  fetchAvailableWorkspaces,
  fetchUserProfile,
  navigateToNextStep,
} from './store/GettingStartedActions'
import { gettingStartedActions } from './store/GettingStartedReducer'
import { gettingStartedSelectors } from './store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'GettingStartedPage' })((theme) => {
  return {
    content: {
      padding: theme.spacing(2),
      '& h1': {
        marginBottom: theme.spacing(2),
      },
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    languageButton: {
      marginLeft: 'auto',
      display: 'flex',
      gap: theme.spacing(2),
    },
    logoImg: {
      height: `100%`,
      maxHeight: '62px',
      maxWidth: theme.spacing(20),
      mixBlendMode: theme.palette.mode === 'dark' ? 'hard-light' : 'inherit',
    },
    menu: {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
      left: 0,
      bottom: 0,
      width: '100%',
      maxWidth: theme.spacing(40),
      overflowY: 'auto',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    body: {
      display: 'flex',
      height: '100%',
    },
  }
})

export const GettingStartedPage = () => {
  const { classes } = useStyles()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const auth0 = useAuth0()

  const hasProfile = useAppSelector((state) => state.user.profile != null)
  const contextAuth0User = useAppSelector(
    gettingStartedSelectors.getAuth0UserSelector,
    isEqual
  )

  useEffect(() => {
    dispatch(navigateToNextStep(navigate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAuth0User = useCallback(async () => {
    if (auth0.user && !contextAuth0User) {
      dispatch(gettingStartedActions.setAuth0User(auth0.user))

      if (!AuthService.GetCurrent()) {
        AuthService.Build(auth0)
      }

      if (!hasProfile) {
        await dispatch(fetchUserProfile())
        await dispatch(fetchAvailableWorkspaces())
        dispatch(navigateToNextStep(navigate))
      }
    } else if (!auth0?.user && !auth0.isLoading) {
      dispatch(gettingStartedActions.setLoading(false))
    }
  }, [auth0, contextAuth0User, dispatch, hasProfile, navigate])

  useEffect(() => {
    handleAuth0User()
  }, [handleAuth0User])

  return (
    <>
      <AppBar position="relative" color="transparent">
        <Toolbar>
          <AppLogo className={classes.logoImg} />
          <div style={{ marginLeft: '2em' }}>
            <SocialMedia />
          </div>
          <div className={classes.languageButton}>
            <ThemeChangeButton />
            <LanguageButton />
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.body}>
        <div className={classes.menu}>{<GettingStartedMenu />}</div>
        <div className={classes.content} id="getting-started-content">
          <GettingStartedRoutes />
        </div>
      </div>
    </>
  )
}

export default GettingStartedPage
