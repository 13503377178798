import {
  HelpCenterOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import TeamViewerIcon from '_icons/TeamViewerLogo.svg?react'
import { CancelButton } from 'components/Common/Button/CancelButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { makeStyles } from 'tss-react/mui'

export type SupportContactDialogProps = {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles()((theme: Theme) => ({
  channels: {
    display: 'flex',
    justifyContent: 'space-around',
    '& svg': {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  contactButton: {
    '& span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export const SupportContactDialog = ({
  open,
  onClose,
}: SupportContactDialogProps) => {
  const { classes } = useStyles()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <DialogTitle>
        <LocalizedTypography
          translationKey={'common:support-contact-dialog-title'}
        >
          support contact
        </LocalizedTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <LocalizedTypography
            translationKey="common:support-contact-dialog-text"
            variant="inherit"
          >
            contact Quotation Factory support team through different channels
          </LocalizedTypography>
        </DialogContentText>
        <div className={classes.channels}>
          <a
            href={'https://quotationfactory.help.center'}
            target={'_blank'}
            rel="noreferrer"
          >
            <Button
              disableRipple
              disableFocusRipple
              className={classes.contactButton}
              startIcon={<HelpCenterOutlined />}
            >
              <LocalizedTypography
                translationKey="common:help-center"
                variant="body1"
              >
                help center
              </LocalizedTypography>
            </Button>
          </a>

          <a href={`callto:+31850047339`}>
            <Button
              disableRipple
              disableFocusRipple
              className={classes.contactButton}
              startIcon={<PhoneOutlined />}
            >
              <Typography variant="body1">+31 (0)85 004 7339</Typography>
            </Button>
          </a>

          <a href={`mailto:support@quotationfactory.com`}>
            <Button
              disableRipple
              disableFocusRipple
              className={classes.contactButton}
              startIcon={<MailOutlined />}
            >
              <Typography variant="body1">
                support@quotationfactory.com
              </Typography>
            </Button>
          </a>

          <Tooltip
            title={
              <LocalizedTypography
                translationKey={'common:support-team-viewer-tooltip'}
                variant="body2"
              >
                download TeamViewer for screen sharing capabilities and a better
                support experience
              </LocalizedTypography>
            }
            arrow
          >
            <a
              href={`https://get.teamviewer.com/rhodium24`}
              rel="noreferrer"
              target={'_blank'}
            >
              <Button
                disableRipple
                disableFocusRipple
                className={classes.contactButton}
                startIcon={<TeamViewerIcon />}
              >
                <Typography variant="body1">team viewer client</Typography>
              </Button>
            </a>
          </Tooltip>
        </div>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} labelTranslationKey="common:close" />
      </DialogActions>
    </Dialog>
  )
}
