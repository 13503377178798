import { Paper, Typography } from '@mui/material'
import { BomItemDimensionSummary } from 'features/BillOfMaterials/PartType/PartTypeDimensionSummary'
import { BomItemWorkingStepSummary } from 'features/BillOfMaterials/components/BomItemWorkingStepSummary'
import { BomItemType } from 'model/Project/BoMItemRow'
import moment from 'moment'
import { ProjectOperationDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export function OperationPartTypeTooltip(props: {
  tooltip: string
  rowNumber: number
  bomItemName: string
  imageSrc: string
  operationGroup: ProjectOperationDto
  onPurchasingPriceClicked?: () => void
}) {
  const bomItemPointer = {
    id: props.operationGroup.boMItemId,
    type: props.rowNumber ? BomItemType.partType : BomItemType.assemblyInstance,
  }

  return (
    <Paper className={props.tooltip} elevation={0}>
      {Boolean(props.rowNumber) && (
        <Typography variant="caption" component="span">
          {props.rowNumber}
        </Typography>
      )}
      <Typography variant="body1" component="span">
        {props.bomItemName}
      </Typography>
      {props.imageSrc ? (
        <img
          alt={props.bomItemName?.toString()}
          src={props.imageSrc}
          style={{
            height: '200px',
            width: '200px',
          }}
          loading="lazy"
        />
      ) : null}
      <BomItemDimensionSummary
        bomItemPointer={bomItemPointer}
        typographyProps={{
          variant: 'body2',
        }}
      />
      <BomItemWorkingStepSummary bomItemPointer={bomItemPointer} />
      <Typography variant="caption">
        {props.operationGroup.status} |{' '}
        {moment(props.operationGroup.lastUpdatedAt).format('L HH:mm:ss')}
      </Typography>
      {props.operationGroup.errorMessage && (
        <Typography variant="body2" color="secondary">
          {props.operationGroup.errorMessage}
        </Typography>
      )}
    </Paper>
  )
}
