import { Typography, TypographyProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { ProjectProcessFlowSelector } from '../store/selectors/ProjectProcessFlowSelector'
import { projectInBuyerViewSelector } from '../store/selectors/projectInBuyerViewSelector'

export const ProjectProcessFlow = (props: TypographyProps) => {
  const flowType = useAppSelector(ProjectProcessFlowSelector)
  const { t } = useTranslation()
  const isBuyingPartyView = useAppSelector(projectInBuyerViewSelector)

  if (!flowType || isBuyingPartyView) {
    return null
  }

  return (
    <Typography variant="caption" color="textSecondary" {...props}>
      {t('project:process-flow', 'process flow: {{processFlow}}', {
        processFlow: flowType,
      })}
    </Typography>
  )
}
