import { BaseController } from 'controllers/BaseController'
import { AcceptInviteAPI } from './AcceptInviteAPI'
import { IAcceptInviteAPI } from './IAcceptInviteAPI'

export class AcceptInviteController extends BaseController<IAcceptInviteAPI> {
  constructor() {
    super((onRequestChange) => new AcceptInviteAPI(onRequestChange))
  }

  public async AcceptInvite(inviteId: string, inviteCode: string) {
    try {
      return await this.api.AcceptInvite({ inviteId, inviteCode })
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
