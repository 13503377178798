import { MaterialsController } from 'controllers/MaterialsController'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { useAppController } from 'customHooks/useAppController'
import { cloneDeep } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'

export const useCopyContextMenu = () => {
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)

  const [open, setOpen] = useState<boolean>(false)

  const { controller: materialController, loading: materialControllerLoading } =
    useAppController(() => new MaterialsController())
  const {
    controller: workingStepController,
    loading: workingStepsControllerLoading,
  } = useAppController(() => new WorkingStepsController())

  const contextMenuCounter = useRef(0)

  const handleDropActivity = useCallback(
    async (
      baseBomItemPointer: BomItemPointer,
      clipboardActivity: BoMItemActivityDto,
      targetBomItemPointers: BomItemPointer[]
    ) => {
      try {
        if (!clipboardActivity.usesRawMaterial) {
          // copy the working step
          await workingStepController.CopyWorkingStepsToOtherParts(
            baseBomItemPointer,
            clipboardActivity.id,
            clipboardActivity.primaryWorkingStep,
            targetBomItemPointers.filter((x) => x.id !== baseBomItemPointer.id)
          )
        } else {
          const allTokens = cloneDeep(clipboardActivity.keywords)
          delete allTokens['shape']
          delete allTokens['dimensions']

          await materialController.AddKeywordsToRows({
            keywords: Object.values(allTokens).flat(),
            bomItemPointers: targetBomItemPointers,
          })
        }

        return
      } catch (err) {
        ShowException('apply materials', err)
      }
    },
    [materialController, workingStepController]
  )

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (contextMenuCounter.current < 1) {
        event.preventDefault()

        setTimeout(() => {
          setX(event.clientX)
          setY(event.clientY)
          setOpen(true)
        })
        contextMenuCounter.current++
      }

      setTimeout(() => {
        contextMenuCounter.current = 0
      }, 300)
    },
    []
  )

  const handleCloseContextMenu = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    const contextMenuHandler = () => {
      handleCloseContextMenu()
    }

    document.addEventListener('contextmenu', contextMenuHandler)

    return () => {
      document.removeEventListener('contextmenu', contextMenuHandler)
    }
  }, [handleCloseContextMenu])

  return {
    handleContextMenu,
    open,
    x,
    y,
    handleCloseContextMenu,
    handleDropActivity,
    loading: { ...materialControllerLoading, ...workingStepsControllerLoading },
  }
}
