import { PartyRelationsController } from 'controllers/Party/Relations/PartyRelationsController'
import { useAppController } from 'customHooks/useAppController'
import React from 'react'
import { ErrorMessage } from 'store/Application/appActions'
import { newUserActions } from 'store/User/newUserReducer'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationContext } from 'utils/useOrganizationContext'

export function useSellingParties(shouldCall?: boolean) {
  const { partyId: organizationId } = useOrganizationContext()

  const { controller, loading } = useAppController(
    () => new PartyRelationsController(organizationId)
  )

  // const [sellingParties, setSellingParties] = React.useState<SellingPartyDto[]>(
  //   []
  // )

  const sellingParties = useAppSelector(
    (state) => state.user.organizationContext?.sellingParties
  )

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (
      !sellingParties?.length &&
      (shouldCall === undefined || shouldCall === true)
    ) {
      controller
        .GetSellerParties()
        .then((sellers) => dispatch(newUserActions.setSellingParties(sellers)))
        .catch((err) => ErrorMessage('suppliers', err['message']))
    }
  }, [controller, dispatch, sellingParties?.length, shouldCall])

  return {
    loading,
    sellingParties,
  }
}
