import { Button, Typography } from '@mui/material'
import { AddressForm } from 'components/OrganizationSettings/General/OrganizationProfile/Sections/PartyDetails/Forms/AddressForm'
import { AddressDto } from 'services/APIs/InternalAPI/internal-api.contracts'

type Props = {
  address: AddressDto
  setAddress: (address: AddressDto) => void
  handleCopyAddress?: () => void
  copyText?: string
  titleText: string
  classes?: {
    titleText?: string
    titleDiv?: string
  }
  addressRequired?: boolean
  disabled?: boolean
}
export const ProjectAddressForm = (props: Props) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
        className={props.classes?.titleDiv}
      >
        <Typography variant="subtitle1" className={props.classes?.titleText}>
          {props.titleText}
        </Typography>
        {props.handleCopyAddress && (
          <Button onClick={() => props.handleCopyAddress()}>
            {props.copyText}
          </Button>
        )}
      </div>
      <AddressForm
        onChangeAddress={(address) => {
          props.setAddress(address)
        }}
        disabled={props.disabled}
        address={props.address}
        loading={false}
        variant="standard"
        required={props.addressRequired}
      />
    </>
  )
}
