import { CloseOutlined } from '@mui/icons-material'
import { Button, Drawer, IconButton, Typography } from '@mui/material'
import { SaveButton } from 'components/Common/Button/SaveButton'
import ShowToRole from 'components/Common/Portal/ShowToRole'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectViewsButtonGroup } from 'features/BillOfMaterials/ProjectViews/ProjectViewsButtonGroup'
import { KeywordTagsList } from 'features/BillOfMaterials/components/BomItemActivities/KeywordTag'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { useCopyContextMenu } from './useCopyContextMenu'

const useStyles = makeStyles({ name: 'CopyPasteDrawer' })((theme) => ({
  header: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(1),
    textAlign: 'center',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
  },
  clipboardItem: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  clipboardBody: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  clipboardItemAction: {
    alignSelf: 'flex-end',
  },
  body: {
    maxWidth: theme.breakpoints.values.sm,
  },
  explanation: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
}))

function CopyPasteItem(props: {
  baseBomItemPointer: BomItemPointer
  activity: BoMItemActivityDto
  selectedItems: Array<BomItemPointer>
}) {
  const { classes } = useStyles()
  const draggableRef = useRef<HTMLDivElement>(null)
  const { handleDropActivity, loading } = useCopyContextMenu()
  const { t } = useTranslation()

  return (
    <div
      className={classes.clipboardItem}
      onDragStart={(e) => {
        e.dataTransfer.setData(
          'text/plain',
          JSON.stringify({
            baseBomItemPointer: props.baseBomItemPointer,
            clipboardActivity: props.activity,
          })
        )
      }}
      onDrag={(e) => {
        if (e.target !== draggableRef.current) {
          e.preventDefault()
        }
      }}
      draggable
    >
      <div className={classes.clipboardBody}>
        <WorkingStepIconSvg
          workingStepType={props.activity.primaryWorkingStep}
          attributes={{ width: '32px' }}
        />
        <div>
          <Typography variant="caption" color="GrayText">
            {props.activity.article?.description}
          </Typography>
          <KeywordTagsList keywords={props.activity.keywords} />
        </div>
      </div>

      {props.selectedItems?.length > 0 && (
        <div className={classes.clipboardItemAction}>
          <SaveButton
            size="small"
            variant={'outlined'}
            saving={
              loading['AddKeywordsToRows'] ||
              loading['CopyWorkingStepsToOtherParts']
            }
            disableCtrlS
            onClick={() =>
              handleDropActivity(
                props.baseBomItemPointer,
                props.activity,
                props.selectedItems
              )
            }
          >
            {`${t(
              'project:apply-to-selected-rows',
              'apply to selected rows'
            )} (${props.selectedItems?.length})`}
          </SaveButton>
        </div>
      )}
    </div>
  )
}

export function CopyPasteMaterialDrawer() {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const selectedItems = useAppSelector(
    projectSelectors.selectedBomItemsPointersSelector,
    isEqual
  )

  const [open, setOpen] = useState(false)

  const dispatch = useAppDispatch()
  const activitiesClipboard = useAppSelector(
    (state) => state.materials.activitiesClipboard,
    isEqual
  )

  useEffect(() => {
    setOpen(Boolean(activitiesClipboard?.activities.length))
  }, [activitiesClipboard?.activities?.length])

  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('keydown', closeOnEscape)
    }
  })

  const handleClose = () => {
    dispatch(MaterialActions.clipboardClear())
    dispatch(newProjectActions.setSelectedBomItems([]))
    setOpen(false)
  }

  return (
    <Drawer
      open={open}
      onClose={() => handleClose()}
      variant="persistent"
      anchor="right"
    >
      <div className={classes.header}>
        <Typography variant="h6">
          {t('project:copy-paste-drawer-title', 'copy/paste keywords')}
        </Typography>
        <IconButton
          onClick={() => handleClose()}
          color="inherit"
          className={classes.closeButton}
        >
          <CloseOutlined />
        </IconButton>
      </div>
      <div className={classes.body}>
        <div className={classes.explanation}>
          <LocalizedTypography
            translationKey="project:copy-paste-drawer-explanation"
            variant="body2"
          >
            drag and drop the item into a part to copy the keywords and the
            working step or use ctrl + click to select rows to apply the
            keywords
          </LocalizedTypography>
          {selectedItems?.length > 0 && (
            <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() =>
                  dispatch(newProjectActions.clearSelectedBomItems())
                }
                size="small"
                color="secondary"
              >
                {t('project:clear-selection', 'clear selection')}
              </Button>
            </div>
          )}
        </div>

        <ul>
          {activitiesClipboard?.activities
            ?.filter((x) => Object.keys(x.keywords).length > 0)
            .map((activity) => {
              return (
                <li key={activity.id}>
                  <CopyPasteItem
                    activity={activity}
                    baseBomItemPointer={activitiesClipboard.baseBomItemPointer}
                    selectedItems={selectedItems}
                  />
                </li>
              )
            })}
        </ul>
      </div>
      <ShowToRole seller>
        <div style={{ marginTop: 'auto' }}>
          <ProjectViewsButtonGroup />
        </div>
      </ShowToRole>
    </Drawer>
  )
}
