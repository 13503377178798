import { Brightness2Outlined, WbSunnyOutlined } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import { SetDefaultTheme } from 'store/Application/appActions'
import { useAppDispatch } from 'store/configureStore'

export const ThemeChangeButton = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const handleChangeTheme = () => {
    if (theme.palette.mode === 'light') {
      dispatch(SetDefaultTheme('darkBlue'))
    } else {
      dispatch(SetDefaultTheme('light'))
    }
  }

  return (
    <IconButton onClick={handleChangeTheme}>
      {theme.palette.mode === 'light' ? (
        <Brightness2Outlined />
      ) : (
        <WbSunnyOutlined />
      )}
    </IconButton>
  )
}
