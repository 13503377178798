import { ButtonGroup } from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { ProjectViews } from 'store/UI/UITypes'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { newProjectActions } from '../store/projectReducer'

export function ProjectViewsButtonGroup() {
  const dispatch = useAppDispatch()

  const currentView = useAppSelector((state) => state.project.currentView)

  return (
    <ButtonGroup fullWidth>
      <LocalizedButton
        translationKey={`project:BoM-BoL-tab`}
        variant={
          currentView === ProjectViews.BoMBoLView ? 'contained' : 'outlined'
        }
        color="primary"
        onClick={() => {
          dispatch(newProjectActions.changeProjectView(ProjectViews.BoMBoLView))
        }}
      />
      <LocalizedButton
        translationKey="project:BoM-tab-routes"
        variant={
          currentView === ProjectViews.RoutesView ? 'contained' : 'outlined'
        }
        color="primary"
        onClick={() => {
          dispatch(newProjectActions.changeProjectView(ProjectViews.RoutesView))
        }}
      />
      <LocalizedButton
        translationKey="project:BoM-tab-raw-material"
        variant={
          currentView === ProjectViews.MaterialsView ? 'contained' : 'outlined'
        }
        color="primary"
        onClick={() => {
          {
            dispatch(
              newProjectActions.changeProjectView(ProjectViews.MaterialsView)
            )
          }
        }}
      />
    </ButtonGroup>
  )
}
