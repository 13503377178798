import { Theme, Typography } from '@mui/material'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { MoneyString } from '../../../model/Money'
import { QuantityString } from '../../../model/Quantity'
import { PriceSummaryDto } from '../../../services/APIs/InternalAPI/internal-api.contracts'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useGraphsTooltipStyles = makeStyles()((theme: Theme) => ({
  customTooltip: {
    display: 'block',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    width: theme.spacing(40),
    zIndex: 10,
    textAlign: 'center',
    boxShadow: theme.shadows[1],
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
    '& ul li': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.disabled,
  },
  value: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
  },
  usage: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
}))

export const MarginGraphTooltip = (props: {
  active?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
  label?: string | number
}) => {
  const { t } = useTranslation()
  const { classes } = useGraphsTooltipStyles()

  if (!props.payload || !props.payload.length) return null

  const priceDetails: PriceSummaryDto = props.payload[0]
    .payload as unknown as PriceSummaryDto

  if (props.active && priceDetails) {
    return (
      <div className={classes.customTooltip}>
        <Typography variant="subtitle1">{props.label}</Typography>
        <div className={classes.usage}>
          {priceDetails?.isMaterial ? (
            <strong>{QuantityString(priceDetails.weight)}</strong>
          ) : (
            <strong>{TimeString(priceDetails.productionTime)}</strong>
          )}
        </div>

        <ul>
          <li>
            <label className={classes.label}>{t('project:cost-price')}</label>
            <label className={classes.value}>
              <strong>{MoneyString(priceDetails.totalCostPrice)}</strong>
            </label>
          </li>
          <li>
            <label className={classes.label}>{t('project:surcharge')}</label>
            <label className={classes.value}>
              <strong>{MoneyString(priceDetails.surchargeValue)}</strong>
              <br />
              <small>
                (
                {parseFloat(
                  priceDetails.surchargeRatio.value.toString()
                ).toFixed(2)}
                %)
              </small>
            </label>
          </li>
          <li>
            <label className={classes.label}>{t('project:discount')}</label>
            <label className={classes.value}>
              <strong>{MoneyString(priceDetails.discountValue)}</strong>
              <br />
              <small>
                (
                {parseFloat(
                  priceDetails.discountRatio.value.toString()
                ).toFixed(2)}
                %)
              </small>
            </label>
          </li>
          <li>
            <label className={classes.label}>{t('project:total-price')}</label>
            <label className={classes.value}>
              <strong>{MoneyString(priceDetails.totalSalesPrice)}</strong>
            </label>
          </li>
        </ul>
        {/* <span className="help-text">click to edit margins</span> */}
      </div>
    )
  }
  return null
}
