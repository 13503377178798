import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import {
  fetchCurrentProject,
  fetchProject,
} from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import {
  bomItemChildrenSelector,
  bomItemSelector,
} from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import _ from 'lodash'
import LogRocket from 'logrocket'
import { MultiplyMoney, SumMoney } from 'model/Money'
import { MultiplyQuantity } from 'model/MultiplyQuantity'
import {
  AssemblyHeaderRow,
  BoMItemRow,
  BomItemType,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { SumQuantity } from 'model/SumQuantity'
import { useCallback, useEffect, useState } from 'react'
import {
  FeatureDto,
  FinancialDto,
  ManuallySetProductionTimeArgs,
  MoneyDto,
  PriceRowDto,
  PriceScope,
  QuantityDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { bomItemFinancialSelector } from 'store/Project/ProjectSelectors'
import { store, useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationAndProjectContext } from 'utils/useOrganizationContext'

export type PriceDetailsGraphData = {
  bomItemPointer: BomItemPointer
  description: string
  resource?: string
  productionTime?: QuantityDto
  primaryWorkingStep?: WorkingStepType
  secondaryWorkingStep?: WorkingStepType
  loading?: MoneyDto
  mainActivityCostPrice?: MoneyDto
  unloading?: MoneyDto
  materialId?: string
  materialDescription?: string
  materialSalesPrice?: MoneyDto
  surchargeValue?: MoneyDto
  surchargeRatio?: QuantityDto
  discountValue?: MoneyDto
  discountRatio?: QuantityDto
  setup?: MoneyDto
  /**
   * used to display the children of an assembly
   */
  childrenBomItems?: Record<string, Omit<PriceDetailsGraphData, 'children'>>
  total?: MoneyDto
  color?: string
  salesPrice?: MoneyDto
  sharedCost?: boolean
  matchDescription?: string
  totalQuantity?: QuantityDto
  staggeredTotalQuantity?: QuantityDto
  salesPrices?: MoneyDto // used to draw Y axis ticks
  totalSalesPrice?: MoneyDto
  totalCostPrice?: MoneyDto
}

export const getSumOfChildrens = (
  bomItemPointer: BomItemPointer,
  unit: PriceScope
): MoneyDto => {
  const state = store.getState()

  const sumFinancials = (bomItems: BoMItemRow[]): number => {
    return _.sumBy(bomItems, (bomItem) => {
      const financial = bomItem['financial'] as FinancialDto

      if (financial) {
        return unit === PriceScope.Unit
          ? financial.salesPricePerItem.value
          : financial.salesPriceOfItems.value
      }

      return 0
    })
  }

  switch (bomItemPointer.type) {
    case BomItemType.project: {
      const assemblyHeaders = Object.values(state.project.assemblyHeaders)

      if (!assemblyHeaders.length) return null

      const sumOfPartsSalesPrice = sumFinancials(assemblyHeaders)

      return {
        ...assemblyHeaders[0].financial?.salesPricePerItem,
        value: sumOfPartsSalesPrice,
      }
    }
    case BomItemType.assemblyType: {
      const assembly = bomItemSelector(
        state,
        bomItemPointer
      ) as AssemblyHeaderRow

      const assemblyPartTypes: PartTypeRow[] = assembly.partTypePointers.map(
        (x) => state.project.partTypes[x.id]
      )

      if (!assemblyPartTypes.length) return null

      const sumOfPartsSalesPrice = _.sumBy(assemblyPartTypes, (x) => {
        if (unit === PriceScope.Unit) {
          return x.financial?.salesPricePerItem?.value
        } else {
          return x.financial?.salesPriceOfItems?.value
        }
      })

      return {
        ...assemblyPartTypes[0].financial.salesPricePerItem,
        value: sumOfPartsSalesPrice,
      }
    }
    default: {
      return { value: 0 }
    }
  }
}

export function useFinancialDetails(props: {
  open?: boolean
  bomItemPointer: BomItemPointer
}) {
  const { partyId, projectId } = useOrganizationAndProjectContext()
  const dispatch = useAppDispatch()

  const { controller, loading, setLoading } = useAppController(
    () => new BomItemController(partyId, projectId)
  )

  const [summarizedPriceDetails, setSummarizedPriceDetails] =
    useState<PriceDetailsGraphData[]>(undefined)

  const [priceDetails, setPriceDetails] = useState<PriceRowDto[]>(undefined)
  const [formDetails, setFormDetails] = useState<FeatureDto[]>(undefined)

  const bomItemFinancial = useAppSelector(
    bomItemFinancialSelector(props.bomItemPointer),
    _.isEqual
  )

  useEffect(() => {
    setPriceDetails(undefined)
    setSummarizedPriceDetails(undefined)
    setFormDetails(undefined)
    setLoading({ 'get-item-details': true })
  }, [props.bomItemPointer, setLoading])

  const getChildItems = useCallback(
    (
      bomItemPointer: BomItemPointer,
      unit: PriceScope
    ): PriceDetailsGraphData => {
      if (!store.getState().project.activeProject) {
        return null
      }

      const childrenBomItems = bomItemChildrenSelector(bomItemPointer)(
        store.getState()
      ) as PartTypeRow[]

      if (!childrenBomItems?.length) return null

      const children = childrenBomItems
        .sort((a, b) => {
          if (unit === PriceScope.Unit) {
            return (
              b.financial?.salesPricePerItem.value -
              a.financial?.salesPricePerItem?.value
            )
          } else {
            return (
              b.financial?.salesPriceOfItems.value -
              a.financial?.salesPriceOfItems?.value
            )
          }
        })
        .reduce(
          (p, c, i) => ({
            ...p,
            [i]: {
              bomItemPointer: {
                id: c.id,
                type: c.type,
              },
              description: c.name,
              primary:
                unit === PriceScope.Unit
                  ? c.financial?.salesPricePerItem
                  : c.financial?.salesPriceOfItems,
              salesPrice:
                unit === PriceScope.Unit
                  ? c.financial?.salesPricePerItem
                  : c.financial?.salesPriceOfItems,
              // color: chroma.random().hex(),
              color: 'transparent',
            } as PriceDetailsGraphData,
          }),
          {}
        )

      return {
        bomItemPointer: undefined,
        description:
          props.bomItemPointer.type === BomItemType.project
            ? 'assemblies'
            : 'parts',
        childrenBomItems: children,
        total: getSumOfChildrens(bomItemPointer, PriceScope.Unit),
        salesPrice: undefined,
        sharedCost: false,
        matchDescription: undefined,
      }
    },
    [props.bomItemPointer.type]
  )

  const getPriceDataForGraph = useCallback(
    (
      details: PriceRowDto[],
      bomItemPointer: BomItemPointer,
      priceScope: PriceScope
    ): PriceDetailsGraphData[] => {
      const priceRows = details.filter((x) => !x.isTotalRow)

      const data: Array<PriceDetailsGraphData> = []

      data.push(getChildItems(bomItemPointer, priceScope))

      priceRows.forEach((priceRow) => {
        const productionTimeTotal = SumQuantity([
          priceRow.productionTime,
          ...priceRow.children.map((x) => x.productionTime),
        ])
        const salesPriceTotal = SumMoney([
          priceRow.salesPrice,
          ...priceRow.children.map((x) => x.salesPrice),
        ])
        const surchargetTotal = SumMoney([
          priceRow.surchargeValue,
          ...priceRow.children.map((x) => x.surchargeValue),
        ])
        const discountTotal = SumMoney([
          priceRow.discountValue,
          ...priceRow.children.map((x) => x.discountValue),
        ])

        data.push({
          bomItemPointer: bomItemPointer,
          description: priceRow.workingStep.primaryWorkingStep,
          resource: priceRow.workingStep.resource.name,
          productionTime: productionTimeTotal,
          total: salesPriceTotal,
          primaryWorkingStep: priceRow.workingStep.primaryWorkingStep,
          salesPrice: salesPriceTotal,
          mainActivityCostPrice: priceRow.costPrice,
          setup: priceRow.children.find(
            (x) => x.workingStep?.secondaryWorkingStep === WorkingStepType.Setup
          )?.costPrice,
          loading: priceRow.children.find(
            (x) =>
              x.workingStep?.secondaryWorkingStep === WorkingStepType.Loading
          )?.costPrice,
          unloading: priceRow.children.find(
            (x) =>
              x.workingStep?.secondaryWorkingStep === WorkingStepType.Unloading
          )?.costPrice,
          materialSalesPrice: priceRow.children.find((x) => !x.isWorkingStep)
            ?.costPrice,
          materialDescription:
            priceRow.children.find((x) => !x.isWorkingStep)?.description ||
            'not set',
          materialId: priceRow.children.find((x) => !x.isWorkingStep)
            ?.materialId,
          discountValue: MultiplyQuantity(discountTotal, -1),
          surchargeValue: surchargetTotal,
          discountRatio: priceRow.discountRatio,
          surchargeRatio: priceRow.surchargeRatio,
        })
      })

      const totalRow = details.find((x) => x.isTotalRow)
      const totalMaterialsSalesPrices = SumMoney(
        priceRows
          .map((x) => x.children)
          .flat()
          .filter((x) => !x.isWorkingStep)
          .map((x) => x.costPrice)
      )

      data.push({
        bomItemPointer: undefined,
        description: 'total',
        mainActivityCostPrice: SumMoney([
          totalRow?.costPrice,
          MultiplyMoney(totalMaterialsSalesPrices, -1),
        ]),
        surchargeValue: totalRow?.surchargeValue,
        surchargeRatio: totalRow?.surchargeRatio,
        discountValue: {
          ...(totalRow?.discountValue || {}),
          value: totalRow?.discountValue.value * -1,
        },
        discountRatio: totalRow?.discountRatio,
        salesPrice: totalRow?.salesPrice,
        sharedCost: totalRow?.costsAreShared,
        matchDescription: undefined,
        materialSalesPrice: totalMaterialsSalesPrices,
      })

      return data.filter((x) => Boolean(x))
    },
    [getChildItems]
  )

  const multiplyUnitPriceByTotalProjectQuantity = useCallback(
    (priceDetails: PriceRowDto): PriceRowDto => {
      if (!priceDetails) return null

      const multiplier = (priceDetails: PriceRowDto) => {
        const modifiedPriceDetails = {
          ...priceDetails,
          productionTime: MultiplyMoney(
            priceDetails.productionTime,
            bomItemFinancial.totalProjectQuantity
          ),
          productionTimeCosts: MultiplyMoney(
            priceDetails.productionTimeCosts,
            bomItemFinancial.totalProjectQuantity
          ),
          productionTimeManuallySet: MultiplyMoney(
            priceDetails.productionTimeManuallySet,
            bomItemFinancial.totalProjectQuantity
          ),
          additionalProductionCosts: MultiplyMoney(
            priceDetails.additionalProductionCosts,
            bomItemFinancial.totalProjectQuantity
          ),
          additionalProductionCostsManuallySet: MultiplyMoney(
            priceDetails.additionalProductionCostsManuallySet,
            bomItemFinancial.totalProjectQuantity
          ),
          costPrice: MultiplyMoney(
            priceDetails?.costPrice,
            bomItemFinancial?.totalProjectQuantity
          ),
          surchargeValue: MultiplyMoney(
            priceDetails.surchargeValue,
            bomItemFinancial.totalProjectQuantity
          ),
          discountValue: MultiplyMoney(
            priceDetails.discountValue,
            bomItemFinancial.totalProjectQuantity
          ),
          salesPrice: MultiplyMoney(
            priceDetails.salesPrice,
            bomItemFinancial.totalProjectQuantity
          ),
        }

        return modifiedPriceDetails
      }

      const modifiedPriceDetails = multiplier(priceDetails)
      modifiedPriceDetails.children = priceDetails.children.map(multiplier)

      return modifiedPriceDetails
    },
    [bomItemFinancial?.totalProjectQuantity]
  )

  const [priceScope, setPriceScope] = useClientStorage<PriceScope>(
    'project:financial-scope',
    PriceScope.Total
  )
  const handleChangeFinancialView = useCallback(
    (unit: PriceScope) => {
      if (!bomItemFinancial) {
        return null
      }

      setPriceScope(unit)

      if (unit === PriceScope.Total) {
        setPriceDetails((state) => {
          const newState = state.map((x) =>
            x.isBatchIndependent
              ? x
              : multiplyUnitPriceByTotalProjectQuantity(x)
          )

          // setPriceDetails(newState)
          setSummarizedPriceDetails(
            getPriceDataForGraph(newState, props.bomItemPointer, priceScope)
          )

          return newState
        })
      }
    },
    [
      bomItemFinancial,
      getPriceDataForGraph,
      multiplyUnitPriceByTotalProjectQuantity,
      priceScope,
      props.bomItemPointer,
      setPriceScope,
    ]
  )

  const getItemFeaturesDetails = useCallback(async () => {
    try {
      const rowDetails = await controller.GetItemFeaturesDetails(
        props.bomItemPointer
      )

      setFormDetails(rowDetails?.features)
    } catch (err) {
      ShowException('item price details', err)
    }
  }, [controller, props.bomItemPointer])

  const getItemPriceDetails = useCallback(async () => {
    try {
      const priceDetails = await controller.GetItemPriceDetails(
        props.bomItemPointer
      )

      if (!priceDetails?.priceRows) {
        return
      }
      // batch independent items are stored as unit prices in the backend
      // we need to show the total value of it in the frontend
      priceDetails.priceRows = priceDetails.priceRows.map((x) =>
        x.isBatchIndependent ? multiplyUnitPriceByTotalProjectQuantity(x) : x
      )

      // setTimeout(() => {
      setPriceDetails(priceDetails.priceRows)

      setSummarizedPriceDetails(
        getPriceDataForGraph(
          priceDetails.priceRows,
          props.bomItemPointer,
          priceScope
        )
      )

      handleChangeFinancialView(priceScope)
    } catch (err) {
      LogRocket.captureException(err as Error)
    }
  }, [
    controller,
    priceScope,
    getPriceDataForGraph,
    handleChangeFinancialView,
    multiplyUnitPriceByTotalProjectQuantity,
    props.bomItemPointer,
  ])

  useEffect(() => {
    // if (props.open && financialViewUnit === 'perUnit') {
    let getItemDetailsTimeout = null

    if (props.open) {
      getItemDetailsTimeout = setTimeout(() => {
        getItemFeaturesDetails()
        getItemPriceDetails()
      })
    } else {
      clearTimeout(getItemDetailsTimeout)
      controller.CancelRequests()
    }
    return () => {
      clearTimeout(getItemDetailsTimeout)
      controller.CancelRequests()
    }
  }, [controller, getItemFeaturesDetails, getItemPriceDetails, props.open])

  const handleResetProductionTime = useCallback(
    async (bomItemPointer: BomItemPointer, priceRow: PriceRowDto) => {
      try {
        await controller.DeleteManualSetProductionTime(bomItemPointer, priceRow)
        dispatch(fetchCurrentProject())
      } catch (ex) {
        ShowException('project', ex)
      }
    },
    [controller, dispatch]
  )

  const handleResetAdditionalCosts = useCallback(
    async (bomItemPointer: BomItemPointer, priceRow: PriceRowDto) => {
      try {
        await controller.DeleteManualSetAdditionalCosts(
          bomItemPointer,
          priceRow
        )
      } catch (ex) {
        ShowException('reset additional costs', ex)
      }
    },
    [controller]
  )

  const handleSaveProductionTime = useCallback(
    async (
      bomItemPointer: BomItemPointer,
      args: ManuallySetProductionTimeArgs
    ) => {
      try {
        await controller.ManualSetProductionTime(bomItemPointer, args)
        setPriceDetails((current) => {
          if (!current) return current

          return current.map((x) =>
            x.workingStep.primaryWorkingStep === args.primaryWorkingStep &&
            x.workingStep.secondaryWorkingStep === args.secondaryWorkingStep
              ? { ...x, productionTimeManuallySet: args.productionTime }
              : x
          )
        })

        return Promise.resolve()
      } catch (ex) {
        ShowException('save production time', ex)
        dispatch(() => fetchProject({ projectId }))
      }
    },
    [controller, dispatch, projectId]
  )

  const handleSaveAdditionalCosts = useCallback(
    async (
      bomItemPointer: BomItemPointer,
      priceRow: PriceRowDto,
      priceScope: PriceScope
    ) => {
      try {
        setPriceDetails((current) => {
          return current.map((x) => {
            if (
              x.workingStep.primaryWorkingStep ===
              priceRow.workingStep.primaryWorkingStep
            ) {
              // "main" row
              if (
                x.workingStep.secondaryWorkingStep ===
                priceRow.workingStep.secondaryWorkingStep
              ) {
                return {
                  ...x,
                  additionalProductionCostsManuallySet:
                    priceRow.additionalProductionCostsManuallySet,
                }
              } else {
                // look for the child row
                return {
                  ...x,
                  children: x.children.map((y) => {
                    if (
                      y.workingStep.primaryWorkingStep ===
                        priceRow.workingStep.primaryWorkingStep &&
                      y.workingStep.secondaryWorkingStep ===
                        priceRow.workingStep.secondaryWorkingStep
                    ) {
                      return {
                        ...y,
                        additionalProductionCostsManuallySet:
                          priceRow.additionalProductionCostsManuallySet,
                      }
                    }
                    return y
                  }),
                }
              }
            }
            return x
          })
        })

        await controller.ManualSetAdditionalCosts(bomItemPointer, {
          additionalCosts: priceRow.additionalProductionCostsManuallySet,
          priceScope: priceScope,
          primaryWorkingStep: priceRow.workingStep.primaryWorkingStep,
          secondaryWorkingStep: priceRow.workingStep.secondaryWorkingStep,
        })
      } catch (ex) {
        ShowException('save additional costs', ex)
      }
    },
    [controller]
  )

  return {
    getPriceDataForGraph,
    summarizedPriceDetails,
    priceDetails,
    loading,
    controller,
    getItemDetails: getItemFeaturesDetails,
    setLoading,
    financialViewUnit: priceScope,
    handleChangeFinancialView,
    totalProjectQuantity: bomItemFinancial?.totalProjectQuantity,
    bomItemFinancial,
    handleResetProductionTime,
    handleResetAdditionalCosts,
    handleSaveProductionTime,
    handleSaveAdditionalCosts,
    formDetails,
    multiplyUnitPriceByTotalProjectQuantity,
    clearPriceDetails: () => setPriceDetails(undefined),
  }
}
