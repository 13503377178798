import { Typography, useTheme } from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import {
  RoutingStep,
  WorkingStepDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { WorkingStepResourceSelector } from '../WorkingSteps/WorkingStepResourceSelector'
import { useNotAllowedWorkingStep } from '../WorkingSteps/useNotAllowedWorkingStep'
import { useRoutingHeaderStyles } from './RoutingHeader.styles'

export const WorkingStepIconWithMachineName = (props: {
  routingStep: RoutingStep
  classes?: {
    root?: string
    icon?: string
    machineName?: string
  }
}) => {
  const { t } = useTranslation()
  return (
    <div className={props.classes?.root}>
      <WorkingStepIconSvg
        workingStepType={props.routingStep.workingStepType}
        className={props.classes?.icon}
      />
      <Typography
        variant="caption"
        color="inherit"
        className={props.classes?.machineName}
      >
        {props.routingStep.resourceName || t('project:no-resources-available')}{' '}
      </Typography>
    </div>
  )
}

export const WorkingStepIconWithResourceSelector = (props: {
  workingStep: WorkingStepDto
  bomItemPointers: BomItemPointer[]
  disableMatchDescription?: boolean
}) => {
  const { classes } = useRoutingHeaderStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { workingStepNotAllowed } = useNotAllowedWorkingStep(
    props.bomItemPointers[0],
    props.workingStep.primaryWorkingStep
  )

  if (!props.workingStep) {
    return null
  }

  return (
    <>
      <div className={classes.routingRow}>
        <div className={classes.workingStepWithResource}>
          <WorkingStepIconSvg
            workingStepType={props.workingStep.primaryWorkingStep}
            className={'working-step-icon'}
          />
          <div
            style={{
              width: '100%',
              padding: '2px',
              color: workingStepNotAllowed
                ? theme.palette.error.contrastText
                : undefined,
              backgroundColor: workingStepNotAllowed
                ? theme.palette.error.main
                : undefined,
            }}
          >
            {props.workingStep.resource?.name ? (
              <WorkingStepResourceSelector
                workingStep={props.workingStep}
                bomItemPointers={props.bomItemPointers}
                disableLabel
                disableUnderline
                disableMatchDescription
                disableSelectMaterialError
              />
            ) : (
              <Typography variant="caption" color="inherit">
                {t('project:no-resources-available')}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
