/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  CircularProgress,
  Fade,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Theme,
} from '@mui/material'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectEditableStateSelector } from 'features/BillOfMaterials/store/selectors/ProjectEditableStateSelector'
import { projectInBuyerViewSelector } from 'features/BillOfMaterials/store/selectors/projectInBuyerViewSelector'
import { projectOperationIsPendingSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowException } from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'

type Props = {
  onClick?: () => void
  /** a redux dispatchable action */
  action?
  /** the payload of redux action */
  actionParams?: any
  primaryText?: React.ReactNode
  primaryTextTranslationKey?: string
  secondaryTextTranslationKey?: string
  icon?: React.ReactElement
  secondary?: React.ReactElement
  onClose?: () => void
  closeAfterFullfilled?: boolean
  closeOnClick?: boolean
  hiddenForBuyers?: boolean
  forceDisabled?: boolean
  disableIfNotEditable?: boolean
  disableForSellerStatus?: ProjectStateAsString[]
  disableForBuyersStatus?: ProjectStateAsString[]
  loading?: boolean
  nestedItems?: React.ReactNode
}

export const useBomItemMenuStyles = makeStyles()((theme: Theme) => ({
  menuRoot: {
    maxWidth: `${theme.breakpoints.values['sm']}px`,
    whiteSpace: 'normal',
    width: '100%',
    padding: theme.spacing(1, 2),
  },
  menuItemAvatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: `${theme.spacing(3)} !important`,
      height: `${theme.spacing(3)} !important`,
    },
  },
}))

export const BomItemMenuItem = ({
  action,
  actionParams,
  primaryText,
  primaryTextTranslationKey,
  secondaryTextTranslationKey,
  icon,
  secondary,
  closeAfterFullfilled,
  onClose,
  closeOnClick,
  onClick,
  hiddenForBuyers,
  disableIfNotEditable,
  disableForSellerStatus,
  disableForBuyersStatus,
  loading,
  nestedItems,
  forceDisabled,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useBomItemMenuStyles()
  const menuItemRef = useRef(null)
  const menuItemBoundingBox = useRef<DOMRect>(null)
  const [nestedItemsOpen, setNestedItemsOpen] = React.useState(false)

  const operationPending = useAppSelector(
    action
      ? projectOperationIsPendingSelector(
          actionParams && actionParams['bomItemPointer']
            ? action['typePrefix'].concat(
                '/',
                actionParams['bomItemPointer']?.id
              )
            : action['typePrefix']
        )
      : () => loading
  )

  const operationStarted = useRef(false)
  const nestedTimeout = useRef<NodeJS.Timeout>()

  const openNestedNow = () => {
    if (!nestedItems) return

    if (nestedTimeout.current) {
      clearTimeout(nestedTimeout.current)
    }

    setNestedItemsOpen(true)
  }
  const closeWithDelay = () => {
    nestedTimeout.current = setTimeout(() => {
      setNestedItemsOpen(false)
    }, 50)
  }

  const handleClick = () => {
    try {
      if (onClick) {
        onClick()
      }
      if (action) {
        dispatch(action(actionParams))
        operationStarted.current = true
      }
      if (closeOnClick && onClose) {
        onClose()
      }
      if (nestedItems) {
        openNestedNow()
      }
    } catch (err) {
      ShowException('project', err)
      operationStarted.current = false
    }
  }

  if (
    closeAfterFullfilled &&
    onClose &&
    operationStarted.current &&
    !operationPending
  ) {
    operationStarted.current = false
    onClose()
  }

  const isBuyerView = useAppSelector(projectInBuyerViewSelector)
  const isEditable = useAppSelector(ProjectEditableStateSelector)
  const currentProjectStatus = useAppSelector(
    projectSelectors.projectStatusSelector
  )

  const menuDisabled =
    (disableIfNotEditable && !isEditable) ||
    (!isBuyerView &&
      disableForSellerStatus &&
      disableForSellerStatus.includes(
        ProjectStateAsString[currentProjectStatus]
      )) ||
    (isBuyerView &&
      disableForBuyersStatus &&
      disableForBuyersStatus.includes(
        ProjectStateAsString[currentProjectStatus]
      ))

  if (hiddenForBuyers && isBuyerView) return null

  if (menuDisabled && !forceDisabled) {
    return null
  }

  if (nestedItems) {
    menuItemBoundingBox.current = menuItemRef.current?.getBoundingClientRect()
  }

  return (
    <div onMouseLeave={closeWithDelay}>
      <MenuItem
        onClick={handleClick}
        className={classes.menuRoot}
        disabled={menuDisabled}
        ref={menuItemRef}
        sx={{
          backgroundColor: (theme) => {
            if (nestedItemsOpen) return theme.palette.action.selected
            else return theme.palette.background.paper
          },
          position: 'relative',
        }}
        onMouseEnter={openNestedNow}
        onMouseLeave={() => clearTimeout(nestedTimeout.current)}
      >
        <ListItemAvatar className={classes.menuItemAvatar}>
          {operationPending ? <CircularProgress size={24} /> : icon}
        </ListItemAvatar>
        <ListItemText
          primary={primaryText || t(primaryTextTranslationKey)}
          secondaryTypographyProps={{ component: 'span' }}
          secondary={secondary || t(secondaryTextTranslationKey)}
        />
      </MenuItem>
      <Fade in={nestedItems && nestedItemsOpen} timeout={300}>
        <Box
          onMouseLeave={() => closeWithDelay()}
          onMouseEnter={() => clearTimeout(nestedTimeout.current)}
          sx={{
            position: 'fixed',
            top: menuItemBoundingBox.current?.bottom,
            left: menuItemBoundingBox.current?.left,
            width: (theme) => theme.spacing(60),
            transform: `translate(-100%, -${menuItemBoundingBox.current?.height}px)`,
            zIndex: 2000,
            boxShadow: (theme) => theme.shadows[5],
            backgroundColor: (theme) => theme.palette.background.paper,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {nestedItems}
        </Box>
      </Fade>
    </div>
  )
}
