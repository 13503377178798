import { UnfoldLessOutlined, UnfoldMoreOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useAppSelector } from 'store/configureStore'
import { priceSummariesGroupedSelector } from './ProjectTotals.selectors'
import { ProjectTotalsMaterialsRows } from './ProjectTotalsMaterialsRows'
import { ProjectTotalsResourcesRows } from './ProjectTotalsResourcesRow'

export function ProjectTotals() {
  const groupedPriceSummaries = useAppSelector(
    priceSummariesGroupedSelector,
    isEqual
  )
  const [forceOpen, setForceOpen] = useState(false)

  return (
    <div>
      <div style={{ paddingLeft: '12px' }}>
        <IconButton onClick={() => setForceOpen(false)} size="small">
          <UnfoldLessOutlined />
        </IconButton>
        <IconButton onClick={() => setForceOpen(true)} size="small">
          <UnfoldMoreOutlined />
        </IconButton>
      </div>

      <ProjectTotalsMaterialsRows
        materials={groupedPriceSummaries.materials.priceSummaries}
        forceOpen={forceOpen}
      />
      <ProjectTotalsResourcesRows
        resources={groupedPriceSummaries.resources}
        forceOpen={forceOpen}
      />
    </div>
  )
}
