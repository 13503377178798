import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import {
  ProjectStateAsString,
  ProjectStatus,
} from 'controllers/Project/ProjectStateMachine'
import { ErrorCountBadge } from './ErrorCountBadge'

import { useProjectStateItem } from './useProjectStateItem'
import { useRequestedProjectState } from './useRequestedProjectState'

export const RequestState = () => {
  const {
    getStateTranslationKey,
    stateItemIsCurrentOrPast,
    classes,
    changeStatus,
    SavingComponent,
    LightTooltip,
  } = useProjectStateItem(ProjectStatus.REQUESTED)

  const { canChangeToRequestState, Errors, errorStyle, errorCount } =
    useRequestedProjectState()

  const handleClick = () => {
    if (canChangeToRequestState) {
      changeStatus(ProjectStateAsString.REQUESTED)
    }
  }

  return (
    <LightTooltip title={errorCount > 0 ? <Errors /> : ''}>
      <li
        style={errorStyle}
        className={
          stateItemIsCurrentOrPast() ? classes.active : classes.disabled
        }
        onClick={handleClick}
      >
        <LocalizedTypography
          translationKey={getStateTranslationKey()}
          variant="body2"
        />
        <ErrorCountBadge errorCount={errorCount} />

        <SavingComponent />
      </li>
    </LightTooltip>
  )
}
