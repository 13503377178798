import { RefreshOutlined } from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  onClick: () => void
  loading: boolean
}
export const RefreshWithProgressButton = (props: Props) => {
  if (props.loading) {
    return (
      <IconButton disabled={props.loading}>
        <CircularProgress size="1rem" color="inherit" />
      </IconButton>
    )
  } else {
    return (
      <IconButton onClick={props.onClick} disabled={props.loading}>
        <RefreshOutlined />
      </IconButton>
    )
  }
}

export const RefreshButton = (
  props: IconButtonProps & { loading: boolean }
) => {
  const { t } = useTranslation()
  const { loading, ...rest } = props

  if (loading) {
    const circularProgressSize = {
      // to prevent flickering of the button when the loading state changes
      small: '20.5px',
      medium: '14.5px',
    }
    return (
      <IconButton size={rest.size}>
        <CircularProgress
          size={circularProgressSize[rest.size || 'medium']}
          color="inherit"
        />
      </IconButton>
    )
  }

  return (
    <Tooltip title={t('common:refresh', 'refresh')}>
      <IconButton {...rest}>
        <RefreshOutlined />
      </IconButton>
    </Tooltip>
  )
}
