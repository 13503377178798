/* eslint-disable tss-unused-classes/unused-classes */
import { alpha, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useOverviewCardStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .Mui-focused': {
      backgroundColor: 'initial',
    },
    height: 'fit-content',
  },
  header: {
    display: 'flex',
    // gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    '& svg': {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
      // color: theme.palette.text.hint,
    },
  },
  headerTitle: {
    flex: '0 0 25%',
  },
  headerIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.disabled,
  },
  headerDiscount: {
    flex: '1 1 100%',
    textAlign: 'left',
  },
  headerTotalSalesPrice: {
    flex: '0 1 auto',
    textAlign: 'right',
    // paddingRight: theme.spacing(2),
    display: 'inherit',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  content: {
    flex: '1 1 100%',
    overflowY: 'auto',
    overflowX: 'auto',
    padding: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  footer: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footerQuantity: {
    alignSelf: 'center',
  },
  cardHightlightText: {
    fontWeight: 'bold',
    fontSize: theme.typography.h6.fontSize,
  },
  cardHightlightTextSecondary: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
  },
  cardHightlightContainer: {
    padding: theme.spacing(0, 2),
    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activeFilter: {
    backgroundColor: theme.palette.info.main + ' !important',
    color: theme.palette.info.contrastText + ' !important',
    '&:hover': {
      backgroundColor: `${theme.palette.info.dark} !important`,
    },
    '& span, input, .MuiInput-root, p, svg': {
      color: `${theme.palette.info.contrastText} !important`,
    },
    '& label.Mui-focused': {
      color: `${theme.palette.info.contrastText} !important`,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: `${alpha(
        theme.palette.info.contrastText,
        0.7
      )} !important`,
    },
    '& .MuiInput-root': {
      '& input': {
        borderColor: `${alpha(theme.palette.info.contrastText, 1)} !important`,
      },
      '&:hover input': {
        borderColor: `${alpha(theme.palette.info.contrastText, 1)} !important`,
      },
      '&.Mui-focused input': {
        borderColor: `${alpha(theme.palette.info.contrastText, 1)} !important`,
      },
    },
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    flexDirection: 'row-reverse',
    width: '100%',
    padding: 0,
  },
  rateInput: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'baseline',

    gap: '1em',
    '& span, p, .MuiInput-root': {
      fontSize: theme.typography.caption.fontSize,
    },
    '& input[name="money"]': {
      paddingTop: '0px',
      paddingBottom: '3px',
    },
    '& input[name="quantity"]': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  rateForm: {
    textAlign: 'right',
  },
  nothingToShow: {
    width: '100%',
    height: '100%',
    minHeight: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
