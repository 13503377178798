import { EventNoteOutlined } from '@mui/icons-material'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { EventLogsDialogProps } from 'features/EventLogs/components/EventLogsDialog'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BadgeList } from './BadgeList'
import { BomItemMenuItem } from './BomItemMenuItem'

export const EventLogMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  const { openDialog } = useDialogManager()

  const handleOpen = () => {
    openDialog('EventLogs', {
      bomItemPointer: bomItemPointer,
    } as EventLogsDialogProps)
  }

  return (
    <BomItemMenuItem
      onClick={handleOpen}
      closeOnClick
      onClose={onClose}
      primaryTextTranslationKey="project:event-logs"
      secondary={<BadgeList bomItemPointer={bomItemPointer} />}
      icon={<EventNoteOutlined />}
      hiddenForBuyers
    />
  )
}
