import { createAsyncThunk } from '@reduxjs/toolkit'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { ShowException } from 'store/Application/appActions'
import { GetPartyId } from 'store/storeUtils'
import { Debugger } from 'utils/debugger'
import { newProjectListActions } from '../NewProjectListReducer'
import { RootAppState } from 'store/configureStore'

export const UnloadProjectList = createAsyncThunk<
  void,
  null,
  { state: RootAppState }
>('projectList/unloadProjectList', async (_, thunkAPI) => {
  const partyId = GetPartyId()

  thunkAPI.dispatch(newProjectListActions.clearProjectList())

  try {
    const hub = await SignalRService.GetHub()
    hub.LeaveGroup(`${partyId}_project_list`, () => {
      Debugger.Log('info', 'disconnected from project list updates')
    })

    hub.unregister('onProjectsListUpdates')
  } catch (err) {
    ShowException('live updates', err)
  }
})
