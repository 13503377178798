import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import _ from 'lodash'
import { forwardRef, ReactNode } from 'react'
import { RootAppState, useAppSelector } from 'store/configureStore'

type Props = {
  children: ReactNode
  /**
   * Show the children to the buyer
   */
  buyer?: boolean
  /**
   * Show the children to the seller
   */
  seller?: boolean

  /**
   * force the role to be buyer
   */
  isBuyerRole?: boolean

  /**
   * states to show the children
   */
  showInStates?: ProjectStateAsString[]
}

const ShowToRoleAndStates = (
  props: Props,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ref
) => {
  const allStates = [
    ProjectStateAsString.CANCELLED,
    ProjectStateAsString.DEFINING,
    ProjectStateAsString.REQUESTED,
    ProjectStateAsString.QUOTING,
    ProjectStateAsString.QUOTED,
    ProjectStateAsString.NEGOTIATING,
    ProjectStateAsString.ORDERED,
    ProjectStateAsString.PRODUCED,
    ProjectStateAsString.PRODUCING,
    ProjectStateAsString.PACKAGING,
    ProjectStateAsString.PACKAGED,
    ProjectStateAsString.DELIVERING,
    ProjectStateAsString.DELIVERED,
  ]

  const statesToShow = props.showInStates || allStates

  const isBuyerRole = useAppSelector((state: RootAppState) =>
    props.isBuyerRole === undefined
      ? state.project.activeProject?.isBuyingPartyView
      : props.isBuyerRole
  )
  const status = useAppSelector(
    (state: RootAppState) =>
      state.project.activeProject?.status as ProjectStateAsString
  )

  if (
    props.isBuyerRole === undefined &&
    status !== undefined &&
    !_.includes(statesToShow, status)
  ) {
    return null
  }

  if (props.buyer && isBuyerRole) {
    return <>{props.children}</>
  } else if (props.seller && !isBuyerRole) {
    return <>{props.children}</>
  }

  return null
}

export default forwardRef(ShowToRoleAndStates)
