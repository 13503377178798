import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemUrlSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    if (window.self !== window.top) {
      // no support for referrals url in iframe
      return null
    }

    if (!bomItemPointer) return null
    if (bomItemPointer.id === state.project.activeProject?.id) {
      return `${window.location}/${state.project.activeProject.id}`
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    return `${window.location.origin}/app/project/${state.project.activeProject.id}/bomitem/${bomItem?.id}`
  }
