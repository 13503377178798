import {
  Box,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  StandardTextFieldProps,
  TextField,
} from '@mui/material'
import React from 'react'
import { Field } from 'react-final-form'
import { NumericFormatProps } from 'react-number-format'
import {
  MoneyDto,
  QuantityDto,
} from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { NumberFormatCustom } from '../NumberFormatInputComponent'
// import ValueLabel from '../ValueLabel/ValueLabel'
import './QuantityField.scss'

const getMenuItems = (abbrs: string[]) => {
  return abbrs.map((unit) => {
    return (
      <MenuItem key={unit} value={unit}>
        {unit}
      </MenuItem>
    )
  })
}

const getSelectableAbbreviations = (
  selectableAbbreviations: string[] | Record<string, string[]>
) => {
  const returnNodes = []

  if (!Array.isArray(selectableAbbreviations)) {
    Object.keys(selectableAbbreviations).forEach((group) => {
      returnNodes.push(<ListSubheader key={group}>{group}</ListSubheader>)
      returnNodes.push(getMenuItems(selectableAbbreviations[group]))
    })
  } else if (selectableAbbreviations.length > 1) {
    returnNodes.push(getMenuItems(selectableAbbreviations))
  }

  return returnNodes
}

export const QuantityField = (
  props: StandardTextFieldProps & {
    decimalScale?: number
    textAlign?: 'right' | 'left' | 'center'
    shouldValidate?: boolean
    allowNegative?: boolean
    onFocus?: (event?: React.FocusEvent<HTMLElement>) => void
    onBlur?: (event) => void
    onChange: (value: QuantityDto | MoneyDto) => void
    showMinus?: boolean
    disableUnderline?: boolean
    fixedDecimalScale?: boolean
    hiddenUnit?: boolean
    fullWidth?: boolean
  }
) => {
  const quantityOrMoney = props.value as QuantityDto & MoneyDto
  const units = quantityOrMoney?.selectableAbbreviations

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { textAlign, shouldValidate, decimalScale, ...textFieldProps } = props

  return (
    <div
      className={`${
        props.textAlign === 'left'
          ? 'input-quantity-left'
          : 'input-quantity-field'
      }`}
      style={props.style}
    >
      <TextField
        name={`${props.name}.value`}
        value={quantityOrMoney?.value}
        label={props.label}
        required={props.required}
        error={props.error}
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        onBlur={props.onBlur}
        onChange={(e) => {
          props.onChange({
            ...quantityOrMoney,
            value: parseFloat(e.currentTarget.value),
          })
        }}
        disabled={props.disabled}
        InputProps={{
          className: 'input-quantity',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumberFormatCustom as any,
          startAdornment:
            (((quantityOrMoney && quantityOrMoney.symbol) ||
              props.showMinus) && (
              <InputAdornment position="start">
                {props.showMinus && '- '}
                {quantityOrMoney?.symbol}
                {props.InputProps?.startAdornment}
              </InputAdornment>
            )) ||
            props.InputProps?.startAdornment,
          endAdornment: quantityOrMoney &&
            quantityOrMoney.unit &&
            props.hiddenUnit !== true &&
            units?.length < 2 && (
              <InputAdornment position="end">
                {quantityOrMoney.unit}
              </InputAdornment>
            ),
          fullWidth: textFieldProps.InputProps?.fullWidth,
          inputProps: {
            decimalScale: props.decimalScale ?? 2,
            allowNegative: props.allowNegative || false,
            fixedDecimalScale: props.fixedDecimalScale ?? true,
            onFocus: (e) => e.target.select(),
            ...(props.InputProps?.inputProps || {}),
          } as NumericFormatProps,
          disableUnderline: props.disableUnderline,
        }}
        inputProps={Object.assign({}, textFieldProps.inputProps, {
          style: { textAlign: textAlign || 'right' },
        })}
      />
      {quantityOrMoney &&
        Object.keys(quantityOrMoney['selectableAbbreviations'] || {})?.length >
          1 && (
          <Box marginLeft={1}>
            <Field name={`${textFieldProps.name}.unit`}>
              {(fieldProps) => {
                return (
                  <Select
                    error={textFieldProps.error}
                    fullWidth={false}
                    disabled={props.disabled}
                    disableUnderline
                    onChange={(e) => fieldProps.input.onChange(e.target.value)}
                    value={fieldProps.input.value || 'kg'}
                  >
                    {getSelectableAbbreviations(
                      quantityOrMoney.selectableAbbreviations
                    )}
                  </Select>
                )
              }}
            </Field>
          </Box>
        )}
    </div>
  )
}
