import { Typography, useTheme } from '@mui/material'
import { CSSProperties } from 'react'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'

export function RenderLegend(props: LegendProps) {
  const theme = useTheme()

  if (props.payload.find((x) => x.value.includes('children'))) {
    // will not show the legend for assemblies and project.
    return null
  }

  return (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        marginTop: '1rem',
        flexWrap: 'wrap',
      }}
    >
      {props.payload.map((entry, index) => {
        if (entry.value.includes('children')) return null
        if (!entry.payload['activeBar']) return null

        const style: CSSProperties = {
          display: 'inner-block',
          width: props.iconSize,
          height: props.iconSize,
          backgroundColor: entry.color,
          marginRight: '0.5rem',
        }

        if (entry.payload['dataKey'] === 'discountValue.value') {
          style.backgroundColor = 'none'
          style.border = `1px solid ${theme.palette.error.main}`
          style.background = `repeating-linear-gradient(
            -45deg,
            ${'transparent'},
            ${'transparent'} 15%,
            ${theme.palette.error.main} 20%)
            `
        }

        return (
          <li
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: entry.color,
            }}
          >
            <div style={style} />
            <Typography variant="body2">{entry.value}</Typography>
          </li>
        )
      })}
    </ul>
  )
}
