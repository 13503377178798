import { faVial } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addRegressionTest } from 'features/BillOfMaterials/store/asyncActions/addRegressionTest'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemMenuItem } from './BomItemMenuItem'

export const RegressionTestMenuItem = ({
  bomItemPointer,
  onClose,
}: {
  bomItemPointer: BomItemPointer
  onClose: () => void
}) => {
  if (import.meta.env.PROD) {
    return null
  }

  return (
    <BomItemMenuItem
      action={addRegressionTest}
      actionParams={{ bomItemId: bomItemPointer.id }}
      primaryTextTranslationKey="project:regression-test"
      secondaryTextTranslationKey="project:regression-test-description"
      icon={
        <FontAwesomeIcon
          className="MuiSvgIcon-root"
          icon={faVial}
          // width={'1em'}
        />
      }
      closeAfterFullfilled
      onClose={onClose}
      hiddenForBuyers
    />
  )
}
