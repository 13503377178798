import { Button, Container, Typography } from '@mui/material'
import Spinning from 'components/Common/Spinner/Spinning'
import { Section } from 'components/Layout/Section'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppLogout } from 'features/Auth/useAppLogout'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { gettingStartedRoutes } from 'features/GettingStarted/GettingStartedRoutes'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootAppState, useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { ProductName } from '../Common/ProductName/ProductName'
import './LandingPage.sass'

const useStyles = makeStyles({ name: 'LandingPage' })((theme) => ({
  hero: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.5em',
    justifyContent: 'center',
    height: '100vh',
    ['& h3']: {
      fontSize: '3.0rem',
    },
  },
  productName: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  smartMetal: {
    marginTop: 'auto',
    width: '100%',
    textAlign: 'center',
    fontVariant: 'all-small-caps',
    display: 'block',
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

const LandingPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { withinIframe } = useAppRunningContext()
  const { t } = useTranslation(['common'])
  const { classes } = useStyles()
  const userContext = useAppSelector(
    (state: RootAppState) => state.user.profile
  )

  const state = location.state

  useEffect(() => {
    if (withinIframe) {
      setTimeout(() => {
        navigate('/login', { state })
      }, 0)
    }
  }, [withinIframe, navigate, state])

  useEffect(() => {
    if (userContext) {
      navigate('/app')
    }
  }, [userContext, navigate])

  const { appLogout } = useAppLogout()

  const handleGettingStarted = async () => {
    await appLogout({
      logoutParams: {
        returnTo: window.location.origin + gettingStartedRoutes.welcome.path,
      },
    })
  }

  if (withinIframe) return <Spinning fixed message="waiting for login page" />

  return (
    <div>
      <div className={classes.hero}>
        <Section className={classes.productName}>
          <Container maxWidth="md">
            <ProductName />
            <div className={classes.actionButtons}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => navigate('/login', { state })}
              >
                {t('common:sign-in')}
              </Button>
              {/* <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleGettingStarted()}
              >
                {t('common:getting-started')}
              </Button> */}
            </div>
          </Container>
        </Section>
        <Button
          variant="text"
          className={classes.smartMetal}
          component="a"
          href="https://quotationfactory.com"
        >
          <Typography variant="h6">
            <strong>
              <LocalizedTypography
                translationKey="common:landing-page-hero-footer-call-to-action"
                translationDefaultValue="smart metal calculations, direct quotes and cam downloads, 24/7"
                component="p"
                className="hero-footer--call-to-action"
              />
            </strong>
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default LandingPage
