import {
  Button,
  Divider,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import { LocalizedButton } from 'components/Localization/LocalizedButton'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { BomItemController } from 'controllers/Project/BomItemController'
import { useAppController } from 'customHooks/useAppController'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { isEqual } from 'lodash'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PrecisionsDto,
  ToleranceInfo,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { Key } from 'utils/keyCodes'
import { hasActiveFiltersSelector } from '../store/filters/hasActiveFiltersSelector'
import { projectSelectors } from '../store/selectors/projectSelectors'

type Props = {
  bomItemPointer: BomItemPointer
}

const useStyles = makeStyles()((theme) => ({
  toleranceTooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    minWidth: '300px',
    zIndex: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    paddingTop: theme.spacing(1),
  },
  toleranceTooltipItem: {
    padding: theme.spacing(0, 2),
  },
  // tooltip: {
  //   backgroundColor: theme.palette.background.paper,
  //   maxWidth: 'max-content',
  //   padding: 0,
  //   zIndex: 10,
  // },
  // arrow: {
  //   position: 'absolute',
  //   width: '3em',
  //   height: '3em',
  //   '&::before': {
  //     content: '" "',
  //     margin: 'auto',
  //     display: 'block',
  //     width: 0,
  //     height: 0,
  //     borderStyle: 'solid',
  //     borderWidth: '0 2em 2em 2em',
  //     borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
  //   },
  // },
  // arrowTop: {
  //   left: '.-5em',
  //   transform: 'translateX(-50%) rotate(-90deg)',
  // },
}))

export const BomItemPrecisions = (props: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const precisionsDto = useAppSelector(
    projectSelectors.bomItemPrecisionsSelector(props.bomItemPointer),
    isEqual
  )

  projectSelectors.headerFilteredRowsSelector(props.bomItemPointer)
  const filteredPartTypes = useAppSelector(
    projectSelectors.headerFilteredRowsSelector(props.bomItemPointer),
    isEqual
  )

  const { openDialog } = useDialogManager()

  const hasFilters = useAppSelector(hasActiveFiltersSelector(true))

  const [precisions, setPrecisions] = useState<PrecisionsDto>(null)
  const [changed, setChanged] = useState(false)

  const { controller, loading } = useAppController(
    () => new BomItemController()
  )

  const [open, setOpen] = useState(false)

  const anchorEl = useRef<HTMLButtonElement>(null)
  // const arrowEl = useRef<HTMLDivElement>(null)
  const closeTimeout = useRef<NodeJS.Timeout>(null)
  const openTimeout = useRef<NodeJS.Timeout>(null)

  const handleChangeToleranceInfo = (
    toleranceType: keyof PrecisionsDto,
    toleranceInfo: ToleranceInfo
  ): void => {
    setPrecisions({
      ...precisions,
      [toleranceType]: toleranceInfo,
    })

    setChanged(true)
  }

  const save = useCallback(
    async (reason: 'apply' | 'escapeKey' = 'apply') => {
      let bomItemPointersToChange: Array<BomItemPointer> = []

      if (props.bomItemPointer.type === BomItemType.partType) {
        bomItemPointersToChange = [props.bomItemPointer]
      } else if (props.bomItemPointer.type === BomItemType.assemblyType) {
        if (hasFilters) {
          bomItemPointersToChange = filteredPartTypes
        } else {
          bomItemPointersToChange = [
            props.bomItemPointer,
            ...(filteredPartTypes || []),
          ]
        }
      } else {
        bomItemPointersToChange = [
          props.bomItemPointer,
          ...(filteredPartTypes || []),
        ]
      }

      const saveAction = async () => {
        try {
          await controller.SetBomItemPrecisions(
            bomItemPointersToChange,
            precisions
          )
        } catch (err) {
          ShowException('project', err)
        }
      }

      if (bomItemPointersToChange.length === 1 && reason === 'apply') {
        await saveAction()
      } else if (bomItemPointersToChange.length > 1) {
        openDialog('ConfirmationDialog', {
          onConfirm: saveAction,
          title: t(
            'project:saveChanges-multiple-rows',
            'save changes to {{count}} items?',
            { count: bomItemPointersToChange.length }
          ),
          onCancel: () => {
            setPrecisions(precisionsDto)
            setChanged(false)
            setOpen(false)
          },
        } as ConfirmationDialogProps)
      } else if (reason === 'escapeKey') {
        openDialog('ConfirmationDialog', {
          onConfirm: saveAction,
          title: t('common:saveChanges', 'save changes?'),
          onCancel: () => {
            setPrecisions(precisionsDto)
            setChanged(false)
            setOpen(false)
          },
        } as ConfirmationDialogProps)
      }
    },
    [
      controller,
      filteredPartTypes,
      hasFilters,
      openDialog,
      precisions,
      precisionsDto,
      props.bomItemPointer,
      t,
    ]
  )

  const handleClose = useCallback(
    (reason: 'apply' | 'escapeKey' = 'apply') => {
      clearTimeout(openTimeout.current)

      closeTimeout.current = setTimeout(async () => {
        setOpen(false)

        if (changed) {
          await save(reason)
          setChanged(false)
        }
      }, 120)
    },
    [changed, save]
  )

  const handleOpen = () => {
    clearTimeout(closeTimeout.current)
    openTimeout.current = setTimeout(() => {
      setOpen(true)
    }, 500)
  }

  useEffect(() => {
    setPrecisions(precisionsDto)
    setChanged(false)
  }, [precisionsDto])

  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === Key.Escape) {
        handleClose('escapeKey')
      }
    }

    document.addEventListener('keydown', escapeListener)

    return () => {
      document.removeEventListener('keydown', escapeListener)
    }
  }, [handleClose])

  if (
    props.bomItemPointer.type === BomItemType.assemblyInstance ||
    !precisions
  ) {
    return null
  }

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          open ? handleClose() : handleOpen()
        }}
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifySelf: 'baseline',
          height: '100%',
          flex: (theme) => `0 0 ${theme.spacing(10)}`,
        }}
        ref={anchorEl}
        onMouseEnter={() => handleOpen()}
        onMouseLeave={() => handleClose()}
      >
        <LocalizedTypography
          translationKey="project:precision"
          variant="caption"
          color="textSecondary"
          sx={{ mb: 0.5 }}
        >
          precision
        </LocalizedTypography>
        <Typography
          variant="body1"
          color={loading['set-precisions'] ? 'textSecondary' : 'textPrimary'}
          whiteSpace={'nowrap'}
        >
          {[
            t(
              `project:precisions-tolerance-${precisions.geometricTolerance.name}-${precisions.geometricTolerance.value}`
            ),
            t(
              `project:precisions-tolerance-${precisions.surfaceRoughness.name}-${precisions.surfaceRoughness.value}`
            ),
            t(
              `project:precisions-tolerance-${precisions.linearAndAngleTolerance.name}-${precisions.linearAndAngleTolerance.value}`
            ),
          ]
            .map((y) => y.charAt(0).toUpperCase())
            .join(' | ')}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement={'right'}
        style={{
          zIndex: 10,
        }}
        keepMounted={false}
      >
        <div
          className={classes.toleranceTooltip}
          onMouseLeave={() => handleClose()}
          onMouseEnter={() => handleOpen()}
        >
          <div className={classes.toleranceTooltipItem}>
            <ToleranceInfoData
              toleranceInfo={precisions.geometricTolerance}
              onChange={(toleranceInfo) =>
                handleChangeToleranceInfo('geometricTolerance', toleranceInfo)
              }
            />
          </div>
          <Divider orientation="horizontal" flexItem />
          <div className={classes.toleranceTooltipItem}>
            <ToleranceInfoData
              toleranceInfo={precisions.surfaceRoughness}
              onChange={(toleranceInfo) =>
                handleChangeToleranceInfo('surfaceRoughness', toleranceInfo)
              }
            />
          </div>
          <Divider orientation="horizontal" flexItem />
          <div className={classes.toleranceTooltipItem}>
            <ToleranceInfoData
              toleranceInfo={precisions.linearAndAngleTolerance}
              onChange={(toleranceInfo) =>
                handleChangeToleranceInfo(
                  'linearAndAngleTolerance',
                  toleranceInfo
                )
              }
            />
          </div>
          <LocalizedButton
            translationKey="common:apply"
            variant={'outlined'}
            sx={{ borderRadius: 0 }}
            disabled={!changed}
            onClick={() => handleClose()}
          />
        </div>
      </Popper>
    </>
  )
}

const ToleranceInfoData = (props: {
  toleranceInfo: ToleranceInfo
  onChange: (toleranceInfo: ToleranceInfo) => void
}) => {
  const { t } = useTranslation()

  return (
    <TextField
      select
      label={t(
        `project:precisions-tolerance-${props.toleranceInfo.name}`,
        `${props.toleranceInfo.name}`
      )}
      helperText={t(
        `project:precisions-tolerance-${props.toleranceInfo.name}-helperText`
      ).trim()}
      value={props.toleranceInfo.value}
      onChange={(e) => {
        props.onChange({
          ...props.toleranceInfo,
          value: e.target.value,
        })
      }}
      onClick={(e) => e.stopPropagation()}
      onPointerDown={(e) => {
        e.stopPropagation()
      }}
      fullWidth
      SelectProps={{
        renderValue: (value) => (
          <Typography variant="body1" color="textPrimary">
            {t(
              `project:precisions-tolerance-${props.toleranceInfo.name}-${value}`,
              `${value}`
            )}
          </Typography>
        ),
      }}
    >
      {props.toleranceInfo.selectableValues.map((value) => (
        <MenuItem
          key={value}
          value={value}
          onClick={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
        >
          {t(
            `project:precisions-tolerance-${props.toleranceInfo.name}-${value}`,
            `${props.toleranceInfo.value}`
          )}
        </MenuItem>
      ))}
    </TextField>
  )
}
