import * as React from "react";
const SvgTime = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 100 100", style: {
  enableBackground: "new 0 0 100 100"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("ellipse", { transform: "matrix(0.7071 -0.7071 0.7071 0.7071 -20.7701 49.966)", style: {
  fill: "transparent"
}, cx: 49.9, cy: 50.1, rx: 22.7, ry: 22.7 }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M49.9,25.8c-13.4,0-24.3,10.9-24.3,24.3c0,13.4,10.9,24.3,24.3,24.3c13.4,0,24.3-10.9,24.3-24.3 C74.2,36.7,63.3,25.8,49.9,25.8z M49.9,70.8c-11.4,0-20.7-9.3-20.7-20.7c0-11.4,9.3-20.7,20.7-20.7c11.4,0,20.7,9.3,20.7,20.7 C70.6,61.5,61.4,70.8,49.9,70.8z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M48.3,47.3l-0.1-10.9c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8l0.1,10.9l-0.3-0.1 C50.4,46.6,49.2,46.7,48.3,47.3z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "currentColor"
}, d: "M49.4,51.8c-0.9-0.4-1.3-1.4-0.9-2.3c0.4-0.8,1.4-1.3,2.3-0.9l13.8,6.1c0.9,0.4,1.3,1.4,0.9,2.3 c-0.4,0.8-1.4,1.3-2.3,0.9L49.4,51.8z" })))));
export default SvgTime;
