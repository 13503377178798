import { TextFieldProps } from '@mui/material'
import { TargetPriceSlider } from 'components/ProjectPage/BillOfMaterials/Rows/TargetPrice/TargetPriceSlider'
import { saveBomItemTargetPrice } from 'features/BillOfMaterials/store/asyncActions/saveBomItemTargetPrice'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'
import { MoneyDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { BomItemPointer } from 'model/Project/BomItemPointer'

export function BomItemTargetPriceInput(props: {
  bomItemPointer: BomItemPointer
  priceScope?: 'perUnit' | 'total'
  textFieldProps?: TextFieldProps
  disableSlider?: boolean
  sliderClassName?: string
}) {
  const { t } = useTranslation()

  const targetPrice: MoneyDto = useAppSelector(
    projectSelectors.bomItemFinancialSelector(
      props.bomItemPointer,
      'targetSalesPricePerItem'
    ),
    isEqual
  ) as MoneyDto
  const salesPrice: MoneyDto = useAppSelector(
    projectSelectors.bomItemFinancialSelector(
      props.bomItemPointer,
      'salesPricePerItem'
    ),
    isEqual
  ) as MoneyDto

  // if (props.priceScope === 'total') {
  //   return (
  //     <ValueLabelConnected
  //       propertySelector={(state) =>
  //         MoneyString(
  //           projectSelectors.bomItemTargetPriceSelector(
  //             props.bomItemId,
  //             props.priceScope
  //           )(state)
  //         )
  //       }
  //       valueLabelProps={{
  //         label: t('common:target-price', 'target price'),
  //       }}
  //     />
  //   )
  // }

  return (
    <span>
      <ProjectTextField
        valueSelector={projectSelectors.bomItemFinancialSelector(
          props.bomItemPointer,
          'targetSalesPricePerItem'
        )}
        textFieldProps={{
          label: t('common:target-price-per-batch', 'target price / batch'),
          ...props.textFieldProps,
        }}
        actionTypePrefix={saveBomItemTargetPrice.typePrefix.concat(
          '/',
          props.bomItemPointer.id
        )}
        saveAction={(value: MoneyDto) =>
          saveBomItemTargetPrice({
            bomItemPointer: props.bomItemPointer,
            targetPrice: value,
          })
        }
        money
      />
      {!props.disableSlider &&
        targetPrice?.value > 0 &&
        salesPrice?.value > 0 && (
          <span>
            <TargetPriceSlider
              salesPrice={salesPrice}
              targetPrice={targetPrice}
              rootClassName={props.sliderClassName}
            />
          </span>
        )}
    </span>
  )
}
