import { TextField, TextFieldProps } from '@mui/material'
import { NumericFormatProps } from 'react-number-format'
import { NumberFormatCustom } from './NumberFormatCustom'

export type NumberInputProps = TextFieldProps & {
  textAlign?: 'left' | 'center' | 'right'
  required?: boolean
  suffix?: string
  preffix?: string
  decimalScale?: number
  disableUnit?: boolean
}

function NumberInput(props: NumberInputProps) {
  return (
    <TextField
      {...props}
      className="number-input"
      fullWidth={props.InputProps?.fullWidth}
      InputProps={{
        ...props.InputProps,
        inputComponent: NumberFormatCustom as never,
        inputProps: {
          fixedDecimalScale: false,
          style: { textAlign: props.textAlign || 'right' },
          decimalScale: props.decimalScale ?? 2,
          autoFocus: props.autoFocus,
          autoComplete: 'off',
          required: props.required,
          suffix: props.suffix || '',
          preffix: props.preffix || undefined,
        } as NumericFormatProps,
      }}
    />
  )
}

export { NumberInput }
