import { useTheme } from '@mui/material'
import { DefinitiveDecimalTextField } from 'components/DefinitiveInputs/DefinitiveDecimalTextField'
import { RenderEditCellProps } from 'react-data-grid'
import { NumericFormatProps } from 'react-number-format'
import { useDataGridEditorNavigation } from './useDataGridEditorNavigation'

type NumberDataGridEditorProps<TRow, TSummaryRow> = {
  rootElementClassName?: string
} & RenderEditCellProps<TRow, TSummaryRow> &
  NumericFormatProps

export function NumberDataGridEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  rootElementClassName,
  ...rest
}: NumberDataGridEditorProps<TRow, TSummaryRow>) {
  const theme = useTheme()

  const { handleNavigationKeys } = useDataGridEditorNavigation(() => {
    onClose(true)
  })

  return (
    <DefinitiveDecimalTextField
      rootElementClassName={rootElementClassName}
      value={row[column.key]}
      onChange={(value) => {
        onRowChange({
          ...row,
          [column.key]: value,
        })
      }}
      numberFormatProps={{
        ...rest,
      }}
      selectTextOnFirstRender
      textFieldProps={{
        onBlur: () => onClose(true),
        InputProps: {
          disableUnderline: true,
        },
        inputProps: {
          autoFocus: true,
          onKeyDown: handleNavigationKeys,
          style: {
            textAlign: 'right',
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing(1),
            height: '100%',
            paddingTop: theme.spacing(1.4),
          },
        },
      }}
    />
  )
}
