import React, { useCallback, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Spinning from '../Common/Spinner/Spinning'
import { checkAppVersionThrottled } from './checkAppVersion'

export function CheckAppVersionRoute() {
  const navigate = useNavigate()
  const [checked, setChecked] = React.useState(false)

  const checkAppPromise = useCallback(async () => {
    return checkAppVersionThrottled(navigate)
      .then((versionInfo) => {
        console.info('isLatestVersion', versionInfo)
        setChecked(versionInfo.isLatest)
      })
      .catch((err) => {
        console.error('error checking app version', err)
        setChecked(true)
      })
  }, [navigate])

  useEffect(() => {
    checkAppPromise()
  }, [checkAppPromise])

  if (!checked) {
    console.info('checking app version')
    return <Spinning fixed />
  }

  return <Outlet />
}
